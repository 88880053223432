<template>
	<div id="story" :class="{ search: storyType == storyScreens.SEARCH_STORY }">
		<div class="story-details">
			<div class="header" v-if="storyType !== storyScreens.SEARCH_STORY">
				<Header :font-size="'large'" :font-face="'pixl'" :text-alignment="'left'" :fontColor="'cream'" :headerText="story.name"></Header>
			</div>
			<div class="story-and-twist-container">
				<div class="story-text-details">
					<div class="author" :lang="this.$i18n.locale">{{ $t(`story_select.player_story_id`, { playerId: story.author || findAuthor }) }}</div>
					
					<div class="story-type" :lang="this.$i18n.locale">
						<div v-if="story.isSolo"><span>Solo Story</span></div>
						<div v-else-if="!story.isPublic && !story.isSolo"><span>Friends Story</span> <img src="../../../../assets/ui/buttons/lock-button.png" alt="lock" class="lock-icon"></div>
						<div v-else> <span>Community Story</span></div>
					</div>
					<div class="latest-chapter" :lang="this.$i18n.locale">{{ $t('story_select.latest_chapter', { latestChapter: this.story.chapter }) }}</div>
					<div class="protagonist" :lang="this.$i18n.locale">{{ $t('story_select.protagonist', { protagonists: story.players.length }) }}</div>

					<div class="story-more-info">
						<StoryDifficultyMap :difficulty="story.difficulty" :map="story.mapSelection"></StoryDifficultyMap>
						<div v-if="!story.isSolo && portal === 'none'" class="game-id" :lang="this.$i18n.locale">
							<CopyToClipboard :copy-text="copyGameInfo(storyLinkURL, story.id)" placeholder-title="Copy sharable link to clipboard" :display-text="'&#91;' + story.id + '&#93;'" class="small"></CopyToClipboard>
						</div>
					</div>
				</div>
				<div class="twist-details" :lang="this.$i18n.locale">
					<span class="header"> Chapter Plot Twists </span>
					<span class="header" v-if="storyType !== storyScreens.SEARCH_STORY && !story.isSolo"> Voting ends in {{ nextVoteTimeString }} </span>
					<div class="mutators-list" v-if="story.formattedTwists.length > 0">
						<ScrollableItemsContainer :enable-arrow-background="false" @on-click-fn="arrowButton" :show-scroll-buttons="true">
							<template #arrow-content>
								<div class="book-container" :id="`moveable-${storyIndex}`">
									<div class="book-list">
										<ItemUpdated
											v-for="(i, index) in story.formattedTwists"
											:key="index"
											:id="`twist-${index}`"
											:item="i"
											:disable-circle="true"
											:small-icons="true"
											:enable-glow="false"
											:selected-item="false"
											:on-click-prop="() => {}"
											:on-hover-fn="() => {}"
											:disable-book="false"
											:component="'twist'"
											:show-book-background="true"
											:tooltip-enabled="true"
										></ItemUpdated>
									</div>
								</div>
							</template>
						</ScrollableItemsContainer>
					</div>

					<div class="mutators-list" v-else>
						<div class="no-twists">{{ $t('story_select.no_twists') }}</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="storyType == storyScreens.ACTIVE_STORIES" class="story-buttons">
			<TextButton :button-label="$t('story_select.button_leave')" :button-style="'blue'" @on-click-fn="archiveStory({ storyId: story.id })"></TextButton>
			<TextButton :button-label="$t('story_select.button_summary')" :button-style="'green'" @on-click-fn="$emit('changeScreen', $event)" :optional-param="{ view: Views.CHAPTER_SELECT, story: story }"></TextButton>
			<TextButton :button-label="$t('story_select.button_play')" :button-style="'bright-green'" @on-click-fn="$emit('changeScreen', $event)" :optional-param="{ view: Views.CHARACTER_SELECT, story: story }"></TextButton>
		</div>
		<div v-else class="story-buttons">
			<TextButton :button-label="$t('story_select.button_summary')" :button-style="'green'" @on-click-fn="$emit('changeScreen', $event)" :optional-param="{ view: Views.CHAPTER_SELECT, story: story }"></TextButton>
			<TextButton :button-label="$t('story_select.button_play')" :button-style="'bright-green'" @on-click-fn="$emit('unarchiveStory', $event)" :optional-param="{ view: Views.CHARACTER_SELECT, story: story }"></TextButton>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Header from '../dividers-headers/header.vue'
import ItemUpdated from '../item/items-updated.vue'
import TextButton from '../buttons/text-button.vue'
import CopyToClipboard from '../labels/copy-to-clipboard.vue'
import { Views } from '../../store/ui-store'
import { format, parseISO, differenceInMilliseconds, formatDistance } from 'date-fns'
import ScrollableItemsContainer from '../arrow/arrow-background.vue'
import { StoryScreens } from '../../store/story-store'
import CopyGameInformation from '../mixins/CopyGameInfomation'
import { StoryDifficulty } from '../../../game-data/difficulties'
import StoryDifficultyMap from '../story-difficulty-map.vue'

export default {
	name: 'StoryContainer',
	components: {
		Header,
		ItemUpdated,
		TextButton,
		CopyToClipboard,
		ScrollableItemsContainer,
		StoryDifficultyMap
	},
	data() {
		return {
			Views: Views,
			storyScreens: StoryScreens,
		}
	},
	props: {
		story: {
			type: Object,
			required: true,
			default: () => {
				console.warn('the default value for :story is being used. Did you forget to supply a value?')
			},
		},

		storyIndex: {
			type: Number,
			required: true,
			default: () => {
				console.warn('the default value for :storyIndex is being used. Did you forget to supply a value?')
			},
		},
		storyType: {
			type: String,
			required: true,
			default: () => {
				console.warn('the default value for :storyType is being used. Did you forget to supply a value?')
			},
			validator: (value: string) => {
				const screens: string[] = Object.values(StoryScreens)
				return screens.includes(value)
			},
		},
	},
	computed: {
		...mapGetters('ui', ['storyLinkURL', 'portal']),
		...mapGetters('story', ['getSelectedStory']),
		...mapState('user', ['externalUserAccountType']),

		nextVoteTime() {
			const endTime = parseISO(this.story.nextTally)
			const time = format(endTime, `eee', 'dd MMM yyyy  hh:mm:ss a`)
			return time
		},
		nextVoteTimeString() {
			const currentTime = new Date()
			const endTime = parseISO(this.story.nextTally)
			const remainingVoteTime = differenceInMilliseconds(endTime, currentTime)
			const time = formatDistance(endTime, currentTime)
			return time
		},
		findAuthor() {
			const creatorId = this.story.creator
			const author = this.story.players.find((p) => p.playerId === creatorId)
			return author.username
		}
	},
	methods: {
		// ...mapMutations('ui', ),
		...mapActions('story', ['archiveStory']),

		arrowButton(direction) {
			const el = document.getElementById('twist-0')
			const scrollableContainer = document.getElementById(`moveable-${this.storyIndex}`)
			let width = el.offsetWidth
			let style = getComputedStyle(el)
			width += parseInt(style.marginLeft) + parseInt(style.marginRight)

			switch (direction) {
				case 'left':
					scrollableContainer.scrollTo({ left: scrollableContainer.scrollLeft - width * 3, behavior: 'smooth' })
					break
				case 'right': 
					scrollableContainer.scrollTo({ left: scrollableContainer.scrollLeft + width * 3, behavior: 'smooth' })
					break
			}
		},
	},
	mixins: [CopyGameInformation]
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#story {
	background-color: @darkBlue;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 1290px;
	height: 255px;
	margin: 12px 0px;
	padding: 10px;
	border-radius: 7px;
	&:hover {
		// border-image-source: url('../../../../assets/ui/item/highlight-hover.png');
		// border-image-slice: 27 fill;
		// border-image-width: 30px;
	}
	&.search {
		height: 161px !important;
	}

	.story-details {
		width: 1070px;
		height: 100%;
		> .header {
			line-height: 35px;
			height: 70px;
		}
		.story-and-twist-container {
			display: flex;
			.story-text-details {
				display: flex;
				flex-direction: column;
				width: 315px;
				align-items: center;
				.author,
				.game-id,
				.latest-chapter,
				.protagonist,
				.story-type {
					&[lang='en'] {
						.regularFont(@enRegularFontPixl);
					}
					&[lang='zh'] {
						.regularFont(@zhHansHeaderFont);
					}
					.lock-icon {
						height: 24px;
						filter: brightness(2);
					}
				}
				.author,
				.story-type {
					color: @offWhite;
					font-size: 30px;
					word-break: keep-all;
					white-space: nowrap;
				}

				.game-id {
					font-size: 30px;
					color: @darkGreen;
				}
				.latest-chapter,
				.protagonist {
					font-size: 30px;
					color: @green;
				}
				.private-story {
					display: flex;
					align-items: center;
				}

				.story-more-info {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: flex-end;

					.game-id {
						margin-left: 10px;
					}
				}
			}
			.twist-details {
				width: 670px;
				display: flex;
				flex-direction: column;
				text-align: center;
				font-size: 30px;
				color: @offWhite;
				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
				.mutators-list {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: center;

					:deep(#scrollable-items) {
					}
					.no-twists {
						margin-top: 10px;
					}
					.book-container {
						overflow: hidden;
						width: 345px;
						margin-bottom: 8px;
						.book-list {
							display: flex;
							.book {
								margin: 0px 10px;
							}
						}
					}
				}
			}
		}
	}
	.story-buttons {
		width: 305px;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
		.text-button {
			width: 264px;
			height: 65px;
		}
	}
}
</style>
