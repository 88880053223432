import { isArray, sampleSize } from "lodash";
import { PlayerBinaryFlags } from "../buffs/buff-system"
import { GameClient } from "../engine/game-client"
import { GameState } from "../engine/game-state";
import { Camera } from "../engine/graphics/camera-logic"
import { DeadBehaviours, EnemyAI } from "../entities/enemies/ai-types";
import { PostSpawnAfterDeathMod } from "../entities/enemies/behaviours/dead";
import EnemyEquilibriumSpawner from "../entities/enemies/enemy-equilibrium-spawner";
import { ENEMY_NAME } from "../entities/enemies/enemy-names";
import EntityStatList, { BossEnemyGlobalStatList, CommonEnemyGlobalStatList, EnemyGlobalStatList, GlobalStatList, StatBonusData, UncommonEnemyGlobalStatList } from "../stats/entity-stat-list"
import { PetRescueEventSystem } from "../events/pet-rescue-gameplay-event";
import { StandInCirclePOI } from "../pois/stand-in-circle";
import { UI } from "../ui/ui";
import { debugtool } from "../utils/decorators";
import { InGameTime } from "../utils/time";
import { BinaryFlagStateDefinition, EnemySelector, MutatorDefinition, MutatorDefinitions, MutatorShortName, mutatorShortNames } from "./mutator-definitions";
import { MODDABLE_EVENT_DEFINITIONS } from "../events/gameplay-event-definitions";
import AISystem from "../entities/enemies/ai-system"
import { PropPlacer } from "../world-generation/prop-placement";
import { GameplayTimedEventSystem } from "../events/gameplay-timed-event-system"
import { Buff } from "../buffs/buff"
import { ChoreographedEventSpawner } from "../entities/enemies/choreographed-event-spawner"
import { createGenericChapterDifficultyMutator } from "./chapter-difficulty-definitions";
import { EventTypes } from "../events/event-types";

const URL_PARAM_KEY = 'mutators'

export class MutatorManager {
	private static instance: MutatorManager
	allMutators: MutatorDefinitions
	appliedMutators: Set<MutatorShortName>
	unappliedMutators: Set<MutatorShortName>

	static init(mutators: MutatorDefinitions) {
		if (!MutatorManager.instance) {
			MutatorManager.instance = new MutatorManager(mutators)
		}
	}
	static destroy() {
		MutatorManager.instance = null
		// do more??
	}

	// Unused - Remove this?
	static rollMutators(limit: number) {
		return sampleSize(Array.from(Object.values(this.instance.allMutators)), limit)
	}

	static applyMutatorEffects(mutator: MutatorDefinition) {
		mutator.mutationsToApply.forEach((mut) => {
			const { target, modifications } = mut

			if (target === 'enemySpawner') {
				EnemyEquilibriumSpawner.getInstance().addPacks(modifications)
			} else if (target === 'enemySpawnValues') {
				EnemyEquilibriumSpawner.getInstance().multiplySpawnValueMultipliers(modifications)
			} else if (target === 'events') {
				if (modifications === "pet-poi-stand-in-circle") {
					PetRescueEventSystem.getInstance().petRescueEventType = StandInCirclePOI
					if (Array.isArray(MODDABLE_EVENT_DEFINITIONS.pet.coolDown)) {
						MODDABLE_EVENT_DEFINITIONS.pet.coolDown[0] = ~~(MODDABLE_EVENT_DEFINITIONS.pet.coolDown[0] * 0.8)
						MODDABLE_EVENT_DEFINITIONS.pet.coolDown[1] = ~~(MODDABLE_EVENT_DEFINITIONS.pet.coolDown[1] * 0.8)
					} else {
						MODDABLE_EVENT_DEFINITIONS.pet.coolDown = ~~(MODDABLE_EVENT_DEFINITIONS.pet.coolDown * 0.8)
					}
				} else if (modifications === 'pet-poi-jail-break') {
					PetRescueEventSystem.getInstance().numberOfPetsPerEvent = 2
					PetRescueEventSystem.getInstance().enemySpawnRateInCircle += 1.5 // 150%
				} else if (modifications === 'goblin') {
					MODDABLE_EVENT_DEFINITIONS.goblin.excludedBy = [EventTypes.Goblin]
					MODDABLE_EVENT_DEFINITIONS.goblin.spawnWindow.length = 0
					// Goblin event lasts 35 seconds max, create a window every 40 seconds
					for (let i = 20; i < 900; i += 40) {
						MODDABLE_EVENT_DEFINITIONS.goblin.spawnWindow.push({min: i, max: i + 40})
					}
					MODDABLE_EVENT_DEFINITIONS.goblin.frequency = 0
				}
			} else if (target === 'addEvent') {
				GameplayTimedEventSystem.getInstance().addEventDefinition(modifications.event, modifications.startCondition)	
			} else if (target === 'gameLoop') {
				if (mutator.id === 'zoomies') {
					// Note, this doesn't compose, if/when we add more of these we'll have to combine them or something
					InGameTime.timeScale *= 1.333333
				} else {
					const timeScale = modifications as number
					InGameTime.timeScale *= timeScale
				}
			} else if (target === 'stats') {
				const mod = modifications
				mod.statChanges?.forEach(([statName, op, value]) => {
					GlobalStatList.addStatBonus(statName, op, value)
				})
				mod.binaryFlags?.forEach((flag) => {
					GameState.player.binaryFlags.add(flag)
				})
			} else if (target === 'player') {
				const mod = modifications[0] as any
				if (typeof mod === 'string' || mod instanceof String) {
					for (const modification of modifications) {
						GameState.player.binaryFlags.add(modification as PlayerBinaryFlags)
					}
				} else if (mod.statName) {
					for (const modification of modifications) {
						const stats = modification as StatBonusData
						GameState.player.stats.addStatBonus(stats.statName, stats.operatorType, stats.value)
					}
					GameState.player.heal(0) // jank to update max/current health
				} else {
					// binary flag state
					for (const modification of modifications) {
						const flagState = modification as BinaryFlagStateDefinition
						GameState.player.binaryFlagState[flagState.flag] = flagState.values
					}
				}

				if (mutator.id === 'bigHead') {
					GameState.player.applyBigHeadModel()
				}

			} else if (target === 'buffPlayer') {
				Buff.apply(modifications, GameState.player, GameState.player)
			} else if (target === 'enemies') {
				modifications.forEach(([selector, modifier, arg1, arg2, arg3]) => {
					const selectedEnemies = MutatorManager.getEnemyDefinitionsForSelector(selector)
					if (modifier === 'explosion') {
						const radius = arg1 as number
						const damagePercent = arg2 as number
						selectedEnemies.forEach((enemy) => {
							if (enemy.states.dead.behaviour !== DeadBehaviours.EXPLODE) {
								enemy.states.dead.behaviour = DeadBehaviours.TWIST_EXPLODE
								enemy.states.dead.explosionRadius = radius
								enemy.states.dead.explosionDamage = damagePercent
								enemy.states.dead.explosionPfxConfig = `plot-twist-explosion`
							}
						})
					} else if (modifier === 'change-size') {
						const scale = arg1 as number
						selectedEnemies.forEach((enemy) => {
							if(!enemy.appearance.scale) {
								enemy.appearance.scale = 1
							}
							enemy.appearance.scale *= scale
							enemy.states.dead.explosionRadius *= scale
						})
					} else if (modifier === 'change-health') {
						const multi = arg1 as number
						selectedEnemies.forEach((enemy) => {
							enemy.baseAttributes.baseStats.maxHealth *= multi
							enemy.states.dead.explosionDamage /= multi // keep explosion damage not ridiculous
							console.log(`${enemy.name} health set to ${multi} => ${enemy.baseAttributes.baseStats.maxHealth}`)
						})
					} else if (modifier === 'spawn-on-death') {
						const amount = arg2 as number
						let delay: number
						let delayPerSpawn: number
						if (isArray(arg3)) {
							delay = arg3[0] as number
							delayPerSpawn = arg3[1] as number
						} else {
							delay = arg3 as number
						}

						let postSpawnMod: PostSpawnAfterDeathMod
						if (mutator.id === 'splitPersonality') {
							postSpawnMod = PostSpawnAfterDeathMod.HalfishHealthAndSize
						} else if (mutator.id === 'twiceDead') {
							postSpawnMod = PostSpawnAfterDeathMod.ReducedExp55
						}

						selectedEnemies.forEach((enemy) => {
							if (!enemy.states.dead.excludeFromModifications) {
								if (!enemy.states.dead.spawnAfterDeath?.length) {
									enemy.states.dead.spawnAfterDeath = []
								}
								const name = arg1 as ENEMY_NAME || enemy.name
								enemy.states.dead.spawnAfterDeath.push({ name, amount, delay, delayPerSpawn, postSpawnMod })
							}
						})
					} else if (modifier === 'function') {
						const fn = arg1 as unknown as any
						selectedEnemies.forEach(fn)
					} else if (modifier === 'drop-amount-mult') {
						const mult = arg1 as number
						selectedEnemies.forEach((enemy) => {
							enemy.baseAttributes.bonusDropMult = mult
						})
					}
				})

			} else if (target === 'modifyEnemyDefinition') {
				GameClient.getInstance().enemyDefintions.forEach((ai) => {
					modifications(ai)
				})
			} else if (target === 'props') {
				PropPlacer.getInstance().addMutator(modifications)
			} else if (target === 'camera') {
				Camera.getInstance().addMutator(mutator, mut)
			} else if (target === 'choreo') {
				const spawnList = ChoreographedEventSpawner.getInstance().enemySpawnList
				spawnList.push(...modifications.choreoDef)
				spawnList.sort((a, b) => a.eventStartTime - b.eventStartTime)
				console.log(spawnList)
			} else if (target === 'recycler') {
				if (mutator.id === 'giantShamblers') {
					AISystem.getInstance().fuseRecycledShambers = true
					AISystem.getInstance().shamblerFusionRecycleCooldown = modifications.cooldown
					AISystem.getInstance().shamblerFusionRecycleCount = modifications.shamblerCount
				}
			} else if (target === 'replaceEnemies') {
				EnemyEquilibriumSpawner.getInstance().replaceEnemies(modifications)
			} else if (target === 'function') {
				modifications()
			} else if (target === 'modifyGrouping') {
				EnemyEquilibriumSpawner.getInstance().modifyGrouping(modifications)
			} else if (target === 'enemySpawnRate') {
				EnemyEquilibriumSpawner.getInstance().addEnemySpawnRateMultipliers(modifications)
			} else if (target === 'enemyStats') {
				let statLists: EntityStatList[]
				switch(modifications.enemyStatListSelector) {
					case 'all':
						statLists = [EnemyGlobalStatList]
						break
					case 'common':
						statLists = [CommonEnemyGlobalStatList]
						break
					case 'uncommon': 
						statLists = [UncommonEnemyGlobalStatList]
						break
					case 'boss':
						statLists = [BossEnemyGlobalStatList]
						break
					case 'nonBoss':
						statLists = [CommonEnemyGlobalStatList, UncommonEnemyGlobalStatList]
						break
					default:
						statLists = [EnemyGlobalStatList]
						console.warn('Invalid Enemy Stat List Target provided. Double check mutator definition')
				}
				modifications.statChanges.forEach(([statName, op, value]) => {
					statLists.forEach((statList) => {
						statList.addStatBonus(statName, op, value)
					})
				})
			} else if (target === 'levelUp') {
				const player = GameState.player
				const { level, callback } = modifications
				if (level === 'every') {
					player.addPlayerLevelUpCallback(callback)
				} else if (Number.isFinite(level) && level > 1) {
					player.addPlayerLevelUpCallback(callback, level)
				}
			} else if (target === 'pickups') {
				GameState.enemyGroundPickups.pushMany(modifications.enemyPickupModifier)
				GameState.propGroundPickups.pushMany(modifications.propPickupModifier)
			}
		})
	}

	static applyMutator(name: MutatorShortName, addToURL?: boolean) {
		if (!this.instance.appliedMutators.has(name)) {
			const mutator = this.instance.allMutators[name]
			this.instance.appliedMutators.add(name)
			this.instance.unappliedMutators.delete(name)
			this.applyMutatorEffects(mutator)
			
			if ((process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') || UI.getInstance().store.getters['user/isQa']) {
				if (addToURL) {
					const oldSearchParams = new URLSearchParams(window.location.search)
					oldSearchParams.set(URL_PARAM_KEY, JSON.stringify(Array.from(this.instance.appliedMutators)))

					const newURL = window.location.origin + '/?' + oldSearchParams.toString()

					window.history.replaceState(null, null, newURL)
				}
			}
		}
		// Currently for the debug UI
		UI.getInstance().emitMutation('ui/updateMutators', MutatorManager.instance.appliedMutators.keys())
	}

	constructor(mutators: MutatorDefinitions) {
		this.allMutators = mutators
		this.appliedMutators = new Set()
		this.unappliedMutators = new Set(mutatorShortNames)
	}

	static getEnemyDefinitionsForSelector(selector: EnemySelector): EnemyAI[] {
		if (selector === 'all') {
			return GameClient.getInstance().enemyDefintions
		}

		const defs: EnemyAI[] = []
		GameClient.getInstance().enemyDefintions.forEach((ai: EnemyAI) => {
			if (selector.includes(ai.name)) {
				defs.push(ai)
			}
		})

		return defs
	}

	static setGenericChapterDifficultyMutator(remainingChapters: number) {
		if (remainingChapters > 0) {
			const mutator = createGenericChapterDifficultyMutator(remainingChapters)
			this.instance.allMutators[mutator.id] = mutator
		}
	}

	@debugtool
	static getAvailableMutators() {
		if (!this.instance) {
			return []
		}
		console.log('returning')
		return [...this.instance.unappliedMutators.values()]
	}
}