import { AnimatedSprite } from "pixi.js"
import { Vector } from "sat"
import { AllWeaponTypes } from "../weapons/weapon-types"
import { ColliderComponent } from "../engine/collision/collider-component"
import { CollisionLayerBits } from "../engine/collision/collision-layers"
import CollisionSystem from "../engine/collision/collision-system"
import { GameState } from "../engine/game-state"
import { Renderer } from "../engine/graphics/renderer"
import { timeInSeconds, timeInMilliseconds, gameUnits } from "../utils/primitive-types"
import { ObjectPoolTyped, PoolableObject } from "../utils/third-party/object-pool"
import { Enemy } from "./enemies/enemy"
import { EntityType } from "./entity-interfaces"
import { SpritesheetAnimatorComponent } from "../engine/graphics/spritesheet-animator-component"
import { AssetManager } from "../web/asset-manager"
import { ComponentOwner } from "../engine/component-owner"
import { getVisibleWorldHeight } from "../engine/graphics/camera-logic"
import { Audio } from "../engine/audio"
import AISystem from "./enemies/ai-system"
import { sampleSize } from "lodash"
import { InstancedAnimatedSprite } from "../engine/graphics/instanced-animated-sprite"
import EntityStatList from "../stats/entity-stat-list"
import { DamageSource } from "../projectiles/damage-source"

const LIGHTNING_EFFECT_LIFETIME = 1.5

export type LightningStrikeAsset = 'lightning-strike' | 'dark-stormy-night'

export interface LightningStrikeParams {
	damageScale: number
	splashRadius: number
	attackKnockback: number
	targetEntity: Enemy
	statList?: EntityStatList
	weaponType?: AllWeaponTypes
	lightningAssetName?: LightningStrikeAsset
}

export class LightningStrike implements PoolableObject, ComponentOwner, DamageSource {
	static pool: ObjectPoolTyped<LightningStrike, LightningStrikeParams>
	triedToReturnToPool: boolean

	nid: number
	entityType: EntityType = EntityType.GroundHazard
	timeScale: number = 1

	position: Vector
	splashRadius: number
	attackKnockback: number

	statList: EntityStatList
	damageScale: number
	remainingLifetime: number

	lightningAssetName: LightningStrikeAsset = 'lightning-strike'
	visuals: SpritesheetAnimatorComponent
	// Need a direct reference to the lightning sprite from the above SpriteSheetAnimatorComponent to do position-based scaling
	lightningSprite: InstancedAnimatedSprite

	weaponType: AllWeaponTypes
	numEntitiesChained: number = 0
	numEntitiesPierced: number = 0

	showImmediateDamageNumber: boolean = false

	static strikeRandomEnemies(enemyList: Enemy[], count: number, statList: EntityStatList, radius: number, knockback: number, damageScale: number = 1.0, lightningAssetName: LightningStrikeAsset = 'lightning-strike', weaponType?: AllWeaponTypes) {
		if (!enemyList.length) {
			return
		}
		const selectedEnemies: Enemy[] = sampleSize(enemyList, count)
		selectedEnemies.forEach((enemy) => {
			LightningStrike.strikeEnemy(enemy, statList, radius, knockback, damageScale, lightningAssetName, weaponType)
		})
	}

	static strikeEnemiesOnScreen(count: number, statList: EntityStatList, radius: number, knockback: number, damageScale: number = 1.0, lightningAssetName: LightningStrikeAsset = 'lightning-strike', weaponType?: AllWeaponTypes) {
		const enemiesOnScreen = AISystem.getInstance().getOnScreenEnemies()
		LightningStrike.strikeRandomEnemies(enemiesOnScreen, count, statList, radius, knockback, damageScale, lightningAssetName, weaponType)
	}

	static strikeEnemy(enemy: Enemy, statList: EntityStatList, radius: number, knockback: number, damageScale: number = 1.0, lightningAssetName: LightningStrikeAsset = 'lightning-strike', weaponType?: AllWeaponTypes) {
		LightningStrike.pool.alloc({
			damageScale: damageScale,
			statList: statList,
			splashRadius: radius,
			targetEntity: enemy,
			attackKnockback: knockback,
			lightningAssetName,
			weaponType
		})
	}

	constructor() {
		this.position = new Vector()
		this.remainingLifetime = LIGHTNING_EFFECT_LIFETIME
		this.triedToReturnToPool = false

		this.makeVisuals()
	}

	setDefaultValues(defaultValues: any, overrideValues?: LightningStrikeParams) {
		if (overrideValues) {
			this.splashRadius = overrideValues.splashRadius
			this.damageScale = overrideValues.damageScale
			this.statList = overrideValues.statList
			this.attackKnockback = overrideValues.attackKnockback

			this.position.copy(overrideValues.targetEntity.position)
			this.weaponType = overrideValues.weaponType === undefined ? null : overrideValues.weaponType

			this.remainingLifetime = LIGHTNING_EFFECT_LIFETIME
			this.triedToReturnToPool = false

			this.lightningAssetName = overrideValues.lightningAssetName || 'lightning-strike'

			GameState.addEntity(this)
			this.setVisuals()
			this.hitEnemy()
		}
	}

	cleanup() {
		this.lightningSprite.scale.y = 1
		this.visuals.removeFromScene()
		this.statList = null

		GameState.removeEntity(this)
	}

	update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		this.visuals.update(delta)
		this.remainingLifetime -= delta
		if (!this.triedToReturnToPool && this.remainingLifetime <= 0) {
			this.returnToPool()
			this.triedToReturnToPool = true
		}
	}

	hitEnemy() {
		Audio.getInstance().playSfx('SFX_Boss_Highlands_Magic_A', { volume: 0.3 })
		const enemiesNearby = CollisionSystem.getInstance().getEntitiesInArea(this.position, this.splashRadius, CollisionLayerBits.HitEnemyOnly)
		enemiesNearby.forEach((enemyCol: ColliderComponent) => {
			const enemy = enemyCol.owner as Enemy
			if (this.statList) {
				enemy.onHitByDamageSource(this, this.damageScale)
			} else {
				enemy.takeDamageSimple(this.damageScale, false)
			}
		})
		this.knockbackEffect(this.splashRadius, this.attackKnockback)
	}

	getKnockbackDirection(mutableEntityPos: Vector): Vector {
		return mutableEntityPos.sub(this.position).normalize()
	}

	knockbackEffect(radius, force) {
		Audio.getInstance().playSfx('SFX_Elemental_Fire') //TODO: Placeholder sfx
		CollisionSystem.getInstance().knockbackAOEfromPoint(this.position, radius, force)
	}

	makeVisuals() {
		const spriteSheet = AssetManager.getInstance().getAssetByName(this.lightningAssetName).spritesheet
		this.visuals = new SpritesheetAnimatorComponent(this, spriteSheet, 'lightning-strike', undefined, true)
		this.lightningSprite = this.visuals.spriteSheetAnimator.animatedSprites.get('lightning-strike')
		this.lightningSprite.animationSpeed = 0.25
	}

	setVisuals() {
		const lightningScale = this.getLightningScaleFromTarget(this.position.y)
		this.lightningSprite.scale.y *= lightningScale
		this.visuals.overrideZindex(Number.MIN_SAFE_INTEGER)
		this.visuals.addToScene()
		const scale = this.splashRadius / 175
		// this.visuals.container.scale.x = scale
		// this.visuals.container.scale.y = scale
		this.visuals.spriteSheetAnimator.playAnimationsConcurrently()
	}

	isPlayerOwned(): boolean {
		return true
	}

	returnToPool() {
		LightningStrike.pool.free(this)
	}

	// The default bolt takes up roughly half the screen, we need to scale its Y scale if it hits anywehere below the player's Y pos.
	private getLightningScaleFromTarget(targetY: number): number {
		const cameraState = Renderer.getInstance().cameraState
		const player = GameState.player
		const cameraHeight = getVisibleWorldHeight(cameraState.zoom) / 2
		const diffY = targetY - player.position.y
		const ratioY = Math.clamp(diffY / (cameraHeight), 0, 1)

		// Should work with just 1 + ratioY but addind a bit of extra scaling for good measure
		return 1.15 + ratioY
	}
}