import { InstancedSpriteSheetAnimator } from "../engine/graphics/instanced-spritesheet-animator"
import { POI } from "../pois/poi";
import { ObjectPool } from "../utils/third-party/object-pool"
import WeightedList from "../utils/weighted-list"
import { AssetManager } from "../web/asset-manager"
import { EventTypes } from "./event-types"
import { forestPlantShrines } from "./gameplay-event-definitions";
import { GameplayTimedEventSystem } from "./gameplay-timed-event-system";
import { ShrineGameplayEvent } from "./shrine-gameplay-event";

const PLANT_Z_OFFSETS = {
    xpFlower: 28,
    superXpFlower: 85,
    papyrusPlant: 61,
    magnetPinecone: 31
}

const PLANT_TIME_REGEN_SCALE = 0.45

export class ForestPlantShrineGameplayEvent extends ShrineGameplayEvent {

    shrineTypeWeightedList: WeightedList<string>

    flowerGfxPools: {
        xpFlower: ObjectPool
        superXpFlower: ObjectPool
        papyrusPlant: ObjectPool
        magnetPinecone: ObjectPool
    }

    lastEventPresetType: string

    constructor() {
        super()

        this.shrineTypeWeightedList = new WeightedList([
            ['xpFlower', 63],
            ['superXpFlower', 3],
            ['papyrusPlant', 22],
            ['magnetPinecone', 12],
        ])

        const papyrusSheet = AssetManager.getInstance().getAssetByName('papyrus-plant').spritesheet
        const magnetSheet = AssetManager.getInstance().getAssetByName('magnet-pinecone').spritesheet
        const xpFlowerSheet = AssetManager.getInstance().getAssetByName('xp-flower').spritesheet
        const superXpFlowerSheet = AssetManager.getInstance().getAssetByName('super-xp-flower').spritesheet
    
        this.flowerGfxPools = {} as any

        this.flowerGfxPools.xpFlower = new ObjectPool(() => {
            return new InstancedSpriteSheetAnimator(xpFlowerSheet, 'idle')
        }, {}, 4, 1, 'xpFlowerPool')

        this.flowerGfxPools.superXpFlower = new ObjectPool(() => {
            return new InstancedSpriteSheetAnimator(superXpFlowerSheet, 'idle')
        }, {}, 1, 1, 'superXpFlowerPool')

        this.flowerGfxPools.papyrusPlant = new ObjectPool(() => {
            return new InstancedSpriteSheetAnimator(papyrusSheet, 'idle')
        }, {}, 3, 1, 'papyrusPool')

        this.flowerGfxPools.magnetPinecone = new ObjectPool(() => {
            return new InstancedSpriteSheetAnimator(magnetSheet, 'idle')
        }, {}, 1, 1, 'magnetSheet')

        this.poiParams.restoreDurationWhenNotInZone = true
        this.poiParams.notInZoneTimeScale = PLANT_TIME_REGEN_SCALE
    }

    override setRandomPreset(): void {
        this.lastEventPresetType = this.shrineTypeWeightedList.pickRandom().value[0]
        this.eventPresetConfig = forestPlantShrines[this.lastEventPresetType]
    }
    
    override startEvent(): void {
        super.startEvent()

        // place a plant at the POI
        const gfxPool = this.flowerGfxPools[this.lastEventPresetType] as ObjectPool
        const sprite: InstancedSpriteSheetAnimator = gfxPool.alloc()

        this.activePoi.state.sprite = sprite
        this.activePoi.state.gfxPool = gfxPool

        const poiPos = this.activePoi.position
        sprite.position.copy(poiPos)
        sprite.zIndex = poiPos.y + PLANT_Z_OFFSETS[this.lastEventPresetType]
        sprite.playAnimation('idle')
        sprite.addToScene()

        // pretend to finish straight away so we place more plants
        GameplayTimedEventSystem.getInstance().onEventEnd(EventTypes.ForestPlants)
    }

    protected onEventDoneInternal(poi: POI) {
        const sprite: InstancedSpriteSheetAnimator = poi.state.sprite
        const gfxPool = poi.state.gfxPool

        sprite.playAnimation('destroyed', undefined, () => {
            sprite.removeFromScene()
            gfxPool.free(sprite)

            poi.freeFromPool()
        })
	}
}
