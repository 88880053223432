import { GameState } from "../engine/game-state";
import { EnemyAI } from "../entities/enemies/ai-types";
import EnemyEquilibriumSpawner from "../entities/enemies/enemy-equilibrium-spawner";
import { COMMON_ENEMY_NAMES, UNCOMMON_ENEMY_NAMES } from "../entities/enemies/enemy-names";
import { Player } from "../entities/player";
import { StoryDifficulty } from "../game-data/difficulties";
import { EnemyGlobalStatList, StatBonus } from "../stats/entity-stat-list";
import { StatOperator, StatType } from "../stats/stat-interfaces-enums";
import { MutatorDefinition, MutatorDefinitions, MutatorShortName } from "./mutator-definitions";
import { MutatorManager } from "./mutator-manager";

export const CHAPTER_DIFFICULTY_MUTATOR_SHORTNAMES = [
	// 'chapter_enemyHealth1',
	// 'chapter_enemyHealth2',
	// 'chapter_enemyHealth3',
	// 'chapter_bossHealth1',
	// 'chapter_bossHealth2',
	// 'chapter_commonSpawnRate1',
	// 'chapter_uncommonSpawnRate1',
	// 'chapter_uncommonSpawnRate2',
	// 'chapter_uncommonSpawnRate3',
	// 'chapter_enemyMovementSpeed1',
	// 'chapter_xpRequirements1',
	// 'chapter_xpRequirements2',
	// 'chapter_bossDamageCommonSpawnRate1',
	// 'chapter_enemyDamageAndMovementSpeed1',
	// 'chapter_bossHealthAndDamage1',
	'chapter_generic'
]

// Index alligned to each chapter
export const MUTATOR_FOR_CHAPTER = [
	null,
	// 'chapter_enemyHealth1',
	// 'chapter_uncommonSpawnRate1',
	// 'chapter_bossHealth1',
	// 'chapter_enemyMovementSpeed1',
	// 'chapter_bossDamageCommonSpawnRate1',
	// 'chapter_xpRequirements1',
	// 'chapter_uncommonSpawnRate2',
	// 'chapter_enemyHealth2',
	// 'chapter_bossHealth2',
	// 'chapter_enemyDamageAndMovementSpeed1',
	// 'chapter_uncommonSpawnRate3',
	// 'chapter_commonSpawnRate1',
	// 'chapter_enemyHealth3',
	// 'chapter_xpRequirements2',
	// 'chapter_bossHealthAndDamage1'
]

export function getChapterDifficultyMutators(currentChapter: number): string[] {
	const bespokeMutatorCount = MUTATOR_FOR_CHAPTER.length - 1
	const lastMutatorIndex = Math.min(bespokeMutatorCount + 1, currentChapter + 1)

	const validMutators = MUTATOR_FOR_CHAPTER.slice(1, lastMutatorIndex)

	const remainingChapters = Math.max(currentChapter - validMutators.length, 0)
	if (remainingChapters) {
		MutatorManager.setGenericChapterDifficultyMutator(remainingChapters)
		validMutators.push('chapter_generic')
	}

	return validMutators
}

// Generate a chapter difficulty mutator based on the number of chapters beyond what we have defined in MUTATOR_FOR_CHAPTER
export function createGenericChapterDifficultyMutator(remainingChapters: number): MutatorDefinition {
	const chapter_generic: MutatorDefinition = {
		id: 'chapter_generic',
		name: "Generic Chapter Mutator",
		description: "N/A",
		icon: 'twist-na',
		mutationsToApply: [
			{
				target: 'enemyStats',
				modifications:{ 
					enemyStatListSelector: 'nonBoss',
					statChanges: [
						[StatType.maxHealth, StatOperator.SUM_THEN_MULTIPLY, 0.035 * remainingChapters],
						[StatType.movementSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.0033 * remainingChapters]
					],
				}
			},
			{
				target: 'enemyStats',
				modifications:{ 
					enemyStatListSelector: 'boss',
					statChanges: [
						[StatType.maxHealth, StatOperator.SUM_THEN_MULTIPLY, 0.06 * remainingChapters],
					],
				}
			},
			{
				target: 'enemySpawnRate',
				modifications: {
					enemies: [
						...COMMON_ENEMY_NAMES
					],
					spawnRateMulit: 0.03 * remainingChapters
				}
			},
			{
				target: 'enemySpawnRate',
				modifications: {
					enemies: [
						...UNCOMMON_ENEMY_NAMES
					],
					spawnRateMulit: 0.06 * remainingChapters
				}
			},
			{
				target: 'levelUp',
				modifications: {
					level: 5,
					callback: (player: Player) => {
						player.stats.addStatBonus(StatType.xpValueMulti, StatOperator.SUM_THEN_MULTIPLY, Math.max(-0.2, -0.005 * remainingChapters))
					}
				}
			}
		],
	}
	return chapter_generic
}

export const CHAPTER_DIFFICULTY_MUTATOR_DEFINITIONS: Partial<MutatorDefinitions> = {
	// 'chapter_enemyHealth1': {
	// 	id: 'chapter_enemyHealth1',
	// 	name: 'Enemy Health 1',
	// 	description: "N/A",
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'enemyStats',
	// 			modifications:{ 
	// 				enemyStatListSelector: 'nonBoss',
	// 				statChanges: [
	// 					[StatType.maxHealth, StatOperator.SUM_THEN_MULTIPLY, 0.2],
	// 				],
	// 			}
	// 		},
	// 	],
	// },
	// 'chapter_uncommonSpawnRate1': {
	// 	id: 'chapter_uncommonSpawnRate1',
	// 	name: 'Uncommon Enemy Spawn Rate 1',
	// 	description: 'N/A',
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'enemySpawnRate',
	// 			modifications: {
	// 				enemies: [
	// 					...UNCOMMON_ENEMY_NAMES
	// 				],
	// 				spawnRateMulit: 0.3
	// 			}
	// 		},
	// 	],
	// },
	// 'chapter_bossHealth1': {
	// 	id: 'chapter_bossHealth1',
	// 	name: 'Boss Health 1',
	// 	description: 'N/A',
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'enemyStats',
	// 			modifications:{ 
	// 				enemyStatListSelector: 'boss',
	// 				statChanges: [
	// 					[StatType.maxHealth, StatOperator.SUM_THEN_MULTIPLY, 0.4],
	// 				],
	// 			}
	// 		},
	// 	],
	// },
	// 'chapter_enemyMovementSpeed1': {
	// 	id: 'chapter_enemyMovementSpeed1',
	// 	name: 'Enemy Movement Speed 1',
	// 	description: 'N/A',
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'enemyStats',
	// 			modifications:{ 
	// 				enemyStatListSelector: 'all',
	// 				statChanges: [
	// 					[StatType.movementSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.05],
	// 				],
	// 			}
	// 		},
	// 		{
				
	// 				target: 'player',
	// 				modifications: [{statName: StatType.attackKnockback, operatorType: StatOperator.MULTIPLY, value: 0.08}]
				
	// 		},
	// 	],
	// },
	// 'chapter_bossDamageCommonSpawnRate1': {
	// 	id: 'chapter_bossDamageCommonSpawnRate1',
	// 	name: 'Boss Damage and Common Enemy Spawn Rate 1',
	// 	description: 'N/A',
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'function',
	// 			modifications: () => {
	// 				let extraDamage = 0
	// 				switch(GameState.storyDifficulty) {
	// 					case StoryDifficulty.Normal:
	// 						extraDamage = 1
	// 						break
	// 					case StoryDifficulty.Hard:
	// 						extraDamage = 1
	// 						break
	// 					case StoryDifficulty.Brutal:
	// 						extraDamage = 2
	// 						break
	// 				}
					
	// 				const player = GameState.player
	// 				player.binaryFlags.add('take-extra-boss-damage')
	// 				player.binaryFlagState['take-extra-boss-damage'].extraDamage += extraDamage
	// 			},
	// 		},
	// 		{
				
	// 			target: 'function',
	// 			modifications: () => {
	// 				let spawnRateMulti = 1
	// 				switch(GameState.storyDifficulty) {
	// 					case StoryDifficulty.Normal:
	// 						spawnRateMulti = 0.2
	// 						break
	// 					case StoryDifficulty.Hard:
	// 						spawnRateMulti = 0.35
	// 						break
	// 					case StoryDifficulty.Brutal:
	// 						spawnRateMulti = 0.2
	// 						break
	// 				}
	// 				const mod = {
	// 					enemies: [ ...COMMON_ENEMY_NAMES ],
	// 					spawnRateMulit: spawnRateMulti
	// 				}
	// 				EnemyEquilibriumSpawner.getInstance().addEnemySpawnRateMultipliers(mod)
	// 			}
	// 		},

	// 	],
	// },
	// 'chapter_xpRequirements1': {
	// 	id: 'chapter_xpRequirements1',
	// 	name: 'Level up XP Requirements 1',
	// 	description: 'N/A',
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'levelUp',
	// 			modifications: {
	// 				level: 5,
	// 				callback: (player: Player) => {
	// 					player.stats.addStatBonus(StatType.xpValueMulti, StatOperator.SUM_THEN_MULTIPLY, -0.075)
	// 				}
	// 			}
	// 		}
	// 	],
	// },
	// 'chapter_uncommonSpawnRate2': {
	// 	id: 'chapter_uncommonSpawnRate2',
	// 	name: 'Uncommon Enemy Spawn Rate 2',
	// 	description: 'N/A',
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'enemySpawnRate',
	// 			modifications: {
	// 				enemies: [
	// 					...UNCOMMON_ENEMY_NAMES
	// 				],
	// 				spawnRateMulit: 0.35
	// 			}
	// 		},
	// 	],
	// },
	// 'chapter_enemyHealth2': {
	// 	id: 'chapter_enemyHealth2',
	// 	name: 'Enemy Health 2',
	// 	description: "N/A",
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'enemyStats',
	// 			modifications:{ 
	// 				enemyStatListSelector: 'nonBoss',
	// 				statChanges: [
	// 					[StatType.maxHealth, StatOperator.SUM_THEN_MULTIPLY, 0.25],
	// 				],
	// 			}
	// 		},
	// 	],
	// },
	// 'chapter_bossHealth2': {
	// 	id: 'chapter_bossHealth2',
	// 	name: 'Boss Health 2',
	// 	description: 'N/A',
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'enemyStats',
	// 			modifications:{ 
	// 				enemyStatListSelector: 'boss',
	// 				statChanges: [
	// 					[StatType.maxHealth, StatOperator.SUM_THEN_MULTIPLY, 0.4],
	// 				],
	// 			}
	// 		},
	// 	],
	// },
	// 'chapter_enemyDamageAndMovementSpeed1': {
	// 	id: 'chapter_enemyDamageAndMovementSpeed1',
	// 	name: 'Enemy damage and movement speed 1',
	// 	description: 'N/A',
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'function',
	// 			modifications: () => {
	// 				let extraDamage = 0
	// 					switch(GameState.storyDifficulty) {
	// 						case StoryDifficulty.Normal:
	// 							extraDamage = 0
	// 							break
	// 						case StoryDifficulty.Hard:
	// 							extraDamage = 1
	// 							break
	// 						case StoryDifficulty.Brutal:
	// 							extraDamage = 1
	// 							break
	// 					}
	// 					const player = GameState.player
	// 					player.binaryFlags.add('take-extra-enemy-damage')
	// 					player.binaryFlagState['take-extra-enemy-damage'].extraDamage += extraDamage
	// 			}
	// 		},
	// 		{
	// 			target: 'function',
	// 			modifications: () => {
	// 				let movementSpeedMulti = 0
	// 					switch(GameState.storyDifficulty) {
	// 						case StoryDifficulty.Normal:
	// 							movementSpeedMulti = 0.05
	// 							break
	// 						case StoryDifficulty.Hard:
	// 							movementSpeedMulti = 0.025
	// 							break
	// 						case StoryDifficulty.Brutal:
	// 							movementSpeedMulti = 0.05
	// 							break
	// 					}
	// 					EnemyGlobalStatList.addStatBonus(StatType.movementSpeed, StatOperator.SUM_THEN_MULTIPLY, movementSpeedMulti)
	// 			}
	// 		},
	// 		{
				
	// 			target: 'player',
	// 			modifications: [{statName: StatType.attackKnockback, operatorType: StatOperator.MULTIPLY, value: 0.08}]
	// 		},
	// 	],
	// },
	// 'chapter_uncommonSpawnRate3': {
	// 	id: 'chapter_uncommonSpawnRate3',
	// 	name: 'Uncommon Enemy Spawn Rate 3',
	// 	description: 'N/A',
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'enemySpawnRate',
	// 			modifications: {
	// 				enemies: [
	// 					...UNCOMMON_ENEMY_NAMES
	// 				],
	// 				spawnRateMulit: 0.45
	// 			}
	// 		},
	// 	],
	// },
	// 'chapter_commonSpawnRate1': {
	// 	id: 'chapter_commonSpawnRate1',
	// 	name: 'Common Enemy Spawn Rate 1',
	// 	description: 'N/A',
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'enemySpawnRate',
	// 			modifications: {
	// 				enemies: [
	// 					...COMMON_ENEMY_NAMES
	// 				],
	// 				spawnRateMulit: 0.25
	// 			}
	// 		},
	// 	],
	// },
	// 'chapter_enemyHealth3': {
	// 	id: 'chapter_enemyHealth3',
	// 	name: 'Enemy Health 3',
	// 	description: "N/A",
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'enemyStats',
	// 			modifications:{ 
	// 				enemyStatListSelector: 'nonBoss',
	// 				statChanges: [
	// 					[StatType.maxHealth, StatOperator.SUM_THEN_MULTIPLY, 0.3],
	// 				],
	// 			}
	// 		},
	// 	],
	// },
	// 'chapter_xpRequirements2': {
	// 	id: 'chapter_xpRequirements2',
	// 	name: 'Level up XP Requirements 2',
	// 	description: 'N/A',
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'levelUp',
	// 			modifications: {
	// 				level: 5,
	// 				callback: (player: Player) => {
	// 					player.stats.addStatBonus(StatType.xpValueMulti, StatOperator.SUM_THEN_MULTIPLY, -0.075)
	// 				}
	// 			}
	// 		}
	// 	],
	// },
	// 'chapter_bossHealthAndDamage1': {
	// 	id: 'chapter_bossHealthAndDamage1',
	// 	name: 'Boss Health and Damage 1',
	// 	description: 'N/A',
	// 	icon: 'twist-na',
	// 	mutationsToApply: [
	// 		{
	// 			target: 'enemyStats',
	// 			modifications:{ 
	// 				enemyStatListSelector: 'boss',
	// 				statChanges: [
	// 					[StatType.maxHealth, StatOperator.SUM_THEN_MULTIPLY, 0.4],
	// 				],
	// 			}
	// 		},
	// 		{
	// 			target: 'player',
	// 			modifications: ['take-extra-boss-damage']
	// 		},
	// 		{
	// 			target: 'player',
	// 			modifications: [
	// 				{
	// 					flag: 'take-extra-boss-damage',
	// 					values: {
	// 						extraDamage: 1
	// 					}
	// 				}
	// 			]
	// 		}
	// 	],
	// },
	// This empty mutator will be replaced by one that we generate at runtime if players have reached a chapter beyond what we have defined in MUTATOR_FOR_CHAPTER
	'chapter_generic': {
		id: 'chapter_generic',
		name: 'Generic Chapter Mutator',
		description: 'N/A',
		isDebug: true,
		icon: 'twist-na',
		mutationsToApply:[
		]
	}

}

