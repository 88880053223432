import { percentage } from "../utils/primitive-types"

export const WORLD_DATA = {
    // the dimension props and ground stamps generate in -- the world is recycled beyond that
    infiniteWorldDimension: 25_000, // no idea what a good number for this is
    maxYInVerticalMap: (25_000 / 2) + 1_200,

    screenHeight: 2_100, // only used in enemy event spawning atm
    screenWidth: 3_650, // ^^
}

export type WeightedPropChances = Array<[string, number]>

export enum GenerationType {
    Infinite,
    Vertical
}

export interface GenerationConfig {
    type: GenerationType
}

export interface InfiniteConfig extends GenerationConfig {
    type: GenerationType.Infinite
}

export interface VerticalScrollConfig extends GenerationConfig {
    type: GenerationType.Vertical
    width: number
    wallAssetName: string
}

export type GenerationTypes = InfiniteConfig | VerticalScrollConfig

export type MapConfig = {
	mapType: MapOption
    propCellDimension: number
    propCellPadding: number

    bigPropChances: WeightedPropChances
    bigPropIndexOfNull: number
    bigPropAccompanyChances: Array<[number, number]>
    accompanimentProps: WeightedPropChances
    accompanimentMaxPropRange: number

    soloPropChances: WeightedPropChances
    
    stampCellDimension: number
    stampCellPadding: number
    groundStampChances: WeightedPropChances

    grassTexture: string
    groundZoom: number
    ambienceEffect?:string
    statusProps?: WeightedPropChances
    generationConfig: GenerationTypes

	currencyMultiplier: percentage
}

export enum MapOption {
    Forest = 'forest',
    Fungi = 'fungi',
    Hollow = 'hollow',
    Tundra = 'tundra'
}

export const mapNames = {
    FOREST: 'forest',
    FUNGI: 'fungi',
    HOLLOW: 'hollow',
    TUNDRA: 'tundra'
} as const

export type MapNames = typeof mapNames[keyof typeof mapNames]


const stringToEnum: Record<MapNames, MapOption> = {
    'forest': MapOption.Forest,
    'fungi': MapOption.Fungi,
    'hollow': MapOption.Hollow,
    'tundra': MapOption.Tundra
}

export function stringNameToMapOption(map: MapNames): MapOption {
    return stringToEnum[map];
}

export const MapConfigRecord: Record<MapOption, MapConfig> = {
    [MapOption.Forest]: {
		mapType: MapOption.Forest,
        // These get joined by other props around them
        bigPropChances: [
            [null, 1000],
            ['chest.png', 0],
            ['flower-pot.png', 0],
            ['tree-01.png', 40], // the rare tree with glowy bits
            ['tree-02.png', 55], // stubby yellow tree with books in the leaves
            ['tree-03.png', 40], // tall brown tree with books on the ground
            ['yellow-mushroom-02.png', 20],
            ['green-mushroom-02.png', 20],
            ['barrel', 40],
            ['lantern', 15],
            ['tanning-rack', 15],
            ['flower-pot', 15],
            ['chest', 15],
            ['papyrus-plant', 0],
            ['magnet-pinecone', 0],
        ],
        bigPropIndexOfNull: 0, // this refers to the [null, 1000], line above

        // when a big prop is spawned, roll on this table for the amount of props to join them
        // number of props to join a big prop, then weight
        bigPropAccompanyChances: [
            [0, 1],
            [1, 2],
            [2, 3],
            [3, 3],
            [4, 2]
        ],
        
        // The props that can join a big prop when it spawns
        accompanimentProps: [
            ['small-grass-01.png', 3],
            ['small-grass-02.png', 3],
            ['small-grass-03.png', 3],
            ['small-green-group.png', 1],
            ['small-yellow-group.png', 1],
            ['god-rays-forest', 0.35]
        ],
        
        // how far away do ALL joining props spawn at MOST from the big prop? 
        // (this distance gets increased as well by the MIN distance from configs found in prop-config !!)
        accompanimentMaxPropRange: 150,

        // props that spawn without anything joining them (when a big prop is not in that cell)
        soloPropChances: [
            [null, 70],
            ['small-grass-01.png', 3],
            ['small-grass-02.png', 3],
            ['small-grass-03.png', 3],
            ['small-rocks-01.png', 1],
            ['small-rocks-02.png', 1],
        ],

        // Ground stamps, unrelated to the above
        groundStampChances: [
            [null, 120],
            ['grass-01.png', 80],
            ['grass-02.png', 75],
            ['grass-03.png', 65],
            ['grass-04.png', 55],
            ['sand-02.png', 40],
            ['sand-01.png', 25],
        ],

        // every [dimension + padding] units is a cell,
        // and has a [chance] to have a prop in the [dimension] area of the cell
        propCellDimension: 350,
        propCellPadding: 100,
        // same as above, but ground stamps
        stampCellDimension: 700,
        stampCellPadding: 250,

        // texture of ground
        grassTexture: 'grass-tile',
        // Zoom level of the ground (grass) texture. Lower = more zoomed in 
        groundZoom: 1,
        ambienceEffect: 'forest-ambience',

        generationConfig: {
            type: GenerationType.Infinite
        },

		currencyMultiplier: 1.0,
    },
    [MapOption.Fungi]: {
		mapType: MapOption.Fungi,
        // These get joined by other props around them
        bigPropChances: [
            [null, 1000],
            ['fungi-tree-01.png', 40], // the rare tree with glowy bits
            ['fungi-tree-02.png', 55], // stubby yellow tree with books in the leaves
            ['fungi-tree-03.png', 40], // tall brown tree with books on the ground
            //['fungi-stump.png', 20],//
            ['green-bone-mushrooms.png', 20],
            ['log', 40],
            ['fungi-lantern-post', 15],
            ['skull', 15],
            ['enchanted-stone', 10],
        ],
        bigPropIndexOfNull: 0, // this refers to the [null, 1000], line above

        // when a big prop is spawned, roll on this table for the amount of props to join them
        // number of props to join a big prop, then weight
        bigPropAccompanyChances: [
            [0, 1],
            [1, 2],
            [2, 3],
            [3, 3],
            [4, 2]
        ],
        
        // The props that can join a big prop when it spawns
        accompanimentProps: [
            ['small-fungi-grass-01.png', 3],
            ['small-fungi-stone-01.png', 1],
            ['small-fungi-grass-03.png', 1],
            ['small-red-mushrooms.png', 1],
            ['small-fungi-stone-02.png', 1],
            ['god-rays-fungi', 0.05],
        ],
        
        // how far away do ALL joining props spawn at MOST from the big prop? 
        // (this distance gets increased as well by the MIN distance from configs found in prop-config !!)
        accompanimentMaxPropRange: 150,

        // props that spawn without anything joining them (when a big prop is not in that cell)
        soloPropChances: [
            [null, 70],
            ['small-fungi-grass-01.png', 8],
            ['small-fungi-grass-02.png', 3],
            ['small-fungi-grass-03.png', 1],
            ['small-fungi-stone-01.png', 1],
            ['small-fungi-stone-02.png', 2],
            ['small-fungi-stone-03.png', 1],
        ],

        
        // Ground stamps, unrelated to the above
        groundStampChances: [
            [null, 120],
            ['fungi-grass-01.png', 50],
            ['fungi-grass-02.png', 75],
            ['fungi-grass-03.png', 60],
            ['puddle-01.png', 5],
            ['puddle-02.png', 40],
            ['puddle-03.png', 25],
        ],

        // every [dimension + padding] units is a cell,
        // and has a [chance] to have a prop in the [dimension] area of the cell
        propCellDimension: 350,
        propCellPadding: 100,
        // same as above, but ground stamps
        stampCellDimension: 600,
        stampCellPadding: 250,

        // texture of ground
        grassTexture: 'fungi-grass-tile',
        // Zoom level of the ground (grass) texture. Lower = more zoomed in 
        groundZoom: 1,
        ambienceEffect: 'fungi-ambience',

        generationConfig: {
            type: GenerationType.Infinite
        },

		currencyMultiplier: 1.0,
    },
    [MapOption.Hollow]: {
		mapType: MapOption.Hollow,
        // These get joined by other props around them
        bigPropChances: [
            [null, 1000],
            ['hollow-tree-01.png', 40], // thick tree with a bunch of green in the middle
            ['hollow-tree-02.png', 55], // tree with face and cobwebs
            ['hollow-tree-03.png', 40], // tree with face thats like aaaaa
            ['med-skull-pile-01.png', 20], 
            ['impaled-skelly-01.png', 30],
            ['fire-pit', 40],
            ['banner', 15],
            ['cursed-gem', 15],
            ['cursed-tome', 15],
            ['stone-eyebat', 15],
        ],
        bigPropIndexOfNull: 0, // this refers to the [null, 1000], line above

        // when a big prop is spawned, roll on this table for the amount of props to join them
        // number of props to join a big prop, then weight
        bigPropAccompanyChances: [
            [0, 1],
            [1, 2],
            [2, 3],
            [3, 3],
            [4, 2]
        ],
        
        // The props that can join a big prop when it spawns
        accompanimentProps: [
            ['small-roots-01.png', 1],
            ['small-lantern-01.png', 1],
            ['small-roots-02.png', 3],
            ['small-roots-03.png', 3],
            ['small-stones-01.png', 2],
            ['fog-hollow', 0.05],
        ],
        
        // how far away do ALL joining props spawn at MOST from the big prop? 
        // (this distance gets increased as well by the MIN distance from configs found in prop-config !!)
        accompanimentMaxPropRange: 150,

        // props that spawn without anything joining them (when a big prop is not in that cell)
        soloPropChances: [
            [null, 70],
            ['small-roots-01.png', 2],
            ['small-grass-arrow-01.png', 2],
            ['small-lantern-01.png', 1],
            ['small-roots-02.png', 4],
            ['small-skull-pile-01.png', 3],
            ['med-skull-pile-01.png', 2],
            ['small-stones-01.png', 2],
        ],

        
        // Ground stamps, unrelated to the above
        groundStampChances: [
            [null, 120],
            ['hollow-grass-01.png', 80],
            ['hollow-grass-02.png', 75],
            ['hollow-grass-03.png', 90],
            ['hollow-grass-04.png', 55],
            ['hollow-sand-02.png', 60],
            ['hollow-sand-01.png', 25],
        ],

        // every [dimension + padding] units is a cell,
        // and has a [chance] to have a prop in the [dimension] area of the cell
        propCellDimension: 350,
        propCellPadding: 100,
        // same as above, but ground stamps
        stampCellDimension: 700,
        stampCellPadding: 250,

        // texture of ground
        grassTexture: 'hollow-grass-tile',
        // Zoom level of the ground (grass) texture. Lower = more zoomed in 
        groundZoom: 1,
        ambienceEffect: 'hollow-ambience',

        generationConfig: {
            type: GenerationType.Infinite
        },

		currencyMultiplier: 1.0,
    },
    [MapOption.Tundra]: {
		mapType: MapOption.Tundra,
        // These get joined by other props around them
        bigPropChances: [
            [null, 1000],
            ['icy-tree-01.png', 30], // no branches, borked
            ['icy-tree-02.png', 20], // widest
            ['icy-tree-03.png', 35], // meh tree
            ['icy-flowers-02.png', 6],
            ['icy-chunk-01.png', 3],
            ['icy-rock-01.png', 3],
            ['icy-rock-02.png', 3],
  
        ],
        bigPropIndexOfNull: 0, // this refers to the [null, 1000], line above

        // when a big prop is spawned, roll on this table for the amount of props to join them
        // number of props to join a big prop, then weight
        bigPropAccompanyChances: [
            [0, 1],
            [1, 3],
            [2, 2],
            [3, 0],
            [4, 0]
        ],
        
        // The props that can join a big prop when it spawns
        accompanimentProps: [
            ['icy-flowers-02.png', 1],
            ['small-icy-rocks-01.png', 0.5],
            ['small-icy-rocks-02.png', 0.5],
            ['small-icy-rocks-03.png', 0.5],
            ['god-rays-tundra', 0.05],
        ],
        
        // how far away do ALL joining props spawn at MOST from the big prop? 
        // (this distance gets increased as well by the MIN distance from configs found in prop-config !!)
        accompanimentMaxPropRange: 150,

        // props that spawn without anything joining them (when a big prop is not in that cell)
        soloPropChances: [
            [null, 70],
            ['icy-flowers-01.png', 3],
            ['icy-flowers-02.png', 6],
            ['small-icy-rocks-01.png', 8],
            ['small-icy-rocks-02.png', 7],
            ['small-icy-rocks-03.png', 12],
            ['icy-chunk-01.png', 2],
            ['giants-axe', 1],
            ['giants-shield', 1],
            ['giants-sword', 1],
            ['icy-grave', 2],
            ['icy-flag', 1],
            ['icy-rock-01.png', 1],
            ['icy-rock-02.png', 1],
        ],

        // Ground stamps, unrelated to the above
        groundStampChances: [
            [null, 120],
            ['tundra-snow-stamp-01.png', 60],
            ['tundra-snow-stamp-02.png', 50],
            ['tundra-snow-stamp-03.png', 50],
            ['tundra-dirt-stamp-01.png', 15],
            ['tundra-dirt-stamp-02.png', 30],
            ['tundra-ice-stamp-01.png', 25],
        ],

        statusProps: [
            [null, 350],
            ['ice-field.png', 8]
        ],

        // every [dimension + padding] units is a cell,
        // and has a [chance] to have a prop in the [dimension] area of the cell
        propCellDimension: 350,
        propCellPadding: 100,
        // same as above, but ground stamps
        stampCellDimension: 700,
        stampCellPadding: 250,

        // texture of ground
        grassTexture: 'tundra-ground-tile',
        // Zoom level of the ground (grass) texture. Lower = more zoomed in 
        groundZoom: 1,
        ambienceEffect: 'tundra-ambience',

        generationConfig: {
            type: GenerationType.Vertical,
            width: 4_750,
            wallAssetName: 'tundra-blizzard'
        },

		currencyMultiplier: 1.50,
    },
}
