import { Container, DisplayObject } from 'pixi.js'
import { InstancedProjectile, InstancedSprite } from '../instanced-sprite'
import { Effect } from '../pfx/effect'
import { IParticleRendererCamera } from '../pfx/sprite-particle-renderer'
import { RenderQueue, RenderQueueElementType } from '../render-queue'
import { Renderer } from '../renderer'
import { RiggedSpineModel } from '../spine-model'
import ProjectileEffectManager from '../projectile-effect-manager'
//import Renderer from '../../engine/client/graphics/renderer'

export type RenderableObjectType = 'effect' | 'display-object' | 'projectile' | 'prop'

interface OneOffEffect {
    effect: Effect
    timer: number
}

export interface RenderableObject {
    renderableType: RenderableObjectType
    obj: any
}

export class LayerRenderer extends Container {
    private topLevelObjects: RenderableObject[] = []
    private oneOffEffects: OneOffEffect[] = []
    private renderQueue: RenderQueue
    private cameraState: IParticleRendererCamera

    _render(_renderer: PIXI.Renderer): void {
        if (this.renderQueue) {
            this.renderQueue.clear()
            let start, end
            const renderer = Renderer.getInstance()
            this.topLevelObjects.forEach((renderableObject) => {
                start = performance.now()
                switch (renderableObject.renderableType) {
                    case 'effect':
                        // The effect will add particles to the render queue
                        renderableObject.obj.render(this.renderQueue)
                        end = performance.now()
                        renderer.addRenderTime('Effect (adding elements to queue)', end - start)
                        break
                    case 'prop':
                        renderableObject.obj.render(this.renderQueue)
                        end = performance.now()
                        renderer.addRenderTime(renderableObject.renderableType, end - start)
                        break
                    case 'projectile':
                        renderableObject.obj.render(this.renderQueue)
                        end = performance.now()
                        renderer.addRenderTime(renderableObject.renderableType, end - start)
                        break
                    case 'display-object':
                        this.renderQueue.addElement(renderableObject.obj, RenderQueueElementType.DisplayObject, renderableObject.obj.zIndex)
                        break
                }
            })
            this.renderQueue.flush(this.cameraState)
        }
    }

    constructor(renderQueue: RenderQueue, cameraState: IParticleRendererCamera) {
        super()
        this.renderQueue = renderQueue
        this.cameraState = cameraState
    }

    shutdown() {
        this.topLevelObjects.forEach((o) => {
            if (o.obj.parent) {
                o.obj.parent = null
            }
        })
        this.topLevelObjects = []
    }

    update(delta: number): void {
        this.topLevelObjects.forEach((renderableObject) => {
            renderableObject.obj.update(delta)
        })

        // if (debugConfig.pfx.drawNames) {
        //     this.topLevelObjects.forEach((renderableObject) => {
        //         const obj = renderableObject.obj
        //         if (obj instanceof Effect) {
        //             Renderer.getInstance().drawText({ text: obj.name, x: obj.x, y: obj.y, permanent: false, destroyAfterSeconds: 0.01, color: 0xffffff, scale: 1 })
        //         }
        //     })
        // }

        for(let i = this.oneOffEffects.length-1; i >= 0; --i) {
            const oneOff = this.oneOffEffects[i]
            oneOff.timer -= delta
            if (oneOff.timer <= 0) {
                this.removeFromScene(oneOff.effect)
                this.oneOffEffects.remove(oneOff)
                ProjectileEffectManager.freeEffect(oneOff.effect)
            }
        }
    }

    addEffectToScene(pfx: Effect): void {
        console.assert(pfx)
        this.topLevelObjects.push({ renderableType: 'effect', obj: pfx })
    }

    addOneOffEffectToScene(pfx: Effect, lifetime: number, prewarm?: boolean) {
        console.assert(pfx)
        //TODO2: this is technically a hack to fast forward the emitter to prevent the delay-of-1-second bug
        if (prewarm) {
            pfx.prewarm()
        }
        this.topLevelObjects.push({ renderableType: 'effect', obj: pfx })
        this.oneOffEffects.push({ effect: pfx, timer: lifetime })
    }

    removeOneOffEffect(pfx: Effect) {
        const found = this.oneOffEffects.find((e) => e.effect === pfx)
        if (found) {
            found.timer = 0
        }
    }

    addPropToScene(prop: InstancedSprite): void {
        console.assert(prop)
        this.topLevelObjects.push({ renderableType: 'prop', obj: prop })
    }

    addProjectileToScene(projectile: InstancedProjectile): void {
        console.assert(projectile)
        this.topLevelObjects.push({ renderableType: 'projectile', obj: projectile })
    }

    addDisplayObjectToScene(dispObj: DisplayObject | RiggedSpineModel): void {
        if (dispObj.parent === null) {
            //this.addChild(dispObj)
            // if we don't set parent disObj won't render,
            //  but we don't want it as child of this so we can control order of render
            dispObj.parent = this
        }
        this.topLevelObjects.push({ renderableType: 'display-object', obj: dispObj })
    }

    removeFromScene(obj: any): void {
        if (obj && obj.emitters) {
            obj.emitters.forEach((emitter) => emitter.reset())
        }

        // huh why was this here
        // if (obj && obj.children && obj.children.length > 0) {
        //     for (let i = 0, len = obj.children.length; i < len; ++i) {
        //         this.removeFromScene(obj.children[i])
        //     }
        // }

        let idx = -1
        for (let i = 0, len = this.topLevelObjects.length; i < len; ++i) {
            if (this.topLevelObjects[i].obj === obj) {
                idx = i
                break
            }
        }
        if (idx >= 0) {
            this.topLevelObjects.splice(idx, 1)
        }

        if (obj.parent) {
            obj.parent = null
            if (obj.transform) {
                obj.transform._parentID = -1
            }
        }
    }
}