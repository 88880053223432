import { Player } from "../entities/player"
import { TutorialFlagsEnum } from "../ftue/tutorial-flags"
import { CHARACTER_NAMES as CHARACTER_DB_NAMES } from "../game-data/characters"
import PlayerMetricsSystem from "../metrics/metric-system"
import { showGenericInfoPromptUI } from "../ui/store/generic-prompt"
import { Views, UICurrentState } from "../ui/store/ui-store"
import { UI } from "../ui/ui"
import { UpgradeManager } from "../upgrades/upgrade-manager"
import { getChapterRuns, getUnlocks, postStoryRun } from "../utils/api/griddle-requests"
import { percentage, timeInMilliseconds, uuid } from "../utils/primitive-types"
import { InGameTime, RealTime, realTimeHighResolutionTimestamp } from "../utils/time"
import { WeaponConfig } from "../weapons/weapon-types"
import { Audio } from "./audio"
import { GameState } from "./game-state"
import { Camera } from "./graphics/camera-logic"
import { PauseManager } from "./pause-manager"
import ClientPlayerInput from "./client-player-input"
import { loadFromLocalStorage, saveToLocalStorage } from "../utils/local-storage"
import { NUMBER_OF_DEMO_RUNS_LOCAL_KEY, NUMBER_OF_RUNS_LOCAL_KEY } from "../ui/store/end-chapter-store"
import { MetaUnlocksManager } from "../upgrades/meta/meta-unlocks-manager"
import { GameClient } from "./game-client"
import { Buff } from "../buffs/buff"
import { BuffIdentifier } from "../buffs/buff.shared"
import { getTableStatsForChapterRuns } from "../ui/store/story-store"

const GAME_END_FADE_OUT_TIME: timeInMilliseconds = 3_000

// we could have called this something boring like GameLifecycleManager but... give me victory or give me death
class VictoryDeathManager {
	victoryOrDeathCalled: boolean = false

	isVictory: boolean = false
	gameEnded: boolean = false

	private endLerpTimeStart: timeInMilliseconds = 0
	private endLerpTimeEnd: timeInMilliseconds = 0

	private endLerpTimeScaleStartValue: percentage = 0
	private endLerpZoomStartValue: percentage
	private startBgmVolume: percentage = 0
	private startSfxVolume: percentage = 0

	victory(player: Player) {
		if (!this.victoryOrDeathCalled) {
			console.log('VictoryDeathManager.victory()')
			Buff.apply(BuffIdentifier.Invulnerable, this, GameState.player, 1, 9999999)
			UI.getInstance().emitMutation('ui/updateWinRound')
			this.isVictory = true
			this.runEnding()
			Audio.getInstance().playSfx('SFX_Victory_Fanfare');
		}
	}

	death(player: Player) {
		if (!this.victoryOrDeathCalled) {
			console.log('VictoryDeathManager.death()')
			UI.getInstance().emitMutation('ui/endRound')
			UI.getInstance().emitAction('endChapter/fetchMutators')
			UI.getInstance().emitAction('story/refreshStory')
			this.isVictory = false
			this.runEnding()
		}
	}

	update() {
		if (this.victoryOrDeathCalled) {
			if (!this.gameEnded) {
				const now = realTimeHighResolutionTimestamp()
				if (now >= this.endLerpTimeEnd) {
					this.stopRun()
				} else {
					const lerpValue = (now - this.endLerpTimeStart) / GAME_END_FADE_OUT_TIME
					const timeScale = Math.lerp(this.endLerpTimeScaleStartValue, 0, lerpValue)
					Camera.getInstance().allZoomMultiplier = Math.lerp(this.endLerpZoomStartValue, this.endLerpZoomStartValue * 1.5, lerpValue)

					InGameTime.timeScale = timeScale
					Audio.getInstance().setMasterBGMVolume(Math.lerp(this.startBgmVolume, 0, lerpValue))
					Audio.getInstance().setMasterSFXVolume(Math.lerp(this.startSfxVolume, 0, lerpValue))
				}
			}
		}
	}

	cleanUp() {
		this.victoryOrDeathCalled = false
		this.isVictory = false
		this.gameEnded = false
		this.endLerpTimeStart = 0
		this.endLerpTimeEnd = 0
		this.endLerpTimeScaleStartValue = 0
		this.endLerpZoomStartValue = undefined
		this.startBgmVolume = 0
		this.startSfxVolume = 0
	}

	private runEnding() {
		this.victoryOrDeathCalled = true
		this.endLerpTimeStart = realTimeHighResolutionTimestamp()
		this.endLerpTimeEnd = this.endLerpTimeStart + GAME_END_FADE_OUT_TIME
		this.endLerpTimeScaleStartValue = InGameTime.timeScale
		this.endLerpZoomStartValue = Camera.getInstance().allZoomMultiplier
		this.startBgmVolume = Audio.getInstance().getMasterBGMVolume()
		this.startSfxVolume = Audio.getInstance().getMasterSFXVolume()
		PlayerMetricsSystem.getInstance().trackMetric('TIME_SURVIVED')
	}

	instantEndRound() {
		UI.getInstance().emitMutation('ui/endRound')
		UI.getInstance().emitAction('endChapter/fetchMutators')
		UI.getInstance().emitAction('story/refreshStory')
		this.isVictory = false

		this.stopRun()
	}

	private async stopRun() {
		this.gameEnded = true
		UI.getInstance().emitAction('ui/hideCanvas')
		this.sendMetrics()
		this.endChapter()
		GameClient.getInstance().shutDown()
		const bgmVolume = UI.getInstance().store.getters['settings/getCurrentBGMVolume']
		const sfxVolume = UI.getInstance().store.getters['settings/getCurrentSFXVolume']
		Audio.getInstance().setMasterBGMVolume(bgmVolume / 100)
		Audio.getInstance().setMasterSFXVolume(sfxVolume / 100)
		Audio.getInstance().playBgm('MUS_FLOATING_LP')
		UI.getInstance().emitMutation('ui/updateMenuMusicStarted', true)
	}

	private endChapter() {
		const ftueGetFlag = UI.getInstance().store.getters['ftue/getFlag']

		UI.getInstance().emitAction('endChapter/fetchMutators')
		UI.getInstance().emitAction('story/refreshStory')
		UI.getInstance().emitAction('endChapter/fetchEndChapterData')
		UI.getInstance().emitAction('ui/changeActiveView', Views.END_CHAPTER)
		UI.getInstance().emitAction('endChapter/updateEndChapterReviewOptions')
		UI.getInstance().emitAction('activityFeed/startLongPollGetNewActivity')
		ClientPlayerInput.preventSpaceInput = false
		try {
			const numRuns = loadFromLocalStorage(NUMBER_OF_RUNS_LOCAL_KEY)
			let num = Number.parseInt(numRuns)
			num = isNaN(num) ? 1 : num + 1
			saveToLocalStorage(NUMBER_OF_RUNS_LOCAL_KEY, num.toString())

			//Temp for steam demo
			if (process.env.IS_ELECTRON) {
				const numDemoRuns = loadFromLocalStorage(NUMBER_OF_DEMO_RUNS_LOCAL_KEY)
				// Hack to make sure we show the demo screen on the very first run
				let numDemo = numDemoRuns === undefined ? -2 : Number.parseInt(numDemoRuns)
				numDemo = isNaN(numDemo) ? 1 : numDemo + 1
				saveToLocalStorage(NUMBER_OF_DEMO_RUNS_LOCAL_KEY, numDemo.toString())
			}

		} catch (err) {
			console.error(`error loading/saving number of runs `, err)
		}

		if (ftueGetFlag(TutorialFlagsEnum.GainedOneVote)) {
			UI.getInstance().emitAction('endChapter/showBookReviewModal')
		}
		UI.getInstance().emitMutation('ui/updateUiState', UICurrentState.MAIN_MENU)

		PauseManager.pauseGame('end-game')
	}

	private async sendMetrics() {
		const metrics = PlayerMetricsSystem.getInstance().getSerializableMetrics()
		const player = GameState.player
		const gameId = UI.getInstance().store.state.story.selectedStoryId
		const chapter = Number.parseInt(UI.getInstance().store.state.story.selectedChapter)
		const portal = UI.getInstance().store.state.ui.webPortalType || 'none'


		const body = {
			character: CHARACTER_DB_NAMES[player.characterType], //TODO: replace with shared library names
			primaryWeapon1: WeaponConfig[player.primaryWeaponType].dbName, //TODO: replace with shared library names
			upgrades: UpgradeManager.getAllocatedUpgradeNames(), //TODO: replace with shared library names
			bonuses: player.bonusesAcquired, //TODO: replace with shared library names
			chapter,
			runDuration: ~~(metrics.totalRunDurationInSeconds),
			isWin: this.isVictory,
			stats: metrics,
			paperScraps: player.commonCurrency,
			lostScrolls: player.rareCurrency,
			portal,
			//magicTomes: 0, TODO: add magic tomes to payload once we decide how / where / when we earn them
		}

		player.secondaryWeapons.forEach((weapon, i) => {
			body[`secondaryWeapon${i + 1}`] = WeaponConfig[weapon.weaponType].dbName //TODO: replace with shared library names
		})

		// send metrics to griddle
		console.log('Posting run data...\n', JSON.stringify(body, null, 4))

		UI.getInstance().emitAction('endChapter/setRun', body)
		UI.getInstance().emitMutation('endChapter/setEndLevel', player.level)

		try {
			const result = await postStoryRun(gameId, body)
			const runId: uuid = result.id
			const voteScore = result.voteScore
			console.log(`Posted run data for ID ${runId}`, result)

			UI.getInstance().emitMutation('endChapter/updateRunId', runId)
			UI.getInstance().emitAction('endChapter/setVoteScoring', voteScore)
			UI.getInstance().emitMutation('user/updateCurrencies', result.currencies)
		} catch (err) {
			console.error(err)
			showGenericInfoPromptUI({
				title: 'errors.server_error_header',
				description: ['errors.end_run_submit_error_description'],
				okButtonText: 'errors.server_error_dismiss',
				dimBackground: true,
			}, null, () => {
				UI.getInstance().emitAction('genericPrompt/closePromptPanel')
			})
		}

		try {
			const unlocksAndPerks = await getUnlocks()

			const metaUnlocksInstance = MetaUnlocksManager.getInstance()
			metaUnlocksInstance.setUnlocks(unlocksAndPerks.unlocks)
		} catch (err) {
			console.error(`Error when fetching unlocks after run`, err)
		}

		try {
			const runs = await getChapterRuns(gameId, chapter)
			const tableStats = getTableStatsForChapterRuns(runs)
			UI.getInstance().emitMutation('endChapter/setLeaderboardData', tableStats)
		} catch (err) {
			console.error(`Error fetching chapter runs at end of game`)
		}
	}
}

const instance = new VictoryDeathManager()

export {
	instance as VictoryDeathManager,
}
