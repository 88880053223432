import { isString, sampleSize } from "lodash"
import { startGameClient } from "../../engine/game-client"
import { GameState } from "../../engine/game-state"
import { PauseManager, PauseSource } from "../../engine/pause-manager"
import { Directions } from "../../entities/enemies/enemy-spawn-config"
import { MUTATOR_DEFINITIONS } from "../../mutators/mutator-definitions"
import { MutatorManager } from "../../mutators/mutator-manager"
import { UpgradeManager } from "../../upgrades/upgrade-manager"
import { InGameTime } from "../../utils/time"
import { PrimaryWeaponType } from "../../weapons/weapon-types"
import { mapEventTypes, PetRescueEventSystem } from '../../events/pet-rescue-gameplay-event'
import { WEAPON_URL_KEY } from "../../engine/game-client-DEBUG-ZONE"
import { toRaw } from "vue"
import { Pet, PET_DEFINITIONS } from "../../entities/pets/pet"
import upgradeIcon from "../../../assets/ui/item/in-game-icons.json"
import playerIcon from "../../../assets/ui/player-icons/player-icons-gray.json"
import perkAndCharacterIcons from "../../../assets/ui/item/perks-skills-characters-weapons-icons.json"
import { nonSensitiveEnvVarsMapping } from "../../utils/env"
import { UI } from "../ui"
import { TutorialFlagsEnum } from "../../ftue/tutorial-flags"
import { startCreateStoryWalkthrough, startFirstChapterWinCharacterSelectWalkthrough } from "../../ftue/tutorial-walkthroughs"
import { WebPortalConfig, WebPortalType } from "../../web-portals/web-portal-init"
import { getMaintenanceModeFlag } from "../../utils/api/griddle-requests"
import { getIconFromTag } from "./settings-store"
import { GoogleAnalyticsHandler } from "../../analytics/google-analytics"
import { InputAction } from "../../engine/client-player-input"
import { mapNames } from "../../world-generation/world-data"
import { showGenericInfoPromptUI } from "./generic-prompt"
import { Audio } from "../../engine/audio"

export enum SpriteSheets {
	UPGRADE_ICONS = 'upgradeIcons',
	PERKS_CHARACTER_ICONS = 'perksCharacterIcons',
	PLAYER_ICONS = 'playerIcons',
}

export interface MenuNavigationKeyBinding {
	// The keyboard key(s) we wish to bind to a given navigation function
	keys: string[]
	callBack: () => void
}

export enum UICurrentState {
	START,
	MAIN_MENU,
	LOADING,
	IN_GAME
}

export enum mainMenuScreen {
	LANDING,
	LOGGED_IN
}

export enum Views {
	LANDING = 'landing',
	SIGN_UP = 'signUp',
	CREATE_ACCOUNT = 'createAccount',
	LOGGED_IN = 'loggedIn',
	STORY_SELECT = 'storySelect',
	CHAPTER_SELECT = 'chapterSelect',
	CHARACTER_SELECT = 'characterSelect',
	END_CHAPTER = 'endChapter',
	//END_CHAPTER_REVIEW = 'endChapterReview',
	META_PROGRESSION = 'metaProgression',
	FORGOT_PASSWORD = 'forgotPassword',
	RESET_PASSWORD = 'resetPassword',
	SETTINGS = 'settings',
	TERMS_OF_USE = 'termsOfUse',
	PRIVACY_POLICY = 'privacyPolicy',
	COOKIE_POLICY = 'cookiePolicy',
	IN_GAME = 'inGame',
	CALL_TO_ACTION = 'callToAction',
	SPLASH = 'splash',
	CREATE_ACCOUNT_LINK_CODE = 'createAccountLink',
	LINK_ACCOUNT_THROUGH_CODE = 'link',
	ACCOUNT_LINKING = 'accountLinking',
	CREDITS = 'credits'
}

// is this browser chrome?
// https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
let isChrome = false
//@ts-expect-error
const isChromium = window.chrome;
const winNav = window.navigator;
const vendorName = winNav.vendor;
//@ts-expect-error
const isOpera = typeof window.opr !== "undefined";
const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
const isIOSChrome = winNav.userAgent.match("CriOS");
if (isIOSChrome) {
	// Google Chrome on IOS
	isChrome = true
} else if (
	isChromium !== null &&
	typeof isChromium !== "undefined" &&
	vendorName === "Google Inc." &&
	isOpera === false &&
	isIEedge === false
) {
	isChrome = true
}

function initialView() {
	if (process.env.IS_WEB) {
		return Views.LANDING
	} else {
		return Views.SPLASH
	}
}

const initialState = {
	uiState: UICurrentState.START,
	doneAssetLoading: false,
	activeView: initialView(),
	previousView: '',
	currentHealth: 1,
	maxHealth: 2,
	roundIsOver: false,
	roundWon: false,
	countdownTimer: '',
	upgradesActive: [],
	allUpgrades: [],
	weaponUpgrades: [],
	primaryWeaponUpgrades: [],
	secondaryWeaponUnlocks: [],
	secondaryWeaponUpgrades: [],
	nonWeaponUpgrades: [],
	eventUpgrades: [],
	mutatorsActive: [],
	primaryWeaponActive: ['Bow'],
	petsActive: [],
	energybar: 0,
	totalAmmo: 3,
	currentAmmo: 3,
	showGoodBoyStacks: false,
	goodBoyStacks: 0,
	nowTimestamp: 0,
	currentMapShown: -1,
	showMap: false,
	mapEvents: [],
	enableDarkenOverlay: false,
	warningTopStartTimestamp: 0,
	warningBottomStartTimestamp: 0,
	warningRightStartTimestamp: 0,
	warningLeftStartTimestamp: 0,
	positiveWarningTopStartTimestamp: 0,
	positiveWarningBottomStartTimestamp: 0,
	positiveWarningRightStartTimestamp: 0,
	positiveWarningLeftStartTimestamp: 0,
	pauseToggledByUser: false,
	mainMenuScreen: mainMenuScreen.LANDING,
	maintenanceMode: false,
	bigMessages: [],
	warningMessages:[],
	showDebugIconScreen: false,
	showDebugLightingColor: false,
	animationTransitioning: true,
	isChrome,
	webPortalType: "none",
	webPortalConfig: {},
	startUpError: "",
	showStartupError: false,
	cookiesRejected: false,
	showCookiesUI: false,
	showCallToAction: false,
	nextAction: "",
	selectedMap: null,
	menuMusicStarted: false,
	keystrokeHandler: undefined,
	previousKeystrokeHandler: undefined
}

const store = {
	namespaced: true,
	modules: {
	},
	state: initialState,
	getters: {
		isEnvProductionLike(state: UIStoreState) {
			return process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
		},
		isEnvWeb(state: UIStoreState) {
			console.log(`isWeb ${process.env.IS_WEB}`)
			return process.env.IS_WEB === true
		},
		portal(state: UIStoreState) {
			return state.webPortalType
		},
		webPortalConfig(state: UIStoreState) {
			return WebPortalConfig[state.webPortalType]
		},
		allowFullscreen(state: UIStoreState) {
			return WebPortalConfig[state.webPortalType].allowFullscreen
		},
		isGamePaused(state: UIStoreState) {
			return () => { return PauseManager.isPaused() }
		},
		pauseToggledByUser(state: UIStoreState) {
			return state.pauseToggledByUser
		},
		upgradesActive(state: UIStoreState) {
			return state.upgradesActive
		},
		mutatorsActive(state: UIStoreState) {
			return state.mutatorsActive
		},
		mutatorsAvailable(state: UIStoreState) {
			// can group this later, for now, there's one 'group'
			return [{ name: 'mutators', mutators: Object.keys(MUTATOR_DEFINITIONS) }]
		},
		primaryWeaponActive(state: UIStoreState) {
			return state.primaryWeaponActive
		},
		allPrimaryWeapons(state: UIStoreState) {
			return Object.values(PrimaryWeaponType).filter(isString)
		},
		petsActive(state: UIStoreState) {
			return state.petsActive.map((p) => p.name)
		},
		allPets(state: UIStoreState) {
			return Object.keys(PET_DEFINITIONS)
		},
		getCountdownToWin(state: UIStoreState) {
			return state.countdownTimer
		},
		getMapEventClass(state: UIStoreState) {
			switch (state.currentMapShown) {
				case mapEventTypes.petRescue:
					return 'pet-rescue'
			}
		},
		hasSplashBg(state: UIStoreState) {
			return state.activeView === Views.LANDING || state.activeView === Views.LOGGED_IN || state.activeView === Views.SIGN_UP
				|| state.activeView === Views.FORGOT_PASSWORD || state.activeView === Views.SETTINGS || state.activeView === Views.LINK_ACCOUNT_THROUGH_CODE || state.activeView === Views.CREATE_ACCOUNT_LINK_CODE || state.activeView === Views.ACCOUNT_LINKING
		},
		showEnergyBar(state: UIStoreState) {
			return state.primaryWeaponActive[0] === PrimaryWeaponType[PrimaryWeaponType.Bow]
		},
		bigMessages(state: UIStoreState) {
			return state.bigMessages
		},
		getWarningMessages(state: UIStoreState){
			return state.warningMessages
		},
		currentBigMessage(state: UIStoreState) {
			return state.bigMessages.first()
		},
		storyLinkURL(state: UIStoreState) {
			return `${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GAME_URL}/?storyId=`
		},
		getActiveView(state: UIStoreState) {
			return state.activeView
		},
		showCallToAction(state: UIStoreState) {
			if (process.env.IS_ELECTRON && process.env.NODE_ENV === 'production') {
				return false
			}
			return state.showCallToAction
		},
		showCookiesUI(state: UIStoreState) {
			if (process.env.IS_ELECTRON) {
				return false
			}
			return state.showCookiesUI
		},
		cookiesRejected(state: UIStoreState) {
			return state.cookiesRejected
		}
	},
	mutations: {
		setWebPortalType(state: UIStoreState, portal: WebPortalType) {
			state.webPortalType = portal
		},
		setWebPortalConfig(state: UIStoreState, config) {
			state.webPortalConfig = config
		},
		setActiveView(state: UIStoreState, newActiveView: Views) {
			state.previousView = state.activeView
			state.activeView = newActiveView

			if (newActiveView === Views.CHARACTER_SELECT) {
				const getFlag = UI.getInstance().store.getters['ftue/getFlag']
				if (!getFlag(TutorialFlagsEnum.FirstChapterWinCharacterSelect)) {
					// is our story a solo story that has at least 1 plot twist? 
					const story = UI.getInstance().store.getters['story/getSelectedStory']
					if (story.isSolo && story.twists.length > 0) {
						const twist = story.twists[0]
						const twistDef = MUTATOR_DEFINITIONS[twist]

						startFirstChapterWinCharacterSelectWalkthrough(twistDef.name)
						UI.getInstance().emitMutation('ftue/completeFlag', TutorialFlagsEnum.FirstChapterWinCharacterSelect)
					}
				}
			}
		},
		updateHealth(state: UIStoreState, [curr, max]) {
			state.currentHealth = curr
			state.maxHealth = max
		},
		updateMutators(state: UIStoreState, names: string[]) {
			state.mutatorsActive = [...names]
		},
		updateUpgrades(state: UIStoreState, upgrades: any[]) {
			state.upgradesActive = [...upgrades]
		},
		updateAllUpgrades(state: UIStoreState, colls: any) {
			state.allUpgrades = convertUpgradesToArray(colls)
		},
		updateWeaponUpgrades(state: UIStoreState, colls: any) {
			const { all, primary, secondary, secondaryUnlocks } = colls
			state.weaponUpgrades = convertUpgradesToArray(all)
			state.primaryWeaponUpgrades = convertUpgradesToArray(primary)
			state.secondaryWeaponUpgrades = convertUpgradesToArray(secondary)
			state.secondaryWeaponUnlocks = convertUpgradesToArray(secondaryUnlocks)
		},
		updateNonWeaponUpgrades(state: UIStoreState, colls: any) {
			state.nonWeaponUpgrades = convertUpgradesToArray(colls)
		},
		updateEventUpgrades(state: UIStoreState, colls: any) {
			state.eventUpgrades = convertUpgradesToArray(colls)
		},
		addPet(state: UIStoreState, pet: Pet) {
			state.petsActive.push(pet)
		},
		startRound(state: UIStoreState) {
			state.roundIsOver = false
		},
		endRound(state: UIStoreState) {
			state.roundIsOver = true
		},
		updateEnergyBar(state: UIStoreState, value) {
			state.energybar = value
		},
		updateAmmoCount(state: UIStoreState, ammoCount) {
			const { totalAmmo, currentAmmo } = ammoCount
			state.totalAmmo = totalAmmo
			state.currentAmmo = currentAmmo
		},
		updateWinRound(state: UIStoreState) {
			state.roundWon = true
		},
		updateCountdownToWin(state: UIStoreState, countdownTime: string) {
			state.countdownTimer = countdownTime
		},
		updateNowTimestamp(state: UIStoreState, now: number) {
			state.nowTimestamp = now
		},
		updateMapEvents(state: UIStoreState, mapEvent: any) {
			state.mapEvents.push(mapEvent)
			state.currentMapShown = mapEvent.eventType
			state.showMap = true
		},
		removeMapEvent(state: UIStoreState) {
			const eventIdxToRemove = state.mapEvents.findIndex(event => {
				return event.eventType === state.currentMapShown
			})
			state.mapEvents.splice(eventIdxToRemove, 1)
			state.currentMapShown = -1
			state.showMap = false
		},
		updateDarkenOverlay(state: UIStoreState, toggle: boolean) {
			state.enableDarkenOverlay = toggle
		},
		updatePrimaryWeapon(state: UIStoreState, weaponName: string) {
			state.primaryWeaponActive = [weaponName]
		},
		updateShowWarningTop(state: UIStoreState, startTime: number) {
			state.warningTopStartTimestamp = startTime
		},
		updateShowWarningBottom(state: UIStoreState, startTime: number) {
			state.warningBottomStartTimestamp = startTime
		},
		updateShowWarningRight(state: UIStoreState, startTime: number) {
			state.warningRightStartTimestamp = startTime
		},
		updateShowWarningLeft(state: UIStoreState, startTime: number) {
			state.warningLeftStartTimestamp = startTime
		},
		updateShowPositiveWarningTop(state: UIStoreState, startTime: number) {
			state.positiveWarningTopStartTimestamp = startTime
		},
		updateShowPositiveWarningBottom(state: UIStoreState, startTime: number) {
			state.positiveWarningBottomStartTimestamp = startTime
		},
		updateShowPositiveWarningRight(state: UIStoreState, startTime: number) {
			state.positiveWarningRightStartTimestamp = startTime
		},
		updateShowPositiveWarningLeft(state: UIStoreState, startTime: number) {
			state.positiveWarningLeftStartTimestamp = startTime
		},
		setPauseToggledByUser(state: UIStoreState, paused: boolean) {
			state.pauseToggledByUser = paused
		},
		updateUiState(state: UIStoreState, ui: UICurrentState) {
			state.uiState = ui
		},
		updateShowGoodBoyStacks(state: UIStoreState, show: boolean) {
			state.showGoodBoyStacks = show
		},
		updateGoodBoyStacks(state: UIStoreState, stacks: number) {
			state.goodBoyStacks = stacks
		},
		addNewBigMessage(state: UIStoreState, [title, subtitle]: [string, string]) {
			if (!title) {
				title = ''
			}
			if (!subtitle) {
				subtitle = ''
			}
			console.log('addNewBigMessage()', { title, subtitle })
			state.bigMessages.push({ title, subtitle })
		},
		removeCurrentBigMessage(state: UIStoreState) {
			console.log('removeCurrentBigMessage', state.bigMessages.first())
			state.bigMessages.shift()
		},

		addNewWarningMessage(state: UIStoreState, [title, subtitle, id ]: [string, string, number]){
			state.warningMessages.push({title,subtitle, id})
		},
		removeWarningMessage(state: UIStoreState, id){
			if(id > -1){
				const testing = state.warningMessages[0]
				const arr = state.warningMessages.find(message => message.id == id);
				if(arr){
					state.warningMessages.splice(id)
				}
			}
		},
		updateWarningMessage(state: UIStoreState, [title, subtitle, id ]: [string, string, number]){
			const user = state.warningMessages.find(message => message.id === id)
			if(user){
				user.title = title
				user.subtitle = subtitle
			}
		},
		setDebugIconScreen(state: UIStoreState) {
			state.showDebugIconScreen = !state.showDebugIconScreen
		},
		setDebugLightingColor(state: UIStoreState) {
			state.showDebugLightingColor = !state.showDebugLightingColor
		},
		updateAnimationTransitioning(state: UIStoreState, transitioning: boolean) {
			state.animationTransitioning = transitioning
		},
		showStartupError(state, error) {
			state.startUpError = error
			state.showStartupError = true
		},
		setRejectCookies(state: UIStoreState, value) {
			state.cookiesRejected = value
		},
		updateMaintenanceModeFlag(state: UIStoreState, flagValue: boolean) {
			state.maintenanceMode = flagValue
		},
		updateShowCookiesUI(state: UIStoreState, value: boolean) {
			state.showCookiesUI = value
		},
		updateShowCallToAction(state: UIStoreState, show: boolean) {
			state.showCallToAction = show
		},
		updateNextAction(state: UIStoreState, buttonName: string) {
			state.nextAction = buttonName
		},
		updateSelectedMap(state: UIStoreState, mapType: number) {
			state.selectedMap = mapType
		},
		updateMenuMusicStarted(state: UIStoreState, hasStarted: boolean) {
			state.menuMusicStarted = hasStarted
		},
		registerKeystrokeHandler(state: UIStoreState, callBacks: MenuNavigationKeyBinding[]) {
			if (state.keystrokeHandler) {
				document.removeEventListener('keydown', state.keystrokeHandler)
			}

			const closure: EventListenerOrEventListenerObject = (event: KeyboardEvent) => {
				menuNavigationKeyHandler(event, callBacks)
			}
			
			document.addEventListener('keydown', closure)
			state.keystrokeHandler = closure
		},
		unregisterKeystrokeHandler(state: UIStoreState) {
			if (state.keystrokeHandler) {
				document.removeEventListener('keydown', state.keystrokeHandler)
				state.keystrokeHandler = undefined
			}
		},
		updatePreviousHandler(state: UIStoreState) {
			state.previousKeystrokeHandler = state.keystrokeHandler
		},
		restorePreviousHandler(state: UIStoreState) {
			if (state.previousKeystrokeHandler) {
				if (state.keystrokeHandler) {
					document.removeEventListener('keydown', state.keystrokeHandler)
				}
				state.keystrokeHandler = state.previousKeystrokeHandler
				state.previousKeystrokeHandler = undefined
				document.addEventListener('keydown', state.keystrokeHandler)
			}
		},
		reset(state) {
			state.roundIsOver = false
			state.roundWon = false
			state.countdownTimer = ''
			state.upgradesActive = []
			state.allUpgrades = []
			state.weaponUpgrades = []
			state.primaryWeaponUpgrades = []
			state.secondaryWeaponUnlocks = []
			state.secondaryWeaponUpgrades = []
			state.nonWeaponUpgrades = []
			state.eventUpgrades = []
			state.mutatorsActive = []
			state.petsActive = []
			state.energybar = 0
			state.showGoodBoyStacks = false
			state.goodBoyStacks = 0
			state.nowTimestamp = 0
			state.showMap = false
			state.currentMapShown = -1
			state.mapEvents = []
			state.bigMessages = []
			state.warningMessages = []
			state.warningTopStartTimestamp = 0
			state.warningBottomStartTimestamp = 0
			state.warningRightStartTimestamp = 0
			state.warningLeftStartTimestamp = 0
			state.selectedMap = null
			state.menuMusicStarted = false
			state.keystrokeHandler = undefined,
			state.previousKeystrokeHandler = undefined
		}
	},
	actions: {
		// debug
		async updateMutators({ state }, names) {
			for (const mutator of names) {
				MutatorManager.applyMutator(mutator, true)
			}
		},
		async updateUpgrades({ state }, names) {
			UpgradeManager.setUpgradesByNames(names)
		},
		async setPrimaryWeapon({ state, rootGetters }, weaponName) {
			if (weaponName === null || weaponName === undefined) {
				return
			}

			const weapon = PrimaryWeaponType[weaponName] as any
			GameState.player.equipPrimaryWeapon(weapon)
			state.primaryWeaponActive = [weaponName]

			if ((process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') || rootGetters['user/isQa']) {
				const oldSearchParams = new URLSearchParams(window.location.search)
				oldSearchParams.set(WEAPON_URL_KEY, weapon)
				const newURL = window.location.origin + '/?' + oldSearchParams.toString()
				window.history.replaceState(null, null, newURL)
			}
		},
		addPet(state: UIStoreState, pet: string) {
			PetRescueEventSystem.getInstance().createAndAddPet(pet as any)
		},
		// end debug

		async togglePause({ getters }) {
			if (PauseManager.isPaused()) {
				PauseManager.unpauseGame('upgrade')
			} else {
				PauseManager.pauseGame('upgrade')
			}
		},
		async setPause({ }) {
			PauseManager.pauseGame('upgrade')
		},
		async setUnpause({ }) {
			PauseManager.unpauseGame('upgrade')
		},
		async hideCanvas({ }) {
			document.getElementById('main-canvas').style.visibility = 'hidden'
		},
		async showCanvas() {
			document.getElementById('main-canvas').style.visibility = 'visible'
		},
		async togglePauseByUser({ state, getters, commit, dispatch }, source: PauseSource = "user") {
			if (PauseManager.isPaused()) {
				dispatch('setUnpausedByUser', source)
			} else {
				dispatch('setPausedByUser', source)
			}
		},
		async setPausedByUser({ state, getters, commit, dispatch }, source: PauseSource = "user") {
			if (PauseManager.isPaused()) {
				return
			}
			PauseManager.pauseGame(source)
			commit('setPauseToggledByUser', true)
			commit('updateDarkenOverlay', true)
		},
		async setUnpausedByUser({ commit }, source: PauseSource = "user") {
			const pauseSource = PauseManager.pauseSource
			if (PauseManager.isPaused() && PauseManager.pauseSource[source]) {
				PauseManager.unpauseGame(source)
				commit('setPauseToggledByUser', false)

				if (PauseManager.allSourcesUnpaused()) {
					commit('updateDarkenOverlay', false)
				}
			}
		},
		async startGame({ state, rootGetters, rootState }: { state: UIStoreState; rootGetters; rootState }) {
			if (state.doneAssetLoading) {
				state.uiState = UICurrentState.LOADING
				const twists = toRaw(rootGetters['story/getStoryTwists'])
				const map = rootState.story.mapSelected
				const mapSelected = (map === '' || map === undefined) ? mapNames.FOREST : map;
				const chapter = rootState.story.selectedChapter
				await startGameClient(twists, mapSelected, chapter)
				state.activeView = Views.IN_GAME
				state.uiState = UICurrentState.IN_GAME
			} else {
				state.uiState = UICurrentState.LOADING
			}
		},
		async finishedLoadingAssets({ state }: { state: UIStoreState }) {
			state.doneAssetLoading = true
		},
		changeActiveView({ state: any, commit }, newActiveView: Views) {
			commit('setActiveView', newActiveView)
			GoogleAnalyticsHandler.getInstance().trackChangeView(newActiveView)
		},
		mapCollected({ state, commit, dispatch }: { state: UIStoreState, commit: any, dispatch: any }, mapEvent: any) {
			setTimeout(() => {
				commit('updateMapEvents', mapEvent)
				PauseManager.pauseGame('map')
				commit('ui/updateDarkenOverlay', true, { root: true })
			}, 50)
		},
		dismissMapCollectedUI({ state, commit, dispatch }: { state: UIStoreState, commit: any, dispatch: any }, mapEvent: any) {
			commit('removeMapEvent')
			PauseManager.unpauseGame('map')
			commit('ui/addNewBigMessage', ['', `Rescue the trapped pet!`], { root: true })
			if (GameState.player.binaryFlags.has('pickup-range-nullified-during-pet-events')) {
				commit('ui/addNewBigMessage', ['', `Your pickup range is CURSED until you do!`], { root: true })
			}

			if (PauseManager.allSourcesUnpaused()) {
				commit('ui/updateDarkenOverlay', false, { root: true })
			}
		},
		async addCollectedPet({ state, commit, dispatch }, { petName, totalPets }) {
			// Show an info modal
			console.log(`ui/addCollectedPet`, { petName, totalPets })
			await dispatch('setPause')
			showGenericInfoPromptUI({
				title: `You rescued ${PET_DEFINITIONS[petName].name}!`,
				description: [PET_DEFINITIONS[petName].description],
				okButtonText: 'generic_modal.awesome',
				dimBackground: true
			}, null, () => {
				UI.getInstance().emitAction('genericPrompt/closePromptPanel')
				dispatch('setUnpause')
			})
		},
		async updateShowWarning({ state: any, commit }, values) {
			const { direction, isPositiveEvent } = values
			const nowTime = InGameTime.highResolutionTimestamp()
			if (direction & Directions.North) {
				isPositiveEvent ? commit('updateShowPositiveWarningTop', nowTime) : commit('updateShowWarningTop', nowTime)
			}

			if (direction & Directions.South) {
				isPositiveEvent ? commit('updateShowPositiveWarningBottom', nowTime) : commit('updateShowWarningBottom', nowTime)
			}

			if (direction & Directions.East) {
				isPositiveEvent ? commit('updateShowPositiveWarningRight', nowTime) : commit('updateShowWarningRight', nowTime)
			}

			if (direction & Directions.West) {
				isPositiveEvent ? commit('updateShowPositiveWarningLeft', nowTime) : commit('updateShowWarningLeft', nowTime)
			}
		},

		async fetchMaintenanceModeFlag({ state, commit, dispatch }: { state: UIStoreState; commit: any, dispatch: any }) {
			try {
				const maintenanceModeResults = await getMaintenanceModeFlag()
				if (maintenanceModeResults.maintenanceStatus) {
					dispatch('error/ErrorDescriptions', { title: 'errors.maintenance_title', description: 'errors.maintenance_description' }, { root: true })
					commit('updateMaintenanceModeFlag', maintenanceModeResults.maintenanceStatus)
				}
			} catch (error) {
				console.log('Error in fetchMaintenanceModeFlag()', error)
			}
		}
	}
}

export type UIStoreState = typeof initialState
export type UIStore = typeof store

export const uiStore = () => {
	return store
}

export function getIconFromSpriteSheet(requestedSpriteSheet, icon) {
	let spriteSheet
	let iconName = icon + '.png'

	switch (requestedSpriteSheet) {
		case SpriteSheets.UPGRADE_ICONS:
			spriteSheet = upgradeIcon
			break;
		case SpriteSheets.PERKS_CHARACTER_ICONS:
			spriteSheet = perkAndCharacterIcons
			break;
		case SpriteSheets.PLAYER_ICONS:
			spriteSheet = playerIcon
			iconName = getIconFromTag(icon) + '.png'
			break;
	}

	if (spriteSheet.frames.hasOwnProperty(iconName)) {
		const icon = spriteSheet.frames[iconName]

		const width = icon.frame.w
		const height = icon.frame.h
		const offsetX = icon.frame.x
		const offsetY = icon.frame.y

		return {
			width: `${width}px`,
			height: `${height}px`,
			'background-position': `-${offsetX}px -${offsetY}px`,
		}
	} else {
		console.error('Icon not found in sprite sheet', iconName)
		return ''
	}


}

function convertUpgradesToArray(colls) {
	const arr = []
	for (const collectionName in colls) {
		const upgradeNames = colls[collectionName]
		arr.push({
			name: collectionName,
			upgradeNames,
		})
	}
	return [...arr]
}

if (process.env.IS_ELECTRON) {
	document.addEventListener('keydown', (event) => {
		if (event.code === 'Tab') {
			event.preventDefault()
		}
	})
}

function menuNavigationKeyHandler (event: KeyboardEvent, callBacks: MenuNavigationKeyBinding[]) {
	callBacks.forEach((cb) => {
		if (cb.keys.includes(event.code)) {
			cb.callBack()
		}
	})
}