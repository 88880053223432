import { Vector } from "sat"
import { InstancedSprite } from "../../../engine/graphics/instanced-sprite"
import { EntityType, IEntity } from "../../../entities/entity-interfaces"
import { timeInSeconds, timeInMilliseconds, radians } from "../../../utils/primitive-types"
import { ObjectPoolTyped, PoolableObject } from "../../../utils/third-party/object-pool"
import { AssetManager } from "../../../web/asset-manager"
import { Renderer } from "../../../engine/graphics/renderer"
import { GameState } from "../../../engine/game-state"
import { Effect } from "../../../engine/graphics/pfx/effect"
import ProjectileEffectManager from "../../../engine/graphics/projectile-effect-manager"
import { VectorXY, angleOfVector } from "../../../utils/math"
import { InGameTime } from "../../../utils/time"
import { FallingBits } from "../../../entities/falling-bits"
import { Beam } from "../../../beams/beams"

const SHOT_Z_OFFSET = 100
const CATCH_GFX_Z_OFFSET = 200

const CATCH_GFX_Y_OFFSET = -85
const CATCH_GFX_SCALE = 0.6

const FALLING_BITS_RELEASE_INTERVAL = 0.1

export const CANNON_SHOT_GRAVITY_RATE = 1_600

const GFX_ROT_RATE = 1 * (Math.PI * 2)

export interface CannonShotParams {
    originPos: Vector
    velocity: Vector
    yKillPlane: number

    targetPos?: Vector // if this is set, we just lerp to the target over time
    targetLerpTime?: timeInSeconds

    overridePfx?: string
    pfxRotToVelocity?: boolean

    isMiniBomb?: boolean

    hideGfx?: boolean
    emitFallingBits?: boolean

    reachedTargetCallback: (shot: CannonShot) => void
}

export class CannonShot implements PoolableObject, IEntity {
    static pool: ObjectPoolTyped<CannonShot, CannonShotParams>

    nid: number
    entityType: EntityType = EntityType.Projectile
    timeScale: number = 1

    graphic: InstancedSprite
    aimGroundGfx: InstancedSprite
    aimLineGfx: InstancedSprite[] = []

    position: Vector
    yKillPlane: number
    velocity: Vector

    targetPosition: Vector

    reachedTargetCallback: (shot: CannonShot) => void

    isDoubleTapShot: boolean

    hangTimeJuggleCount: number = 0

    activateBombWall: boolean
    isBombWallBomb: boolean
    originalFireAngle: radians = 0

    hasSubExplosion: boolean
    subExplosionCount: number

    hideGfx: boolean

    private effect: Effect

    private lerpToTarget: boolean
    private timeElapsed: timeInSeconds
    private lerpTotalTime: timeInSeconds
    private startPosition: Vector

    private juggleCatchGraphic: InstancedSprite
    private isCatchGraphicActive: boolean = false

    private emitFallingBits: boolean = false
    private previousFallingBitTime: timeInSeconds
    private groundPlaneY: number = 0

    private pfxRotToVelocity: boolean = false

    isMiniBomb: boolean = false
    rainingAgonyBeam: Beam

    constructor() {
        const texture = AssetManager.getInstance().getAssetByName('placeholder-cannon-bomb').texture
        this.graphic = new InstancedSprite(texture, 0, 0, 0, undefined, undefined, Math.getRandomFloat(0, Math.PI * 2))

        const catchTex = AssetManager.getInstance().getAssetByName('hangtime-juggle-catch-icon').texture
        this.juggleCatchGraphic = new InstancedSprite(catchTex, 0, 0, CATCH_GFX_Z_OFFSET, CATCH_GFX_SCALE, CATCH_GFX_SCALE)

        this.position = new Vector()
        this.targetPosition = new Vector()
        this.startPosition = new Vector()
        this.velocity = new Vector()

        // @TODO add a collider to this so it is affected by temporal distortion

        // @TODO add a shadow graphic? use math.ts: getProjectileTravelTime maybe -- won't look right for lerp mode though
    }

    setDefaultValues(defaultValues: any, overrideValues?: CannonShotParams) {
        if (overrideValues) {
            this.position.copy(overrideValues.originPos)
            
            this.velocity.copy(overrideValues.velocity)

            this.yKillPlane = overrideValues.yKillPlane

            this.pfxRotToVelocity = Boolean(overrideValues.pfxRotToVelocity)
            this.hideGfx = Boolean(overrideValues.hideGfx)

            this.graphic.x = overrideValues.originPos.x
            this.graphic.y = overrideValues.originPos.y

            this.graphic.zIndex = overrideValues.originPos.y + SHOT_Z_OFFSET

            this.graphic.scaleX = 33
            this.graphic.scaleY = 33

            if (overrideValues.targetPos) {
                this.targetPosition.copy(overrideValues.targetPos)
                this.startPosition.copy(overrideValues.originPos)

                this.lerpTotalTime = overrideValues.targetLerpTime
                this.lerpToTarget = true
                this.timeElapsed = 0
            } else {
                this.lerpToTarget = false
            }

            this.reachedTargetCallback = overrideValues.reachedTargetCallback

            this.effect = ProjectileEffectManager.allocEffect(overrideValues.overridePfx ?? 'cannon-basic-projectile')
            this.effect.prewarm()

            if (!this.hideGfx) {
                Renderer.getInstance().mgRenderer.addEffectToScene(this.effect)
            }

            this.emitFallingBits = Boolean(overrideValues.emitFallingBits)
            if (this.emitFallingBits) {
                this.previousFallingBitTime = InGameTime.timeElapsedInSeconds
                this.groundPlaneY = GameState.player.position.y
            }


            GameState.addEntity(this)
        }
    }

    update(delta: timeInSeconds, now?: timeInMilliseconds) {
        if (this.lerpToTarget) {
            this.timeElapsed += delta
            if (this.timeElapsed >= this.lerpTotalTime) {
                this.position.copy(this.targetPosition)
                return this.explode()
            }

            const percentDone = this.timeElapsed / this.lerpTotalTime
            this.position.x = Math.lerp(this.startPosition.x, this.targetPosition.x, percentDone)
            this.position.y = Math.lerp(this.startPosition.y, this.targetPosition.y, percentDone)
        } else {
            if (this.position.y >= this.yKillPlane && this.velocity.y >= 0) {
                this.position.y = this.yKillPlane
                return this.explode()
            }
    
            this.position.x += this.velocity.x * delta * this.timeScale
            this.position.y += this.velocity.y * delta * this.timeScale
    
            this.velocity.y = Math.clamp(this.velocity.y + CANNON_SHOT_GRAVITY_RATE * delta * this.timeScale, Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER)
        }

        this.effect.x = this.position.x
        this.effect.y = this.position.y

        if (this.pfxRotToVelocity) {
            this.effect.rot = angleOfVector(this.velocity)
        } else {
            this.effect.rot += delta * GFX_ROT_RATE * this.timeScale
        }
        
        this.effect.zIndex = this.position.y + SHOT_Z_OFFSET

        if (this.emitFallingBits) {
            const targetDirection = Math.sign(this.yKillPlane - this.groundPlaneY)
            const fallingBitYKillPlane = this.velocity.y < 0 || targetDirection < 0 ? this.groundPlaneY : this.yKillPlane  
            if (InGameTime.timeElapsedInSeconds - this.previousFallingBitTime >= FALLING_BITS_RELEASE_INTERVAL) {
                FallingBits.pool.alloc({
                    position: this.position,
                    yKillPlane: fallingBitYKillPlane,
                    targetDirection: targetDirection,
                    timeScale: this.timeScale
                })
                this.previousFallingBitTime = InGameTime.timeElapsedInSeconds
            }
        }
    }

    explode() {
        if (this.reachedTargetCallback) {
            this.reachedTargetCallback(this)
        }

        CannonShot.pool.free(this)
        ProjectileEffectManager.freeEffect(this.effect)
        return 
    }

    setCatchTarget(position: VectorXY) {
        this.juggleCatchGraphic.x = position.x
        this.juggleCatchGraphic.y = position.y + CATCH_GFX_Y_OFFSET
        this.juggleCatchGraphic.zIndex = this.juggleCatchGraphic.y + CATCH_GFX_Z_OFFSET
        
        if (!this.isCatchGraphicActive) {
            this.isCatchGraphicActive = true

            Renderer.getInstance().fgRenderer.addPropToScene(this.juggleCatchGraphic)
        }
    }

    cleanup() {
        if (!this.hideGfx) {
            Renderer.getInstance().mgRenderer.removeFromScene(this.effect)
        }

        if (this.isCatchGraphicActive) {
            Renderer.getInstance().fgRenderer.removeFromScene(this.juggleCatchGraphic)
            this.isCatchGraphicActive = false
        }

        ProjectileEffectManager.freeEffect(this.effect)
        this.effect = null

        this.reachedTargetCallback = null

        this.timeScale = 1
        this.isDoubleTapShot = false
        this.hangTimeJuggleCount = 0
        this.activateBombWall = false
        this.isMiniBomb = false
        this.isBombWallBomb = false
        this.subExplosionCount = 0
        this.hasSubExplosion = false
        this.aimGroundGfx = null
        this.aimLineGfx.length = 0

        GameState.removeEntity(this)
    }
}
