import { Vector } from "sat";
import { SpritesheetAnimatorComponent } from "../../../engine/graphics/spritesheet-animator-component";
import { Player } from "../../../entities/player";
import { defaultStatAttribute } from "../../../game-data/stat-formulas";
import EntityStatList from "../../../stats/entity-stat-list";
import { StatOperator, StatType } from "../../../stats/stat-interfaces-enums";
import { AllWeaponTypes } from "../../weapon-types";
import { AutoFireSecondaryWeapon } from "./auto-fire-secondary-weapon";
import { ThrownPhoenixBomb } from "./thrown-phoenix-bombs";
import { EntityType } from "../../../entities/entity-interfaces";
import { DamageSource } from "../../../projectiles/damage-source";
import { Audio } from "../../../engine/audio"
import { GameClient } from "../../../engine/game-client"

const PHOENIX_BOMB_FUSE_TIME = 2
const PHOENIX_BOMB_BOMB_CONE = 90

export class PhoenixBombs extends AutoFireSecondaryWeapon implements DamageSource  {
    entityType: EntityType
    weaponType: AllWeaponTypes = AllWeaponTypes.PhoenixBombs
    timeScale: number = 1

    numEntitiesChained: number = 0
    numEntitiesPierced: number = 0

    nid: number

    showImmediateDamageNumber: boolean = false

    coneAngle: number = PHOENIX_BOMB_BOMB_CONE

    fuseTime: number = PHOENIX_BOMB_FUSE_TIME

    activeBombs: ThrownPhoenixBomb[] = []

    private waitingForBombsToRevive: boolean = false

    override update(delta: number): void {
        // not calling super intentionally

        if(this.cooldown.isUp() && !this.player.noFireWeapons && !this.waitingForBombsToRevive) {
            this.fire(true)
        }
    }

    fire(naturalCooldown?: boolean) {
        if (!naturalCooldown) { 
            this.throwNewBombs(true)
            return
        }

        if (this.activeBombs.length === 0) {
            // this route only gets hit the first activation, rest should be through collecting bombs 
            this.throwNewBombs(false)
            this.cooldown.useCharge()
        } else {
            for (let i = 0; i < this.activeBombs.length; ++i) {
                this.activeBombs[i].resurrect()
            }
            this.waitingForBombsToRevive = true
        }

        Audio.getInstance().playSfx('SFX_Pheonix_Bomb_Explosion');
    }

    throwNewBombs(forcedFire: boolean) {
        const count = this.statList.getStat(StatType.projectileCount)

        for (let i = 0; i < count; i++) {
            const bombPosition = new Vector

            const angle = Math.atan2(this.player.nonZeroMovementInput.y, this.player.nonZeroMovementInput.x)
            const cone = this.coneAngle * Math.PI / 180

            const angleInsideCone = angle + (Math.random() * cone) - (cone / 2)
            const placementRange = this.statList.getStat(StatType.placementRange)
            const bombRange = Math.random() * (placementRange * 2 - placementRange) + placementRange

            bombPosition.x = Math.cos(angleInsideCone) * bombRange + this.player.position.x
            bombPosition.y = Math.sin(angleInsideCone) * bombRange + this.player.position.y
        
            const bomb = ThrownPhoenixBomb.pool.alloc({
                targetX: bombPosition.x,
                targetY: bombPosition.y,
                originPosition: this.player.position,
                weapon: this,
                weaponType: this.weaponType,
                angle: angle,
                forcedFire
            })

            if (!forcedFire) {
                this.activeBombs.push(bomb)
            }
        }
    }

    forceStopFiring() {

    }

    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)

        statList._actualStatValues.baseDamage = 30
        statList._actualStatValues.attackSize = 160
        statList._actualStatValues.projectileCount = 3
        statList._actualStatValues.attackPierceCount = 0
        statList._actualStatValues.projectileSpeed = 800

        statList._actualStatValues.allDamageMult = 1
        statList._actualStatValues.projectileChainCount = 0
		statList._actualStatValues.projectileSplitCount = 0

		statList._actualStatValues.maxAmmo = 1
        statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 5_000
		statList._actualStatValues.reloadInterval = 50

        // Min distance the phoenix bomb can be thrown
        statList._actualStatValues.placementRange = 400

    }

    onBombCollected(bomb: ThrownPhoenixBomb) {
        this.activeBombs.remove(bomb)

        if (this.activeBombs.length === 0 && !GameClient.isShutDown) {
            this.waitingForBombsToRevive = false
            this.cooldown.useCharge()
            this.throwNewBombs(false)
        }
    }

    isPlayerOwned(): boolean {
        return true
    }
    
    getKnockbackDirection(mutableEntityPos: SAT.Vector): SAT.Vector {
        mutableEntityPos.x = 0
        mutableEntityPos.y = 0
        return mutableEntityPos
    }

    reduceFuseTime(percentage: number){
        this.fuseTime = this.fuseTime * (1 - percentage)
    }

    updateAngle(percentage: number){
        this.coneAngle = this.coneAngle + (this.coneAngle * percentage)
    }
}