import { UPGRADE_COLLECTIONS } from '../../upgrades/upgrade-definitions'

export type TooltipComponent = "treeUpgrade" | "upgrade" | "twist" | "protag"

const  initialState = {
	quadrantString: 'top-left',
	verticalProp: 'top',
	horizontalProp: 'left',
	horizontalValue: 0,
	verticalValue: 0,
	hoveredItem: null,
	tooltipComponent: 'upgrade' as TooltipComponent,
	showAdditionalDetails: false,
}

const store = {
	namespaced: true,
	state: initialState,
	getters: {
		currentHorizontalValue(state: TooltipStoreState) {
			return state.horizontalValue
		},
		currentVerticalValue(state: TooltipStoreState) {
			return state.verticalValue
		},
		currentHorizontalProp(state: TooltipStoreState) {
			return state.horizontalProp
		},
		currentVerticalProp(state: TooltipStoreState) {
			return state.verticalProp
		},
		currentQuadrantString(state: TooltipStoreState) {
			return state.quadrantString
		},
		currentHoveredItem(state: TooltipStoreState) {
			return state.hoveredItem
		},
		currentComponent(state: TooltipStoreState) {
			return state.tooltipComponent
		},
		tooltipHeader(state: TooltipStoreState) {
			if (state.tooltipComponent === 'treeUpgrade' && state.hoveredItem.unlocks && state.hoveredItem.unlocks.length) {
				return `Unlocks ${UPGRADE_COLLECTIONS[state.hoveredItem.unlocks[0]].name}`
			}
			return state.hoveredItem.name
		},
		tooltipDescription(state: TooltipStoreState) {
			if (state.tooltipComponent === 'treeUpgrade' && state.hoveredItem.unlocks && state.hoveredItem.unlocks.length) {
				return UPGRADE_COLLECTIONS[state.hoveredItem.unlocks[0]].desc
			}
			return state.hoveredItem.desc ? state.hoveredItem.desc : state.hoveredItem.description
		}
	},
	mutations:{
		showTooltip(state: TooltipStoreState, payload) {
			if (!payload) {
				state.hoveredItem = null
				state.tooltipComponent = null
			} else {
				const { item, component } = payload

				state.hoveredItem = item
				state.tooltipComponent = component
			}
		},
		updateTooltipPosition(state: TooltipStoreState, mouseEvent: any) {
			if (state.tooltipComponent === 'treeUpgrade') {
				const { clientX: mouseX, clientY: mouseY } = mouseEvent
				const MOUSE_OFFSET = 64
				state.verticalProp = 'top'
				state.horizontalProp = 'left'
				state.verticalValue = mouseY + MOUSE_OFFSET / 2 
				state.horizontalValue = mouseX + MOUSE_OFFSET

				const attachedElement = document.getElementById(`evolution-upgrade-tooltip`)
				if (attachedElement) {
					const elemRect = attachedElement.getBoundingClientRect()

					state.horizontalValue = mouseX - elemRect.width / 2
				}
			} else {
				const MOUSE_OFFSET = 32

				const getVerticalCssPropFromMouseYPosition = (mouseYPosition) => {
					return mouseYPosition >= window.innerHeight / 2 ? 'bottom' : 'top'
				}
				const getHorizontalCssPropFromMouseXPosition = (mouseXPosition) => {
					return mouseXPosition >= window.innerWidth / 2 ? 'right' : 'left'
				}

				const { clientX: mouseX, clientY: mouseY } = mouseEvent
				state.verticalProp = getVerticalCssPropFromMouseYPosition(mouseY)
				state.horizontalProp = getHorizontalCssPropFromMouseXPosition(mouseX)
				state.quadrantString = `${state.verticalProp}-${state.horizontalProp}`

				if (state.verticalProp === 'top') {
					state.verticalValue = mouseY + MOUSE_OFFSET / 2 
				} else {
					state.verticalValue = Math.abs(window.innerHeight - mouseY - MOUSE_OFFSET )
				}

				if (state.horizontalProp === 'left') {
					state.horizontalValue = mouseX + MOUSE_OFFSET
				} else {
					state.horizontalValue = Math.abs(window.innerWidth - mouseX)
				}
			}	
		},
		updateShowAdditionalDetails(state: TooltipStoreState, showDetails: boolean){
			state.showAdditionalDetails = showDetails
		}
	},
	actions:{},
}
export type TooltipStoreState = typeof initialState
export const TooltipStore = typeof store

export const tooltipStore = () => {
	return store
}