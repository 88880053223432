<template>
	<div id="Stories" :class="{ 'find-story': showFindStory }">
		<div class="main-panel-container">
			<Panel :height="840">
				<template #panel-content>
					<div class="active-archived-stories">
						<div class="header">
							<Header v-if="showActiveStories" :font-size="'extraLarger'" :font-face="'boldPT'" :text-alignment="'center'" :fontColor="'cream'" :headerText="'Active Stories'"></Header>
							<Header v-else-if="showArchivedStories" :font-size="'extraLarger'" :font-face="'boldPT'" :text-alignment="'center'" :fontColor="'cream'" :headerText="'Archived Stories'"></Header>
							<Header v-else-if="showFindStory" :font-size="'extraLarger'" :font-face="'boldPT'" :text-alignment="'center'" :fontColor="'cream'" :headerText="'Find Stories'"></Header>
						</div>
						<div class="stories">
							<div class="active-stories" v-if="!fetchingStories && showActiveStories">
								<TransitionGroup tag="div" @before-enter="beforeEnter" @enter="enter" appear :css="false">
									<EmptyStoryContainer v-if="getPlayerActiveStories.length < maxStories" :data-index="getPlayerActiveStories.length < maxStories ? 0 : ''"></EmptyStoryContainer>
									<StoryContainer v-for="(story, index) in getPlayerActiveStories" :key="index" :story="story" @change-screen="changeScreen" :story-index="index" :story-type="storyScreens.ACTIVE_STORIES" :data-index="getPlayerActiveStories.length < maxStories ? index + 1 : index"></StoryContainer>
								</TransitionGroup>
							</div>
							<div v-if="!fetchingStories && showArchivedStories" class="archived-stories">
								<TransitionGroup tag="div" @before-enter="beforeEnter" @enter="enter" appear>
									<StoryContainer v-for="(story, index) in getPlayerArchivedStories" :key="`archive-` + index" :story="story" @unarchive-story="unarchiveStory" @change-screen="changeScreen" :story-index="index" :story-type="storyScreens.ARCHIVED_STORIES" :data-index="index >= 3 ? 3 : index">
									</StoryContainer>
								</TransitionGroup>
							</div>
							<div v-if="!fetchingStories && showFindStory" class="find-stories" :class="{ 'empty-results': searchedStoryResults.length === 0 }">
								<TransitionGroup tag="div" @before-enter="beforeEnter" @enter="enter" appear>
									<div v-if="searchedStoryResults.length === 0" class="no-results" :lang="this.$i18n.locale">No results were found with the current search options.</div>
									<StoryContainer v-for="(story, index) in searchedStoryResults" :key="`search-` + index" :story="story" @change-screen="changeScreen" @unarchive-story="unarchiveStory" :story-index="index" :story-type="storyScreens.SEARCH_STORY"></StoryContainer>
								</TransitionGroup>
							</div>
							<transition name="fade" v-if="fetchingStories">
								<div class="fetching-stories" :lang="this.$i18n.locale">
									<div v-for="i in 1" :key="i" class="idk">
										<div class="text">Fetching</div>
										<div class="spinner">
											<img src="../../../assets/ui/buttons/loading-spinner.gif" alt="" />
										</div>
									</div>
								</div>
							</transition>
						</div>
						<div class="pagination" v-if="showFindStory">
							<Amount :key="componentKey" :selected-amount="getPage + 1" @update-text-input="updatePageText" @decrease="decreasePage" @increase="increasePage" :min-amount="1" :max-amount="2"></Amount>
						</div>
					</div>
				</template>
			</Panel>
			<div class="button-container" :class="{ 'find-stories': showFindStory }">
				<TextButton v-if="showActiveStories || showArchivedStories"  :button-label="$t('story_select.button_return')" :disabled="false" :button-style="'blue'" @on-click-fn="leaveStories"></TextButton>
				<TextButton v-else-if="showFindStory" :button-label="$t('story_select.button_return')" :disabled="false" :button-style="'blue'" @on-click-fn="changeScreenClickFn(storyScreens.ACTIVE_STORIES)"></TextButton>
				<TextButton id='archived-stories-btn' v-if="showActiveStories" :disabled="isFtueMode" :button-label="$t('story_select.button_archived_stories')" :button-style="'green'" @on-click-fn="changeScreenClickFn(storyScreens.ARCHIVED_STORIES)"></TextButton>
				<TextButton v-else-if="showArchivedStories" :button-label="$t('story_select.button_active_stories')" :button-style="'green'" @on-click-fn="changeScreenClickFn(storyScreens.ACTIVE_STORIES)"></TextButton>
			</div>
		</div>
		<SearchContainer v-if="showFindStory"></SearchContainer>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Panel from '../ui-components/panel/panel.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import StoryContainer from '../ui-components/container/story-container.vue'
import EmptyStoryContainer from '../ui-components/container/empty-story-container.vue'
import { Views } from '../store/ui-store'
import { TOTAL_ACTIVE_STORIES } from '../store/story-store'
import { StoryScreens } from '../store/story-store'
import { showGenericInfoPromptUI } from '../store/generic-prompt'
import Amount from '../ui-components/form-inputs/amount.vue'
import SearchContainer from '../ui-components/container/search-container.vue'

import Checkbox from '../ui-components/form-inputs/checkbox.vue'
import DropDown from '../ui-components/form-inputs/dropdown.vue'
import { MUTATOR_DEFINITIONS } from '../../mutators/mutator-definitions'

// Remove testing only
import { sampleSize } from 'lodash'
import { TutorialFlagsEnum } from '../../ftue/tutorial-flags'

export default {
	name: 'Stories',
	components: {
		Panel,
		Header,
		TextButton,
		StoryContainer,
		EmptyStoryContainer,
		Checkbox,
		DropDown,
		Amount,
		SearchContainer,
	},
	data() {
		return {
			Views: Views,
			storyScreens: StoryScreens,
			gameIdSearch: '',
			maxStories: TOTAL_ACTIVE_STORIES,
			componentKey: 0,
		}
	},
	props: {},
	watch: {
		gameIdSearch() {
			if (this.gameIdSearch.length === 6) {
				this.searchForGame()
			}
		},
	},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('user', ['userType']),
		...mapState('story', ['stories', 'fetchingStories', 'selectedChapter', 'showActiveStories', 'showArchivedStories', 'showFindStory', 'searchedStoryResults', 'activeAnimationSeqTriggered', 'archivedAnimationSeqTriggered', 'searchAnimationSeqTriggered', 'activeStoryCount']),
		...mapGetters('story', ['getVotingMethod', 'getGameType', 'getVoteLength', 'getPlayerActiveStories', 'getPlayerArchivedStories', 'getPage']),
		...mapGetters('ftue', ['getFlag']),

		getTwistsWithId() {
			const twists = []

			for (const [key, mutator] of Object.entries(MUTATOR_DEFINITIONS)) {
				twists.push({
					id: mutator.id,
					name: mutator.name,
				})
			}
			return twists
		},
		isFtueMode() {
			return !this.getFlag(TutorialFlagsEnum.MadeFirstStory)
		}
	},
	methods: {
		// ...mapMutations('ui', ),
		...mapActions('ui', ['startGame', 'changeActiveView']),
		...mapActions('genericPrompt', ['closePromptPanel']),
		...mapActions('story', ['searchForStories', 'fetchAndSearchStories', 'changeStoryScreen', 'setSelectedStory', 'fetchChapterRuns']),
		...mapMutations('story', ['selectedStory', 'updateVotingMethod', 'updateGameType', 'updateVoteLength', 'updateActiveStories', 'updateStoryScreen', 'updatePage', 'resetAnimationSequence']),
		...mapMutations('ui', ['registerKeystrokeHandler', 'updatePreviousHandler']),

		changeScreen(params) {
			this.setSelectedStory(params.story)
			this.changeView(params)
			this.resetAnimationSequence()
		},
		unarchiveStory(params) {
			if (this.activeStoryCount >= TOTAL_ACTIVE_STORIES) {
				showGenericInfoPromptUI(
					{
						title: 'story_select.prompt_title_max_active_stories',
						description: ['story_select.prompt_description_max_active_stories'],
						okButtonText: 'story_select.prompt_button_ok',
						dimBackground: true,
					},
					null,
					() => {
						this.closePromptPanel()
					},
				)
			} else {
				this.changeScreen(params)
			}
		},
		changeView(params) {
			const { view } = params
			if (view === Views.CHAPTER_SELECT) {
				this.fetchChapterRuns(this.selectedChapter)
			}
			this.changeActiveView(view)
		},
		decreasePage() {
			if (this.getPage > 0) {
				this.updatePage(this.getPage - 1)
			}
		},
		increasePage() {
			this.updatePage(this.getPage + 1)
		},
		updatePageText(pageNum) {
			let page = pageNum - 1
			if (page < 0) {
				page = 0
				this.componentKey++
			} else {
				this.componentKey--
			}
			return this.updatePage(page)
		},
		beforeEnter(el) {
			if (!this.activeAnimationSeqTriggered && !this.searchAnimationSeqTriggered && !this.searchAnimationSeqTriggered) {
				el.style.opacity = 0
				el.style.transform = 'translateX(75px)'
				el.style.transition = 'all 0.5s ease'
			}
		},
		enter(el) {
			const delay = el.dataset.index * 150
			if (!this.activeAnimationSeqTriggered && !this.searchAnimationSeqTriggered && !this.searchAnimationSeqTriggereds) {
				setTimeout(() => {
					el.style.opacity = 1
					el.style.transform = 'translateX(0px)'
				}, delay)
			}
		},
		leaveStories() {
			if (this.userType === 'anonymous') {
				this.changeActiveView(Views.LANDING)
			} else {
				this.changeActiveView(Views.LOGGED_IN)
			}
		}, 
		changeScreenClickFn(storyScreen){
			this.changeStoryScreen(storyScreen)
		},
	},
	mounted() {
		const callBacks = [
			{
				keys: ['Escape'],
				callBack: this.leaveStories.bind(this)
			}
		] 
		this.registerKeystrokeHandler(callBacks)
		this.fetchAndSearchStories()
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');
#Stories {
	width: 1920px;
	height: 1080px;
	transform-origin: top left;
	transform-origin: top;
	left: 50%;
	position: absolute;
	&.ratio16By10 {
		height: 1200px;
	}
	&.find-story {
		.main-panel-container {
			position: absolute;
			right: 30px !important;
			top: 80px;
			left: unset;
			bottom: unset;
			margin: unset !important;
		}
	}

	.main-panel-container {
		width: 1430px;
		height: 1080px;
		position: absolute;
		left: 0;
		right: 0;
		top: 80px;
		bottom: 0;
		margin: 0 auto;
		&.find-story {
			position: absolute;
			right: 1px;
		}
		:deep(.panel-inner) {
			border-width: 2px 60px 57px;
		}
		.active-archived-stories {
			.header {
			}
			.stories {
				display: flex;
				justify-content: center;
				width: 1320px;
				height: 675px;
				overflow-x: hidden;
				overflow-y: auto;
				.active-stories {
					position: relative;
				}
				.find-stories {
					position: relative;
					&.empty-results {
						display: flex;
						align-items: center;
						.no-results {
							font-size: 41px;
							line-spacing: 1.5px;
							color: @green;
							&[lang='en'] {
								.regularFont(@enRegularFontPixl);
							}
							&[lang='zh'] {
								.regularFont(@zhHansHeaderFont);
							}
						}
					}
				}
				.fetching-stories {
					.idk {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						background-color: @darkBlue;
						width: 1290px;
						height: 210px;
						color: @cream;
						font-size: 40px;
						border-radius: 7px;
						margin: 12px 0px;
					}
					&[lang='en'] {
						.regularFont(@enRegularFontPixl);
					}
					&[lang='zh'] {
						.regularFont(@zhHansHeaderFont);
					}
				}
			}
			.pagination {
				display: flex;
				justify-content: center;
				margin-top: 10px;
			}
		}

		.button-container {
			display: flex;
			justify-content: space-evenly;
			margin-top: 30px;
			&.find-stories {
				justify-content: left;
			}
			.text-button {
				height: 77px;
				width: 290px;
			}
		}
	}
	.fade-enter-active {
		transition: all 0.25s ease-out;
	}
	.fade-leave-active {
		transition: all 0.15s ease-out;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
}
</style>
