import { ensureEnumUnique } from '../../../utils/debug'
import logger from '../../../utils/client-logger'

enum ParticleEffectType {
    PROJECTILE_NONE = 0,

    // 1XXX projectile idles
    // 10XX swords idle pfx
    PROJECTILE_SWORD0 = 1000,
    PROJECTILE_SWORD1 = 1001,
    PROJECTILE_SWORD2 = 1002,
    PROJECTILE_SWORD3 = 1003,

    // 11XX scythes idle pfx
    PROJECTILE_SCYTHE0 = 1100,
    PROJECTILE_SCYTHE1 = 1101,
    PROJECTILE_SCYTHE2 = 1102,
    PROJECTILE_SCYTHE3 = 1103,

    // 12XX wands idle pfx
    PROJECTILE_WAND0 = 1200,
    PROJECTILE_WAND1 = 1201,
    PROJECTILE_WAND2 = 1202,
    PROJECTILE_WAND3 = 1203,

    PROJECTILE_WAND = 1204,

    // 13XX staff idle pfx
    PROJECTILE_STAFF0 = 1300,
    PROJECTILE_STAFF1 = 1301,
    PROJECTILE_STAFF2 = 1302,
    PROJECTILE_STAFF3 = 1303,

    // 14XX arcane focii idle pfx
    PROJECTILE_ARCANEFOCUS0 = 1400,
    PROJECTILE_ARCANEFOCUS1 = 1401,
    PROJECTILE_ARCANEFOCUS2 = 1402,
    PROJECTILE_ARCANEFOCUS3 = 1403,

    // 15XX crossbows idle pfx
    PROJECTILE_CROSSBOW0 = 1500,
    PROJECTILE_CROSSBOW1 = 1501,
    PROJECTILE_CROSSBOW2 = 1502,
    PROJECTILE_CROSSBOW3 = 1503,

    PROJECTILE_CROSSBOW = 1504,


    // pet
    SEA_TURTLE_TSUNAMI,

	// Boomerang projectiles
    PROJECTILE_BOOMERANG,
    PROJECTILE_BOOMERANG_FLAME,
    PROJECTILE_BOOMERANG_SHARK,
    PROJECTILE_CHAKRAM,
    PROJECTILE_OUTBACK,
    PROJECTILE_RICOCHETRANG_01,
    PROJECTILE_RICOCHETRANG_02,

    // Spear projectiles
    PROJECTILE_GUGNIR_BLOOD,
    PROJECTILE_GUGNIR_LIGHTNING,
    PROJECTILE_SPEAR_REGULAR,	

    // generic projectiles
    PROJECTILE_FIRE_SMALL_unused,
    PROJECTILE_FIRE_MEDIUM,
    PROJECTILE_FIRE_LARGE_unused,
    PROJECTILE_SPELL_SMALL,
    PROJECTILE_SPELL_MEDIUM,
    PROJECTILE_SPELL_LARGE,
    PROJECTILE_LUTEPROJECTILE,
    PROJECTILE_LONG_SPELL_SMALL,
    PROJECTILE_LONG_SPELL_MEDIUM,
    PROJECTILE_LONG_SPELL_LARGE,
    PROJECTILE_AXE,
    PROJECTILE_STUN_SHRAPNEL,
    PROJECTILE_GRAVITY_WELL,

    //Solara PFX
    PROJECTILE_LUNAR_BLOW, 
    PROJECTILE_SOLAR_FLARE, 
    SOLARA_TRANSFORMATION,
		PROJECTILE_MOON_SOUL,

    //Fire Fairy PFX
    FIRE_FAIRY_PROJECTILE,
    FIRE_FAIRY_EXPLOSION,

    //Cannon PFX
    CANNON_MIASMA_PFX,
    CHEM_ENHANCED_PAYLOAD_PROJECTILE_01,
    CHEM_ENHANCED_PAYLOAD_PROJECTILE_02,
    PARASITIC_EGG_EXPLOSION,
    WORM,
    CANNON_FALLING_BITS,
    GOOPY_EXPLOSION,
    PARASITIC_EGG_PROJECTILE,


    // 5XXX enemy projectiles (and trails, see correspondence with 100XX range)
    PROJECTILE_ENEMY0 = 5000,
    PROJECTILE_ROCK,
    PROJECTILE_HEAD_SPOREKID,
    PROJECTILE_TRAIL_SPOREKID,
    PROJECTILE_HEAD_HEALER_unused,
    PROJECTILE_TRAIL_HEALER_unused,
    PROJECTILE_HEAD_SHAMBLINGMOUND,
    PROJECTILE_TRAIL_SHAMBLINGMOUND,
    PROJECTILE_HEAD_SHRIEKER,
    PROJECTILE_TRAIL_SHRIEKER,
    PROJECTILE_HEAD_MUSHIE,
    PROJECTILE_TRAIL_MUSHIE,
    PROJECTILE_HEAD_FORESTBOSS,
    PROJECTILE_HEAD_SPRAYFORESTBOSS,
    PROJECTILE_HEAD_FUNGIBOSS,
    PROJECTILE_TRAIL_FUNGIBOSS,
    PROJECTILE_HEAD_BEACHSKELEMAGEBOSS,
    PROJECTILE_HEAD_BEACHCRABBOSS,
    PROJECTILE_HEAD_BOSS,
    PROJECTILE_HEAD_BOSSHIGHLANDSSHARD,
    PROJECTILE_HEAD_BOSSHIGHLANDSWAVE,
    PROJECTILE_HEAD_BOSSHIGHLANDSSTONE,
    PROJECTILE_TRAIL_BOSS,
    PROJECTILE_HEAD_DISTANCER,
    PROJECTILE_TRAIL_DISTANCER,
    PROJECTILE_HEAD_BLIMPIE_GAS,
    PROJECTILE_HEAD_BLIMPIE,
    PROJECTILE_TRAIL_BLIMPIE,
    PROJECTILE_HEAD_SKELEMAGE,
    PROJECTILE_TRAIL_SKELEMAGE,
    PROJECTILE_HEAD_SPAWNER,
    PROJECTILE_TRAIL_SPAWNER,
    PROJECTILE_HEAD_TURRET_unused,
    PROJECTILE_TRAIL_TURRET_unused,
    PROJECTILE_HEAD_STRAFER,
    PROJECTILE_TRAIL_STRAFER,
    PROJECTILE_HEAD_ASP,
    PROJECTILE_TRAIL_ASP,
    PROJECTILE_HEAD_WISP,
    PROJECTILE_TRAIL_WISP,
    PROJECTILE_HEAD_SKELETONBEACH,
    PROJECTILE_TRAIL_SKELETONBEACH,
    PROJECTILE_TRAIL_GOBLIN,
    PROJECTILE_HEAD_GOBLINJESTER,
    PROJECTILE_HEAD_BONESPIRIT,
    PROJECTILE_TRAIL_BONESPIRIT,
    PROJECTILE_HEAD_SKELETALWHELP,
    PROJECTILE_TRAIL_SKELETALWHELP,
    PROJECTILE_HEAD_THORNWOLF,
    PROJECTILE_TRAIL_THORNWOLF,
    PROJECTILE_HEAD_DEATHDRAKE,
    PROJECTILE_TRAIL_DEATHDRAKE,
    PROJECTILE_HEAD_DRAGONSKULL,
    PROJECTILE_TRAIL_DRAGONSKULL,
    PROJECTILE_HEAD_GOLEMPRISM,
    PROJECTILE_TRAIL_GOLEMPRISM,
    PROJECTILE_HEAD_DREAMER,
    PROJECTILE_HEAD_ICE_DRAKE,
    PROJECTILE_TRAIL_DREAMER,
    PROJECTILE_HEAD_CRYSTALSPIDER,
    PROJECTILE_TRAIL_CRYSTALSPIDER,
    PROJECTILE_HEAD_PRISMBOSS,
    PROJECTILE_TRAIL_PRISMBOSS,
    BOSS_PRISM_WIND,
    BOSS_PRISM_CRYSTALCOCOON,
    BOSS_BEACH_DUST,
    BOSS_FOREST_HEALINGSPRING,
    BOSS_HIGHLANDS_RUNE,
    __PROJECTILE_ENEMIES_END__,

    // 100XX projectile trails
    PROJECTILE_ENEMY_TRAIL = 10000,
    PROJECTILE_PHYSICAL_TRAIL = 10001,
    PROJECTILE_FIRE_TRAIL = 10002,
    PROJECTILE_ICE_TRAIL = 10003,
    PROJECTILE_LIGHTNING_TRAIL = 10004,
    PROJECTILE_POISON_TRAIL = 10005,

    // 101XX projectile shots / muzzle flair
    PROJECTILE_PHYSICAL_SHOOT = 10100,
    PROJECTILE_FIRE_SHOOT = 10101,
    PROJECTILE_ICE_SHOOT = 10102,
    PROJECTILE_LIGHTNING_SHOOT = 10103,
    PROJECTILE_POISON_SHOOT = 10104,
    PROJECTILE_POOF_SHOOT = 10105,

    // 102XX projectile hits
    PROJECTILE_PHYSICAL_HIT = 10200,
    PROJECTILE_FIRE_HIT = 10201,
    PROJECTILE_ICE_HIT = 10202,
    PROJECTILE_LIGHTNING_HIT = 10203,
    PROJECTILE_POISON_HIT = 10204,

    // 103XX projectiles misc
    PROJECTILE_SPORES = 10300,

    // 104XX Random stuff
    ENEMY_SPAWN_POOF = 10400,

    //pet projectiles
    HEARTS_EXPLOSION,

    MAX_PROJECTILE_ID = 65355 // see projectile.shared.ts
    
}

ensureEnumUnique(ParticleEffectType, logger)

const swordParticles = [ParticleEffectType.PROJECTILE_SWORD0, ParticleEffectType.PROJECTILE_SWORD1, ParticleEffectType.PROJECTILE_SWORD2, ParticleEffectType.PROJECTILE_SWORD3]
const scytheParticles = [ParticleEffectType.PROJECTILE_SCYTHE0, ParticleEffectType.PROJECTILE_SCYTHE1, ParticleEffectType.PROJECTILE_SCYTHE2, ParticleEffectType.PROJECTILE_SCYTHE3]
const staffParticles = [ParticleEffectType.PROJECTILE_STAFF0, ParticleEffectType.PROJECTILE_STAFF1, ParticleEffectType.PROJECTILE_STAFF2, ParticleEffectType.PROJECTILE_STAFF3]
const wandParticles = [ParticleEffectType.PROJECTILE_WAND0, ParticleEffectType.PROJECTILE_WAND1, ParticleEffectType.PROJECTILE_WAND2, ParticleEffectType.PROJECTILE_WAND3]
const arcaneFocusParticles = [ParticleEffectType.PROJECTILE_ARCANEFOCUS0, ParticleEffectType.PROJECTILE_ARCANEFOCUS1, ParticleEffectType.PROJECTILE_ARCANEFOCUS2, ParticleEffectType.PROJECTILE_ARCANEFOCUS3]
const crossBowParticles = [ParticleEffectType.PROJECTILE_CROSSBOW0, ParticleEffectType.PROJECTILE_CROSSBOW1, ParticleEffectType.PROJECTILE_CROSSBOW2, ParticleEffectType.PROJECTILE_CROSSBOW3]

const ELEMENTAL_EFFECT_TRAILS = [ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL, ParticleEffectType.PROJECTILE_FIRE_TRAIL, ParticleEffectType.PROJECTILE_ICE_TRAIL, ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL, ParticleEffectType.PROJECTILE_POISON_TRAIL,] 

// projectileParticles ///////////////////

const projectileParticles: ParticleEffectType[] = []
projectileParticles.push(...scytheParticles)
projectileParticles.push(...wandParticles)
projectileParticles.push(...staffParticles)
projectileParticles.push(...arcaneFocusParticles)
projectileParticles.push(...crossBowParticles)

// ParticleEffectEToDataString ///////////////////
export const ParticleEffectEToDataString = {}

ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_WAND] = 'player-wand-projectile-01'

ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_ARCANEFOCUS0] = 'arcane-focus-projectile-01'

ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_LUTEPROJECTILE] = `lute-pfx`

ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_CROSSBOW] = 'player-crossbow-projectile-01'


ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_GUGNIR_BLOOD] = 'gugnir-blood-projectile'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_GUGNIR_LIGHTNING] = 'gungnir-projectile'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_SPEAR_REGULAR] = 'gungnir-projectile-2'

ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_BOOMERANG] = 'player-boomerang-projectile-01'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_BOOMERANG_FLAME] = 'player-boomerang-flame-projectile-01'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_BOOMERANG_SHARK] = 'player-boomerang-shark-projectile-01'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_CHAKRAM] = 'player-chakram-projectile-01'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_OUTBACK] = 'player-outback-projectile-01'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_RICOCHETRANG_01] = 'player-ricochetrang-projectile-01'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_RICOCHETRANG_02] = 'player-ricochetrang-projectile-02'

ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL] = 'physical-projectile-trail'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_FIRE_TRAIL] = 'fire-projectile-trail'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_ICE_TRAIL] = 'ice-projectile-trail'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL] = 'lightning-projectile-trail'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_POISON_TRAIL] = 'poison-projectile-trail'

ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT] = 'projectile-shoot-physical'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_FIRE_SHOOT] = 'projectile-shoot-fire'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_ICE_SHOOT] = 'projectile-shoot-ice'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_LIGHTNING_SHOOT] = 'projectile-shoot-lightning'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_POISON_SHOOT] = 'projectile-shoot-poison'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_POOF_SHOOT] = 'smoke-explosion'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_STUN_SHRAPNEL] = 'stun-shrapnel-projectile'

ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_PHYSICAL_HIT] = 'hit-physical'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_FIRE_HIT] = 'hit-fire'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_ICE_HIT] = 'hit-ice'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_LIGHTNING_HIT] = 'hit-lightning'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_POISON_HIT] = 'hit-poison'

ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_MOON_SOUL] = 'moon-soul'

ParticleEffectEToDataString[ParticleEffectType.FIRE_FAIRY_PROJECTILE] = 'fire-fairy-projectile'
ParticleEffectEToDataString[ParticleEffectType.FIRE_FAIRY_EXPLOSION] = 'fire-fairy-explosion'

ParticleEffectEToDataString[ParticleEffectType.CANNON_MIASMA_PFX] = 'cannon-miasma-pfx'
ParticleEffectEToDataString[ParticleEffectType.CHEM_ENHANCED_PAYLOAD_PROJECTILE_01] = 'chem-enhanced-payload-projectile-01'
ParticleEffectEToDataString[ParticleEffectType.CHEM_ENHANCED_PAYLOAD_PROJECTILE_02] = 'chem-enhanced-payload-projectile-02'
ParticleEffectEToDataString[ParticleEffectType.PARASITIC_EGG_EXPLOSION] = 'parasitic-egg-explosion'
ParticleEffectEToDataString[ParticleEffectType.WORM] = 'worm'
ParticleEffectEToDataString[ParticleEffectType.CANNON_FALLING_BITS] = 'cannon-falling-bits'
ParticleEffectEToDataString[ParticleEffectType.GOOPY_EXPLOSION] = 'goopy-explosion'
ParticleEffectEToDataString[ParticleEffectType.PARASITIC_EGG_PROJECTILE] = 'cannon-egg-projectile'

ParticleEffectEToDataString[ParticleEffectType.SEA_TURTLE_TSUNAMI] = 'tsunami-pfx'


// 5000 - enemy pfx
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_ENEMY0] = 'eyebat-projectile'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_ROCK] = 'enemy-projectile-rock'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_ENEMY_TRAIL] = 'enemy-projectile-trail'

ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_DREAMER] = 'dreamer-projectile'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_ICE_DRAKE] = 'everfrost-breath-pfx'

ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_THORNWOLF] = 'projectile-thornwolf-head'
ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_THORNWOLF] = 'projectile-thornwolf-trail'

// Hello dear friend, uncomment this to generate the below enemy block
/*
for (let i = ParticleEffectType.PROJECTILE_ENEMY0 + 1; i < ParticleEffectType.__PROJECTILE_ENEMIES_END__; i++) {
    const name = ParticleEffectType[i]
    const words = name.toLowerCase().split('_')
    console.log(`ParticleEffectEToDataString[ParticleEffectType.${name}] = '${words[0]}-${words[2]}-${words[1]}'`)
}
process.exit(0)
/**/

// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_SPOREKID] = 'projectile-sporekid-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_SHAMBLINGMOUND] = 'projectile-shamblingmound-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_SHAMBLINGMOUND] = 'projectile-shamblingmound-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_SHRIEKER] = 'projectile-shrieker-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_SHRIEKER] = 'projectile-shrieker-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_MUSHIE] = 'projectile-mushie-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_MUSHIE] = 'projectile-mushie-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_FORESTBOSS] = 'projectile-forestboss-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_SPRAYFORESTBOSS] = 'projectile-forestboss-shotspray'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_FUNGIBOSS] = 'projectile-fungiboss-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_FUNGIBOSS] = 'projectile-fungiboss-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_BEACHCRABBOSS] = 'projectile-beachcrabboss-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_BEACHSKELEMAGEBOSS] = 'projectile-beachskelemageboss-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_BOSSHIGHLANDSSHARD] = 'projectile-boss-highlands-shard'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_BOSSHIGHLANDSSTONE] = 'boss-highlands-stone'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_BOSSHIGHLANDSWAVE] = 'boss-highlands-petrifyingwave'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_BOSS] = 'projectile-boss-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_BOSS] = 'projectile-boss-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_DISTANCER] = 'projectile-distancer-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_DISTANCER] = 'projectile-distancer-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_BLIMPIE] = 'projectile-blimpie-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_BLIMPIE_GAS] = 'projectile-blimpie-gas'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_BLIMPIE] = 'projectile-blimpie-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_SKELEMAGE] = 'projectile-skelemage-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_SKELEMAGE] = 'projectile-skelemage-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_SPAWNER] = 'projectile-spawner-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_SPAWNER] = 'projectile-spawner-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_STRAFER] = 'projectile-strafer-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_STRAFER] = 'projectile-strafer-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_ASP] = 'projectile-asp-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_ASP] = 'projectile-asp-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_WISP] = 'projectile-wisp-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_WISP] = 'projectile-wisp-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_SKELETONBEACH] = 'projectile-skeletonbeach-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_SKELETONBEACH] = 'projectile-skeletonbeach-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_GOBLIN] = 'projectile-goblin-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_GOBLINJESTER] = 'projectile-goblin-jester-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_BONESPIRIT] = 'projectile-bonespirit-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_BONESPIRIT] = 'projectile-bonespirit-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_SKELETALWHELP] = 'projectile-skeletalwhelp-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_SKELETALWHELP] = 'projectile-skeletalwhelp-trail'

// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_DEATHDRAKE] = 'projectile-deathdrake-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_DEATHDRAKE] = 'projectile-deathdrake-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_DRAGONSKULL] = 'projectile-asp-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_DRAGONSKULL] = 'projectile-asp-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_GOLEMPRISM] = 'projectile-asp-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_GOLEMPRISM] = 'projectile-asp-trail'

// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_DREAMER] = 'projectile-asp-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_CRYSTALSPIDER] = 'projectile-asp-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_CRYSTALSPIDER] = 'projectile-asp-trail'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_HEAD_PRISMBOSS] = 'projectile-prism-head'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_TRAIL_PRISMBOSS] = 'projectile-prism-trail'
// ParticleEffectEToDataString[ParticleEffectType.BOSS_PRISM_WIND] = 'boss-prism-wind'
// ParticleEffectEToDataString[ParticleEffectType.BOSS_PRISM_CRYSTALCOCOON] = 'boss-prism-crystalcocoon'
// ParticleEffectEToDataString[ParticleEffectType.BOSS_BEACH_DUST] = 'boss-crab-dust'
// ParticleEffectEToDataString[ParticleEffectType.BOSS_FOREST_HEALINGSPRING] = 'boss-forest-healingspring'
// ParticleEffectEToDataString[ParticleEffectType.BOSS_HIGHLANDS_RUNE] = 'boss-gorgon-rune-glow'

// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_FIRE_SMALL_unused] = 'projectile-fire-medium'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_FIRE_MEDIUM] = 'projectile-fire-medium'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_FIRE_LARGE_unused] = 'projectile-fire-medium'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_SPELL_SMALL] = 'projectile-spell-small'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_SPELL_MEDIUM] = 'projectile-spell-medium'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_SPELL_LARGE] = 'projectile-spell-large'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_LONG_SPELL_SMALL] = 'projectile-long-spell-small'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_LONG_SPELL_MEDIUM] = 'projectile-long-spell-medium'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_LONG_SPELL_LARGE] = 'projectile-long-spell-large'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_AXE] = 'projectile-axe'
// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_GRAVITY_WELL] = 'skill-gravity-well'

// ParticleEffectEToDataString[ParticleEffectType.PROJECTILE_SPORES] = 'aoe-spores'

// ParticleEffectEToDataString[ParticleEffectType.ENEMY_SPAWN_POOF] = 'smoke-explosion-loop'


const reverseLookup: Map<string, ParticleEffectType> = new Map()
Object.keys(ParticleEffectEToDataString).forEach((e) => {
    reverseLookup[ParticleEffectEToDataString[e]] = e
})

const PARTICLE_EFFECT_SCALES = new Map<ParticleEffectType, number>()
Object.values(ParticleEffectType).forEach((e) => {
    const effectType = e as ParticleEffectType
    PARTICLE_EFFECT_SCALES.set(effectType, 1)
})

// REMINDER: Trails do not get scaled! But that can be changed if wanted
PARTICLE_EFFECT_SCALES.set(ParticleEffectType.PROJECTILE_HEAD_SHRIEKER, 0.25)
PARTICLE_EFFECT_SCALES.set(ParticleEffectType.PROJECTILE_GRAVITY_WELL, 0.17)
PARTICLE_EFFECT_SCALES.set(ParticleEffectType.WORM, 0.25)
PARTICLE_EFFECT_SCALES.set(ParticleEffectType.CANNON_MIASMA_PFX, 0.1)
PARTICLE_EFFECT_SCALES.set(ParticleEffectType.SEA_TURTLE_TSUNAMI, 0.44)
// exports ///////////////////

function getRandomProjectileParticle(): ParticleEffectType {
    return projectileParticles.pickRandom()
}

export function getRandomProjectileTrail(): ParticleEffectType {
    return ELEMENTAL_EFFECT_TRAILS.pickRandom()
}

const getProjectileShootParticle = function (): ParticleEffectType {
    return ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT
}

const getProjectileTrailParticle = function (): ParticleEffectType {
    return ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL
}

const getProjectileHitParticle = function (): ParticleEffectType {
    return ParticleEffectType.PROJECTILE_PHYSICAL_HIT
}

const getProjectileAssetFromEnum = function (effect: ParticleEffectType) {
    return ParticleEffectEToDataString[effect]
}

const getProjectileEnumFromAsset = function (effect: string) {
    return reverseLookup.get(effect)
}

export { ParticleEffectType, getRandomProjectileParticle, getProjectileShootParticle, getProjectileHitParticle, getProjectileAssetFromEnum, getProjectileEnumFromAsset, PARTICLE_EFFECT_SCALES }

// Hello dear friend, remove '/*' to generate projectile asset assetmanager data
/*

let projectileAssetString = ``
for (let i = ParticleEffectType.PROJECTILE_ENEMY0 + 1; i < ParticleEffectType.__PROJECTILE_ENEMIES_END__; i++) {
    const name = ParticleEffectType[i]
    const words = name.toLowerCase().split('_')
    projectileAssetString += `	{
        name: '${words[0]}-${words[2]}-${words[1]}',
        path: './pfx/enemy-projectiles/${words[0]}-${words[2]}-${words[1]}.json',
        numberOfTransitiveAssets: 0,
        phase: 2,
    },\n`
}
writeTimestampedIgnoredFile(`projectile-assets`, projectileAssetString)
logger.warn(`wrote projectile assets to ignored/projectile-assets-TIMESTAMP.txt, do not commit this`)
process.exit(0)
/**/
