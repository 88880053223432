<template>
	<div id="pagination">
		<div class="arrow-back">
			<button @click="$emit('onClickBackFn', 'back')" :disabled="disable" >
				<img src="../../../../assets/ui/buttons/settings-arrow-left.png" width="36" height="38" alt=""  :class="{disable: disable}"  />
			</button>
		</div>
		<div class="middle">
			<div v-if="!disablePips" class="pips">
				<Pip v-for="i in pipCount" :key="i" :class="{ selected: i === selectedPip }"></Pip>
			</div>
			<div v-else class="middle-text">
				<transition name="fade" mode="out-in">
					<div :class="'text ' + color + (disable? ' disable' :'') + (useSmallCaps? ' small-caps': '')" :key="titleString" :lang="this.$i18n.locale">{{ $t(titleString) }}</div>
				</transition>
			</div>
		</div>

		<div class="arrow-forward">
			<button @click="$emit('onClickForwardFn', 'forward')" :disabled="disable">
				<img src="../../../../assets/ui/buttons/settings-arrow-right.png" width="36" height="38" alt=""  :class="{disable: disable}"  />
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Pip from '../pips/pip.vue'

export default {
	name: 'Pagination',
	components: {
		Pip,
	},
	data() {
		return {}
	},
	props: {
		pipCount: {
			type: Number,
			default: 1,
		},
		selectedPip: {
			type: Number,
			default: 1,
		},
		disablePips: {
			type: Boolean,
			default: false,
		},
		titleString: {
			type: String,
			default: '',
		},
		disable: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: 'cream'
		},
		useSmallCaps: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#pagination {
	display: flex;
	width: 100%;
	.arrow-back {
		button {
			img {
				&.disable {
					filter: grayscale(90%);
				}
			}
			&:hover {
				filter: brightness(1.2);
			}
			&:active {
				filter: brightness(0.8);
			}
			&:focus {
			}
		}
	}
	.middle {
		color: white;
		text-align: center;
		align-self: center;
		flex-grow: 1;
		.pips {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.middle-text {
			display: flex;
			justify-content: center;
			align-items: center;
			.text {
				&.disable {
					color: gray;
				}
				&.cream {
					color: @cream;
				}

				&.offWhite { 
					color: @offWhite;
				}

				&.small-caps {
					font-variant: small-caps;
				}

				text-align: center;
				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					font-size: 40px;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
				position: absolute;
			}
		}
	}
	.arrow-forward {
		button {
			img {
				&.disable {
					filter: grayscale(90%);
				}
			}
			&:hover {
				filter: brightness(1.2);
			}
			&:active {
				filter: brightness(0.8);
			}
			&:focus {
			}
		}
	}
	.fade-move,
	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.15s ease-out;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
}
</style>
