<template>
	<div id="end-run">
		<div class="top-container">
			<div class="chat-button">
				<IconButton :icon-name="'chat'" :button-style="'green'" :on-click-prop="openActivityModal"></IconButton>
			</div>
			<div class="group">
				<div class="portrait-text">
					<div class="result-text" id="win-lose-text">
						<div class="win-header" :class="getIsPlayerWinner ? 'green' : 'red'" :lang="this.$i18n.locale">
							<div>{{ getIsPlayerWinner ? 'SURVIVED' : 'You Died' }}</div>
							<div class="difficulty-time">
								<StoryDifficultyMap :difficulty="getSelectedStory.difficulty" :map="getSelectedStory.mapSelection"></StoryDifficultyMap>
								<div class="time">{{ `[${getEndGameTimeInMinutesSeconds}]` }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="currency-container">
				<div class="currency-list">
					<div class="currency-list-item">
						<div class="currency-icon-1"></div>
						<div class="currency" :lang="this.$i18n.locale">{{ commonCurrency }}</div>
					</div>
					<div class="currency-list-item">
						<div class="currency-icon-2"></div>
						<div class="currency" :lang="this.$i18n.locale">{{ rareCurrency }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main-container">
			<div class="weapon-stats-container">
				<div class="pagination-container">
					<div class="small-page">
						<Pagination :disablePips="true" :title-string="leftBlockCategory" :color="'offWhite'" :use-small-caps="true"  @on-click-back-fn="decreaseWeaponsPage" @on-click-forward-fn="increaseWeaponsPage"></Pagination>
					</div>
				</div>
				<div class="wrapper">
					<div class="embellishment">
						<img src="../../../assets/ui/divider/separator.png" alt="" />
					</div>
					<div class="weapon-stats-table">
						<div class="table-content">
							<div v-if="currentWeaponsPage === 0" class="weapons-table">
								<IconTable :row-data="getAllWeaponDamageWithIcons" :col-data="weaponDamageCol"></IconTable>
							</div>
							<div v-else-if="currentWeaponsPage === 1" class="stats-table">
								<Table class="stat-table-border" :disable-header="false" :use-regular-font-header="true" :large-font="true" :table-row-data="getAllEventData" :headers="statsCol" :color="'green'"></Table>
							</div>
							<div v-else class="upgrades-table">
								<div class="upgrades-container">
									<ItemUpdated v-for="i in upgradesActive" :key="i" :item="i" :disable-circle="true" :medium-icons="true" :enable-glow="false" :selected-item="false" :on-click-prop="() => {}" :disable-book="false" :component="'upgrade'" :show-book-background="true" :tooltip-enabled="true"></ItemUpdated>									
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="current-twists">
					<div class="embellishment">
						<img src="../../../assets/ui/divider/separator.png" alt="" />
					</div>
					<div class="twists-container">
						<br>
						<div class="text" :lang="this.$i18n.locale">
							{{ $t('end_chapter.current_twists') }}
						</div>
						<div class="twist-image-row">
							<ScrollableItemsContainer :enable-arrow-background="false" @on-click-fn="arrowButton" :show-scroll-buttons="true">
								<template #arrow-content>
									<div class="twist-container" id="twist-scroll-container">
										<div class="twist-list">
											<ItemUpdated
												v-for="(i, index) in this.getSelectedStory.formattedTwists"
												:key="index"
												:id="`twist-${index}`"
												:item="i"
												:disable-circle="true"
												:medium-icons="true"
												:enable-glow="false"
												:selected-item="false"
												:on-click-prop="() => {}"
												:on-hover-fn="() => {}"
												:no-book="true"
												:component="'twist'"
												:tooltip-enabled="true"
											></ItemUpdated>
										</div>
									</div>
								</template>
							</ScrollableItemsContainer>
						</div>
					</div>
				</div>
			</div>
			<div class="chapter-summary-container">
				<div class="large-portrait" :class="[selectedCharacter.icon]"></div>
				<RunSummary></RunSummary>
			</div>
			<div class="leaderboard-container">
				<div class="header-text" :lang="this.$i18n.locale">
					{{ $t('end_chapter.leaderboard') }}
				</div>
				<br>
				<div class="leaderboard">
					<div class="embellishment">
						<img src="../../../assets/ui/divider/separator.png" alt="" />
					</div>
					<br>
					<div class="table-head">
						<div class="header" :lang="this.$i18n.locale">
							{{ $t('end_chapter.player_name') }}
						</div>
						<div class="header" :lang="this.$i18n.locale">
							{{ $t('end_chapter.total_score') }}
						</div>
					</div>
					<br>
					<div class="table-wrapper">
						<table>
							<tbody v-for="(rowData, rowIndex) in leaderboardData" :key="rowIndex" class="row-data">
								<td class="name">
									<PlayerAvatar :icon-tag="rowData.iconTag" :icon-color="rowData.iconColor" :text-color="'limerGreen'" :disabled="false" :icon-size="'medium'" :show-background="true" :show-username="true" :username="rowData.username" :show-icon="true"></PlayerAvatar>
								</td>
								<td class="stat" :lang="this.$i18n.locale">
									{{ truncateNumber(rowData.totalScore) }}
								</td>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-container">
			<div class="group">
				<div class="buttons-left-align">
					<TextButton :button-label="$t('end_chapter.button_main_menu')" :button-style="'blue'" @on-click-fn="changeView" :optional-param="{ view: Views.STORY_SELECT }"></TextButton>
					<TextButton :button-label="$t('end_chapter.button_chapter_summary')" :button-style="'green'" @on-click-fn="changeView" :optional-param="{ view: Views.CHAPTER_SELECT }"></TextButton>
				</div>
				<div class="buttons-right-align">
					<TextButton id="end-chapter-perk-store" :button-label="$t('end_chapter.button_perk_store')" :button-style="'green'" @on-click-fn="openPerkStore" :disabled="false"></TextButton>
					<TextButton :button-label="$t('end_chapter.button_character_select')" :button-style="'bright-green'" @on-click-fn="changeView" :optional-param="{ view: Views.CHARACTER_SELECT }"></TextButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Background from '../ui-components/background/background.vue'
import RunSummary from '../ui-components/end-run/run-summary.vue'
import Table from '../ui-components/table/table.vue'
import Divider from '../ui-components/dividers-headers/divider.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import ArrowBackground from '../ui-components/arrow/arrow-background.vue'
import ItemUpdated from '../ui-components/item/items-updated.vue'
import SquareItem from '../ui-components/item/square-items.vue'
import parseISO from 'date-fns/parseISO'
import { formatDistance } from 'date-fns'
import { AllWeaponTypes } from '../../weapons/weapon-types'
import { NUMBER_OF_DEMO_RUNS_LOCAL_KEY, STORY_PAGE_PARAM_KEY } from '../store/end-chapter-store'
import { STORIES_PAGE_PARAM_KEY } from '../store/end-chapter-store'
import Pagination from '../ui-components/pagination/pagination.vue'
import IconTable from '../ui-components/table/icon-table.vue'
import IconButton from '../ui-components/buttons/icon-button.vue'
import { Views } from '../store/ui-store'
import ItemPagination from '../ui-components/container/item-pagination.vue'
import { loadFromLocalStorage, saveToLocalStorage } from '../../utils/local-storage'
import StoryDifficultyMap from '../ui-components/story-difficulty-map.vue'
import ScrollableItemsContainer from '../ui-components/arrow/arrow-background.vue'
import PlayerAvatar from '../ui-components/item/player-avatar.vue'
import { to3DigitTruncatedString } from '../../utils/math'

export default {
	name: 'EndChapter',
	components: {
		Background,
		RunSummary,
		Table,
		Divider,
		TextButton,
		Header,
		ArrowBackground,
		ItemUpdated,
		SquareItem,
		Pagination,
		IconTable,
		IconButton,
		ItemPagination,
		StoryDifficultyMap,
		ScrollableItemsContainer,
		PlayerAvatar
	},
	data() {
		return {
			weaponDamageCol: [{ headerName: 'weapon_damage_table.weapon_header' }, { headerName: 'weapon_damage_table.dps_header' }, { headerName: 'weapon_damage_table.damage_dealt_header' }],
			statsCol: [{ headerName: 'event_table.event_category' }, { headerName: 'event_table.event_total' }],
			endDate: new Date('2022-11-25T03:10:45.507Z'),
			storyParam: STORY_PAGE_PARAM_KEY,
			storiesParam: STORIES_PAGE_PARAM_KEY,
			weaponTypes: AllWeaponTypes,

			showWeaponDamage: true,
			itemPerPage: 14,
			currentUpgradesPage: 1,
			currentTwistsPage: 1,
			currentWeaponsPage: 0,
			Views: Views,
		}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('time', ['date']),
		...mapGetters('story', ['getSelectedStory', 'votingTimeLeft']),
		...mapGetters('endChapter', ['getAllWeaponDamage', 'getAllWeaponDamageWithIcons', 'getAllEventData', 'getEndGameTimeInMinutesSeconds', 'getIsPlayerWinner']),
		...mapGetters('paused', ['upgradesActive']),
		...mapGetters('characterSelect', ['selectedCharacter', 'selectedWeapon']),
		...mapState('paused', ['upgrades', 'selectedUpgrade', 'hoveredUpgrade']),
		...mapState('endChapter', ['twistList', 'indexedTwists', 'selectedTwist', 'endRunResults', 'endChapterData', 'leaderboardData']),
		...mapState('story', ['selectedChapter', 'oldSelectedChapter', 'selectedChapterTableStats']),
		...mapState('ui', ['showCallToAction', 'nextAction']),
		commonCurrency() {
			if (this.endRunResults) {
				return this.endRunResults.paperScraps
			}
			return 0
		},
		rareCurrency() {
			if (this.endRunResults) {
				return this.endRunResults.lostScrolls
			}
			return 0
		},
		voteEndTimes() {
			const endTime = parseISO(this.getSelectedStory.nextTally)
			return formatDistance(endTime, this.date)
		},
		leftBlockCategory() {
			switch (this.currentWeaponsPage) {
				case 0:
					return 'Weapons'
				case 1:
					return "Stats"
				case 2:
					return "Upgrades"
			}
		}
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapActions('paused', ['upgradeOver', 'toggleSelectedUpgradeItem']),
		...mapActions('story', ['fetchStoryById']),
		...mapActions('endChapter', ['fetchMutators', 'postMutatorVote']),
		...mapActions('ui', ['changeActiveView']),
		...mapMutations('ui', ['updateDarkenOverlay', 'updateShowCallToAction', 'updateNextAction', 'registerKeystrokeHandler', 'updatePreviousHandler']),
		...mapMutations('metaProgression', ['showOrHidePerks']),
		...mapMutations('activityFeed', ['updateActivityFeedModal']),

		changeView(params) {
			if (this.tryCallToAction()) {
				let buttonName
				switch(params.view){
					case this.Views.STORY_SELECT:
						buttonName = 'storySelect'
						break
					case this.Views.CHARACTER_SELECT:
						buttonName = 'characterSelect'
						break
					case this.Views.CHAPTER_SELECT:
						buttonName = 'chapterSelect'
						break
				}
				this.updateNextAction(buttonName)
				return
			}
			const { view } = params
			this.changeActiveView(view)
		},

		tryCallToAction() {
			try {
				if (process.env.IS_ELECTRON){
					const numRuns = loadFromLocalStorage(NUMBER_OF_DEMO_RUNS_LOCAL_KEY)
					let num = Number.parseInt(numRuns)
					num = isNaN(num) ? 0 : num
					// num should only ever be -1 on the very fist play through
					if (num >= 6 || num < 0) {
						this.updateShowCallToAction(true)
						num = 0
						saveToLocalStorage(NUMBER_OF_DEMO_RUNS_LOCAL_KEY, num.toString())
						return true
					}
				}

			} catch (err) {
				console.error('Error in tryCallToAction', err)
			}
			return false
		},

		openActivityModal() {
			if (this.tryCallToAction()) {
				this.updateNextAction('chat')
				return
			}
			this.updateActivityFeedModal(true)
			this.updateDarkenOverlay(true)
		},

		openPerkStore() {
			if (this.tryCallToAction()) {
				this.updateNextAction('perkStore')
				return
			}
			this.showOrHidePerks(true)
			this.updateDarkenOverlay(true)
		},

		pipCounter(itemTotal) {
			return Math.ceil(itemTotal.length / this.itemPerPage)
		},

		upgradeIsSelected(upgradeName) {
			if (this.selectedUpgrade) {
				return upgradeName === this.selectedUpgrade.icon
			}
		},
		upgradeIsHovered(upgradeName) {
			if (this.hoveredUpgrade && this.selectedUpgrade !== this.hoveredUpgrade) {
				return upgradeName === this.hoveredUpgrade.icon
			}
		},
		arrowButton(direction) {
			const el = document.getElementById('twist-0')
			const scrollableContainer = document.getElementById('twist-scroll-container')
			let width = el.offsetWidth
			let style = getComputedStyle(el)
			width += parseInt(style.marginLeft) + parseInt(style.marginRight)

			switch (direction) {
				case 'left':
					scrollableContainer.scrollTo({ left: scrollableContainer.scrollLeft - width, behavior: 'smooth' })
					break
				case 'right':
					scrollableContainer.scrollTo({ left: scrollableContainer.scrollLeft + width, behavior: 'smooth' })
					break
			}
		},

		increaseUpgradePage() {
			if (this.currentUpgradesPage < this.pipCounter(this.upgradesActive)) {
				this.currentUpgradesPage++
			} else {
				this.currentUpgradesPage = 1
			}
		},
		decreaseUpgradePage() {
			if (this.currentUpgradesPage > 1) {
				this.currentUpgradesPage--
			}
		},
		increaseTwistPage() {
			if (this.currentTwistsPage < this.pipCounter(this.getSelectedStory.formattedTwists)) {
				this.currentTwistsPage++
			} else {
				this.currentUpgradesPage = 1
			}
		},
		decreaseTwistPage() {
			if (this.currentTwistsPage > 1) {
				this.currentTwistsPage--
			}
		},
		increaseWeaponsPage() {
			if (this.currentWeaponsPage < 2) {
				this.currentWeaponsPage++
			} else {
				this.currentWeaponsPage = 0
			}
		},
		decreaseWeaponsPage() {
			if (this.currentWeaponsPage > 0) {
				this.currentWeaponsPage--
			} else {
				this.currentWeaponsPage = 2
			}
		},
		truncateNumber(num: number) {
			return to3DigitTruncatedString(num)
		}
	},
	mounted() {
		if (process.env.IS_ELECTRON){
			if (!this.showCallToAction) {
				switch(this.nextAction) {
					case 'chat': 
						this.openActivityModal()
						break
					case 'storySelect':
						this.changeView({view: this.Views.STORY_SELECT})
						break
					case 'chapterSelect':
						this.changeView({view: this.Views.CHAPTER_SELECT})
						break
					case 'perkStore':
						this.openPerkStore()
						break
					case 'characterSelect':
						this.changeView({view: this.Views.CHARACTER_SELECT})
						break
				}
				this.updateNextAction('')
			}
		}
		this.updatePreviousHandler()
		const confirmAction = () => {
			this.changeView({ view: Views.CHARACTER_SELECT })
		}
		const dismissAction = () => {
			this.changeView({ view: Views.STORY_SELECT })
		}
		const callBacks = [
			{
				keys: ['Escape'],
				callBack: dismissAction.bind(this)
			},
			{
				keys: ['Enter', 'Space'],
				callBack: confirmAction.bind(this)
			}
		] 
		this.registerKeystrokeHandler(callBacks)
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#end-run {
	display: flex;
	justify-content: center;
	position: relative;
	flex-direction: column;
	align-items: center;
	&.ratio21By9 {
		.top-container,
		.bottom-container {
			.group {
				margin: 0 auto;
				width: 1920px;
			}
		}
	}

	.top-container {
		top: 0px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		.group {
			   
			.portrait-text {
				margin: auto;
				display: flex;
				position: relative;
				:deep(.square-item) {
					transform: scale(0.58);
					transform-origin: center;
				}
				
				.result-text {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					text-align: center;
					.win-header {
						&[lang='en'] {
							.regularFont(@enRegularFontPixl);
							font-variant: small-caps;
							font-size: 60px;
						}
						&[lang='zh'] {
							.regularFont(@zhHansHeaderFont);
						}
						&.green {
							color: @green;
						}
						&.red {
							color: @failureRed;
						}
						.difficulty-time {
							display: flex;

							.time {
								font-size: 35px;
								margin-left: 10px;
								margin-bottom: -9px;
							}
						}
					}
				}
			}
		}

		.currency-container {
			position: absolute;
			right: 65px;
			top: 27px;
			.currency-list {
				display: flex;
				flex-direction: column;

				.currency-list-item {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					.currency-icon-1,
					.currency-icon-2 {
						width: 55px;
						height: 55px;
						background-size: contain;
						background-repeat: no-repeat;
					}
					.currency-icon-1 {
						background-image: url('~/assets/ui/currency/icon-paper-scraps.png');
					}
					.currency-icon-2 {
						background-image: url('~/assets/ui/currency/icon-lost-scroll.png');
					}
					.currency {
						color: @offWhite;
						margin-left: 12px;
						margin-top: 12px;
						&[lang='en'] {
							.regularFont(@enRegularFontPixl);
							font-variant: small-caps;
							font-size: 40px;
						}

						&[lang='zh'] {
							.regularFont(@zhHansHeaderFont);
						}
					}
				}
			}
		}
	}

	.main-container {
		width: 100%;
		height: calc(100% - 300px);
		display: flex;
		align-items: center;
		justify-content: center;

		.weapon-stats-container {
			width: 33.333%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			.pagination-container {
				width: 500px;
				margin-bottom: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				.small-page {
					width: 280px;
				}
			}

			.wrapper {
				width: 500px;
				height: 710px;
				position: relative;

				.embellishment {
					position: absolute;
					top: -16px;
					z-index: 12;
					pointer-events: none;
				}
				
				.weapon-stats-table {
					background-color: @darkBlue;
					height: 500px;
					width: 500px;
					.table-content {
						width: 100%;
						height: 100%;

						.weapons-table {
							height: 100%;
						}

						.stats-table {
							:deep(.table-cell) {
								font-size: 40px;
							}
						}

						.upgrades-table {
							width: 100%;
							height: 100%;
							
							.upgrades-container {
								display: flex;
								flex-wrap: wrap;
								justify-content: flex-start;
								align-items: flex-start;
								align-content: flex-start;
								width: 100%;
								height: 100%;

								overflow-y: scroll;
								overflow-x: hidden;
							}
						}
					}

				}
			}

			.current-twists {
				width: 500px;
				height: 200px;
				position: relative;

				.embellishment {
					position: absolute;
					top: -16px;
				}

				.twists-container {
					background-color: @darkBlue;
					width: 100%;
					height: 160px;

					.text {
						color: @offWhite;
						text-align: center;
						&[lang='en'] {
							.regularFont(@enRegularFontPixl);
							font-variant: small-caps;
							font-weight: lighter;
							font-size: 45px;
						}

						&[lang='zh'] {
							.regularFont(@zhHansHeaderFont);
						}
					}

					.twist-image-row {
						display: flex;
						justify-content: center;
						flex-wrap: wrap;
						flex-direction: row;

						.twist-container {
							width: 300px;
							overflow: hidden;

							.twist-list {
								display: flex;
								.twist {
									margin: 0;
								}
							}
						}
					}
				}
			}
		}
		.chapter-summary-container {
			width: 33.333%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			.large-portrait {
				width: 543px;
				height: 415px;
				background-size: contain;
				background-position: bottom;
				background-repeat: no-repeat;
				position: relative;
				&.character-cone-dog {
					background-image: url('~/assets/ui/character-portraits/conewolf-portrait-fancy.png');
					top: 30px;
					left: 30px;
				}
				&.character-barb {
					background-image: url('~/assets/ui/character-portraits/barb-barian-portrait-fancy.png');
					top: 10px;
					left: 30px;
				}
				&.character-elf-n-ranjar {
					background-image: url('~/assets/ui/character-portraits/elf-portrait-fancy.png');
				}
				&.character-solara {
					background-image: url('~/assets/ui/character-portraits/solara-potrait-fancy.png');
				}
				&.character-radar {
					background-image: url('~/assets/ui/character-portraits/radar-portrait-fancy.png');
					transform: scale(0.95);
					top: 20px;
					left: 60px;
				}
			}
		}
		
		.leaderboard-container {
			width: 33.333%;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			.header-text {
				color: @cream;
				text-align: center;

				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					font-variant: small-caps;
					font-weight: lighter;
					font-size: 45px;
				}

				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
			}

			.leaderboard {
				background-color: @darkBlue;
				width: 500px;
				
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;

				.embellishment {
					position: absolute;
					top: -16px;
					right: 0px;
					transform: scaleX(-1);
					z-index: 12;
				}

				.table-head {
					display: flex;
					justify-content: space-between;
					width: 85%;

					.header {
						color: @offWhite;

						&[lang='en'] {
							.regularFont(@enRegularFontPixl);
							font-size: 32px;
						}

						&[lang='zh'] {
							.regularFont(@zhHansHeaderFont);
						}
					}
				}

				.table-wrapper {
					height: 600px;
					width: 100%;
					overflow-y: scroll;
					overflow-x: hidden;

					.row-data {
						.name {
							width: 100%;
						}

						.stat {
							color: @green;
							min-width: 140px;
							text-align: right;
							padding-right: 20px;
							&[lang='en'] {
								.regularFont(@enRegularFontPixl);
								font-size: 32px;
							}

							&[lang='zh'] {
								.regularFont(@zhHansHeaderFont);
							}
						}
					}
				}
			}
		}
	}

	.top-container,
	.bottom-container {
		height: 150px;
		position: relative;
		width: 100%;
		.group {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;
			position: relative;
			padding: 0px 60px;
		}
		.chat-button {
			position: absolute;
			top: 18%;
			left: 3%;
			pointer-events: all;
			z-index: 2;
			.icon-button {
				width: 100px;
			}
		}
	}
	.bottom-container {
		padding: 0px 60px 0px 60px;
		position: relative;
		top: 0;

		.group {
			.buttons-left-align,
			.buttons-right-align {
				display: flex;
				flex-direction: row;
				.text-button {
					width: 300px;
					height: 100px;
					margin: 0px 10px;
				}
			}
		}
	}

	.stat-table-border {
		padding: 0 30px;
	}
	.list-move,
	.list-enter-active,
	.list-leave-active {
		transition: all 0.5s ease;
	}

	.list-enter-active {
		transition-delay: 0.5s;
	}

	.list-enter-from {
		opacity: 0;
		transform: translateX(30px);
	}
	.list-leave-to {
		opacity: 0;
		transform: translateX(-30px);
	}
}
</style>
