<template>
	<Panel id="cookie-policy" :panel-identifier="'cookie-policy'" :height="1000">
		<template #panel-content>
			<div class="cookies-text-container">
				<h2 style="text-align: center">Cookie Policy for Other Ocean</h2>
				This is the Cookie Policy of the Other Ocean group of companies: Other Ocean Group Canada Limited, Sculpin QA Limited; Other Ocean Interactive NL Limited; and Other Ocean Group Inc., (collectively referred to as “Other Ocean”) Our company websites use cookies. By using any of our websites through any device you agree that this Cookie Policy applies to that use in additio n to any other policies that may also apply. Further information on these cookies is set out below. We may revise and update this Cookie Policy at any time. Please periodically review this Cookie Policy to ensure that you are aware of any changes.
				<br>
				<h3>What Are Cookies</h3>
				Cookies are small data files stored on your hard drive or in device memory that help us to improve our services and your experience, see which areas and features of our services are popular and count visits. We also may collect information using web beacons (also known as “tracking pixels”). Web beacons are electronic images that are used in our services or emails and help deliver cookies, count visits, understand usage and campaign effectiveness and determine whether an email has been opened and acted upon. To measure site activity and provide a better user experience, Other Ocean may allow our service providers or business partners to compile information from the cookies, Web beacons or other technologies on our websites which may include information about your visit (such as demographic data, browser type, IP address, pages visited, activities conducted on the page and the day and time of your visit). We may also use this information to develop personalized content and appropriate advertising based on your visits over time on Other Ocean websites.
				<br>
				<h3>Types of Cookies</h3>
				<span>We use different types of cookies to run our websites, some or all of the cookies identified in the list below may be stored in your browser. Cookies are divided into 2 categories:</span>
				<ol>
					<li>First-party cookies are created by the website you visit directly.</li>
					<li>Third-party cookies are created by other parties, not our sites. We, like most other website owners, use online tools to create the content on our pages, some or all of which will also create a cookie (e.g. Mailchimp.com or YouTube.com) and save it to your computer. As these cookies are not created by our site, they are classified as third-party cookies.</li>
				</ol>
				<h3>How to Opt-In?</h3>
				<span>On your first visit to our sites, a pop up box with details on our Cookie Notice will be presented allowing you to opt-in. If you choose not to take any action, the pop-up will manifest itself every time you visit our sites until you either opt-in or close the pop-up. Please note that some website functionalities may not be available if you do not opt in.</span>
				<h3>How to Opt-Out?</h3>
				<span>You can turn off cookies set by our sites and you can also elect to be prompted when a cookie is being set. If instead you choose to turn all not strictly necessary cookies off, however, you may restrict your access to certain functions of a site that rely on cookies. You can also simply set your browser to accept cookies but clear them at the end of every session. For further details on how to set your cookie preferences please visit: https://www.wikihow.com/Disable-Cookies</span>
				<h3>Changing Cookie Settings</h3>
				<span>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our services. Please refer to your browser Help instructions to learn more about cookies and other technologies and how to manage their use. If you elect to refuse or delete cookies, you will need to repeat this process if you use another computer, or change browsers.</span>
				<h3>The Cookies We Set</h3>
				<h4>Login related cookies</h4>
				<span>We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.</span>
				<h4>Form related cookies</h4>
				<span>When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.</span>
				<h4>Site preferences cookies</h4>
				<span>In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences, we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.</span>
				<h4>Third Party Cookies</h4>
				<span>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through our sites. Our sites uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content. For more information on Google Analytics cookies, see the official Google Analytics page. We also use social media buttons and/or plugins on this site that allow you to connect with your social network in various ways. For these to work the following social media sites including; Twitter, Instagram, YouTube, Twitch and Facebook who will set cookies through our site which may be used to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies. We use MailChimp to manage all subscriptions to our mailing lists and to manage the creation and sending of emails to subscribers of those lists. Mailchimp uses a session cookie to track users through the Sign Up process when they submit information via our Sign Up (subscription) form. MailChimp&apos;s privacy policy is available at: http://www.mailchimp.com/legal/privacy/</span>
				<h3>URL Links</h3>
				<span>This site contains links to third party sites. Other Ocean is not responsible for any actions or policies of such third parties. Users should check the applicable privacy policy of such a party when providing information.</span>
				<h3>More Information</h3>
				<span>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our sites. However, if you are still looking for more information than you can contact us at: contact@otherocean.com</span>
			</div>
			<div class="button-container">
				<TextButton :button-label="'Exit'" @on-click-fn="leaveScreen"></TextButton>
			</div>
		</template>
	</Panel>
</template>

<script lang="ts">
import Panel from '../ui-components/panel/panel.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'CookiePolicy',
	components: {
		Panel,
		TextButton,
	},
	data() {
	},
	computed: {
		...mapGetters('user', ['userType']),
		...mapState('ui', ['previousView'])
	},
	methods: {
		...mapActions('ui', ['changeActiveView']),
		...mapMutations('ui', ['registerKeystrokeHandler', 'updatePreviousHandler']),

		leaveScreen() {
			this.changeActiveView(this.previousView)
		}
	},
	mounted () {
		this.updatePreviousHandler()
		const callBacks = [
			{
				keys: ['Escape'],
				callBack: this.leaveScreen.bind(this)
			}
		] 
		this.registerKeystrokeHandler(callBacks)
	}
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');
.cookies-text-container {
	overflow-y: auto;
	height: 800px;
	font-size: 22px;
	margin: 20px;
	color: @cream;
}
.button-container {
	margin: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
</style>
