import { percentage } from "../utils/primitive-types"

/** Whether a given stat can be used as a number, or exclusively as a percentage multiplier. */
export enum StatUnit {
	Number,
	Percentage,
}

/** List of valid operators for how a given StatMod will influence an entity's stats */
export enum StatOperator {
	/** Additively stacking */
	SUM = 1,
	/** Additively sum all multis, then multiply by that value */
	SUM_THEN_MULTIPLY = 2,
	/** Multiplicatively stacking */
	MULTIPLY = 3,
	/** Indicates a value that is non-numeric */
	NOOP = 4,
}

/** A stat line that will clamp a given stat to within a min and max value (e.g. 1-3 projectiles) */
export interface StatClampConstraint {
	/** Stat that will be clamped */
	statType: StatType
	/** Minimum amount of stat */
	clampMin?: number
	/** Maximum amount of stat */
	clampMax?: number
}

/** A stat line that will convert one stat to another, with ratios and reserves (e.g. convert 30% of all damage to fire damage) */
export interface StatConverter {
	/** Stat that will be converted */
	inputStatType: StatType
	/** Units that the input stat uses. Used for tooltip descriptions. */
	inputStatUnit: StatUnit
	/** Amount of stat that is reserved and NOT converted */
	inputMinReserve: number
	/** Ratio of how much of stat to convert */
	inputRatio: percentage
	/** Ratio of how much of the stat to freely apply to the output WITHOUT converting */
	inputFreeRatio: percentage
	/** Output stat that is converted to */
	outputStatType: StatType
	/** Units that the output stat uses. Used for tooltip descriptions. */
	outputStatUnit: StatUnit
	/** Ratio of how much output stat should be boosted per 1 input stat */
	outputRatio: percentage
	/** Operator to apply to output stat */
	outputStatOperator: StatOperator
}

export enum StatType {
	maxHealth = 'maxHealth',

	/** number: flat base damage of the attack */
	baseDamage = 'baseDamage',
	/** percentage: multiplier for all damage */
	allDamageMult = 'allDamageMult',
	/** percentage: multiplier for initial impact damage, but damage-over-time ticks, etc. */
	initialImpactDamageMult = 'initialImpactDamageMult', // formerly known as projectileDamage
	/** percentage: bonus damage against bosses */
	bossDamageMult = 'bossDamageMult',
	/** number of attacks per second */
	attackRate = 'attackRate',

	/** gameUnits: movement speed in game units per second */
	movementSpeed = 'movementSpeed',
	/** percentage: scale movementSpeed by this amount when walking (shooting with primary weapon) */
	walkSpeedScalar = 'walkSpeedScalar',

	// stuff for all weapons
	/** number: # of enemies that an attack can pierce through */
	attackPierceCount = 'attackPierceCount',
	/** gameUnits: size of all kinds of attacks, beams, projectiles, hazards, etc. */
	attackSize = 'attackSize',
	/** number: force of knockback applied from an attack */
	attackKnockback = 'attackKnockback',

	// stuff for projectiles
	/** number: # of projectiles to shoot during one attack */
	projectileCount = 'projectileCount',
	/** gameUnits: how fast projectiles move in game units per second */
	projectileSpeed = 'projectileSpeed', // also projectile max range, as a result of faster speed * duration = longer distance
	/** radians: how much distance is between each individual projectile in a spread */
	projectileSpreadAngle = 'projectileSpreadAngle',
	/** number: # of enemies a projectile should chain through */
	projectileChainCount = 'projectileChainCount',
	/** percentage: multiplier for how far a projectile will search for another target to chain to */
	projectileChainDistanceMultiplier = 'projectileChainDistanceMultiplier',
	/** number: # of times a projectile should split into two */
	projectileSplitCount = 'projectileSplitCount',
	/** timeInSeconds: how long the projectile lasts before deletion */
	projectileLifeSpan = 'projectileLifeSpan',

	/** gameUnits: The radius the attack deals splash damage around the enemy */
	projectileSplashRadius = 'projectileSplashRadius',
	/** percentage: multiplier for the damage scale of splash damage */
	projectileSplashDamage = 'projectileSplashDamage',

	/** how fast charge weapons fill up the bar */
	chargeRate = 'chargeRate',
	/** The maximum charge value */
	maxCharge = 'maxCharge',

	/** gameUnits: stuff for weapons that have ranges */
	placementRange = 'placementRange',

	/** gameUnits: how far the player can pickup stuff on the ground */
	pickupRange = 'pickupRange',
	/** percentage: how often hearts drop */
	heartDropMulti = 'heartDropMulti',
	/** percentage: if the player doesn't earn xp from an enemy, this is the chance to reroll the drop */
	xpReDropChance = 'xpReDropChance',
	/** percentage: how much value each XP is worth */
	xpValueMulti = 'xpValueMulti',
	/** percentage: how much common currency drops */
	commmonCurrencyDropMulti = 'commmonCurrencyDropMulti',
	/** percentage: how much rare currency drops */
	rareCurrencyDropMulti = 'rareCurrencyDropMulti',

	/** timeInSeconds: how long a skill lasts */
	skillDuration = 'skillDuration',
	/** percentage: how powerful a slow is, multiplied against movement speed (negative values are more slowing) */
	slowAmount = 'slowAmount',

	// cooldown / reload stuff
	maxAmmo = 'maxAmmo',
	reloadAmmoIncrement = 'reloadAmmoIncrement',
	reloadInterval = 'reloadInterval',
	cooldownInterval = 'cooldownInterval',

	// debuffs
	randomAilmentChance = 'randomAilmentChance',
	stunChance = 'stunChance',
	shockChance = 'shockChance',
	poisonChance = 'poisonChance',
	bleedChance = 'bleedChance',
	igniteChance = 'igniteChance',
	chillChance = 'chillChance',
	doomChance = 'doomChance',

	stunPotency = 'stunPotency',
	bleedPotency = 'bleedPotency',
	ignitePotency = 'ignitePotency',
	chillPotency = 'chillPotency',
	shockPotency = 'shockPotency',
	poisonPotency = 'poisonPotency',
	doomPotency = 'doomPotency',

	allAilmentPotencyMult = 'allAilmentPotencyMult',
	damagingAilmentPotencyMult = 'damagingAilmentPotencyMult',
	statusAilmentPotencyMult = 'statusAilmentPotencyMult',

	knockbackResist = 'knockbackResist',
	
	saintsSpearChance = 'saintsSpearChance',








	/**********************/
	/*** UNUSED FOR NOW ***/
	/**********************/
	/* 🛑 HEY YOU, if you enable/implement these, move them up above eh? 🛑 */
	critChance = 'critChance',
	critDamage = 'critDamage',

	

	// stuff for energy weapons
	maxEnergy = 'maxEnergy',
	energyRegen = 'energyRegen',
	primaryFireEnergyCost = 'primaryFireEnergyCost',

	// stuff for beams
	beamRampUpDamageMult = 'beamRampUpDamageMult',

	chargedWeaponDamageMult = 'chargedWeaponDamageMult',

	projectileRicochetCount = 'projectileRicochetCount', // 📎 Did you mean 'projectileChainCount'?

	buffDurationMult = 'buffDurationMult',
	skillUsageSpeedMult = 'skillUsageSpeedMult',

	healthRegenInterval = 'healthRegenInterval',


	/* 🛑 HEY YOU, if you enable/implement these, move them up above eh? 🛑 */

	/* 🛑 HEY YOU, if you enable/implement these, move them up above eh? 🛑 */

	maxShields = 'maxShields',
	shieldRegenRate = 'shieldRegenRate',
	shieldRegenDelay = 'shieldRegenDelay',
	damageTakenMult = 'damageTakenMult',

	/* 🛑 HEY YOU, if you enable/implement these, move them up above eh? 🛑 */
	projectileSpreadVariance = 'projectileSpreadVariance',

}

const enemyStats = {
	maxHealth: null,
	baseDamage: null,
	allDamageMult: null,
	initialImpactDamageMult: null,
	attackRate: null,
	movementSpeed: null,
	attackSize: null,
	attackKnockback: null,
	projectileCount: null,
	projectileSpreadAngle: null,
	projectileSpeed: null,
	projectileLifeSpan: null,
	allAilmentPotencyMult: null,
	damagingAilmentPotencyMult: null,
	statusAilmentPotencyMult: null,
	knockbackResist: null,
	chillChance: null,
	chillPotency: null,
	igniteChance: null,
	ignitePotency: null,
	poisonChance: null,
	poisonPotency: null,
	shockChance: null,
	shockPotency: null,
	stunChance: null,
	stunPotency: null,
	bleedChance: null,
	bleedPotency: null
}

const playerStats = {
	maxHealth: null,
	baseDamage: null,
	allDamageMult: null,
	initialImpactDamageMult: null,
	bossDamageMult: null,
	attackRate: null,
	movementSpeed: null,
	walkSpeedScalar: null,
	attackPierceCount: null,
	attackSize: null,
	attackKnockback: null,
	projectileCount: null,
	projectileSpeed: null,
	projectileSpreadAngle: null,
	projectileChainCount: null,
	projectileChainDistanceMultiplier: null,
	projectileSplitCount: null,
	chargeRate: null,
	placementRange: null,
	pickupRange: null,
	heartDropMulti: null,
	xpReDropChance: null,
	xpValueMulti: null,
	skillDuration: null,
	slowAmount: null,
	maxAmmo: null,
	reloadAmmoIncrement: null,
	reloadInterval: null,
	cooldownInterval: null,
	stunChance: null,
	shockChance: null,
	poisonChance: null,
	bleedChance: null,
	igniteChance: null,
	chillChance: null,
	doomChance: null,
	bleedPotency: null,
	ignitePotency: null,
	chillPotency: null,
	shockPotency: null,
	poisonPotency: null,
	doomPotency: null,
	allAilmentPotencyMult: null,
	damagingAilmentPotencyMult: null,
	statusAilmentPotencyMult: null,
}

const unusedStats = {
	critChance: null,
	critDamage: null,
	projectileSplashRadius: null,
	projectileSplashDamage: null,
	maxEnergy: null,
	energyRegen: null,
	primaryFireEnergyCost: null,
	beamRampUpDamageMult: null,
	chargedWeaponDamageMult: null,
	projectileRicochetCount: null,
	buffDurationMult: null,
	skillUsageSpeedMult: null,
	healthRegen: null,
	healthRegenDelay: null,
	healingReceived: null,
	maxShields: null,
	shieldRegenRate: null,
	shieldRegenDelay: null,
	damageTakenMult: null,
	projectileSpreadVariance: null,
}

export const AllStatPropNames: StatType[] = Object.values(StatType)
export type StatName = keyof typeof StatType
export type EnemyStatNames = keyof typeof enemyStats
export type PlayerStatNames = keyof typeof playerStats
