<template>
	<div id="chapter-summary">
		<div class="main">
			<div class="chat-button">
				<IconButton :icon-name="'chat'" :button-style="'green'" :on-click-prop="openActivityModal"></IconButton>
			</div>
			<div class="chapter-select"> <!-- Title, chapter selector box at top -->			
				<Header :header-text="selectedStory.name" :text-alignment="'center'" :font-size="'extraLarger'" :font-color="'green'"></Header>
				<div class="authored-header">
					<StoryDifficultyMap style="margin-bottom: 2px;" :map="selectedStory.mapSelection" :difficulty="selectedStory.difficulty"></StoryDifficultyMap>
					<div style="width: 20px;"></div>
					<Header :header-text="`Authored by: ${selectedStory.author}`" :text-alignment="'center'" :font-size="'medium'" :font-color="'offWhite'" :enableDropShadow="false"></Header>
				</div>
				<br>
				<div class="chapter-selector-container">
					<div class="chapter-selector">
						<Pagination :disablePips="true" :color="'offWhite'" :title-string="selectedChapterNumberTitle" @on-click-back-fn="goToPreviousChapter" @on-click-forward-fn="goToNextChapter"></Pagination>
					</div>
				</div>
			</div>
			<div class="story-stats"> <!-- Entire mid section -->
				<div class="leaderboard-table-container">
					<div class="embellishment">
						<img src="../../../assets/ui/divider/separator.png" alt="" />
					</div>
					<div v-if="!chapterRunDataLoading" class="leaderboard">
						<ChapterSummaryTable :table-row-data="selectedChapterTableStats"></ChapterSummaryTable>
					</div>
					<div v-else class="leaderboard">
						<ChapterSummaryTable :table-row-data="[]"></ChapterSummaryTable>
					</div>
				</div>
				<br>
				<div class="twists">
					<div class="twists-for-vote">
						<div class="embellishment">
							<img src="../../../assets/ui/divider/separator.png" alt="" />
						</div>
						<br>
						<Header header-text="Twists Up for Vote" :text-alignment="'center'" :font-face="'pixl'" :font-size="'medium'" :font-color="'offWhite'" :enable-drop-shadow="false"></Header>
						<div class="vote-timer" :lang="this.$i18n.locale" v-if="!this.selectedStory.isSolo">
							<div v-if="isVotingOpen">Voting ends in {{ voteEndTime }}</div>
							<div v-else>Voting has ended: next Chapter is open!</div>
						</div>
						<br>
						<div class="icon-container">
							<div @click="showVoteProgress" v-for="twist in indexedTwists" :key="twist" class="individual-twist">
								<ItemUpdated :item="twist" :component="'twist'" :tooltip-enabled="true" :medium-icons="true"></ItemUpdated>
								<div class="player-container" v-if="twist.userInfo" :class="{ scuffedIconPosition: twist.userInfo.length === 1}">
									<PlayerAvatar :icon-tag="twist.userInfo[0].iconTag" :icon-color="twist.userInfo[0].iconColor" :username="''" :icon-size="'this-sucks'" :show-username="false" :show-icon="true" :show-background="true"></PlayerAvatar>
									<div v-if="twist.userInfo.length > 1" class="elipses">
										...
									</div>
								</div>
								<div v-else class="empty-player-container">
								</div>
							</div>
						</div>
					</div>
					<div class="current-twists">
						<div class="embellishment">
							<img src="../../../assets/ui/divider/separator.png" alt="" />
						</div>
						<transition name="fade" @after-leave="twistTransitionFinished" appear>
							<div v-if="!startTransition" class="twists-wrapper">
								<br>
								<Header :header-text="'Current Twists'" :text-alignment="'center'" :font-size="'medium'" :font-face="'pixl'" :font-color="'offWhite'"></Header>
								<div class="twists-container">
									<ItemUpdated v-for="twist in getStoryTwists" :key="twist" :item="twist" :component="'twist'" :no-book="true" :tooltip-enabled="true" :small-icons="false"></ItemUpdated>
								</div>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-container"> <!-- bottom box, return, twists up for vote, voting has ended -->
			<div class="group" :style="{ width: `${currentSettings.width}px` }">
				<div class="active-twists-container">
				</div>
				<div class="button-container">
					<TextButton :button-label="$t(`chapter_select.button_return`)" :button-style="'green'" @on-click-fn="backButton"></TextButton>
					<TextButton v-if="Views.CHARACTER_SELECT !== previousView" :button-label="$t(`chapter_select.button_character_select`)" :button-style="'green'" @on-click-fn="changeView" :optional-param="{ view: Views.CHARACTER_SELECT, urlParam: storyParam, value: getSelectedStory.id }"></TextButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Background from '../ui-components/background/background.vue'
import ChapterSummaryTable from '../ui-components/table/chapter-summary-table.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import Panel from '../ui-components/panel/panel.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import ItemUpdated from '../ui-components/item/items-updated.vue'
import GameIdContainer from '../ui-components/container/text-container.vue'
import ScrollableItemsContainer from '../ui-components/arrow/arrow-background.vue'
import Pagination from '../ui-components/pagination/pagination.vue'
import ItemPagination from '../ui-components/container/item-pagination.vue'
import parseISO from 'date-fns/parseISO'
import ActivityFeed from '../ui-components/activity-feed/activity-feed.vue'
import { differenceInMilliseconds, formatDistance } from 'date-fns'
import { Views } from '../store/ui-store'
import { STORY_PAGE_PARAM_KEY } from '../store/end-chapter-store'
import StoryDifficultyMap from '../ui-components/story-difficulty-map.vue'
import IconButton from '../ui-components/buttons/icon-button.vue'
import PlayerAvatar from '../ui-components/item/player-avatar.vue'

export default {
	name: 'ChapterSummary',
	components: {
		Background,
		ChapterSummaryTable,
		Header,
		Panel,
		TextButton,
		ItemUpdated,
		GameIdContainer,
		ScrollableItemsContainer,
		Pagination,
		ItemPagination,
		ActivityFeed,
		StoryDifficultyMap,
		IconButton,
		PlayerAvatar
	},
	data() {
		return {
			Views: Views,
			playerChapterHeader: [{ headerName: '' }, { headerName: 'Character' }, { headerName: 'Enemies Slain' }, { headerName: 'Run Time' }, { headerName: 'Score' }, { headerName: 'Win' }],
			currentTwistsPage: 1,
			itemPerPage: 14,
			largeActivityFeed: false,
			startTransition: false,
			showHideArrow: false,
			storyParam: STORY_PAGE_PARAM_KEY,
			currentChapter: 0
		}
	},
	props: {},
	watch: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('story', ['selectedStoryId', 'selectedStory', 'selectedChapter', 'selectedChapterTableStats', 'oldSelectedChapter', 'chapterRunDataLoading']),
		...mapGetters('story', ['getStoryTwists', 'votingTimeLeft', 'getSelectedStory', 'isVotingOpen']),
		...mapState('time', ['date']),
		...mapState('endChapter', ['indexedTwists']),
		...mapState('ui', ['activeView', 'previousView']),
		...mapState('settings', ['currentSettings']),
		voteEndTime() {
			const endTime = parseISO(this.getSelectedStory.nextTally)
			return formatDistance(endTime, this.date)
		},
		currentChapterNumber() {
			return this.selectedStory.chapter
		},
		selectedChapterNumberTitle() {
			if (this.currentChapter > 0) {
				return `Chapter ${this.currentChapter}`
			} else {
				return `Prelude`
			}
		}
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('story', ['updateSelectedChapter']),
		...mapMutations('ui', ['registerKeystrokeHandler', 'updatePreviousHandler', 'updateDarkenOverlay']),
		...mapActions('ui', ['changeActiveView']),
		...mapActions('story', ['fetchChapterRuns']),
		...mapActions('endChapter', ['fetchMutators']),
		...mapMutations('endChapter', ['updateShowVoteDetailsModal']),
		...mapMutations('activityFeed', ['updateActivityFeedModal']),

		changeView(params) {
			const { view, value, urlParam } = params
			this.changeActiveView(view)
		},
		selectChapter(chapter) {
			this.updateSelectedChapter(chapter)
			this.fetchChapterRuns(chapter)
		},
		chapterButtonActive(chapter) {
			return this.selectedChapter === chapter
		},
		arrowButton(direction) {
			const el = document.getElementById('chapter-1')
			const scrollableContainer = document.getElementById('moveable')
			let width = el.offsetWidth
			let style = getComputedStyle(el)
			width += parseInt(style.marginLeft) + parseInt(style.marginRight)

			switch (direction) {
				case 'left':
					scrollableContainer.scrollTo({ left: scrollableContainer.scrollLeft - width * 3, behavior: 'smooth' })
					break
				case 'right':
					scrollableContainer.scrollTo({ left: scrollableContainer.scrollLeft + width * 3, behavior: 'smooth' })
					break
			}
		},
		backButton() {
			this.changeActiveView(this.previousView)
		},

		beginActivityFeedTransition() {
			this.startTransition = !this.startTransition
			if (!this.startTransition) {
				this.changeChangeSize(false)
			}
		},
		twistTransitionFinished() {
			this.changeChangeSize(true)
		},
		changeChangeSize(toggle) {
			this.largeActivityFeed = toggle
		},
		goToNextChapter() {
			this.currentChapter++
			if (this.currentChapter > this.selectedStory.chapter) {
				this.currentChapter = 0
			}

			this.selectChapter(this.currentChapter)
		},
		goToPreviousChapter() {
			if (this.currentChapter > 0) {
				this.currentChapter--
			} else {
				this.currentChapter = this.selectedStory.chapter
			}

			this.selectChapter(this.currentChapter)
		},
		openActivityModal() {
			this.updateActivityFeedModal(true)
			this.updateDarkenOverlay(true)
		},
		showVoteProgress() {
			this.updateShowVoteDetailsModal(true)
		},
		shouldShowSelectedPip(twist) {
			return twist.count > 0
		}
	},
	mounted() {
		// Save whatever the previous handler was (undefined is valid) since we can navigate to this page from various sources
		this.updatePreviousHandler()

		const callBacks = [
			{
				keys: ['Escape'],
				callBack: this.backButton.bind(this)
			},
		]
		
		this.registerKeystrokeHandler(callBacks)
		this.fetchMutators()
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#chapter-summary {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	&.ratio21By9 {
		.bottom-container {
			.group {
				margin: 0 auto;
				width: 1920px;
			}
		}
	}
	.main {
		height: calc(100% - 170px);
		margin: calc(150px / 4) auto auto;

		width: 100%;

		.chat-button {
			position: absolute;
			top: 30px;
			left: 30px;
			z-index: 100;
		}

		.chapter-select {
			.authored-header {
				display: flex;
				align-items: flex-end;
				justify-content: center;
			}

			.chapter-selector-container {
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				
				.chapter-selector {
					width: 350px;
				}
			}
		}

		.story-stats {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 100%;

			margin: 44px 0px;
			.leaderboard-table-container {
				width: 90%;
				position: relative;
				.embellishment {
					position: absolute;
					top: -16px;
					z-index: 999999999999;
				}
			}

			.twists {
				display: flex;
				justify-content: space-between;
				width: 90%;

				.current-twists {
					width: 49%;
					height: 250px;
					background-color: @darkBlue;
					position: relative;

					.embellishment {
						position: absolute;
						top: -16px;
						right: 0;
						transform: scaleX(-1);
					}

					.twists-wrapper {
						width: 100%;
						height: 247px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						.items {
							position: relative;
						}
						
						.twists-container {
							display: flex;
							flex-wrap: wrap;
							width: 95%;
							height: 100%;
							overflow-y: scroll;
						}
					}
				}

				.twists-for-vote {
					width: 49%;
					height: 250px;
					background-color: @darkBlue;
					position: relative;

					.embellishment {
						position: absolute;
						top: -16px;
						left: 0;
					}

					.icon-container {
						display: flex;
						justify-content: space-around;
					
						.individual-twist {
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;

							.player-container {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 100%;
								height: 50px;
								margin-top: -4px;

								:deep(#player-avatar) {
									scale: 0.3;
									transform-origin: left center;
									width: 73px;
								}

								&.scuffedIconPosition {
									:deep(#player-avatar) {
										margin-left: 11px;
									}
								}

								.elipses {
									text-align: left;
									letter-spacing: -1px;
									.regularFont(@enSecondaryFontPTBold);
									color: @limerGreen;
									font-size: 37px;
									margin-left: -25px;
									margin-bottom: -11px;
								}
							}

							.empty-player-container {
								height: 50px;
							}
						}
					}

					.vote-timer {
						text-align: center;
						&[lang='en'] {
							.regularFont(@enRegularFontPixl);
							color: @limerGreen;
							font-size: 30px;
						}
						&[lang='zh'] {
							.regularFont(@zhHansSecondaryFont);
							font-size: 30px;
						}
					}
				}
			}
		}
	}

	.bottom-container {
		position: absolute;
		height: 150px;
		width: 100%;
		bottom: 0px;
		.group {
			max-width: 1920px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;
			position: relative;
			padding: 0px 60px;
		}
		.button-container {
			width: 100%;
			display: flex;
			justify-content: space-between;
			pointer-events: all;
			margin: 0px 9px;
			.text-button {
				width: 320px;
				height: 100px;
			}
		}
		.active-twists-container {
			width: 100%;
			padding: 0px 450px;
			position: absolute;
			right: 0;
			display: flex;
			justify-content: space-between;
			.twits {
				display: flex;
				flex-direction: column;
				.icon-container {
					display: flex;
				}
			}
			.vote-timer {
				align-self: center;
				color: @green;
				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					font-size: 32px;
					letter-spacing: 2px;
				}
				&[lang='zh'] {
					.regularFont(@zhHansSecondaryFont);
					font-size: 32px;
				}
			}
		}
	}
}

.fade-enter-active {
	transition: all 0.25s ease-out;
}
.fade-leave-active {
	transition: all 0.25s ease-out;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
