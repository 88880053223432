<template>
	<div id="settings">
		<Header v-if="Views.SETTINGS === activeView" :header-text="'Settings'" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'medium'" :enable-drop-shadow="false" :font-color="'cream'"></Header>
		<Header v-else-if="uiState === UICurrentState.IN_GAME" :header-text="'Paused'" :class="'in-game'" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'large'" :enable-drop-shadow="false" :font-color="'cream'"></Header>
		<div v-if="!isElectron" class="control-scheme">
			<Controls></Controls>
		</div>
		<div v-if="isElectron" class="pagination-container">
			<Pagination :disablePips="true" :title-string="selectCategoryString" @on-click-back-fn="previousCategory" @on-click-forward-fn="nextCategory" :disable="false"></Pagination>
		</div>
		<div class="settings-container">
			<div v-if="defaultSelectedCategory === 'general'">
				<div v-if="!isElectron && allowFullscreen">
					<FullscreenModule></FullscreenModule>
				</div>
				<AutoAimShootModule></AutoAimShootModule>
				<AudioModule></AudioModule>
				<!-- disabled for Steam demo, we'll want to finish/re-enable for release -->
				<AccountLinkingModule></AccountLinkingModule>
				<PlayerIconsModule></PlayerIconsModule>
			</div>
			<div v-else-if="defaultSelectedCategory === 'video'">
				<WindowModeModule></WindowModeModule>
				<ResolutionModule></ResolutionModule>
			</div>
			<div v-else-if="defaultSelectedCategory === 'controls'">
				<EmbellishmentContainer>
					<template #embellishment-content>
						<div class="visit-steam-controller" :lang="this.$i18n.locale">
							{{ $t('settings.visit_steam_controller_settings') }}
						</div>
					</template>
				</EmbellishmentContainer>
				<!-- <ControllerSelectorModule></ControllerSelectorModule> -->
				<InputRebindingModule></InputRebindingModule>
			</div>
		</div>
		<div class="button-container">
			<TextButton v-if="uiState === UICurrentState.IN_GAME" :button-label="$t('settings.abandon_story')" :button-style="'blue'" :fontColor="'failureRed'" @on-click-fn="leaveChapter"></TextButton>
			<TextButton :button-label="$t('settings.return')" :button-style="'bright-green'" @on-click-fn="closeAndSave"></TextButton>
			<div style="height: 35px"></div>
			<TextButton :button-label="$t('discord.report_issue')" :button-style="'blue'" :fontColor="'failureRed'" @on-click-fn="openDiscord"></TextButton>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { UICurrentState, Views } from '../store/ui-store'

import EmbellishmentContainer from '../ui-components/embellishment-container.vue'
import Toggle from '../ui-components/form-inputs/toggle.vue'
import Range from '../ui-components/form-inputs/range.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import Controls from '../ui-components/controls.vue'
import Radial from '../ui-components/form-inputs/radial.vue'
import ItemPagination from '../ui-components/container/item-pagination.vue'
import { getIconTags, getIconColors } from '../store/settings-store'
import Pagination from '../ui-components/pagination/pagination.vue'
import FullscreenModule from '../ui-components/settings/fullscreen.vue'
import AudioModule from '../ui-components/settings/audio.vue'
import PlayerIconsModule from '../ui-components/settings/player-icons.vue'
import WindowModeModule from '../ui-components/settings/window-mode.vue'
import ResolutionModule from '../ui-components/settings/resolution-select.vue'
import ControllerSelectorModule from '../ui-components/settings/controller-selector.vue'
import InputRebindingModule from '../ui-components/settings/input-binding.vue'
import AccountLinkingModule from '../ui-components/settings/account-linking.vue'
import AutoAimShootModule from '../ui-components/settings/auto-aim-shoot.vue'

import { paginationMixins } from '../ui-components/mixins/Pagination-mixin'

export default {
	name: 'Settings',
	components: {
		EmbellishmentContainer,
		Toggle,
		Range,
		Header,
		TextButton,
		Controls,
		Radial,
		ItemPagination,
		Pagination,
		FullscreenModule,
		AudioModule,
		PlayerIconsModule,
		WindowModeModule,
		ResolutionModule,
		ControllerSelectorModule,
		InputRebindingModule,
		AccountLinkingModule,
		AutoAimShootModule,
	},
	data() {
		return {
			UICurrentState: UICurrentState,
			Views: Views,
			categories: [
				{ id: 'general', name: 'settings.category_general' },
				{ id: 'video', name: 'settings.category_video' },
				{ id: 'controls', name: 'settings.category_controls' },
			],
			defaultSelectedCategory: 'general',
			isElectron: process.env.IS_ELECTRON,
		}
	},
	props: {},
	computed: {
		...mapState('settings', ['fullscreen', 'autoAim', 'autoShoot', 'bgmVolume', 'sfxVolume', 'keyboardControls', 'currentSettings', 'oldSettings']),
		...mapState('ui', ['uiState', 'activeView']),
		...mapGetters('ui', ['webPortalConfig', 'allowFullscreen']),
		...mapGetters('settings', ['getIsSettingsEqual', 'getCurrentBGMVolume', 'getCurrentSFXVolume', 'getSelectedColor', 'getSelectedIcon']),
		...mapState('user', ['isPlayerCodeInfoLoading']),
		getPlayerIcons() {
			return getIconTags()
		},
		getPlayerIconColors() {
			return getIconColors()
		},
	},
	methods: {
		...mapMutations('settings', ['updateBGMVolume', 'toggleAutoAim', 'toggleAutoShoot', 'updateSFXVolume', 'updateSelectedColor', 'updateSelectedIcon']),
		...mapActions('settings', ['closeSettings', 'saveSettings', 'checkFullscreen']),
		...mapActions('endChapter', ['leaveChapter']),
		...mapMutations('ui', ['setActiveView', 'registerKeystrokeHandler', 'updatePreviousHandler', 'unregisterKeystrokeHandler', 'restorePreviousHandler']),
		...mapActions('user', ['generateAccountLinkCode']),

		closeAndSave() {
			this.saveSettings()
			this.closeSettings()
		},
		openDiscord() {
			window.open('https://discord.gg/xbXhxe7Dxs')
		},
	},
	mixins: [paginationMixins],
	mounted() {
		if (this.uiState !== UICurrentState.IN_GAME) {
			this.updatePreviousHandler()
			const callBacks = [
			{
				keys: ['Escape'],
				callBack: this.closeAndSave.bind(this)
			}
			] 
			this.registerKeystrokeHandler(callBacks)
		}
	}
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');
#settings {
	width: 780px;
	margin: auto;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.pagination-container {
		width: 400px;
		margin: 0 auto;
	}
	.control-scheme {
		position: absolute;
		right: -125px;
		margin: 10px 0px;
		transform: translateY(-50%);
	}
	.settings-container {
		overflow-y: auto;
		overflow-x: hidden;

		width: 712px;
		height: 570px;
		overflow-y: auto;
		margin: 0 auto;
		:deep(.content) {
			display: flex;
			align-items: center;
		}
		.controls-container {
			.label {
				color: @offWhite;
				font-size: 30px;
				width: 660px;
				padding-left: 50px;
				&[lang='en'] {
					.regularFont(@enSecondaryFontPTBold);
					font-variant: small-caps;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
			}
		}

		.visit-steam-controller {
			margin-left: 50px;
			&[lang='en'] {
				.regularFont(@enSecondaryFontPTBold);
				font-variant: small-caps;
				font-size: 30px;
				color: @offWhite;
			}
			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}

		.centered {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}
	}
	.button-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		:deep(.text-button) {
			margin: 10px 0px;
			width: 500px;
		}
	}
}
</style>
