import { GroundPickupConfigType } from "../entities/pickups/ground-pickup-types"
import { Player } from "../entities/player"
import { timeInSeconds } from "../utils/primitive-types"

export interface XpDropShuffleBagEntry {
    pickupType: GroundPickupConfigType
    count: number
}

export interface ShuffleBagEntryOverTime {
    start: timeInSeconds
    entries: XpDropShuffleBagEntry[]
}

export const CHANCE_TO_DROP_PICKUP = 0.012

export function levelToXPThreshold(level: number) {
	const idx = Math.clamp(level, 1, 50) - 1
	return xpNeededToLevel[idx]
}

export const NO_MORE_XP_TIME = 900

export type LevelUpCallback = (player: Player) => void
export type CallbacksForLevel = { 
	level: number,
	callbacks: LevelUpCallback[]
}

export const ITEM_DROP_SHUFFLE_BAG_ENTRIES: ShuffleBagEntryOverTime[] = [
    { 
        start: 0,
        entries: [
            { pickupType: GroundPickupConfigType.None, count: 5 },
            { pickupType: GroundPickupConfigType.XPSmall, count: 5 }
        ]
    },
    { 
        start: 151,
        entries: [
            { pickupType: GroundPickupConfigType.None, count: 10 },
            { pickupType: GroundPickupConfigType.XPSmall, count: 10 }
        ]
    },
    {
        start: 301,
        entries: [
            { pickupType: GroundPickupConfigType.None, count: 10 },
            { pickupType: GroundPickupConfigType.XPSmall, count: 5 },
            { pickupType: GroundPickupConfigType.XPMedium, count: 5 }
        ]
    },
    {
        start: 421,
        entries: [
            { pickupType: GroundPickupConfigType.None, count: 10 },
            { pickupType: GroundPickupConfigType.XPMedium, count: 10 }
        ]
    },
    {
        start: 601,
        entries: [
            { pickupType: GroundPickupConfigType.None, count: 12 },
            { pickupType: GroundPickupConfigType.XPMedium, count: 3 },
            { pickupType: GroundPickupConfigType.XPLarge, count: 5 },
        ]
    },
    {
        start: 691,
        entries: [
            { pickupType: GroundPickupConfigType.None, count: 12 },
            { pickupType: GroundPickupConfigType.XPLarge, count: 8 },
        ]
    },
    {
        start: 751,
        entries: [
            { pickupType: GroundPickupConfigType.None, count: 14 },
            { pickupType: GroundPickupConfigType.XPLarge, count: 6 },
        ]
    },
    {
        start: NO_MORE_XP_TIME,
        entries: [
            { pickupType: GroundPickupConfigType.None, count: 10 },
        ]
    },
]

const xpNeededToLevel = [
	10, //L2
	15, //L3
	16, //L4
	18, //L5
	20, //L6
	25, //L7
	30, //L8
	35, //L9
	40, //L10
	45, //L11
	55, //L12
	60, //L13
	65, //L14
	70, //L15
	75, //L16
	80, //L17
	85, //L18
	90, //L19
	95, //L20
	100, //L21
	110, //L22
	120, //L23
	130, //L24
	140, //L25
	150, //L26
	160, //L27
	180, //L28
	200, //L29
	230, //L30
	260, //L31
	290, //L32
	320, //L33
	370, //L34
	420, //L35
	470, //L36
	520, //L37
	560, //L38
	600, //L39
	650, //L40
	700, //L41
	750, //L42
	800, //L43
	850, //L44
	900, //L45
	1000, //L46
	1100, //L47
	1200, //L48
	1300, //L49
	1400, //L50
	1500, //L51
]