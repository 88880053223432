import { throttle } from "lodash"
import { Audio } from "../../engine/audio"
import { GameState } from "../../engine/game-state"
import { Camera, getVisibleWorldHeight, getVisibleWorldWidth } from "../../engine/graphics/camera-logic"
import { EnemySpawnRateDefinition, GroupAmountModifier, ReplaceEnemyDefinition } from "../../mutators/mutator-definitions"
import { UI } from "../../ui/ui"
import { debugConfig } from "../../utils/debug-config"
import { percentage, timeInSeconds } from "../../utils/primitive-types"
import { ObjectPoolTyped } from "../../utils/third-party/object-pool"
import { ACT_TIMER, InGameTime } from "../../utils/time"
import { ExplosionGroundHazard, ExplosionGroundHazardParams } from "../hazards/explosion-ground-hazard"
import AISystem from "./ai-system"
import { Enemy } from "./enemy"
import { BOSS_ENEMY_NAMES, ENEMY_NAME } from "./enemy-names"
import { Directions, EnemySpawnValues, ENEMY_SPAWN_CONFIG, EnemySpawnRateRecord } from "./enemy-spawn-config"
import { MapNames } from "../../world-generation/world-data"
import { BossEventConfig, BOSS_EVENTS_BY_MAP_AND_ACT } from "./boss-event-spawner"
import { MapSystem } from "../../world-generation/map-system"
import { randomRange } from "../../utils/math"
import { callbacks_addCallback, callbacks_removeCallbacksFromOwner } from "../../utils/callback-system"
import isThisHour from "date-fns/isThisHour/index"
import { cloneDeepSerializable } from "../../utils/object-util"

/** While a boss is alive, log their hp to console */
const DEBUG_ENABLE_BOSS_HP_LOGGING = false
const MAX_SPAWN_RATE_MULTIPLIER = 5.0
const HEIGHT_VARIANCE = 500
const WIDTH_VARIANCE = 500
const OFFSET_X = 50
const OFFSET_Y = 50

const SUPPRESS_SPAWN_MIN = 3
const SUPPRESS_SPAWN_MAX = 40

interface SpawnWorldBounds {
	minX: number
	maxX: number
	minY: number
	maxY: number
}

export interface EnemyPackConfig {
	enemyName: ENEMY_NAME
	equilibriumAmount: number
	maxAmount: number
	spawnRate: timeInSeconds
	spawnAmount: number // The total amount of enemies to spawn
	groupAmount?: { min: number, max: number } // the min & max (of the above) to spawn as a group
	isBoss?: boolean
	isEventSpawn?: boolean
	ignoreSpawnChanges?: boolean
}

export interface EnemyPackStageConfig {
	min: timeInSeconds
	max: timeInSeconds

	packs: EnemyPackConfig[]
}

export interface EnemyPackInstance extends EnemyPackConfig {
	// timing
	acc?: timeInSeconds
	totalSpawned?: number
	lastEquilibriumAmount?: number

	// UI values
	currentAmount?: number
	lastSpawnRateMulti?: percentage
	calcMaxAmount?: number
	calcSpawnRate?: timeInSeconds
}

export interface EnemyPackStage extends EnemyPackStageConfig {
	packs: EnemyPackInstance[]
}


export default class EnemyEquilibriumSpawner {
	static getInstance() {
		if (!EnemyEquilibriumSpawner.instance) {
			EnemyEquilibriumSpawner.instance = new EnemyEquilibriumSpawner()
		}

		return EnemyEquilibriumSpawner.instance
	}
	static destroy() {
		EnemyEquilibriumSpawner.instance = null
	}
	private static instance: EnemyEquilibriumSpawner

	private stages: EnemyPackStage[] = []
	private spawnValues: EnemySpawnValues = {
		allEnemySpawnRate: 1.0,
		act1EnemySpawnRate: 1.0,
		act2EnemySpawnRate: 1.0,
		act3EnemySpawnRate: 1.0,

		allEnemySpawnMax: 1.0,
		act1EnemySpawnMax: 1.0,
		act2EnemySpawnMax: 1.0,
		act3EnemySpawnMax: 1.0,
	}
	temporarySpawnRateMulti: percentage = 1.0
	temporarySpawnMaxMulti: percentage = 1.0

	mapName: MapNames
	bossConfig: BossEventConfig

	packsToAdd: EnemyPackStage[]

	private displayedAct1Intro = false
	private displayed1stBossIntro = false
	private displayed2ndBossIntro = false
	private displayed3rdBossIntro = false

	private enemiesToReplace: ReplaceEnemyDefinition[]
	private groupingModifiers: GroupAmountModifier[]
	private enemySpawnRateModifiers: EnemySpawnRateRecord

	private secondPackStartTime: number = undefined

	bruteTrioActive: boolean = false
	bruteTrioSpawnCount: number = 0
	bruteTrioAct2BossesKilled: number = 0
	bruteTrioEventStarted: boolean = false

	enableDramaticShowdownTwist:boolean = false
	dramaticShowDownBossSpawned: boolean = false

	constructor() {
		this.enemiesToReplace = []
		this.groupingModifiers = []
		this.enemySpawnRateModifiers = {}
		// Putting this here since only blimpies use explosions as ground hazards so far, but we can move it if somewhere else is preferable
		if (!ExplosionGroundHazard.pool) {
			ExplosionGroundHazard.pool = new ObjectPoolTyped<ExplosionGroundHazard, ExplosionGroundHazardParams>(() => new ExplosionGroundHazard(), {}, 10, 1)
		}

		if (DEBUG_ENABLE_BOSS_HP_LOGGING) {
			setInterval(() => {
				GameState.enemyList.forEach((e) => {
					const enemy = e as Enemy
					if (BOSS_ENEMY_NAMES.includes(enemy.name)) {
						console.log(`${enemy.name}: ${enemy._currentHealth}/${enemy.maxHealth}`)
					}
				})
			}, 1000)
		}
	}

	init() {
		this.setPacksToAdd()
	}

	setPacksToAdd() {
		const mapName = MapSystem.getInstance().mapName
		const packConfig = ENEMY_SPAWN_CONFIG[mapName].ENEMY_SPAWN_TIME_STAGES
		// console.log(`Pack config for ${mapName}: `, packConfig)
		if (!packConfig) {
			console.warn(`No pack config found for ${mapName}.`)
			return
		}
		this.packsToAdd = [
			...packConfig,
		]
		const bossConfig = BOSS_EVENTS_BY_MAP_AND_ACT[mapName]
		// console.log(`Boss config for ${mapName}: `, bossConfig)
		if (!bossConfig) {
			console.warn(`No boss config found for ${mapName}.`)
			return
		}
		this.bossConfig = bossConfig
		const ACT_BOSS_TIMES = { 1: { min: 300, max: 325 }, 2: { min: 600, max: 625 }, 3: { min: 900, max: 925 }}
		let act = 1
		this.bossConfig.forEach((bossEvent) => {
			this.packsToAdd.push({
				min: ACT_BOSS_TIMES[act].min,
				max: ACT_BOSS_TIMES[act].max,
				packs: bossEvent.packs,
			})
			// console.log(`Added boss pack: `, this.packsToAdd[this.packsToAdd.length-1])
			act++
		})
	}

	addPacks(stages: EnemyPackStage[]) {
		for (let i = 0; i < stages.length; ++i) {
			const stage = stages[i]
			const existingStage = this.packsToAdd.find((s) => s.min === stage.min && s.max === stage.max)
			if (existingStage) {
				existingStage.packs.push(...stage.packs)
			} else {
				this.packsToAdd.push(stage)
			}
		}
	}

	replaceEnemies(replacer: ReplaceEnemyDefinition) {
		this.enemiesToReplace.push(replacer)
	}

	modifyGrouping(groupingMod: GroupAmountModifier[]) {
		this.groupingModifiers.concat(groupingMod)
	}

	setSecondPackStartTime(startTime: number) {
		this.secondPackStartTime = startTime
	}

	initStages() {
		this.packsToAdd.sort((a, b) => a.min - b.min)
		this.stages.length = 0

		for (let i = 0; i < this.packsToAdd.length; ++i) {
			const oldStage = this.packsToAdd[i]
			const stage = cloneDeepSerializable(oldStage) as EnemyPackStage
			for (const pack of stage.packs) {
				pack.currentAmount = 0
				pack.acc = pack.spawnRate
				pack.totalSpawned = 0
				pack.lastEquilibriumAmount = 0

				const replacement = this.enemiesToReplace.find(e => e.enemy === pack.enemyName)
				if (replacement) {
					pack.enemyName = replacement.replacement
				}

				const enemyGroupingMod = this.groupingModifiers.find(e => e.enemy === pack.enemyName)
				if (enemyGroupingMod) {
					pack.groupAmount = enemyGroupingMod.groupAmount
				}

				for (let r = i - 1; r >= 0; --r) {
					const pastStage = this.packsToAdd[r]
					if (stage.min - pastStage.max <= 2) {
						const enemyInLastPack = pastStage.packs.find(p => p.enemyName === pack.enemyName)
						if (enemyInLastPack) {
							pack.lastEquilibriumAmount = enemyInLastPack.equilibriumAmount
							break
						}
					}
				}
			}

			this.stages.push(stage)
		}

		this.packsToAdd.length = 0

		if (this.secondPackStartTime !== undefined) {
			this.stages[0].max = this.secondPackStartTime
			this.stages[1].min = this.secondPackStartTime + 1
		}
	}

	updateUI = throttle(function (enemyCountsByName: {}) {
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const activeStages = []
		const activeEnemyNames = []

		for (const stage of this.stages) {
			if (currentTimeInSeconds.between(stage.min, stage.max)) {
				activeEnemyNames.push(...stage.packs.map((pack) => pack.enemyName))
				activeStages.push(stage)
			}
		}
		// console.log(`${highResolutionTimestamp()} updating UI with ${activeStages.length} stages and ${activeEnemyNames.join(',')} enemies active`)
		UI.getInstance().emitMutation('debug/updateSpawnerStages', activeStages)

		const stragglerEnemies = []
		for (const enemyName in enemyCountsByName) {
			if (activeEnemyNames.includes(enemyName)) {
				return
			}
			const count = enemyCountsByName[enemyName]
			stragglerEnemies.push({
				enemyName,
				currentAmount: count,
			})
		}
		UI.getInstance().emitMutation('debug/updateStragglerEnemies', stragglerEnemies)
	}, 500)

	triggerBossMessage(msg: [string, string], intensity: percentage, times: number) {
		for (let i = 0; i < times; i++) {
			setTimeout(() => {
				Camera.getInstance().triggerShake(intensity)
			}, i * 200)
		}
		setTimeout(() => {
			UI.getInstance().emitMutation('ui/addNewBigMessage', msg)
		}, 500)
	}

	update(delta: timeInSeconds): void {
		if (debugConfig.enemy.disableSpawning || this.dramaticShowDownBossSpawned) {
			return
		}

		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const currentTimeInSecondsRounded = ~~currentTimeInSeconds


		if (!this.displayedAct1Intro && ~~currentTimeInSecondsRounded === ACT_TIMER.ACT_1_INTRO_BANNER_TIME) {
			UI.getInstance().emitMutation('ui/addNewBigMessage', ['', `Act 1`]) // subtitle sized
			this.displayedAct1Intro = true
		}

		if (!this.displayed1stBossIntro && ~~currentTimeInSecondsRounded === ACT_TIMER.ACT_1_END_TIME - 1) {
			this.triggerBossMessage([this.bossConfig[0].title, this.bossConfig[0].subtitle], 0.8, 3)
			setTimeout(() => {
				Audio.getInstance().playSfx('SFX_Boss_Crab_Emerge')
				Audio.getInstance().playSfx('SFX_Boss_Fungi_Growl')
			}, 133)
			this.displayed1stBossIntro = true
		}
		if (!this.displayed2ndBossIntro && ~~currentTimeInSecondsRounded === ACT_TIMER.ACT_2_END_TIME) {
			if (this.bruteTrioActive) {
				this.bruteTrioEventStarted = true
				this.triggerBossMessage(['The Brute Trio', `Bigger and Badder`], 1.0, 4) //help words
				Audio.getInstance().playSfx('SFX_Boss_Crab_Vox_Death')
				for (let i = 0; i < 3; ++i) {
					setTimeout(() => {
						Audio.getInstance().playSfx('SFX_Boss_Forest_Appear_B')
					}, i * 300 + 100);
				}
			} else {
				this.triggerBossMessage([this.bossConfig[1].title, this.bossConfig[1].subtitle], 1.0, 4)
				Audio.getInstance().playSfx('SFX_Boss_Hateful_Grace')
			}

			this.displayed2ndBossIntro = true
		}
		if (!this.displayed3rdBossIntro && ~~currentTimeInSecondsRounded === ACT_TIMER.ACT_3_END_TIME) {
			this.triggerBossMessage([this.bossConfig[2].title, this.bossConfig[2].subtitle], 1.0, 5)
			setTimeout(() => {
				Audio.getInstance().playSfx('SFX_Boss_Forest_Appear_A')
				Audio.getInstance().playSfx('SFX_Boss_Prism_Cocoon_In')
			}, 116)
			setTimeout(() => {
				Audio.getInstance().playSfx('SFX_Boss_Forest_Appear_A')
				Audio.getInstance().playSfx('SFX_Boss_Prism_Cocoon_Out')
			}, 566)
			this.displayed3rdBossIntro = true
		}

		// count number of enemies to compare later
		const enemyCountsByName = {}
		for (let i =0; i < GameState.enemyList.length; ++i) {
			const enemy = GameState.enemyList[i] as Enemy
			const name = enemy.name
			enemyCountsByName[name] = (enemyCountsByName[name] || 0) + 1
		}

		const packsToSpawn: EnemyPackInstance[] = []
		const currentAct = InGameTime.getCurrentAct()
		let spawnRateMulti = this.spawnValues.allEnemySpawnRate * this.temporarySpawnRateMulti
		let spawnMaxMulti = this.spawnValues.allEnemySpawnMax * this.temporarySpawnMaxMulti
		switch (currentAct) {
			case 1:
				spawnRateMulti *= this.spawnValues.act1EnemySpawnRate
				spawnMaxMulti *= this.spawnValues.act1EnemySpawnRate
				break
			case 2:
				spawnRateMulti *= this.spawnValues.act2EnemySpawnRate
				spawnMaxMulti *= this.spawnValues.act2EnemySpawnRate
				break
			case 3:
				spawnRateMulti *= this.spawnValues.act3EnemySpawnRate
				spawnMaxMulti *= this.spawnValues.act3EnemySpawnRate
				break
		}

		for (const stage of this.stages) {
			if (currentTimeInSeconds.between(stage.min, stage.max)) {
				// loop through active stage, active packs
				for (let i = 0; i < stage.packs.length; ++i) {
					const pack = stage.packs[i]
					pack.acc += delta

					pack.calcMaxAmount = pack.ignoreSpawnChanges ? pack.maxAmount : Math.ceil(pack.maxAmount * spawnMaxMulti)
					const enemyCount = enemyCountsByName[pack.enemyName] || 0
					if (pack.totalSpawned === 0) {
						if (pack.ignoreSpawnChanges) {
							pack.totalSpawned = Math.max(pack.lastEquilibriumAmount, enemyCount)
						} else {
							pack.totalSpawned = Math.max(pack.lastEquilibriumAmount * spawnMaxMulti, enemyCount)
						}
					}

					pack.currentAmount = enemyCount
					if (enemyCount > pack.calcMaxAmount - pack.spawnAmount) { // if we can't spawn a full group, get out
						break
					}

					const chapterDifficultySpawnRate = this.enemySpawnRateModifiers[pack.enemyName] ?? 1
					let spawnRate = pack.ignoreSpawnChanges ? pack.spawnRate : pack.spawnRate / spawnRateMulti / chapterDifficultySpawnRate
					
					pack.calcSpawnRate = spawnRate
					let equilibriumAdjustedSpawnRateMulti = 1.0
					let percentBelowEquilibrium
					const packEquilibriumAmount = pack.ignoreSpawnChanges ? pack.equilibriumAmount : pack.equilibriumAmount * spawnMaxMulti
					const equilibriumAmount = Math.min(packEquilibriumAmount, pack.totalSpawned)
					if (enemyCount < equilibriumAmount) {
						// lerp between 100%-n% spawn rate based on how far below the equilibriumAmount we are
						percentBelowEquilibrium = 1 - (enemyCount / equilibriumAmount)
						equilibriumAdjustedSpawnRateMulti = Math.clamp(Math.lerp(1.0, MAX_SPAWN_RATE_MULTIPLIER, percentBelowEquilibrium), 1, MAX_SPAWN_RATE_MULTIPLIER)
					}
					spawnRate /= equilibriumAdjustedSpawnRateMulti

					if (pack.acc >= spawnRate) {
						pack.lastSpawnRateMulti = equilibriumAdjustedSpawnRateMulti

						// console.log(`${Math.round(percentBelowEquilibrium*100)}% below equilibrium, ${spawnRateMulti.toFixedIfFloat(2)}x`, {
						// 	enemyCount,
						// 	enemyMax: pack.maxAmount,
						// 	spawnRate,
						// 	spawnRateMulti,
						// })
						pack.acc -= spawnRate
						packsToSpawn.push(pack)
					}
				}
			}
		}

		// spawn the required number of enemies
		for (const pack of packsToSpawn) {
			if (pack.isBoss && this.enableDramaticShowdownTwist) {
				if (this.bruteTrioActive && this.bruteTrioEventStarted) {
					this.bruteTrioSpawnCount++;
					if (this.bruteTrioSpawnCount === 3) {
						UI.getInstance().emitMutation('player/updatePauseKillstreakTimer', true)
						this.dramaticShowDownBossSpawned = true;
					}
				} else {
					UI.getInstance().emitMutation('player/updatePauseKillstreakTimer', true)
					this.dramaticShowDownBossSpawned = true;
				}
			}

			const enemyCount = enemyCountsByName[pack.enemyName] || 0
			const enemyCountSpace = pack.calcMaxAmount - enemyCount


			let groupAmount = 0
			if (pack.groupAmount) {
				groupAmount = Math.getRandomInt(pack.groupAmount.min, Math.min(pack.groupAmount.max, enemyCountSpace))
			}
			const stragglerSpace = enemyCountSpace - groupAmount
			const stragglerSpawnCount = Math.min(pack.spawnAmount - groupAmount, stragglerSpace)

			if (groupAmount > 0) {
				const enemiesSpawned = AISystem.getInstance().spawnGroup(pack.enemyName, groupAmount)
				if (pack.isBoss || pack.isEventSpawn) {
					enemiesSpawned.forEach((enemy) => {
						enemy.isBoss = pack.isBoss || false
						enemy.isEventSpawn = pack.isEventSpawn || false
					})
				}
			}

			for (let i = 0; i < stragglerSpawnCount; ++i) {
				const enemy = AISystem.getInstance().spawnEnemyAtRandomPos(pack.enemyName)
				enemy.isBoss = pack.isBoss || false
				enemy.isEventSpawn = pack.isEventSpawn || false
			}

			pack.totalSpawned += groupAmount + stragglerSpawnCount
		}

		// Hide and remove all enemies
		if(this.dramaticShowDownBossSpawned){
			for (let i =0; i < GameState.enemyList.length; ++i) {
				const enemy = GameState.enemyList[i] as Enemy
				if(!enemy.isBoss){
					enemy.dramaticShowdownRemoveEnemies()
				}
			}
		}

		this.updateUI(enemyCountsByName)
	}

	forceStartNextPack(ensureCurrentIsBoss?: boolean) {
		const currentTime = InGameTime.timeElapsedInSeconds
		let foundCurrentStage = false
		for (const stage of this.stages) {
			if (currentTime >= stage.min && currentTime <= stage.max) {
				if (ensureCurrentIsBoss) {
					foundCurrentStage = stage.packs[0].isBoss
				} else {
					foundCurrentStage = true
				}
			} else if (foundCurrentStage) {
				// relies on this.stages being sorted (it is)
				stage.min = currentTime
				return
			}	
		}
	}

	// Temp for now, we'll want to define some actual spawn shapes in AISystem beyond just defining a border?
	getSpawnBounds(direction: Directions): SpawnWorldBounds {
		if (direction === Directions.North) {
			const minX = GameState.player.x - getVisibleWorldWidth() / 2
			const maxX = GameState.player.x + getVisibleWorldWidth() / 2
			const minY = GameState.player.y - OFFSET_Y - getVisibleWorldHeight() / 2
			const maxY = minY - HEIGHT_VARIANCE
			return { minX: minX, maxX: maxX, minY: minY, maxY: maxY }
		} else if (direction === Directions.South) {
			const minX = GameState.player.x - getVisibleWorldWidth() / 2
			const maxX = GameState.player.x + getVisibleWorldWidth() / 2
			const minY = GameState.player.y + OFFSET_Y + getVisibleWorldHeight() / 2
			const maxY = minY + HEIGHT_VARIANCE
			return { minX: minX, maxX: maxX, minY: minY, maxY: maxY }
		} else if (direction === Directions.East) {
			const minX = GameState.player.x + OFFSET_X + getVisibleWorldWidth() / 2
			const maxX = minX + WIDTH_VARIANCE
			const minY = GameState.player.y + getVisibleWorldHeight() / 2
			const maxY = GameState.player.y - getVisibleWorldHeight() / 2
			return { minX: minX, maxX: maxX, minY: minY, maxY: maxY }
		} else if (direction === Directions.West) {
			const minX = GameState.player.x - OFFSET_X - getVisibleWorldWidth() / 2
			const maxX = minX - WIDTH_VARIANCE
			const minY = GameState.player.y + getVisibleWorldHeight() / 2
			const maxY = GameState.player.y - getVisibleWorldHeight() / 2
			return { minX: minX, maxX: maxX, minY: minY, maxY: maxY }
		} else if (direction === Directions.NorthEast) {
			const minX = GameState.player.x + OFFSET_X + getVisibleWorldWidth() / 2
			const maxX = minX + WIDTH_VARIANCE
			const minY = GameState.player.y - OFFSET_Y - getVisibleWorldHeight() / 2
			const maxY = minY - HEIGHT_VARIANCE
			return { minX: minX, maxX: maxX, minY: minY, maxY: maxY }
		} else if (direction === Directions.NorthWest) {
			const minX = GameState.player.x - OFFSET_X - getVisibleWorldWidth() / 2
			const maxX = minX - WIDTH_VARIANCE
			const minY = GameState.player.y - OFFSET_Y - getVisibleWorldHeight() / 2
			const maxY = minY - HEIGHT_VARIANCE
			return { minX: minX, maxX: maxX, minY: minY, maxY: maxY }
		} else if (direction === Directions.SouthEast) {
			const minX = GameState.player.x + OFFSET_X + getVisibleWorldWidth() / 2
			const maxX = minX + WIDTH_VARIANCE
			const minY = GameState.player.y + OFFSET_Y + getVisibleWorldHeight() / 2
			const maxY = minY + HEIGHT_VARIANCE
			return { minX: minX, maxX: maxX, minY: minY, maxY: maxY }
		} else if (direction === Directions.SouthWest) {
			const minX = GameState.player.x - OFFSET_X - getVisibleWorldWidth() / 2
			const maxX = minX - WIDTH_VARIANCE
			const minY = GameState.player.y + OFFSET_Y + getVisibleWorldHeight() / 2
			const maxY = minY + HEIGHT_VARIANCE
			return { minX: minX, maxX: maxX, minY: minY, maxY: maxY }
		}
	}

	updateSpawnValueMultipliers(spawnValues: EnemySpawnValues) {
		Object.assign(this.spawnValues, spawnValues)
	}

	multiplySpawnValueMultipliers(spawnValues: EnemySpawnValues) {
		for (const key in spawnValues) {
			if (Number.isFinite(spawnValues[key])) {
				this.spawnValues[key] *= spawnValues[key]
				console.log(`${key} multipled by ${spawnValues[key]} == ${this.spawnValues[key]}`)
			}
		}
	}

	addSpawnValueMultipliers(spawnValues: EnemySpawnValues) {
		for (const key in spawnValues) {
			if (Number.isFinite(spawnValues[key])) {
				this.spawnValues[key] += spawnValues[key]
				console.log(`${key} added by ${spawnValues[key]} == ${this.spawnValues[key]}`)
			}
		}
	}

	addEnemySpawnRateMultipliers(spawnRateMod: EnemySpawnRateDefinition) {
		if (Number.isFinite(spawnRateMod.spawnRateMulit)){
			spawnRateMod.enemies.forEach((e) => {
				if (Number.isFinite(this.enemySpawnRateModifiers[e])) {
					this.enemySpawnRateModifiers[e] += (1 + spawnRateMod.spawnRateMulit)
				} else {
					this.enemySpawnRateModifiers[e] = (1 + spawnRateMod.spawnRateMulit)
				}
				// console.log(`spawn rate multi set to ${this.enemySpawnRateModifiers[e]}`)
			})
		}
	}

	dramaticShowdownResumeSpawning(){
		callbacks_addCallback(this, ()=>{
			this.dramaticShowDownBossSpawned = false
			this.bruteTrioEventStarted = false
			if(GameState.player.killstreakEnabled){
				UI.getInstance().emitMutation('player/updatePauseKillstreakTimer', false)
				GameState.player.setKillstreak(GameState.player.currentKillstreak + 1)
			}
		}, randomRange(SUPPRESS_SPAWN_MIN, SUPPRESS_SPAWN_MAX) )
	}

	removeDramaticShowdownCB(){
		callbacks_removeCallbacksFromOwner(this)
	}
}
