import { MapNames, mapNames } from "../../world-generation/world-data";
import { ENEMY_NAME } from "./enemy-names";


export const BOSS_EVENTS = {
	[ENEMY_NAME.CHAOS_SEER_BOSS]: {
		title: ENEMY_NAME.CHAOS_SEER_BOSS,
		subtitle: "The End of Evolution",
		//TODO: could add in camera shake and audio configuration here
		packs: [
			{
				enemyName: ENEMY_NAME.CHAOS_SEER_BOSS,
				equilibriumAmount: 0,
				maxAmount: 1,
				spawnAmount: 1,
				spawnRate: 9999.0,
				isBoss: true,
				isEventSpawn: true,
				ignoreSpawnChanges: true,
			},
		],
		choreos: [

		],
	},

	[ENEMY_NAME.PRISMFLY_BOSS] : {
		title: ENEMY_NAME.PRISMFLY_BOSS,
		subtitle: "The Sky's Hateful Grace",
		packs: [
			{
				enemyName: ENEMY_NAME.PRISMFLY_BOSS,
				equilibriumAmount: 0,
				maxAmount: 1,
				spawnAmount: 1,
				spawnRate: 9999.0,
				isBoss: true,
				isEventSpawn: true,
				ignoreSpawnChanges: true,
			},
		],
		choreos: [

		],
	},

	[ENEMY_NAME.DREAMER_BOSS]: {
		title: ENEMY_NAME.DREAMER_BOSS,
		subtitle: "The Abyss That Stares Back",
		packs: [
			{
				enemyName: ENEMY_NAME.DREAMER_BOSS,
				equilibriumAmount: 0,
				maxAmount: 1,
				spawnAmount: 1,
				spawnRate: 9999.0,
				isBoss: true,
				isEventSpawn: true,
				ignoreSpawnChanges: true,
			},
		],
		choreos: [

		],
	},

	[ENEMY_NAME.MR_CUDDLES]: {
		title: ENEMY_NAME.MR_CUDDLES,
		subtitle: "The Icebound Scourge",
		packs: [
			{
				enemyName: ENEMY_NAME.MR_CUDDLES,
				equilibriumAmount: 0,
				maxAmount: 1,
				spawnAmount: 1,
				spawnRate: 9999.0,
				isBoss: true,
				isEventSpawn: true,
				ignoreSpawnChanges: true,
			},
		],
		choreos: [

		],
	},

	[ENEMY_NAME.YETI] : {
		title: ENEMY_NAME.YETI,
		subtitle: "The Yawning Hunger",
		packs: [
			{
				enemyName: ENEMY_NAME.YETI,
				equilibriumAmount: 0,
				maxAmount: 1,
				spawnAmount: 1,
				spawnRate: 9999.0,
				isBoss: true,
				isEventSpawn: true,
				ignoreSpawnChanges: true,
			},
		],
		choreos: [

		],
	},

	[ENEMY_NAME.ICE_DRAKE]: {
		title: ENEMY_NAME.ICE_DRAKE,
		subtitle: "Lord of the Mountain",
		packs: [
			{
				enemyName: ENEMY_NAME.ICE_DRAKE,
				equilibriumAmount: 0,
				maxAmount: 1,
				spawnAmount: 1,
				spawnRate: 9999.0,
				isBoss: true,
				isEventSpawn: true,
				ignoreSpawnChanges: true,
			},
		],
		choreos: [

		],
	},

	"The Brute Trio": {
		title: "The Brute Trio",
		subtitle: "Bigger and Badder",
		packs: [
			{
				enemyName: ENEMY_NAME.BRUTE_SHAMBLER,
				equilibriumAmount: 0,
				maxAmount: 1,
				spawnAmount: 1,
				spawnRate: 9999.0,
				isBoss: true,
				isEventSpawn: true,
				ignoreSpawnChanges: true,
			},
			{
				enemyName: ENEMY_NAME.BRUTE_EYE_BAT,
				equilibriumAmount: 0,
				maxAmount: 1,
				spawnAmount: 1,
				spawnRate: 999.0,
				isEventSpawn: true,
				isBoss: true
			},
			{
				enemyName: ENEMY_NAME.BRUTE_SHRIEKER,
				equilibriumAmount: 0,
				maxAmount: 1,
				spawnAmount: 1,
				spawnRate: 999.0,
				isEventSpawn: true,
				isBoss: true
			},
		],
		choreos: [

		],
	},

}

export const BOSS_EVENTS_BY_MAP_AND_ACT = {
	[mapNames.FOREST]: [
		BOSS_EVENTS[ENEMY_NAME.CHAOS_SEER_BOSS],
		BOSS_EVENTS[ENEMY_NAME.PRISMFLY_BOSS],
		BOSS_EVENTS[ENEMY_NAME.DREAMER_BOSS],
	],
	[mapNames.TUNDRA]: [
		BOSS_EVENTS[ENEMY_NAME.MR_CUDDLES],
		BOSS_EVENTS[ENEMY_NAME.YETI],
		BOSS_EVENTS[ENEMY_NAME.ICE_DRAKE],
	],
}

export type BossEventConfig = typeof BOSS_EVENTS_BY_MAP_AND_ACT.forest
