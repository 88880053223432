<template>
	<div class="tab-navigation-panel">
		<div class="wrapper">
			<ul class="tab-group">
				<li class="tab" v-for="(text, index) in tabText" :key="index" @click="onClick(index)" :class="{ selected: selectedIndex === index }">
					<div v-if="useIcons" class="icon" @click="onClick">
						<ItemUpdated
							:id="`twist-${index}`"
							:item="items[index]"
							:disable-circle="false"
							:small-icons="true"
							:enable-glow="false"
							:selected-item="false"
							:disable-book="true"
							:component="'upgrade'"
							:show-book-background="true"
							:tooltip-enabled="false"
							:selectable="false"
						></ItemUpdated>
					</div>
					<div class="text" :lang="this.$i18n.locale">{{ tabText[index] }}</div>
				</li>
			</ul>
		</div>
		<div class="tab-content">
			<slot name="tab-content"></slot>

		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ItemUpdated from '../../ui-components/item/items-updated.vue'
import TextButton from '../../ui-components/buttons/text-button.vue'


export default {
	name: 'TabNavigation',
	components: {
		ItemUpdated,
		TextButton
	},
	data() {
		return {
			testing: ['3213', '333', '4123'],
			selected: 'testing',
		}
	},
	props: {
		tabText: {
			type: Array,
		},
		iconTypes: {
			type: Array,
		},
		items: {
			type: Array,
		},
		useIcons: {
			type: Boolean,
			default: false,
		},
		selectedIndex: {
			type: Number,
		},
	},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('endChapter', ['updateShowVoteDetailsModal']),
		onClick(index) {
			this.$emit('onClickFn', index)
		},
		closeModal(){
			this.updateShowVoteDetailsModal(false)
		}
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.tab-navigation-panel {
	.wrapper {
		.tab-group {
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
			pointer-events: all;
			.tab {
				flex: 1;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				border: 11px solid #040e26;
				margin: 0 2.5px 0 2.5px;
				background-color: @tabModalInactive;
				display: flex;
				justify-content: center;
				flex-direction: column;
				text-align: center;
				padding: 5px 5px;
				pointer-events: all;
				top: 10px;
				position: relative;
				z-index: 3;
				&:last-child,
				&:first-child {
					margin: 0;
				}
				&.selected {
					background-color: @tabModalActive !important;
					border-bottom: unset;
					padding-bottom: 11px;
				}
				.icon {
					:deep(.item) {
						.item-container {
							left: -11px;
						}
					}
				}
				.text {
					&[lang='en'] {
						.regularFont(@enRegularFontPixl);
						font-size: 32px;
						font-variant: small-caps;
						color: @green;
					}
					&[lang='zh'] {
						.regularFont(@zhHansSecondaryFont);
						font-size: 32px;
					}
				}
			}
		}
	}
	.tab-content {
		border-image: url('../../../../assets/ui/tabs/main.png') 15 130 95 130 fill / 15px 130px 95px 130px;
		border-style: solid;
		border-width: 21px 38px;
		min-width: 100%;
		min-height: 125px;

	}
}
</style>
