import { sortBy } from "lodash"
import { Buff } from "../buffs/buff"
import { GameState } from "../engine/game-state"
import { Player } from "../entities/player"
import EntityStatList, { StatBonus } from "../stats/entity-stat-list"
import { selectorsToStatLists, statSelectors } from "../stats/stat-selector"
import { UI } from "../ui/ui"
import { debugtool } from "../utils/decorators"
import { AllWeaponTypes, PrimaryWeaponType } from "../weapons/weapon-types"
import { getUpgradesOfCollection, UpgradeCollection, UpgradeCollections, UpgradeDefinition, UpgradeCollectionName } from "./upgrade-definitions"
import { AffectedEntities, AffectedEntitiesAllPrimaryWeapons, AffectedEntitiesAllSecondaryWeapons } from "./upgrade-entities"

const URL_PARAM_KEY = 'upgrades'
const LOGGING = true
export type UpgradeWithId = UpgradeDefinition & { id: number } & { upgradeParent: string }

function addIdToUpgrade(upgrade: UpgradeDefinition, id: number, upgradeParent: string): UpgradeWithId {
    return { ...upgrade, id, upgradeParent }
}

export type UpgradePoolType = "level-up" | "boss" | "event"
export type UpgradePools = { [k in UpgradePoolType]?: number }

export class UpgradeManager {
    // for now this is the complete collection, we'll probably take subsets to respond to player choices in the future
    private static instance: UpgradeManager
    private upgrades: UpgradeCollections
    //** An array of upgrades indexed on an internal id */
    private allUpgrades: UpgradeWithId[]
    private childrenById: Map<number, number[]>
    private exclusiveSiblingsById: Map<number, number[]>
    private excludedsById: Map<number, number[]>
    private availableUpgrades: Set<UpgradeWithId>
    private allocatedIds: Set<number>
    private upgradesTreesWithIds: any[]
    private allocatedUpgradeState: Map<UpgradeWithId, {
        clear: () => void,
    }>
    private upgradesWithMultiplePrereqs: Map<number, number>
    static init(upgrades: UpgradeCollections) {
        if (!UpgradeManager.instance) {
            UpgradeManager.instance = new UpgradeManager(upgrades)
        } else {
            console.warn('Initializing the upgrade multiple times, this is probably an error!')
        }
    }
    static destroy() {
        UpgradeManager.instance = null
    }

    constructor(upgrades: UpgradeCollections) {
        this.upgrades = upgrades
        this.upgradesTreesWithIds = []
        this.allocatedIds = new Set()
        this.allocatedUpgradeState = new Map()
        this.childrenById = new Map()
        this.exclusiveSiblingsById = new Map()
        this.excludedsById = new Map()
        this.availableUpgrades = new Set()
        this.allUpgrades = []
        this.upgradesWithMultiplePrereqs = new Map()
        let upgradeIndex = 0
        // first pass, index by id
        for (const [upgradeKey, coll] of Object.entries(this.upgrades)) {
            if ((coll.upgradeAllowedFunction && !coll.upgradeAllowedFunction())) {
                continue
            }

            if (coll.shape === 'diamond') {
                const { top, left, right, bottom } = coll
                const topIndex = upgradeIndex
                const leftIndex = upgradeIndex + 1
                const rightIndex = upgradeIndex + 2
                const bottomIndex = upgradeIndex + 3
                // NOTE: We either look up the buff shape based on Buff name,
                // Or we also take on the shape around here. 
                this.allUpgrades.push(addIdToUpgrade(top, topIndex, upgradeKey))
                this.allUpgrades.push(addIdToUpgrade(left, leftIndex, upgradeKey))
                this.allUpgrades.push(addIdToUpgrade(right, rightIndex, upgradeKey))
                this.allUpgrades.push(addIdToUpgrade(bottom, bottomIndex, upgradeKey))

                if (!coll.isUnlock && !coll.metaStartsLocked) {
                    this.availableUpgrades.add(this.allUpgrades[topIndex])
                }

                if (coll.isUnlock && coll.unlockPrereqs) {
                    this.upgradesWithMultiplePrereqs.set(topIndex, coll.unlockPrereqs)
                }

                // top -> left, right
                this.childrenById.set(topIndex, [topIndex + 1, topIndex + 2])
                // left -> bottom
                this.childrenById.set(leftIndex, [bottomIndex])
                // right -> bottom
                this.childrenById.set(rightIndex, [bottomIndex])

                const collClone = { ...coll }

                for (const key of Object.keys(collClone)) {
                    if (key === 'top') {
                        collClone[key].upgradeIndex = topIndex
                    } else if (key === 'left') {
                        collClone[key].upgradeIndex = leftIndex
                    } else if (key === 'right') {
                        collClone[key].upgradeIndex = rightIndex
                    } else if (key === 'bottom') {
                        collClone[key].upgradeIndex = bottomIndex
                    }
                }

                collClone.parentIndex = upgradeKey
                this.upgradesTreesWithIds.push(collClone)

                upgradeIndex += 4
            } else if (coll.shape === 'tree') {

                const { top, left, right, leftLeaf, middleLeaf, rightLeaf } = coll
                const rootIndex = upgradeIndex
                const leftIndex = upgradeIndex + 1
                const rightIndex = upgradeIndex + 2
                const leftLeafIndex = upgradeIndex + 3
                const middleLeafIndex = upgradeIndex + 4
                const rightLeafIndex = upgradeIndex + 5

                this.allUpgrades.push(addIdToUpgrade(top, rootIndex, upgradeKey))
                this.allUpgrades.push(addIdToUpgrade(left, leftIndex, upgradeKey))
                this.allUpgrades.push(addIdToUpgrade(right, rightIndex, upgradeKey))
                this.allUpgrades.push(addIdToUpgrade(leftLeaf, leftLeafIndex, upgradeKey))
                this.allUpgrades.push(addIdToUpgrade(middleLeaf, middleLeafIndex, upgradeKey))
                this.allUpgrades.push(addIdToUpgrade(rightLeaf, rightLeafIndex, upgradeKey))

                this.exclusiveSiblingsById.set(leftIndex, [rightIndex])
                this.exclusiveSiblingsById.set(rightIndex, [leftIndex])
                this.exclusiveSiblingsById.set(leftLeafIndex, [middleLeafIndex])
                this.exclusiveSiblingsById.set(middleLeafIndex, [leftLeafIndex, rightLeafIndex])
                this.exclusiveSiblingsById.set(rightLeafIndex, [middleLeafIndex])

                this.childrenById.set(rootIndex, [leftIndex, rightIndex])
                this.childrenById.set(leftIndex, [leftLeafIndex, middleLeafIndex])
                this.childrenById.set(rightIndex, [middleLeafIndex, rightLeafIndex])

                this.excludedsById.set(leftIndex, [rightIndex, rightLeafIndex])
                this.excludedsById.set(rightIndex, [leftIndex, leftLeafIndex])
                this.excludedsById.set(leftLeafIndex, [middleLeafIndex, rightLeafIndex])
                this.excludedsById.set(middleLeafIndex, [leftLeafIndex, rightLeafIndex])
                this.excludedsById.set(rightLeafIndex, [middleLeafIndex, leftLeafIndex])


                if (!coll.isUnlock && !coll.metaStartsLocked) {
                    this.availableUpgrades.add(this.allUpgrades[rootIndex])
                }

                if (coll.isUnlock && coll.unlockPrereqs) {
                    this.upgradesWithMultiplePrereqs.set(rootIndex, coll.unlockPrereqs)
                }

                const collClone = { ...coll }

                for (const key of Object.keys(collClone)) {
                    if (key === 'top') {
                        collClone[key].upgradeIndex = rootIndex
                    } else if (key === 'left') {
                        collClone[key].upgradeIndex = leftIndex
                    } else if (key === 'right') {
                        collClone[key].upgradeIndex = rightIndex
                    } else if (key === 'leftLeaf') {
                        collClone[key].upgradeIndex = leftLeafIndex
                    } else if (key === 'middleLeaf') {
                        collClone[key].upgradeIndex = middleLeafIndex
                    } else if (key === 'rightLeaf') {
                        collClone[key].upgradeIndex = rightLeafIndex
                    }
                }
                collClone.parentIndex = upgradeKey
                this.upgradesTreesWithIds.push(collClone)

                upgradeIndex += 6
            } else if (coll.shape === 'single') { // @TODO: same as line? 
                this.allUpgrades.push(addIdToUpgrade(coll.top, upgradeIndex, upgradeKey))

                const collClone = { ...coll }
                for (const key of Object.keys(collClone)) {
                    if (key === 'top') {
                        collClone[key].upgradeIndex = upgradeIndex
                    }
                }
                collClone.parentIndex = upgradeKey
                this.upgradesTreesWithIds.push(collClone)
                if (!coll.isUnlock && !coll.metaStartsLocked) {
                    this.availableUpgrades.add(this.allUpgrades[upgradeIndex])
                }
                if (coll.isUnlock && coll.unlockPrereqs) {
                    this.upgradesWithMultiplePrereqs.set(upgradeIndex, coll.unlockPrereqs)
                }

                upgradeIndex++
            } else if (coll.shape === 'line') {

                for (let i = 0; i < coll.upgrades.length; ++i) {
                    const upgrade = coll.upgrades[i]
                    this.allUpgrades.push(addIdToUpgrade(upgrade, upgradeIndex + i, upgradeKey))

                    if (i > 0) {
                        this.childrenById.set(upgradeIndex + i - 1, [upgradeIndex + i])
                    }
                }

                const collClone = { ...coll }
                for (let i = 0; i < collClone.upgrades.length; ++i) {
                    collClone.upgrades[i].upgradeIndex = upgradeIndex + i

                }
                collClone.parentIndex = upgradeKey
                this.upgradesTreesWithIds.push(collClone)
                if (!coll.isUnlock && !coll.metaStartsLocked) {
                    this.availableUpgrades.add(this.allUpgrades[upgradeIndex])
                }

                if (coll.isUnlock && coll.unlockPrereqs) {
                    this.upgradesWithMultiplePrereqs.set(upgradeIndex, coll.unlockPrereqs)
                }

                upgradeIndex += coll.upgrades.length
            }
        }
    }

    static getAvailableUpgradesReducer(acc: any[], pool: UpgradePoolType, up: UpgradeWithId) {
        const collection = this.instance.upgrades[up.upgradeParent] as UpgradeCollection
        let isAvailable = true
        if (collection.requireWeapon) {
            if (!GameState.player.allWeapons.has(collection.requireWeapon)) {
                isAvailable = false
            }
        }

        if (collection.isSteam && process.env.IS_WEB) {
            isAvailable = false
        }

        if (collection.isSecondaryUnlock) {
            if (GameState.player.secondaryWeapons.length >= GameState.player.maxSecondaryWeapons) {
                isAvailable = false
            }
        }

        if (isAvailable) {
            let weight = up.pools[pool]
            if (weight && LOGGING) {
                console.log({
                    weight: weight,
                    name: up.name,
                })
            }
            while (weight) {
                acc.push(up)
                weight--
            }
        }

        return acc
    }

    static rollUpgrades(limit: number, pool: UpgradePoolType, forceSiblingsOnly: boolean = false, mt?: any) {
        let availableUpgrades = Array.from(this.instance.availableUpgrades)
        if (LOGGING) {
            console.groupCollapsed(`Rolling upgrades - pool ${pool}, limit ${limit}, siblings ${forceSiblingsOnly}`)
        }
        availableUpgrades = availableUpgrades.reduce((acc, up) => {
            return UpgradeManager.getAvailableUpgradesReducer(acc, pool, up)
        }, [])
        if (LOGGING) {
            console.groupEnd()
        }

        let rolled: UpgradeWithId[] = []
        let tries = 0
        if (forceSiblingsOnly && limit < 2) {
            limit = 2
        }
        while (limit > 0 && availableUpgrades.length > 0) {
            tries++
            if (tries >= 2000) {
                console.warn(`Could not find any valid upgrades for the user in pool ${pool}, limit ${limit}, with siblings ${forceSiblingsOnly}`)
                break
            }
            const up = availableUpgrades.pickRandom(mt)
            const sibling = this.getSiblingUpgrade(up)
            if (forceSiblingsOnly && !sibling) {
                continue
            }
            if (sibling) {
                if (limit <= 1) {
                    continue
                }
                if (LOGGING) {
                    console.log(`rolled a sibling! ${up.name} <> ${sibling.name}`)
                }
                rolled.push(sibling)
                availableUpgrades.removeAll(sibling)
                limit--
            }
            rolled.push(up)
            availableUpgrades.removeAll(up)
            limit--
        }
        rolled = sortBy(rolled, ['id'])
        const excludedUpgrades = this.getAllExcludedUpgrades()
        const upgradeTreeWithIds = this.getAllUpgradeTreesWithIds()
        const allocatedIds = this.getAllocatedUpgradeIds()
        const playerLoadout = GameState.player.getPlayerLoadout()
        if (LOGGING) {
            console.group('Rolled upgrades')
            rolled.forEach((up, i) => {
                console.log(`${i + 1}-${up.id}: ${up.name}: ${up.desc}`)
            })
            console.groupEnd()
        }

        return { rolledUpgrades: rolled, excludedBy: excludedUpgrades, upgradeTreeWithIds: upgradeTreeWithIds, allocatedIds: allocatedIds, playerLoadout: playerLoadout }
    }

    static getSiblingUpgrade(upgrade: UpgradeWithId): UpgradeWithId {
        const siblings = UpgradeManager.instance.exclusiveSiblingsById.get(upgrade.id)
        if (siblings !== undefined) {
            const validUpgrade = siblings.find((upId) => {
                const upgrade = UpgradeManager.getUpgradeDefinitionsById([upId]).first()
                return this.instance.availableUpgrades.has(upgrade)
            })
            const siblingUpgrade = UpgradeManager.getUpgradeDefinitionsById([validUpgrade]).first()
            // we don't currently check if the user already has this upgrade, because they shouldn't be allowed to take siblings due to lockout
            return siblingUpgrade
        }
    }

    static getParentUpgrade(rolledUpgrades: UpgradeWithId[]) {
        const upgrades = []
        for (const rolledUpgrade of rolledUpgrades) {
            const upgradeFound = this.instance.upgrades.hasOwnProperty(rolledUpgrade.upgradeParent)
            if (upgradeFound) {
                const upgradeClone = this.instance.upgrades[rolledUpgrade.upgradeParent]
                upgradeClone.parent = rolledUpgrade.upgradeParent

                upgrades.push(upgradeClone)
            }
        }
        return upgrades
    }

    static applyUpgradeBonuses(upgrade: UpgradeWithId, player: Player) {
        if (LOGGING) {
            console.group(`Upgrade! ${upgrade.name}`)
        }

        const flags = upgrade.binaryFlags ? [...upgrade.binaryFlags] : []
        upgrade.binaryFlags?.forEach((flag) => {
            player.binaryFlags.add(flag)
            if (LOGGING) {
                console.log(`applied binary flag: ${flag}`)
            }
        })

        let buff: Buff
        if (upgrade.autoAppliedBuff) {
            buff = Buff.apply(upgrade.autoAppliedBuff.identifier, player, player, 1)
            if (LOGGING) {
                console.log(`applied buff: ${upgrade.autoAppliedBuff.identifier}`)
            }
        }

        const bonuses: StatBonus[] = []
        if (upgrade.statBonuses) {
            for (const [selectors, ...bonus] of upgrade.statBonuses) {
                const statLists = selectorsToStatLists(player, selectors)
                for (const statList of statLists) {
                    const statBonus = new StatBonus(statList, ...bonus)
                    statList.addStatBonusDirect(statBonus)
                    bonuses.push(statBonus)
                }
            }
            if (LOGGING) {
                console.log(`applied bonuses, count ${bonuses.length}`)
            }
        }

        const simpleRemoveFn = upgrade.simpleRemoveFn
        this.instance.allocatedUpgradeState.set(upgrade, {
            clear: () => {
                flags?.forEach((flag) => {
                    player.binaryFlags.delete(flag)
                })
                buff?.wearOff()
                bonuses?.forEach((bonus) => {
                    bonus.remove()
                })
                if (simpleRemoveFn) {
                    simpleRemoveFn(player, this.instance.allocatedUpgradeState.get(upgrade))
                }
            }
        })

        if (upgrade.simpleApplyFn) {
            upgrade.simpleApplyFn(player, this.instance.allocatedUpgradeState.get(upgrade))
        }

        if (LOGGING) {
            console.groupEnd()
        }

        player.updateAmmoCount()
    }

    static redeemUpgrade(upgrade: UpgradeWithId) {
        if (this.instance.allocatedIds.has(upgrade.id)) {
            console.warn(`Attempted to redeem an upgrade that was already redeemed: ${upgrade.id}`, upgrade)
            return
        }
        // TODO The instrumented version of this is not the internal version, we might wanna do this just by id
        const toRemove = this.instance.allUpgrades[upgrade.id]
        // Remove from available upgrades
        this.instance.availableUpgrades.delete(toRemove)
        // Apply upgrade to player
        this.applyUpgradeBonuses(upgrade, GameState.player)

        this.instance.allocatedIds.add(upgrade.id)

        if (upgrade.unlocks) {
            for (let i = 0; i < upgrade.unlocks.length; ++i) {
                const unlockName = upgrade.unlocks[i]
                this.unlockUpgrade(unlockName)
            }
        }

        // remove upgrades this upgrade locks out
        const excludes = this.instance.excludedsById.get(upgrade.id)
        if (excludes) {
            for (let i = 0; i < excludes.length; ++i) {
                const excludedId = excludes[i]
                this.instance.availableUpgrades.delete(this.instance.allUpgrades[excludedId])
            }
        }

        // remove any upgrades this upgrade locks out on special conditions (Solara)
        if (upgrade.locks) {
            for (let i = 0; i < upgrade.locks.length; ++i) {
                const unlockName = upgrade.locks[i]
                this.lockUpgrade(unlockName)
            }
        }

        // Add new available upgrades (by children lookup)
        const children = this.instance.childrenById.get(upgrade.id) || []
        for (const child of children) {
            if (!this.instance.allocatedIds.has(child)) {
                this.instance.availableUpgrades.add(this.instance.allUpgrades[child])
            }
        }

        // Notify UI
        const ids = Array.from(this.instance.allocatedIds.values())
        const upgradeNames = this.getUpgradeNamesById(ids)
        const upgradeDefintions = this.getUpgradeDefinitionsById(ids)
        const upgradeTrees = this.getParentUpgrade(upgradeDefintions)
        UI.getInstance().emitMutation('ui/updateUpgrades', upgradeNames)
        UI.getInstance().emitAction('paused/postPauseUpgrades', { upgradeDefintions: upgradeDefintions, upgradeTrees: upgradeTrees })
    }

    static unlockUpgrade(unlockName: UpgradeCollectionName) {
        const collection = UpgradeManager.instance.upgrades[unlockName]
        let topUpgrade: UpgradeDefinition
        if (collection.shape === 'line') {
            topUpgrade = collection.upgrades[0]
        } else {
            topUpgrade = collection.top
        }

        const upgradePair = UpgradeManager.instance.allUpgrades.find((pair) => pair.name === topUpgrade.name)

        console.assert(upgradePair)

        if (UpgradeManager.instance.allocatedIds.has(upgradePair.id)) {
            return
        }

        if (collection.unlockPrereqs && UpgradeManager.instance.upgradesWithMultiplePrereqs.has(upgradePair.id)) {
            let remainingPrereqs = UpgradeManager.instance.upgradesWithMultiplePrereqs.get(upgradePair.id)
            remainingPrereqs--
            UpgradeManager.instance.upgradesWithMultiplePrereqs.set(upgradePair.id, remainingPrereqs)
            if (remainingPrereqs > 0) {
                return
            }
        }

        UpgradeManager.instance.availableUpgrades.add(upgradePair)
    }

    // Locks out any remaining upgrades in a given node (currently only used for Solara's edge case lock outs)
    static lockUpgrade(lockName: UpgradeCollectionName) {
        const collection = UpgradeManager.instance.upgrades[lockName]
        let topUpgrade: UpgradeDefinition
        if (collection.shape === 'line') {
            topUpgrade = collection.upgrades[0]
        } else {
            topUpgrade = collection.top
        }

        const upgradePair = UpgradeManager.instance.allUpgrades.find((pair) => pair.name === topUpgrade.name)

        console.assert(upgradePair)

        const flatTreeWithIds = UpgradeManager.getFlattenedUpgradeTreeWithIds(upgradePair.id)
        const upgradesInTree = UpgradeManager.getUpgradeDefinitionsById(flatTreeWithIds)

        for (let i = 0; i < upgradesInTree.length; i++) {
            const upgrade = upgradesInTree[i]
            if (UpgradeManager.instance.availableUpgrades.delete(upgrade) && collection.shape !== 'diamond') {
                // Early return if this isn't a diamond (in which case we need to make sure all available children are removed)
                return
            }
        }
    }

    private static getUpgradeNamesById(ids: number[]) {
        return this.instance.allUpgrades.filter((upgrade) => {
            return ids.includes(upgrade.id)
        }).map((upgrade) => upgrade.name)
    }

    private static getUpgradeDefinitionsById(ids: number[]) {
        return this.instance.allUpgrades.filter((upgrade) => {
            return ids.includes(upgrade.id)
        })
    }

    static getAllocatedUpgrades() {
        const ids = Array.from(this.instance.allocatedIds.values())
        return this.instance.allUpgrades.filter((upgrade) => {
            return ids.includes(upgrade.id)
        })
    }

    static getAllocatedGenericUpgrades() {
        return this.getAllocatedUpgrades().filter((upgrade) => {
            return upgrade.affectedEntities.includes(AffectedEntities.Player || AffectedEntities.ConeDog) || upgrade.affectedEntities.length > 1
        })
    }

    static getAllocatedWeaponsPetsUpgrades() {
        return this.getAllocatedUpgrades().filter((upgrade) => {
            return !!upgrade.affectedEntities.find((value) => {
                return [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet].includes(value)
            })
        })
    }

    static getAllocatedUpgradeNames() {
        const ids = Array.from(this.instance.allocatedIds.values())
        return this.getUpgradeNamesById(ids)
    }

    static getAllCollectionAndUpgradesByName(filterCollections?: Partial<UpgradeCollections>) {
        const ret = {}
        for (const key in this.instance.upgrades) {
            const coll: UpgradeCollection = this.instance.upgrades[key]
            if (filterCollections && !Object.keys(filterCollections).includes(key)) {
                continue
            }
            ret[coll.name] = getUpgradesOfCollection(coll).map((upgrade) => upgrade.name)
        }
        return ret
    }
    static getAllExcludedUpgrades() {
        const excludedUpgrade = Array.from(this.instance.excludedsById, ([key, value]) => { return { upgradeIndex: key, excluded: value } })
        return excludedUpgrade
    }
    static getAllUpgradeTreesWithIds() {
        const upgradeTreeWithIds = this.instance.upgradesTreesWithIds
        return upgradeTreeWithIds
    }
    static getAllocatedUpgradeIds() {
        return Array.from(this.instance.allocatedIds.values())
    }

    static getFlattenedUpgradeTreeWithIds(id: number): number[] {
        const ret = [id]
        const children = this.instance.childrenById.get(id) || []
        for (const child of children) {
            ret.concat(this.getFlattenedUpgradeTreeWithIds(child))
        }
        return ret
    }

    static redeemUpgradeByName(name: string) {
        const upgrade = this.instance.allUpgrades.find((upgrade) => upgrade.name === name)
        // console.log(`redeemByName id #${upgrade.id} ${name} == ${upgrade.name}`, upgrade)
        return this.redeemUpgrade(upgrade)
    }

    @debugtool
    private static redeemUpgradeById(id: number) {
        const upgrade = this.instance.allUpgrades.find((upgrade) => upgrade.id === id)
        return this.redeemUpgrade(upgrade)
    }

    @debugtool
    static setUpgradesByNames(names: string[]) {
        if ((process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') || UI.getInstance().store.getters['user/isQa']) {
            const oldSearchParams = new URLSearchParams(window.location.search)
            oldSearchParams.set(URL_PARAM_KEY, JSON.stringify(names))

            const newURL = window.location.origin + '/?' + oldSearchParams.toString()

            window.history.replaceState(null, null, newURL)
        }

        // console.log('setUpgradesByNames', names)
        this.instance.allocatedUpgradeState.forEach((v) => v.clear())
        this.instance.allocatedUpgradeState.clear()
        this.instance.allocatedIds.clear()

        names.forEach((name) => {
            this.redeemUpgradeByName(name)
        })
    }

    @debugtool
    static applyURLUpgrades() {
        const searchParams = new URLSearchParams(window.location.search)
        const upgrades = searchParams.get(URL_PARAM_KEY)

        if (upgrades) {
            const names = JSON.parse(upgrades)

            this.setUpgradesByNames(names)
        }
    }
}

