import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { DeadBehaviours, VariantAI } from "../ai-types"
import { PostSpawnAfterDeathMod } from "../behaviours/dead"
import { ENEMY_NAME } from "../enemy-names"

export const iceElemental2: VariantAI = {
    extends: ENEMY_NAME.ICE_ELEMENTAL,
    name: ENEMY_NAME.ICE_ELEMENTAL_2,
    appearance: {
        asset: SpineDataName.ICE_ELEMENTAL_2,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
		states: {
			dead: {
				behaviour: DeadBehaviours.BE_A_CORPSE,
				corpseTimeoutInSeconds: 1,
				spawnAfterDeath: [
					{
						name: ENEMY_NAME.ICE_ELEMENTAL_2,
						amount: 2,
						delay: 500,
						delayPerSpawn: 0,
						postSpawnMod: PostSpawnAfterDeathMod.HalfishHealthAndSize
					} 
				]
			},
		}
		
}