{
  "Level_Up": {
    "effectNames": {
      "Level_Up": [
        0,
        926
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "Player_Die": {
    "effectNames": {
      "Player_Die_01": [
        1426,
        473
      ],
      "Player_Die_02": [
        2399,
        470
      ],
      "Player_Die_03": [
        3369,
        287
      ],
      "Player_Die_04": [
        4156,
        308
      ]
    },
    "volume": 0.7,
    "volumeInterval": [
      0.5,
      0.7
    ],
    "rate": 1.0,
    "rateInterval": [
      0.85,
      1.0
    ]
  },
  "Projectile_Blip": {
    "effectNames": {
      "Projectile_Blip_01": [
        4964,
        310
      ],
      "Projectile_Blip_02": [
        5774,
        242
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.65,
      0.8
    ],
    "rate": 1.0,
    "rateInterval": [
      0.75,
      1.2
    ]
  },
  "Projectile_Fireball": {
    "effectNames": {
      "Projectile_Fireball_01": [
        6516,
        620
      ],
      "Projectile_Fireball_02": [
        7636,
        561
      ],
      "Projectile_Fireball_03": [
        8697,
        504
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.65,
      0.8
    ],
    "rate": 1.0,
    "rateInterval": [
      0.95,
      1.1
    ]
  },
  "Projectile_Pulse": {
    "effectNames": {
      "Projectile_Pulse_01": [
        9701,
        103
      ],
      "Projectile_Pulse_02": [
        10304,
        103
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.65,
      0.8
    ],
    "rate": 1.0,
    "rateInterval": [
      0.95,
      1.1
    ]
  },
  "Projectile_Zap": {
    "effectNames": {
      "Projectile_Zap": [
        10907,
        306
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.95,
      1.1
    ]
  },
  "UI_Menu_Select": {
    "effectNames": {
      "UI_Menu_Select": [
        11713,
        251
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Receive_Pit": {
    "effectNames": {
      "UI_Receive_Pit": [
        12464,
        1620
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Throw_Pit": {
    "effectNames": {
      "UI_Throw_Pit": [
        14584,
        1670
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "AMB_Smelting_LP": {
    "effectNames": {
      "AMB_Smelting_LP": [
        16754,
        6031
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Slider_Tick": {
    "effectNames": {
      "UI_Slider_Tick": [
        23285,
        135
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Dialog_Major_Open": {
    "effectNames": {
      "UI_Dialog_Major_Open": [
        23920,
        635
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Dialog_Minor_Open": {
    "effectNames": {
      "UI_Dialog_Minor_Open": [
        25055,
        186
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Toggle": {
    "effectNames": {
      "UI_Toggle": [
        25741,
        57
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Smelting_Reward": {
    "effectNames": {
      "UI_Smelting_Reward": [
        26298,
        1225
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Click_Smelting": {
    "effectNames": {
      "UI_Click_Smelting": [
        28023,
        676
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Number_Tick": {
    "effectNames": {
      "UI_Number_Tick": [
        29199,
        284
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Menu_Close_Minor": {
    "effectNames": {
      "UI_Menu_Close_Minor": [
        29983,
        500
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Menu_Open_Minor": {
    "effectNames": {
      "UI_Menu_Open_Minor": [
        30983,
        469
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Menu_Close_Major": {
    "effectNames": {
      "UI_Menu_Close_Major": [
        31952,
        649
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Menu_Open_Major": {
    "effectNames": {
      "UI_Menu_Open_Major": [
        33101,
        732
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Click_Minor": {
    "effectNames": {
      "UI_Click_Minor": [
        34333,
        218
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Click_Major": {
    "effectNames": {
      "UI_Click_Major": [
        35051,
        700
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Click_Daily_Reward": {
    "effectNames": {
      "UI_Click_Daily_Reward": [
        36251,
        2325
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Throw_In_Pit": {
    "effectNames": {
      "UI_Throw_In_Pit": [
        39076,
        1548
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "UI_Pit_Reward": {
    "effectNames": {
      "UI_Pit_Reward": [
        41124,
        1318
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Impact_Enemy_Bone": {
    "effectNames": {
      "SFX_Impact_Enemy_Bone_01": [
        42942,
        290
      ],
      "SFX_Impact_Enemy_Bone_02": [
        43732,
        290
      ],
      "SFX_Impact_Enemy_Bone_03": [
        44522,
        326
      ],
      "SFX_Impact_Enemy_Bone_04": [
        45348,
        290
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Crystal": {
    "effectNames": {
      "SFX_Impact_Enemy_Crystal_01": [
        46138,
        384
      ],
      "SFX_Impact_Enemy_Crystal_02": [
        47022,
        378
      ],
      "SFX_Impact_Enemy_Crystal_03": [
        47900,
        259
      ],
      "SFX_Impact_Enemy_Crystal_04": [
        48659,
        275
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Flesh": {
    "effectNames": {
      "SFX_Impact_Enemy_Flesh_01": [
        49434,
        204
      ],
      "SFX_Impact_Enemy_Flesh_02": [
        50138,
        246
      ],
      "SFX_Impact_Enemy_Flesh_03": [
        50884,
        265
      ],
      "SFX_Impact_Enemy_Flesh_04": [
        51649,
        278
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Generic": {
    "effectNames": {
      "SFX_Impact_Enemy_Generic_01": [
        52427,
        353
      ],
      "SFX_Impact_Enemy_Generic_02": [
        53280,
        342
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Spear": {
    "effectNames": {
      "SFX_Spear_01": [
        54122,
        431
      ],
      "SFX_Spear_02": [
        55053,
        418
      ],
      "SFX_Spear_03": [
        55971,
        398
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Holy_Spear_Explosion": {
    "effectNames": {
      "SFX_Holy_Spear_Explosion_01": [
        56869,
        1011
      ],
      "SFX_Holy_Spear_Explosion_02": [
        58380,
        1011
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Bow": {
    "effectNames": {
      "SFX_Bow_01": [
        59891,
        369
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.2
    ]
  },
  "SFX_Boomerang": {
    "effectNames": {
      "SFX_Boomerang_01": [
        60760,
        229
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_XP_Pickup": {
    "effectNames": {
      "SFX_XP_Pickup": [
        61489,
        381
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Impact_Enemy_Insect": {
    "effectNames": {
      "SFX_Impact_Enemy_Insect_01": [
        62370,
        287
      ],
      "SFX_Impact_Enemy_Insect_02": [
        63157,
        268
      ],
      "SFX_Impact_Enemy_Insect_03": [
        63925,
        296
      ],
      "SFX_Impact_Enemy_Insect_04": [
        64721,
        368
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Mush": {
    "effectNames": {
      "SFX_Impact_Enemy_Mush_01": [
        65589,
        228
      ],
      "SFX_Impact_Enemy_Mush_02": [
        66317,
        251
      ],
      "SFX_Impact_Enemy_Mush_03": [
        67068,
        192
      ],
      "SFX_Impact_Enemy_Mush_04": [
        67760,
        203
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Plant": {
    "effectNames": {
      "SFX_Impact_Enemy_Plant_01": [
        68463,
        462
      ],
      "SFX_Impact_Enemy_Plant_02": [
        69425,
        445
      ],
      "SFX_Impact_Enemy_Plant_03": [
        70370,
        395
      ],
      "SFX_Impact_Enemy_Plant_04": [
        71265,
        405
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Rock": {
    "effectNames": {
      "SFX_Impact_Enemy_Rock_01": [
        72170,
        328
      ],
      "SFX_Impact_Enemy_Rock_02": [
        72998,
        298
      ],
      "SFX_Impact_Enemy_Rock_03": [
        73796,
        363
      ],
      "SFX_Impact_Enemy_Rock_04": [
        74659,
        419
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Impact_Enemy_Shell": {
    "effectNames": {
      "SFX_Impact_Enemy_Shell_01": [
        75578,
        260
      ],
      "SFX_Impact_Enemy_Shell_02": [
        76338,
        232
      ],
      "SFX_Impact_Enemy_Shell_03": [
        77070,
        241
      ],
      "SFX_Impact_Enemy_Shell_04": [
        77811,
        214
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Attack": {
    "effectNames": {
      "SFX_Boss_Fungi_Attack_01": [
        78525,
        1414
      ],
      "SFX_Boss_Fungi_Attack_02": [
        80439,
        1366
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Death_Vox": {
    "effectNames": {
      "SFX_Boss_Fungi_Death_Vox": [
        82305,
        1677
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Emerge": {
    "effectNames": {
      "SFX_Boss_Fungi_Emerge": [
        84482,
        1045
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Growl": {
    "effectNames": {
      "SFX_Boss_Fungi_Growl_01": [
        86027,
        2662
      ],
      "SFX_Boss_Fungi_Growl_02": [
        89189,
        1995
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Hit": {
    "effectNames": {
      "SFX_Boss_Fungi_Hit_01": [
        91684,
        873
      ],
      "SFX_Boss_Fungi_Hit_02": [
        93057,
        671
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Slime_Bubbles": {
    "effectNames": {
      "SFX_Boss_Fungi_Slime_Bubbles_01": [
        94228,
        1689
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Splashes": {
    "effectNames": {
      "SFX_Boss_Fungi_Splashes_01": [
        96417,
        1080
      ],
      "SFX_Boss_Fungi_Splashes_02": [
        97997,
        443
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Fungi_Spore_Pop": {
    "effectNames": {
      "SFX_Boss_Fungi_Spore_Pop_01": [
        98940,
        497
      ],
      "SFX_Boss_Fungi_Spore_Pop_02": [
        99937,
        557
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Item_Augment": {
    "effectNames": {
      "SFX_Item_Augment": [
        100994,
        330
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Item_Discard": {
    "effectNames": {
      "SFX_Item_Discard": [
        101824,
        560
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Item_Drop_Astronomical": {
    "effectNames": {
      "SFX_Item_Drop_Astronomical": [
        102884,
        495
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Item_Drop_Default": {
    "effectNames": {
      "SFX_Item_Drop_Default_01": [
        103879,
        313
      ],
      "SFX_Item_Drop_Default_02": [
        104692,
        302
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Item_Drop_Epic": {
    "effectNames": {
      "SFX_Item_Drop_Epic": [
        105494,
        927
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Item_Drop_Legendary": {
    "effectNames": {
      "SFX_Item_Drop_Legendary": [
        106921,
        846
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Item_Equip": {
    "effectNames": {
      "SFX_Item_Equip": [
        108267,
        293
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Item_Place": {
    "effectNames": {
      "SFX_Item_Place": [
        109060,
        174
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Item_Swap": {
    "effectNames": {
      "SFX_Item_Swap": [
        109734,
        362
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Loot_Pickup": {
    "effectNames": {
      "SFX_Loot_Pickup": [
        110596,
        856
      ]
    },
    "volume": 0.8,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Player_Footfall_Dirt": {
    "effectNames": {
      "Player_Footfall_Dirt_01": [
        111952,
        112
      ],
      "Player_Footfall_Dirt_02": [
        112564,
        136
      ],
      "Player_Footfall_Dirt_03": [
        113200,
        168
      ],
      "Player_Footfall_Dirt_04": [
        113868,
        164
      ],
      "Player_Footfall_Dirt_05": [
        114532,
        140
      ],
      "Player_Footfall_Dirt_06": [
        115172,
        148
      ],
      "Player_Footfall_Dirt_07": [
        115820,
        135
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.5,
      0.9
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Hit_Critical": {
    "effectNames": {
      "Player_Hit_Critical_01": [
        116455,
        325
      ],
      "Player_Hit_Critical_02": [
        117280,
        349
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Hit_Health": {
    "effectNames": {
      "Player_Hit_Health_01": [
        118129,
        264
      ],
      "Player_Hit_Health_02": [
        118893,
        242
      ],
      "Player_Hit_Health_03": [
        119635,
        256
      ],
      "Player_Hit_Health_04": [
        120391,
        266
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Instadeath": {
    "effectNames": {
      "Player_Instadeath_01": [
        121157,
        1322
      ],
      "Player_Instadeath_02": [
        122979,
        1248
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Shield_Block": {
    "effectNames": {
      "Player_Shield_Block_01": [
        124727,
        535
      ],
      "Player_Shield_Block_02": [
        125762,
        408
      ],
      "Player_Shield_Block_03": [
        126670,
        394
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Teleport": {
    "effectNames": {
      "Player_Teleport": [
        127564,
        1000
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Area_Buff": {
    "effectNames": {
      "SFX_Player_Area_Buff": [
        129064,
        1018
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Area_Debuff": {
    "effectNames": {
      "SFX_Player_Area_Debuff": [
        130582,
        1216
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Buff": {
    "effectNames": {
      "SFX_Player_Buff": [
        132298,
        952
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Fully_Healed": {
    "effectNames": {
      "SFX_Player_Fully_Healed": [
        133750,
        1118
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Player_Roll": {
    "effectNames": {
      "SFX_Player_Roll_01": [
        135368,
        310
      ],
      "SFX_Player_Roll_02": [
        136178,
        334
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Player_Speed_Buff": {
    "effectNames": {
      "SFX_Player_Speed_Buff": [
        137012,
        893
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Shield_Regen_Pip": {
    "effectNames": {
      "SFX_Shield_Regen_Pip": [
        138405,
        381
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Level_Up": {
    "effectNames": {
      "SFX_Level_Up": [
        139286,
        1662
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Player_Dodge": {
    "effectNames": {
      "SFX_Player_Dodge_01": [
        141448,
        433
      ],
      "SFX_Player_Dodge_02": [
        142381,
        526
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Shield_Regen": {
    "effectNames": {
      "SFX_Shield_Regen": [
        143407,
        433
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Enemy_Asp_Death": {
    "effectNames": {
      "SFX_Enemy_Asp_Death": [
        144340,
        962
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Asp_Hit": {
    "effectNames": {
      "SFX_Enemy_Asp_Hit_01": [
        145802,
        355
      ],
      "SFX_Enemy_Asp_Hit_02": [
        146657,
        382
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Asp_Shoot": {
    "effectNames": {
      "SFX_Enemy_Asp_Shoot_01": [
        147539,
        803
      ],
      "SFX_Enemy_Asp_Shoot_02": [
        148842,
        654
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Blimpie_Death": {
    "effectNames": {
      "SFX_Enemy_Blimpie_Death": [
        149996,
        476
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Blimpie_Hit": {
    "effectNames": {
      "SFX_Enemy_Blimpie_Hit": [
        150972,
        288
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Blimpie_Shoot": {
    "effectNames": {
      "SFX_Enemy_Blimpie_Shoot_01": [
        151760,
        89
      ],
      "SFX_Enemy_Blimpie_Shoot_02": [
        152349,
        81
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_BoneSpirit_Death": {
    "effectNames": {
      "SFX_Enemy_BoneSpirit_Death": [
        152930,
        1205
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_BoneSpirit_Hit": {
    "effectNames": {
      "SFX_Enemy_BoneSpirit_Hit_01": [
        154635,
        546
      ],
      "SFX_Enemy_BoneSpirit_Hit_02": [
        155681,
        595
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_BoneSpirit_Shoot": {
    "effectNames": {
      "SFX_Enemy_BoneSpirit_Shoot_01": [
        156776,
        643
      ],
      "SFX_Enemy_BoneSpirit_Shoot_02": [
        157919,
        668
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Crab_Death": {
    "effectNames": {
      "SFX_Enemy_Crab_Death": [
        159087,
        1040
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Crab_Hit": {
    "effectNames": {
      "SFX_Enemy_Crab_Hit_01": [
        160627,
        540
      ],
      "SFX_Enemy_Crab_Hit_02": [
        161667,
        586
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Crab_Shoot": {
    "effectNames": {
      "SFX_Enemy_Crab_Shoot_01": [
        162753,
        182
      ],
      "SFX_Enemy_Crab_Shoot_02": [
        163435,
        83
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_CrystalSpider_Death": {
    "effectNames": {
      "SFX_Enemy_CrystalSpider_Death": [
        164018,
        464
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_CrystalSpider_Hit": {
    "effectNames": {
      "SFX_Enemy_CrystalSpider_Hit_01": [
        164982,
        353
      ],
      "SFX_Enemy_CrystalSpider_Hit_02": [
        165835,
        320
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_CrystalSpider_Shoot": {
    "effectNames": {
      "SFX_Enemy_CrystalSpider_Shoot_01": [
        166655,
        548
      ],
      "SFX_Enemy_CrystalSpider_Shoot_02": [
        167703,
        574
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DeathDrake_Death": {
    "effectNames": {
      "SFX_Enemy_DeathDrake_Death": [
        168777,
        999
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DeathDrake_Fall": {
    "effectNames": {
      "SFX_Enemy_DeathDrake_Fall": [
        170276,
        455
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DeathDrake_Hit": {
    "effectNames": {
      "SFX_Enemy_DeathDrake_Hit_01": [
        171231,
        676
      ],
      "SFX_Enemy_DeathDrake_Hit_02": [
        172407,
        732
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DeathDrake_Shoot": {
    "effectNames": {
      "SFX_Enemy_DeathDrake_Shoot_01": [
        173639,
        1063
      ],
      "SFX_Enemy_DeathDrake_Shoot_02": [
        175202,
        957
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DragonSkull_Death": {
    "effectNames": {
      "SFX_Enemy_DragonSkull_Death": [
        176659,
        1711
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DragonSkull_Hit": {
    "effectNames": {
      "SFX_Enemy_DragonSkull_Hit_01": [
        178870,
        748
      ],
      "SFX_Enemy_DragonSkull_Hit_02": [
        180118,
        851
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DragonSkull_Open": {
    "effectNames": {
      "SFX_Enemy_DragonSkull_Open": [
        181469,
        415
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DragonSkull_Rumble": {
    "effectNames": {
      "SFX_Enemy_DragonSkull_Rumble": [
        182384,
        1339
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_DragonSkull_Shoot": {
    "effectNames": {
      "SFX_Enemy_DragonSkull_Shoot_01": [
        184223,
        864
      ],
      "SFX_Enemy_DragonSkull_Shoot_02": [
        185587,
        759
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Ent_Death": {
    "effectNames": {
      "SFX_Enemy_Ent_Death": [
        186846,
        1190
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Ent_Fall": {
    "effectNames": {
      "SFX_Enemy_Ent_Fall_01": [
        188536,
        585
      ],
      "SFX_Enemy_Ent_Fall_02": [
        189621,
        790
      ],
      "SFX_Enemy_Ent_Fall_03": [
        190911,
        517
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Ent_Hit": {
    "effectNames": {
      "SFX_Enemy_Ent_Hit_01": [
        191928,
        530
      ],
      "SFX_Enemy_Ent_Hit_02": [
        192958,
        630
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Ent_Shoot": {
    "effectNames": {
      "SFX_Enemy_Ent_Shoot_01": [
        194088,
        600
      ],
      "SFX_Enemy_Ent_Shoot_02": [
        195188,
        620
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Ent_Swipe": {
    "effectNames": {
      "SFX_Enemy_Ent_Swipe_01": [
        196308,
        341
      ],
      "SFX_Enemy_Ent_Swipe_02": [
        197149,
        319
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Ent_Walk": {
    "effectNames": {
      "SFX_Enemy_Ent_Walk_01": [
        197968,
        604
      ],
      "SFX_Enemy_Ent_Walk_02": [
        199072,
        585
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_EyeBat_Death": {
    "effectNames": {
      "SFX_Enemy_EyeBat_Death": [
        200157,
        796
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_EyeBat_Hit_02": {
    "effectNames": {
      "SFX_Enemy_EyeBat_Hit_01": [
        201453,
        483
      ],
      "SFX_Enemy_EyeBat_Hit_02": [
        202436,
        639
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_EyeBat_Shoot": {
    "effectNames": {
      "SFX_Enemy_EyeBat_Shoot_01": [
        203575,
        448
      ],
      "SFX_Enemy_EyeBat_Shoot_02": [
        204523,
        526
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Goblin_Death": {
    "effectNames": {
      "SFX_Enemy_Goblin_Death_01": [
        205549,
        738
      ],
      "SFX_Enemy_Goblin_Death_02": [
        206787,
        830
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Goblin_Hit": {
    "effectNames": {
      "SFX_Enemy_Goblin_Hit_01": [
        208117,
        609
      ],
      "SFX_Enemy_Goblin_Hit_02": [
        209226,
        639
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Goblin_Throw": {
    "effectNames": {
      "SFX_Enemy_Goblin_Throw": [
        210365,
        186
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Golem_Death": {
    "effectNames": {
      "SFX_Enemy_Golem_Death": [
        211051,
        632
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Golem_Hit": {
    "effectNames": {
      "SFX_Enemy_Golem_Hit_01": [
        212183,
        341
      ],
      "SFX_Enemy_Golem_Hit_02": [
        213024,
        391
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Golem_Shoot": {
    "effectNames": {
      "SFX_Enemy_Golem_Shoot_01": [
        213915,
        959
      ],
      "SFX_Enemy_Golem_Shoot_02": [
        215374,
        831
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_HermitCrab_ArmorUp": {
    "effectNames": {
      "SFX_Enemy_HermitCrab_ArmorUp": [
        216705,
        335
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mosquito_Death": {
    "effectNames": {
      "SFX_Enemy_Mosquito_Death": [
        217540,
        482
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mosquito_Hit": {
    "effectNames": {
      "SFX_Enemy_Mosquito_Hit_01": [
        218522,
        455
      ],
      "SFX_Enemy_Mosquito_Hit_02": [
        219477,
        483
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mosquito_Shoot": {
    "effectNames": {
      "SFX_Enemy_Mosquito_Shoot_01": [
        220460,
        485
      ],
      "SFX_Enemy_Mosquito_Shoot_02": [
        221445,
        490
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mushie_Death": {
    "effectNames": {
      "SFX_Enemy_Mushie_Death": [
        222435,
        1070
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mushie_Hit": {
    "effectNames": {
      "SFX_Enemy_Mushie_Hit_01": [
        224005,
        647
      ],
      "SFX_Enemy_Mushie_Hit_02": [
        225152,
        690
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mushie_Shoot": {
    "effectNames": {
      "SFX_Enemy_Mushie_Shoot_01": [
        226342,
        549
      ],
      "SFX_Enemy_Mushie_Shoot_02": [
        227391,
        619
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Mushie_Squish": {
    "effectNames": {
      "SFX_Enemy_Mushie_Squish": [
        228510,
        504
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_ShamblingMound_Death": {
    "effectNames": {
      "SFX_Enemy_ShamblingMound_Death": [
        229514,
        1299
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_ShamblingMound_Hit": {
    "effectNames": {
      "SFX_Enemy_ShamblingMound_Hit_01": [
        231313,
        341
      ],
      "SFX_Enemy_ShamblingMound_Hit_02": [
        232154,
        313
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Shrieker_Death": {
    "effectNames": {
      "SFX_Enemy_Shrieker_Death": [
        232967,
        732
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Shrieker_Hit": {
    "effectNames": {
      "SFX_Enemy_Shrieker_Hit_01": [
        234199,
        568
      ],
      "SFX_Enemy_Shrieker_Hit_02": [
        235267,
        504
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_SkeletalWhelp_Death": {
    "effectNames": {
      "SFX_Enemy_SkeletalWhelp_Death": [
        236271,
        466
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_SkeletalWhelp_Shoot": {
    "effectNames": {
      "SFX_Enemy_SkeletalWhelp_Shoot_01": [
        237237,
        226
      ],
      "SFX_Enemy_SkeletalWhelp_Shoot_02": [
        237963,
        240
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Spider_Death": {
    "effectNames": {
      "SFX_Enemy_Spider_Death": [
        238703,
        521
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Spider_Hit": {
    "effectNames": {
      "SFX_Enemy_Spider_Hit_01": [
        239724,
        267
      ],
      "SFX_Enemy_Spider_Hit_02": [
        240491,
        270
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Spider_Shoot": {
    "effectNames": {
      "SFX_Enemy_Spider_Shoot": [
        241261,
        270
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_SporeKid_Death": {
    "effectNames": {
      "SFX_Enemy_SporeKid_Death": [
        242031,
        799
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_SporeKid_Hit": {
    "effectNames": {
      "SFX_Enemy_SporeKid_Hit_01": [
        243330,
        581
      ],
      "SFX_Enemy_SporeKid_Hit_02": [
        244411,
        686
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_SporeKid_Shoot": {
    "effectNames": {
      "SFX_Enemy_SporeKid_Shoot_01": [
        245597,
        117
      ],
      "SFX_Enemy_SporeKid_Shoot_02": [
        246214,
        89
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_StrangleVine_Death": {
    "effectNames": {
      "SFX_Enemy_StrangleVine_Death": [
        246803,
        1529
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_StrangleVine_LongAttack": {
    "effectNames": {
      "SFX_Enemy_StrangleVine_LongAttack": [
        248832,
        901
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_StrangleVine_ShortAttack": {
    "effectNames": {
      "SFX_Enemy_StrangleVine_ShortAttack": [
        250233,
        850
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_ThornWolf_Death": {
    "effectNames": {
      "SFX_Enemy_ThornWolf_Death": [
        251583,
        365
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_ThornWolf_Hit": {
    "effectNames": {
      "SFX_Enemy_ThornWolf_Hit_01": [
        252448,
        192
      ],
      "SFX_Enemy_ThornWolf_Hit_02": [
        253140,
        234
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_ThornWolf_Shoot": {
    "effectNames": {
      "SFX_Enemy_ThornWolf_Shoot_01": [
        253874,
        415
      ],
      "SFX_Enemy_ThornWolf_Shoot_02": [
        254789,
        448
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Wasp_Death": {
    "effectNames": {
      "SFX_Enemy_Wasp_Death": [
        255737,
        451
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Wasp_Hit": {
    "effectNames": {
      "SFX_Enemy_Wasp_Hit_01": [
        256688,
        277
      ],
      "SFX_Enemy_Wasp_Hit_02": [
        257465,
        277
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Wasp_Shoot": {
    "effectNames": {
      "SFX_Enemy_Wasp_Shoot_01": [
        258242,
        230
      ],
      "SFX_Enemy_Wasp_Shoot_02": [
        258972,
        240
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Wisp_Death": {
    "effectNames": {
      "SFX_Enemy_Wisp_Death": [
        259712,
        925
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Wisp_Hit": {
    "effectNames": {
      "SFX_Enemy_Wisp_Hit_01": [
        261137,
        567
      ],
      "SFX_Enemy_Wisp_Hit_02": [
        262204,
        623
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Wisp_Shoot": {
    "effectNames": {
      "SFX_Enemy_Wisp_Shoot_01": [
        263327,
        1393
      ],
      "SFX_Enemy_Wisp_Shoot_02": [
        265220,
        1505
      ]
    },
    "volume": 0.3,
    "volumeInterval": [
      0.25,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Elemental_Blood": {
    "effectNames": {
      "SFX_Elemental_Blood": [
        267225,
        631
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Elemental_Fire": {
    "effectNames": {
      "SFX_Elemental_Fire": [
        268356,
        815
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Elemental_Ice": {
    "effectNames": {
      "SFX_Elemental_Ice": [
        269671,
        708
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Elemental_Poison": {
    "effectNames": {
      "SFX_Elemental_Poison": [
        270879,
        1056
      ]
    },
    "volume": 0.6,
    "volumeInterval": [
      0.5,
      0.6
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Elemental_Thunder": {
    "effectNames": {
      "SFX_Elemental_Thunder": [
        272435,
        1216
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.6
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Bubba_Grunt": {
    "effectNames": {
      "SFX_Bubba_Grunt_01": [
        274151,
        303
      ],
      "SFX_Bubba_Grunt_02": [
        274954,
        247
      ],
      "SFX_Bubba_Grunt_03": [
        275701,
        268
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Bubba_Purchase": {
    "effectNames": {
      "SFX_Bubba_Purchase": [
        276469,
        764
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Chicken_Shame": {
    "effectNames": {
      "SFX_Chicken_Shame": [
        277733,
        1168
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Death_Fanfare": {
    "effectNames": {
      "SFX_Death_Fanfare": [
        279401,
        2169
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Worm_Gurgle": {
    "effectNames": {
      "SFX_Worm_Gurgle_01": [
        282070,
        578
      ],
      "SFX_Worm_Gurgle_02": [
        283148,
        571
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Boss_Crab_Claw_Impact": {
    "effectNames": {
      "SFX_Boss_Crab_Claw_Impact": [
        284219,
        760
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Dig": {
    "effectNames": {
      "SFX_Boss_Crab_Dig": [
        285479,
        1020
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Emerge": {
    "effectNames": {
      "SFX_Boss_Crab_Emerge": [
        286999,
        800
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Explode": {
    "effectNames": {
      "SFX_Boss_Crab_Explode": [
        288299,
        1225
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Land": {
    "effectNames": {
      "SFX_Boss_Crab_Land": [
        290024,
        602
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Skeleton_Shoot": {
    "effectNames": {
      "SFX_Boss_Crab_Skeleton_Shoot": [
        291126,
        1037
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Vox_Attack": {
    "effectNames": {
      "SFX_Boss_Crab_Vox_Attack_01": [
        292663,
        830
      ],
      "SFX_Boss_Crab_Vox_Attack_02": [
        293993,
        802
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Vox_Dance": {
    "effectNames": {
      "SFX_Boss_Crab_Vox_Dance_01": [
        295295,
        454
      ],
      "SFX_Boss_Crab_Vox_Dance_02": [
        296249,
        469
      ],
      "SFX_Boss_Crab_Vox_Dance_03": [
        297218,
        388
      ],
      "SFX_Boss_Crab_Vox_Dance_04": [
        298106,
        394
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Vox_Death": {
    "effectNames": {
      "SFX_Boss_Crab_Vox_Death": [
        299000,
        1463
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Crab_Vox_Hit": {
    "effectNames": {
      "SFX_Boss_Crab_Vox_Hit_01": [
        300963,
        410
      ],
      "SFX_Boss_Crab_Vox_Hit_02": [
        301873,
        414
      ],
      "SFX_Boss_Crab_Vox_Hit_03": [
        302787,
        414
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Appear_A": {
    "effectNames": {
      "SFX_Boss_Forest_Appear_A": [
        303701,
        1100
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Appear_B": {
    "effectNames": {
      "SFX_Boss_Forest_Appear_B": [
        305301,
        990
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Appear_C": {
    "effectNames": {
      "SFX_Boss_Forest_Appear_C": [
        306791,
        808
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Fade_A": {
    "effectNames": {
      "SFX_Boss_Forest_Fade_A": [
        308099,
        570
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Fade_B": {
    "effectNames": {
      "SFX_Boss_Forest_Fade_B": [
        309169,
        550
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Fade_C": {
    "effectNames": {
      "SFX_Boss_Forest_Fade_C": [
        310219,
        600
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Poof_A": {
    "effectNames": {
      "SFX_Boss_Forest_Poof_A": [
        311319,
        1256
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Poof_B": {
    "effectNames": {
      "SFX_Boss_Forest_Poof_B": [
        313075,
        1203
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Shoot_A": {
    "effectNames": {
      "SFX_Boss_Forest_Shoot_A": [
        314778,
        2865
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Vox_Death": {
    "effectNames": {
      "SFX_Boss_Forest_Vox_Death": [
        318143,
        1190
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Forest_Vox_Hit": {
    "effectNames": {
      "SFX_Boss_Forest_Vox_Hit_01": [
        319833,
        753
      ],
      "SFX_Boss_Forest_Vox_Hit_02": [
        321086,
        733
      ],
      "SFX_Boss_Forest_Vox_Hit_03": [
        322319,
        658
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.4,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Forest_Vox_Laugh": {
    "effectNames": {
      "SFX_Boss_Forest_Vox_Laugh_01": [
        323477,
        1823
      ],
      "SFX_Boss_Forest_Vox_Laugh_02": [
        325800,
        2100
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.4,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Forest_Vox_Laugh_Short": {
    "effectNames": {
      "SFX_Boss_Forest_Vox_Laugh_Short_01": [
        328400,
        773
      ],
      "SFX_Boss_Forest_Vox_Laugh_Short_02": [
        329673,
        773
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.4,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Boss_Forest_Whoosh_A": {
    "effectNames": {
      "SFX_Boss_Forest_Whoosh_A": [
        330946,
        892
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Forest_Zap": {
    "effectNames": {
      "SFX_Boss_Forest_Zap_01": [
        332338,
        504
      ],
      "SFX_Boss_Forest_Zap_02": [
        333342,
        710
      ],
      "SFX_Boss_Forest_Zap_03": [
        334552,
        650
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Magic_A": {
    "effectNames": {
      "SFX_Boss_Highlands_Magic_A": [
        335702,
        1634
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Magic_B": {
    "effectNames": {
      "SFX_Boss_Highlands_Magic_B": [
        337836,
        1688
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Magic_C": {
    "effectNames": {
      "SFX_Boss_Highlands_Magic_C": [
        340024,
        1688
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Shard": {
    "effectNames": {
      "SFX_Boss_Highlands_Shard": [
        342212,
        1388
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Shield": {
    "effectNames": {
      "SFX_Boss_Highlands_Shield": [
        344100,
        2050
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Snake_Hiss": {
    "effectNames": {
      "SFX_Boss_Highlands_Snake_Hiss_01": [
        346650,
        1176
      ],
      "SFX_Boss_Highlands_Snake_Hiss_02": [
        348326,
        1054
      ],
      "SFX_Boss_Highlands_Snake_Hiss_03": [
        349880,
        1309
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.4,
      0.6
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Thunder": {
    "effectNames": {
      "SFX_Boss_Highlands_Thunder": [
        351689,
        1777
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Turn_Stone": {
    "effectNames": {
      "SFX_Boss_Highlands_Turn_Stone": [
        353966,
        887
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Highlands_Vox_Scream": {
    "effectNames": {
      "SFX_Boss_Highlands_Vox_Scream": [
        355353,
        1914
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Breath": {
    "effectNames": {
      "SFX_Boss_Prism_Breath": [
        357767,
        2320
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Cocoon_In": {
    "effectNames": {
      "SFX_Boss_Prism_Cocoon_In": [
        360587,
        830
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Cocoon_Out": {
    "effectNames": {
      "SFX_Boss_Prism_Cocoon_Out": [
        361917,
        1158
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Death_Explode": {
    "effectNames": {
      "SFX_Boss_Prism_Death_Explode": [
        363575,
        1048
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Death_Freeze": {
    "effectNames": {
      "SFX_Boss_Prism_Death_Freeze": [
        365123,
        2055
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Flap": {
    "effectNames": {
      "SFX_Boss_Prism_Flap_01": [
        367678,
        530
      ],
      "SFX_Boss_Prism_Flap_02": [
        368708,
        500
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Land": {
    "effectNames": {
      "SFX_Boss_Prism_Land": [
        369708,
        735
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Vox_Attack": {
    "effectNames": {
      "SFX_Boss_Prism_Vox_Attack_01": [
        370943,
        1351
      ],
      "SFX_Boss_Prism_Vox_Attack_02": [
        372794,
        1379
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Vox_Death": {
    "effectNames": {
      "SFX_Boss_Prism_Vox_Death": [
        374673,
        1295
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Vox_Hurt": {
    "effectNames": {
      "SFX_Boss_Prism_Vox_Hurt_01": [
        376468,
        1350
      ],
      "SFX_Boss_Prism_Vox_Hurt_02": [
        378318,
        1130
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Prism_Wind": {
    "effectNames": {
      "SFX_Boss_Prism_Wind": [
        379948,
        1279
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Battery": {
    "effectNames": {
      "SFX_Gear_Battery": [
        381727,
        1565
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Battle_Cry": {
    "effectNames": {
      "SFX_Gear_Battle_Cry": [
        383792,
        3210
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Bulwark_Start": {
    "effectNames": {
      "SFX_Gear_Bulwark_Start": [
        387502,
        943
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Bulwark_Break": {
    "effectNames": {
      "SFX_Gear_Bulwark_Break": [
        388945,
        633
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Gravity_Start": {
    "effectNames": {
      "SFX_Gear_Gravity_Start": [
        390078,
        3029
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Gear_Gravity_Loop": {
    "effectNames": {
      "SFX_Gear_Gravity_Loop": [
        393607,
        4270
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Overcharged_Start": {
    "effectNames": {
      "SFX_Gear_Overcharged_Start": [
        398377,
        1943
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Overcharged_Loop": {
    "effectNames": {
      "SFX_Gear_Overcharged_Loop": [
        400820,
        2338
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Overshield_Start": {
    "effectNames": {
      "SFX_Gear_Overshield_Start": [
        403658,
        1407
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Overshield_Pop": {
    "effectNames": {
      "SFX_Gear_Overshield_Pop": [
        405565,
        1076
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Sickening_Nova": {
    "effectNames": {
      "SFX_Gear_Sickening_Nova": [
        407141,
        2182
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Stone_Start": {
    "effectNames": {
      "SFX_Gear_Stone_Start": [
        409823,
        1619
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Gear_Stone_Loop": {
    "effectNames": {
      "SFX_Gear_Stone_Loop": [
        411942,
        1477
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Lute_Stinger": {
    "effectNames": {
      "SFX_Lute_Stinger_01": [
        413919,
        3758
      ],
      "SFX_Lute_Stinger_02": [
        418177,
        3309
      ],
      "SFX_Lute_Stinger_03": [
        421986,
        2709
      ]
    },
    "volume": 0.5,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      0.95,
      1.05
    ]
  },
  "SFX_Lute": {
    "effectNames": {
      "SFX_Lute_Loop": [
        425195,
        8896
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Choreo_Warning": {
    "effectNames": {
      "SFX_Choreo_Warning": [
        434591,
        1600
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Knockback": {
    "effectNames": {
      "SFX_Knockback": [
        436691,
        812
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Acid_Bottle": {
    "effectNames": {
      "SFX_Acid_Bottle": [
        438003,
        504
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Boss_Hateful_Grace": {
    "effectNames": {
      "SFX_Boss_Hateful_Grace": [
        439007,
        1833
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Tesla_Coil": {
    "effectNames": {
      "SFX_Tesla_Coil_01": [
        441340,
        986
      ],
      "SFX_Tesla_Coil_02": [
        442826,
        1180
      ],
      "SFX_Tesla_Coil_03": [
        444506,
        861
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.8,
      1.1
    ]
  },
  "SFX_Wand_Pulse": {
    "effectNames": {
      "SFX_Wand_Pulse_01": [
        445867,
        333
      ],
      "SFX_Wand_Pulse_02": [
        446700,
        333
      ],
      "SFX_Wand_Pulse_03": [
        447533,
        333
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.4,
      0.4
    ],
    "rate": 1.0,
    "rateInterval": [
      0.95,
      1.15
    ]
  },
  "SFX_Hunters_Mark": {
    "effectNames": {
      "SFX_Hunters_Mark": [
        448366,
        1423
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Reload_Start": {
    "effectNames": {
      "SFX_Enemy_Golem_Hit_01": [
        450289,
        341
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.2,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Reload_Finish": {
    "effectNames": {
      "SFX_Reload_Finish": [
        451130,
        556
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Doom_Explosion": {
    "effectNames": {
      "SFX_Doom_Explosion": [
        452186,
        2263
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.5,
      0.5
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Upgrade": {
    "effectNames": {
      "SFX_Upgrade": [
        454949,
        2780
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Trap_Trigger": {
    "effectNames": {
      "SFX_Trap_Trigger": [
        458229,
        1500
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      1.0,
      1.0
    ],
    "rate": 1.0,
    "rateInterval": [
      1.0,
      1.0
    ]
  },
  "SFX_Fire_Fairy_Shoot": {
    "effectNames": {
      "SFX_Fire_Fairy_Shoot": [
        460229,
        2663
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.6,
      0.6
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_IceDrake_Spawn": {
    "effectNames": {
      "SFX_IceDrake_Spawn": [
        463392,
        3861
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.6,
      0.6
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Lightning_Rod": {
    "effectNames": {
      "SFX_Lightning_Rod": [
        467753,
        6179
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.6,
      0.6
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Monster_Merge": {
    "effectNames": {
      "SFX_Monster_Merge": [
        474432,
        373
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Monster_Whistle_Pickup": {
    "effectNames": {
      "SFX_Monster_Whistle_Pickup": [
        475305,
        1843
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.6,
      0.6
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_MrCuddles_Spawn": {
    "effectNames": {
      "SFX_MrCuddles_Spawn": [
        477648,
        2747
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.6,
      0.6
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Nikola_Scope_Fire": {
    "effectNames": {
      "SFX_Nikola_Scope_Fire": [
        480895,
        3232
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Pet_Offensive": {
    "effectNames": {
      "SFX_Pet_Offensive": [
        484627,
        696
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.6,
      0.6
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Pet_Rescue_Complete": {
    "effectNames": {
      "SFX_Pet_Rescue_Complete": [
        485823,
        7595
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Pheonix_Bomb_Explosion": {
    "effectNames": {
      "SFX_Pheonix_Bomb_Explosion": [
        493918,
        2424
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Pickup_Radar": {
    "effectNames": {
      "SFX_Pickup_Radar": [
        496842,
        501
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Radar_Skill": {
    "effectNames": {
      "SFX_Radar_Skill": [
        497843,
        1856
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Solara_Skill": {
    "effectNames": {
      "SFX_Solara_Skill": [
        500199,
        3528
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Spooky_Ghost": {
    "effectNames": {
      "SFX_Spooky_Ghost": [
        504227,
        7241
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Supply_Drop": {
    "effectNames": {
      "SFX_Supply_Drop": [
        511968,
        618
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Victory_Fanfare": {
    "effectNames": {
      "SFX_Victory_Fanfare": [
        513086,
        7595
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Yeti_Spawn": {
    "effectNames": {
      "SFX_Yeti_Spawn": [
        521181,
        2813
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.6,
      0.6
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  },
  "SFX_Enemy_Hit": {
    "effectNames": {
      "SFX_Enemy_Hit": [
        524494,
        253
      ]
    },
    "volume": 1.0,
    "volumeInterval": [
      0.3,
      0.3
    ],
    "rate": 1.0,
    "rateInterval": [
      0.9,
      1.1
    ]
  }
}