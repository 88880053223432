import EntityStatList, { StatBonus } from '../../../stats/entity-stat-list'
import { PrimaryWeapon } from '../../primary-weapon'
import { ResourceType, WEAPON_STATS } from '../../weapon-definitions'
import { AllWeaponTypes } from '../../weapon-types'
import { defaultStatAttribute } from '../../../game-data/stat-formulas'
import { ParticleEffectType } from '../../../engine/graphics/pfx/particle-config'
import ClientPlayerInput, { InputAction } from '../../../engine/client-player-input'
import { InstancedSprite } from '../../../engine/graphics/instanced-sprite'
import { AssetManager } from '../../../web/asset-manager'
import { Renderer } from '../../../engine/graphics/renderer'
import { InGameTime } from '../../../utils/time'
import { percentage, radians, timeInMilliseconds, timeInSeconds } from '../../../utils/primitive-types'
import { Vector } from 'sat'
import { VectorXY, degToRad, distanceSquaredVV, distanceVV, getProjectileVelocity, getRandomPointInCircleRange, getRandomPointInRectRange } from '../../../utils/math'
import { CANNON_SHOT_GRAVITY_RATE, CannonShot, CannonShotParams } from './cannon-shot'
import { ObjectPool, ObjectPoolTyped } from '../../../utils/third-party/object-pool'
import { dealAOEDamageDamageSource } from '../../../projectiles/explosions'
import { CollisionLayerBits } from '../../../engine/collision/collision-layers'
import { StatOperator, StatType } from '../../../stats/stat-interfaces-enums'
import { DamageSource } from '../../../projectiles/damage-source'
import { DamageableEntityType, EntityType } from '../../../entities/entity-interfaces'
import { angleBetweenVectors } from '../../../utils/vector'
import { Buff } from '../../../buffs/buff'
import { BuffIdentifier } from '../../../buffs/buff.shared'
import { EffectConfig } from '../../../engine/graphics/pfx/effectConfig'
import CollisionSystem from '../../../engine/collision/collision-system'
import { GroundPickup } from '../../../entities/pickups/ground-pickup'
import { RepulsionBlasts } from '../../../entities/repulsion-blasts'
import ProjectileEffectManager from '../../../engine/graphics/projectile-effect-manager'
import { PlayerProjectile, ProjectileInitialParams } from '../../../projectiles/projectile'
import { GameState } from "../../../engine/game-state"
import { FallingBitParams, FallingBits } from "../../../entities/falling-bits"
import { Beam, BeamParams, makeBeamPool } from "../../../beams/beams"
import { callbacks_addCallback } from "../../../utils/callback-system"
import { BigIgnitePoolHazard, BigIgnitePoolHazardParams } from '../../../entities/hazards/big-ignite-pool-hazard'
import { getIgniteStacks } from '../../../buffs/generic-buff-definitions'

const MIN_SHOT_DISTANCE: number = 200
const MAX_SHOT_DISTANCE: number = 1_020
const AIM_SPEED_SCALAR: number = 1

const AIM_ARROW_GFX_X_OFFSET = -40
const AIM_ARROW_GFX_Y_OFFSET = -220
const AIM_ARROW_GFX_ROT: radians = 0

const AIM_GROUND_GFX_SCALE = 0.25
const AIM_ARROW_GFX_SCALE = 0.25
const AIM_LINE_GFX_SCALE = 1.0

const AIM_LINE_GFX_OFFSET = 105

const CANNON_ANGLE_OFFSET = degToRad(12)

const MIN_CANNON_WEAPON_ANGLE: radians = degToRad(3)
const MAX_CANNON_WEAPON_ANGLE: radians = degToRad(30)

const DEFAULT_SHOT_TIME_SCALE = 1.20 // hacky / temp maybe

const ABSURD_SHOT_FORCE_CUTOFF = 3_000
const SHOT_LERPED_SPEED = 1000 //** 2

const EXPLOSION_Z_OFFSET = 200

const PLAYER_DAMAGING_EXPLOSION_RADIUS_SCALE = 0.7

// --- Upgrades ---

const DOUBLE_TAP_SECOND_EXPLOSION_SIZE_PENALTY: percentage = 0.75
const DOUBLE_TAP_SECOND_EXPLOSION_DAMAGE_SCALE: percentage = 1.8
const DOUBLE_TAP_SECOND_SHOT_Y_FORCE: number = -850

const JUGGLE_CATCH_DIST2 = 100 ** 2
const JUGGLE_CATCH_SIZE_BONUS_PER_CATCH: percentage = 0.2
const JUGGLE_CATCH_ATTACK_BONUS_PER_CATCH: percentage = 0.4
const JUGGLE_CATCH_KNOCKBACK_RANGE: number = 900
const JUGGLE_CATCH_KNOCKBACK_STRENGTH: number = 2_000
const JUGGLE_CATCH_KNOCKBACK_BONUS_PER_CATCH: number = 200
const JUGGLE_MAX_CATCH_COUNT: number = 5
const MAX_JUGGLE_SIZE_BONUS: percentage = 1 + (JUGGLE_MAX_CATCH_COUNT + 1) * JUGGLE_CATCH_SIZE_BONUS_PER_CATCH
const MAX_JUGGLE_DAMAGE_BONUS: percentage = 1 + (JUGGLE_MAX_CATCH_COUNT + 1) * JUGGLE_CATCH_ATTACK_BONUS_PER_CATCH
const MAX_JUGGLE_INVULN_TIME: timeInMilliseconds = 2_000
const JUGGLE_NEW_SHOT_MIN_HEIGHT: number = 50
const JUGGLE_NEW_SHOT_MIN_WIDTH: number = 200
const JUGGLE_NEW_SHOT_MAX_HEIGHT: number = 350
const JUGGLE_NEW_SHOT_MAX_WIDTH: number = 400
const JUGGLE_NEW_SHOT_TIMESCALE: percentage = 0.75
const JUGGLE_IGNITE_CHANCE_BONUS: percentage = 1

const IRRADIATION_ZONE_DURATION: timeInSeconds = 3.0
const IRRADIATION_ZONE_BONUS_SIZE: percentage = 0.72

const EXPLOSION_GFX_DEFAULT_SIZE: number = 320
const JUGGLE_REPULSION_Z_OFFSET = -300
const JUGGLE_REPULSION_GFX_DEFAULT_SIZE = 320

const PICKUP_VACUUM_FORCE_SCALE: number = 2.75

const JUGGLE_SHOT_PFX_NAMES = [
    'atomic-blast-projectile-01',
    'atomic-blast-projectile-02',
    'atomic-blast-projectile-03',
    'atomic-blast-projectile-04',
    'atomic-blast-projectile-05',
] as const

type CannonShotPfxNames = 'cannon-basic-projectile' | 'chem-enhanced-payload-projectile-01' | 'chem-enhanced-payload-projectile-02' | 'cannon-egg-projectile' | typeof JUGGLE_SHOT_PFX_NAMES[number]

const MIASMA_DAMAGE_SCALE = 0.10
const MIASMA_LIFESPAN = 3

const RAINING_AGONY_BEAM_ROTATED_OFFSET = new Vector(50, 0)
const RAINING_AGONY_BEAM_WIDTH_SCALE = 0.5
const RAINING_AGONY_DELAY_TIME = 0.15

const BOMB_WALL_MINI_BOMB_OFFSET = 175
const BOMB_WALL_MINI_BOMB_SHOT_DELAY: timeInSeconds = 0.1

const RANDOM_CLUSTER_FIXED_DISTANCE = 150
const RANDOM_CLUSTER_DISTANCE_MULTIPLIER = 0
const PARASITIC_MINI_CANNON_DAMAGE_SCALE = 0.8
const PARASITIC_EGG_AOE_SCALE = 1.2

const AUTO_AIM_TARGET_ENEMY_DIST2 = 200**2

export class Cannon extends PrimaryWeapon implements DamageSource {
	nid: number
	entityType: EntityType
	timeScale: number = 1
	weaponType: AllWeaponTypes = AllWeaponTypes.Cannon

	projectileEffectType: ParticleEffectType = ParticleEffectType.PROJECTILE_NONE
	projectileTrailType: ParticleEffectType = ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL

	numEntitiesChained: number = 0
	numEntitiesPierced: number = 0
	showImmediateDamageNumber: boolean = true

	shotTimeScale: number = DEFAULT_SHOT_TIME_SCALE
    private hasDoubleTap: boolean = false
    private hasHangtimeJuggle: boolean = false
    private hasMiasma: boolean = false
    private hasRainingAgony: boolean = false

	hasExplosiveVacuum: boolean = false
	private repulsionBlasts: RepulsionBlasts

	private isAiming: boolean = false
	private isAimingUp: boolean = false

	private aimDistancePercent: number = 0

    private aimArrowGfx: InstancedSprite
    private aimGroundGfx: InstancedSprite
	private forwardLineGfx: InstancedSprite
	private upWallGfx: InstancedSprite
	private downWallGfx: InstancedSprite
    private static aimGroundGfxPool: ObjectPool
    private static aimLineGfxPool: ObjectPool

	private aimGroundDirectionVector: Vector = new Vector()
	private aimGroundPosVector: Vector = new Vector()
	private aimArrowOffsetVector = new Vector()
	private reuseAngleVector = new Vector()
	private juggleReusePosVector = new Vector()
	private explosiveVacuumReuseVector = new Vector()

	private cannonWeaponAngle: radians

	private reuseCannonShotParams: CannonShotParams

	private juggleRepulsionEffectConfig: EffectConfig
	private juggleNukeEffectConfig: EffectConfig
	private doubleTapLargeEffectConfig: EffectConfig

	private clusterBombExplosionConfig: EffectConfig

	// private parasiticEggEffectConfig: EffectConfig
	// private goopyExplosionEffectConfig: EffectConfig

	private juggleIgniteBonus: StatBonus

	hasParasiticCluster: boolean
	hasRandomBomb: boolean
	hasFallingBomb: boolean
	hasBombWall: boolean
	hasWorms: boolean
	hasGoopyExplosion: boolean
	hasIrradiationZone: boolean

	private projectileCreationParams: ProjectileInitialParams
	private irradiationZoneCreationParams: BigIgnitePoolHazardParams

    private miniBombVector: Vector = new Vector()
	private distanceVector: Vector = new Vector()

    private cannonShotPFX: CannonShotPfxNames
    private miasmaProjectileParams: ProjectileInitialParams
	private rainingAgonyBeamParams: BeamParams
    private rainingAgonyReuseOffset: Vector = new Vector(0, 0)
    private rainingAgonyStatList: EntityStatList
    private rainingAgonyStatBonus: StatBonus

	private activeShotCount: number = 0

	init(player, playerStatList: EntityStatList) {
		const arrowGfxTex = AssetManager.getInstance().getAssetByName('cannon-mark-arrow').texture
		const groundGfxTex = AssetManager.getInstance().getAssetByName('cannon-mark').texture
		const groundLineGfxTex = AssetManager.getInstance().getAssetByName('cannon-line-indicator').texture

        this.aimArrowGfx = new InstancedSprite(arrowGfxTex, 0, 0, 999_900, AIM_ARROW_GFX_SCALE, AIM_ARROW_GFX_SCALE, AIM_ARROW_GFX_ROT)

        if (!Cannon.aimGroundGfxPool) {
            Cannon.aimGroundGfxPool = new ObjectPool(() => {
                return new InstancedSprite(groundGfxTex, 0, 0, 999_900, AIM_GROUND_GFX_SCALE, AIM_GROUND_GFX_SCALE)
            }, {}, 3, 1)
        }
		if (!Cannon.aimLineGfxPool) {
            Cannon.aimLineGfxPool = new ObjectPool(() => {
                return new InstancedSprite(groundLineGfxTex, 0, 0, 999_900, AIM_LINE_GFX_SCALE, AIM_LINE_GFX_SCALE)
            }, {}, 6, 1)
        }
        this.aimGroundGfx = Cannon.aimGroundGfxPool.alloc() as InstancedSprite
		this.forwardLineGfx = Cannon.aimLineGfxPool.alloc() as InstancedSprite

		if (!CannonShot.pool) {
			CannonShot.pool = new ObjectPoolTyped(() => new CannonShot(), {}, 3, 1, 'cannon-shot-pool')
		}

        this.reuseCannonShotParams = {
            originPos: new Vector(),
            targetPos: this.aimGroundPosVector,
            velocity: new Vector(),
            yKillPlane: 0,
            reachedTargetCallback: this.onShotHitTarget.bind(this),
        }

		this.projectileCreationParams = {
            owningEntityId: this.player.nid,
			position: new Vector(),
			speed: this.statList.getStat(StatType.projectileSpeed) / 4,
			aimAngleInRads: 0,
			radius: 30,
			trajectoryMods: [],
			collisionLayer: CollisionLayerBits.PlayerProjectile,
			statList: this.statList,
			resourceType: ResourceType.NONE,
			player: this.player,
			weaponType: this.weaponType,
            effectType: ParticleEffectType.WORM,
            trailType: ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL,
			isSplit: true,
			damageScale: 0.6

        }

        this.juggleRepulsionEffectConfig = AssetManager.getInstance().getAssetByName('double-tap-repulsion-pfx').data
        this.juggleNukeEffectConfig = AssetManager.getInstance().getAssetByName('atomic-blast-base-explosion').data
        this.doubleTapLargeEffectConfig = AssetManager.getInstance().getAssetByName('hangtime-juggle-explosion').data

        this.rainingAgonyStatList = new EntityStatList(this._resetRainingAgonyStats, this.statList)
        this.rainingAgonyStatBonus = this.rainingAgonyStatList.addStatBonus(StatType.allAilmentPotencyMult, StatOperator.MULTIPLY, -0.5) as StatBonus
    }
	
	resetStatsFunction(statList: EntityStatList) {
		defaultStatAttribute(statList)
		for (const stat of Object.keys(WEAPON_STATS.cannon.stats)) {
			statList._actualStatValues[stat] = WEAPON_STATS.cannon.stats[stat]
		}
	}

	update(delta: number) {
		const isAutoShoot = this.player.autoShootEnabled && this.player.autoShootToggledOn
		const autoShootDoneAiming = isAutoShoot && this.activeShotCount === 0 && this.player.autoAimNearestEnemy && distanceSquaredVV(this.player.autoAimNearestEnemy.position, this.aimGroundPosVector) <= AUTO_AIM_TARGET_ENEMY_DIST2

		if (this.player.canShoot() && !this.isAiming && this.player.ammoCount > 0 && (!isAutoShoot || this.activeShotCount === 0)) {
			this.isAiming = true
			this.isAimingUp = true

			// this.lockedAimAngle = this.player.aimAngle

			// this.player.aimLockedUntilTime = InGameTime.highResolutionTimestamp() + 100

			if (this.repulsionBlasts) {
				this.repulsionBlasts.addToObject(this.player, this.player.aimAngle)
			}

			this.showAimGraphics()
		} else if (this.isAiming && ((!ClientPlayerInput.currentFrameInput.get(InputAction.SHOOT) && !isAutoShoot) || autoShootDoneAiming) && !this.player.isDead() && !this.player.noFireWeapons) {
			this.isAiming = false
			this.isAimingUp = false

			if (this.repulsionBlasts) {
				this.repulsionBlasts.removeFromObject()
			}

			this.hideAimGraphics()
			this.fire()
			this.aimDistancePercent = 0
		} else if (this.isAiming && (this.player.isDead() || this.player.noFireWeapons)) {
			this.isAiming = false
			this.isAimingUp = false
			this.aimDistancePercent = 0

			if (this.repulsionBlasts) {
				this.repulsionBlasts.removeFromObject()
			}

			this.hideAimGraphics()
            if (this.aimGroundGfx) {
                this.removeAimGroundGfx(this.aimGroundGfx)
            }
		}

		if (this.isAiming) {
			const igtScale = InGameTime.timeScale
			if (this.isAimingUp) {
				this.aimDistancePercent += (delta * AIM_SPEED_SCALAR) / igtScale
				if (this.aimDistancePercent >= 1) {
					this.aimDistancePercent = 1
					this.isAimingUp = false
				}
			} else {
				this.aimDistancePercent -= (delta * AIM_SPEED_SCALAR) / igtScale
				if (this.aimDistancePercent <= 0) {
					this.aimDistancePercent = 0
					this.isAimingUp = true
				}
			}

			this.updateAimGraphics()
		}
	}

	addRepulsionBlasts() {
		this.repulsionBlasts = new RepulsionBlasts()

		if (this.isAiming) {
			this.repulsionBlasts.addToObject(this.player, this.player.aimAngle)
		}
	}

	removeRepulsionBlasts() {
		this.repulsionBlasts.removeFromObject()
		this.repulsionBlasts = null
	}

    addDragonsBreath() { 
        this.hasMiasma = true
        this.miasmaProjectileParams = {
            owningEntityId: GameState.player.nid,
            position: new Vector(),
            speed: this.statList.getStat(StatType.projectileSpeed),
            aimAngleInRads: 0,
            radius: this.statList.getStat(StatType.attackSize),
            trajectoryMods: [],
            collisionLayer: CollisionLayerBits.PlayerGroundHazard, // Still hits enemies and affected by temporal distortion but no collision with props
            statList: this.statList,
            damageScale: MIASMA_DAMAGE_SCALE,
            resourceType: ResourceType.NONE,
            player: this.player,
            weaponType: this.weaponType,
            effectType: ParticleEffectType.CANNON_MIASMA_PFX,
            trailType: ParticleEffectType.PROJECTILE_NONE,
            isSplit: true, // prevent cloud from splitting
            noChaining: true
        }
    }

    removeDragonsBreath() {
        this.hasMiasma = false
        this.miasmaProjectileParams = null
    }

    addRainingAgony() {
        this.hasRainingAgony = true
        this.reuseCannonShotParams.emitFallingBits = true
        if(!FallingBits.pool) {
            FallingBits.pool = new ObjectPoolTyped<FallingBits, FallingBitParams>(() => new FallingBits(), {}, 10, 1)
        }
        makeBeamPool()
        this.rainingAgonyBeamParams = {
            x: this.player.position.x,
            y: this.player.position.y,
            angle: 0,
            width: 0,
            length: 0,
            maxLength: 0,
            noGfx: true,
            noCollisions: true,
            //noDoubleStrike: true,
            statList: this.rainingAgonyStatList,
            weaponType: this.weaponType
        }
    }

	setHasFallingBomb(hasFalling: boolean) {
		if (this.hasFallingBomb && !hasFalling) {
			this.hasFallingBomb = false

			if (this.isAiming) {
				Renderer.getInstance().fgRenderer.removeFromScene(this.forwardLineGfx)
			}
		} else if (!this.hasFallingBomb && hasFalling) {
			this.hasFallingBomb = true

			if (this.isAiming) {
				Renderer.getInstance().fgRenderer.addPropToScene(this.forwardLineGfx)
			}
		}
	}

	setHasBombWall(hasWall: boolean) {
		if (this.hasBombWall && !hasWall) {
			this.hasBombWall = false

			if (this.isAiming) {
				Renderer.getInstance().fgRenderer.removeFromScene(this.upWallGfx)
				Renderer.getInstance().fgRenderer.removeFromScene(this.downWallGfx)
			}
		} else if (!this.hasBombWall && hasWall) {
			this.hasBombWall = true

			this.upWallGfx = Cannon.aimLineGfxPool.alloc() as InstancedSprite
			this.downWallGfx = Cannon.aimLineGfxPool.alloc() as InstancedSprite

			if (this.isAiming) {
				Renderer.getInstance().fgRenderer.addPropToScene(this.upWallGfx)
				Renderer.getInstance().fgRenderer.addPropToScene(this.downWallGfx)
			}
		}
	}

    setCanonShotPFX(effectName: CannonShotPfxNames) {
        this.cannonShotPFX = effectName
    }

    removeRainingAgony() {
        this.hasRainingAgony = false
        this.reuseCannonShotParams.emitFallingBits = false
		this.rainingAgonyBeamParams = null
    }

    setHangtimeJuggle(hasJuggle: boolean) {
        this.hasHangtimeJuggle = hasJuggle
        this.setCanonShotPFX('atomic-blast-projectile-01')
        ProjectileEffectManager.createEffectPoolFromConfig(this.juggleNukeEffectConfig, 2)
    }

	setDoubleTap(hasDoubleTap: boolean) {
		this.hasDoubleTap = hasDoubleTap
		ProjectileEffectManager.createEffectPoolFromConfig(this.doubleTapLargeEffectConfig, 3)
	}

	setExplosionConfig(effectName: 'parasitic-egg-explosion' | 'goopy-explosion' ) {
		this.clusterBombExplosionConfig = AssetManager.getInstance().getAssetByName(effectName).data
	}

	private showAimGraphics() {
		this.aimArrowOffsetVector.x = AIM_ARROW_GFX_X_OFFSET * this.player.facingDirection
		this.aimArrowOffsetVector.y = AIM_ARROW_GFX_Y_OFFSET
		this.aimArrowOffsetVector.rotate(this.cannonWeaponAngle)

		this.aimArrowGfx.x = this.player.x + this.aimArrowOffsetVector.x
		this.aimArrowGfx.y = this.player.y + this.aimArrowOffsetVector.y

		this.aimGroundDirectionVector.x = 0
		this.aimGroundDirectionVector.y = 0
		this.aimGroundDirectionVector.rotate(this.player.aimAngle)

		this.aimGroundPosVector.copy(this.player.position)
		this.aimGroundPosVector.add(this.aimGroundDirectionVector)

		Renderer.getInstance().fgRenderer.addPropToScene(this.aimArrowGfx)
		Renderer.getInstance().fgRenderer.addPropToScene(this.aimGroundGfx)

		if (this.hasFallingBomb) {
			this.forwardLineGfx.rot = this.player.aimAngle

			this.aimGroundDirectionVector.x = this.forwardLineGfx.width + AIM_LINE_GFX_OFFSET
			this.aimGroundDirectionVector.y = 0
			this.aimGroundDirectionVector.rotate(this.player.aimAngle)

			this.forwardLineGfx.x = this.aimGroundGfx.x + this.aimGroundDirectionVector.x
			this.forwardLineGfx.y = this.aimGroundGfx.y + this.aimGroundDirectionVector.y

			Renderer.getInstance().fgRenderer.addPropToScene(this.forwardLineGfx)
		}

		if (this.hasBombWall) {
			this.upWallGfx.rot = this.player.aimAngle - (Math.PI/2)
			this.downWallGfx.rot = this.player.aimAngle + (Math.PI/2)

			this.aimGroundDirectionVector.x = 0
			this.aimGroundDirectionVector.y = this.forwardLineGfx.width + AIM_LINE_GFX_OFFSET
			this.aimGroundDirectionVector.rotate(this.player.aimAngle)

			this.upWallGfx.x = this.aimGroundGfx.x + this.aimGroundDirectionVector.x
			this.upWallGfx.y = this.aimGroundGfx.y + this.aimGroundDirectionVector.y

			this.downWallGfx.x = this.aimGroundGfx.x + this.aimGroundDirectionVector.x
			this.downWallGfx.y = this.aimGroundGfx.y - this.aimGroundDirectionVector.y

			Renderer.getInstance().fgRenderer.addPropToScene(this.upWallGfx)
			Renderer.getInstance().fgRenderer.addPropToScene(this.downWallGfx)
		}
	}

	private hideAimGraphics() {
        Renderer.getInstance().fgRenderer.removeFromScene(this.aimArrowGfx)
    }

    private removeAimGroundGfx(sprite: InstancedSprite) {
        Renderer.getInstance().fgRenderer.removeFromScene(sprite)
        Cannon.aimGroundGfxPool.free(sprite as any)
    }

	private updateAimGraphics() {
		this.aimGroundDirectionVector.x = Math.lerp(MIN_SHOT_DISTANCE, MAX_SHOT_DISTANCE, this.aimDistancePercent)
		this.aimGroundDirectionVector.y = 0
		this.aimGroundDirectionVector.rotate(this.player.aimAngle)

		this.aimGroundPosVector.copy(this.player.position)
		this.aimGroundPosVector.add(this.aimGroundDirectionVector)

		this.aimGroundGfx.x = this.aimGroundPosVector.x
		this.aimGroundGfx.y = this.aimGroundPosVector.y

		this.reuseAngleVector.x = this.aimGroundDirectionVector.x
		this.reuseAngleVector.y = 0
		this.reuseAngleVector.rotate(this.player.aimAngle)

		const xOff = Math.abs(this.reuseAngleVector.x / MAX_SHOT_DISTANCE)
		let angleToTarget = -angleBetweenVectors(this.player.position, this.aimGroundPosVector)
		while (angleToTarget < 0) {
			angleToTarget += Math.PI * 2
		}

		this.cannonWeaponAngle = Math.lerp(MIN_CANNON_WEAPON_ANGLE, MAX_CANNON_WEAPON_ANGLE, xOff) * this.player.facingDirection

		if (this.player.facingDirection === -1) {
			this.player.weapon.rotation = this.cannonWeaponAngle - CANNON_ANGLE_OFFSET
		} else {
			this.player.weapon.rotation = (this.cannonWeaponAngle + CANNON_ANGLE_OFFSET) * -1
		}

		this.aimArrowOffsetVector.x = AIM_ARROW_GFX_X_OFFSET * this.player.facingDirection
		this.aimArrowOffsetVector.y = AIM_ARROW_GFX_Y_OFFSET
		this.aimArrowOffsetVector.rotate(this.cannonWeaponAngle)

		this.aimArrowGfx.rot = this.cannonWeaponAngle
		this.aimArrowGfx.x = this.player.x + this.aimArrowOffsetVector.x
		this.aimArrowGfx.y = this.player.y + this.aimArrowOffsetVector.y

		if (this.hasFallingBomb) {
			this.forwardLineGfx.rot = this.player.aimAngle

			this.aimGroundDirectionVector.x = this.forwardLineGfx.width + AIM_LINE_GFX_OFFSET
			this.aimGroundDirectionVector.y = 0
			this.aimGroundDirectionVector.rotate(this.player.aimAngle)

			this.forwardLineGfx.x = this.aimGroundGfx.x + this.aimGroundDirectionVector.x
			this.forwardLineGfx.y = this.aimGroundGfx.y + this.aimGroundDirectionVector.y
		}

		if (this.hasBombWall) {
			this.upWallGfx.rot = this.player.aimAngle + (Math.PI/2)
			this.downWallGfx.rot = this.player.aimAngle - (Math.PI/2)

			this.aimGroundDirectionVector.x = 0
			this.aimGroundDirectionVector.y = this.forwardLineGfx.width + AIM_LINE_GFX_OFFSET
			this.aimGroundDirectionVector.rotate(this.player.aimAngle)

			this.upWallGfx.x = this.aimGroundGfx.x + this.aimGroundDirectionVector.x
			this.upWallGfx.y = this.aimGroundGfx.y + this.aimGroundDirectionVector.y

			this.downWallGfx.x = this.aimGroundGfx.x - this.aimGroundDirectionVector.x
			this.downWallGfx.y = this.aimGroundGfx.y - this.aimGroundDirectionVector.y
		}
	}

	private fire() {
		const shot = this.fireProjectileAtPosition(this.aimGroundPosVector)
		// Pass the ownership of this target reticle instance off to the shot 
		shot.aimGroundGfx =  this.aimGroundGfx
		// Allocate a fresh reticle to be updated with the next aim state
		this.aimGroundGfx = Cannon.aimGroundGfxPool.alloc() as InstancedSprite
		if (this.hasRainingAgony) {
			const cb = this.fireRainingAgony.bind(this, shot)
			callbacks_addCallback(this, cb, RAINING_AGONY_DELAY_TIME)
		}
		this.player.onShot(true, 1)
		this.player.currentAttackCooldown = 1.0 / this.statList.getStat(StatType.attackRate)

		if (this.hasParasiticCluster) {
			this.fireMiniBombs()

			if (this.hasFallingBomb) {
				shot.aimLineGfx.push(this.forwardLineGfx)
				this.forwardLineGfx = Cannon.aimLineGfxPool.alloc() as InstancedSprite
			}

			if (this.hasBombWall) {
				shot.aimLineGfx.push(this.upWallGfx)
				shot.aimLineGfx.push(this.downWallGfx)
				this.upWallGfx = Cannon.aimLineGfxPool.alloc() as InstancedSprite
				this.downWallGfx = Cannon.aimLineGfxPool.alloc() as InstancedSprite
			}
		}

        if (this.hasHangtimeJuggle) {
            shot.hangTimeJuggleCount = 0
        }
    }

	private fireProjectileAtPosition(position: Vector, secondaryProjectile?: boolean): CannonShot {
		this.reuseCannonShotParams.originPos.x = this.aimArrowGfx.x
		this.reuseCannonShotParams.originPos.y = this.aimArrowGfx.y

		this.setShotVelocity(this.aimArrowGfx, position, Math.PI / 2 - this.cannonWeaponAngle)

		this.reuseCannonShotParams.yKillPlane = position.y
		this.reuseCannonShotParams.overridePfx = this.cannonShotPFX
		this.reuseCannonShotParams.pfxRotToVelocity = false

		this.player.aimLockedUntilTime = 0
		const shot = CannonShot.pool.alloc(this.reuseCannonShotParams)

		shot.timeScale = this.shotTimeScale

		if (this.hasHangtimeJuggle) {
			shot.hangTimeJuggleCount = 0
		}

		if (this.hasParasiticCluster && secondaryProjectile) {
			shot.isMiniBomb = true
		} else {
			this.activeShotCount++
		}

		if (this.hasBombWall && !secondaryProjectile) {
			shot.activateBombWall = true
			shot.originalFireAngle = this.player.aimAngle
		}

		if (this.hasMiasma && !secondaryProjectile) {
			shot.originalFireAngle = this.player.aimAngle
		}


		return shot
	}

	private setShotVelocity(originPos: VectorXY, targetPos: Vector, angle: radians) {
		const xOff = targetPos.x - originPos.x
		const yOff = targetPos.y - originPos.y

		while (angle < 0) {
			angle += Math.PI * 2
		}

		// if we are shooting left, change it as if we are shooting right so the math works
		if (angle > Math.PI / 2) {
			angle = Math.PI / 2 - (angle - Math.PI / 2)
		}

		const totalForce = getProjectileVelocity(angle, Math.abs(xOff), CANNON_SHOT_GRAVITY_RATE, yOff)

		if (isNaN(totalForce) || totalForce === Infinity || totalForce >= ABSURD_SHOT_FORCE_CUTOFF) {
			this.reuseCannonShotParams.targetPos = targetPos

			const distance2 = distanceVV(targetPos, originPos)
			const totalTime = distance2 / SHOT_LERPED_SPEED

			this.reuseCannonShotParams.targetLerpTime = totalTime
		} else {
			this.reuseCannonShotParams.targetPos = null

			this.reuseCannonShotParams.velocity.x = totalForce
			this.reuseCannonShotParams.velocity.y = 0
			// negative angle because pixi is backwards from the real world (down is up)
			this.reuseCannonShotParams.velocity.rotate(-angle)

			this.reuseCannonShotParams.velocity.scale(Math.sign(xOff), 1)
		}
	}

    private onShotHitTarget(shot: CannonShot) {
        if(shot.aimGroundGfx) {
            this.removeAimGroundGfx(shot.aimGroundGfx)
			shot.aimGroundGfx = null
        }

		if (shot.aimLineGfx) {
			for (let i = 0; i < shot.aimLineGfx.length; ++i) {
				Renderer.getInstance().fgRenderer.removeFromScene(shot.aimLineGfx[i])
				Cannon.aimLineGfxPool.free(shot.aimLineGfx[i] as any)
			}
			shot.aimLineGfx.length = 0
		}

		if (!shot.isMiniBomb) {
			this.activeShotCount--
		}
        
		const radius = this.statList.getStat(StatType.attackSize)

		if (this.hasMiasma) {
            this.fireMiasma(shot)
        }

		if (shot.rainingAgonyBeam && shot.rainingAgonyBeam.isColliderInScene) {
			shot.rainingAgonyBeam.removeColliderFromScene()
			Beam.pool.free(shot.rainingAgonyBeam)
			shot.rainingAgonyBeam = null
		}
		
		if (this.hasDoubleTap) {
			if (shot.isDoubleTapShot) {
				// small explosion
				dealAOEDamageDamageSource(CollisionLayerBits.PlayerProjectile, radius * DOUBLE_TAP_SECOND_EXPLOSION_SIZE_PENALTY, shot.position, this, DOUBLE_TAP_SECOND_EXPLOSION_DAMAGE_SCALE, true, undefined, true)
				dealAOEDamageDamageSource(CollisionLayerBits.HitPlayerOnly, radius * DOUBLE_TAP_SECOND_EXPLOSION_SIZE_PENALTY * PLAYER_DAMAGING_EXPLOSION_RADIUS_SCALE, shot.position, this, DOUBLE_TAP_SECOND_EXPLOSION_DAMAGE_SCALE, true, undefined, false)
				this.tryVacuumPickupsInZone(shot.position, radius * DOUBLE_TAP_SECOND_EXPLOSION_SIZE_PENALTY)
			} else {
				dealAOEDamageDamageSource(CollisionLayerBits.PlayerProjectile, radius, shot.position, this, 1, true, undefined, false)
				dealAOEDamageDamageSource(CollisionLayerBits.HitPlayerOnly, radius * PLAYER_DAMAGING_EXPLOSION_RADIUS_SCALE, shot.position, this, 1, true, undefined, false)
				this.tryVacuumPickupsInZone(shot.position, radius)

				const effect = Renderer.getInstance().addOneOffEffectByConfig(this.doubleTapLargeEffectConfig, shot.position.x, shot.position.y, shot.position.y + EXPLOSION_Z_OFFSET, radius / EXPLOSION_GFX_DEFAULT_SIZE, 1 /*duration*/, true)
				effect.timeScale = 1.5

				// make a new double-tap shot
				this.reuseCannonShotParams.targetPos = null
				this.reuseCannonShotParams.originPos.copy(shot.position)
				this.reuseCannonShotParams.yKillPlane = shot.position.y
				this.reuseCannonShotParams.velocity.x = 0
				this.reuseCannonShotParams.velocity.y = DOUBLE_TAP_SECOND_SHOT_Y_FORCE

				const newShot = CannonShot.pool.alloc(this.reuseCannonShotParams)
				newShot.timeScale = this.shotTimeScale
				newShot.isDoubleTapShot = true
			}
		} else if (this.hasParasiticCluster) {
			let effect
			if (shot.isMiniBomb){
				dealAOEDamageDamageSource(CollisionLayerBits.PlayerProjectile, radius / 2, shot.position, this, PARASITIC_MINI_CANNON_DAMAGE_SCALE, true, undefined, false)
				effect = Renderer.getInstance().addOneOffEffectByConfig(this.clusterBombExplosionConfig, shot.position.x, shot.position.y, shot.position.y + EXPLOSION_Z_OFFSET, PARASITIC_EGG_AOE_SCALE / 2, 1, true)
			} else {
				dealAOEDamageDamageSource(CollisionLayerBits.PlayerProjectile, radius, shot.position, this, PARASITIC_MINI_CANNON_DAMAGE_SCALE, true, undefined, false)				
				dealAOEDamageDamageSource(CollisionLayerBits.HitPlayerOnly, radius * PLAYER_DAMAGING_EXPLOSION_RADIUS_SCALE, shot.position, this, PARASITIC_MINI_CANNON_DAMAGE_SCALE, true, undefined, false)				
				effect = Renderer.getInstance().addOneOffEffectByConfig(this.clusterBombExplosionConfig, shot.position.x, shot.position.y, shot.position.y + EXPLOSION_Z_OFFSET, PARASITIC_EGG_AOE_SCALE, 1, true)
			}	
			
			if (!this.hasGoopyExplosion) {
				effect.timeScale = 0.60
			}
		} else {
			if (this.hasHangtimeJuggle) {
				const distToPlayer = distanceSquaredVV(shot.position, this.player.position)
				if (distToPlayer < JUGGLE_CATCH_DIST2) {
					// caught
					if (shot.hangTimeJuggleCount === JUGGLE_MAX_CATCH_COUNT) {
						// big boom
						const sizeBonus = MAX_JUGGLE_SIZE_BONUS
						const damageBonus = MAX_JUGGLE_DAMAGE_BONUS

						Buff.apply(BuffIdentifier.Invulnerable, this.player, this.player, 1, MAX_JUGGLE_INVULN_TIME)

						if (this.juggleIgniteBonus) {
							this.juggleIgniteBonus.update(JUGGLE_IGNITE_CHANCE_BONUS)
						} else {
							this.juggleIgniteBonus = this.statList.addStatBonus(StatType.igniteChance, StatOperator.SUM, JUGGLE_IGNITE_CHANCE_BONUS) as StatBonus
						}

						dealAOEDamageDamageSource(CollisionLayerBits.PlayerProjectile, radius * sizeBonus, shot.position, this, damageBonus, true, undefined, false)
						this.tryVacuumPickupsInZone(shot.position, radius * sizeBonus)

						this.juggleIgniteBonus.update(0)

						Renderer.getInstance().addOneOffEffectByConfig(this.juggleNukeEffectConfig, shot.position.x, shot.position.y, shot.position.y + EXPLOSION_Z_OFFSET, (radius * sizeBonus) / EXPLOSION_GFX_DEFAULT_SIZE, 1 /*duration*/, true)

						return
					}

					// knockback enemies around you
					this.player.knockbackSurroundingEnemies(JUGGLE_CATCH_KNOCKBACK_RANGE, JUGGLE_CATCH_KNOCKBACK_STRENGTH + (shot.hangTimeJuggleCount * JUGGLE_CATCH_KNOCKBACK_BONUS_PER_CATCH))
					Renderer.getInstance().addOneOffEffectByConfig(this.juggleRepulsionEffectConfig, shot.position.x, shot.position.y, shot.position.y + JUGGLE_REPULSION_Z_OFFSET, JUGGLE_CATCH_KNOCKBACK_RANGE / JUGGLE_REPULSION_GFX_DEFAULT_SIZE, 1, true)

					//  make a new shot to catch with count+1

					this.reuseCannonShotParams.targetPos = null
					this.reuseCannonShotParams.originPos.copy(shot.position)

					getRandomPointInRectRange(shot.position.x, shot.position.y, JUGGLE_NEW_SHOT_MIN_WIDTH, JUGGLE_NEW_SHOT_MAX_WIDTH, JUGGLE_NEW_SHOT_MIN_HEIGHT, JUGGLE_NEW_SHOT_MAX_HEIGHT, this.juggleReusePosVector)
					let newAngle = 0
					const xDiff = this.juggleReusePosVector.x - shot.position.x
					newAngle = Math.lerp(MIN_CANNON_WEAPON_ANGLE, MAX_CANNON_WEAPON_ANGLE, xDiff / MAX_SHOT_DISTANCE)
					if (this.juggleReusePosVector.x < shot.position.x) {
						newAngle = -newAngle + Math.PI / 2
					} else {
						newAngle = Math.PI / 2 + newAngle
					}

					this.setShotVelocity(shot.position, this.juggleReusePosVector, newAngle)

					this.reuseCannonShotParams.yKillPlane = this.juggleReusePosVector.y
					this.reuseCannonShotParams.overridePfx = JUGGLE_SHOT_PFX_NAMES[shot.hangTimeJuggleCount]
					this.reuseCannonShotParams.pfxRotToVelocity = true
					const newShot = CannonShot.pool.alloc(this.reuseCannonShotParams)
					newShot.hangTimeJuggleCount = shot.hangTimeJuggleCount + 1
					newShot.timeScale = this.shotTimeScale * JUGGLE_NEW_SHOT_TIMESCALE
					newShot.setCatchTarget(this.juggleReusePosVector)
				} else {
					// missed
					const juggleCount = shot.hangTimeJuggleCount

					const sizeBonus = 1 + juggleCount * JUGGLE_CATCH_SIZE_BONUS_PER_CATCH
					const damageBonus = 1 + juggleCount * JUGGLE_CATCH_ATTACK_BONUS_PER_CATCH

					dealAOEDamageDamageSource(CollisionLayerBits.PlayerProjectile, radius * sizeBonus, shot.position, this, damageBonus, true, undefined, true)
					dealAOEDamageDamageSource(CollisionLayerBits.HitPlayerOnly, radius * sizeBonus * PLAYER_DAMAGING_EXPLOSION_RADIUS_SCALE, shot.position, this, 1, true, undefined, false)
					this.tryVacuumPickupsInZone(shot.position, radius * sizeBonus)
				}
			} else {
				// default functionality
				dealAOEDamageDamageSource(CollisionLayerBits.PlayerProjectile, radius, shot.position, this, 1, true, undefined, true)
				dealAOEDamageDamageSource(CollisionLayerBits.HitPlayerOnly, radius * PLAYER_DAMAGING_EXPLOSION_RADIUS_SCALE, shot.position, this, 1, true, undefined, false)
				this.tryVacuumPickupsInZone(shot.position, radius)

				if (this.hasIrradiationZone) {
					// leave behind a zone that causes ignite
					this.irradiationZoneCreationParams.applyStacks = getIgniteStacks(this.statList.getStat(StatType.baseDamage) * this.statList.getStat(StatType.allDamageMult)) * this.statList.getStat(StatType.ignitePotency)
					this.irradiationZoneCreationParams.triggerRadius = radius * IRRADIATION_ZONE_BONUS_SIZE
					this.irradiationZoneCreationParams.lifeTime = IRRADIATION_ZONE_DURATION * this.statList.getStat(StatType.skillDuration)
					this.irradiationZoneCreationParams.position.x = shot.position.x
					this.irradiationZoneCreationParams.position.y = shot.position.y
					BigIgnitePoolHazard.pool.alloc(this.irradiationZoneCreationParams)
				}
			}
		}

		if (shot.activateBombWall) {
			const subBombCount = this.statList.getStat(StatType.projectileCount) - 1
			this.makeBombWallBomb(subBombCount, shot.originalFireAngle + Math.PI/2, shot.position)
			this.makeBombWallBomb(subBombCount, shot.originalFireAngle - Math.PI/2, shot.position)
		} else if (shot.subExplosionCount) {
			this.makeBombWallBomb(shot.subExplosionCount - 1, shot.originalFireAngle, shot.position)
		}

		if (this.hasWorms && shot.isMiniBomb){
			const wormProjectiles = Math.ceil(this.statList.getStat(StatType.projectileCount) / 2)
			this.projectileCreationParams.position = shot.position
	
			for (let i =0; i < wormProjectiles; ++i) {
				const randomAngle = Math.random() * Math.PI * 2;
				this.projectileCreationParams.aimAngleInRads = randomAngle
				PlayerProjectile.objectPool.alloc(this.projectileCreationParams)
			}
		}
	}

	isPlayerOwned(): boolean {
		return true
	}

	getKnockbackDirection(mutableEntityPos: Vector): Vector {
		mutableEntityPos.x = 0
		mutableEntityPos.y = 0
		return mutableEntityPos // @TODO knockback
	}

	setHasIrradiationZone(hasZone: boolean) {
		if (hasZone) {
			if(!BigIgnitePoolHazard.pool) {
				BigIgnitePoolHazard.pool = new ObjectPool(() => new BigIgnitePoolHazard(), {}, 5, 1, 'big-ignite-pool-hazard')
				this.irradiationZoneCreationParams = {
					applyStacks: 0, // set just before allocing
					statList: null,//this.statList, // todo set this
					damageTargetType: DamageableEntityType.Enemy,
					lifeTime: IRRADIATION_ZONE_DURATION,
					triggerRadius: 0, // set just before allocing
					position: new Vector()
				}
			}
		}
		
		this.hasIrradiationZone = hasZone
	}

	private tryVacuumPickupsInZone(origin: VectorXY, radius: number) {
		if (this.hasExplosiveVacuum) {
			const pickups = CollisionSystem.getInstance().getEntitiesInArea(origin, radius, CollisionLayerBits.PlayerPickup)
			for (let i = 0; i < pickups.length; ++i) {
				const pickup = pickups[i].owner as GroundPickup

				this.explosiveVacuumReuseVector.x = origin.x - pickup.position.x
				this.explosiveVacuumReuseVector.y = origin.y - pickup.position.y

				pickup.velocity.x = this.explosiveVacuumReuseVector.x * PICKUP_VACUUM_FORCE_SCALE
				pickup.velocity.y = this.explosiveVacuumReuseVector.y * PICKUP_VACUUM_FORCE_SCALE
			}
		}
	}

	private fireMiniBombs() {
		const projectileCount = this.statList.getStat(StatType.projectileCount) - 1
		if(this.hasRandomBomb) {
			this.miniBombRandomPattern(projectileCount)
		} else if(this.hasFallingBomb) {
			this.fireFallPattern(projectileCount)
		}
	}

	private miniBombRandomPattern(miniBombCount: number) {
		for (let miniBombIndex = 0; miniBombIndex < miniBombCount; miniBombIndex++) {
			const mainCannonAOE = this.statList.getStat(StatType.attackSize)
			const distanceFromCenter = RANDOM_CLUSTER_FIXED_DISTANCE + mainCannonAOE * RANDOM_CLUSTER_DISTANCE_MULTIPLIER

			const randomAngle = Math.random() * 2 * Math.PI

			this.miniBombVector.x = this.aimGroundPosVector.x + distanceFromCenter * Math.cos(randomAngle)
			this.miniBombVector.y = this.aimGroundPosVector.y + distanceFromCenter * Math.sin(randomAngle)

			this.fireProjectileAtPosition(this.miniBombVector, true)
		}
	}

	private makeBombWallBomb(miniBombCount: number, angle: radians, originPos: Vector) {
		// get our final destination
		this.reuseAngleVector.x = BOMB_WALL_MINI_BOMB_OFFSET
		this.reuseAngleVector.y = 0
		this.reuseAngleVector.rotate(angle)
		this.reuseAngleVector.add(originPos)

		this.reuseCannonShotParams.originPos.x = originPos.x
		this.reuseCannonShotParams.originPos.y = originPos.y

		this.reuseCannonShotParams.targetPos = this.reuseAngleVector
		this.reuseCannonShotParams.targetLerpTime = BOMB_WALL_MINI_BOMB_SHOT_DELAY

		this.reuseCannonShotParams.hideGfx = false

		const shot = CannonShot.pool.alloc(this.reuseCannonShotParams)

		this.reuseCannonShotParams.targetPos = undefined

		// this.reuseCannonShotParams.hideGfx = false

		shot.isMiniBomb = true
		shot.isBombWallBomb = true
		shot.subExplosionCount = miniBombCount
		shot.originalFireAngle = angle
	}

	private fireFallPattern(miniBombCount: number) {
		const mainCannonAOE = this.statList.getStat(StatType.attackSize)
		const miniCannonAOE = mainCannonAOE / 2

		this.distanceVector = this.distanceVector.copy(this.aimGroundPosVector).sub(this.player.position)
		const distanceToAim = this.distanceVector.len()

		const dirNormal = this.distanceVector.normalize()

		let bombsBeforeTarget = Math.floor((distanceToAim - mainCannonAOE) / miniCannonAOE)
		bombsBeforeTarget = Math.max(0, Math.min(bombsBeforeTarget, miniBombCount))

		const bombsAfterTarget = miniBombCount - bombsBeforeTarget

		for (let i = 1; i <= bombsBeforeTarget; i++) {
			this.miniBombVector.x = this.player.x + dirNormal.x * (miniCannonAOE * i)
			this.miniBombVector.y = this.player.y + dirNormal.y * (miniCannonAOE * i)
			this.fireProjectileAtPosition(this.miniBombVector, true)
		}

		for (let j = 1; j <= bombsAfterTarget; j++) {
			this.miniBombVector.x = this.aimGroundPosVector.x + dirNormal.x * (mainCannonAOE + miniCannonAOE * j - miniCannonAOE)
			this.miniBombVector.y = this.aimGroundPosVector.y + dirNormal.y* (mainCannonAOE + miniCannonAOE * j - miniCannonAOE)
			this.fireProjectileAtPosition(this.miniBombVector, true)
		}
	}

    private fireMiasma(shot: CannonShot) {
		this.miasmaProjectileParams.speed = this.statList.getStat(StatType.projectileSpeed) * 0.25
		this.miasmaProjectileParams.radius = this.statList.getStat(StatType.attackSize)
		this.miasmaProjectileParams.position.copy(shot.position)
		this.miasmaProjectileParams.aimAngleInRads = shot.originalFireAngle
		const miasmaCloud = PlayerProjectile.objectPool.alloc(this.miasmaProjectileParams)

		miasmaCloud.maxPierceCount = Number.MAX_SAFE_INTEGER
		miasmaCloud.lifespan = MIASMA_LIFESPAN
    }

    private fireRainingAgony(shot: CannonShot) {
		const length = distanceVV(this.player.position, this.aimGroundPosVector)
		const width = this.statList.getStat(StatType.attackSize) * RAINING_AGONY_BEAM_WIDTH_SCALE
		const yOffset = this.player.scale * (- width / 2)
		this.rainingAgonyReuseOffset.copy(RAINING_AGONY_BEAM_ROTATED_OFFSET)
		this.rainingAgonyReuseOffset.rotate(this.player.aimAngle)
		
		shot.rainingAgonyBeam = Beam.pool.alloc(this.rainingAgonyBeamParams)
		shot.rainingAgonyBeam.setColliderProperties(length, width, this.player.aimAngle)

		shot.rainingAgonyBeam.position.copy(this.player.position)
		shot.rainingAgonyBeam.position.add(this.rainingAgonyReuseOffset)
		shot.rainingAgonyBeam.position.y += yOffset

		shot.rainingAgonyBeam.addColliderToScene()
    }

    private _resetRainingAgonyStats(statList: EntityStatList) {
        defaultStatAttribute(statList)

        statList._actualStatValues.baseDamage = 0
        statList._actualStatValues.attackPierceCount = Number.MAX_SAFE_INTEGER
    }
}
