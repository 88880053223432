import { Vector } from "sat"
import { BuffIdentifier } from "../../buffs/buff.shared"
import { VectorXY } from "../../utils/math"
import { radians, timeInSeconds } from "../../utils/primitive-types"
import { ENEMY_NAME } from "./enemy-names"
import { Directions } from "./enemy-spawn-config"
import WeightedList from "../../utils/weighted-list"
import { GroundPickupConfigType } from "../pickups/ground-pickup-types"

const PET_CHOREO_REWARD_WEIGHTED_LIST = new WeightedList([
  [GroundPickupConfigType.HealingSmall, 15],
  [GroundPickupConfigType.RareCurrency, 30],
  [GroundPickupConfigType.CommonCurrencyMedium, 55], // Appears as a stack of 5 CommonCurrencySmall
])

// Just some example choreo pack enemyReplacement bundles.
// Feel free to define more here if you want to reuse them, or directly in POSITIVE_PET_CHOREO_EVENTS if they are unique

const PET_CHOREO_EQUAL_OPPORTUNITY_BUNDLE = new WeightedList([
  [ENEMY_NAME.CHOREO_PET_CAT, 1],
  [ENEMY_NAME.CHOREO_PET_HORSE, 1],
  [ENEMY_NAME.CHOREO_PET_PLANT, 1],
  [ENEMY_NAME.CHOREO_PET_ROT_SON, 1],
  [ENEMY_NAME.CHOREO_PET_CRYSTAL, 1],
])

const PET_CHOREO_MAMMAL_BUNDLE = new WeightedList([
  [ENEMY_NAME.CHOREO_PET_CAT, 75],
  [ENEMY_NAME.CHOREO_PET_HORSE, 25],
])

const PET_CHOREO_GREEN_THUMB_BUNDLE = new WeightedList([
  [ENEMY_NAME.CHOREO_PET_PLANT, 80],
  [ENEMY_NAME.CHOREO_PET_ROT_SON, 20],
])

const PET_CHOREO_WIERD_LITTLE_GUY_BUNDLE = new WeightedList([
  [ENEMY_NAME.CHOREO_PET_ROT_SON, 60],
  [ENEMY_NAME.CHOREO_PET_CRYSTAL, 40],
])

export enum EnemySpawnShape {
    Clump,
    Line,
    Oval,
    Raindrops,
}

interface BaseTimedEnemiesConfig {
    spawnTime: timeInSeconds
    enemy: ENEMY_NAME
    count: number
    shape: EnemySpawnShape
    buffs?: BuffIdentifier[]
    enemyHPScale?: number
    randomReplacements?: WeightedList<ENEMY_NAME>
    randomRewards?: WeightedList<GroundPickupConfigType>
}

export interface LineEnemiesConfig extends BaseTimedEnemiesConfig {
    shape: EnemySpawnShape.Line,
    direction: Directions,
    distanceBetweenUnits: number
    movementDirectionOverride?: VectorXY
    distanceFromPlayer?: number
    displacementFromPlayer?: number
}

export interface OvalEnemiesConfig extends BaseTimedEnemiesConfig {
    shape: EnemySpawnShape.Oval
    xRadius: number
    yRadius: number
    displacementX?: number
    displacementY?: number

    arcAngleStart?: radians
    arcAngleEnd?: radians
}

export interface ClumpEnemiesConfig extends BaseTimedEnemiesConfig {
    shape: EnemySpawnShape.Clump
    direction: Directions
    distanceFromPlayerX?: number
    distanceFromPlayerY?: number
    spawnRectXLength?: number
    spawnRectYLength?: number
    movementDirectionOverride?: VectorXY
    noDeathTime?: boolean
}

export interface RaindropEnemiesConfig extends BaseTimedEnemiesConfig {
    shape: EnemySpawnShape.Raindrops
    direction: Directions
    numberOfLines: number
    horizontalStepSize: number
    verticalStepSize: number
    movementDirectionOverride?: VectorXY
    distanceFromPlayer?: number
    displacementFromPlayer?: number
    chancePerEnemySpot: number
}

export type AnyEnemiesConfig = LineEnemiesConfig | OvalEnemiesConfig | ClumpEnemiesConfig | RaindropEnemiesConfig

export interface ChoreographedEvent {
    eventStartTime: timeInSeconds
    timedEnemies: AnyEnemiesConfig[]
    eventEndTime: timeInSeconds
    warningDirection: Directions
    isPositiveEvent?: boolean
}

export const WARNING_TIME_MS = 1_000

export const CLUMP_DEFAULT_SPAWN_RECT_X = 500
export const CLUMP_DEFAULT_SPAWN_RECT_Y = 500

const LOOSE_ENEMY_VERTICAL_SPACING = 330
const STANDARD_ENEMY_VERTICAL_SPACING = 280
const TIGHT_ENEMY_VERTICAL_SPACING = 240

const LOOSE_ENEMY_HORIZONTAL_SPACING = 280
const STANDARD_ENEMY_HORIZONTAL_SPACING = 220
const TIGHT_ENEMY_HORIZONTAL_SPACING = 180
const BLOCKER_ENEMY_HORIZONTAL_SPACING = 125

const LINE1_ENEMY_VERTICAL_DISTANCE = 980
const LINE2_ENEMY_VERTICAL_DISTANCE = 1340
const LINE3_ENEMY_VERTICAL_DISTANCE = 1700
const LINE4_ENEMY_VERTICAL_DISTANCE = 2060
const LINE5_ENEMY_VERTICAL_DISTANCE = 2420
const LINE6_ENEMY_VERTICAL_DISTANCE = 2780
const LINE7_ENEMY_VERTICAL_DISTANCE = 3140

const LINE1_ENEMY_HORIZONTAL_DISTANCE = 1950
const LINE2_ENEMY_HORIZONTAL_DISTANCE = 2330
const LINE3_ENEMY_HORIZONTAL_DISTANCE = 2710
const LINE4_ENEMY_HORIZONTAL_DISTANCE = 3090
const LINE5_ENEMY_HORIZONTAL_DISTANCE = 3470
const LINE6_ENEMY_HORIZONTAL_DISTANCE = 3850
const LINE7_ENEMY_HORIZONTAL_DISTANCE = 4230


// Q & A:
// Q: How do I make the enemy tankier? 
// A: You can use enemyHPScale parameter in timedEnemies[] to modify HP,
// Or we could use a separate enemy config that is tankier than most enemies,
// Or a new buff could be added

const ACT_1_CHOREO_START_TIME = 15

// act 1
export const SPRING_TIME_DANCING_EVENTS: ChoreographedEvent[] = [
    // Raindrops N
    {
        eventStartTime: 0 + ACT_1_CHOREO_START_TIME,
        eventEndTime: 30 + ACT_1_CHOREO_START_TIME,
        warningDirection: Directions.North,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Raindrops,
                spawnTime: 1 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME_FLYING,
                direction: Directions.North,
                distanceFromPlayer: LINE4_ENEMY_VERTICAL_DISTANCE,
                count: 24,
                numberOfLines: 30,
                horizontalStepSize: 300,
                verticalStepSize: 275,
                chancePerEnemySpot: 0.26,
                enemyHPScale: 10,
                buffs: [BuffIdentifier.Sturdy]
            }
        ],
    },
    // Raindrops E
    {
        eventStartTime: 40 + ACT_1_CHOREO_START_TIME,
        eventEndTime: 40 + 30 + ACT_1_CHOREO_START_TIME,
        warningDirection: Directions.East,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Raindrops,
                spawnTime: 41 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
                direction: Directions.East,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                count: 24,
                numberOfLines: 30,
                horizontalStepSize: 275,
                verticalStepSize: 300,
                chancePerEnemySpot: 0.30,
                enemyHPScale: 10,
                buffs: [BuffIdentifier.Sturdy]
            }
        ],
    },
    // // Raindrops S and W at the same time! AKA pedestrian crossing
    // {
    //     eventStartTime: 80 + ACT_1_CHOREO_START_TIME,
    //     eventEndTime: 80 + 25 + ACT_1_CHOREO_START_TIME,
    //     warningDirection: Directions.South | Directions.West,
    //     timedEnemies: [
    //         {
    //             shape: EnemySpawnShape.Raindrops,
    //             spawnTime: 1 + ACT_1_CHOREO_START_TIME,
    //             enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME_FLYING,
    //             direction: Directions.West,
    //             distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
    //             count: 24,
    //             numberOfLines: 30,
    //             horizontalStepSize: 275,
    //             verticalStepSize: 300,
    //             chancePerEnemySpot: 0.33,
    //             enemyHPScale: 10,
    //             buffs: [BuffIdentifier.Sturdy]
    //         },
    //         {
    //             shape: EnemySpawnShape.Raindrops,
    //             spawnTime: 1 + ACT_1_CHOREO_START_TIME,
    //             enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME_FLYING,
    //             direction: Directions.South,
    //             distanceFromPlayer: LINE4_ENEMY_VERTICAL_DISTANCE,
    //             count: 24,
    //             numberOfLines: 30,
    //             horizontalStepSize: 300,
    //             verticalStepSize: 275,
    //             chancePerEnemySpot: 0.1, // south has less enemies than west
    //             enemyHPScale: 10,
    //             buffs: [BuffIdentifier.Sturdy]
    //         }
    //     ]
    // },

    // Blimpie raindrops (easy but fun)
    {
        eventStartTime: 80 + ACT_1_CHOREO_START_TIME,
        eventEndTime: 80 + 48 + ACT_1_CHOREO_START_TIME,
        warningDirection: Directions.North,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Raindrops,
                spawnTime: 80 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.BLIMPIE,
                direction: Directions.North,
                distanceFromPlayer: LINE4_ENEMY_VERTICAL_DISTANCE,
                count: 24,
                numberOfLines: 20,
                horizontalStepSize: 300,
                verticalStepSize: 275,
                chancePerEnemySpot: 0.25,
                buffs: [BuffIdentifier.Sturdy]
            }
        ],
    },

    // flappy bird
    {
        eventStartTime: 135 + ACT_1_CHOREO_START_TIME,
        eventEndTime: 135 + 45 + ACT_1_CHOREO_START_TIME,
        warningDirection: Directions.East,
        timedEnemies: [
            // gap 1
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 136 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
                direction: Directions.East,
                distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
                displacementFromPlayer: 950*2,
                distanceBetweenUnits: BLOCKER_ENEMY_HORIZONTAL_SPACING,
                count: 20,
                enemyHPScale: 2,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow]
            },
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 136 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
                direction: Directions.East,
                distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
                displacementFromPlayer: -950*2,
                distanceBetweenUnits: BLOCKER_ENEMY_HORIZONTAL_SPACING,
                count: 20,
                enemyHPScale: 2,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow] 
            },
            // gap 2
            // {
            //     shape: EnemySpawnShape.Line,
            //     spawnTime: 136 + ACT_1_CHOREO_START_TIME,
            //     enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
            //     direction: Directions.East,
            //     distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE + 1_000,
            //     displacementFromPlayer: 950*2,
            //     distanceBetweenUnits: BLOCKER_ENEMY_HORIZONTAL_SPACING,
            //     count: 20,
            //     enemyHPScale: 2,
            //     buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow]
            // },
            // {
            //     shape: EnemySpawnShape.Line,
            //     spawnTime: 136 + ACT_1_CHOREO_START_TIME,
            //     enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
            //     direction: Directions.East,
            //     distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE + 1_000,
            //     displacementFromPlayer: -450*2,
            //     distanceBetweenUnits: BLOCKER_ENEMY_HORIZONTAL_SPACING,
            //     count: 20,
            //     enemyHPScale: 2,
            //     buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow]
            // },
            // gap 3
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 136 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
                direction: Directions.East,
                distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE + 2_000,
                displacementFromPlayer: 650*2,
                distanceBetweenUnits: BLOCKER_ENEMY_HORIZONTAL_SPACING,
                count: 20,
                enemyHPScale: 2,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow]
            },
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 136 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
                direction: Directions.East,
                distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE + 2_000,
                displacementFromPlayer: -750*2,
                distanceBetweenUnits: BLOCKER_ENEMY_HORIZONTAL_SPACING,
                count: 20,
                enemyHPScale: 2,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow]
            },
            // gap 4
            // {
            //     shape: EnemySpawnShape.Line,
            //     spawnTime: 136 + ACT_1_CHOREO_START_TIME,
            //     enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
            //     direction: Directions.East,
            //     distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE + 3_000,
            //     displacementFromPlayer: 450*2,
            //     distanceBetweenUnits: BLOCKER_ENEMY_HORIZONTAL_SPACING,
            //     count: 20,
            //     enemyHPScale: 2,
            //     buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow]
            // },
            // {
            //     shape: EnemySpawnShape.Line,
            //     spawnTime: 136 + ACT_1_CHOREO_START_TIME,
            //     enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
            //     direction: Directions.East,
            //     distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE + 3_000,
            //     displacementFromPlayer: -950*2,
            //     distanceBetweenUnits: BLOCKER_ENEMY_HORIZONTAL_SPACING,
            //     count: 20,
            //     enemyHPScale: 2,
            //     buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow]
            // },
            // gap 5
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 136 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
                direction: Directions.East,
                distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE + 4_000,
                displacementFromPlayer: 650*2,
                distanceBetweenUnits: BLOCKER_ENEMY_HORIZONTAL_SPACING,
                count: 20,
                enemyHPScale: 2,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow]
            },
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 136 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
                direction: Directions.East,
                distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE + 4_000,
                displacementFromPlayer: -750*2,
                distanceBetweenUnits: BLOCKER_ENEMY_HORIZONTAL_SPACING,
                count: 20,
                enemyHPScale: 2,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow]
            },
            // last gap (6) -- it's far!
            // {
            //     shape: EnemySpawnShape.Line,
            //     spawnTime: 136 + ACT_1_CHOREO_START_TIME,
            //     enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
            //     direction: Directions.East,
            //     distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE + 5_000,
            //     displacementFromPlayer: 1_050*2,
            //     distanceBetweenUnits: BLOCKER_ENEMY_HORIZONTAL_SPACING,
            //     count: 20,
            //     enemyHPScale: 2,
            //     buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow]
            // },
            // {
            //     shape: EnemySpawnShape.Line,
            //     spawnTime: 136 + ACT_1_CHOREO_START_TIME,
            //     enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
            //     direction: Directions.East,
            //     distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE + 5_000,
            //     displacementFromPlayer: -350*2,
            //     distanceBetweenUnits: BLOCKER_ENEMY_HORIZONTAL_SPACING,
            //     count: 20,
            //     enemyHPScale: 2,
            //     buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow]
            // },
        ],
    },

    // Semi-circle reaction tests
    {
        eventStartTime: 185 + ACT_1_CHOREO_START_TIME,
        eventEndTime: 185 + 7.25 + ACT_1_CHOREO_START_TIME,
        warningDirection: Directions.East | Directions.West,
        timedEnemies: [
            {
                spawnTime: 186 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 20,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_000,
                yRadius: 2_000,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlow],
                enemyHPScale: 999,
                arcAngleStart: 0,
                arcAngleEnd: Math.PI - (Math.PI/2)
            },
            {
                spawnTime: 186 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 20,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_000,
                yRadius: 2_000,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlow],
                enemyHPScale: 999,
                arcAngleStart: Math.PI,
                arcAngleEnd: (Math.PI*2) - (Math.PI/2)
            },
            
        ]
    },
    {
        eventStartTime: 192 + ACT_1_CHOREO_START_TIME,
        eventEndTime: 192 + 7.25 + ACT_1_CHOREO_START_TIME,
        warningDirection: Directions.East | Directions.West,
        timedEnemies: [
            {
                spawnTime: 193 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 35,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_000,
                yRadius: 2_000,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlow],
                enemyHPScale: 999,
                arcAngleStart: Math.PI + Math.PI/2,
                arcAngleEnd: (Math.PI*2) + Math.PI*1.25
            },
        ]
    },
    {
        eventStartTime: 199 + ACT_1_CHOREO_START_TIME,
        eventEndTime: 199 + 7.25 + ACT_1_CHOREO_START_TIME,
        warningDirection: Directions.East | Directions.West,
        timedEnemies: [
            {
                spawnTime: 200 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 35,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_000,
                yRadius: 2_000,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow],
                enemyHPScale: 999,
                arcAngleStart: 0 ,
                arcAngleEnd: (Math.PI*2) - (Math.PI/5)
            },
        ]
    },
    {
        eventStartTime: 206 + ACT_1_CHOREO_START_TIME,
        eventEndTime: 206 + 7.25 + ACT_1_CHOREO_START_TIME,
        warningDirection: Directions.East | Directions.West,
        timedEnemies: [
            {
                spawnTime: 207 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 35,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_000,
                yRadius: 2_000,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow],
                enemyHPScale: 999,
                arcAngleStart: Math.PI*1.75,
                arcAngleEnd: Math.PI*3.75 - (Math.PI * 0.33)
            },
            
        ]
    },
    // fast assault of rings
    {
        eventStartTime: 211 + ACT_1_CHOREO_START_TIME,
        eventEndTime: 211 + 7.25 + ACT_1_CHOREO_START_TIME,
        warningDirection: Directions.East | Directions.West | Directions.North | Directions.South,
        timedEnemies: [
            {
                spawnTime: 212 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 35,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_000,
                yRadius: 2_000,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow],
                enemyHPScale: 999,
                arcAngleStart: 0 + Math.PI/2,
                arcAngleEnd: Math.PI*2 - (Math.PI/4) + Math.PI/2
            },
        ]
    },
    {
        eventStartTime: 216.5 + ACT_1_CHOREO_START_TIME,
        eventEndTime: 216.5 + 7.25 + ACT_1_CHOREO_START_TIME,
        warningDirection: Directions.None,
        timedEnemies: [
            {
                spawnTime: 217.5 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 35,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_000,
                yRadius: 2_000,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow],
                enemyHPScale: 999,
                arcAngleStart: Math.PI*3,
                arcAngleEnd: Math.PI*2 - (Math.PI/4) + Math.PI *3
            },
        ]
    },
    {
        eventStartTime: 222 + ACT_1_CHOREO_START_TIME,
        eventEndTime: 222 + 7.25 + ACT_1_CHOREO_START_TIME,
        warningDirection: Directions.None,
        timedEnemies: [
            {
                spawnTime: 223 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 35,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_000,
                yRadius: 2_000,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow],
                enemyHPScale: 999,
                arcAngleStart: Math.PI*5.75,
                arcAngleEnd: Math.PI*2 - (Math.PI/4) + Math.PI*5.75
            },
        ]
    },
    {
        eventStartTime: 227.5 + ACT_1_CHOREO_START_TIME,
        eventEndTime: 227.5 + 7.25 + ACT_1_CHOREO_START_TIME,
        warningDirection: Directions.None,
        timedEnemies: [
            {
                spawnTime: 228.5 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 35,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_000,
                yRadius: 2_000,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow],
                enemyHPScale: 999,
                arcAngleStart: Math.PI*0.77,
                arcAngleEnd: Math.PI*2 - (Math.PI/4) + Math.PI*0.77
            },
        ]
    },
    {
        eventStartTime: 232 + ACT_1_CHOREO_START_TIME,
        eventEndTime: 232 + 7.25 + ACT_1_CHOREO_START_TIME,
        warningDirection: Directions.None,
        timedEnemies: [
            {
                spawnTime: 233 + ACT_1_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 35,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_000,
                yRadius: 2_000,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSmallSlow],
                enemyHPScale: 999,
                arcAngleStart: Math.PI*11.256,
                arcAngleEnd: Math.PI*2 - (Math.PI/4) + Math.PI*11.256
            },
        ]
    },
]

// act 2
export const SHRIEKER_CHAOS_EVENTS: ChoreographedEvent[] = [
    {
        eventStartTime: 360,
        warningDirection: Directions.North | Directions.South,
        timedEnemies: [
            {
                spawnTime: 362,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 37,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE1_ENEMY_VERTICAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 362,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 37,
                direction: Directions.South,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE3_ENEMY_VERTICAL_DISTANCE,
                displacementFromPlayer: LOOSE_ENEMY_HORIZONTAL_SPACING / 2,
                enemyHPScale: 20,
            },
            {
                spawnTime: 362,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 37,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE5_ENEMY_VERTICAL_DISTANCE,
                enemyHPScale: 20,
            },
        ],
        eventEndTime: 380
    },
    {
        eventStartTime: 390,
        warningDirection: Directions.West | Directions.East,
        timedEnemies: [
            {
                spawnTime: 392,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 25,
                direction: Directions.West,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 392,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 25,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE3_ENEMY_HORIZONTAL_DISTANCE,
                displacementFromPlayer: LOOSE_ENEMY_VERTICAL_SPACING / 2,
                enemyHPScale: 20,
            },
            {
                spawnTime: 392,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 25,
                direction: Directions.West,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE5_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
        ],
        eventEndTime: 410
    },
    {
        eventStartTime: 420,
        warningDirection: Directions.North | Directions.South | Directions.East | Directions.West, // show warning everywhere
        timedEnemies: [
            {
                spawnTime: 422,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 40,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_500,
                yRadius: 2_000,
                buffs: [BuffIdentifier.PermanentSlow, BuffIdentifier.Sturdy],
                enemyHPScale: 8
            }
        ],
        eventEndTime: 438
    },
    {
        eventStartTime: 450,
        warningDirection: Directions.North | Directions.East,
        timedEnemies: [
            {
                spawnTime: 451,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 20,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE1_ENEMY_VERTICAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 451,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 20,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE3_ENEMY_VERTICAL_DISTANCE,
                displacementFromPlayer: LOOSE_ENEMY_HORIZONTAL_SPACING / 2,
                enemyHPScale: 20,
            },
            {
                spawnTime: 451,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 20,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE5_ENEMY_VERTICAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 451.5,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE3_ENEMY_VERTICAL_DISTANCE,
                displacementFromPlayer: LOOSE_ENEMY_VERTICAL_SPACING / 2,
                enemyHPScale: 20,
            },
            {
                spawnTime: 451.5,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE5_ENEMY_VERTICAL_DISTANCE,
                enemyHPScale: 20,
            },
        ],
        eventEndTime: 462
    },
    {
        eventStartTime: 458,
        warningDirection: Directions.West | Directions.South, 
        timedEnemies: [
            {
                spawnTime: 459,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 30,
                direction: Directions.West,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 459,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 30,
                direction: Directions.West,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE3_ENEMY_HORIZONTAL_DISTANCE,
                displacementFromPlayer: LOOSE_ENEMY_VERTICAL_SPACING / 2,
                enemyHPScale: 20,
            },
            {
                spawnTime: 459,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 30,
                direction: Directions.West,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE5_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 459.5,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 30,
                direction: Directions.South,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 459.5,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 30,
                direction: Directions.South,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE5_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
        ],
        eventEndTime: 470
    },
    {
        eventStartTime: 466,
        warningDirection: Directions.South,
        timedEnemies: [
            {
                spawnTime: 467,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 30,
                direction: Directions.South,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE1_ENEMY_VERTICAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 467,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 30,
                direction: Directions.South,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE3_ENEMY_VERTICAL_DISTANCE,
                displacementFromPlayer: LOOSE_ENEMY_HORIZONTAL_SPACING / 2,
                enemyHPScale: 20,
            },
            {
                spawnTime: 467,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 30,
                direction: Directions.South,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE5_ENEMY_VERTICAL_DISTANCE,
                enemyHPScale: 20,
            },
        ],
        eventEndTime: 478
    },
    {
        eventStartTime: 474,
        warningDirection: Directions.East,
        timedEnemies: [
            {
                spawnTime: 475,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 475,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE3_ENEMY_HORIZONTAL_DISTANCE,
                displacementFromPlayer: LOOSE_ENEMY_VERTICAL_SPACING / 2,
                enemyHPScale: 20,
            },
            {
                spawnTime: 475,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE5_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
        ],
        eventEndTime: 486
    },
    {
        eventStartTime: 510,
        warningDirection: Directions.North | Directions.East,
        timedEnemies: [
            {
                spawnTime: 512,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 37,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: STANDARD_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_VERTICAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 512,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 37,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 512,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 37,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: STANDARD_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE7_ENEMY_VERTICAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 512,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 37,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
        ],
        eventEndTime: 530
    },
    {
        eventStartTime: 540,
        warningDirection: Directions.South | Directions.West,
        timedEnemies: [
            {
                spawnTime: 542,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 37,
                direction: Directions.South,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: STANDARD_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_VERTICAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 542,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 37,
                direction: Directions.West,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 542,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 37,
                direction: Directions.South,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: STANDARD_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE7_ENEMY_VERTICAL_DISTANCE,
                enemyHPScale: 20,
            },
            {
                spawnTime: 542,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 37,
                direction: Directions.West,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable],
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
        ],
        eventEndTime: 560
    },
    {
        eventStartTime: 570,
        warningDirection: Directions.East | Directions.West,
        timedEnemies: [
            {
                spawnTime: 565,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 40,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_500,
                yRadius: 2_000,
                buffs: [BuffIdentifier.PermanentSlow, BuffIdentifier.Sturdy], // would be good to add a knockback resist buff as well.
                enemyHPScale: 8
            }
        ],
        eventEndTime: 577
    },
    {
        eventStartTime: 571,
        warningDirection: Directions.North | Directions.South,
        timedEnemies: [
            {
                spawnTime: 565,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 50, // +10 per ring
                shape: EnemySpawnShape.Oval,
                xRadius: 3_200, // + 700 per ring
                yRadius: 2_500, // + 500 per ring
                buffs: [BuffIdentifier.PermanentSlow, BuffIdentifier.Sturdy],
                enemyHPScale: 8
            }
        ],
        eventEndTime: 581 // +4 s per ring
    },

    {
        eventStartTime: 572,
        warningDirection: Directions.None,
        timedEnemies: [
            {
                spawnTime: 565,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 60,
                shape: EnemySpawnShape.Oval,
                xRadius: 3_900,
                yRadius: 3_000,
                buffs: [BuffIdentifier.PermanentSlow, BuffIdentifier.Sturdy],
                enemyHPScale: 8
            }
        ],
        eventEndTime: 585
    },
]

const ACT_3_CHOREO_START_TIME = 620
// act 3
export const FALLING_FOLK_DANCE_EVENTS: ChoreographedEvent[] = [
    // A T T A C K  S Q U A D  > formation
    {
        eventStartTime: 1 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 20 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.West,
        timedEnemies: [
            {
                spawnTime: 2 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 1,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
            },
            {
                spawnTime: 2 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 2,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250,
            },
            {
                spawnTime: 2 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 3,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 500,
            },
            {
                spawnTime: 2 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 4,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 750,
            },
            {
                spawnTime: 2 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_000,
            },
            {
                spawnTime: 2 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 6,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_250,
            },
            {
                spawnTime: 2 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 7,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_500,
            },
            {
                spawnTime: 2 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 8,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_750,
            },
            {
                spawnTime: 2 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 9,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 2_000,
            },
            {
                spawnTime: 2 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 10,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 2_250,
            },
            {
                spawnTime: 2 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 11,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 2_500,
            },
        ]
    },

    // A T T A C K  S Q U A D  < formation
    {
        eventStartTime: 21 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 40 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.East,
        timedEnemies: [
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 1,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_500,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 2,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_250,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 3,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_000,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 4,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 750,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 500,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 6,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 7,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
            },
        ]
    },

    // attack squad double pincer!! > <
    {
        eventStartTime: 50 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 65 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.East | Directions.West,
        timedEnemies: [
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 2,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 1,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 3,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 1.5, // enemy count 3!
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 2,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 4,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 3,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 4,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 6,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 5,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 8,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING, //* 7, // count 8 !
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 6,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 8,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 7,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 9,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 8,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 11,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING, //* 10, // count 11
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 9,
            },

            // West
            // {
            //     spawnTime: 21 + ACT_3_CHOREO_START_TIME,
            //     enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
            //     count: 1,
            //     shape: EnemySpawnShape.Line,
            //     direction: Directions.West,
            //     buffs: [BuffIdentifier.Immovable],
            //     enemyHPScale: 10,
            //     distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 1,
            //     distanceFromPlayer: -LINE4_ENEMY_HORIZONTAL_DISTANCE - 250 * 0,
            // },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 2,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 1,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 3,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 1.5, // enemy count 3!
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 2,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 4,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 3,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 4,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 6,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 5,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 8,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,// * 7, // count 8!
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 6,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 8,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 7,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING * 9,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 8,
            },
            {
                spawnTime: 21 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 11,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING, //* 10, // count 11
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250 * 9,
            },
        ]
    },

    // (rest)

    // DEADLY ARENA:
    // Stay here while the squad runs at you.
    {
        eventStartTime: 90 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 105 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.North | Directions.South | Directions.East | Directions.West,
        timedEnemies: [
            {
                spawnTime: 90 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 60,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_800,
                yRadius: 2_800,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlow],
                enemyHPScale: 999
            }
        ]
    },
    {
        eventStartTime: 93 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 93+15 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.East,
        timedEnemies: [
            {
                spawnTime: 94 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 12,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.ChoreoSpeedy],
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
        ]
    },
    {
        eventStartTime: 96 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 96+15 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.West,
        timedEnemies: [
            {
                spawnTime: 97 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 12,
                direction: Directions.West,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.ChoreoSpeedy],
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 20,
            },
        ]
    },
    {
        eventStartTime: 99 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 99+15 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.North,
        timedEnemies: [
            {
                spawnTime: 100 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 12,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.ChoreoSpeedy],
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_VERTICAL_DISTANCE,
                enemyHPScale: 20,
            },
        ]
    },
    {
        eventStartTime: 102 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 102+15 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.South,
        timedEnemies: [
            {
                spawnTime: 103 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 12,
                direction: Directions.South,
                shape: EnemySpawnShape.Line,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.ChoreoSpeedy],
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_VERTICAL_DISTANCE,
                enemyHPScale: 20,
            },
        ]
    },
    // DEADLY ARENA END

    // (rest)

    // South east to north west moving
    {
        eventStartTime: 130 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 150 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.South | Directions.East,
        timedEnemies: [
            {
                spawnTime: 131 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 30,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                movementDirectionOverride: { x: -0.656, y: -0.755 }, // North West (down is up)
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                displacementFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 10
            },
            {
                spawnTime: 131 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 30,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                movementDirectionOverride: { x: -0.656, y: -0.755 },
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 500,
                displacementFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 10
            },
            {
                spawnTime: 131 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 30,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                movementDirectionOverride: { x: -0.656, y: -0.755 },
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_000,
                displacementFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 10
            },
        ]
    },

    // South west to north east
    {
        eventStartTime: 146 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 166 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.South | Directions.West,
        timedEnemies: [
            {
                spawnTime: 147 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 24,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                movementDirectionOverride: { x: 0.656, y: -0.755 }, // North East (down is up)
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                displacementFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 10
            },
            {
                spawnTime: 147 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 24,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                movementDirectionOverride: { x: 0.656, y: -0.755 },
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE - 500,
                displacementFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 10
            },
            {
                spawnTime: 147 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 24,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                movementDirectionOverride: { x: 0.656, y: -0.755 },
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE - 1_000,
                displacementFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 10
            }
        ]
    },

    // both!
    {
        eventStartTime: 162 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 182 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.South | Directions.East | Directions.West,
        timedEnemies: [
            {
                spawnTime: 163 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 30,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                movementDirectionOverride: { x: -0.656, y: -0.755 }, // North West (down is up)
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                displacementFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 10
            },
            {
                spawnTime: 163 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 30,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                movementDirectionOverride: { x: -0.656, y: -0.755 },
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 500,
                displacementFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 10
            },
            {
                spawnTime: 163 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 30,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                movementDirectionOverride: { x: -0.656, y: -0.755 },
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_000,
                displacementFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 10
            },


            {
                spawnTime: 163 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 24,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                movementDirectionOverride: { x: 0.656, y: -0.755 }, // North East (down is up)
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                displacementFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 10
            },
            {
                spawnTime: 163 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 24,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                movementDirectionOverride: { x: 0.656, y: -0.755 },
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE - 500,
                displacementFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 10
            },
            {
                spawnTime: 163 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 24,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                movementDirectionOverride: { x: 0.656, y: -0.755 },
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE - 1_000,
                displacementFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 10
            }
        ]
    },

    // (rest)

    // put them all together: deadly arena with eyebat squads and NE/NW shriekers
    {
        eventStartTime: 200 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 220 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.North | Directions.South | Directions.East | Directions.West,
        timedEnemies: [
            {
                spawnTime: 200 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 60,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_800,
                yRadius: 2_800,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlower], //slower than the other slow
                enemyHPScale: 999
            }
        ]
    },
    // BOX move west eyebats
    {
        eventStartTime: 203 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 220 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.East,
        timedEnemies: [
            {
                spawnTime: 204 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 7,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                enemyHPScale: 10
            },
            {
                spawnTime: 204 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 7,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250,
                enemyHPScale: 10
            },
            {
                spawnTime: 204 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 7,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 500,
                enemyHPScale: 10
            },
            {
                spawnTime: 204 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 7,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 750,
                enemyHPScale: 10
            },
            {
                spawnTime: 204 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 7,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_000,
                enemyHPScale: 10
            },
            {
                spawnTime: 204 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 7,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_250,
                enemyHPScale: 10
            },
            {
                spawnTime: 204 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.EYEBAT_CHOREO,
                count: 7,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_500,
                enemyHPScale: 10
            },
        ]
    },
    // shrieker rectangles west and east
    {
        eventStartTime: 214 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 232 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.West | Directions.East,
        timedEnemies: [
            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 5,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: -LINE4_ENEMY_HORIZONTAL_DISTANCE,
                displacementFromPlayer: 340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },
            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250,
                displacementFromPlayer: 340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },
            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 500,
                displacementFromPlayer: 340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },
            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 750,
                displacementFromPlayer: 340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },
            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_000,
                displacementFromPlayer: 340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },
            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_250,
                displacementFromPlayer: 340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },
            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 5,
                shape: EnemySpawnShape.Line,
                direction: Directions.West,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_500,
                displacementFromPlayer: 340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },

            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 5,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                displacementFromPlayer: -340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },
            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 250,
                displacementFromPlayer: -340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },
            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 500,
                displacementFromPlayer: -340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },
            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 750,
                displacementFromPlayer: -340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },
            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_000,
                displacementFromPlayer: -340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },
            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 2,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING * 5,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_250,
                displacementFromPlayer: -340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },
            {
                spawnTime: 214 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 5,
                shape: EnemySpawnShape.Line,
                direction: Directions.East,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE + 1_500,
                displacementFromPlayer: -340,
                buffs: [BuffIdentifier.Immovable],
                enemyHPScale: 10
            },
        ]
    },


    // circle til the end of the game 
    {
        eventStartTime: 242 + ACT_3_CHOREO_START_TIME,
        eventEndTime: 400 + ACT_3_CHOREO_START_TIME,
        warningDirection: Directions.West | Directions.East | Directions.North | Directions.South,
        timedEnemies: [
            {
                spawnTime: 246 + ACT_3_CHOREO_START_TIME,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_PLUME,
                count: 100,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_500,
                yRadius: 2_500,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentNoMove],
                enemyHPScale: 999
            }
        ]
    }
]

export const ENEMY_EVENT_SPAWN_LIST: ChoreographedEvent[] = [

]

export const RUNNING_OF_SHRIEKER_EVENTS: ChoreographedEvent[] = [
	// Clumps 1
    {
        eventStartTime: 108,
        warningDirection: Directions.West | Directions.East,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Clump,
                spawnTime: 110,
                enemy: ENEMY_NAME.SHRIEKER_VINES,
                count: 13,
                direction: Directions.East,
                buffs: [BuffIdentifier.PermanentSlow],
                enemyHPScale: 2,
                
            },
            {
                shape: EnemySpawnShape.Clump,
                spawnTime: 110,
                enemy: ENEMY_NAME.SHRIEKER_VINES,
                count: 13,
                direction: Directions.West,
                buffs: [BuffIdentifier.PermanentSlow],
                enemyHPScale: 2,
                
            }
            
        ],
        eventEndTime: 130
    },

    // Horiziontal Running 1

    {
        eventStartTime: 123,
        eventEndTime: 155,
        warningDirection: Directions.East,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Raindrops,
                spawnTime: 125,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
                direction: Directions.East,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                count: 24,
                numberOfLines: 30,
                horizontalStepSize: 275,
                verticalStepSize: 300,
                chancePerEnemySpot: 0.30,
                enemyHPScale: 10,
                buffs: [BuffIdentifier.Sturdy]
            }
        ],
    },
    	// Clumps 2
        {
            eventStartTime: 228,
            warningDirection: Directions.West | Directions.East,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Clump,
                    spawnTime: 230,
                    enemy: ENEMY_NAME.SHRIEKER_VINES,
                    count: 13,
                    direction: Directions.East,
                    buffs: [BuffIdentifier.PermanentSlow],
                    enemyHPScale: 2,
                    
                },
                {
                    shape: EnemySpawnShape.Clump,
                    spawnTime: 230,
                    enemy: ENEMY_NAME.SHRIEKER_VINES,
                    count: 13,
                    direction: Directions.West,
                    buffs: [BuffIdentifier.PermanentSlow],
                    enemyHPScale: 2,
                    
                }
                
            ],
            eventEndTime: 250
        },
    
        // Horiziontal Running 2
    
        {
            eventStartTime: 243,
            eventEndTime: 275,
            warningDirection: Directions.West,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Raindrops,
                    spawnTime: 245,
                    enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
                    direction: Directions.West,
                    distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                    count: 24,
                    numberOfLines: 30,
                    horizontalStepSize: 275,
                    verticalStepSize: 300,
                    chancePerEnemySpot: 0.30,
                    enemyHPScale: 10,
                    buffs: [BuffIdentifier.Sturdy]
                }
            ],
        },
        	// Clumps 3
    {
        eventStartTime: 403,
        warningDirection: Directions.West | Directions.East,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Clump,
                spawnTime: 405,
                enemy: ENEMY_NAME.SHRIEKER_VINES,
                count: 13,
                direction: Directions.East,
                buffs: [BuffIdentifier.PermanentSlow],
                enemyHPScale: 2,
                
            },
            {
                shape: EnemySpawnShape.Clump,
                spawnTime: 405,
                enemy: ENEMY_NAME.SHRIEKER_VINES,
                count: 13,
                direction: Directions.West,
                buffs: [BuffIdentifier.PermanentSlow],
                enemyHPScale: 2,
                
            }
            
        ],
        eventEndTime: 425
    },

    // Horiziontal Running 3

    {
        eventStartTime: 418,
        eventEndTime: 450,
        warningDirection: Directions.East,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Raindrops,
                spawnTime: 420,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
                direction: Directions.East,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                count: 24,
                numberOfLines: 30,
                horizontalStepSize: 275,
                verticalStepSize: 300,
                chancePerEnemySpot: 0.30,
                enemyHPScale: 10,
                buffs: [BuffIdentifier.Sturdy]
            }
        ],
    },
    	// Clumps 4
        {
            eventStartTime: 528,
            warningDirection: Directions.West | Directions.East,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Clump,
                    spawnTime: 530,
                    enemy: ENEMY_NAME.SHRIEKER_VINES,
                    count: 13,
                    direction: Directions.East,
                    buffs: [BuffIdentifier.PermanentSlow],
                    enemyHPScale: 2,
                    
                },
                {
                    shape: EnemySpawnShape.Clump,
                    spawnTime: 530,
                    enemy: ENEMY_NAME.SHRIEKER_VINES,
                    count: 13,
                    direction: Directions.West,
                    buffs: [BuffIdentifier.PermanentSlow],
                    enemyHPScale: 2,
                    
                }
                
            ],
            eventEndTime: 550
        },
    
        // Horiziontal Running 4
    
        {
            eventStartTime: 543,
            eventEndTime: 575,
            warningDirection: Directions.West,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Raindrops,
                    spawnTime: 545,
                    enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
                    direction: Directions.West,
                    distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                    count: 24,
                    numberOfLines: 30,
                    horizontalStepSize: 275,
                    verticalStepSize: 300,
                    chancePerEnemySpot: 0.30,
                    enemyHPScale: 10,
                    buffs: [BuffIdentifier.Sturdy]
                }
            ],
        },
        	// Clumps 5
    {
        eventStartTime: 733,
        warningDirection: Directions.West | Directions.East,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Clump,
                spawnTime: 735,
                enemy: ENEMY_NAME.SHRIEKER_VINES,
                count: 13,
                direction: Directions.East,
                buffs: [BuffIdentifier.PermanentSlow],
                enemyHPScale: 2,
                
            },
            {
                shape: EnemySpawnShape.Clump,
                spawnTime: 735,
                enemy: ENEMY_NAME.SHRIEKER_VINES,
                count: 13,
                direction: Directions.West,
                buffs: [BuffIdentifier.PermanentSlow],
                enemyHPScale: 2,
                
            }
            
        ],
        eventEndTime: 755
    },

    // Horiziontal Running 5

    {
        eventStartTime: 748,
        eventEndTime: 780,
        warningDirection: Directions.East,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Raindrops,
                spawnTime: 750,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
                direction: Directions.East,
                distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                count: 24,
                numberOfLines: 30,
                horizontalStepSize: 275,
                verticalStepSize: 300,
                chancePerEnemySpot: 0.30,
                enemyHPScale: 10,
                buffs: [BuffIdentifier.Sturdy]
            }
        ],
    },
    	// Clumps 6
        {
            eventStartTime: 808,
            warningDirection: Directions.West | Directions.East,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Clump,
                    spawnTime: 810,
                    enemy: ENEMY_NAME.SHRIEKER_VINES,
                    count: 13,
                    direction: Directions.East,
                    buffs: [BuffIdentifier.PermanentSlow],
                    enemyHPScale: 2,
                    
                },
                {
                    shape: EnemySpawnShape.Clump,
                    spawnTime: 810,
                    enemy: ENEMY_NAME.SHRIEKER_VINES,
                    count: 13,
                    direction: Directions.West,
                    buffs: [BuffIdentifier.PermanentSlow],
                    enemyHPScale: 2,
                    
                }
                
            ],
            eventEndTime: 830
        },
    
        // Horiziontal Running 6
    
        {
            eventStartTime: 823,
            eventEndTime: 855,
            warningDirection: Directions.West,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Raindrops,
                    spawnTime: 825,
                    enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR_FLYING,
                    direction: Directions.West,
                    distanceFromPlayer: LINE4_ENEMY_HORIZONTAL_DISTANCE,
                    count: 24,
                    numberOfLines: 30,
                    horizontalStepSize: 275,
                    verticalStepSize: 300,
                    chancePerEnemySpot: 0.30,
                    enemyHPScale: 10,
                    buffs: [BuffIdentifier.Sturdy]
                }
            ],
        },

]

export const RAZOR_SHARP_SNOWFALL_EVENTS: ChoreographedEvent[] = [
	// Rising Lines 1
    {
        eventStartTime: 65,  // Start at the same time
        eventEndTime: 73,  // 2nd and 3rd lines end later to push the player up. 
        warningDirection: Directions.South,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 65,
                enemy: ENEMY_NAME.SNOW_FURY_CHOREO_1,
                direction: Directions.South,
                distanceFromPlayer: LINE1_ENEMY_VERTICAL_DISTANCE,
                distanceBetweenUnits: 180,
                displacementFromPlayer: 0,
                count: 50,
                enemyHPScale: 20,
                buffs: [BuffIdentifier.Immovable,BuffIdentifier.PermanentSlower]
            }
        ],
    },
    {
        eventStartTime: 65.01,
        eventEndTime: 77,
        warningDirection: Directions.South,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 65.01,
                enemy: ENEMY_NAME.SNOW_FURY_CHOREO_1,
                direction: Directions.South,
                distanceFromPlayer: LINE2_ENEMY_VERTICAL_DISTANCE,
                distanceBetweenUnits: 180,
                displacementFromPlayer: 0,
                count: 50,
                enemyHPScale: 20,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlower]
            }
        ],
    },
    { 
        eventStartTime: 65.02,
        eventEndTime: 81,
        warningDirection: Directions.South,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 65.02,
                enemy: ENEMY_NAME.SNOW_FURY_CHOREO_1,
                direction: Directions.South,
                distanceFromPlayer: LINE3_ENEMY_VERTICAL_DISTANCE,
                distanceBetweenUnits: 180,
                displacementFromPlayer: 0,
                count: 50,
                enemyHPScale: 20,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlower]
            }
        ],
    },
    // Falling Snowflakes 1
    {
        eventStartTime: 80,
        eventEndTime: 106,
        warningDirection: Directions.North,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Raindrops,
                spawnTime: 81,
                enemy: ENEMY_NAME.SNOW_FURY_CHOREO_1,
                direction: Directions.North,
                distanceFromPlayer: LINE4_ENEMY_VERTICAL_DISTANCE,
                count: 36,
                numberOfLines: 40,
                horizontalStepSize: 300,
                verticalStepSize: 275,
                chancePerEnemySpot: 0.26,
                enemyHPScale: 10,
                buffs: [BuffIdentifier.Sturdy]
            }
        ],
    },

    	// Rising Lines 2
        {
            eventStartTime: 200,  // Start at the same time
            eventEndTime: 208,  // 2nd and 3rd lines end later to push the player up. 
            warningDirection: Directions.South,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Line,
                    spawnTime: 200,
                    enemy: ENEMY_NAME.SNOW_FURY_CHOREO_1,
                    direction: Directions.South,
                    distanceFromPlayer: LINE1_ENEMY_VERTICAL_DISTANCE,
                    distanceBetweenUnits: 180,
                    displacementFromPlayer: 0,
                    count: 50,
                    enemyHPScale: 20,
                    buffs: [BuffIdentifier.Immovable,BuffIdentifier.PermanentSlower]
                }
            ],
        },
        { 
            eventStartTime: 200.01,
            eventEndTime: 212,
            warningDirection: Directions.South,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Line,
                    spawnTime: 200.01,
                    enemy: ENEMY_NAME.SNOW_FURY_CHOREO_1,
                    direction: Directions.South,
                    distanceFromPlayer: LINE2_ENEMY_VERTICAL_DISTANCE,
                    distanceBetweenUnits: 180,
                    displacementFromPlayer: 0,
                    count: 50,
                    enemyHPScale: 20,
                    buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlower]
                }
            ],
        },
        { 
            eventStartTime: 200.2,
            eventEndTime: 216,
            warningDirection: Directions.South,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Line,
                    spawnTime: 200.02,
                    enemy: ENEMY_NAME.SNOW_FURY_CHOREO_1,
                    direction: Directions.South,
                    distanceFromPlayer: LINE3_ENEMY_VERTICAL_DISTANCE,
                    distanceBetweenUnits: 180,
                    displacementFromPlayer: 0,
                    count: 50,
                    enemyHPScale: 20,
                    buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlower]
                }
            ],
        },
        // Falling Snowflakes 2
        {
            eventStartTime: 215,
            eventEndTime: 241,
            warningDirection: Directions.North,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Raindrops,
                    spawnTime: 216,
                    enemy: ENEMY_NAME.SNOW_FURY_CHOREO_1,
                    direction: Directions.North,
                    distanceFromPlayer: LINE4_ENEMY_VERTICAL_DISTANCE,
                    count: 36,
                    numberOfLines: 40,
                    horizontalStepSize: 300,
                    verticalStepSize: 275,
                    chancePerEnemySpot: 0.26,
                    enemyHPScale: 10,
                    buffs: [BuffIdentifier.Sturdy]
                }
            ],
        },
        	// Rising Lines 3
    {
        eventStartTime: 370,  // Start at the same time
        eventEndTime: 378,  // 2nd and 3rd lines end later to push the player up. 
        warningDirection: Directions.South,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 370,
                enemy: ENEMY_NAME.SNOW_FURY_CHOREO_2,
                direction: Directions.South,
                distanceFromPlayer: LINE1_ENEMY_VERTICAL_DISTANCE,
                distanceBetweenUnits: 180,
                displacementFromPlayer: 0,
                count: 50,
                enemyHPScale: 20,
                buffs: [BuffIdentifier.Immovable,BuffIdentifier.PermanentSlower]
            }
        ],
    },
    { 
        eventStartTime: 370.01,
        eventEndTime: 382,
        warningDirection: Directions.South,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 370.01,
                enemy: ENEMY_NAME.SNOW_FURY_CHOREO_2,
                direction: Directions.South,
                distanceFromPlayer: LINE2_ENEMY_VERTICAL_DISTANCE,
                distanceBetweenUnits: 180,
                displacementFromPlayer: 0,
                count: 50,
                enemyHPScale: 20,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlower]
            }
        ],
    },
    { 
        eventStartTime: 370.02,
        eventEndTime: 386,
        warningDirection: Directions.South,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 370.02,
                enemy: ENEMY_NAME.SNOW_FURY_CHOREO_2,
                direction: Directions.South,
                distanceFromPlayer: LINE3_ENEMY_VERTICAL_DISTANCE,
                distanceBetweenUnits: 180,
                displacementFromPlayer: 0,
                count: 50,
                enemyHPScale: 20,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlower]
            }
        ],
    },
    // Falling Snowflakes 3
    {
        eventStartTime: 385,
        eventEndTime: 411,
        warningDirection: Directions.North,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Raindrops,
                spawnTime: 386,
                enemy: ENEMY_NAME.SNOW_FURY_CHOREO_2,
                direction: Directions.North,
                distanceFromPlayer: LINE4_ENEMY_VERTICAL_DISTANCE,
                count: 36,
                numberOfLines: 40,
                horizontalStepSize: 300,
                verticalStepSize: 275,
                chancePerEnemySpot: 0.26,
                enemyHPScale: 10,
                buffs: [BuffIdentifier.Sturdy]
            }
        ],
    },

    	// Rising Lines 4
        {
            eventStartTime: 490,  // Start at the same time
            eventEndTime: 498,  // 2nd and 3rd lines end later to push the player up. 
            warningDirection: Directions.South,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Line,
                    spawnTime: 490,
                    enemy: ENEMY_NAME.SNOW_FURY_CHOREO_2,
                    direction: Directions.South,
                    distanceFromPlayer: LINE1_ENEMY_VERTICAL_DISTANCE,
                    distanceBetweenUnits: 180,
                    displacementFromPlayer: 0,
                    count: 50,
                    enemyHPScale: 20,
                    buffs: [BuffIdentifier.Immovable,BuffIdentifier.PermanentSlower]
                }
            ],
        },
        { 
            eventStartTime: 490.01,
            eventEndTime: 502,
            warningDirection: Directions.South,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Line,
                    spawnTime: 490.01,
                    enemy: ENEMY_NAME.SNOW_FURY_CHOREO_2,
                    direction: Directions.South,
                    distanceFromPlayer: LINE2_ENEMY_VERTICAL_DISTANCE,
                    distanceBetweenUnits: 180,
                    displacementFromPlayer: 0,
                    count: 50,
                    enemyHPScale: 20,
                    buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlower]
                }
            ],
        },
        { 
            eventStartTime: 490.02,
            eventEndTime: 506,
            warningDirection: Directions.South,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Line,
                    spawnTime: 490.02,
                    enemy: ENEMY_NAME.SNOW_FURY_CHOREO_2,
                    direction: Directions.South,
                    distanceFromPlayer: LINE3_ENEMY_VERTICAL_DISTANCE,
                    distanceBetweenUnits: 180,
                    displacementFromPlayer: 0,
                    count: 50,
                    enemyHPScale: 20,
                    buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlower]
                }
            ],
        },
        // Falling Snowflakes 4
        {
            eventStartTime: 505,
            eventEndTime: 531,
            warningDirection: Directions.North,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Raindrops,
                    spawnTime: 506,
                    enemy: ENEMY_NAME.SNOW_FURY_CHOREO_2,
                    direction: Directions.North,
                    distanceFromPlayer: LINE4_ENEMY_VERTICAL_DISTANCE,
                    count: 36,
                    numberOfLines: 40,
                    horizontalStepSize: 300,
                    verticalStepSize: 275,
                    chancePerEnemySpot: 0.26,
                    enemyHPScale: 10,
                    buffs: [BuffIdentifier.Sturdy]
                }
            ],
        },

        	// Rising Lines 5
    {
        eventStartTime: 655,  // Start at the same time
        eventEndTime: 663,  // 2nd and 3rd lines end later to push the player up. 
        warningDirection: Directions.South,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 655,
                enemy: ENEMY_NAME.SNOW_FURY_CHOREO_3,
                direction: Directions.South,
                distanceFromPlayer: LINE1_ENEMY_VERTICAL_DISTANCE,
                distanceBetweenUnits: 180,
                displacementFromPlayer: 0,
                count: 50,
                enemyHPScale: 20,
                buffs: [BuffIdentifier.Immovable,BuffIdentifier.PermanentSlower]
            }
        ],
    },
    { 
        eventStartTime: 655.01,
        eventEndTime: 667,
        warningDirection: Directions.South,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 655.01,
                enemy: ENEMY_NAME.SNOW_FURY_CHOREO_3,
                direction: Directions.South,
                distanceFromPlayer: LINE2_ENEMY_VERTICAL_DISTANCE,
                distanceBetweenUnits: 180,
                displacementFromPlayer: 0,
                count: 50,
                enemyHPScale: 20,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlower]
            }
        ],
    },
    { 
        eventStartTime: 655.02,
        eventEndTime: 671,
        warningDirection: Directions.South,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Line,
                spawnTime: 655.02,
                enemy: ENEMY_NAME.SNOW_FURY_CHOREO_3,
                direction: Directions.South,
                distanceFromPlayer: LINE3_ENEMY_VERTICAL_DISTANCE,
                distanceBetweenUnits: 180,
                displacementFromPlayer: 0,
                count: 50,
                enemyHPScale: 20,
                buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlower]
            }
        ],
    },
    // Falling Snowflakes 5
    {
        eventStartTime: 670,
        eventEndTime: 696,
        warningDirection: Directions.North,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Raindrops,
                spawnTime: 671,
                enemy: ENEMY_NAME.SNOW_FURY_CHOREO_3,
                direction: Directions.North,
                distanceFromPlayer: LINE4_ENEMY_VERTICAL_DISTANCE,
                count: 36,
                numberOfLines: 40,
                horizontalStepSize: 300,
                verticalStepSize: 275,
                chancePerEnemySpot: 0.26,
                enemyHPScale: 10,
                buffs: [BuffIdentifier.Sturdy]
            }
        ],
    },

    	// Rising Lines 6
        {
            eventStartTime: 795,  // Start at the same time
            eventEndTime: 803,  // 2nd and 3rd lines end later to push the player up. 
            warningDirection: Directions.South,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Line,
                    spawnTime: 795,
                    enemy: ENEMY_NAME.SNOW_FURY_CHOREO_3,
                    direction: Directions.South,
                    distanceFromPlayer: LINE1_ENEMY_VERTICAL_DISTANCE,
                    distanceBetweenUnits: 180,
                    displacementFromPlayer: 0,
                    count: 50,
                    enemyHPScale: 20,
                    buffs: [BuffIdentifier.Immovable,BuffIdentifier.PermanentSlower]
                }
            ],
        },
        { 
            eventStartTime: 795.01,
            eventEndTime: 807,
            warningDirection: Directions.South,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Line,
                    spawnTime: 795.01,
                    enemy: ENEMY_NAME.SNOW_FURY_CHOREO_3,
                    direction: Directions.South,
                    distanceFromPlayer: LINE2_ENEMY_VERTICAL_DISTANCE,
                    distanceBetweenUnits: 180,
                    displacementFromPlayer: 0,
                    count: 50,
                    enemyHPScale: 20,
                    buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlower]
                }
            ],
        },
        { 
            eventStartTime: 795.02,
            eventEndTime: 811,
            warningDirection: Directions.South,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Line,
                    spawnTime: 795.02,
                    enemy: ENEMY_NAME.SNOW_FURY_CHOREO_3,
                    direction: Directions.South,
                    distanceFromPlayer: LINE3_ENEMY_VERTICAL_DISTANCE,
                    distanceBetweenUnits: 180,
                    displacementFromPlayer: 0,
                    count: 50,
                    enemyHPScale: 20,
                    buffs: [BuffIdentifier.Immovable, BuffIdentifier.PermanentSlower]
                }
            ],
        },
        // Falling Snowflakes 6
        {
            eventStartTime: 810,
            eventEndTime: 836,
            warningDirection: Directions.North,
            timedEnemies: [
                {
                    shape: EnemySpawnShape.Raindrops,
                    spawnTime: 811,
                    enemy: ENEMY_NAME.SNOW_FURY_CHOREO_3,
                    direction: Directions.North,
                    distanceFromPlayer: LINE4_ENEMY_VERTICAL_DISTANCE,
                    count: 36,
                    numberOfLines: 40,
                    horizontalStepSize: 300,
                    verticalStepSize: 275,
                    chancePerEnemySpot: 0.26,
                    enemyHPScale: 10,
                    buffs: [BuffIdentifier.Sturdy]
                }
            ],
        },
    
    
]

// Just some examples alternating between east and west in the rain drop pattern. Feel free to change.
// Rewards and start times are to spec.
export const POSITIVE_PET_CHOREO_EVENTS: ChoreographedEvent[] = [
  {
      eventStartTime: 120,
      eventEndTime: 120 + 30,
      warningDirection: Directions.East,
      isPositiveEvent: true,
      timedEnemies: [
          {
              shape: EnemySpawnShape.Raindrops,
              spawnTime: 1 + 120,
              enemy: ENEMY_NAME.CHOREO_PET_CAT,
              randomReplacements: PET_CHOREO_EQUAL_OPPORTUNITY_BUNDLE,
              randomRewards: PET_CHOREO_REWARD_WEIGHTED_LIST,
              direction: Directions.East,
              distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
              count: 24,
              numberOfLines: 30,
              horizontalStepSize: 275,
              verticalStepSize: 300,
              chancePerEnemySpot: 0.33,
          }
      ],
  },
  {
    eventStartTime: 120 * 2,
    eventEndTime: 120 * 2 + 30,
    warningDirection: Directions.West,
    isPositiveEvent: true,
    timedEnemies: [
        {
            shape: EnemySpawnShape.Raindrops,
            spawnTime: 1 + 120 * 2,
            enemy: ENEMY_NAME.CHOREO_PET_CAT,
            randomReplacements: PET_CHOREO_GREEN_THUMB_BUNDLE,
            randomRewards: PET_CHOREO_REWARD_WEIGHTED_LIST,
            direction: Directions.West,
            distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
            count: 24,
            numberOfLines: 30,
            horizontalStepSize: 275,
            verticalStepSize: 300,
            chancePerEnemySpot: 0.33,
        }
    ],
  },
  {
    eventStartTime: 120 * 3,
    eventEndTime: 120 * 3 + 30,
    warningDirection: Directions.East,
    isPositiveEvent: true,
    timedEnemies: [
        {
            shape: EnemySpawnShape.Raindrops,
            spawnTime: 1 + 120 * 3,
            enemy: ENEMY_NAME.CHOREO_PET_CAT,
            randomReplacements: PET_CHOREO_MAMMAL_BUNDLE,
            randomRewards: PET_CHOREO_REWARD_WEIGHTED_LIST,
            direction: Directions.East,
            distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
            count: 24,
            numberOfLines: 30,
            horizontalStepSize: 275,
            verticalStepSize: 300,
            chancePerEnemySpot: 0.33,
        }
    ],
  },
  {
    eventStartTime: 120 * 4,
    eventEndTime: 120 * 4 + 30,
    warningDirection: Directions.West,
    isPositiveEvent: true,
    timedEnemies: [
        {
            shape: EnemySpawnShape.Raindrops,
            spawnTime: 1 + 120 * 4,
            enemy: ENEMY_NAME.CHOREO_PET_CAT,
            randomReplacements: PET_CHOREO_WIERD_LITTLE_GUY_BUNDLE,
            randomRewards: PET_CHOREO_REWARD_WEIGHTED_LIST,
            direction: Directions.West,
            distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
            count: 24,
            numberOfLines: 30,
            horizontalStepSize: 275,
            verticalStepSize: 300,
            chancePerEnemySpot: 0.33,
        }
    ],
  },
  {
    eventStartTime: 120 * 5,
    eventEndTime: 120 * 5 + 30,
    warningDirection: Directions.East,
    isPositiveEvent: true,
    timedEnemies: [
        {
            shape: EnemySpawnShape.Raindrops,
            spawnTime: 1 + 120 * 5,
            enemy: ENEMY_NAME.CHOREO_PET_CAT,
            randomReplacements: PET_CHOREO_EQUAL_OPPORTUNITY_BUNDLE,
            randomRewards: PET_CHOREO_REWARD_WEIGHTED_LIST,
            direction: Directions.East,
            distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
            count: 24,
            numberOfLines: 30,
            horizontalStepSize: 275,
            verticalStepSize: 300,
            chancePerEnemySpot: 0.33,
        }
    ],
  },
  {
    eventStartTime: 120 * 6,
    eventEndTime: 120 * 6 + 30,
    warningDirection: Directions.West,
    isPositiveEvent: true,
    timedEnemies: [
        {
            shape: EnemySpawnShape.Raindrops,
            spawnTime: 1 + 120 * 6,
            enemy: ENEMY_NAME.CHOREO_PET_CAT,
            randomReplacements: PET_CHOREO_MAMMAL_BUNDLE,
            randomRewards: PET_CHOREO_REWARD_WEIGHTED_LIST,
            direction: Directions.West,
            distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
            count: 24,
            numberOfLines: 30,
            horizontalStepSize: 275,
            verticalStepSize: 300,
            chancePerEnemySpot: 0.33,
        }
    ],
  },
  {
    eventStartTime: 120 * 7,
    eventEndTime: 120 * 7 + 30,
    warningDirection: Directions.East,
    isPositiveEvent: true,
    timedEnemies: [
        {
            shape: EnemySpawnShape.Raindrops,
            spawnTime: 1 + 120 * 7,
            enemy: ENEMY_NAME.CHOREO_PET_CAT,
            randomReplacements: PET_CHOREO_GREEN_THUMB_BUNDLE,
            randomRewards: PET_CHOREO_REWARD_WEIGHTED_LIST,
            direction: Directions.East,
            distanceFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
            count: 24,
            numberOfLines: 30,
            horizontalStepSize: 275,
            verticalStepSize: 300,
            chancePerEnemySpot: 0.33,
        }
    ],
  },
]

// Some examples
const ExampleSpawnList: ChoreographedEvent[] = [
    // A clump from the south
    {
        eventStartTime: 1,
        warningDirection: Directions.South,
        timedEnemies: [
            {
                shape: EnemySpawnShape.Clump,
                spawnTime: 1,
                enemy: ENEMY_NAME.CRAB,
                count: 7,
                direction: Directions.South,
                noDeathTime: true // don't kill these enemies at eventEndTime
            }
        ],
        eventEndTime: 2
    },
    // A quick oval moving in on the player
    {
        eventStartTime: 4,
        warningDirection: Directions.North | Directions.South | Directions.East | Directions.West, // show warning everywhere
        timedEnemies: [
            {
                spawnTime: 5,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 40,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_500,
                yRadius: 2_000,
                buffs: [BuffIdentifier.PermanentSlow],
                enemyHPScale: 10 // 10x HP as they would normally have
            }
        ],
        eventEndTime: 20
    },

    // 3 closed ovals closing in on the player. Never closes completely.
    {
        eventStartTime: 10,
        warningDirection: Directions.East | Directions.West, // show warning everywhere
        timedEnemies: [
            {
                spawnTime: 3,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 40,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_500,
                yRadius: 2_000,
                buffs: [BuffIdentifier.PermanentSlow], // would be good to add a knockback resist buff as well.
                enemyHPScale: 10 // 10x HP as they would normally have
            }
        ],
        eventEndTime: 16
    },
    {
        eventStartTime: 11,
        warningDirection: Directions.North, // show warning everywhere
        timedEnemies: [
            {
                spawnTime: 3,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 50, // +10 per ring
                shape: EnemySpawnShape.Oval,
                xRadius: 3_200, // + 700 per ring
                yRadius: 2_500, // + 500 per ring
                buffs: [BuffIdentifier.PermanentSlow],
                enemyHPScale: 10 // 10x HP as they would normally have
            }
        ],
        eventEndTime: 20 // +4 s per ring
    },

    {
        eventStartTime: 12,
        warningDirection: Directions.South, // show warning everywhere
        timedEnemies: [
            {
                spawnTime: 3,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 60,
                shape: EnemySpawnShape.Oval,
                xRadius: 3_900,
                yRadius: 3_000,
                buffs: [BuffIdentifier.PermanentSlow],
                enemyHPScale: 10 // 10x HP as they would normally have
            }
        ],
        eventEndTime: 24
    },

    // 5 loose ovals closing in on the player. Enemies close completely, and die off late.
    {
        eventStartTime: 10,
        warningDirection: Directions.North | Directions.South | Directions.East | Directions.West, // show warning everywhere
        timedEnemies: [
            {
                spawnTime: 3,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 15,
                shape: EnemySpawnShape.Oval,
                xRadius: 2_500,
                yRadius: 2_000,
                buffs: [BuffIdentifier.PermanentSlow], // would be good to add a knockback resist buff as well.
                enemyHPScale: 2 // 2x HP as they would normally have
            }
        ],
        eventEndTime: 45
    },
    {
        eventStartTime: 11,
        warningDirection: Directions.North, // show warning everywhere
        timedEnemies: [
            {
                spawnTime: 3,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20, // +5 per ring
                shape: EnemySpawnShape.Oval,
                xRadius: 3_340, // + 800 per ring
                yRadius: 2_550, // + 550 per ring
                buffs: [BuffIdentifier.PermanentSlow],
                enemyHPScale: 2 // 2x HP as they would normally have
            }
        ],
        eventEndTime: 45 // same end
    },

    {
        eventStartTime: 12,
        warningDirection: Directions.South, // show warning everywhere
        timedEnemies: [
            {
                spawnTime: 3,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 25,
                shape: EnemySpawnShape.Oval,
                xRadius: 4_100,
                yRadius: 3_100,
                buffs: [BuffIdentifier.PermanentSlow],
                enemyHPScale: 2 // 2x HP as they would normally have
            }
        ],
        eventEndTime: 45
    },

    {
        eventStartTime: 13,
        warningDirection: Directions.South, // show warning everywhere
        timedEnemies: [
            {
                spawnTime: 3,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 30,
                shape: EnemySpawnShape.Oval,
                xRadius: 4_900,
                yRadius: 3_650,
                buffs: [BuffIdentifier.PermanentSlow],
                enemyHPScale: 2 // 2x HP as they would normally have
            }
        ],
        eventEndTime: 45
    },
    {
        eventStartTime: 14,
        warningDirection: Directions.None, // show warning everywhere
        timedEnemies: [
            {
                spawnTime: 3,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 35,
                shape: EnemySpawnShape.Oval,
                xRadius: 5_700,
                yRadius: 4_200,
                buffs: [BuffIdentifier.PermanentSlow],
                enemyHPScale: 2 // 2x HP as they would normally have
            }
        ],
        eventEndTime: 45
    },


    // 2 lines, with the first having a gap in the middle
    {
        eventStartTime: 9,
        warningDirection: Directions.East,
        timedEnemies: [
            {
                spawnTime: 9.5,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 10,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: STANDARD_ENEMY_HORIZONTAL_SPACING,
                displacementFromPlayer: 950,
            },
            {
                spawnTime: 9.5,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 10,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: STANDARD_ENEMY_HORIZONTAL_SPACING,
                displacementFromPlayer: -950,
            },
            {
                spawnTime: 12,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 10,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: STANDARD_ENEMY_HORIZONTAL_SPACING,
            },
        ],
        eventEndTime: 25
    },

    // 3 lines from the north
    {
        eventStartTime: 20,
        warningDirection: Directions.North,
        timedEnemies: [
            {
                spawnTime: 20.5,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 19,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,
            },
            {
                spawnTime: 21.5,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,
            },
            {
                spawnTime: 22.5,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 19,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: STANDARD_ENEMY_VERTICAL_SPACING,
            },
        ],
        eventEndTime: 35
    },

    // 2 lines, 1 from North, 1 from South
    {
        eventStartTime: 28,
        warningDirection: Directions.North | Directions.South,
        timedEnemies: [
            {
                spawnTime: 29,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 10,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: STANDARD_ENEMY_HORIZONTAL_SPACING,
                displacementFromPlayer: 500,
            },
            {
                spawnTime: 30,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 10,
                direction: Directions.South,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: STANDARD_ENEMY_HORIZONTAL_SPACING,
                displacementFromPlayer: -950,
            },
        ],
        eventEndTime: 40
    },

    //8 loose lines, circling from north to west to south to east.
    {
        eventStartTime: 28,
        warningDirection: Directions.North,
        timedEnemies: [
            {
                spawnTime: 29,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                displacementFromPlayer: LINE1_ENEMY_VERTICAL_DISTANCE,
            },
            {
                spawnTime: 29,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20,
                direction: Directions.North,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                displacementFromPlayer: LINE3_ENEMY_VERTICAL_DISTANCE,
            },
        ],
        eventEndTime: 40
    },
    {
        eventStartTime: 36,
        warningDirection: Directions.West,
        timedEnemies: [
            {
                spawnTime: 37,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20,
                direction: Directions.West,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                displacementFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
            },
            {
                spawnTime: 37,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20,
                direction: Directions.West,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                displacementFromPlayer: LINE3_ENEMY_HORIZONTAL_DISTANCE,
            },
        ],
        eventEndTime: 48
    },
    {
        eventStartTime: 44,
        warningDirection: Directions.South,
        timedEnemies: [
            {
                spawnTime: 45,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 10,
                direction: Directions.South,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                displacementFromPlayer: LINE1_ENEMY_VERTICAL_DISTANCE,
            },
            {
                spawnTime: 45,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20,
                direction: Directions.South,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: LOOSE_ENEMY_HORIZONTAL_SPACING,
                displacementFromPlayer: LINE3_ENEMY_VERTICAL_DISTANCE,
            },
        ],
        eventEndTime: 56
    },
    {
        eventStartTime: 52,
        warningDirection: Directions.East,
        timedEnemies: [
            {
                spawnTime: 53,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                displacementFromPlayer: LINE1_ENEMY_HORIZONTAL_DISTANCE,
            },
            {
                spawnTime: 53,
                enemy: ENEMY_NAME.SHRIEKER_KNIGHT_BLUE_SPEAR,
                count: 20,
                direction: Directions.East,
                shape: EnemySpawnShape.Line,
                distanceBetweenUnits: LOOSE_ENEMY_VERTICAL_SPACING,
                displacementFromPlayer: LINE3_ENEMY_HORIZONTAL_DISTANCE,
            },
        ],
        eventEndTime: 64
    },
]

