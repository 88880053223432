import { Vector } from "sat"
import { Audio } from "../../engine/audio"
import { Buff } from "../../buffs/buff"
import { BuffIdentifier } from "../../buffs/buff.shared"
import { GameState } from "../../engine/game-state"
import { UI } from "../../ui/ui"
import { debugConfig } from "../../utils/debug-config"
import { VectorXY } from "../../utils/math"
import { timeInSeconds } from "../../utils/primitive-types"
import { InGameTime } from "../../utils/time"
import { WORLD_DATA } from "../../world-generation/world-data"
import AISystem from "./ai-system"
import { Enemy } from "./enemy"
import { ChoreographedEvent, CLUMP_DEFAULT_SPAWN_RECT_X, CLUMP_DEFAULT_SPAWN_RECT_Y, EnemySpawnShape, ENEMY_EVENT_SPAWN_LIST, LineEnemiesConfig, RaindropEnemiesConfig } from "./enemy-events-config"
import { ENEMY_NAME } from "./enemy-names"
import { Directions } from "./enemy-spawn-config"
import { cloneDeepSerializable } from "../../utils/object-util"
import { GroundPickupType } from "../pickups/ground-pickup-types"
import { GROUND_PICKUP_CONFIGS, allocGroundPickup } from "../pickups/ground-pickup"
import WeightedList from "../../utils/weighted-list"

const REWARD_CARRIER_OFFSET = { x: 0, y: 15 }
const REWARD_STACK_OFFSET = { x: 0, y: 5 }

export class ChoreographedEventSpawner {
    static getInstance() {
        return ChoreographedEventSpawner.instance
    }
    static destroy() {
        ChoreographedEventSpawner.instance = null
    }
    private static instance: ChoreographedEventSpawner

    private eventIndex: number
    private currentEvent: ChoreographedEvent
    private packIndex: number

    private playerPositionOnEventStart: Vector

    enemySpawnList: ChoreographedEvent[]

    constructor() {
        ChoreographedEventSpawner.instance = this

        this.eventIndex = 0
        this.currentEvent = null
        this.playerPositionOnEventStart = new Vector()

        this.enemySpawnList = cloneDeepSerializable(ENEMY_EVENT_SPAWN_LIST) as ChoreographedEvent[]
    }

    update(delta: timeInSeconds) {
        if (debugConfig.enemy.disableEvents) {
            return
        }

        const currentTimeInSeconds = InGameTime.timeElapsedInSeconds

        let nextEvent: ChoreographedEvent
        if (this.eventIndex <  this.enemySpawnList.length) {
            nextEvent =  this.enemySpawnList[this.eventIndex]
            if (currentTimeInSeconds >= nextEvent.eventStartTime) {
                Audio.getInstance().playSfx('SFX_Choreo_Warning')
                UI.getInstance().emitAction('ui/updateShowWarning', { direction: nextEvent.warningDirection, isPositiveEvent:  Boolean(nextEvent.isPositiveEvent)})
                this.currentEvent = nextEvent
                this.packIndex = 0
                this.eventIndex++
                this.playerPositionOnEventStart.copy(GameState.player.position)
            }
        }

        if (this.currentEvent) {
            let nextPack = true
            while (this.packIndex < this.currentEvent.timedEnemies.length && nextPack) {
                const pack = this.currentEvent.timedEnemies[this.packIndex]
                const spawnTime = pack.spawnTime
                nextPack = false

                if (currentTimeInSeconds >= spawnTime) {
                    const deathTimer = this.currentEvent.eventEndTime - spawnTime
                    let keepFacingDirection = false

                    let enemiesSpawned: Enemy[]
                    if (pack.shape === EnemySpawnShape.Line) {
                        let displacement = pack.displacementFromPlayer ?? 0
                        let movementDirection = this.getMovementDirection(pack)

                        const direction = pack.direction
                        let dist: number
                        switch (direction) {
                            case Directions.North:
                                dist = -(pack.distanceFromPlayer ?? WORLD_DATA.screenHeight / 2)
                                break
                            case Directions.South:
                                dist = (pack.distanceFromPlayer ?? WORLD_DATA.screenHeight / 2)
                                break
                            case Directions.East:
                                dist = (pack.distanceFromPlayer ?? WORLD_DATA.screenWidth / 2)
                                keepFacingDirection = true
                                break
                            case Directions.West:
                                dist = -(pack.distanceFromPlayer ?? WORLD_DATA.screenWidth / 2)
                                keepFacingDirection = true
                                break
                        }

                        enemiesSpawned = this.spawnLineFromDirection(direction, pack.enemy, pack.count, displacement, dist, pack.distanceBetweenUnits, movementDirection, deathTimer, pack.randomReplacements)

                    } else if (pack.shape === EnemySpawnShape.Oval) {
                        enemiesSpawned = AISystem.getInstance().spawnEnemiesAlongArc(pack.enemy, this.playerPositionOnEventStart.x + (pack.displacementX ?? 0),
                            this.playerPositionOnEventStart.y + (pack.displacementY ?? 0), pack.count, pack.xRadius, pack.yRadius, true, deathTimer, pack.arcAngleStart, pack.arcAngleEnd, pack.randomReplacements)

                        keepFacingDirection = true
                    } else if (pack.shape === EnemySpawnShape.Clump) {
                        const rectX = pack.spawnRectXLength ?? CLUMP_DEFAULT_SPAWN_RECT_X
                        const rectY = pack.spawnRectYLength ?? CLUMP_DEFAULT_SPAWN_RECT_Y

                        const offsetDirection = this.directionToReversedVectorXY(pack.direction)
                        offsetDirection.y *= -1 // above func is which direction enemies should move in after being spawned in the direction, so we have to reverse it
                        offsetDirection.x *= -1
                        offsetDirection.x *= pack.distanceFromPlayerX ?? WORLD_DATA.screenWidth / 2
                        offsetDirection.y *= pack.distanceFromPlayerY ?? WORLD_DATA.screenHeight / 2

                        const posX = this.playerPositionOnEventStart.x + offsetDirection.x
                        const posY = this.playerPositionOnEventStart.y + offsetDirection.y

                        enemiesSpawned = AISystem.getInstance().spawnEnemiesInRectangle(pack.enemy, pack.count, posX,
                            posY, rectX + posX, rectY + posY, pack.movementDirectionOverride, pack.noDeathTime ? undefined : deathTimer, pack.randomReplacements)
                    } else if (pack.shape === EnemySpawnShape.Raindrops) {
                        let dist: number
                        let distanceStep
                        let reverseXY: boolean = false
                        switch (pack.direction) {
                            case Directions.North:
                                dist = -(pack.distanceFromPlayer ?? WORLD_DATA.screenHeight / 2)
                                distanceStep = -pack.verticalStepSize
                                break
                            case Directions.South:
                                dist = (pack.distanceFromPlayer ?? WORLD_DATA.screenHeight / 2)
                                distanceStep = pack.verticalStepSize
                                break
                            case Directions.East:
                                dist = (pack.distanceFromPlayer ?? WORLD_DATA.screenWidth / 2)
                                distanceStep = pack.verticalStepSize
                                reverseXY = true
                                keepFacingDirection = true
                                break
                            case Directions.West:
                                dist = -(pack.distanceFromPlayer ?? WORLD_DATA.screenWidth / 2)
                                distanceStep = -pack.verticalStepSize
                                reverseXY = true
                                keepFacingDirection = true
                                break
                        }

                        const displacement = pack.displacementFromPlayer ?? 0
                        const halfCount = pack.count / 2

                        let movementDirection = this.getMovementDirection(pack)
                        enemiesSpawned = []
                        for (let y = 0; y < pack.numberOfLines; ++y) {
                            const xDisplace = (y % 2) * pack.horizontalStepSize / 2
                            for (let x = 0; x < pack.count; ++x) {
                                const yOff = y * distanceStep + dist
                                const xOff = pack.horizontalStepSize * (x - halfCount) + displacement + xDisplace

                                if (Math.random() <= pack.chancePerEnemySpot) {
                                    let enemy
                                    if (reverseXY) {
                                        enemy = AISystem.getInstance().spawnEnemyAtPlayerOffsetPos(pack.enemy, yOff, xOff, movementDirection, deathTimer, pack.randomReplacements)
                                    } else {
                                        enemy = AISystem.getInstance().spawnEnemyAtPlayerOffsetPos(pack.enemy, xOff, yOff, movementDirection, deathTimer, pack.randomReplacements)
                                    }
                                    enemiesSpawned.push(enemy)

                                    if (pack.enemyHPScale !== undefined) {
                                        enemy.maxHealth = enemy.maxHealth * pack.enemyHPScale
                                        enemy.currentHealth = enemy.maxHealth
                                    }
                                }
                            }
                        }
                    }

                    this.packIndex++
                    nextPack = true

                    for (let e = 0; e < enemiesSpawned.length; ++e) {
                        const enemy = enemiesSpawned[e]
                        enemy.isChoreoSpawn = true
                        enemy.isEventSpawn = true
                        enemy.keepFacingDirection = keepFacingDirection
                    }

                    if (pack.buffs) {
                        for (let b = 0; b < pack.buffs.length; ++b) {
                            for (let e = 0; e < enemiesSpawned.length; ++e) {
                                Buff.apply(pack.buffs[b], null, enemiesSpawned[e])
                            }
                        }
                    }

                    if (pack.enemyHPScale) {
                        for (let e = 0; e < enemiesSpawned.length; ++e) {
                            const enemy = enemiesSpawned[e]
                            enemy.maxHealth *= pack.enemyHPScale
                            enemy.currentHealth = enemy.maxHealth
                        }
                    }

                    if (pack.randomRewards) {
                      for (let e = 0; e < enemiesSpawned.length; ++e) {
                          const enemy = enemiesSpawned[e]
                          const rewardConfig = pack.randomRewards.pickRandom().value[0]
                          if (GROUND_PICKUP_CONFIGS[rewardConfig].type === GroundPickupType.CommonCurrency) {
                            const amount = GROUND_PICKUP_CONFIGS[rewardConfig].config.amount as number
                            for (let i = 0; i < amount; i++) {
                              const pickup = allocGroundPickup(rewardConfig, enemy.x, enemy.y, 0, 0)
                              pickup.setCarrier(enemy.position, REWARD_CARRIER_OFFSET.x + REWARD_STACK_OFFSET.x * i, REWARD_CARRIER_OFFSET.y + REWARD_STACK_OFFSET.y * i, enemy.deathTimer || 0)
                              pickup.maxLife
                            }
                          } else {
                            const pickup = allocGroundPickup(rewardConfig, enemy.x, enemy.y, 0, 0)
                            pickup.setCarrier(enemy.position, REWARD_CARRIER_OFFSET.x, REWARD_CARRIER_OFFSET.y, enemy.deathTimer || 0)
                          }
                      }
                  }
                }
            }
        }
    }

    getMovementDirection(pack: LineEnemiesConfig | RaindropEnemiesConfig): VectorXY {
        if (pack.movementDirectionOverride) {
            return pack.movementDirectionOverride
        }

        if (pack.direction !== undefined) {
            return this.directionToReversedVectorXY(pack.direction)
        }

        return undefined
    }

    directionToReversedVectorXY(direction: Directions): VectorXY {
        // these are reversed intentionally -- enemies spawned in the north move south 

        switch (direction) {
            case Directions.North:
                return { x: 0, y: 1 }
            case Directions.South:
                return { x: 0, y: -1 }
            case Directions.East:
                return { x: -1, y: 0 }
            case Directions.West:
                return { x: 1, y: 0 }
            case Directions.NorthEast:
                return { x: -0.7071067811865475, y: 0.7071067811865475 }
            case Directions.NorthWest:
                return { x: 0.7071067811865475, y: 0.7071067811865475 }
            case Directions.SouthEast:
                return { x: -0.7071067811865475, y: -0.7071067811865475 }
            case Directions.SouthWest:
                return { x: 0.7071067811865475, y: -0.7071067811865475 }
        }
    }

    private spawnLineFromDirection(direction: Directions, enemy: ENEMY_NAME, count: number, displacement: number, distance: number, distanceBetweenUnits: number, movementDirection: VectorXY, deathTimer: number, replacements?: WeightedList<ENEMY_NAME>): Enemy[] {
        switch (direction) {
            case Directions.North:
                return AISystem.getInstance().spawnEnemiesInHorizontalLine(enemy, count, this.playerPositionOnEventStart.x + displacement,
                    this.playerPositionOnEventStart.y + distance, distanceBetweenUnits, movementDirection, deathTimer, replacements)
            case Directions.South:
                return AISystem.getInstance().spawnEnemiesInHorizontalLine(enemy, count, this.playerPositionOnEventStart.x + displacement,
                    this.playerPositionOnEventStart.y + distance, distanceBetweenUnits, movementDirection, deathTimer, replacements)
            case Directions.East:
                return AISystem.getInstance().spawnEnemiesInVerticalLine(enemy, count, this.playerPositionOnEventStart.y + displacement,
                    this.playerPositionOnEventStart.x + distance, distanceBetweenUnits, movementDirection, deathTimer, replacements)
            case Directions.West:
                return AISystem.getInstance().spawnEnemiesInVerticalLine(enemy, count, this.playerPositionOnEventStart.y + displacement,
                    this.playerPositionOnEventStart.x + distance, distanceBetweenUnits, movementDirection, deathTimer, replacements)
        }
    }

    cleanUp() {
        this.eventIndex = 0
        this.currentEvent = null
    }
}