import { Player } from "../../entities/player"
import { PetRescueEventSystem } from "../../events/pet-rescue-gameplay-event"
import { StatOperator, StatType } from "../../stats/stat-interfaces-enums"
import { InGameTime } from "../../utils/time"
import { UpgradeStatBonuses } from "../upgrade-definitions"
import { MetaUnlockTag } from "./meta-unlocks-definitions"
import { MetaPerkName } from "./meta-perk-name"

export type MetaPerkDefinition = {
    name: string
    description: string
    icon: string
    startsLocked?: boolean
    unlockCondition?: MetaUnlockTag

    maxStacks: number
    purchaseCost: number
    getStatBonuses?: (stacks: number) => UpgradeStatBonuses
    simpleApplyFn?: (player: Player, currentStacks: number, state: any) => void
    simpleRemoveFn?: (player: Player, currentStacks: number, state: any) => void // this isn't necesary, but if we add debug stuff to add/remove it will become necessary
}

export const MetaUpgradeDefinitions: Record<MetaPerkName, MetaPerkDefinition> = {
    [MetaPerkName.AllDamage]: {
        name: `More Damage`,
        description: `Gain +10% damage per rank`,
        icon: 'perk-damage',
        maxStacks: 10,
        purchaseCost: 1,
        getStatBonuses(stacks: number) {
            return [
                [['Player'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.1 * stacks]
            ]
        }
    },
    [MetaPerkName.MoveSpeed]: {
        name: `Move Faster`,
        description: `Gain +2% movement speed per rank`,
        icon: 'perk-move-speed',
        maxStacks: 10,
        purchaseCost: 1,
        getStatBonuses(stacks: number) {
            return [
                [['Player'], StatType.movementSpeed, StatOperator.SUM_THEN_MULTIPLY, 0.02 * stacks]
            ]
        }
    },
    [MetaPerkName.ExtraHearts]: {
        name: `Extra Hearts`,
        description: `Gain one heart per rank`,
        icon: 'perk-extra-hearts',
        maxStacks: 3,
        purchaseCost: 5,
        getStatBonuses(stacks: number) {
            return [
                [['Player'], StatType.maxHealth, StatOperator.SUM, 2 * stacks]
            ]
        },
        simpleApplyFn(player: Player, stacks) {
            player.heal(stacks * 2)
        }
    },
    [MetaPerkName.Splitting]: {
        name: `Bonus Splitting`,
        description: `Gain one split per rank`,
        icon: 'perk-splitting',
        maxStacks: 2,
        purchaseCost: 3,
        getStatBonuses(stacks: number) {
            return [
                [['Player'], StatType.projectileSplitCount, StatOperator.SUM, stacks]
            ]
        }
    },
    [MetaPerkName.Chaining]: {
        name: `Bonus Chaining`,
        description: `Gain one chaining per rank`,
        icon: 'perk-chaining',
        maxStacks: 2,
        purchaseCost: 3,
        getStatBonuses(stacks: number) {
            return [
                [['Player'], StatType.projectileChainCount, StatOperator.SUM, stacks]
            ]
        }
    },
    [MetaPerkName.CooldownSpeed]: {
        name: `Increased Cooldown Speed`,
        description: `Reduce all cooldowns by 3% per rank`,
        icon: 'perk-cooldown-speed',
        maxStacks: 10,
        purchaseCost: 1.5,
        getStatBonuses(stacks: number) {
            return [
                [['SecondaryWeapons'], StatType.cooldownInterval, StatOperator.MULTIPLY, -0.03 * stacks]
            ]
        }
    },
    [MetaPerkName.ReloadSpeed]: {
        name: `Increased Reload Speed`,
        description: `Reload 4% faster per rank`,
        icon: 'perk-reload-speed',
        maxStacks: 10,
        purchaseCost: 1,
        getStatBonuses(stacks: number) {
            return [
                [['PrimaryWeapon'], StatType.reloadInterval, StatOperator.MULTIPLY, -0.04 * stacks]
            ]
        }
    },
    [MetaPerkName.GameSpeed]: {
        name: `Game Speed`,
        description: `Increase game speed by 25/50/75/100%, based on rank`,
        icon: 'perk-game-speed',
        maxStacks: 4,
        purchaseCost: 0.1,
        unlockCondition: MetaUnlockTag.GameSpeed,
        simpleApplyFn(player, currentStacks, state) {
            const amount = [1.25, 1.5, 1.75, 2.0]
						// Multiply by any existing timescale mods
            InGameTime.timeScale *= amount[currentStacks - 1]
        },
        simpleRemoveFn(player, currentStacks, state) {
					// I don't think this is ever called but updating it just to be safe
					const amount = [1.25, 1.5, 1.75, 2.0]
            InGameTime.timeScale /= amount[currentStacks -1]
        },
    },
    [MetaPerkName.AttackRate]: {
        name: `Attack Rate Up`,
        description: `Gain +5% attack rate per rank`,
        icon: 'perk-attack-rate',
        maxStacks: 10,
        purchaseCost: 1,
        getStatBonuses(stacks: number) {
            return [
                [['Player'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.05 * stacks],
                [['Player'], StatType.chargeRate, StatOperator.SUM_THEN_MULTIPLY, 0.05 * stacks]
            ]
        }
    },
    [MetaPerkName.ProjectileCount]: {
        name: `Bonus Projectiles`,
        description: `Gain one projectile count per rank`,
        icon: 'perk-projectile-count',
        maxStacks: 3,
        purchaseCost: 1,
        getStatBonuses(stacks: number) {
            return [
                [['Player'], StatType.projectileCount, StatOperator.SUM, stacks],
                [['Player'], StatType.projectileSpreadAngle, StatOperator.SUM, stacks * 12],
            ]
        },
        simpleApplyFn(player: Player, currentStacks, state) {
            player.checkBoomerangAmmoCount()
        },
    },
    [MetaPerkName.CharacterSkillCooldown]: {
        name: `Increased Character Skill Cooldown Speed`,
        description: `Reduce your character's skill cooldown by 5% per rank`,
        icon: 'perk-character-skill-cooldown',
        maxStacks: 5,
        purchaseCost: 2,
        getStatBonuses(stacks: number) {
            return [
                [['PlayerSkill'], StatType.reloadInterval, StatOperator.MULTIPLY, -0.05 * stacks]
            ]
        }
    },
    [MetaPerkName.AttackSize]: {
        name: "Attack Size Increase",
        description: "Increase attack size by 10% per rank",
        icon: "perk-attack-size",
        maxStacks: 10,
        purchaseCost: 1,
        getStatBonuses: function (stacks: number): UpgradeStatBonuses {
            return [
                // the description says 10%, but this increases the radius of sizes, not the total area
                [['Player'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, 0.05 * stacks]
            ]
        },
    },
    [MetaPerkName.PickUpRange]: {
        name: "Pick Up Range Increase",
        description: "Increase your pickup range by 15% per rank",
        icon: "perk-pick-up-range",
        maxStacks: 5,
        purchaseCost: 1,
        getStatBonuses: function (stacks: number): UpgradeStatBonuses {
            return [
                [['Player'], StatType.pickupRange, StatOperator.SUM_THEN_MULTIPLY, 0.15 * stacks]
            ]
        },
        simpleApplyFn(player, currentStacks, state) {
            player.updateColliderRadius(player.pickupColliderComponent, player.stats.getStat(StatType.pickupRange))
        },
        simpleRemoveFn(player, currentStacks, state) {
            player.updateColliderRadius(player.pickupColliderComponent, player.stats.getStat(StatType.pickupRange))
        },
    },
    [MetaPerkName.RandomPet]: {
        name: "Best Friend",
        description: "Start chapters with a random pet!",
        icon: "perk-best-friend",
        maxStacks: 1,
        purchaseCost: 2,
        simpleApplyFn: function (player: Player, currentStacks: number, state: any): void {
            for (let i = 0; i < currentStacks; ++i) {
                PetRescueEventSystem.getInstance().createAndAddPet(null, false)
            }
        },
    },
    [MetaPerkName.Piercing]: {
        name: "Bonus Piercing",
        description: "Increase pierce by 1 per rank",
        icon: "perk-piercing",
        maxStacks: 3,
        purchaseCost: 3,
        getStatBonuses: function (stacks: number): UpgradeStatBonuses {
            return [
                [['Player'], StatType.attackPierceCount, StatOperator.SUM, stacks]
            ]
        },
    },
    [MetaPerkName.Knockback]: {
        name: "Knockback Boost",
        description: "Increase knockback by 20% per rank", //holy
        icon: "perk-knockback",
        maxStacks: 5,
        purchaseCost: 1,
        getStatBonuses: function (stacks: number): UpgradeStatBonuses {
            return [
                [['Player'], StatType.attackKnockback, StatOperator.SUM_THEN_MULTIPLY, 0.2 * stacks]
            ]
        },
    },
    [MetaPerkName.Regeneration]: {
        name: "Regeneration",
        description: "Regenerate 1 half heart every 120/105/90/75/60/45 seconds, based on rank",
        icon: "perk-regeneration",
        maxStacks: 6,
        purchaseCost: 1,
        getStatBonuses: function (stacks: number): UpgradeStatBonuses {
            const seconds = 120 - (stacks - 1) * 15
            return [
                [['Player'], StatType.healthRegenInterval, StatOperator.SUM, seconds * 1_000] //ms
            ]
        },
    },
    [MetaPerkName.AilmentPotency]: {
        name: "Increased Ailment Potency",
        description: "Increase ailment potency by 5% per rank",
        icon: "perk-ailment-potency-bonus",
        maxStacks: 10,
        purchaseCost: 1,
        getStatBonuses: function (stacks: number): UpgradeStatBonuses {
            return [
                [['Player'], StatType.allAilmentPotencyMult, StatOperator.SUM, 0.05 * stacks]
            ]
        },
    },
    [MetaPerkName.XPBoost]: {
        name: "Experienced",
        description: "Increase XP gained by 5% per rank",
        icon: "perk-xp-boost",
        maxStacks: 10,
        purchaseCost: 2,
        getStatBonuses: function (stacks: number): UpgradeStatBonuses {
            return [
                [['Player'], StatType.xpValueMulti, StatOperator.SUM, 0.05 * stacks]
            ]
        },
    },
    [MetaPerkName.MainWeaponCrush]: {
        name: `Main Weapon Crush`,
        description: `Gain 20% attack size and 15% damage on your main weapon per rank`,
        icon: 'perk-main-weapon-crush',
        maxStacks: 5,
        purchaseCost: 1,
        getStatBonuses(stacks: number) {
                return [
                        [['PrimaryWeapon'], StatType.attackSize, StatOperator.SUM_THEN_MULTIPLY, stacks * 0.2],
                        [['PrimaryWeapon'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, stacks * 0.15],
                ]
        },  
    },
    [MetaPerkName.MainWeaponVicious]: {
        name: `Main Weapon Vicious`,
        description: `Gain 30% knockback and 20% ailment potency on your main weapon per rank`,
        icon: 'perk-main-weapon-vicious',
        maxStacks: 5,
        purchaseCost: 1,
        getStatBonuses(stacks: number) {
                return [
                        [['PrimaryWeapon'], StatType.attackKnockback, StatOperator.SUM_THEN_MULTIPLY, stacks * 0.30],
                        [['PrimaryWeapon'], StatType.allAilmentPotencyMult, StatOperator.SUM_THEN_MULTIPLY, stacks * 0.20],
                ]
        },  
    },
    [MetaPerkName.MainWeaponAgility]: {
        name: `Main Weapon Agility`,
        description: `Gain 15% attack speed and 10% reduced movement penalty on your main weapon per rank`,
        icon: 'perk-main-weapon-agility',
        maxStacks: 5,
        purchaseCost: 1,
        getStatBonuses(stacks: number) {
                return [
                        [['PrimaryWeapon'], StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, stacks * 0.15],
                        [['PrimaryWeapon'], StatType.walkSpeedScalar, StatOperator.SUM_THEN_MULTIPLY, stacks * 0.1],
                ]
        },  
    },
}