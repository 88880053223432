<template>
	<div id="player-avatar" :class="{background: showBackground, small: iconSize ==='small', medium: iconSize ==='medium', large: iconSize ==='large'}">
		<div class="avatar-container">
			<div v-if="this.showIcon" class="icon-container">
				<SquareItem 
					:item="{ icon: iconTag }"
					:playerIcon="iconTag"
					:selected="false"
					:iconColor="iconColor"
					:disabled="false"
					:faded="false"
					:small-icons="true"
					:goldtrimEnabled="false"
					:selected-colour="'#ffe35f'"
					:goldHighlight="false">
				</SquareItem>
			</div>
			<div v-if="this.showUsername" class="name-container">
				<div :class="'name ' + textColor" :lang="this.$i18n.locale">{{this.username}}</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SquareItem from './square-items.vue'

export default {
	name: 'PlayerAvatar',
	components: {
    SquareItem
	},
	data() {
		return {}
	},
	props: {
		username: {
			type: String,
			required: true,
			default: "Player"
		},
		iconTag: {
			type: String,
			required: true,
			default: "bees"
		},
		iconColor: {
			type: String,
			required: true,
			default: "blue"
		},
		textColor: {
			type: String,
			required: false,
			default: 'offWhite'
		},
		iconSize: {
			type: String,
			required: true,
			default: "small"
		},
		showUsername: {
			type: Boolean,
			required: true,
			default: false
		},
		showIcon: {
			type: Boolean,
			required: true,
			default: true
		},
		showBackground: {
			type: Boolean,
			required: true,
			default: false
		},
  },
	computed: {
	},
	methods: {
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

	#player-avatar {

		&.background {
			.avatar-container{
				.icon-container {
					background-image: url('~/assets/ui/item/upgrade-container-normal.png');
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
				}
			}
		}
		&.small{
			// The width of square-item  at this scale
			width: 22px;
			height: 47px;
			// I don't think there's a way to avoid using scaling as long as we are using Square-Item to display the icon
			.avatar-container {
				transform: scale(0.30);
				transform-origin: top center;
			}

			.name-container{
				//transform: scale(3.333);
				//transform-origin: center left;
			}
		}
		&.medium{
			width: 30px;
			height: 60px;
			.avatar-container {
				transform: scale(0.4);
				transform-origin: top center;
			}
			.name-container{
				//transform: scale(2.5);
				//transform-origin: center left;
			}
		}
		&.large{
			width: 55px;
			height: 110px;
			.avatar-container {
				transform: scale(0.6);
				transform-origin: top center;
			}
			.name-container{
				//transform: scale(1.667);
				//transform-origin: center left;
			}
		}
		.avatar-container{
			display: flex;
			align-items: center;
			flex-flow: row;
		
			.icon-container {
			}
			.name-container {
				.name {
					font-size: 60px; //30px;
					&[lang='en'] {
						.regularFont(@enSecondaryFontPTBold);
						font-variant: small-caps;
					}
					&[lang='zh'] {
						.regularFont(@zhHansHeaderFont);
					}

					&.offWhite {
						color: @offWhite;
					}

					&.limerGreen {
						color: @limerGreen;
					}
				}
			}
		}

	}
</style>
