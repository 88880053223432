import { randomRange } from '../utils/math'
import { InGameTime } from '../utils/time'
import { EventCollections, EventStartData, MODDABLE_EVENT_DEFINITIONS, GameplayEvent } from './gameplay-event-definitions'
import { PetRescueEventSystem } from './pet-rescue-gameplay-event'
import { GoblinGameplayEvent } from './goblin-gameplay-event'
import { ShrineGameplayEvent } from './shrine-gameplay-event'
import { ShamblingTowerGameplayEvent } from './shambling-tower-gameplay-event'
import { timeInSeconds } from '../utils/primitive-types'
import { CombatArenaGameplayEvent } from './combat-arena-gameplay-event'
import { FountainsOfManaGameplayEvent } from './fountains-of-mana-gameplay-event'
import { EventTypes } from './event-types'
import { CreepyEggGameplayEvent } from './creepy-egg-gameplay-event'
import { TemporalDistortionGameplayEvent } from './temporal-distortion-gameplay-event'
import { WildRotSonEventSystem } from './wild-rot-sons-gameplay-event'
import { RoamingWildlingsEventSystem } from './roaming-wildlings-gameplay-event'
import { ForestPlantShrineGameplayEvent } from './forest-plant-shrine-gameplay-event'
import { KillingForFlowersShrineGameplayEvent } from './killing-for-flowers-shrine-gameplay-event'

const THROTTLE_UPDATE = 1
const INITIAL_EVENT_COUNT = 1
const STALE_EVENT_THRESHOLD = 15_000

export class GameplayTimedEventSystem {
	private timeToRun = 1
	queuedEvents: Map<EventTypes, number>
	eventRegistry: Map<EventTypes, GameplayEvent>
	activeEvents: Map<EventTypes, number>
	eventCount: Map<EventTypes, number>

	eventDefinitions: EventCollections

	static getInstance() {
		if (!GameplayTimedEventSystem.instance) {
			GameplayTimedEventSystem.instance = new GameplayTimedEventSystem()
		}
		return GameplayTimedEventSystem.instance
	}
	static destroy() {
		GameplayTimedEventSystem.instance = null
	}

	private static instance: GameplayTimedEventSystem

	constructor() {
		this.timeToRun = InGameTime.timeElapsedInSeconds + THROTTLE_UPDATE
		this.activeEvents = new Map()
		this.queuedEvents = new Map()
		this.eventRegistry = new Map()
		this.eventCount = new Map()

		// Queue initial events at index 0
		for (const eventName of Object.keys(MODDABLE_EVENT_DEFINITIONS) as EventTypes[]) {
			let { min, max } = MODDABLE_EVENT_DEFINITIONS[eventName].spawnWindow[0]

			let eventStartTime = Math.round(randomRange(min, max))

			this.queuedEvents.set(eventName, eventStartTime)
		}

		this.eventDefinitions = { ...MODDABLE_EVENT_DEFINITIONS }
	}

	update(delta: timeInSeconds) {
		const currentTime = InGameTime.timeElapsedInSeconds
		// Throttle update to once per second
		if (currentTime >= this.timeToRun) {
			this.timeToRun = currentTime + THROTTLE_UPDATE

			for (const [eventType, eventStartTime] of this.queuedEvents) {
				if (currentTime >= eventStartTime) {
					if (!this.isMaxedEventCount(eventType) && !this.isExcludedFromStarting(eventType)) {
						this.startEvent(eventType)
					}
				}
			}
		}

		for (const [eventType, event] of this.eventRegistry) {
			event.update(delta)
		}
	}
	
	startEvent(queuedEvent: EventTypes) {
		const definition = this.eventDefinitions[queuedEvent]

		let event = this.eventRegistry.get(queuedEvent)
		if (!event) {
			switch (queuedEvent) {
				case EventTypes.Pet:
					event = PetRescueEventSystem.getInstance()
					break
				case EventTypes.Shrine:
					event = new ShrineGameplayEvent()
					break
				case EventTypes.Goblin:
					event = GoblinGameplayEvent.getInstance()	
					break
				case EventTypes.ShamblingTower:
					event = new ShamblingTowerGameplayEvent()
					break
				case EventTypes.CombatArena:
					event = new CombatArenaGameplayEvent()
					break
				case EventTypes.FountainsOfMana:
					event = new FountainsOfManaGameplayEvent()
					break
				case EventTypes.CreepyEgg:
					event = CreepyEggGameplayEvent.getInstance()
					break
				case EventTypes.TemporalDistortion:
					event = new TemporalDistortionGameplayEvent()
					break
				case EventTypes.WildRotSons:
					event = WildRotSonEventSystem.getInstance()
					break
				case EventTypes.RoamingWildlings:
					event = RoamingWildlingsEventSystem.getInstance()
					break
				case EventTypes.ForestPlants:
					event = new ForestPlantShrineGameplayEvent()
					break
				case EventTypes.KillingForFlowers:
					event = new KillingForFlowersShrineGameplayEvent()
					break
		}

			event.setStartData(definition)

			this.eventRegistry.set(queuedEvent, event)
		}
		
		const currentActive = this.activeEvents.get(queuedEvent) || 0
		this.activeEvents.set(queuedEvent, currentActive + 1)

		this.eventCount.set(queuedEvent, this.eventCount.get(queuedEvent) + 1 || 1)
		this.queuedEvents.delete(queuedEvent)

		if (currentActive + 1 < this.eventDefinitions[queuedEvent].maxConcurrent) {
			this.queueEvent(queuedEvent)
		}

		event.startEvent()
	}

	isMaxedEventCount(event: EventTypes) {
		const count = this.activeEvents.get(event)
		if (count) {
			return count >= this.eventDefinitions[event].maxConcurrent
		}

		return false
	}

	isActive(event: EventTypes): boolean {
		return Boolean(this.activeEvents.get(event))
	}

	isExcludedFromStarting(event: EventTypes) {
		for (const excludedEvent of this.eventDefinitions[event].excludedBy) {
			if (this.activeEvents.get(excludedEvent)) {
				return true
			}
		}
		return false
	}

	addEventDefinition(eventType: EventTypes, startData: EventStartData) {
		this.eventDefinitions[eventType] = startData

		let { min, max } = startData.spawnWindow[0]
		let eventStartTime = Math.round(randomRange(min, max))

		this.queuedEvents.set(eventType, eventStartTime)
	}

	queueEvent(eventTypeToQueue: EventTypes): void {
		const event: EventStartData = this.eventDefinitions[eventTypeToQueue]

		// If the event has 1 spawn window and a 0 frequency then it can spawn almost infinitely (pets)
		// use now + eventTime + event.coolDown to control when that event will happen again
		const cd = Array.isArray(event.coolDown) ? Math.getRandomInt(event.coolDown[0], event.coolDown[1]) : event.coolDown
		if (event.frequency === 0) {
			const eventTime = this.getQueuedEventTime(event, 0)
			const now = InGameTime.timeElapsedInSeconds
			const startEventTime = Math.round(now + eventTime + cd)
			this.queuedEvents.set(eventTypeToQueue, startEventTime)
		} else {
			// check how many times this event has happened and use that number to determine the next event time if that spawn window exists
			const now = InGameTime.timeElapsedInSeconds
			let spawnCount = this.eventCount.get(eventTypeToQueue)
			let spawnTime = this.eventDefinitions[eventTypeToQueue].spawnWindow?.[spawnCount]

			if (!spawnTime) {
				return
			}
			
			let eventTime = this.getQueuedEventTime(event, spawnCount)

			// if spawn time picked is old/stale, then try and pick a new time if one exists. Update the event count so things
			// are still in sync
			if (eventTime <= this.timeToRun) {
				spawnTime = this.eventDefinitions[eventTypeToQueue].spawnWindow?.[spawnCount + 1]
				if (spawnTime) {
					this.eventCount.set(eventTypeToQueue, this.eventCount.get(eventTypeToQueue) + 1)
					spawnCount = this.eventCount.get(eventTypeToQueue)
					eventTime = this.getQueuedEventTime(event, spawnCount)
				} else {
					return
				}
			}
			const updateEventTime = Math.round(now + eventTime + cd)
			this.queuedEvents.set(eventTypeToQueue, updateEventTime)
		}
	}

	onEventEnd(eventTypeCompleted: EventTypes): void {
		const oldCount = this.activeEvents.get(eventTypeCompleted)
		if (oldCount) {
			this.activeEvents.set(eventTypeCompleted, oldCount - 1)
		}

		this.queueEvent(eventTypeCompleted)
	}

	getQueuedEventTime(event: EventStartData, eventIndex: number): number {
		const { min, max } = event.spawnWindow[eventIndex]
		return Math.round(randomRange(min, max))
	}
}
