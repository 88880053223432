import { checkIfPointIsInView, getVisibleWorldHeight, getVisibleWorldWidth } from "../engine/graphics/camera-logic"
import { ACT_TIMER, InGameTime } from "../utils/time"
import { Vector } from "sat"
import { KillEnemiesInCirclePOI, KillEnemiesInCirclePOIParams } from "../pois/kill-enemies-in-circle"
import { ObjectPoolTyped } from "../utils/third-party/object-pool"
import { isArray, sample } from "lodash"
import { allocGroundPickup, GroundPickup } from "../entities/pickups/ground-pickup"
import { angleBetweenVectors } from '../utils/vector'
import { percentage, timeInMilliseconds, timeInSeconds } from "../utils/primitive-types"
import { UI } from "../ui/ui"
import { GameState } from "../engine/game-state"
import PlayerMetricsSystem from "../metrics/metric-system"
import { AllEventTypes } from "./event-stat-types"
import { Pet, PetCollectionName, PET_DEFINITIONS, PetRarity, PET_NAMES, PET_OFFSET } from "../entities/pets/pet"
import { GameplayTimedEventSystem } from "./gameplay-timed-event-system"
import { EventStartData, GameplayEvent } from "./gameplay-event-definitions"
import { StandInCirclePOI, StandInCirclePOIParams } from "../pois/stand-in-circle"
import { CollectorPet } from "../entities/pets/collector-pet"
import { ShrineGameplayEventPOI } from "./shrine-gameplay-event"
import EnemyEquilibriumSpawner from "../entities/enemies/enemy-equilibrium-spawner"
import { VictoryDeathManager } from "../engine/victory-death-manager"
import { createNewPet } from "../entities/pets/pet-util"
import { PropPlacer } from "../world-generation/prop-placement"
import { EventTypes } from "./event-types"
import { GroundPickupConfigType } from "../entities/pickups/ground-pickup-types"
import { WildPet } from "../entities/pets/wild-pet"
import { Audio } from "../engine/audio"

// const FIRST_EVENT_DELAY = 10_000
const EVENT_RESPAWN_TIME_DELAY = 10_000
const REPOSITION_MAP_AFTER_DELAY = 5_000
const MIN_EVENT_DIST = 5_000
const MAX_EVENT_DIST = 9_600

// Actual event circle size before multipliers are applied
const DEFAULT_EVENT_CIRCLE_RADIUS = 1100

// Enemy count based on currentEventIdx
const KILL_POI_ENEMY_COUNTS: number[] = [
	45,
	60,
	80,
	100,
	130,
	165,
	200,
	250,
]

function getPoiKillMultiplierByTime() {
	const percentOfRunPassed = InGameTime.percentOfRunPassed()
	const multi = Math.lerp(1, 4, percentOfRunPassed)
	return multi
}

function getPoiKillCount(timesBeaten: number, multiplier: percentage) {
	return Math.round(5 * getPoiKillMultiplierByTime() * multiplier * KILL_POI_ENEMY_COUNTS[Math.clamp(timesBeaten, 0, KILL_POI_ENEMY_COUNTS.length - 1)] / 5)
}

const STAND_IN_CIRCLE_DURATION: timeInSeconds[] = [
	25,
	30,
	35,
	40,
	45,
]

export enum mapEventTypes {
	petRescue,
}

export class PetRescueEventSystem implements GameplayEvent {

	static getInstance() {
		if (!PetRescueEventSystem.instance) {
			PetRescueEventSystem.instance = new PetRescueEventSystem()
		}

		return PetRescueEventSystem.instance
	}
	static destroy() {
		PetRescueEventSystem.instance = null
	}
	private static instance: PetRescueEventSystem

	currentTime: InGameTime
	eventFinishedTime: InGameTime
	petRescueEventActive: boolean = false
	eventPosition: Vector = new Vector()

	availablePets: Set<PetCollectionName> = new Set()
	spawnedPets: Pet[] = []
	tailingPet: Pet = null

	petRescueEvents: ShrineGameplayEventPOI[] = []
	petRescueEventType: typeof KillEnemiesInCirclePOI | typeof StandInCirclePOI = KillEnemiesInCirclePOI

	numberOfPetsPerEvent: number = 1
	enemySpawnRateInCircle: number = 0

	currentEventIdx: number = 0
	currentPetNames: PetCollectionName[] = []
	currentPets: Pet[] = []
	petsRescuedThisEvent: number

	enemyKillAmountBonus: number = 0 

	mapPickup: GroundPickup
	mapsCollected: boolean = false
	mapEventStarted: boolean = false
	mapRepositionTimeout: timeInMilliseconds

	kickOffEvent: boolean = false

	// This has to take into account pets from perks, not just in game
	totalPets: number = 0

	constructor() {
		if (!KillEnemiesInCirclePOI.pool) {
			KillEnemiesInCirclePOI.pool = new ObjectPoolTyped<KillEnemiesInCirclePOI, KillEnemiesInCirclePOIParams>(() => new KillEnemiesInCirclePOI(), {}, 1, 1)
		}
		if (!StandInCirclePOI.pool) {
			StandInCirclePOI.pool = new ObjectPoolTyped<StandInCirclePOI, StandInCirclePOIParams>(() => new StandInCirclePOI(), {}, 1, 1)
		}
		this.addAvailablePets()
	}

	update(delta: timeInSeconds) {
		this.currentTime = InGameTime.highResolutionTimestamp()
		const elapsedTime = Number(this.currentTime) - Number(this.eventFinishedTime)

		if (this.availablePets.size > 0) {
			// Start map event
			if (this.kickOffEvent) {
				// If the map hasn't been created or collected
				if (!this.mapEventStarted && !this.mapsCollected) {
					this.mapEventStarted = true
					this.mapPickup = this.createMapPickup(this.getMapLocation())
					this.mapRepositionTimeout = Number(this.currentTime) + REPOSITION_MAP_AFTER_DELAY

					if (GameState.player.binaryFlags.has('pickup-range-nullified-during-pet-events')) {
						GameState.player.navigationArrow.setIsBlinking(true, 0.2)
					} else {
						GameState.player.navigationArrow.setIsBlinking(true, 0.7)
					}
				}

				const currentTime = Number(this.currentTime)
				// Map event has begun but we haven't picked the map up yet and it hasn't been seen for N seconds
				if (this.mapEventStarted && !this.mapsCollected && currentTime > this.mapRepositionTimeout) {
					const mapInView = checkIfPointIsInView(this.mapPickup.position)
					if (!mapInView) {
						this.mapPickup.position = this.getMapLocation()
					}
					this.mapRepositionTimeout = currentTime + REPOSITION_MAP_AFTER_DELAY
				}
			}
			// TODO DISABLE AFTER ALL PETS ARE SPAWNED TO PREVENT CRASH
			// Map collected and the rescue Event has Started nearby
			if (this.mapsCollected && !this.petRescueEventActive) {
				this.petRescueEventActive = true
				this.rollPets()
				this.setPetRescueActive()
			}

			if (this.petRescueEventActive) {
				GameState.player.navigationArrow.setDestination(this.eventPosition.x, this.eventPosition.y)
			}
		}
		if (this.petRescueEventActive && GameState.player.navigationArrow) {
			GameState.player.navigationArrow.blink(delta, 1.0)
		}

	}

	setStartData(data: EventStartData) {
		
	}

	startEvent(): void {
		const now = InGameTime.highResolutionTimestamp()
		this.eventFinishedTime = now - EVENT_RESPAWN_TIME_DELAY
		this.kickOffEvent = true
		this.petsRescuedThisEvent = 0
		if (GameState.player.binaryFlags.has('pickup-range-nullified-during-pet-events')) {
			GameState.player.binaryFlagState['pickup-range-nullified-during-pet-events'].eventStarted = true
		}
	}

	addAvailablePets() {
		for (const petName of Object.keys(PET_DEFINITIONS)) {
			this.availablePets.add(petName as PetCollectionName)
		}
	}

	setPetRescueActive() {
		this.currentPets.length = 0

		// estimate radius because I cba to calculate this
		const eventRadius = this.currentPetNames.length === 1 ? DEFAULT_EVENT_CIRCLE_RADIUS : DEFAULT_EVENT_CIRCLE_RADIUS * this.currentPetNames.length / 1.25
		this.eventPosition = PropPlacer.getInstance().getRandomValidPositionInWorld(MIN_EVENT_DIST, MAX_EVENT_DIST, eventRadius, undefined, true)

		const spawnRateIncrease = this.enemySpawnRateInCircle
		
		for (let i = 0; i < this.currentPetNames.length; ++i) {
			const petName = this.currentPetNames[i]
			const petRarity: PetRarity = PET_DEFINITIONS[petName].rarity
			const radius = DEFAULT_EVENT_CIRCLE_RADIUS * this.radiusMultFromRarity(petRarity)

			let xPos = this.eventPosition.x
			let yPos = this.eventPosition.y
			if (this.currentPetNames.length > 1) {
				const angleOff = ((Math.PI * 2) / this.currentPetNames.length) * (i+1)
				const posOff = radius / 2

				const xAngleOff = Math.cos(angleOff) * posOff
				const yAngleOff = Math.sin(angleOff) * posOff

				xPos += xAngleOff
				yPos += yAngleOff
			}

			let event: KillEnemiesInCirclePOI | StandInCirclePOI
			if (this.petRescueEventType === KillEnemiesInCirclePOI) {
				event = KillEnemiesInCirclePOI.pool.alloc({
					xPosition: xPos,
					yPosition: yPos,
					enemyCount: getPoiKillCount(this.currentEventIdx, this.enemyMultFromRarity(petRarity) + this.enemyKillAmountBonus),
					radius,
					onPlayerEnteredFn(poi, state) {
						EnemyEquilibriumSpawner.getInstance().temporarySpawnRateMulti += spawnRateIncrease
					},
					whilePlayerInZoneFn(poi, delta, state) { },
					onPlayerExitedFn(poi, state) { 
						EnemyEquilibriumSpawner.getInstance().temporarySpawnRateMulti -= spawnRateIncrease
					},
					onEventDone: this.petRescueDone.bind(this),
					state: i
				})
			} else if (this.petRescueEventType === StandInCirclePOI) {
				event = StandInCirclePOI.pool.alloc({
					xPosition: xPos,
					yPosition: yPos,
					duration: STAND_IN_CIRCLE_DURATION[Math.clamp(this.currentEventIdx, 0, STAND_IN_CIRCLE_DURATION.length - 1)] * (this.enemyMultFromRarity(petRarity) + this.enemyKillAmountBonus),
					radius,
					onPlayerEnteredFn(poi, state) {
						EnemyEquilibriumSpawner.getInstance().temporarySpawnRateMulti += spawnRateIncrease
					},
					whilePlayerInZoneFn(poi, delta, state) { },
					onPlayerExitedFn(poi, state) { 
						EnemyEquilibriumSpawner.getInstance().temporarySpawnRateMulti -= spawnRateIncrease
					},
					onEventDone: this.petRescueDone.bind(this),
					state: i
				})
			}

			const pet = this.makePet(petName, new Vector(xPos, yPos))
			this.currentPets.push(pet)

			this.petRescueEvents.push(event)
		}
		
		this.currentEventIdx++
	}

	petRescueDone(poi: any, state: any) {

		// this.eventFinishedTime = InGameTime.highResolutionTimestamp()
		const pet = this.currentPets[state]
		PlayerMetricsSystem.getInstance().trackMetric('EVENT_COMPLETED', AllEventTypes.PETS_RESCUE)
		pet.releaseFromCage()
		const player = GameState.player

		player.bonusesAcquired.push(pet.name)
		this.petsRescuedThisEvent++
		this.totalPets += 1

		UI.getInstance().emitAction('ui/addCollectedPet', { petName: pet.name, totalPets: this.totalPets })

		if (this.spawnedPets.length > 3) {
			// shrink the target distance if we got a lot of pets
			const targetDistance = PET_OFFSET * 3 / this.spawnedPets.length
			this.spawnedPets.forEach((pet) => {
				pet.targetDistance = targetDistance
			})
		}
		
		if (this.petsRescuedThisEvent === this.currentPets.length) {
			this.petRescueEventActive = false
			GameState.player.navigationArrow.setIsShowing(false)

			this.mapEventStarted = false
			this.mapsCollected = false
			this.kickOffEvent = false
			if (GameState.player.binaryFlags.has('pickup-range-nullified-during-pet-events')) {
				GameState.player.binaryFlagState['pickup-range-nullified-during-pet-events'].eventStarted = false
			}
			GameplayTimedEventSystem.getInstance().onEventEnd(EventTypes.Pet)
			PlayerMetricsSystem.getInstance().trackMetric("PET_ADDED", pet.name)
		}

		Audio.getInstance().playSfx('SFX_Pet_Rescue_Complete');
	}

	createAndAddPet(name?: PetCollectionName, showNotification = true) {
		const player = GameState.player
		if (!name) {
			name = sample(PET_NAMES)
		}
		const pet = this.makePet(name, GameState.player.position, true)
		pet.releaseFromCage(undefined, true)
		player.bonusesAcquired.push(name)
		this.totalPets += 1
		if (showNotification) {
			UI.getInstance().emitAction('ui/addCollectedPet', { petName: name, totalPets: this.totalPets })
		}
		PlayerMetricsSystem.getInstance().trackMetric("PET_ADDED", pet.name)
	}

	tameWildPet(wildPet: WildPet) {
		const player = GameState.player
		const pet = wildPet.pet
		pet.isWildPet = false
		pet.blockAbility = false
		pet.targetDistance =  PET_OFFSET
		pet.dampedLerp = true

		this.spawnedPets.push(pet)
		pet.releaseFromCage(undefined, true)
		player.bonusesAcquired.push(pet.name)
		this.totalPets += 1
		UI.getInstance().emitAction('ui/addCollectedPet', { petName: pet.name, totalPets: this.totalPets })
		PlayerMetricsSystem.getInstance().trackMetric("PET_ADDED", pet.name)
	}

	setFollowingPets(names: PetCollectionName[]) {
		this.spawnedPets.forEach((pet) => {
			if (pet.petFollow) {
				GameState.player.bonusesAcquired.remove(pet.name)
				this.spawnedPets.remove(pet)
				pet.destroy()
				if (this.tailingPet.nid === pet.nid) {
					this.tailingPet = null
				}
			}
		})

		if (!isArray(names)) {
			names = [names]
		}

		for (let i = 0; i < names.length; ++i) {
			this.createAndAddPet(names[i])
		}
	}

	rollPets() {
		this.currentPetNames.length = 0
		for (let i =0; i < this.numberOfPetsPerEvent; ++i) {
			const randomPet = sample(Array.from(this.availablePets))
			this.currentPetNames.push(randomPet)
			this.availablePets.delete(randomPet)
			if (this.availablePets.size === 0) {
				this.addAvailablePets()
			}
		}
	}

	getMapLocation(): Vector {
		let directionRads
		const player = GameState.player
		// if player was recently moving, put it in front of them
		if (player.position !== player.previousPosition) {
			directionRads = angleBetweenVectors(player.previousPosition, player.position)
		} else {
			// otherwise just pick a direction
			directionRads = Math.PI * 2 * Math.random()
		}
		const xComponent = Math.cos(directionRads)
		const yComponent = Math.sin(directionRads)

		return new Vector(player.x + (xComponent * getVisibleWorldWidth()), player.y + (yComponent * getVisibleWorldHeight()))
	}

	private createMapPickup(mapPosition: Vector): GroundPickup {
		const pickup = allocGroundPickup(GroundPickupConfigType.Map,
			mapPosition.x,
			mapPosition.y,
			0,
			0,
		)
		if (GameState.player.binaryFlags.has('auto-magnet-pet-ransom-notes')) {
			pickup.onPickedUp(GameState.player)
		}
		return pickup
	}

	allMapsCollected() {
		this.mapsCollected = true
		if (VictoryDeathManager.victoryOrDeathCalled) {
			return
		}
		UI.getInstance().emitAction('ui/mapCollected', { eventType: mapEventTypes.petRescue })
	}

	makePet(name: PetCollectionName, position: Vector, uncaged?: boolean): Pet {
		const pet = createNewPet(name, position, GameState.player.petStatList, uncaged)
		this.spawnedPets.push(pet)
		return pet
	}

	radiusMultFromRarity(rarity: PetRarity) {
		switch (rarity) {
			case 'normal':
				return 1
			case 'epic':
				return 1
			case 'legendary':
				return 1.2
		}
	}

	enemyMultFromRarity(rarity: PetRarity) {
		switch (rarity) {
			case 'normal':
				return 1
			case 'epic':
				return 1.2
			case 'legendary':
				return 1.5
		}
	}
}