{"frames": {

"event-a-broken-heart-weeps-open.png":
{
	"frame": {"x":543,"y":3,"w":59,"h":49},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":13,"w":59,"h":49},
	"sourceSize": {"w":66,"h":64}
},
"event-big-brain-learning.png":
{
	"frame": {"x":277,"y":3,"w":61,"h":44},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":11,"w":61,"h":44},
	"sourceSize": {"w":66,"h":64}
},
"event-bullet-heaven.png":
{
	"frame": {"x":507,"y":234,"w":61,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":3,"w":61,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"event-chain-reaction.png":
{
	"frame": {"x":73,"y":3,"w":62,"h":36},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":16,"w":62,"h":36},
	"sourceSize": {"w":66,"h":64}
},
"event-close-and-bloody.png":
{
	"frame": {"x":578,"y":1146,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"event-demigod-of-thunder.png":
{
	"frame": {"x":329,"y":672,"w":66,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":62},
	"sourceSize": {"w":66,"h":62}
},
"event-faster-than-before.png":
{
	"frame": {"x":3,"y":3,"w":66,"h":33},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":18,"w":66,"h":33},
	"sourceSize": {"w":66,"h":64}
},
"event-forest-fire.png":
{
	"frame": {"x":648,"y":1146,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"event-hyper-pets.png":
{
	"frame": {"x":332,"y":542,"w":65,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":65,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"event-icy-come-icy-go.png":
{
	"frame": {"x":311,"y":1137,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"event-id-rather-be-rich.png":
{
	"frame": {"x":132,"y":539,"w":60,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":3,"w":60,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"event-jokers-wild.png":
{
	"frame": {"x":584,"y":56,"w":66,"h":54},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":6,"w":66,"h":54},
	"sourceSize": {"w":66,"h":64}
},
"event-master-of-elements.png":
{
	"frame": {"x":186,"y":867,"w":64,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":64,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"event-more-gain-more-pain.png":
{
	"frame": {"x":705,"y":298,"w":66,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"event-stronger-better-faster-longer.png":
{
	"frame": {"x":449,"y":1144,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"grand-pike-01.png":
{
	"frame": {"x":254,"y":868,"w":60,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":60,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"icon-cannon.png":
{
	"frame": {"x":513,"y":1145,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"icon-damage-over-time.png":
{
	"frame": {"x":654,"y":56,"w":63,"h":54},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":6,"w":63,"h":54},
	"sourceSize": {"w":66,"h":64}
},
"twist--harsh-rejection.png":
{
	"frame": {"x":718,"y":1147,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-a-branching-strategy.png":
{
	"frame": {"x":845,"y":815,"w":49,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":0,"w":49,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-a-link-between-enemies.png":
{
	"frame": {"x":176,"y":1202,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-a-risky-morning-run.png":
{
	"frame": {"x":464,"y":549,"w":66,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"twist-a-shocking-number-of-explosions.png":
{
	"frame": {"x":379,"y":1208,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-a-surprising-climax.png":
{
	"frame": {"x":602,"y":550,"w":65,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":65,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"twist-a-vast-arsenal.png":
{
	"frame": {"x":327,"y":108,"w":66,"h":55},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":55},
	"sourceSize": {"w":66,"h":64}
},
"twist-ace-of-scrolls.png":
{
	"frame": {"x":118,"y":1133,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-an-educational-field-trip.png":
{
	"frame": {"x":576,"y":1214,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-an-executive-gambit.png":
{
	"frame": {"x":128,"y":158,"w":66,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"twist-attack-of-the-giant-shamblers.png":
{
	"frame": {"x":775,"y":57,"w":66,"h":54},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":66,"h":54},
	"sourceSize": {"w":66,"h":64}
},
"twist-bad-eyes.png":
{
	"frame": {"x":342,"y":3,"w":66,"h":44},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":17,"w":66,"h":44},
	"sourceSize": {"w":66,"h":64}
},
"twist-berserker.png":
{
	"frame": {"x":781,"y":1151,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-better-go-fast.png":
{
	"frame": {"x":323,"y":167,"w":66,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"twist-big-brain-frail-body.png":
{
	"frame": {"x":973,"y":236,"w":39,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":14,"y":1,"w":39,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"twist-bloated-blimpies.png":
{
	"frame": {"x":244,"y":1203,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-butter-fingers.png":
{
	"frame": {"x":845,"y":1157,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-chained-together.png":
{
	"frame": {"x":3,"y":1125,"w":55,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":55,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-combat-arena.png":
{
	"frame": {"x":906,"y":1159,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-deferred-learning.png":
{
	"frame": {"x":572,"y":234,"w":62,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":4,"w":62,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"twist-diabolical-furniture.png":
{
	"frame": {"x":3,"y":594,"w":52,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":2,"w":52,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"twist-dont-drop-the-egg.png":
{
	"frame": {"x":68,"y":859,"w":50,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":0,"w":50,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-dramatic-showdown.png":
{
	"frame": {"x":646,"y":1214,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-eating-their-wheaties.png":
{
	"frame": {"x":309,"y":1273,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-elemental-maelstrom.png":
{
	"frame": {"x":393,"y":168,"w":63,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":5,"w":63,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"twist-elixir-of-something.png":
{
	"frame": {"x":310,"y":1205,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-enraged.png":
{
	"frame": {"x":671,"y":551,"w":66,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"twist-eyes-filled-with-rage.png":
{
	"frame": {"x":517,"y":173,"w":66,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"twist-falling-folk-dance.png":
{
	"frame": {"x":775,"y":298,"w":66,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"twist-favorite-toy.png":
{
	"frame": {"x":63,"y":1063,"w":52,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":0,"w":52,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-floor-is-lava.png":
{
	"frame": {"x":175,"y":1270,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-for-want-of-a-boom.png":
{
	"frame": {"x":65,"y":995,"w":51,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":0,"w":51,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-fountains-of-mana.png":
{
	"frame": {"x":379,"y":1276,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-giant-shamblers-everyday-carry.png":
{
	"frame": {"x":572,"y":1282,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-goblin-season-is-open.png":
{
	"frame": {"x":641,"y":1282,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-going-nuclear.png":
{
	"frame": {"x":709,"y":1283,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-hardcore-survival.png":
{
	"frame": {"x":172,"y":1338,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-head-start.png":
{
	"frame": {"x":307,"y":1341,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-hearts-explosion.png":
{
	"frame": {"x":377,"y":1344,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-infestation.png":
{
	"frame": {"x":569,"y":1350,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-insect-fever.png":
{
	"frame": {"x":639,"y":1350,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-intelligence-was-my-dumb-stat.png":
{
	"frame": {"x":116,"y":1201,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-killing-for-flowers.png":
{
	"frame": {"x":641,"y":174,"w":66,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"twist-king-shriekers.png":
{
	"frame": {"x":976,"y":691,"w":43,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":11,"y":0,"w":43,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-legends-never-die.png":
{
	"frame": {"x":709,"y":1351,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-lil-best-friend.png":
{
	"frame": {"x":651,"y":114,"w":66,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"twist-little-plague-bearers.png":
{
	"frame": {"x":449,"y":1212,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-lootstreak.png":
{
	"frame": {"x":66,"y":340,"w":51,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":0,"w":51,"h":60},
	"sourceSize": {"w":66,"h":60}
},
"twist-making-new-friends.png":
{
	"frame": {"x":62,"y":1131,"w":52,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":0,"w":52,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-masters-degree.png":
{
	"frame": {"x":721,"y":115,"w":66,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"twist-mind-over-matter.png":
{
	"frame": {"x":412,"y":3,"w":66,"h":44},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":10,"w":66,"h":44},
	"sourceSize": {"w":66,"h":64}
},
"twist-monster-merge-game.png":
{
	"frame": {"x":172,"y":1406,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-monster-whistle.png":
{
	"frame": {"x":3,"y":1193,"w":55,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":55,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-narrow-focus.png":
{
	"frame": {"x":574,"y":296,"w":59,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":5,"w":59,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"twist-not-quite-wings.png":
{
	"frame": {"x":307,"y":1409,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-oh-approaching-me.png":
{
	"frame": {"x":377,"y":1412,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-paranormal-exercise.png":
{
	"frame": {"x":741,"y":551,"w":66,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"twist-personal-mastery.png":
{
	"frame": {"x":569,"y":1418,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-pet-jail-break.png":
{
	"frame": {"x":388,"y":873,"w":61,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":1,"w":61,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"twist-pet-survival.png":
{
	"frame": {"x":266,"y":541,"w":62,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":62,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"twist-pinball.png":
{
	"frame": {"x":399,"y":675,"w":54,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":2,"w":54,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"twist-positive-choreo.png":
{
	"frame": {"x":457,"y":679,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"twist-power-overwhelming.png":
{
	"frame": {"x":637,"y":1418,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-prestigious-treasure-trove.png":
{
	"frame": {"x":586,"y":878,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"twist-punch-through.png":
{
	"frame": {"x":707,"y":1419,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-quick-hands.png":
{
	"frame": {"x":511,"y":1213,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-random-ricochet.png":
{
	"frame": {"x":716,"y":1215,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-ransom-situation.png":
{
	"frame": {"x":638,"y":235,"w":63,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":63,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"twist-razorshar-snowfall.png":
{
	"frame": {"x":778,"y":1219,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-roaming-wildlings.png":
{
	"frame": {"x":527,"y":679,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"twist-running-of-the-shriekers.png":
{
	"frame": {"x":841,"y":424,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"twist-scrap-ing-by.png":
{
	"frame": {"x":243,"y":1271,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-scrapyard.png":
{
	"frame": {"x":967,"y":1160,"w":51,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":0,"w":51,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-shambling-towers.png":
{
	"frame": {"x":102,"y":1473,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-shielded-lifestyle.png":
{
	"frame": {"x":509,"y":1281,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-shrieker-chaos.png":
{
	"frame": {"x":844,"y":1225,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-shriekers-shrieking.png":
{
	"frame": {"x":171,"y":1474,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-skillful-evasion.png":
{
	"frame": {"x":807,"y":883,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"twist-skillfull-moves.png":
{
	"frame": {"x":306,"y":1477,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-smash-the-whole-world.png":
{
	"frame": {"x":877,"y":889,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"twist-spectral-farmer.png":
{
	"frame": {"x":906,"y":1227,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-speed-drafting.png":
{
	"frame": {"x":375,"y":1480,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-spicy-pepper.png":
{
	"frame": {"x":114,"y":1269,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-split-personality.png":
{
	"frame": {"x":3,"y":153,"w":54,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":7,"w":54,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"twist-spooky-ghosts.png":
{
	"frame": {"x":569,"y":1486,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-spring-time-dancing.png":
{
	"frame": {"x":778,"y":1287,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-stop-drop-roll.png":
{
	"frame": {"x":534,"y":549,"w":64,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":64,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"twist-temporal-distortion.png":
{
	"frame": {"x":108,"y":1337,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-the-bigger-they-are.png":
{
	"frame": {"x":242,"y":1339,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-the-brute-trio.png":
{
	"frame": {"x":705,"y":236,"w":66,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"twist-they-arent-stopping.png":
{
	"frame": {"x":397,"y":109,"w":66,"h":55},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":55},
	"sourceSize": {"w":66,"h":64}
},
"twist-twice-dead.png":
{
	"frame": {"x":637,"y":297,"w":64,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":64,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"twist-violence-rinse-repeat.png":
{
	"frame": {"x":843,"y":1293,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-violent-epiphany.png":
{
	"frame": {"x":778,"y":1355,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-whats-that-noise.png":
{
	"frame": {"x":637,"y":1486,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-wild-rot-sons.png":
{
	"frame": {"x":50,"y":1541,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"twist-zoomies.png":
{
	"frame": {"x":330,"y":51,"w":60,"h":53},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":10,"w":60,"h":53},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-2-way-split.png":
{
	"frame": {"x":449,"y":1280,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-3-round-burst.png":
{
	"frame": {"x":842,"y":1361,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-better-poison.png":
{
	"frame": {"x":947,"y":891,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-bigger-bolt.png":
{
	"frame": {"x":776,"y":1423,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-brew-so-foul.png":
{
	"frame": {"x":68,"y":927,"w":48,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":0,"w":48,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-horrible-night-for-a-curse.png":
{
	"frame": {"x":707,"y":1487,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-lil-spice.png":
{
	"frame": {"x":108,"y":1405,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-little-more-coating.png":
{
	"frame": {"x":65,"y":468,"w":64,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":4,"w":64,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-quick-reaction-to-a-good-burn.png":
{
	"frame": {"x":316,"y":936,"w":65,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":65,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-shockingly-good-time.png":
{
	"frame": {"x":789,"y":3,"w":59,"h":50},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":11,"w":59,"h":50},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-very-personal-bottle-of-poison.png":
{
	"frame": {"x":845,"y":298,"w":63,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":63,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-wall-of-broken-flesh.png":
{
	"frame": {"x":597,"y":680,"w":65,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":2,"w":65,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-a-wind-named-doom.png":
{
	"frame": {"x":711,"y":175,"w":66,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":66,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-absolute-units.png":
{
	"frame": {"x":240,"y":1475,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-accelerated-technology.png":
{
	"frame": {"x":121,"y":342,"w":61,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":5,"w":61,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-acceleration.png":
{
	"frame": {"x":198,"y":165,"w":59,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":1,"w":59,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-acidic-purification.png":
{
	"frame": {"x":775,"y":1491,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-acrobatics.png":
{
	"frame": {"x":186,"y":934,"w":64,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":64,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-adding-a-little-something-something.png":
{
	"frame": {"x":971,"y":1092,"w":49,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":0,"w":49,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-aether-magnet.png":
{
	"frame": {"x":3,"y":529,"w":58,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":3,"w":58,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-aggressive-posturing.png":
{
	"frame": {"x":450,"y":943,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-aikido-darling.png":
{
	"frame": {"x":310,"y":351,"w":66,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-all-about-that-size.png":
{
	"frame": {"x":666,"y":681,"w":58,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":2,"w":58,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-amped-up.png":
{
	"frame": {"x":811,"y":552,"w":66,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-an-acid-ent-waiting-to-happen.png":
{
	"frame": {"x":120,"y":1542,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-angular-momentum.png":
{
	"frame": {"x":239,"y":1543,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ankle-height-terror.png":
{
	"frame": {"x":372,"y":1548,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-anything-but-basic.png":
{
	"frame": {"x":3,"y":660,"w":52,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":2,"w":52,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-arc-reactor.png":
{
	"frame": {"x":306,"y":1545,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-arcane-rain.png":
{
	"frame": {"x":881,"y":553,"w":64,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-aristotles-lack-of-motion.png":
{
	"frame": {"x":242,"y":1407,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-assassins-arch-final-form.png":
{
	"frame": {"x":728,"y":681,"w":55,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":55,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-attack-of-the-killer-wasps.png":
{
	"frame": {"x":120,"y":932,"w":62,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":62,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-balm-of-the-night-sky.png":
{
	"frame": {"x":403,"y":2098,"w":65,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":66},
	"sourceSize": {"w":65,"h":66}
},
"upgrade-banditry.png":
{
	"frame": {"x":3,"y":339,"w":59,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":59,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-barbed-arrows.png":
{
	"frame": {"x":606,"y":3,"w":62,"h":49},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":9,"w":62,"h":49},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bardcore.png":
{
	"frame": {"x":775,"y":236,"w":66,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":6,"w":66,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-battery-quickcharge.png":
{
	"frame": {"x":401,"y":545,"w":59,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":3,"w":59,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bees-forever.png":
{
	"frame": {"x":842,"y":1429,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-beestorm.png":
{
	"frame": {"x":845,"y":57,"w":66,"h":54},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":54},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-better-grips.png":
{
	"frame": {"x":67,"y":277,"w":51,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":5,"w":51,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-better-living-through-poison.png":
{
	"frame": {"x":585,"y":945,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-better-powder-better-boom.png":
{
	"frame": {"x":949,"y":561,"w":66,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-big-stick.png":
{
	"frame": {"x":845,"y":236,"w":63,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":6,"w":63,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bigger-beam.png":
{
	"frame": {"x":906,"y":1295,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bigger-is-better.png":
{
	"frame": {"x":202,"y":50,"w":64,"h":52},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":8,"w":64,"h":52},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-biggest-beehive.png":
{
	"frame": {"x":569,"y":1554,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-blazing-speed.png":
{
	"frame": {"x":638,"y":1554,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-blimpie-ba-boom-ordinances.png":
{
	"frame": {"x":445,"y":1484,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-blood-soaked-bullets.png":
{
	"frame": {"x":787,"y":682,"w":55,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":2,"w":55,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-body-builder.png":
{
	"frame": {"x":781,"y":175,"w":63,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":7,"w":63,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-boiling-blood.png":
{
	"frame": {"x":3,"y":214,"w":50,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":6,"w":50,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bone-conduction.png":
{
	"frame": {"x":472,"y":2098,"w":67,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":67,"h":66},
	"sourceSize": {"w":67,"h":66}
},
"upgrade-bonus-bullet.png":
{
	"frame": {"x":721,"y":56,"w":50,"h":54},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":6,"w":50,"h":54},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-boom.png":
{
	"frame": {"x":842,"y":1497,"w":60,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":60,"h":64},
	"sourceSize": {"w":60,"h":64}
},
"upgrade-boomerang-final-form.png":
{
	"frame": {"x":791,"y":115,"w":66,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":8,"w":66,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-booming-steps.png":
{
	"frame": {"x":706,"y":1555,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-boosted-lightning.png":
{
	"frame": {"x":774,"y":1559,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bounce-a-rang.png":
{
	"frame": {"x":261,"y":166,"w":58,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":5,"w":58,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-bounce-house.png":
{
	"frame": {"x":3,"y":1609,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-brewers-choice.png":
{
	"frame": {"x":447,"y":1348,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-brighter-than-a-shooting-star.png":
{
	"frame": {"x":442,"y":1552,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-burn-a-rang.png":
{
	"frame": {"x":846,"y":683,"w":58,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":2,"w":58,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-burn-brighter.png":
{
	"frame": {"x":655,"y":945,"w":65,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":65,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-burn-burn-burn.png":
{
	"frame": {"x":3,"y":1533,"w":43,"h":68},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":43,"h":68},
	"sourceSize": {"w":43,"h":68}
},
"upgrade-careful shooter.png":
{
	"frame": {"x":133,"y":475,"w":61,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":61,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-cast-a-cruel-net.png":
{
	"frame": {"x":509,"y":1349,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chain-distance.png":
{
	"frame": {"x":62,"y":1199,"w":50,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":0,"w":50,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chain-impact.png":
{
	"frame": {"x":3,"y":1261,"w":55,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":0,"w":55,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chain-infinitum.png":
{
	"frame": {"x":447,"y":1416,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chain-recursion.png":
{
	"frame": {"x":73,"y":1610,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chaining-attacks.png":
{
	"frame": {"x":967,"y":1228,"w":47,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":0,"w":47,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-charged-up.png":
{
	"frame": {"x":140,"y":1610,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chomp-bite-force.png":
{
	"frame": {"x":861,"y":115,"w":66,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":66,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chomp-cant-keep-a-good-dog-down.png":
{
	"frame": {"x":724,"y":946,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chomp-getting-rough.png":
{
	"frame": {"x":3,"y":924,"w":61,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":1,"w":61,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chomp-relentless.png":
{
	"frame": {"x":908,"y":691,"w":64,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":64,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chomp-vicious.png":
{
	"frame": {"x":3,"y":726,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-chonkers.png":
{
	"frame": {"x":842,"y":1565,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-cloudburst.png":
{
	"frame": {"x":671,"y":2103,"w":56,"h":67},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":56,"h":67},
	"sourceSize": {"w":56,"h":67}
},
"upgrade-coil-tower.png":
{
	"frame": {"x":794,"y":950,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-cold-and-ready-to-die.png":
{
	"frame": {"x":963,"y":1568,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-comet-blast.png":
{
	"frame": {"x":328,"y":2164,"w":61,"h":71},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":71},
	"sourceSize": {"w":61,"h":71}
},
"upgrade-conflagration.png":
{
	"frame": {"x":208,"y":1611,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-continuing-chains.png":
{
	"frame": {"x":274,"y":1613,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-cornucopia.png":
{
	"frame": {"x":186,"y":349,"w":62,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":62,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-corrosive-wasps.png":
{
	"frame": {"x":385,"y":940,"w":61,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":61,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-crowd-hopper.png":
{
	"frame": {"x":341,"y":1616,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-cupids-recycling.png":
{
	"frame": {"x":198,"y":477,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-damage-damage-resolution.png":
{
	"frame": {"x":864,"y":956,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-damage-vault.png":
{
	"frame": {"x":251,"y":413,"w":55,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":4,"w":55,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-damaging-mark.png":
{
	"frame": {"x":906,"y":1363,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-dance-forever-with-the-fairies.png":
{
	"frame": {"x":262,"y":2160,"w":62,"h":69},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":69},
	"sourceSize": {"w":62,"h":69}
},
"upgrade-death-on-rails.png":
{
	"frame": {"x":406,"y":1620,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-death-storm.png":
{
	"frame": {"x":268,"y":477,"w":60,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":60,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-deep-breaths-and-itchy-triggers.png":
{
	"frame": {"x":966,"y":1296,"w":51,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":0,"w":51,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-deliberate-damage.png":
{
	"frame": {"x":934,"y":958,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-destructive-rollout.png":
{
	"frame": {"x":59,"y":596,"w":62,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":3,"w":62,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-discharge-capacitor.png":
{
	"frame": {"x":509,"y":1417,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-divine-punishment.png":
{
	"frame": {"x":467,"y":1621,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-do-those-rats-have-knives.png":
{
	"frame": {"x":332,"y":478,"w":65,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":4,"w":65,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-dog-of-all-trades.png":
{
	"frame": {"x":537,"y":1622,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-doom-mark.png":
{
	"frame": {"x":62,"y":1267,"w":48,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":0,"w":48,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-double-flurry.png":
{
	"frame": {"x":380,"y":352,"w":61,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":61,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-double-strike.png":
{
	"frame": {"x":401,"y":481,"w":60,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":60,"h":60},
	"sourceSize": {"w":60,"h":60}
},
"upgrade-double-tap.png":
{
	"frame": {"x":125,"y":604,"w":63,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":63,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-dragon-whisker-poison.png":
{
	"frame": {"x":605,"y":1622,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-dripping-with-venom.png":
{
	"frame": {"x":507,"y":358,"w":59,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":59,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-dry-heat.png":
{
	"frame": {"x":671,"y":1623,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-effective-mark.png":
{
	"frame": {"x":732,"y":1627,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-electrical-discharge.png":
{
	"frame": {"x":530,"y":2169,"w":62,"h":72},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":72},
	"sourceSize": {"w":62,"h":72}
},
"upgrade-elemental-consistency.png":
{
	"frame": {"x":797,"y":1633,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ember-charm.png":
{
	"frame": {"x":863,"y":1635,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-eminence-of-equinox.png":
{
	"frame": {"x":866,"y":2112,"w":74,"h":68},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":74,"h":68},
	"sourceSize": {"w":74,"h":68}
},
"upgrade-essence-loaded.png":
{
	"frame": {"x":180,"y":1001,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-even-more-bullets.png":
{
	"frame": {"x":467,"y":109,"w":50,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":7,"w":50,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-everyone-gets-bees.png":
{
	"frame": {"x":311,"y":1003,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-assassins-arch.png":
{
	"frame": {"x":523,"y":877,"w":59,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":59,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-atomic-back-blast-cannon.png":
{
	"frame": {"x":933,"y":1636,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-dragon-tongue-cannon.png":
{
	"frame": {"x":3,"y":1677,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-gem-dragons-breath.png":
{
	"frame": {"x":73,"y":1678,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-gilded-greatbow.png":
{
	"frame": {"x":143,"y":1678,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-holy-spear.png":
{
	"frame": {"x":205,"y":1679,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-longbow.png":
{
	"frame": {"x":267,"y":1681,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-naginata.png":
{
	"frame": {"x":570,"y":359,"w":61,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":61,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-organic-cluster-launcher.png":
{
	"frame": {"x":330,"y":1684,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-parasitic-hive-launcher.png":
{
	"frame": {"x":465,"y":485,"w":63,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":63,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-ricochet-rang.png":
{
	"frame": {"x":394,"y":1688,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-evolve-stormbreaker.png":
{
	"frame": {"x":461,"y":1689,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-expert-marksmanship.png":
{
	"frame": {"x":3,"y":1329,"w":53,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":11,"y":0,"w":53,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-expertise-in-action.png":
{
	"frame": {"x":254,"y":935,"w":58,"h":63},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":58,"h":63},
	"sourceSize": {"w":58,"h":63}
},
"upgrade-explosive-rush.png":
{
	"frame": {"x":635,"y":360,"w":62,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":4,"w":62,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-extra-deep-vials.png":
{
	"frame": {"x":984,"y":3,"w":25,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":21,"y":0,"w":25,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-extra-steps.png":
{
	"frame": {"x":912,"y":236,"w":57,"h":58},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":57,"h":58},
	"sourceSize": {"w":57,"h":58}
},
"upgrade-extreme-force.png":
{
	"frame": {"x":125,"y":734,"w":59,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":2,"w":59,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fairy-fireworks.png":
{
	"frame": {"x":668,"y":2179,"w":72,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":72,"h":74},
	"sourceSize": {"w":72,"h":74}
},
"upgrade-fairy-flame-on.png":
{
	"frame": {"x":393,"y":2168,"w":60,"h":71},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":60,"h":71},
	"sourceSize": {"w":60,"h":71}
},
"upgrade-fairy-flames-forever.png":
{
	"frame": {"x":801,"y":2111,"w":61,"h":67},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":67},
	"sourceSize": {"w":61,"h":67}
},
"upgrade-fast-acting-poison.png":
{
	"frame": {"x":3,"y":277,"w":60,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":4,"w":60,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fast-cuts.png":
{
	"frame": {"x":3,"y":94,"w":61,"h":55},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":9,"w":61,"h":55},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fast-learner.png":
{
	"frame": {"x":701,"y":361,"w":60,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":4,"w":60,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fast-rolls.png":
{
	"frame": {"x":188,"y":735,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-faster-attacks.png":
{
	"frame": {"x":523,"y":1690,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-faster-shocks.png":
{
	"frame": {"x":381,"y":1007,"w":65,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":65,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fat-rat.png":
{
	"frame": {"x":586,"y":1690,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fatal-speed.png":
{
	"frame": {"x":656,"y":1691,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fearsome-roar.png":
{
	"frame": {"x":192,"y":605,"w":65,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":65,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-final-form-chakram.png":
{
	"frame": {"x":724,"y":1695,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fire-storm.png":
{
	"frame": {"x":906,"y":1431,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-firebird-flock.png":
{
	"frame": {"x":790,"y":1701,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-firepower-overload.png":
{
	"frame": {"x":860,"y":1703,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fission-magic.png":
{
	"frame": {"x":450,"y":1010,"w":64,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":64,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-flame-bright.png":
{
	"frame": {"x":929,"y":1704,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-flame-fast.png":
{
	"frame": {"x":966,"y":1364,"w":53,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":53,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-flames-burn-all-night.png":
{
	"frame": {"x":205,"y":2157,"w":53,"h":71},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":53,"h":71},
	"sourceSize": {"w":53,"h":71}
},
"upgrade-flash-of-brilliance.png":
{
	"frame": {"x":320,"y":738,"w":75,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":75,"h":62},
	"sourceSize": {"w":75,"h":62}
},
"upgrade-fleet-flooted.png":
{
	"frame": {"x":71,"y":43,"w":64,"h":51},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":8,"w":64,"h":51},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-focal-prism.png":
{
	"frame": {"x":394,"y":51,"w":64,"h":53},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":11,"w":64,"h":53},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-for-doom-the-bell-tolls.png":
{
	"frame": {"x":584,"y":1012,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-fortississimo.png":
{
	"frame": {"x":765,"y":361,"w":61,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":4,"w":61,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-frenetic-activity.png":
{
	"frame": {"x":3,"y":1745,"w":60,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":60,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-full-auto.png":
{
	"frame": {"x":520,"y":944,"w":61,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":1,"w":61,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-gatling-sorceries.png":
{
	"frame": {"x":67,"y":1746,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-getting-into-rat-trouble.png":
{
	"frame": {"x":532,"y":485,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-gloom-and-doom.png":
{
	"frame": {"x":261,"y":606,"w":66,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-gotta-catch-em-all.png":
{
	"frame": {"x":3,"y":991,"w":58,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":1,"w":58,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-gotta-make-em-bleed.png":
{
	"frame": {"x":331,"y":607,"w":65,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":65,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-grand-finale.png":
{
	"frame": {"x":60,"y":1335,"w":44,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":11,"y":0,"w":44,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-grand-pike-final-form.png":
{
	"frame": {"x":654,"y":1012,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-grand-tower.png":
{
	"frame": {"x":136,"y":1746,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-great-tone.png":
{
	"frame": {"x":848,"y":175,"w":62,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":7,"w":62,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-greater-skyfire.png":
{
	"frame": {"x":457,"y":2168,"w":69,"h":71},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":69,"h":71},
	"sourceSize": {"w":69,"h":71}
},
"upgrade-grenadier-training.png":
{
	"frame": {"x":724,"y":1013,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ground-shaker-ammo.png":
{
	"frame": {"x":915,"y":57,"w":65,"h":54},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":7,"w":65,"h":54},
	"sourceSize": {"w":66,"h":61}
},
"upgrade-growth-hormone.png":
{
	"frame": {"x":523,"y":1758,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-hangtime-juggle.png":
{
	"frame": {"x":3,"y":1397,"w":48,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":10,"y":0,"w":48,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-hard-hitting-rangs.png":
{
	"frame": {"x":656,"y":1759,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-hard-knocks.png":
{
	"frame": {"x":789,"y":1769,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-have-heart.png":
{
	"frame": {"x":329,"y":1752,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-heavy-hitting.png":
{
	"frame": {"x":914,"y":175,"w":66,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":66,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-heist.png":
{
	"frame": {"x":463,"y":614,"w":63,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":63,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-hemorrhagic-arrows.png":
{
	"frame": {"x":509,"y":1485,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-accuracy-shots.png":
{
	"frame": {"x":258,"y":736,"w":58,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":58,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-capacity-battery.png":
{
	"frame": {"x":457,"y":745,"w":60,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":2,"w":60,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-density-energy.png":
{
	"frame": {"x":966,"y":1432,"w":54,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":54,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-efficacy-lasers.png":
{
	"frame": {"x":120,"y":999,"w":56,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":1,"w":56,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-precision-torch.png":
{
	"frame": {"x":857,"y":1771,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-priority-targets.png":
{
	"frame": {"x":521,"y":745,"w":62,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":62,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-speed-plunderer.png":
{
	"frame": {"x":931,"y":115,"w":60,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":6,"w":60,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-high-tide-blessing.png":
{
	"frame": {"x":57,"y":216,"w":62,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":7,"w":62,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-holy-light.png":
{
	"frame": {"x":830,"y":361,"w":66,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-holy-spear-final-form.png":
{
	"frame": {"x":587,"y":746,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-hungry-hungry-doggo.png":
{
	"frame": {"x":521,"y":113,"w":57,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":4,"w":57,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-hustle-up.png":
{
	"frame": {"x":270,"y":51,"w":56,"h":52},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":9,"w":56,"h":52},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-i-like-em-when-they-stopmoving.png":
{
	"frame": {"x":507,"y":421,"w":55,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":11,"y":4,"w":55,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-impatient.png":
{
	"frame": {"x":530,"y":614,"w":66,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-improv.png":
{
	"frame": {"x":394,"y":1756,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-in-the-eye-of-mercy.png":
{
	"frame": {"x":926,"y":1772,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-index-warning.png":
{
	"frame": {"x":657,"y":747,"w":64,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":64,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-intimidating-aura.png":
{
	"frame": {"x":725,"y":747,"w":57,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":2,"w":57,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-irradiation-zone.png":
{
	"frame": {"x":602,"y":486,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-it-bites-deeper.png":
{
	"frame": {"x":65,"y":1814,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-its-a-trap.png":
{
	"frame": {"x":134,"y":1814,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-its-raining-agony.png":
{
	"frame": {"x":904,"y":757,"w":61,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":2,"w":61,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-its-raining-traps-hallelujah.png":
{
	"frame": {"x":399,"y":741,"w":54,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":2,"w":54,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-just-walk-it-off.png":
{
	"frame": {"x":794,"y":1017,"w":64,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-kill-trigger.png":
{
	"frame": {"x":138,"y":2086,"w":56,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":56,"h":66},
	"sourceSize": {"w":56,"h":66}
},
"upgrade-laser-light-show.png":
{
	"frame": {"x":900,"y":361,"w":66,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":66,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-lasting-mark.png":
{
	"frame": {"x":460,"y":169,"w":53,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":5,"w":53,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-latent-destruction.png":
{
	"frame": {"x":724,"y":1763,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-leading-the-fairy-dance.png":
{
	"frame": {"x":73,"y":2154,"w":57,"h":69},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":57,"h":69},
	"sourceSize": {"w":57,"h":69}
},
"upgrade-learning-practical-skills.png":
{
	"frame": {"x":852,"y":3,"w":60,"h":50},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":8,"w":60,"h":50},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-legato.png":
{
	"frame": {"x":139,"y":3,"w":66,"h":37},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":10,"w":66,"h":37},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-let-the-arrows-hit-the-floor.png":
{
	"frame": {"x":328,"y":1820,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-lethal-sniper.png":
{
	"frame": {"x":911,"y":424,"w":52,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":1,"w":52,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-light-it-up.png":
{
	"frame": {"x":205,"y":1747,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-light-the-night-again.png":
{
	"frame": {"x":543,"y":2099,"w":61,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":66},
	"sourceSize": {"w":61,"h":66}
},
"upgrade-lightning-always-strikes-twice.png":
{
	"frame": {"x":250,"y":1002,"w":57,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":57,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-lightning-fast.png":
{
	"frame": {"x":394,"y":1824,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-lightning-prowess.png":
{
	"frame": {"x":55,"y":1403,"w":49,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":49,"h":64},
	"sourceSize": {"w":49,"h":64}
},
"upgrade-lightning-rod.png":
{
	"frame": {"x":744,"y":2182,"w":66,"h":74},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":74},
	"sourceSize": {"w":66,"h":74}
},
"upgrade-lightning-tempest.png":
{
	"frame": {"x":3,"y":792,"w":61,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":62},
	"sourceSize": {"w":61,"h":62}
},
"upgrade-linked-chains.png":
{
	"frame": {"x":906,"y":1499,"w":55,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":55,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-living-bombs.png":
{
	"frame": {"x":672,"y":487,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-locked-loaded-ready-to-boom.png":
{
	"frame": {"x":522,"y":1826,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-long-distance-power.png":
{
	"frame": {"x":122,"y":280,"w":66,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-longer-chains.png":
{
	"frame": {"x":970,"y":367,"w":49,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":3,"w":49,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-longer-forearm.png":
{
	"frame": {"x":123,"y":219,"w":66,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-longwave-radio.png":
{
	"frame": {"x":587,"y":1826,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-lumbering-burst.png":
{
	"frame": {"x":608,"y":2103,"w":59,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":59,"h":66},
	"sourceSize": {"w":59,"h":66}
},
"upgrade-lunar-lady.png":
{
	"frame": {"x":65,"y":405,"w":66,"h":59},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":59},
	"sourceSize": {"w":66,"h":59}
},
"upgrade-lye-ing-it-on-thick.png":
{
	"frame": {"x":138,"y":99,"w":59,"h":55},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":8,"w":59,"h":55},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-make-a-thump.png":
{
	"frame": {"x":600,"y":615,"w":62,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":3,"w":62,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-making-it-rain-arrows.png":
{
	"frame": {"x":655,"y":1827,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-mana-sphere.png":
{
	"frame": {"x":786,"y":1837,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-mark-aim-fire.png":
{
	"frame": {"x":855,"y":1839,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-markswitch-final-form.png":
{
	"frame": {"x":266,"y":1749,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-martial-tactics.png":
{
	"frame": {"x":925,"y":1840,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-massive-attack.png":
{
	"frame": {"x":672,"y":3,"w":56,"h":49},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":7,"w":56,"h":49},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-maximum-amperage.png":
{
	"frame": {"x":187,"y":801,"w":63,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":2,"w":63,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-meteoric-pace.png":
{
	"frame": {"x":320,"y":804,"w":64,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":64,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-minor-travesty.png":
{
	"frame": {"x":742,"y":487,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-MOAR-BEES.png":
{
	"frame": {"x":65,"y":1882,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-MORE-bees.png":
{
	"frame": {"x":462,"y":52,"w":66,"h":53},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":11,"w":66,"h":53},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-more-mass-more-oomph.png":
{
	"frame": {"x":201,"y":106,"w":59,"h":55},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":9,"w":59,"h":55},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-multi-facet-burst.png":
{
	"frame": {"x":721,"y":1831,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-multi-point-show.png":
{
	"frame": {"x":388,"y":807,"w":63,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":63,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-multi-strike.png":
{
	"frame": {"x":199,"y":2019,"w":52,"h":65},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":52,"h":65},
	"sourceSize": {"w":52,"h":65}
},
"upgrade-naginata-final-form.png":
{
	"frame": {"x":455,"y":811,"w":63,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":63,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-naginata-mastery.png":
{
	"frame": {"x":135,"y":1882,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-not-just-a-one-trick-pony.png":
{
	"frame": {"x":916,"y":3,"w":64,"h":50},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":9,"w":64,"h":50},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-noxious-leak.png":
{
	"frame": {"x":445,"y":292,"w":58,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":5,"w":58,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-on-a-wave-of-mutilation.png":
{
	"frame": {"x":312,"y":289,"w":64,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":64,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-one-arrow-intwain.png":
{
	"frame": {"x":587,"y":173,"w":50,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":5,"w":50,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-one-arrow-to-rule-them-all.png":
{
	"frame": {"x":460,"y":1757,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-only-a-spark.png":
{
	"frame": {"x":3,"y":1465,"w":45,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":12,"y":0,"w":45,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-orbital-lasers.png":
{
	"frame": {"x":519,"y":1894,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-out-and-back.png":
{
	"frame": {"x":518,"y":1011,"w":62,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":1,"w":62,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-out-for-blood.png":
{
	"frame": {"x":182,"y":412,"w":65,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":65,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-overdrive.png":
{
	"frame": {"x":326,"y":1888,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pain-fountain.png":
{
	"frame": {"x":254,"y":802,"w":62,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":2,"w":62,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pet-caller.png":
{
	"frame": {"x":732,"y":3,"w":53,"h":49},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":10,"w":53,"h":49},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pet-fiesta.png":
{
	"frame": {"x":123,"y":800,"w":60,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":60,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-petnip.png":
{
	"frame": {"x":209,"y":3,"w":64,"h":43},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":15,"w":64,"h":43},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ph-0-acid.png":
{
	"frame": {"x":393,"y":1892,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-phoenix-flock.png":
{
	"frame": {"x":854,"y":1907,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-phoenix-multiplier.png":
{
	"frame": {"x":593,"y":1758,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-phoenixpalooza.png":
{
	"frame": {"x":924,"y":1908,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-photon.png":
{
	"frame": {"x":786,"y":748,"w":55,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":2,"w":55,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pickup-sweep.png":
{
	"frame": {"x":3,"y":1813,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pierce.png":
{
	"frame": {"x":457,"y":230,"w":46,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":4,"w":46,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-piercing-force.png":
{
	"frame": {"x":73,"y":726,"w":48,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":2,"w":48,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pike-master.png":
{
	"frame": {"x":65,"y":1950,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pike-shockwave.png":
{
	"frame": {"x":587,"y":812,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-plasma-overload.png":
{
	"frame": {"x":862,"y":1023,"w":58,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":1,"w":58,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-platos-ideal.png":
{
	"frame": {"x":518,"y":1962,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-poison-coated-weapons.png":
{
	"frame": {"x":582,"y":114,"w":65,"h":55},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":6,"w":65,"h":55},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-poison-magic.png":
{
	"frame": {"x":52,"y":1471,"w":46,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":10,"y":0,"w":46,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-powerful-magic.png":
{
	"frame": {"x":192,"y":287,"w":57,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":3,"w":57,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-powerful-toxin.png":
{
	"frame": {"x":924,"y":1025,"w":63,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":63,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-praying-mantis-style.png":
{
	"frame": {"x":657,"y":813,"w":63,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":63,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-premium-payload.png":
{
	"frame": {"x":589,"y":1894,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pressurized-pustule-popping.png":
{
	"frame": {"x":68,"y":98,"w":66,"h":54},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":5,"w":66,"h":54},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pretty-overpowered-laser.png":
{
	"frame": {"x":202,"y":1815,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-primary-specialist.png":
{
	"frame": {"x":264,"y":107,"w":59,"h":55},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":5,"w":59,"h":55},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-prog-rock.png":
{
	"frame": {"x":854,"y":1975,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pulverizing-arcana.png":
{
	"frame": {"x":655,"y":1895,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-pythagoras-theorum-of-explosions.png":
{
	"frame": {"x":310,"y":414,"w":60,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":2,"w":60,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quantum-ammo-chamber.png":
{
	"frame": {"x":180,"y":1068,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quick-coil-placement.png":
{
	"frame": {"x":666,"y":616,"w":61,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":3,"w":61,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quick-firing.png":
{
	"frame": {"x":731,"y":616,"w":61,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":3,"w":61,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quick-fuse.png":
{
	"frame": {"x":265,"y":1817,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quick-temper.png":
{
	"frame": {"x":796,"y":617,"w":64,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quickchange-artist.png":
{
	"frame": {"x":459,"y":1825,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quicktomancy.png":
{
	"frame": {"x":311,"y":1070,"w":64,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":64,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-quietly-into-the-night.png":
{
	"frame": {"x":924,"y":1976,"w":73,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":73,"h":64},
	"sourceSize": {"w":73,"h":64}
},
"upgrade-radiation-blast.png":
{
	"frame": {"x":898,"y":823,"w":62,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":62,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ramping-bpm.png":
{
	"frame": {"x":253,"y":288,"w":55,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":4,"w":55,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ranger-big-game-hunter.png":
{
	"frame": {"x":522,"y":811,"w":61,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":2,"w":61,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-deployment.png":
{
	"frame": {"x":786,"y":1905,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-engagement.png":
{
	"frame": {"x":965,"y":1500,"w":54,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":0,"w":54,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-fire-final-form.png":
{
	"frame": {"x":379,"y":1074,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-killer.png":
{
	"frame": {"x":3,"y":40,"w":64,"h":50},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":8,"w":64,"h":50},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-rebirth.png":
{
	"frame": {"x":720,"y":1899,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-recon.png":
{
	"frame": {"x":449,"y":1077,"w":63,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":63,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rapid-stingers.png":
{
	"frame": {"x":135,"y":1950,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-rats-are-making-all-the-rules.png":
{
	"frame": {"x":135,"y":405,"w":43,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":13,"y":1,"w":43,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-razorang.png":
{
	"frame": {"x":509,"y":1553,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-regeneration.png":
{
	"frame": {"x":139,"y":44,"w":59,"h":51},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":6,"w":59,"h":51},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-resilient-fire.png":
{
	"frame": {"x":944,"y":2112,"w":57,"h":68},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":57,"h":68},
	"sourceSize": {"w":57,"h":68}
},
"upgrade-ricochet-rang-final-form.png":
{
	"frame": {"x":325,"y":1956,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ricochet.png":
{
	"frame": {"x":3,"y":1058,"w":56,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":1,"w":56,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-right-in-the-soft-bits.png":
{
	"frame": {"x":201,"y":1883,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-righteous-fervor.png":
{
	"frame": {"x":392,"y":1960,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-roost-boost.png":
{
	"frame": {"x":786,"y":1973,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-secondary-specialist.png":
{
	"frame": {"x":380,"y":290,"w":61,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":5,"w":61,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-shocked-to-death.png":
{
	"frame": {"x":64,"y":2018,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-shocking-percussion.png":
{
	"frame": {"x":970,"y":303,"w":49,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":49,"h":60},
	"sourceSize": {"w":49,"h":60}
},
"upgrade-skillful-collection.png":
{
	"frame": {"x":516,"y":1078,"w":62,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":1,"w":62,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-skillstrike-gadget.png":
{
	"frame": {"x":582,"y":1079,"w":65,"h":63},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":63},
	"sourceSize": {"w":65,"h":63}
},
"upgrade-sky-splitter-arrows.png":
{
	"frame": {"x":588,"y":1962,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-skyfire-fairy.png":
{
	"frame": {"x":814,"y":2184,"w":65,"h":75},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":75},
	"sourceSize": {"w":65,"h":75}
},
"upgrade-slice-thru.png":
{
	"frame": {"x":845,"y":749,"w":55,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":2,"w":55,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-slow-and-steady.png":
{
	"frame": {"x":654,"y":1963,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-soaking-it-in.png":
{
	"frame": {"x":864,"y":618,"w":65,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":65,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-socrates-revenge.png":
{
	"frame": {"x":719,"y":1967,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-solar-supremacy.png":
{
	"frame": {"x":731,"y":2109,"w":66,"h":66},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":66},
	"sourceSize": {"w":66,"h":66}
},
"upgrade-some-more-bees.png":
{
	"frame": {"x":532,"y":56,"w":48,"h":53},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":9,"y":11,"w":48,"h":53},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-something-that-bigs-gotta-hurt.png":
{
	"frame": {"x":133,"y":2018,"w":62,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":62,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-song-of-haste.png":
{
	"frame": {"x":374,"y":415,"w":62,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":4,"w":62,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-spear-mastery.png":
{
	"frame": {"x":969,"y":759,"w":50,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":8,"y":2,"w":50,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-speed-boomerang.png":
{
	"frame": {"x":651,"y":1079,"w":63,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":63,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-speed-of-light.png":
{
	"frame": {"x":317,"y":228,"w":66,"h":57},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":57},
	"sourceSize": {"w":66,"h":57}
},
"upgrade-staggering-amounts-of-goop.png":
{
	"frame": {"x":701,"y":424,"w":66,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-stalwart-soldier-style.png":
{
	"frame": {"x":724,"y":813,"w":58,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":2,"w":58,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-starburst.png":
{
	"frame": {"x":718,"y":1080,"w":62,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":62,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-starry-malevolence.png":
{
	"frame": {"x":440,"y":418,"w":63,"h":59},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":63,"h":59},
	"sourceSize": {"w":63,"h":59}
},
"upgrade-steal-and-heal.png":
{
	"frame": {"x":507,"y":296,"w":63,"h":58},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":63,"h":58},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-stormbreaker-final-form.png":
{
	"frame": {"x":933,"y":626,"w":66,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-straight-shooter.png":
{
	"frame": {"x":812,"y":488,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-strength-of-the-storm.png":
{
	"frame": {"x":905,"y":1567,"w":54,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":0,"w":54,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-strike-after-strike.png":
{
	"frame": {"x":119,"y":1066,"w":57,"h":63},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":57,"h":63},
	"sourceSize": {"w":57,"h":63}
},
"upgrade-strolling-minstrel.png":
{
	"frame": {"x":964,"y":825,"w":56,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":2,"w":56,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-stronger-bees.png":
{
	"frame": {"x":771,"y":424,"w":66,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":66,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-stunning-effectiveness.png":
{
	"frame": {"x":3,"y":1881,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-stunning-strikes.png":
{
	"frame": {"x":784,"y":1084,"w":58,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":1,"w":58,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-summers-rage.png":
{
	"frame": {"x":3,"y":2154,"w":66,"h":68},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":68},
	"sourceSize": {"w":66,"h":68}
},
"upgrade-sweep-aside.png":
{
	"frame": {"x":882,"y":489,"w":65,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":65,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-tactical-strike-final-form.png":
{
	"frame": {"x":255,"y":2021,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-taking-names.png":
{
	"frame": {"x":324,"y":2024,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":3,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-TBD-pets.png":
{
	"frame": {"x":482,"y":3,"w":57,"h":45},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":11,"w":57,"h":45},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-tear-em-up.png":
{
	"frame": {"x":951,"y":497,"w":64,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":64,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-tempered-blades.png":
{
	"frame": {"x":912,"y":298,"w":54,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":4,"w":54,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-tempo-change.png":
{
	"frame": {"x":566,"y":422,"w":65,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":4,"w":65,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-terrified-approach.png":
{
	"frame": {"x":318,"y":870,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-best-of-living-through-acid.png":
{
	"frame": {"x":250,"y":1069,"w":57,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":57,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-crowd-controller.png":
{
	"frame": {"x":458,"y":2030,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-entire-symphony.png":
{
	"frame": {"x":846,"y":1090,"w":59,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":59,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-heart-of-an-assassin.png":
{
	"frame": {"x":391,"y":2028,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-size-on-that-thing.png":
{
	"frame": {"x":68,"y":792,"w":51,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":1,"w":51,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-stuff-that-really-lasts.png":
{
	"frame": {"x":61,"y":156,"w":63,"h":56},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":63,"h":56},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-the-wise-one.png":
{
	"frame": {"x":635,"y":423,"w":62,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":4,"w":62,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-they-stalk-at-night.png":
{
	"frame": {"x":527,"y":2030,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thornapalooza.png":
{
	"frame": {"x":597,"y":2031,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thorns-aggressive-defense.png":
{
	"frame": {"x":264,"y":1885,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thorns-natural-defense.png":
{
	"frame": {"x":453,"y":877,"w":66,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":2,"w":66,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thorns-panic-option.png":
{
	"frame": {"x":196,"y":541,"w":66,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":4,"w":66,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thorns-spiney.png":
{
	"frame": {"x":909,"y":1092,"w":58,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":1,"w":58,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thorns-thorn-dog.png":
{
	"frame": {"x":667,"y":2035,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-thorny.png":
{
	"frame": {"x":458,"y":1893,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-three-fairies-are-better-than-one.png":
{
	"frame": {"x":656,"y":879,"w":71,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":71,"h":62},
	"sourceSize": {"w":71,"h":62}
},
"upgrade-thunderous-blows.png":
{
	"frame": {"x":3,"y":465,"w":58,"h":60},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":58,"h":60},
	"sourceSize": {"w":58,"h":60}
},
"upgrade-thunderous-squall.png":
{
	"frame": {"x":134,"y":2157,"w":67,"h":68},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":67,"h":68},
	"sourceSize": {"w":67,"h":68}
},
"upgrade-tighten-spread.png":
{
	"frame": {"x":445,"y":355,"w":58,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":5,"w":58,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-tough-skin.png":
{
	"frame": {"x":737,"y":2041,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-truly-shocking.png":
{
	"frame": {"x":786,"y":814,"w":55,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":2,"w":55,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-twos-company.png":
{
	"frame": {"x":967,"y":432,"w":53,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":7,"y":3,"w":53,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-ultra-rapid-fire.png":
{
	"frame": {"x":3,"y":858,"w":61,"h":62},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":62},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-bees.png":
{
	"frame": {"x":806,"y":2043,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-dark-stormy-night.png":
{
	"frame": {"x":122,"y":866,"w":60,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":60,"h":62},
	"sourceSize": {"w":60,"h":62}
},
"upgrade-unlock-fire-fairy.png":
{
	"frame": {"x":731,"y":880,"w":72,"h":62},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":72,"h":62},
	"sourceSize": {"w":72,"h":62}
},
"upgrade-unlock-lute.png":
{
	"frame": {"x":179,"y":1135,"w":62,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":1,"w":62,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-nikolas-scope.png":
{
	"frame": {"x":876,"y":2044,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-phoenix-bombs.png":
{
	"frame": {"x":943,"y":2044,"w":65,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":65,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-poison-bottles.png":
{
	"frame": {"x":3,"y":1949,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-rat-parade.png":
{
	"frame": {"x":200,"y":1951,"w":58,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":58,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-unlock-tesla-coil.png":
{
	"frame": {"x":262,"y":1953,"w":59,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":59,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-up-tempo-the-fairy-beat.png":
{
	"frame": {"x":400,"y":610,"w":59,"h":61},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":59,"h":61},
	"sourceSize": {"w":59,"h":61}
},
"upgrade-venomous.png":
{
	"frame": {"x":252,"y":350,"w":54,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":6,"y":4,"w":54,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-visceral-magic.png":
{
	"frame": {"x":186,"y":1542,"w":49,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":11,"y":0,"w":49,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-wasp-outbreak.png":
{
	"frame": {"x":3,"y":2086,"w":63,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":0,"w":63,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-we-all-fall-down.png":
{
	"frame": {"x":192,"y":670,"w":65,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":65,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-we-are-the-rats.png":
{
	"frame": {"x":70,"y":2086,"w":64,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":0,"w":64,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-weight-training.png":
{
	"frame": {"x":387,"y":229,"w":66,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":7,"w":66,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-when-is-the-next-gig.png":
{
	"frame": {"x":245,"y":1136,"w":62,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":1,"w":62,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-where-your-nightmares-end.png":
{
	"frame": {"x":198,"y":2089,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-whirling-dervish-style.png":
{
	"frame": {"x":65,"y":532,"w":63,"h":60},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":63,"h":60},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-whirlwind-blade.png":
{
	"frame": {"x":125,"y":669,"w":63,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":3,"w":63,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-whirlwind.png":
{
	"frame": {"x":458,"y":1961,"w":56,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":0,"w":56,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-wide-spray.png":
{
	"frame": {"x":3,"y":402,"w":58,"h":59},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":5,"w":58,"h":59},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-wider-authority.png":
{
	"frame": {"x":59,"y":661,"w":62,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":1,"y":3,"w":62,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-widespread-calamity.png":
{
	"frame": {"x":379,"y":1141,"w":66,"h":63},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":0,"y":1,"w":66,"h":63},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-wild-fire.png":
{
	"frame": {"x":3,"y":2017,"w":57,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":5,"y":0,"w":57,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-wisdom-of-the-goddess.png":
{
	"frame": {"x":596,"y":2173,"w":68,"h":73},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":68,"h":73},
	"sourceSize": {"w":68,"h":73}
},
"upgrade-you-call-that-a-critical.png":
{
	"frame": {"x":193,"y":226,"w":58,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":5,"w":58,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-you-call-that-a-hit.png":
{
	"frame": {"x":255,"y":227,"w":58,"h":57},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":4,"y":5,"w":58,"h":57},
	"sourceSize": {"w":66,"h":64}
},
"upgrade-you-should-be-dancing.png":
{
	"frame": {"x":268,"y":2092,"w":61,"h":64},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":0,"w":61,"h":64},
	"sourceSize": {"w":66,"h":64}
},
"upgrades-slicing-and-dicing.png":
{
	"frame": {"x":261,"y":671,"w":64,"h":61},
	"rotated": false,
	"trimmed": true,
	"spriteSourceSize": {"x":2,"y":3,"w":64,"h":61},
	"sourceSize": {"w":66,"h":64}
},
"upgrde-shock-infused.png":
{
	"frame": {"x":333,"y":2096,"w":66,"h":64},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":66,"h":64},
	"sourceSize": {"w":66,"h":64}
}},
"meta": {
	"app": "https://www.codeandweb.com/texturepacker",
	"version": "1.1",
	"image": "in-game-icons.png",
	"format": "RGBA8888",
	"size": {"w":1023,"h":2262},
	"scale": "1",
	"smartupdate": "$TexturePacker:SmartUpdate:cba90127d2f1b7b6b45d6d39e3d76dfa:008e210b7ddd3ca3de2855f23803d4cd:4b2fac010a65861d4d8d6e0634c4bf05$"
}
}
