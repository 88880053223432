<template>
	<div id="Logged-in">
		<Logo class="logged-in"></Logo>
		<div class="button-container">
			<TextButton :button-label="$t('logged_in.button_play')" :button-style="'bright-green'" @on-click-fn="changeView" :optional-param="{view: Views.STORY_SELECT}"></TextButton>
			<TextButton :button-label="$t('logged_in.button_settings')" :button-style="'blue'" @on-click-fn="changeView" :optional-param="{view: Views.SETTINGS}" :disabled="false"></TextButton>
			<div v-if="envElectron">
				<TextButton :button-label="$t('logged_in.button_quit')" :button-style="'blue'" @on-click-fn="quit"></TextButton>
			</div>
			<TextButton v-if="!webPortalConfig.useCustomAuth" :button-label="$t('logged_in.button_log_out')" :button-style="'blue'" @on-click-fn="logOut"></TextButton>
			<div v-if="!isChrome" class="chrome-message" :lang="this.$i18n.locale">{{$t('error.use_chrome')}}</div>
			<JoinDiscordButton></JoinDiscordButton>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TextButton from '../ui-components/buttons/text-button.vue'
import Logo from '../ui-components/logo.vue'
import JoinDiscordButton from '../ui-components/buttons/join-discord.vue'
import { Views } from '../store/ui-store'
import { Audio } from '../../engine/audio'
export default {
	name: 'LoggedIn',
	components: {
		TextButton,
		Logo,
		JoinDiscordButton
	},
	data() {
		return {
			Views: Views,
			envElectron: process.env.IS_ELECTRON,
		}
	},
	props: {},
	computed: {
		...mapState('user', ['externalUserAccountLoggedIn']),
		...mapState('ui', ['isChrome', 'menuMusicStarted']),
		...mapGetters('ui', ['webPortalConfig']),
	},
	methods: {
		...mapActions('ui', ['startGame', 'changeActiveView']),
		...mapMutations('ui', ['updateMenuMusicStarted', 'registerKeystrokeHandler']),
		...mapActions('user', ['logOut']),
		changeView(params) {
			const { view } = params
			this.changeActiveView(view)
		},
		quit(){
			window.bridge.quitApp()
		}
	},
	mounted() {
		const changeViewCallBack = () => {
			this.changeView({view: Views.STORY_SELECT})
		}
		const callBacks = [
			{
				keys: ['Enter', 'Space'],
				callBack: changeViewCallBack.bind(this)
			}
		] 
		this.registerKeystrokeHandler(callBacks)

		if (!this.menuMusicStarted) {
			Audio.getInstance().playBgm('MUS_FLOATING_LP')
			this.updateMenuMusicStarted(true)
		}
	}
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');
#Logged-in {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 692px; // main panel width
	margin: 0 auto;// center left right
	.title {
		filter: drop-shadow(4px 5px 1px black);
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-size: 106px;
			color: @green;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
			font-size: 64px;
		}
	}
	.button-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 80px 0px;
		.text-button {
			margin: 30px;
			width: 450px;
		}
		.chrome-message {
			text-align: center;
			color: @failureRed;
			&[lang='en'] {
				.regularFont(@enSecondaryFontPTBold);
				font-size: 35px;
				font-variant: small-caps;
			}
			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
				font-size: 35px;
			}
		}
	}
}
</style>
