import { Player } from "../../../entities/player"
import EntityStatList from "../../../stats/entity-stat-list"
import { AllWeaponTypes } from "../../weapon-types"
import { AutoFireSecondaryWeapon } from "./auto-fire-secondary-weapon"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { Beam } from "../../../beams/beams"
import { Vector } from "sat"
import { radians, timeInSeconds } from "../../../utils/primitive-types"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { BeamConfigType } from "../../../beams/beam-graphics"
import { callbacks_addCallback, callbacks_removeCallbacksFromOwner } from "../../../utils/callback-system"
import { Audio } from "../../../engine/audio"

const PREFIRE_BEAM_WIDTH = 40
const STARTING_BEAM_WIDTH = 100
const STARTING_BEAM_LENGTH = 725
const PREFIRE_DURATION = 2.5

const BEAM_Y_OFFSET = -50

const LASER_LIGHT_ANGLE_INCREMENT = 0.05
const LASER_LIGHT_START_ANGLE_OFFSET = -0.2
const LASER_LIGHT_NUM_ANGLE_INCREMENTS = 10


export class NikolaScope extends AutoFireSecondaryWeapon {
	weaponType: AllWeaponTypes = AllWeaponTypes.NikolaScope
	
	beams: Beam[]

	beamPositionVector: Vector

	constructor(player: Player, parentStatList: EntityStatList) {
		super(player, parentStatList)
		this.beams = []

		this.beamPositionVector = new Vector()
	}

	resetStatsFunction(statList: EntityStatList) {
		defaultStatAttribute(statList)
		
		statList._actualStatValues.baseDamage = 8
		statList._actualStatValues.attackRate = 5.0000
		statList._actualStatValues.skillDuration = 2.0
		statList._actualStatValues.attackSize = 1
		statList._actualStatValues.projectileCount = 1
		
		statList._actualStatValues.attackPierceCount = 999_999

		statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 8_000
		statList._actualStatValues.reloadInterval = 50
    }

	fire() {
		this.startPreFire()
	}

	forceStopFiring() {
		callbacks_removeCallbacksFromOwner(this)
		// not using this.removeBeams,
		// because that does not play nice with this.beams (removing during iteration)
		for (let i = 0; i < this.beams.length; ++i) {
			Beam.pool.free(this.beams[i])
		}

		this.beams.length = 0
	}

	override update(delta: number) {
        super.update(delta)

		if (this.beams.length > 0) {
			this.beamPositionVector.copy(this.player.position)
			this.beamPositionVector.y += BEAM_Y_OFFSET

			const hasLaserLightShow = this.player.binaryFlags.has('nikola-scope-light-show')

			for (let i = 0; i < this.beams.length; ++i) {
				const beam = this.beams[i]
				beam.position.copy(this.beamPositionVector)

				if (hasLaserLightShow && beam.firedLastFrame) {
					beam.numAngleIncrements++
					if(beam.numAngleIncrements >= LASER_LIGHT_NUM_ANGLE_INCREMENTS) {
						beam.setAngle(beam.originalAngle)
						beam.numAngleIncrements = 0
					} else {
						beam.setAngle(beam.angle + LASER_LIGHT_ANGLE_INCREMENT)
					}
				}
			}
		}
    }

	getAttackTickTime(): number {
		return (1 / this.statList.getStat(StatType.attackRate))
	}

	private startPreFire() {
		const beams = this.fireBeams(true)
		callbacks_addCallback(this, () => {
			this.onPrefireDone(beams)
		}, PREFIRE_DURATION)
	}

	private onPrefireDone(oldBeams: Beam[]) {
		const newBeams = this.fireBeams(false, oldBeams)
		this.removeBeams(oldBeams)
		
		const skillDuration = this.statList.getStat(StatType.skillDuration) as timeInSeconds
		
		callbacks_addCallback(this, () => {
			this.onFireDone(newBeams)
		}, skillDuration)
	}

	private onFireDone(beams: Beam[]) {
		this.removeBeams(beams)
	}

	private fireBeams(isPreFire: boolean, existingBeams?: Beam[]): Beam[] {
		const numOfBeams = isPreFire ? this.statList.getStat(StatType.projectileCount) : existingBeams.length
		const hasLaserLightShow = this.player.binaryFlags.has('nikola-scope-light-show')
		const hasOPLaser = this.player.binaryFlags.has('nikola-scope-op')
		const toReturnBeams = []

		for (let i = 0; i < numOfBeams; ++i) {
			let angle: radians
			if (isPreFire) {
				angle = Math.random() * Math.PI * 2//-Math.PI/2 //Math.random() * Math.PI * 2
			} else {
				angle = existingBeams[i].angle
			}
			
			
			if (hasLaserLightShow && !isPreFire) {
				angle += LASER_LIGHT_START_ANGLE_OFFSET
			}

			const attackSize = this.statList.getStat(StatType.attackSize)
			const width = (isPreFire ? PREFIRE_BEAM_WIDTH : STARTING_BEAM_WIDTH) * attackSize
			const length = STARTING_BEAM_LENGTH * attackSize
			const position = this.player.position


			const beam = Beam.pool.alloc({
				x: position.x,
				y: position.y + BEAM_Y_OFFSET,
				angle: angle,
				width: width,
				length: length,
				maxLength: length,
				statList: this.statList,
				noCollisions: isPreFire,
				hasRandomChanceForMassiveDamage: hasOPLaser,
				weaponType: this.weaponType,
				beamConfigType: isPreFire ? BeamConfigType.NikolaScopePreFire : BeamConfigType.NikolaScopeFiring
			})

			this.beams.push(beam)
			toReturnBeams.push(beam)
		}

        Audio.getInstance().playSfx('SFX_Nikola_Scope_Fire');

		return toReturnBeams
	}

	private removeBeams(beams: Beam[]) {
		for (let i = 0; i < beams.length; ++i) {
			Beam.pool.free(beams[i])
			this.beams.remove(beams[i])
		}

		beams.length = 0
	}
}