

import { Player } from "../entities/player";
import { AllWeaponTypes } from "../weapons/weapon-types";
import EntityStatList from "./entity-stat-list";

/** Selectors are a collection of targets that stats can be applied to. They determine which stat lists we modify
 * for a given stat.
 * 
 *  A selector can target one specific stat list (for example, a player's), or a category that encompasses multiple
 * stat lists (for example, every  secondary weapon.)
 * 
 * If the desired selector doesn't show up here you'll likely have to implement it.
 */
export const statSelectors = {
    Player: 'player',
    Pets: 'pets',
    PrimaryWeapon: 'primary-weapon',
    SecondaryWeapons: 'secondary-weapons',
    PlayerSkill: 'player-skill',
    Bow: AllWeaponTypes.Bow,
    Wand: AllWeaponTypes.Wand,
    Spear: AllWeaponTypes.Spear,
    Boomerang: AllWeaponTypes.Boomerang,
    Cannon: AllWeaponTypes.Cannon,
    TeslaCoil: AllWeaponTypes.TeslaCoil,
    AcidBottles: AllWeaponTypes.AcidBottles,
    RatParade: AllWeaponTypes.RatParade,
    NikolaScope: AllWeaponTypes.NikolaScope,
    ElfRangerPassive: AllWeaponTypes.ElfRangerPassive,
    ElfRangerSkill: AllWeaponTypes.ElfRangerSkill,
    BarbarianPassive: AllWeaponTypes.BarbarianPassive,
    BarbarianSkill: AllWeaponTypes.BarbarianSkill,
    ConeDogSkill: AllWeaponTypes.DogSkill,
    SolaraSkill: AllWeaponTypes.SolaraSkill,
    SolaraPassive: AllWeaponTypes.SolaraPassive,
    Thorns: AllWeaponTypes.ConeDogThorns,
	Bees: AllWeaponTypes.Bees,
    Lute: AllWeaponTypes.Lute,
    DarkStormyNight: AllWeaponTypes.DarkStormyNight,
    ShockPulse: AllWeaponTypes.RadarSkill,
    FieldOpGadget: AllWeaponTypes.RadarPassive,
    FireFairies: AllWeaponTypes.FireFairies,
    PhoenixBombs: AllWeaponTypes.PhoenixBombs
} as const

export type StatSelector = keyof typeof statSelectors

export type StatSelectors = StatSelector[]

/**
 * 
 * @param player 
 * @param selectors 
 * @returns A list of EntityStatLists based on the selectors.
 * 
 * Note: This will do its best to deduplicate selectors (for example if you chose primary weapon and crossbow as selectors,
 * and the player has the crossbow, it should only provide one stat list).
 * 
 * This does **not** apply to using the SecondaryWeapons selector along with a specific secondary weapon, that will produce
 * two stat lists and so it could apply an upgrade twice.
 */
export function selectorsToStatLists(player: Player, selectors: StatSelectors) {
    const statSet = new Set<EntityStatList>()
    for (const selector of selectors) {
        if (selector === "Player") {
            statSet.add(player.stats)
        } else if (selector === "PrimaryWeapon") {
            statSet.add(player.primaryWeapon.statList)
        } else if (selector === "SecondaryWeapons") {
            statSet.add(player.secondaryStatList)
        } else if (selector === "Pets") {
            statSet.add(player.petStatList)
        } else if (selector === 'PlayerSkill') {
            statSet.add(player.skillWeapon.statList)
        } else {
            // HEADS UP: this is now the default case for any of the weapon types
            // if you add any more handlers they should occur above here
            const weaponType = statSelectors[selector]
            const weapon = player.getWeapon(weaponType)
            if (weapon) {
                statSet.add(weapon.statList)
            }
        }
    }
    return Array.from(statSet)
}