import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, ENEMY_OBJECT_POOL_GROWTH_SIZE, ENEMY_OBJECT_POOL_INITIAL_SIZE, EnemyAI, EnemyType, FightingBehaviours, ShotLeadPrecision } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"
import { EnemyDefaults } from "./enemy-defaults"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"

const choreoPetCat: EnemyAI = {
	name: ENEMY_NAME.CHOREO_PET_CAT,
	type: EnemyType.BASIC,
	objectPoolInitialSize: ENEMY_OBJECT_POOL_INITIAL_SIZE,
	objectPoolGrowthSize: ENEMY_OBJECT_POOL_GROWTH_SIZE,
	appearance: {
		asset: SpineDataName.PET_CAT_SPRITESHEET,
		skin: EnemyBipedSkinType.DEFAULT,
		spriteSheet: true,
		mixSettings: [],
	},
	baseAttributes: {
		...EnemyDefaults,
		isPet: true,
		colliderLayer: CollisionLayerBits.None,
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.50,
		},
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Enemy_Hit',
	},
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			},
			attackConfig: {
				attackType: AttackTypes.NONE,
			},
			engagementMaxDistance: 900,
			engagementMinDistance: 0,
			modelCenterOffset: 0,
			movementMaxDistance: 100,
			movementMinDistance: 0,
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default choreoPetCat