import { Vector } from "sat"
import { GameState } from "../engine/game-state"
import AISystem from "../entities/enemies/ai-system"
import EnemyEquilibriumSpawner from "../entities/enemies/enemy-equilibrium-spawner"
import { allocGroundPickup, splayGroundPickupsInRadius } from "../entities/pickups/ground-pickup"
import { KillEnemiesInCirclePOI } from "../pois/kill-enemies-in-circle"
import { StandInCirclePOI } from "../pois/stand-in-circle"
import { timeInSeconds } from "../utils/primitive-types"
import { getDecreasingRadiusByInGameTime, getIncreasingRadiusByInGameTime, getRadiusByInGameTime } from "./shrine-gameplay-event"
import { GameplayTimedEventSystem } from "./gameplay-timed-event-system"
import { PropPlacer } from "../world-generation/prop-placement"
import { EventTypes } from "./event-types"
import { GroundPickupConfigType } from "../entities/pickups/ground-pickup-types"
import { InGameTime } from "../utils/time"
import { NO_MORE_XP_TIME } from "../game-data/levelling"
import { cloneDeepSerializable } from "../utils/object-util"

const REUSABLE_VECTOR = new Vector()


export interface GameplayEvent {
	startEvent(): void
	setStartData(data: EventStartData)
	update(delta: timeInSeconds)
}

export interface IGameEvent {
	isActive: (event:EventCollections) => boolean
	startEvent: (queuedEvent) => void
}

type SpawnWindow = {
	min: number
	max: number
}

export type EventStartData = {
	excludedBy: EventTypes[]
	/** Pass a single number for a fixed cooldown, or [min, max] for a random cooldown */
	coolDown: number | number[]
	spawnWindow: SpawnWindow[]
	frequency: number
	maxConcurrent: number
	timeLimit?: timeInSeconds
}

export type EventCollections = Partial<Record<EventTypes, EventStartData>>

export const MODDABLE_EVENT_DEFINITIONS: EventCollections = {
	/*
	'shrine': {
		excludedBy: [EventTypes.Goblin],
		coolDown: [50, 75],
		spawnWindow: [
			{
				min: 50,
				max: 75,
			},
		],
		// events with a freq of 0 will constantly spawn based on current time, cooldown, and random spawn window time
		frequency: 0,
		maxConcurrent: 1
	},
	*/
	'pet': {
		excludedBy: [EventTypes.Goblin],
		coolDown: [35, 45],
		spawnWindow: [
			{
				min: 90,
				max: 100,
			},
		],
		// events with a freq of 0 will constantly spawn based on current time, cooldown, and random spawn window time
		frequency: 0,
		maxConcurrent: 1
	},
	'goblin': {
		excludedBy: [EventTypes.Pet, EventTypes.Goblin],
		coolDown: 1,
		spawnWindow: [
			{
				min: 70,
				max: 140,
			},
			{
				min: 375,
				max: 580,
			},
			{
				min: 640,
				max: 690,
			},
			{
				min: 810,
				max: 830,
			},
		],
		// events with 1 + frequency will spawn based on the times listed in the spawn window
		frequency: 2,
		maxConcurrent: 1
	},
}

export const ORIGINAL_EVENT_DEFINITIONS = cloneDeepSerializable(MODDABLE_EVENT_DEFINITIONS)

export const ShrineGameplayEventPresets = {
	'killEnemiesForBuff': {
		poiType: KillEnemiesInCirclePOI,
		poiParams: {
			enemyCountMultiplier: 2,
		},
		getPosition() {
			const radius = this.getRadius()
			const minDist = Math.max(800, radius + 300)
			const maxDist = Math.max(1200, minDist)
			return PropPlacer.getInstance().getRandomValidPositionInWorld(minDist, maxDist, radius, REUSABLE_VECTOR)
		},
		getRadius() {
			return getRadiusByInGameTime() * 1.6
		},
		onPlayerEnteredFn(poi: KillEnemiesInCirclePOI, state: any) {
			EnemyEquilibriumSpawner.getInstance().temporarySpawnRateMulti += 3.0
		},
		whilePlayerInZoneFn(poi: KillEnemiesInCirclePOI, delta: timeInSeconds, state: any) { },
		onPlayerExitedFn(state: any) {
			EnemyEquilibriumSpawner.getInstance().temporarySpawnRateMulti -= 3.0
		},
		onEventDone(poi: KillEnemiesInCirclePOI, state: any) {
			GameState.player.heal(9999)
			//TODO: Buff
		},
	},

	'standInCircleForXP': {
		poiType: StandInCirclePOI,
		poiParams: {
			getDuration() {
				return 15
			},
		},
		getPosition() {
			const radius = this.getRadius()
			const minDist = Math.max(800, radius + 300)
			const maxDist = Math.max(1200, minDist)
			return PropPlacer.getInstance().getRandomValidPositionInWorld(minDist, maxDist, radius, REUSABLE_VECTOR)
		},
		getRadius() {
			return getRadiusByInGameTime()
		},
		onPlayerEnteredFn(poi: StandInCirclePOI, state: any) {
			EnemyEquilibriumSpawner.getInstance().temporarySpawnRateMulti += 3.0
		},
		whilePlayerInZoneFn(poi: StandInCirclePOI, delta: timeInSeconds, state: any) {
			if (state.acc === undefined) {
				state.acc = 0
			}
			state.acc += delta
			if (state.acc >= 1.0) {
				state.acc -= 1.0

				let iterations = 0
				let xpPickup: GroundPickupConfigType
				while (iterations < 20 && !xpPickup) {
					xpPickup = AISystem.getInstance().xpDropShuffleBag.next()
					iterations++
				}

				splayGroundPickupsInRadius([xpPickup], poi.position.x, poi.position.y, 1200, 1700, 0.3)
			}
		},
		onPlayerExitedFn(poi: StandInCirclePOI, state: any) {
			EnemyEquilibriumSpawner.getInstance().temporarySpawnRateMulti -= 3.0
		},
		onEventDone(poi: StandInCirclePOI, state: any) {
			let iterations = 0
			let xpPickup: GroundPickupConfigType
			while (iterations < 20 && !xpPickup) {
				xpPickup = AISystem.getInstance().xpDropShuffleBag.next()
				iterations++
			}

			splayGroundPickupsInRadius(new Array(8).fill(xpPickup), poi.position.x, poi.position.y, 1200, 1700, 0.05)
		},
	},

	'standInCircleForHearts': {
		poiType: StandInCirclePOI,
		poiParams: {
			getDuration() {
				return 15
			},
		},
		getPosition() {
			const radius = this.getRadius()
			const minDist = Math.max(800, radius + 300)
			const maxDist = Math.max(1200, minDist)
			return PropPlacer.getInstance().getRandomValidPositionInWorld(minDist, maxDist, radius, REUSABLE_VECTOR)
		},
		getRadius() {
			return getRadiusByInGameTime() * 1.3
		},
		onPlayerEnteredFn(poi: StandInCirclePOI, state: any) { },
		whilePlayerInZoneFn(poi: StandInCirclePOI, delta: timeInSeconds, state: any) {
			if (state.acc === undefined) {
				state.acc = 0
			}
			state.acc += delta
			if (state.acc >= 5.0) {
				state.acc -= 5.0
				splayGroundPickupsInRadius([GroundPickupConfigType.HealingSmall], poi.position.x, poi.position.y, 1200, 1700, 0.3)
			}
		},
		onPlayerExitedFn(poi: StandInCirclePOI, state: any) { },
		onEventDone(poi: StandInCirclePOI, state: any) {
			splayGroundPickupsInRadius([GroundPickupConfigType.HealingLarge], poi.position.x, poi.position.y, 1200, 1700, 0.3)
		},
	},
}

export const CombatArenaGameplayEventPresets = {
	'killNumEnemies': {
		poiType: KillEnemiesInCirclePOI,
		poiParams: {
			enemyCountMultiplier: 2,
		},
		getPosition() {
			const radius = this.getRadius()
			const minDist = Math.max(800, radius + 300)
			const maxDist = Math.max(1200, minDist)
			return PropPlacer.getInstance().getRandomValidPositionInWorld(minDist, maxDist, radius, REUSABLE_VECTOR)
		},
		getRadius() {
			return getRadiusByInGameTime() * 1.6
		},
		onPlayerEnteredFn(poi: KillEnemiesInCirclePOI, state: any) {
			EnemyEquilibriumSpawner.getInstance().temporarySpawnRateMulti += 3.0
		},
		onPlayerExitedFn(state: any) {
			EnemyEquilibriumSpawner.getInstance().temporarySpawnRateMulti -= 3.0
		},
	},

	'surviveForTime': {
		poiType: StandInCirclePOI,
		poiParams: {
			getDuration() {
				return 30
			},
		},
		getPosition() {
			const radius = this.getRadius()
			const minDist = Math.max(800, radius + 300)
			const maxDist = Math.max(1200, minDist)
			return PropPlacer.getInstance().getRandomValidPositionInWorld(minDist, maxDist, radius, REUSABLE_VECTOR)
		},
		getRadius() {
			return getRadiusByInGameTime() * 1.3
		},
	},
}

export const FountainsOfManaGameplayEventPresets = {
	'fountainsOfMana': {
		poiType: StandInCirclePOI,
		poiParams: {
			getDuration() {
				return 60
			},
			borderSpriteOverride: 'mana'
		},
		getPosition() {
			const radius = this.getRadius()
			const minDist = Math.max(800, radius + 300)
			const maxDist = Math.max(1200, minDist)
			return PropPlacer.getInstance().getRandomValidPositionInWorld(minDist, maxDist, radius, REUSABLE_VECTOR)
		},
		getRadius() {
			return getDecreasingRadiusByInGameTime()
		},
		onPlayerEnteredFn(poi: StandInCirclePOI, state: any) {
		},
		whilePlayerInZoneFn(poi: StandInCirclePOI, delta: timeInSeconds, state: any) {
			if (state.acc === undefined) {
				state.acc = 0
			}
			state.acc += delta
		},
		onPlayerExitedFn(poi: StandInCirclePOI, state: any) {
			const BONUS_DROPS = 15
			const xpDropCount = Math.floor(state.acc / 1.5) + (state.acc >= 60 ? BONUS_DROPS : 0);
			const xpPickups = []

			while (xpPickups.length < xpDropCount && InGameTime.timeElapsedInSeconds < NO_MORE_XP_TIME) {
				const nextPickup = AISystem.getInstance().xpDropShuffleBag.next()
				if (nextPickup) {
					xpPickups.push(nextPickup)
				}
			}

			splayGroundPickupsInRadius(xpPickups, poi.position.x, poi.position.y, 1200, 1700, 0.3)

			// Cleanup if and only if the player exits the poi without winning.
			// If the player wins, POI and event superclass take care of the cleanup on their own. Doing the cleanup twice causes a crash.
			if (!poi.playerWon) {
				GameplayTimedEventSystem.getInstance().onEventEnd(EventTypes.FountainsOfMana)
				poi.freeFromPool()
			}
		},
		onEventDone(poi: StandInCirclePOI, state: any) {
		}
	}
}

export const forestPlantShrines = {
	'xpFlower': {
		poiType: StandInCirclePOI,
		poiParams: {
			getDuration() {
				return 12
			},
			borderSpriteOverride: 'plant'
		},
		getPosition() {
			const radius = this.getRadius()
			const minDist = Math.max(800, radius + 300)
			const maxDist = Math.max(1200, minDist)
			return PropPlacer.getInstance().getRandomValidPositionInWorld(minDist, maxDist, radius, REUSABLE_VECTOR)
		},
		getRadius() {
			return getIncreasingRadiusByInGameTime()
		},
		onPlayerEnteredFn(poi: StandInCirclePOI, state: any) {
		},
		onPlayerExitedFn(poi: StandInCirclePOI, state: any) {
		},
		onEventDone(poi: StandInCirclePOI, state: any) {
			const xpCount = Math.round(25 + (80 * InGameTime.percentOfRunPassed() * InGameTime.percentOfRunPassed()))

			const xpPickups = []
			if (InGameTime.timeElapsedInSeconds >= NO_MORE_XP_TIME) {
				xpPickups.fill(GroundPickupConfigType.XPLarge, 0, xpCount)
			} else {
				const xpShuffleBag = AISystem.getInstance().xpDropShuffleBag
				while (xpPickups.length < xpCount) {
					const xp = xpShuffleBag.next()
					if (xp) {
						xpPickups.push(xp)
					}
				}
			}

			splayGroundPickupsInRadius(xpPickups, poi.position.x, poi.position.y, 800, 1_133, 0.075)

			poi.winDelayTime = Number.MAX_SAFE_INTEGER
		}
	},
	'superXpFlower': {
		poiType: StandInCirclePOI,
		poiParams: {
			getDuration() {
				return 30
			},
			borderSpriteOverride: 'plant'
		},
		getPosition() {
			const radius = this.getRadius()
			const minDist = Math.max(800, radius + 300)
			const maxDist = Math.max(1200, minDist)
			return PropPlacer.getInstance().getRandomValidPositionInWorld(minDist, maxDist, radius, REUSABLE_VECTOR)
		},
		getRadius() {
			return getIncreasingRadiusByInGameTime() * 1.2
		},
		onPlayerEnteredFn(poi: StandInCirclePOI, state: any) {
		},
		onPlayerExitedFn(poi: StandInCirclePOI, state: any) {
		},
		onEventDone(poi: StandInCirclePOI, state: any) {
			const xpCount = Math.round(4 * (20 + (80 * InGameTime.percentOfRunPassed() * InGameTime.percentOfRunPassed())))

			const xpPickups = []
			if (InGameTime.timeElapsedInSeconds >= NO_MORE_XP_TIME) {
				xpPickups.fill(GroundPickupConfigType.XPLarge, 0, xpCount)
			} else {
				const xpShuffleBag = AISystem.getInstance().xpDropShuffleBag
				while (xpPickups.length < xpCount) {
					const xp = xpShuffleBag.next()
					if (xp) {
						xpPickups.push(xp)
					}
				}
			}
			
			splayGroundPickupsInRadius(xpPickups, poi.position.x, poi.position.y, 800, 1_133, 0.02)

			poi.winDelayTime = Number.MAX_SAFE_INTEGER
		}
	},
	'papyrusPlant': {
		poiType: StandInCirclePOI,
		poiParams: {
			getDuration() {
				return 16
			},
			borderSpriteOverride: 'plant'
		},
		getPosition() {
			const radius = this.getRadius()
			const minDist = Math.max(800, radius + 300)
			const maxDist = Math.max(1200, minDist)
			return PropPlacer.getInstance().getRandomValidPositionInWorld(minDist, maxDist, radius, REUSABLE_VECTOR)
		},
		getRadius() {
			return getIncreasingRadiusByInGameTime()
		},
		onPlayerEnteredFn(poi: StandInCirclePOI, state: any) {
		},
		onPlayerExitedFn(poi: StandInCirclePOI, state: any) {
		},
		onEventDone(poi: StandInCirclePOI, state: any) {
			const xpPickups = []
			if (Math.random() < 0.3) {
				xpPickups.length = Math.getRandomInt(10, 15)
				xpPickups.fill(GroundPickupConfigType.RareCurrency)
			} else {
				xpPickups.length = Math.getRandomInt(30, 40)
				xpPickups.fill(GroundPickupConfigType.CommonCurrencySmall)
			}
			
			splayGroundPickupsInRadius(xpPickups, poi.position.x, poi.position.y, 800, 1_133, 0.075)

			poi.winDelayTime = Number.MAX_SAFE_INTEGER
		}
	},
	'magnetPinecone': {
		poiType: StandInCirclePOI,
		poiParams: {
			getDuration() {
				return 10
			},
			borderSpriteOverride: 'plant'
		},
		getPosition() {
			const radius = this.getRadius()
			const minDist = Math.max(800, radius + 300)
			const maxDist = Math.max(1200, minDist)
			return PropPlacer.getInstance().getRandomValidPositionInWorld(minDist, maxDist, radius, REUSABLE_VECTOR)
		},
		getRadius() {
			return getIncreasingRadiusByInGameTime()
		},
		onPlayerEnteredFn(poi: StandInCirclePOI, state: any) {
		},
		onPlayerExitedFn(poi: StandInCirclePOI, state: any) {
		},
		onEventDone(poi: StandInCirclePOI, state: any) {
			allocGroundPickup(GroundPickupConfigType.MagnetSmall, poi.position.x, poi.position.y)

			poi.winDelayTime = Number.MAX_SAFE_INTEGER
		}
	},	
}

export const killingForFlowersEventPreset = {
	'killingForFlowers': {
		poiType: StandInCirclePOI,
		poiParams: {
			getDuration() {
				return 20
			},
			borderSpriteOverride: 'heart'
		},
		getPosition() {
			return GameState.player.binaryFlagState['killing-for-flowers'].eventPosition
		},
		getRadius() {
			return getRadiusByInGameTime() * 1.3
		},
		onPlayerEnteredFn(poi: StandInCirclePOI, state: any) { },
		whilePlayerInZoneFn(poi: StandInCirclePOI, delta: timeInSeconds, state: any) {
		},
		onPlayerExitedFn(poi: StandInCirclePOI, state: any) { },
		onEventDone(poi: StandInCirclePOI, state: any) {
			splayGroundPickupsInRadius([GroundPickupConfigType.HealingLarge, GroundPickupConfigType.HealingSmall, GroundPickupConfigType.HealingSmall], poi.position.x, poi.position.y, 1200, 1700, 0.3)
		},
	},
}