<template>
	<!-- Only used for twist votes and nothing else -->
	<div class="user-icon-container">
		<div v-for="(user, index) in twist.userInfo" :key="index" :style="{ left: `${15 * index}px` }" class="user-icons">
			<PlayerAvatar v-if="user && index < 3" :icon-tag="user.iconTag" :icon-color="user.iconColor" :disabled="false" :icon-size="true" :show-background="true" :show-username="false" :show-icon="true"></PlayerAvatar>
			<div v-if="twist.userInfo.length > 3" class="more-users">
				<div class="plus"></div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import PlayerAvatar from '../item/player-avatar.vue'

export default {
	name: 'VoteIconContainer',
	components: {
		PlayerAvatar
	},
	data() {
		return {}
	},
	props: {
		twist:{
			type: Array,
			required: true
		}
	},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('endChapter', ['updateShowVoteDetailsModal']),
	},
}
</script>

<style lang="less" scoped>
.user-icon-container {
	position: relative;
	width: 91px;
	height: 100px;

	&:hover {
		.user-icons {
			animation: bounce 1s infinite;

			&:nth-child(2) {
				animation-delay: 133ms;
			}

			&:nth-child(3) {
				animation-delay: 200ms;
			}
			&:nth-child(4) {
				animation-delay: 200ms;
			}
		}
	}
	.user-icons {
		position: absolute;
		:deep(#player-avatar) {
			scale: 0.27;
			transform-origin: left top;
		}
		.more-users {
			position: relative;
			.plus {
				background-image: url('../../../../assets/ui/no-icon.png');
				width: 24px;
				height: 18px;
				background-size: cover;
				transform: rotate(45deg);
				position: absolute;
				top: -3px;
				left: 15px;
				filter: drop-shadow(0 0 1px white);
			}
		}
	}
}

@keyframes bounce {
	50% {
		transform: translateY(-4px);
	}
}
</style>
