import { Container, Sprite } from "pixi.js"
import { Vector } from "sat"
import { Buff } from "../../../buffs/buff"
import { BuffIdentifier } from "../../../buffs/buff.shared"
import { InputAction, INPUT_PRESS_ACTION_EVENT_NAME } from "../../../engine/client-player-input"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import CollisionSystem from "../../../engine/collision/collision-system"
import { Effect } from "../../../engine/graphics/pfx/effect"
import { ParticleEffectType } from "../../../engine/graphics/pfx/particle-config"
import { Renderer } from "../../../engine/graphics/renderer"
import { allocGroundPickup } from "../../../entities/pickups/ground-pickup"
import { Player } from "../../../entities/player"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { PlayerProjectile, ProjectileInitialParams } from "../../../projectiles/projectile"
import { ProjectileSystem } from "../../../projectiles/projectile-system"
import { TrajectoryModPreset, TrajectoryModPresets } from "../../../projectiles/trajectory-presets"
import EntityStatList, { StatBonus } from "../../../stats/entity-stat-list"
import { StatOperator, StatType } from "../../../stats/stat-interfaces-enums"
import { callbacks_addCallback } from "../../../utils/callback-system"
import { percentage, radians, timeInMilliseconds, timeInSeconds } from "../../../utils/primitive-types"
import { angleBetweenVectors } from "../../../utils/vector"
import { AssetManager } from "../../../web/asset-manager"
import { ResourceType } from "../../weapon-definitions"
import { AllWeaponTypes } from "../../weapon-types"
import { AutoFireSecondaryWeapon } from "./auto-fire-secondary-weapon"
import { GroundPickupConfigType } from "../../../entities/pickups/ground-pickup-types"
import { Audio } from "../../../engine/audio"

// nerd stuff
const NEARBY_ENEMY_SEARCH_DISTANCE = 1_500

const DDR_OPTIONS = [InputAction.MOVE_DOWN, InputAction.MOVE_LEFT, InputAction.MOVE_RIGHT]
const DDR_OFFSETS = {
    [InputAction.MOVE_DOWN]: {x: 0, y: 100},
    [InputAction.MOVE_LEFT]: {x: -100, y: 0},
    [InputAction.MOVE_RIGHT]: {x: 100, y: 0},
}

const PROJECTILE_START_Y_OFFSET = -70

interface GlowArrowObject {
    sprite: Sprite
    timeElapsed: timeInSeconds
}
const GLOW_ARROW_SPRITE_LIFETIME: timeInSeconds = 0.25
// end nerd stuff

export const POWERUP_BUFF_DURATION = 10_000

const DDR_ATTACK_RATE_BONUS = 0.1

const POWERUP_ATTACKS_REQ = 2
const POWERUP_JAM_EXTEND_TIME: timeInSeconds = 0.5
const POWERUP_CDR: timeInMilliseconds = 250
const POWERUP_FORCE_MIN: number = 2_500
const POWERUP_FORCE_MAX: number = 3_500

const PFX_Y_OFFSET = -70
const PFX_Z_OFFSET = -150
// const DDR_PFX_Y_OFFSET = 0
const JAM_PFX_SCALE = 2

const DDR_MISS_LOCKOUT_TIME = 1.5

export class LuteWeapon extends AutoFireSecondaryWeapon {
    weaponType: AllWeaponTypes = AllWeaponTypes.Lute

    isJamming: boolean = false
    
    enableDDR: boolean = false
    ddrRampAttackRate: boolean = false
    ddrProjectileDamageScale: number = 1
    ddrProjectileSizeBonus: number = 1
    ddrGrandFinale: boolean = false

    enablePowerUp: boolean = false
    powerUpIncreasedGroundDuration: timeInSeconds = 0
    powerUpIncreasedBuffDuration: percentage = 1
    powerUpIncreasesJamDuration: boolean = false
    powerUpDecreasesCooldown: boolean = false
    powerUpAttackRate: boolean = false

    private ddrThisJam: boolean
    private ddrAttacksHit: number = 0
    private currentDDRDirection: InputAction
    private nextDDRDirection: InputAction
    private ddrAttackRateBonus: StatBonus
    private ddrVisible: boolean = false

    private powerUpAttackCount: number = 0

    private jamTime: number
    private jamDuration: number
    private jamAttackTime: number

    private projectileCreationParams: ProjectileInitialParams

    private reuseAngleArray: radians[]
    private projectileStartPosition: Vector

    private ddrLocked: boolean = false
    private fillDDRArrow: Sprite
    private previewDDRArrow: Sprite
    private glowDDRContainer: Container
    private glowDDRArrows: Map<InputAction, GlowArrowObject>
    // private ddrJamPfx: Effect

    private jamPfx: Effect

    private boundDelayedSetDdrArrows: () => void
    private inputPressFunction: () => void

    constructor(player: Player, statList: EntityStatList) {
        super(player, statList)

        this.projectileStartPosition = new Vector()

        this.projectileCreationParams = {
			owningEntityId: player.nid,
			position: this.projectileStartPosition,
			speed: this.statList.getStat(StatType.projectileSpeed),
			aimAngleInRads: 0,
			radius: this.statList.getStat(StatType.attackSize),
			trajectoryMods: [TrajectoryModPresets.get(TrajectoryModPreset.SineWave)],
			collisionLayer: CollisionLayerBits.PlayerProjectile,
			statList: this.statList,
			resourceType: ResourceType.NONE,
			player: this.player,
			weaponType: this.weaponType,
            effectType: ParticleEffectType.PROJECTILE_LUTEPROJECTILE,
            trailType: ParticleEffectType.PROJECTILE_NONE,
            noEffectRotation: true,
		}

        this.reuseAngleArray = []
        this.boundDelayedSetDdrArrows = this.delayedSetDDRArrows.bind(this)

        this.inputPressFunction =  (this.onInputPress).bind(this)
        document.addEventListener(INPUT_PRESS_ACTION_EVENT_NAME, this.inputPressFunction)

        this.fillDDRArrow = Sprite.from('arrow-fill')
        this.fillDDRArrow['update'] = () => {}
        this.fillDDRArrow.anchor.x = 0.5
        this.fillDDRArrow.anchor.y = 0.5
        this.fillDDRArrow.zIndex = 999_999 + 1
        
        this.previewDDRArrow = Sprite.from('arrow-preview')
        this.previewDDRArrow['update'] = () => {}
        this.previewDDRArrow.anchor.x = 0.5
        this.previewDDRArrow.anchor.y = 0.5
        this.previewDDRArrow.zIndex = 999_999 + 1

        this.glowDDRContainer = new Container()
        this.glowDDRContainer['update'] = () => {}
        this.glowDDRArrows = new Map()
        for (let i =0; i < DDR_OPTIONS.length;  ++i) {
            const glowDDRArrow = Sprite.from('arrow-glow')

            glowDDRArrow['update'] = () => {}
            glowDDRArrow.anchor.x = 0.5
            glowDDRArrow.anchor.y = 0.5

            const offset = DDR_OFFSETS[DDR_OPTIONS[i]]
            glowDDRArrow.position.x = offset.x
            glowDDRArrow.position.y = offset.y

            glowDDRArrow.rotation = this.getGraphicRotationForirection(DDR_OPTIONS[i])

            glowDDRArrow.visible = false
            this.glowDDRContainer.addChild(glowDDRArrow)

            this.glowDDRArrows.set(DDR_OPTIONS[i], {
                sprite: glowDDRArrow,
                timeElapsed: 0
            })
        }

        const renderer = Renderer.getInstance()
        const cam = renderer.cameraState
        // this.ddrJamPfx = new Effect(AssetManager.getInstance().getAssetByName('lute-jam-pfx').data, cam)
        // this.ddrJamPfx.zIndex = -100

        this.jamPfx = new Effect(AssetManager.getInstance().getAssetByName('lute-jam-pfx').data, cam)
        this.jamPfx.scale = JAM_PFX_SCALE
    }

    resetStatsFunction(statList: EntityStatList) {
		defaultStatAttribute(statList)

        statList._actualStatValues.baseDamage = 25
		statList._actualStatValues.projectileCount = 1
        statList._actualStatValues.projectileSpreadAngle = 10
		statList._actualStatValues.attackRate = 1.5
		statList._actualStatValues.projectileSpeed = 800
        statList._actualStatValues.attackSize = 15

		statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 15_000
		statList._actualStatValues.reloadInterval = 50

		statList._actualStatValues.skillDuration = 4
    }

    fire() {
        if (this.isJamming) {
            this.stopJamming()
        }

        if (this.enableDDR && !this.ddrVisible && !this.ddrLocked) {
            this.currentDDRDirection = DDR_OPTIONS.pickRandom()
            this.nextDDRDirection = DDR_OPTIONS.pickRandom()
            while (this.nextDDRDirection === this.currentDDRDirection) {
                this.nextDDRDirection = DDR_OPTIONS.pickRandom()                    
            }

            this.setDDRArrowGraphicsRotation()

            // Renderer.getInstance().mgRenderer.addEffectToScene(this.ddrJamPfx)
            this.setDDRArrowsVisible(true)
        }

        Renderer.getInstance().mgRenderer.addEffectToScene(this.jamPfx)

        this.jamAttackTime = 0

        this.jamTime = 0
        this.jamDuration = this.statList.getStat(StatType.skillDuration)

        this.ddrThisJam = this.enableDDR

        this.isJamming = true
    }

    forceStopFiring() {
        if (this.isJamming) {
            this.stopJamming()
        }
    }

    override update(delta: number) {
        super.update(delta)

        if (this.isJamming) {
            this.projectileStartPosition.copy(this.player.position)
            this.projectileStartPosition.y += PROJECTILE_START_Y_OFFSET

            this.jamTime += delta
            this.jamAttackTime -= delta

            if (this.jamAttackTime <= 0) {
                this.jamAttackTime += this.getAttackTime()
                const angle = this.getRandomNearbyEnemyAngle()
                this.shootProjectile(angle)
            }

            if (this.ddrThisJam) {
                // this.ddrJamPfx.x = this.player.x
                // this.ddrJamPfx.y = this.player.y + DDR_PFX_Y_OFFSET

                this.setDDRArrowGraphicsPosition()

                this.glowDDRArrows.forEach((arrowObj: GlowArrowObject) => {
                    if (arrowObj.sprite.visible) {
                        arrowObj.timeElapsed += delta

                        if (arrowObj.timeElapsed >= GLOW_ARROW_SPRITE_LIFETIME) {
                            arrowObj.sprite.visible = false
                            return
                        }

                        arrowObj.sprite.alpha = Math.lerp(1, 0, arrowObj.timeElapsed / GLOW_ARROW_SPRITE_LIFETIME)
                    }
                })
            }
            
            this.jamPfx.x = this.player.x
            this.jamPfx.y = this.player.y + PFX_Y_OFFSET
            this.jamPfx.zIndex = this.jamPfx.y + PFX_Z_OFFSET

            if (this.jamTime >= this.jamDuration) {
                this.stopJamming()
            }
        }
    }

    shootProjectile(angle: radians, damageScale: number = 1, sizeScale: number = 1) {
        this.projectileCreationParams.speed = this.statList.getStat(StatType.projectileSpeed)
		this.projectileCreationParams.radius = this.statList.getStat(StatType.attackSize) * sizeScale
        this.projectileCreationParams.damageScale = damageScale

        const spread = this.statList.getStat(StatType.projectileSpreadAngle)
        const numProjectiles = this.statList.getStat(StatType.projectileCount)
        for (let i=0; i < numProjectiles; ++i) {
            const angleOffset = ProjectileSystem.projectileSpreadAngle(i, numProjectiles, spread)
            this.projectileCreationParams.aimAngleInRads = angle + angleOffset
            PlayerProjectile.objectPool.alloc(this.projectileCreationParams)
        }


        if (this.enablePowerUp) {
            if (this.powerUpAttackCount % POWERUP_ATTACKS_REQ === 0) {
                this.dropPowerUp()
            }

            this.powerUpAttackCount++
        }

        Audio.getInstance().playSfx('SFX_Lute_Stinger')
    }

    onPowerUpPickedUp() {
        if (this.isJamming) {
            if (this.powerUpIncreasesJamDuration) {
                this.jamDuration += POWERUP_JAM_EXTEND_TIME
            }
        } else {
            if (this.powerUpDecreasesCooldown) {
                this.cooldown.reduceNextCooldown(POWERUP_CDR)
            }
        }

        Buff.apply(BuffIdentifier.LutePowerUp, this.player, this.player, 1, POWERUP_BUFF_DURATION * this.powerUpIncreasedBuffDuration)
    }

    destroy() {
        document.removeEventListener(INPUT_PRESS_ACTION_EVENT_NAME, this.inputPressFunction)
    }

    private getRandomNearbyEnemyAngle(): radians  {
        const entities = CollisionSystem.getInstance().getEntitiesInArea(this.player.position, NEARBY_ENEMY_SEARCH_DISTANCE, CollisionLayerBits.HitEnemyOnly)
        if (entities.length) {
            const randomEntity = entities[Math.getRandomInt(0, entities.length - 1)]
            return angleBetweenVectors(this.projectileStartPosition, randomEntity.position)
        }
        return Math.getRandomFloat(0, Math.PI * 2)
    }

    private getRandomNearbyEnemiesAngle(count: number): radians[] {
        this.reuseAngleArray.length = count

        const entities = CollisionSystem.getInstance().getEntitiesInArea(this.player.position, NEARBY_ENEMY_SEARCH_DISTANCE, CollisionLayerBits.HitEnemyOnly)
        const entityCount = Math.min(count, entities.length)
        for (let i =0; i < entityCount; ++i) {
            const index = Math.getRandomInt(0, entities.length - 1)
            const collider = entities.splice(index, 1)[0]
            this.reuseAngleArray[i] = angleBetweenVectors(this.projectileStartPosition, collider.position)
        }

        if (entityCount < count) {
            for (let i = entityCount; i < count; ++i) {
                this.reuseAngleArray[i] = Math.getRandomFloat(0, Math.PI * 2)
            }
        }

        return this.reuseAngleArray
    }

    private stopJamming() {
        this.isJamming = false

        if (this.ddrThisJam) {
            // Renderer.getInstance().mgRenderer.removeFromScene(this.ddrJamPfx)
            this.setDDRArrowsVisible(false)

            if (this.ddrAttackRateBonus) {
                this.ddrAttackRateBonus.remove()
                this.ddrAttackRateBonus = null
            }

            if (this.ddrGrandFinale) {
                const angles = this.getRandomNearbyEnemiesAngle(this.ddrAttacksHit)
                for (let i =0; i < this.ddrAttacksHit; ++i) {
                    this.shootProjectile(angles[i], this.ddrProjectileDamageScale, this.ddrProjectileSizeBonus)
                }
            }

            this.ddrAttacksHit = 0
        }

        Renderer.getInstance().mgRenderer.removeFromScene(this.jamPfx)
    }

    private dropPowerUp() {
        const force = Math.getRandomFloat(POWERUP_FORCE_MIN, POWERUP_FORCE_MAX)
        const randomRot = Math.random() * Math.PI * 2
        const randomX = force * Math.cos(randomRot)
        const randomY = force * Math.sin(randomRot)

        const pickup = allocGroundPickup(GroundPickupConfigType.LutePower, this.projectileStartPosition.x, this.projectileStartPosition.y, randomX, randomY, true)

        if (this.powerUpIncreasedGroundDuration) {
            pickup.lifeRemaining += this.powerUpIncreasedGroundDuration
        }
    }

	private onInputPress(e: CustomEvent<InputAction>) {
        if (this.ddrThisJam && this.isJamming && !this.ddrLocked) {
            const inputAction = e.detail
            if (inputAction !== InputAction.MOVE_DOWN && inputAction !== InputAction.MOVE_LEFT && inputAction !== InputAction.MOVE_RIGHT) {// && inputAction !== InputAction.MOVE_UP) {
                return
            }

            if (inputAction === this.currentDDRDirection) {
                // hit!
                const angle = this.getRandomNearbyEnemyAngle()
                this.shootProjectile(angle, this.ddrProjectileDamageScale, this.ddrProjectileSizeBonus)

                const glowArrow = this.glowDDRArrows.get(this.currentDDRDirection)
                glowArrow.sprite.visible = true
                glowArrow.timeElapsed = 0

                this.currentDDRDirection = this.nextDDRDirection
                while (this.nextDDRDirection === this.currentDDRDirection) {
                    this.nextDDRDirection = DDR_OPTIONS.pickRandom()                    
                }

                this.setDDRArrowGraphicsRotation()
                this.setDDRArrowGraphicsPosition()

                this.ddrAttacksHit++

                if (this.ddrRampAttackRate) {
                    if (this.ddrAttackRateBonus) {
                        this.ddrAttackRateBonus.update(this.ddrAttacksHit * DDR_ATTACK_RATE_BONUS)
                    } else {
                        this.ddrAttackRateBonus = this.statList.addStatBonus(StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, this.ddrAttacksHit * DDR_ATTACK_RATE_BONUS) as StatBonus
                    }
                }
            } else {
                // miss!
                this.ddrLocked = true
                this.setDDRArrowsVisible(false)
                callbacks_addCallback(this, this.boundDelayedSetDdrArrows, DDR_MISS_LOCKOUT_TIME)
            }
        }
    }

    private delayedSetDDRArrows() {
        this.ddrLocked = false

        if (this.isJamming && this.ddrThisJam) {
            this.setDDRArrowsVisible(true)
        }

        this.currentDDRDirection = this.nextDDRDirection
        while (this.nextDDRDirection === this.currentDDRDirection) {
            this.nextDDRDirection = DDR_OPTIONS.pickRandom()                    
        }

        this.setDDRArrowGraphicsRotation()
    }

    private setDDRArrowsVisible(visible: boolean) {
        if (visible) {
            Renderer.getInstance().fgRenderer.addDisplayObjectToScene(this.previewDDRArrow)
            Renderer.getInstance().fgRenderer.addDisplayObjectToScene(this.fillDDRArrow)
            Renderer.getInstance().fgRenderer.addDisplayObjectToScene(this.glowDDRContainer)
        } else {
            Renderer.getInstance().fgRenderer.removeFromScene(this.previewDDRArrow)
            Renderer.getInstance().fgRenderer.removeFromScene(this.fillDDRArrow)
            Renderer.getInstance().fgRenderer.removeFromScene(this.glowDDRContainer)

            this.glowDDRArrows.forEach((arrowObj: GlowArrowObject) => {
                arrowObj.sprite.visible = false
            })
        }

        this.ddrVisible = visible
    }

    private setDDRArrowGraphicsRotation() {
        const previewRotation = this.getGraphicRotationForirection(this.nextDDRDirection)
        const currentRotation = this.getGraphicRotationForirection(this.currentDDRDirection)
        
        this.previewDDRArrow.rotation = previewRotation
        this.fillDDRArrow.rotation = currentRotation
    }

    private setDDRArrowGraphicsPosition() {
        const previewOffset = DDR_OFFSETS[this.nextDDRDirection]
        this.previewDDRArrow.x = previewOffset.x
        this.previewDDRArrow.y = previewOffset.y

        this.previewDDRArrow.x += this.player.x
        this.previewDDRArrow.y += this.player.y

        const currentOffset = DDR_OFFSETS[this.currentDDRDirection]
        this.fillDDRArrow.x = currentOffset.x 
        this.fillDDRArrow.y = currentOffset.y

        this.fillDDRArrow.x += this.player.x
        this.fillDDRArrow.y += this.player.y

        this.glowDDRContainer.x = this.player.x
        this.glowDDRContainer.y = this.player.y
    }

    private getGraphicRotationForirection(direction: InputAction): radians {
        switch (direction) {
            case InputAction.MOVE_DOWN:
                return Math.PI/2
            case InputAction.MOVE_LEFT:
                return Math.PI
            case InputAction.MOVE_RIGHT:
                return 0
            case InputAction.MOVE_UP:
                return -Math.PI/2
        }
    }

    private getAttackTime(): number {
        return 1 / this.statList.getStat(StatType.attackRate)
    }
}