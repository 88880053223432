<template>
	<div id="warning-message">
		<TransitionGroup name="fade">
			<div v-for="(message, index) in warningMessages" :key="index" class="message">
				<div class="warning-message-wrapper">
					<div class="decoration">
						<img src="../../../../assets/ui/indicators/offscreen-warning.png" alt="" />
					</div>
					<div class="text-wrapper">
						<div class="title" :lang="this.$i18n.locale">{{ message.title }}</div>
						<div class="subtitle" :lang="this.$i18n.locale">{{ message.subtitle }}</div>
					</div>
				</div>
			</div>
		</TransitionGroup>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'WarningMessage',
	components: {},
	data() {
		return {}
	},
	props: {},
	computed: {
		...mapState('ui', ['warningMessages']),
	},
	methods: {
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#warning-message {
	.fade-enter-active{
		animation: vertical-slide-in 0.5s;
	}

	.fade-leave-active {
		animation: vertical-slide-in 0.5s;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
	position: absolute;
	top: 382px;
	right: 5px;
	.message {
		width: fit-content;
		height: fit-content;
		.warning-message-wrapper {
			padding: 10px 10px;
			display: flex;
			align-content: center;
			
			background-color: rgba(5, 25, 45, 0.75);
			// offset-x / offset-y / blur-radius / spread-radius / color
			box-shadow: 0px 0px 40px 50px rgba(5, 25, 45, 0.75);
			min-width: 200px;
			min-height: 100px;
			.decoration {
				img{
					width: 90px;
					height: auto;
					animation: blinker 1s linear infinite;
				}
			}
			.text-wrapper {
				margin: 0px 20px;
				.title {
					font-size: 40px;

					 
				}

				.subtitle {
					font-size: 30px;
					margin-bottom: -0.5em;
				}
				div {
					color: @green;
					text-transform: uppercase;
					text-align: center;
					&[lang='en'] {
						.regularFont(@enRegularFontPixl);
					}
					&[lang='zh'] {
						.regularFont(@zhHansHeaderFont);
					}
				}
			}
		}
	}
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
@keyframes horizontal-slide-in {
	0% {
		transform: translateX(-100%);
	}

	60% {
		transform: translateX(20%);
	}

	100% {
		transform: translateX(0%);
	}
}

@keyframes horizontal-slide-out {
	0% {
		transform: translateX(0%);
	}

	40% {
		transform: translateX(10%);
	}

	100% {
		transform: translateX(-100%);
	}
}
</style>
