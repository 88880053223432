<template>
	<div class="summary">
		<Table :disable-header="true" :large-font="true" :table-row-data="endRunPointsSummary" color="green"></Table>
		<div class="break"></div>
		<Table class="table-text-white" style="margin-bottom: 10px;" :disable-header="true" :large-font="true" :table-row-data="totalPoints" color="white"></Table>
		<div class="vote-countdown-container" :lang="this.$i18n.locale">
			<div v-if="isSelectedStorySolo"></div>
			<div v-else-if="isVotingOpen">Voting ends in {{ voteEndTime }}</div>
			<div v-else :lang="this.$i18n.locale">Voting has ended: next Chapter is open!</div>
		</div>
		<div v-if="getIsPlayerWinner" class="row-margin">
			<ProgressBarWrapper :class="'large green swipe twinkle'" :value="`100`" :max-value="`100`" :subtitle="''" :title="''"></ProgressBarWrapper>
		</div>
		<div v-else class="row-margin">
			<ProgressBarWrapper :class="'large green'" :value="voteScore" :max-value="voteScoreThreshold" :subtitle="''" :title="'Vote Progress'"></ProgressBarWrapper>
		</div>
		<div class="votes-cast-container" :lang="this.$i18n.locale">
			<BookContainer :items="indexedTwists" :voting-visible="true" :disable-books="!isVotingOpen" :is-Winner="playerWinner || getPlayerMeetsVoteThreshold" :on-select-fn="updateSelectedTwist" :selected-fn="bookSelected" :progress-bar-max-votes="maxVotes"></BookContainer>
		</div>
	</div>
</template>

<script lang="ts">
import { mapGetters, mapState, mapMutations } from 'vuex'
import Panel from '../panel/panel.vue'
import Table from '../table/table.vue'
import Header from '../dividers-headers/header.vue'
import { formatDistance } from 'date-fns'
import parseISO from 'date-fns/parseISO'
import ProgressBarWrapper from '../bars-sliders/progress-bar-wrapper.vue'
import BookContainer from '../container/book-container.vue'
import { MapOption } from '../../../world-generation/world-data'

export default {
	name: 'RunSummary',
	components: {
		Panel,
		Table,
		Header,
		ProgressBarWrapper,
		BookContainer,
	},
	data() {
		return {
			endDate: new Date('2022-11-25T03:10:45.507Z'),
		}
	},
	props: {},
	computed: {
		...mapGetters('endChapter', ['getEndGameTimeInMinutesSeconds', 'getIsPlayerWinner', 'getPlayerMeetsVoteThreshold']),
		...mapGetters('story', ['getSelectedStory', 'votingTimeLeft', 'isVotingOpen', 'isSelectedStorySolo']),
		...mapState('endChapter', ['endChapterData', 'endRunResults', 'indexedTwists', 'selectedTwist', 'voteScoreThreshold', 'voteScore', 'endingLevel']),
		...mapState('story', ['selectedChapter', 'oldSelectedChapter']),
		...mapState('time', ['date']),
		playerWinner() {
			if (this.endRunResults) {
				return this.endRunResults.isWin
			}
			return false
		},

		totalPoints() {
			let formattedData = []
			formattedData.push({ rowName: 'Total Score Earned', value: this.endChapterData.totalScore })
			return formattedData
		},
		endRunPointsSummary() {
			let formattedData = []
			formattedData.push(
				{ rowName: `Level [${this.endingLevel}]`, value: this.endChapterData.scoreBreakdown.fromLevel },
				{ rowName: `Enemies Killed [${this.endChapterData.sumOfEnemiesKilled}]`, value: this.endChapterData.scoreBreakdown.fromKills },
				{ rowName: `Acts & Events`, value: this.endChapterData.scoreBreakdown.fromEvents },
			)
			return formattedData
		},
		voteEndTime() {
			const endTime = parseISO(this.getSelectedStory.nextTally)
			return formatDistance(endTime, this.date)
		},
		maxVotes() {
			if (this.indexedTwists) {
				const voteTotal = this.indexedTwists.reduce((a, b) => a + parseInt(b.count, 10), 0)
				return Math.max(voteTotal, 1)
			}
			return 1
		},
		mapPrettyName() {
			switch (this.getSelectedStory.mapSelection) {
				case MapOption.Forest:
					return 'story_select.map_forest'
				case MapOption.Tundra:
					return 'story_select.map_mountain'
			}
		}
	},
	methods: {
		...mapMutations('endChapter', ['updateSelectedTwist']),
		bookSelected(book) {
			if (this.selectedTwist != null || this.selectedTwist != undefined) {
				return book.id == this.selectedTwist.id
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');
.summary {
	width: 670px;
	height: 717px;
	padding: 10px 20px;
	text-align: center;

	.top-container {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.text-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.right-image {
			transform: scaleX(-1);
		}
	}

	.inner-content {
		display: flex;
		flex-direction: column;
	}
	.break {
		margin: 12px 20px;
		height: 3px;
		width: 100%;
		background-color: white;
	}

	.vote-countdown-container,
	.votes-cast-container {
		color: @green;
		&[lang='en'] {
			.regularFont(@enRegularFontPixl);
			font-size: 32px;
			letter-spacing: 2px;
		}
		&[lang='zh'] {
			.regularFont(@zhHansSecondaryFont);
			font-size: 32px;
		}
	}
	.row-margin {
		margin: 15px 0;
	}
}
</style>
