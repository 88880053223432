import assert from "assert"
import { Effect } from "../engine/graphics/pfx/effect"
import { AffectorConfig, ScaleAffectorMode } from "../engine/graphics/pfx/emitterConfig"
import { Renderer } from "../engine/graphics/renderer"
import { Enemy } from "../entities/enemies/enemy"
import { Player } from "../entities/player"
import { attachments_addAttachment } from "../utils/attachments-system"
import { add, sub } from "../utils/math"
import { AnimatedNumber, simpleAnimation_addScaleAnimation, simpleAnimation_removeAnimations } from "../utils/simple-animation-system"
import { Buff } from "./buff"
import { VisualBuffData } from "./buff-definition-visuals"
import { BuffIdentifier } from "./buff.shared"

export interface AppliedBuffVisuals {
	buffId: BuffIdentifier
	effects: Effect[]
	filters: PIXI.Filter[]
}

export type AppliedBuffVisualsArray = [AppliedBuffVisuals, AppliedBuffVisuals, AppliedBuffVisuals]
export type BuffableEntity = Enemy | Player

const DEFAULT_SCALE_OUT_TIME = 0.1

export function handleBuffChange(entity: BuffableEntity, buffId: BuffIdentifier, removeBuff: boolean) {
	const visualBeingRemoved = entity.attachedBuffEffects.get(buffId)
	const buff = Buff.getBuff(entity, buffId)
	
	//console.log(`\n------------------------\nhandleBuffChange add:<${buffId}> replace:<${visualBeingRemoved?.buffId}> idx:${idx}`)
	const renderer = Renderer.getInstance()
	if (visualBeingRemoved && removeBuff) {
		// remove existing effect animations and add scale out
		visualBeingRemoved.effects.forEach((effect) => {
			simpleAnimation_removeAnimations(effect)
			// TODO This breaks any scaling that has already been applied to PFX, disabling for now
			//simpleAnimation_addScaleAnimation(effect, (t) => easeOutBounceReversed(t, DEFAULT_SCALE_OUT_TIME))

			renderer.removeEffectFromScene(effect)
			/* callbacks_addCallback(
				entity,
				() => {
					renderer.removeEffectFromScene(effect)
				},
				DEFAULT_SCALE_OUT_TIME,
			) */
		})

		visualBeingRemoved.filters.forEach((filter) => {
			simpleAnimation_removeAnimations(filter)
			if ((filter as any).scale) {
				simpleAnimation_removeAnimations((filter as any).scale)
			}
			Renderer.getInstance().stage.filters.remove(filter)
			if (entity instanceof Player) {
				entity.riggedModel.filters.remove(filter)
			} else if (entity instanceof Enemy && entity.isBoss) {
				entity.gfx.removeFilter(filter)
			}
		})
		const hasFeetPfxOverride = VisualBuffData.get(buffId).replaceFeetPfx

		if (hasFeetPfxOverride) {
			if (entity instanceof Player) {
				entity.removeDustPfx()
				entity.setDustEffects('dust')
			}
		}
		entity.attachedBuffEffects.delete(buffId)
		
	} else if (!removeBuff && !visualBeingRemoved) {
		const visualBuffDef = VisualBuffData.get(buffId)
		console.assert(`no visuals configured for ${buffId}, see VisualBuffData`)
		if (visualBuffDef) {
			let pfxConfigs = visualBuffDef?.pfxConfig
			if (!pfxConfigs) {
				pfxConfigs = []
			} else if (!Array.isArray(pfxConfigs)) {
				pfxConfigs = [pfxConfigs]
			}

			const appliedVisuals: AppliedBuffVisuals = { buffId, effects: [], filters: [] }

			entity.attachedBuffEffects.set(buffId, appliedVisuals)
			if (visualBuffDef.shouldApplyFn && !visualBuffDef.shouldApplyFn(entity, buff)) {
				return
			}
			const hasFeetPfxOverride = VisualBuffData.get(buffId).replaceFeetPfx

			pfxConfigs.forEach((pfxConfig) => {
				if (pfxConfig) {
					if (hasFeetPfxOverride){
						// This assumes we're dealing with a single pfxConfig for dust with no special offset function
						if (entity instanceof Player) {
							entity.removeDustPfx()
							entity.setDustEffects(pfxConfig.pfx)
						}
					} else {
						const effect = Renderer.getInstance().addEffectToScene(pfxConfig.pfx, entity.x, entity.y)

						attachments_addAttachment(effect, entity, (t) => {
							const visualServerPosDiff = sub(entity.position, entity)
							return add(visualServerPosDiff, pfxConfig.offset(t))
						}, pfxConfig.renderBehind)

						if (pfxConfig.scale !== undefined) {
							effect.scale *= pfxConfig.scale
						}

						if (pfxConfig.scaleFn !== undefined) {
							addEffectScaleAnim(effect, pfxConfig.scaleFn)
						}

						effect.prewarm()

						appliedVisuals.effects.push(effect)
					}
				}
			})

			// apply any screen filters for this buff
			const screenFilters = visualBuffDef.screenFilters
			/* if (debugConfig.render.disableScreenFilters) {
				screenFilters = null
			} */
			if (screenFilters && (entity as any).isPlayersEntity) {
				screenFilters.forEach((filter) => {
					const filterInstance = filter(visualBuffDef.duration)
					appliedVisuals.filters.push(filterInstance)
					Renderer.getInstance().stage.filters.push(filterInstance)
				})
			}

			// apply any entity filters for this buff
			const entityFilters = visualBuffDef.entityFilters
			if (entityFilters) {
				entityFilters.forEach((filter) => {
					const filterInstance = filter(visualBuffDef.duration)
					if (visualBuffDef.modifyEntityFilterFn) {
						visualBuffDef.modifyEntityFilterFn(filterInstance, entity, buff)
					}
					appliedVisuals.filters.push(filterInstance)
					if (entity instanceof Player) {
						entity.riggedModel.filters.push(filterInstance)
					} else if (entity instanceof Enemy && entity.isBoss) {
						entity.gfx.addFilter(filterInstance)
					}
				})
			}
		}
	}
}

export function addEffectScaleAnim(effect: Effect, scale: AnimatedNumber) {
	// how this works:
	//  1) emitters, depending on mode, inherit the scale of their parent effect
	//  2) so here we add an effect scale anim to scale the effect, which in turn scales the emitters
	//  3) we add a scale affector so the pfx inherit the emitter scale

	simpleAnimation_addScaleAnimation(effect, scale)

	const affector: AffectorConfig = {
		id: 'scale',
		cfg: { mode: ScaleAffectorMode.InheritEmitter },
	}

	effect.emitters.forEach((e) => {
		e.addAffector(affector)
	})
}

export function clearAttachedPfx(entity: BuffableEntity) {
	entity.attachedBuffEffects.forEach((value: AppliedBuffVisuals, key) =>{
		handleBuffChange(entity, key, true)
	})

	assert(entity.attachedBuffEffects.size === 0)
}

