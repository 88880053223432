import { GameState } from "../../engine/game-state"
import { gameUnits, radians, timeInMilliseconds, timeInSeconds } from "../../utils/primitive-types"
import { Enemy, EnemyInitialParams } from "./enemy"
import { Player } from "../player"
import { Vector } from "sat"
import { ENEMY_NAME } from "./enemy-names"
import { ObjectPoolTyped } from "../../utils/third-party/object-pool"
import { CollisionLayerBits } from "../../engine/collision/collision-layers"
import ShuffleBag from "../../utils/shuffle-bag"
import { InGameTime } from "../../utils/time"
import { angleDiff, distanceSquaredVV, isBetweenAngles, sub, VectorXY } from "../../utils/math"
import { AGGRESSIVE_ENEMY_SPAWN_DISTANCE, AGGRESSIVE_RECYCLE_CULL_TIME, AGGRESSIVE_RECYCLE_DISTANCE_FROM_PLAYER, AGGRESSIVE_RECYCLE_SPAWN_DISTANCE, ENEMY_GROUP_RAD_OFFSET, ENEMY_RECYCLE_DISTANCE_FROM_PLAYER, ENEMY_RECYCLE_SPAWN_DISTANCE, ENEMY_RECYCLE_TIME, ENEMY_SPAWN_DISTANCE, getEnemyTargetHealth, RENDERABLE_ENEMY_CULL_DISTANCE, STANDARD_ENEMY_SIZE } from "./enemy-spawn-config"
import { ShuffleBagEntryOverTime, ITEM_DROP_SHUFFLE_BAG_ENTRIES } from "../../game-data/levelling"
import { checkIfPointIsInView } from "../../engine/graphics/camera-logic"
import CollisionSystem from "../../engine/collision/collision-system"
import { angleInRadsFromVector } from "../../utils/vector"
import { CURRENCY_DROP_SHUFFLE_BAG_ENTRIES } from "../../game-data/currency"
import { debugConfig } from "../../utils/debug-config"
import { GameClient } from "../../engine/game-client"
import { moveToPosition } from "./ai-util"
import { remove } from "lodash"
import assert from "assert"
import { Buff } from "../../buffs/buff"
import { BuffIdentifier } from "../../buffs/buff.shared"
import { Renderer } from "../../engine/graphics/renderer"
import { EffectConfig } from "../../engine/graphics/pfx/effectConfig"
import { AssetManager } from "../../web/asset-manager"
import { attachments_addAttachment } from "../../utils/attachments-system"
import { callbacks_addCallback } from "../../utils/callback-system"
import { GroundPickupConfigType, GroundPickupType } from "../pickups/ground-pickup-types"
import { Audio } from "../../engine/audio"
import { dealAOEDamageSimple } from "../../projectiles/explosions"
import { UI } from "../../ui/ui"
import { PLAYER_DAMAGE_BIGGER_THEY_ARE, PLOT_TWIST_EXPLOSIONS_PER_BATCH } from "../../game-data/player-formulas"
import WeightedList from "../../utils/weighted-list"
import { Colors } from "../../utils/colors"

const MONSTER_MERGE_HEALTH_MULTIPLIER = 4
const MONSTER_MERGE_DROP_MULTIPLIER = 4

const BIGGER_THEY_ARE_AOE_RADIUS = 1650
const BIGGER_THEY_ARE_PFX_WIDTH = 813
const BIGGER_THEY_ARE_SCALE = 1.25
const BIGGER_THEY_ARE_COUNTDOWN = 5

interface BossInfo {
    enemy: Enemy
    currentTime: timeInMilliseconds
	countDown: number
}

export const MAX_SHAMBLER_COUNT_FOR_MAGENET_SPAWN = 300
export const MAGNET_SHAMBLER_HEALTH_MULTI =  6

export default class AISystem {
	static getInstance() {
		if (!AISystem.instance) {
			AISystem.instance = new AISystem()
		}

		return AISystem.instance
	}
	static destroy() {
		AISystem.instance.enemyPoolMap.forEach((value, key) => {
			const allEnemies = value.getAllObjects()
			allEnemies.forEach((e) =>{
				const enemy = e as Enemy
				enemy.destroy()
			})
			value.destroy()
		})
		AISystem.instance.enemyPoolMap.clear()
		AISystem.instance = null
	}
	private static instance: AISystem

	private enemyPoolMap: Map<ENEMY_NAME, ObjectPoolTyped<Enemy, EnemyInitialParams>> = new Map()

	xpDropShuffleBag: ShuffleBag
	nextShuffleBagConfig: ShuffleBagEntryOverTime
	nextShuffleBagConfigIndex: number

	currencyDropShuffleBag: ShuffleBag
	nextCurrencyShuffleBagConfig: ShuffleBagEntryOverTime
	nextCurrencyShuffleBagConfigIndex: number

	twistExplosionNextCooldown: timeInMilliseconds = -1
	twistExplosionsRemaining: number = PLOT_TWIST_EXPLOSIONS_PER_BATCH

	fuseRecycledShambers: boolean = false
	shamblerFusionRecycleCount: number = 0
	shamblerFusionRecycleCooldown: number = 0

	bruteTrioKillCount: number = 0

	xpEmptyCountBonusPercent: number = 0

	// Giant Shambler's Everyday Carry Twist
	shamblerMagnetTwist: boolean = false
	basicShamblerCount: number = 0

	// Merge Monster Twist
	monsterMergeTwist: boolean = false
	monsterMergeRadiusPadding: number = 0
	monsterMergeCooldown: number = 0 
	monsterMergeAmount: number = 0
	monsterMergeChance: number = 0
	monsterMergeQueue: Enemy[][] = []
	monsterMergeScale: number = 1
	monsterMergeEffectConfig: EffectConfig

	// Spooky Ghost twists
	spookyGhostTwist: boolean = false
	spookyGhostCooldown: timeInSeconds
	spookyGhostChance: number
	spookyGhostRadius: number
	spookyGhostCount: number
	
	lastGroupDirectionRad: number = undefined

	// Binary flag? 
	theBiggerTheyAreFlag: Boolean = false
	theBiggerTheyAreBosses: BossInfo[] = []

	private reuseEnemySpawnVector: Vector = new Vector()

	private reuseMinSpawnBound: Vector = new Vector()
	private reuseMaxSpawnBound: Vector = new Vector()

	private recycledShamblersFusionCount: number = 0
	private nextRecycledShamblersFusionCooldown: number = 0
	private nextMonsterMergeCooldown: number = 0
	private nextSpookyGhostCooldown: number = 0

	//TODO restore spatialEnemies
	private constructor() {
		//this.spatialEnemies = spatialEnemies

		this.xpDropShuffleBag = new ShuffleBag(Date.now(), true)
		this.populateShuffleBag(ITEM_DROP_SHUFFLE_BAG_ENTRIES[0], this.xpDropShuffleBag, this.xpEmptyCountBonusPercent) // don't really need to check the time for these
		this.nextShuffleBagConfig = ITEM_DROP_SHUFFLE_BAG_ENTRIES[1]
		this.nextShuffleBagConfigIndex = 1

		this.currencyDropShuffleBag = new ShuffleBag(Date.now(), true)
		this.populateShuffleBag(CURRENCY_DROP_SHUFFLE_BAG_ENTRIES[0], this.currencyDropShuffleBag)
		this.nextCurrencyShuffleBagConfig = CURRENCY_DROP_SHUFFLE_BAG_ENTRIES[1]
		this.nextCurrencyShuffleBagConfigIndex = 1

		const enemyDefinitions = GameClient.getInstance().enemyDefintions
		console.groupCollapsed('Initializing enemy object pools')
		for (let i = 0; i < enemyDefinitions.length; ++i) {
			const enemyDef = enemyDefinitions[i]

			const pool = new ObjectPoolTyped<Enemy, EnemyInitialParams>(() => new Enemy(enemyDef, new Vector()), {}, enemyDef.objectPoolInitialSize, enemyDef.objectPoolGrowthSize, `${enemyDef.name}-pool`)
			console.log(`${enemyDef.name.padStart(35)} - size ${enemyDef.objectPoolInitialSize} (+${enemyDef.objectPoolGrowthSize})`)

			this.enemyPoolMap.set(enemyDef.name, pool)
		}
		console.groupEnd()
	}

	removePlayerTarget(player: Player) {
		GameState.enemyList.forEach((enemy: Enemy) => {
			if (enemy.target) {
				if (enemy.target === player) {
					enemy.target = null
				}
			}
		})
	}

	update(delta: timeInSeconds): void {
		// Make each AI agent aware of the players and fellow AI agents that are near itself
		const playerPos = GameState.player.position

		let recycleReappearDist: number
		if (debugConfig.enemy.aggressiveRecycling) {
			recycleReappearDist = AGGRESSIVE_RECYCLE_SPAWN_DISTANCE
		} else {
			recycleReappearDist = ENEMY_RECYCLE_SPAWN_DISTANCE
		}
		recycleReappearDist *= debugConfig.enemy.recycleOppositeOfPlayer ? 1 : -1

		GameState.enemyList.forEach((enemy: Enemy) => {
			// Delay enemy deletion by one frame so things like the corpse manager can do things once it is determined the entity should be deleted
			if (enemy.toBeDeleted) {
				if(enemy.isBoss && this.theBiggerTheyAreFlag){
					this.theBiggerTheyAreBosses.push({enemy: enemy, currentTime: InGameTime.highResolutionTimestamp(), countDown: BIGGER_THEY_ARE_COUNTDOWN})
					UI.getInstance().emitMutation('ui/addNewWarningMessage', ['Warning', `Explosion in: ${BIGGER_THEY_ARE_COUNTDOWN}`, this.theBiggerTheyAreBosses.length - 1])
				}
				this.removeEnemy(enemy)
			} else {

				if (enemy.distanceToPlayer2 >= RENDERABLE_ENEMY_CULL_DISTANCE) {
					if (enemy.gfx.isRendering) {
						enemy.gfx.removeFromScene()
					}
				} else {
					if (!enemy.gfx.isRendering) {
						enemy.gfx.addToScene()
					}
				}
			
				enemy.recycleTime += delta
				if (!enemy.isBoss && !enemy.immuneToRecycle && !enemy.isChoreoSpawn && enemy.recycleTime >= ENEMY_RECYCLE_TIME) {
					enemy.recycleTime -= ENEMY_RECYCLE_TIME

					if (debugConfig.enemy.aggressiveRecycling) {
						if (enemy.distanceToPlayer2 >= AGGRESSIVE_RECYCLE_DISTANCE_FROM_PLAYER) {
							enemy.offScreenTime += delta

							if (enemy.offScreenTime >= AGGRESSIVE_RECYCLE_CULL_TIME) {
								this.onEnemyToBeRecycled(enemy)
								if (debugConfig.enemy.noTeleportOnRecycle) {
									this.removeEnemy(enemy)
								} else {
									// little weird since this isn't really supposed to be mutable, but when we teleport it's fixed right away
									enemy.directionToPlayer.normalize()
									enemy.directionToPlayer.scale(recycleReappearDist)
									enemy.directionToPlayer.add(playerPos)
									enemy.offScreenTime = 0

									enemy.teleport(enemy.directionToPlayer)
								}
							}
						} else {
							enemy.offScreenTime = 0
						}
					} else {
						if (enemy.distanceToPlayer2 >= ENEMY_RECYCLE_DISTANCE_FROM_PLAYER) {
							this.onEnemyToBeRecycled(enemy)
							if (debugConfig.enemy.noTeleportOnRecycle) {
								this.removeEnemy(enemy)
							} else {
								// little weird since this isn't really supposed to be mutable, but when we teleport it's fixed right away
								enemy.directionToPlayer.normalize()
								enemy.directionToPlayer.scale(recycleReappearDist)
								enemy.directionToPlayer.add(playerPos)

								enemy.teleport(enemy.directionToPlayer)
							}
						}
					}
				}
			}
		})

		if (this.nextShuffleBagConfig && InGameTime.timeElapsedInSeconds >= this.nextShuffleBagConfig.start) {
			this.populateShuffleBag(this.nextShuffleBagConfig, this.xpDropShuffleBag, this.xpEmptyCountBonusPercent)

			this.nextShuffleBagConfig = ITEM_DROP_SHUFFLE_BAG_ENTRIES[++this.nextShuffleBagConfigIndex]
		}

		if (this.nextCurrencyShuffleBagConfig && InGameTime.timeElapsedInSeconds >= this.nextCurrencyShuffleBagConfig.start) {
			this.populateShuffleBag(this.nextCurrencyShuffleBagConfig, this.currencyDropShuffleBag)

			this.nextCurrencyShuffleBagConfig = CURRENCY_DROP_SHUFFLE_BAG_ENTRIES[++this.nextCurrencyShuffleBagConfigIndex]
		}

		if (this.fuseRecycledShambers) {
			this.nextRecycledShamblersFusionCooldown -= delta
		}

		if (this.monsterMergeTwist) {
			this.updateMergeQueue(delta)
			this.nextMonsterMergeCooldown -= delta
		}

		if (this.spookyGhostTwist) {
			this.nextSpookyGhostCooldown -= delta
		}
		
		for (let index = 0; index < this.theBiggerTheyAreBosses.length; index++) {
			const enemy = this.theBiggerTheyAreBosses[index];
			const now = InGameTime.highResolutionTimestamp()
			
			if( now - enemy.currentTime >= 1000){
				enemy.countDown = enemy.countDown - 1
				enemy.currentTime = now
				UI.getInstance().emitMutation('ui/updateWarningMessage', ['Warning', `Explosion in: ${enemy.countDown}`, index])
			}

			if(this.theBiggerTheyAreBosses[index].countDown <= 0){
				this.biggerTheyAreExplosion(index)
			}
		}

	}

	biggerTheyAreExplosion(bossIndex){
		const bossConfig = this.theBiggerTheyAreBosses[bossIndex];
		dealAOEDamageSimple(CollisionLayerBits.EnemyProjectile, BIGGER_THEY_ARE_AOE_RADIUS, bossConfig.enemy.position, PLAYER_DAMAGE_BIGGER_THEY_ARE, bossConfig.enemy, undefined, undefined,undefined, 4)
		const effectConfig = AssetManager.getInstance().getAssetByName('bigger-explosion').data
		Renderer.getInstance().addOneOffEffectByConfig(effectConfig, bossConfig.enemy.x, bossConfig.enemy.y, 9999, (BIGGER_THEY_ARE_AOE_RADIUS * 2) / BIGGER_THEY_ARE_PFX_WIDTH * BIGGER_THEY_ARE_SCALE, undefined, undefined, false)
		this.theBiggerTheyAreBosses.shift()
		UI.getInstance().emitMutation('ui/removeWarningMessage', bossIndex)
	}

	addEnemiesToMergeQueue(enemies: Enemy[]) {
		this.monsterMergeQueue.push(enemies)
		this.nextMonsterMergeCooldown = this.monsterMergeCooldown
	}

	updateMergeQueue(delta: timeInSeconds) {
		const removeGroups = []
		this.monsterMergeQueue.forEach((enemyGroup) => {
			if (enemyGroup.length !== this.monsterMergeAmount || enemyGroup.some((e) => e.isDead())) {
				removeGroups.push(enemyGroup)
			} else {
				let readyToMerge = true
				for (let i = 0; i < enemyGroup.length; i++) {
					const enemy = enemyGroup[i]
					if (distanceSquaredVV(enemy.position, enemy.targetPosition) > enemy.colliderComponent.bounds.width ** 2) {
						readyToMerge = false
						moveToPosition(enemy, enemy.targetPosition, enemy.movementSpeed, delta)
					} else {
						enemy.velocity.x = 0
						enemy.velocity.y = 0
					}
				}
				if (readyToMerge) {
					removeGroups.push(enemyGroup)
					AISystem.getInstance().onEnemyMerge(enemyGroup)
				}
			}
		})
		removeGroups.forEach((group) => {
			group.forEach((e: Enemy) => {
				e.toBeMerged = false
			})
		})
		remove(this.monsterMergeQueue, (e) => removeGroups.includes(e))
	}

	private onEnemyMerge(enemies: Enemy[]) {
		assert(enemies.length === this.monsterMergeAmount, `WARNING: Trying to merge incorrect number of enemies. Tried to merge ${enemies.length} but correct value is ${this.monsterMergeAmount}`)

		const enemyType = enemies[0].name as ENEMY_NAME
		const {x, y} = enemies[0].position
		const effect = Renderer.getInstance().addOneOffEffectByConfig(this.monsterMergeEffectConfig, x, y, y + 200, 4, 0.5, true)
		enemies.forEach((enemy) => {
			this.removeEnemy(enemy)
		})

		const pool = this.enemyPoolMap.get(enemyType)
		const mergedEnemy = pool.alloc({
			x,
			y,
			isMerged: true
		})

		mergedEnemy.setCircularScale(mergedEnemy.scale, mergedEnemy.scale * this.monsterMergeScale) // todo: account for non-circular colliders?
		mergedEnemy.maxHealth = enemies[0].maxHealth * MONSTER_MERGE_HEALTH_MULTIPLIER
		mergedEnemy._currentHealth = mergedEnemy.maxHealth
		mergedEnemy.bonusDropMult = MONSTER_MERGE_DROP_MULTIPLIER
		attachments_addAttachment(effect, mergedEnemy)
		callbacks_addCallback(this, () => Renderer.getInstance().removeEffectFromScene(effect), 0.5)
		Audio.getInstance().playSfx('SFX_Monster_Merge');
	}

	mergeCooldownUp() {
		return this.nextMonsterMergeCooldown <= 0
	}

	mergeRoll() {
		return Math.random() <= this.monsterMergeChance
	}

	setShamblerMagnetTwist() {
		this.shamblerMagnetTwist = true
		this.basicShamblerCount = 0
	}

	setMonsterMergeTwist(mergeAmount: number, cooldown: timeInSeconds, mergeChance: number, mergeRadiusPadding: number, mergeScale: number) {
		this.monsterMergeTwist = true
		this.monsterMergeRadiusPadding = mergeRadiusPadding
		this.monsterMergeCooldown = cooldown
		this.monsterMergeAmount = mergeAmount
		this.monsterMergeChance = mergeChance
		this.monsterMergeScale = mergeScale
		this.monsterMergeEffectConfig = AssetManager.getInstance().getAssetByName('merge-charge').data
	}

	setSpookyGhostsTwist(cooldown: timeInSeconds, chanceToGhost: number, ghostRadius: number, enemiesToGhost: number) {
		this.spookyGhostTwist = true
		this.spookyGhostCooldown = cooldown
		this.spookyGhostChance =  chanceToGhost
		this.spookyGhostRadius = ghostRadius
		this.spookyGhostCount = enemiesToGhost
	}

	spookyGhostCooldownUp() {
		return this.nextSpookyGhostCooldown <= 0
	}

	spookyGhostRoll() {
		return Math.random() <= this.spookyGhostChance
	}

	applySpookyGhost(enemy: Enemy) {
		Buff.apply(BuffIdentifier.SpookyGhosted, enemy, enemy)
		const nearbyEnemies = CollisionSystem.getInstance().getEntitiesInArea(enemy.position, this.spookyGhostRadius, CollisionLayerBits.HitEnemyOnly)
		let enemiesGhosted = 1
		for (let i = 0; i < nearbyEnemies.length && enemiesGhosted < this.spookyGhostCount; i++) {
			const nearbyEnemy = nearbyEnemies[i].owner as Enemy
			if (!nearbyEnemy.ghosted) {
				Buff.apply(BuffIdentifier.SpookyGhosted, nearbyEnemy, nearbyEnemy)
				enemiesGhosted++
			}
		}
		this.nextSpookyGhostCooldown = this.spookyGhostCooldown
		Audio.getInstance().playSfx('SFX_Spooky_Ghost');
	}

	setTheBiggerTheyAreTwist(toggle: boolean){
		this.theBiggerTheyAreFlag = toggle
	}

	spawnGroup(enemyAI: ENEMY_NAME, count: number): Enemy[] {		
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)

		this.reuseEnemySpawnVector.x = debugConfig.enemy.aggressiveRecycling ? AGGRESSIVE_ENEMY_SPAWN_DISTANCE : ENEMY_SPAWN_DISTANCE
		this.reuseEnemySpawnVector.y = 0

		let direction = Math.getRandomFloat(0, Math.PI * 2)
		
		while(this.lastGroupDirectionRad !== undefined && Math.abs(angleDiff(direction, this.lastGroupDirectionRad)) < ENEMY_GROUP_RAD_OFFSET) {
			direction = Math.getRandomFloat(0, Math.PI * 2)
		}
		this.lastGroupDirectionRad = direction

		this.reuseEnemySpawnVector.rotate(direction)

		const playerPosition = GameState.player.position

		const distApart = (count * STANDARD_ENEMY_SIZE) / 2
		const halfDist = distApart / 2

		this.reuseMinSpawnBound.x = -halfDist
		this.reuseMinSpawnBound.y = 0

		this.reuseMaxSpawnBound.x = halfDist
		this.reuseMaxSpawnBound.y = distApart

		this.reuseMinSpawnBound.rotate(direction)
		this.reuseMaxSpawnBound.rotate(direction)

		this.reuseMinSpawnBound.add(this.reuseEnemySpawnVector)
		this.reuseMaxSpawnBound.add(this.reuseEnemySpawnVector)

		this.reuseMinSpawnBound.add(playerPosition)
		this.reuseMaxSpawnBound.add(playerPosition)

		const enemiesSpawned = []

		for (let i = 0; i < count; ++i) {
			const ex = Math.getRandomInt(this.reuseMinSpawnBound.x, this.reuseMaxSpawnBound.x)
			const ey = Math.getRandomInt(this.reuseMinSpawnBound.y, this.reuseMaxSpawnBound.y)

			const pool = this.enemyPoolMap.get(enemyAI)
			const enemy = pool.alloc({
				x: ex,
				y: ey
			})

			enemy.maxHealth = targetHealth * enemy.maxHealth
			enemy.currentHealth = enemy.maxHealth

			enemiesSpawned.push(enemy)
		}

		return enemiesSpawned

		// this way 100% does not spawn enemies on top of each other, but results in groups all looking the same
		// const numEnemyRows = Math.ceil(Math.sqrt(count))
		// let spawned = 0
		// for (let x = 0; x < numEnemyRows; ++x) {
		// 	for (let y = 0; y < numEnemyRows; ++y) {
		// 		const ex = this.reuseMinSpawnBound.x + (x * STANDARD_ENEMY_SIZE)
		// 		const ey = this.reuseMinSpawnBound.y + (y * STANDARD_ENEMY_SIZE)
	
		// 		const pool = this.enemyPoolMap.get(enemyAI)
		// 		const enemy = pool.alloc({
		// 			x: ex,
		// 			y: ey
		// 		})
	
		// 		enemy.maxHealth = targetHealth * enemy.maxHealth
		// 		enemy.currentHealth = enemy.maxHealth
	
		// 		enemiesSpawned.push(enemy)

		// 		spawned++
		// 		if (spawned === count) {
		// 			return enemiesSpawned
		// 		}
		// 	}
		// }
	}

	spawnGroupFromDirection(enemyAI: ENEMY_NAME, count: number, direction: radians) {
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)

		this.reuseEnemySpawnVector.x = debugConfig.enemy.aggressiveRecycling ? AGGRESSIVE_ENEMY_SPAWN_DISTANCE : ENEMY_SPAWN_DISTANCE
		this.reuseEnemySpawnVector.y = 0

		this.reuseEnemySpawnVector.rotate(direction)

		const playerPosition = GameState.player.position

		const distApart = (count * STANDARD_ENEMY_SIZE) / 2
		const halfDist = distApart / 2

		this.reuseMinSpawnBound.x = -halfDist
		this.reuseMinSpawnBound.y = -halfDist

		this.reuseMaxSpawnBound.x = halfDist
		this.reuseMaxSpawnBound.y = halfDist

		this.reuseMinSpawnBound.rotate(direction)
		this.reuseMaxSpawnBound.rotate(direction)

		this.reuseMinSpawnBound.add(this.reuseEnemySpawnVector)
		this.reuseMaxSpawnBound.add(this.reuseEnemySpawnVector)

		this.reuseMinSpawnBound.add(playerPosition)
		this.reuseMaxSpawnBound.add(playerPosition)

		const enemiesSpawned = []

		for (let i = 0; i < count; ++i) {
			const ex = Math.getRandomInt(this.reuseMinSpawnBound.x, this.reuseMaxSpawnBound.x)
			const ey = Math.getRandomInt(this.reuseMinSpawnBound.y, this.reuseMaxSpawnBound.y)

			const pool = this.enemyPoolMap.get(enemyAI)
			const enemy = pool.alloc({
				x: ex,
				y: ey
			})

			enemy.maxHealth = targetHealth * enemy.maxHealth
			enemy.currentHealth = enemy.maxHealth

			enemiesSpawned.push(enemy)
		}

		return enemiesSpawned

		// this way 100% does not spawn enemies on top of each other, but results in groups all looking the same
		// const numEnemyRows = Math.ceil(Math.sqrt(count))
		// let spawned = 0
		// for (let x = 0; x < numEnemyRows; ++x) {
		// 	for (let y = 0; y < numEnemyRows; ++y) {
		// 		const ex = this.reuseMinSpawnBound.x + (x * STANDARD_ENEMY_SIZE)
		// 		const ey = this.reuseMinSpawnBound.y + (y * STANDARD_ENEMY_SIZE)
	
		// 		const pool = this.enemyPoolMap.get(enemyAI)
		// 		const enemy = pool.alloc({
		// 			x: ex,
		// 			y: ey
		// 		})
	
		// 		enemy.maxHealth = targetHealth * enemy.maxHealth
		// 		enemy.currentHealth = enemy.maxHealth
	
		// 		enemiesSpawned.push(enemy)

		// 		spawned++
		// 		if (spawned === count) {
		// 			return enemiesSpawned
		// 		}
		// 	}
		// }
	}

	spawnEnemyAtRandomPos(enemyAI: ENEMY_NAME): Enemy {
		this.reuseEnemySpawnVector.x = debugConfig.enemy.aggressiveRecycling ? AGGRESSIVE_ENEMY_SPAWN_DISTANCE : ENEMY_SPAWN_DISTANCE
		this.reuseEnemySpawnVector.y = 0

		let direction: number = Math.getRandomFloat(0, Math.PI * 2)
		this.reuseEnemySpawnVector.rotate(direction)

		const playerPosition = GameState.player.position
		this.reuseEnemySpawnVector.add(playerPosition)

		const pool = this.enemyPoolMap.get(enemyAI)
		const enemy = pool.alloc({
			x: this.reuseEnemySpawnVector.x,
			y: this.reuseEnemySpawnVector.y
		})

		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)
		enemy.maxHealth = targetHealth * enemy.maxHealth
		enemy.currentHealth = enemy.maxHealth

		return enemy
	}

	spawnEnemyAtPlayerOffsetPos(enemyAI: ENEMY_NAME, x: number, y: number, directionOverride?: VectorXY, deathTimer?: number, replacements?: WeightedList<ENEMY_NAME>): Enemy {
		const playerPosition = GameState.player.position
		const enemyName = replacements ? replacements.pickRandom().value[0] : enemyAI
		
		const pool = this.enemyPoolMap.get(enemyName)
		const enemy = pool.alloc({
			x: playerPosition.x + x,
			y: playerPosition.y + y,
		})
		
		enemy.deathTimer = deathTimer
		if(directionOverride) {
			enemy.setMovementOverride(directionOverride.x, directionOverride.y)
		}
		
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)
		enemy.maxHealth = targetHealth * enemy.maxHealth
		enemy.currentHealth = enemy.maxHealth

		return enemy
	}

	spawnEnemyAtPos(enemyName: ENEMY_NAME, x: number, y: number) {
		const pool = this.enemyPoolMap.get(enemyName)
		const enemy = pool.alloc({
			x,
			y,
		})
		
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)
		enemy.maxHealth = targetHealth * enemy.maxHealth
		enemy.currentHealth = enemy.maxHealth

		return enemy
	}

	spawnEnemiesInRectangle(enemyAI: ENEMY_NAME, count: number, x: gameUnits, y: gameUnits, maxX: gameUnits, maxY: gameUnits, directionOverride?: VectorXY, deathTimer?: timeInSeconds, replacements?: WeightedList<ENEMY_NAME>): Enemy[] {
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)
		const enemyName = replacements ? replacements.pickRandom().value[0] : enemyAI

		const enemiesSpawned = []
		for (let i = 0; i < count; i++) {
			const ex = Math.getRandomInt(x, maxX)
			const ey = Math.getRandomInt(y, maxY)

			const pool = this.enemyPoolMap.get(enemyAI)
			const enemy = pool.alloc({
				x: ex,
				y: ey
			})

			if(directionOverride) {
				enemy.setMovementOverride(directionOverride.x, directionOverride.y)
			}
			enemy.deathTimer = deathTimer

			enemy.maxHealth = targetHealth * enemy.maxHealth
			enemy.currentHealth = enemy.maxHealth

			enemiesSpawned.push(enemy)
		}
		return enemiesSpawned
	}

	spawnEnemiesInVerticalLine(enemyAI: ENEMY_NAME, count: number, centerY: gameUnits, x: gameUnits, spacing: gameUnits, directionOverride?: any, deathTimer?: timeInSeconds, replacements?: WeightedList<ENEMY_NAME>) {
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)
		const enemyName = replacements ? replacements.pickRandom().value[0] : enemyAI

		const height = (count * spacing) - spacing
		const start = centerY - (height / 2)
		
		const enemiesSpawned = []
		for (let i = 0; i < count; i++) {
			const ex = x
			const ey = start + (i * spacing)

			const pool = this.enemyPoolMap.get(enemyName)
			const enemy = pool.alloc({
				x: ex,
				y: ey
			})

			if(directionOverride) {
				enemy.setMovementOverride(directionOverride.x, directionOverride.y)
			}

			enemy.deathTimer = deathTimer
			enemy.colliderComponent.setLayer(CollisionLayerBits.FlyingEnemy)
			
			enemy.maxHealth = targetHealth * enemy.maxHealth
			enemy.currentHealth = enemy.maxHealth

			enemiesSpawned.push(enemy)
		}

		return enemiesSpawned
	}

	spawnEnemiesInHorizontalLine(enemyAI: ENEMY_NAME, count: number, centerX: gameUnits, y: gameUnits, spacing: gameUnits, directionOverride?: VectorXY, deathTimer?: timeInSeconds, replacements?: WeightedList<ENEMY_NAME>) {
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)
		const enemyName = replacements ? replacements.pickRandom().value[0] : enemyAI

		const width = (count * spacing) - spacing
		const start = centerX - (width / 2)

		const enemiesSpawned = []
		for (let i = 0; i < count; i++) {
			const ex = start + (i * spacing)
			const ey = y

			const pool = this.enemyPoolMap.get(enemyName)
			const enemy = pool.alloc({
				x: ex,
				y: ey
			})

			if(directionOverride) {
				enemy.setMovementOverride(directionOverride.x, directionOverride.y)
			}

			enemy.deathTimer = deathTimer
			enemy.colliderComponent.setLayer(CollisionLayerBits.FlyingEnemy)
			
			enemy.maxHealth = targetHealth * enemy.maxHealth
			enemy.currentHealth = enemy.maxHealth

			enemiesSpawned.push(enemy)
		}

		return enemiesSpawned
	}

	spawnEnemiesAlongArc(enemyAI: ENEMY_NAME, centerX: number, centerY: number, numberOfEnemies: number, xRadius: number, yRadius: number, moveTowardsCenter?: boolean, deathTimer?: timeInSeconds, angleStart: radians = 0, angleEnd: radians = Math.PI * 2, replacements?: WeightedList<ENEMY_NAME>) {
		const currentTimeInSeconds = InGameTime.timeElapsedInSeconds
		const targetHealth = getEnemyTargetHealth(currentTimeInSeconds)
		const enemyName = replacements ? replacements.pickRandom().value[0] : enemyAI

		const enemiesSpawned = []

		const totalAngle = angleEnd - angleStart

		const stepSize = totalAngle / numberOfEnemies

		for(let a = angleStart; a < angleEnd; a += stepSize) {
			const circleX = Math.sin(a)
			const circleY = Math.cos(a)
			const xPos = circleX * xRadius + centerX
			const yPos = circleY * yRadius + centerY

			const pool = this.enemyPoolMap.get(enemyName)
			const enemy = pool.alloc({
				x: xPos,
				y: yPos
			})

			if (moveTowardsCenter) {
				enemy.setMovementOverride(-circleX, -circleY)
			}

			enemy.deathTimer = deathTimer
			enemy.colliderComponent.setLayer(CollisionLayerBits.FlyingEnemy)
			
			enemy.maxHealth = targetHealth * enemy.maxHealth
			enemy.currentHealth = enemy.maxHealth

			enemiesSpawned.push(enemy)
		}

		return enemiesSpawned
	}

	// if this turns out useful for non-debug functionality then rename please
	debugForEachEnemy(func) {
		GameState.enemyList.forEach((enemy) => func(enemy))
	}

	getOnScreenEnemies(): Enemy[] {
		const onScreenEnemies = []
		
		GameState.enemyList.forEach((enemy: Enemy) => {
			if (checkIfPointIsInView(enemy.position)){
				onScreenEnemies.push(enemy)
			}
		})
		return onScreenEnemies
	}

	getEnemiesInAngleRange(position: Vector, baseAngleRadians: number, spreadAngleRadians: number, radius: number): Enemy[] {
		const result: Enemy[] = []
		const enemiesInRadius = CollisionSystem.getInstance().getEntitiesInArea(position, radius, CollisionLayerBits.HitEnemyOnly)
		
		while (baseAngleRadians < 0) {
			baseAngleRadians += Math.PI * 2
		}

		while (spreadAngleRadians < 0) {
			spreadAngleRadians += Math.PI * 2
		}


		let upperBoundAngle = baseAngleRadians + spreadAngleRadians / 2
		if (upperBoundAngle < 0) {
			upperBoundAngle += Math.PI * 2
		} else if (upperBoundAngle > Math.PI * 2) {
			upperBoundAngle -= Math.PI * 2
		}

		let lowerBoundAngle = (baseAngleRadians - spreadAngleRadians / 2)
		if (lowerBoundAngle < 0) {
			lowerBoundAngle += Math.PI * 2
		} else if (lowerBoundAngle > Math.PI * 2) {
			lowerBoundAngle -= Math.PI * 2
		}

		if (enemiesInRadius.length) {
			enemiesInRadius.forEach((collider) => {
				const enemy = collider.owner as Enemy
				let enemyAngle =  angleInRadsFromVector(sub(enemy.position, position))
				if (enemyAngle < 0) {
					enemyAngle += Math.PI * 2
				}
				if (isBetweenAngles(enemyAngle, upperBoundAngle, lowerBoundAngle)){
					result.push(enemy)
				}
			})
		}

		// Temp debug visuals
		// Renderer.getInstance().drawLine({sourceX: position.x, sourceY: position.y, destX: position.x + Math.cos(upperBoundAngle) * radius, destY: position.y + Math.sin(upperBoundAngle) * radius, color: Colors.blue, permanent: false, destroyAfterSeconds: 0.2})
		// Renderer.getInstance().drawLine({sourceX: position.x, sourceY: position.y, destX: position.x + Math.cos(lowerBoundAngle) * radius, destY: position.y + Math.sin(lowerBoundAngle) * radius, color: Colors.blue, permanent: false, destroyAfterSeconds: 0.2})
		return result
	}

	private onEnemyToBeRecycled(enemy: Enemy) {
		if (this.fuseRecycledShambers) {
			if (enemy.isBasicShambler && !enemy.isMerged) {
				this.recycledShamblersFusionCount++

				if (this.nextRecycledShamblersFusionCooldown <= 0) {
					if (this.recycledShamblersFusionCount >= this.shamblerFusionRecycleCount) {
						this.nextRecycledShamblersFusionCooldown = this.shamblerFusionRecycleCooldown
						this.recycledShamblersFusionCount = 0 // intentional not -= shamblerFusionRecycleCount

						const baseName = ENEMY_NAME.SHAMBLING_MOUND
						let spawnedEnemy: Enemy
						if (baseName.length === enemy.name.length) {
							spawnedEnemy = this.spawnEnemyAtRandomPos(ENEMY_NAME.HUGE_SHAMBLING_MOUND)
						} else {
							const suffix = enemy.name[baseName.length + 1]
							const name = ENEMY_NAME.HUGE_SHAMBLING_MOUND + ' ' + suffix
							spawnedEnemy = this.spawnEnemyAtRandomPos(name as ENEMY_NAME)
						}
					}
				}
			}
		}
	}

	removeEnemy(enemy: Enemy) {
		const pool = this.enemyPoolMap.get(enemy.name)
		pool.free(enemy)
	}

	repopulateXpShuffleBag() {
		this.populateShuffleBag(ITEM_DROP_SHUFFLE_BAG_ENTRIES[this.nextShuffleBagConfigIndex - 1], this.xpDropShuffleBag, this.xpEmptyCountBonusPercent)
	}

	private populateShuffleBag(shuffleEntry: ShuffleBagEntryOverTime, shuffleBag: ShuffleBag, addEmptyEntryPercentage?: number) {
		const items: GroundPickupConfigType[] = []
		let emptyEntryCount = 0
		let totalEntries = 0
		for(let i = 0 ; i < shuffleEntry.entries.length; ++i) {
			const entry = shuffleEntry.entries[i]
			for(let ei = 0; ei < entry.count; ++ei) {
				items.push(entry.pickupType)

				if (entry.pickupType === GroundPickupConfigType.None) {
					emptyEntryCount++
				}
			}

			totalEntries += entry.count
		}

		if (items[0] === GroundPickupConfigType.CommonCurrencySmall || items[0] === GroundPickupConfigType.CommonCurrencyMedium || items[0] === GroundPickupConfigType.CommonCurrencyLarge){
			console.log(`populating shuffle bag with ${items.length} items`)
		}

		if (addEmptyEntryPercentage) {
			const targetEmptyPercentage = Math.clamp((emptyEntryCount / totalEntries) + addEmptyEntryPercentage, 0, 0.99) // not 1, just in case

			let emptyPercentage = (emptyEntryCount / totalEntries)
			while (emptyPercentage < targetEmptyPercentage) { // math is hard, so I code
				items.push(GroundPickupConfigType.None)

				emptyEntryCount++
				totalEntries++

				emptyPercentage = (emptyEntryCount / totalEntries)
			}
		}

		shuffleBag.setItems(items)
	}

	cleanUp() {
		this.xpDropShuffleBag = new ShuffleBag(Date.now(), true)
		this.populateShuffleBag(ITEM_DROP_SHUFFLE_BAG_ENTRIES[0], this.xpDropShuffleBag)
		this.nextShuffleBagConfig = ITEM_DROP_SHUFFLE_BAG_ENTRIES[1]
		this.nextShuffleBagConfigIndex = 1

		this.currencyDropShuffleBag = new ShuffleBag(Date.now(), true)
		this.populateShuffleBag(CURRENCY_DROP_SHUFFLE_BAG_ENTRIES[0], this.currencyDropShuffleBag)
		this.nextCurrencyShuffleBagConfig = CURRENCY_DROP_SHUFFLE_BAG_ENTRIES[1]
		this.nextCurrencyShuffleBagConfigIndex = 1

		this.twistExplosionNextCooldown = -1
		this.fuseRecycledShambers = false
		this.shamblerFusionRecycleCount = 0
		this.shamblerFusionRecycleCooldown = 0
		this.bruteTrioKillCount = 0
		this.recycledShamblersFusionCount = 0 
		this.nextRecycledShamblersFusionCooldown = 0

		// Giant Shambler's Everyday Carry Twist
		this.shamblerMagnetTwist = false
		this.basicShamblerCount = 0

		// Merge Monster Twist
		this.monsterMergeTwist = false
		this.monsterMergeRadiusPadding = 0
		this.monsterMergeCooldown = 0 
		this.monsterMergeAmount = 0
		this.monsterMergeChance = 0
		this.monsterMergeQueue.length = 0
		this.monsterMergeScale = 1
		this.nextMonsterMergeCooldown = 0

		// Spooky Ghost twists
		this.spookyGhostTwist = false
		this.spookyGhostCooldown = 0
		this.spookyGhostChance = 0
		this.spookyGhostRadius = 0
		this.spookyGhostCount = 0
		this.nextSpookyGhostCooldown = 0
	}

}
