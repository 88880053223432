import { GameClient } from "../engine/game-client"
import { GameState } from "../engine/game-state"
import EnemyEquilibriumSpawner from "../entities/enemies/enemy-equilibrium-spawner"
import { ENEMY_NAME } from "../entities/enemies/enemy-names"
import { StoryDifficulty } from "../game-data/difficulties"
import { BossEnemyGlobalStatList, EnemyGlobalStatList } from "../stats/entity-stat-list"
import { StatOperator, StatType } from "../stats/stat-interfaces-enums"

export class DifficultyManager {

    static applyStoryDifficulty(difficulty: StoryDifficulty) {
        switch (difficulty) {
            case StoryDifficulty.Normal:
                return this.applyNormalDifficulty()
            case StoryDifficulty.Hard:
                return this.applyHardDifficulty()
            case StoryDifficulty.Brutal: 
                return this.applyBrutalDifficulty()
        }
    }

    private static applyNormalDifficulty() {
        // nothing, same as default settings
        GameState.storyDifficulty = StoryDifficulty.Normal
    }

    private static applyHardDifficulty() {
        GameState.storyDifficulty = StoryDifficulty.Hard

        // +40% spawn rate
        EnemyEquilibriumSpawner.getInstance().addSpawnValueMultipliers({ allEnemySpawnRate: 0.40 })

        // +30% enemy health
        EnemyGlobalStatList.addStatBonus(StatType.maxHealth, StatOperator.MULTIPLY, 0.3)

        // +100% Boss Health (This is 15.5% multiplied by the above 30% for 50.15% effective health)
        BossEnemyGlobalStatList.addStatBonus(StatType.maxHealth, StatOperator.MULTIPLY, 0.155)
    
        // +10% enemy move speed
        EnemyGlobalStatList.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, 0.10)
        // Add a knockback bonus to counter the inherent resistance in the extra movement speed
        GameState.player.stats.addStatBonus(StatType.attackKnockback, StatOperator.MULTIPLY, 0.15)

        // +20% enemy attack / fire rate
        EnemyGlobalStatList.addStatBonus(StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.2)
        EnemyGlobalStatList.addStatBonus(StatType.cooldownInterval, StatOperator.MULTIPLY, -0.2)
        EnemyGlobalStatList.addStatBonus(StatType.reloadInterval, StatOperator.MULTIPLY, -0.2)

        // +1 Boss Damage
        GameState.player.binaryFlags.add('take-extra-boss-damage')
        GameState.player.binaryFlagState["take-extra-boss-damage"].extraDamage = 1

        // adjust first enemy spawn group to start at 10 seconds instead of 20
        EnemyEquilibriumSpawner.getInstance().setSecondPackStartTime(10)

        // players require 10% more XP to level up
        GameState.player.stats.addStatBonus(StatType.xpValueMulti, StatOperator.MULTIPLY, -0.10)

                // +25% more currency rewards
        GameState.player.currencyValueMultiplier += 0.25
    }

    private static applyBrutalDifficulty()  {
        GameState.storyDifficulty = StoryDifficulty.Brutal

        // +60% spawn rate
        EnemyEquilibriumSpawner.getInstance().addSpawnValueMultipliers({ allEnemySpawnRate: 0.6 })

        // +70% enemy health
        EnemyGlobalStatList.addStatBonus(StatType.maxHealth, StatOperator.MULTIPLY, 0.7)

        // +100% Boss Health (This is 18% multiplied by the above 70% for 100.6% effective health)
        BossEnemyGlobalStatList.addStatBonus(StatType.maxHealth, StatOperator.MULTIPLY, 0.18)

        // +15% enemy move speed
        EnemyGlobalStatList.addStatBonus(StatType.movementSpeed, StatOperator.MULTIPLY, 0.15)
        // Add a knockback bonus to counter the inherent resistance in the extra movement speed
        GameState.player.stats.addStatBonus(StatType.attackKnockback, StatOperator.MULTIPLY, 0.23)
        // shriekers have have an additional +5% move speed
        GameClient.getInstance().enemyDefintions.forEach((enemy) => {
            if (enemy.name === ENEMY_NAME.SHRIEKER || enemy.name === ENEMY_NAME.SHRIEKER_VINES) {
                enemy.baseAttributes.baseStats.movementSpeed *= 1.05
            }
        })

        // +30% enemy attack / fire rate
        EnemyGlobalStatList.addStatBonus(StatType.attackRate, StatOperator.SUM_THEN_MULTIPLY, 0.3)
        EnemyGlobalStatList.addStatBonus(StatType.cooldownInterval, StatOperator.MULTIPLY, -0.3)
        EnemyGlobalStatList.addStatBonus(StatType.reloadInterval, StatOperator.MULTIPLY, -0.3)

        // +1 Enemy Damage, +1 Boss Damage
        GameState.player.binaryFlags.add('take-extra-boss-damage')
        GameState.player.binaryFlagState["take-extra-boss-damage"].extraDamage = 1
		GameState.player.binaryFlags.add('take-extra-enemy-damage')
		GameState.player.binaryFlagState['take-extra-enemy-damage'].extraDamage = 1


        // adjust first enemy spawn group to start at 4 seconds instead of 20
        EnemyEquilibriumSpawner.getInstance().setSecondPackStartTime(4)

        // players require 20% more XP to level up
        GameState.player.stats.addStatBonus(StatType.xpValueMulti, StatOperator.MULTIPLY, -0.20)

        // +60% more currency rewards
        GameState.player.currencyValueMultiplier += 0.6
    }
}
