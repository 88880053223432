<template>
	<table id="table">
		<tr class="header" v-show="!disableHeader">
			<th v-for="(header, index) in headers" :key="index" class="col-header" :class="{ 'regular-font': useRegularFontHeader }" :lang="this.$i18n.locale">{{ $t(header.headerName) }}</th>
		</tr>
		<tbody class="table-body">
			<tr v-for="(rowData, rowIndex) in tableRowData" :key="rowIndex" class="table-row">
				<td :id="`cell-${cellKey}-row-${rowIndex}`" v-for="(columnData, cellKey) in rowData" :key="cellKey" :class="{ 'large-font': largeFont, [color]: true }" class="table-cell" :lang="this.$i18n.locale">{{ columnData }}</td>
			</tr>
		</tbody>
	</table>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SquareItem from '../item/square-items.vue'

export default {
	name: 'Table',
	components: {
		SquareItem,
	},
	data() {
		return {}
	},
	props: {
		headers: {
			type: Array,
			required: false,
		},
		tableRowData: {
			type: Array,
			required: true,
		},
		disableHeader: {
			type: Boolean,
			required: true,
		},
		largeFont: {
			type: Boolean,
			required: true,
		},
		color: {
			type: String,
			required: false,
			default: 'cream'
		},
		useRegularFontHeader: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#table.table-text-white {
	.table-body .table-row .table-cell {
		color: @cream;
		&:first-child {
			color: @offWhite;
		}
	}
}

#table {
	width: 100%;
	.header {
		font-weight: bold;
		font-size: 25px;
		.col-header {
			padding: 15px 0px;
			text-align: justify;
			text-align: center;
			color: @offWhite;
			&[lang='en'] {
				.regularFont(@enSecondaryFontPTBold);
				font-size: 24px;

				&.regular-font { 
					.regularFont(@enRegularFontPixl);
					font-variant: small-caps;
					font-size: 38px;
					font-weight: lighter;
				}
			}
			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
			&:first-child {
				text-align: left;
			}
			&:last-child {
				text-align: right;
			}
		}
	}
	.table-body {
		.table-row {
			height: 35px;
			.table-cell {
				text-align: center;
				&.cream {
					color: @cream;					
				}
				&.green {
					color: @green;
				}
				&.white {
					color: @offWhite;
				}

				.icon {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 74px;
					height: 74px;
					transform: scale(0.55)
				}
				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					font-size: 24px;
					letter-spacing: 2px;
				}

				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
				&:first-child {
					text-align: left;
					color: @green;
				}
				&:last-child {
					text-align: right;
				}
				&.large-font {
					font-size: 30px;
				}
			}
		}
	}
}
</style>
