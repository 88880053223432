import { Sprite, Texture } from "pixi.js"
import { Vector } from "sat"
import { Audio } from "../engine/audio"
import { ColliderComponent } from "../engine/collision/collider-component"
import { CollisionLayerBits } from "../engine/collision/collision-layers"
import CollisionSystem from "../engine/collision/collision-system"
import { ComponentOwner } from "../engine/component-owner"
import { GameState, getNID } from "../engine/game-state"
import { InstancedSprite } from "../engine/graphics/instanced-sprite"
import playAnimation from "../engine/graphics/play-animation"
import { Renderer } from "../engine/graphics/renderer"
import { LayerRenderer } from "../engine/graphics/renderers/layer-renderer"
import { RiggedSpineModel } from "../engine/graphics/spine-model"
import { DEFAULT_AOE_EXPLOSION_DURATION } from "../projectiles/explosions"
import { AnimationTrack } from "../spine-config/animation-track"
import { callbacks_addCallback } from "../utils/callback-system"
import { debugConfig } from "../utils/debug-config"
import { createInstancedSprites, getAttachmentOffsets } from "../utils/pixi-util"
import { timeInSeconds, timeInMilliseconds } from "../utils/primitive-types"
import { InGameTime } from "../utils/time"
import { AssetManager } from "../web/asset-manager"
import { PropHolder, PropPlacer } from "../world-generation/prop-placement"
import { EntityType, IEntity } from "./entity-interfaces"
import { getGroundPickupToDrop, GroundPickupPoolType, splayGroundPickupsInRadius } from "./pickups/ground-pickup"
import { ClarityAuraReason, Player } from "./player"
import { PropConfig, VisualBlockerConfig, propConfigs } from "./prop-config"
import { SpritesheetAnimatorComponent } from "../engine/graphics/spritesheet-animator-component"
import { randomRange } from "../utils/math"

const PROP_INVULN_BETWEEN_HITS: timeInMilliseconds = 300
const DESTRUCTIBLE_COLLISION_ENTITY_TYPES: EntityType[] = [
    EntityType.Beam,
    EntityType.Projectile,
    // EntityType.GroundHazard, // should be covered by layers?
]
const DESTRUCTIBLE_COLLISION_LAYERS: CollisionLayerBits[] = [
    CollisionLayerBits.PlayerGroundHazard,
]
const DESTRUCTIBLE_SINGLE_FRAMES = {
    IDLE: 'idle_00.png',
    HIT: 'hit_00.png',
    DESTROYED: 'destructible_death_00.png',
}

const DESTRUCTIBLE_ANIMATIONS = {
    IDLE: 'idle',
    HIT: 'hit',
    DESTROYED: 'destroyed'
}

export class Prop implements ComponentOwner, IEntity {
    instancedSprites: InstancedSprite[]
    sprite: InstancedSprite
    visualBlockedSprite: Sprite
    animatedSprite: SpritesheetAnimatorComponent

    propConfig: PropConfig
    position: Vector
    originalPosition: Vector
    nid: number
    entityType: EntityType = EntityType.Prop
	timeScale: number = 1

    hitsToDestroy: number
    isDestructible: boolean
    lastHitTime: timeInMilliseconds

    colliderComponent: ColliderComponent

    propHolder: PropHolder

    // These are only OK if the prop is in the scene
    distToPlayerX: number
    distToPlayerY: number

    private isInScene: boolean
    private renderer: LayerRenderer


    private blockerConfig: VisualBlockerConfig
    private isBlocked: boolean = false

    private blockerYMin: number
    private blockerYMax: number

    private blockerXMin: number
    private blockerXMax: number

    constructor(assetName, position: Vector) {
        this.position = position
        this.nid = getNID(this)
        this.originalPosition = new Vector().copy(position)

        const propConfig = propConfigs[assetName]
        if (!propConfig) {
            throw new Error(`Could not find prop config for asset name ${assetName}`)
        }

        this.propConfig = propConfig
        this.hitsToDestroy = propConfig.hitsToDestroy
        this.isDestructible = !(propConfig.hitsToDestroy === undefined)

        this.renderer = propConfig.useBgRenderer ? Renderer.getInstance().bgRenderer : Renderer.getInstance().mgRenderer
        this.isInScene = true // @TODO start not in the scene? (remove adding below)

        const scale = propConfig.scale ?? 1

        if (propConfig.isSingleImage) {
            const tex = AssetManager.getInstance().getAssetByName(assetName)
            const zOffset = propConfig.zOffset || 0
            this.sprite = new InstancedSprite(tex.texture, this.position.x, this.position.y, this.position.y + zOffset)

            if (propConfig.randomFlip) {
                this.sprite.scaleX = Math.getRandomInt(0, 1) ? -scale : scale
            } else {
                this.sprite.scaleX = scale
            }
            this.sprite.scaleY = scale

            this.renderer.addPropToScene(this.sprite)

        } else {
            const sheetName = propConfig.useSpriteSheet || assetName
            const spriteSheet = AssetManager.getInstance().getAssetByName(sheetName).spritesheet
            const zOffset = propConfig.zOffset || 0

            if (!propConfig.animate) {
                let tex: Texture
                if (propConfig.isDestructible && !propConfig.animate) {
                    tex = spriteSheet.textures[DESTRUCTIBLE_SINGLE_FRAMES.IDLE]
                    if (!tex) {
                        throw new Error(`Could not find ${DESTRUCTIBLE_SINGLE_FRAMES.IDLE}  (from non-animated destructible prop) entry in sprite sheet ${sheetName}`)
                    }
                } else {
                    tex = spriteSheet.textures[assetName]

                    if (!tex) {
                        throw new Error(`Could not find ${assetName} entry in sprite sheet ${sheetName}`)
                    }
                }
                
                this.sprite = new InstancedSprite(tex, this.position.x, this.position.y, this.position.y + zOffset)

                let scaleX = scale
                if (propConfig.randomFlip) {
                    scaleX = Math.getRandomInt(0, 1) ? -scale : scale
                    this.sprite.scaleX = scale
                } else {
                    this.sprite.scaleX = scale
                }
                this.sprite.scaleY = scale

                this.renderer.addPropToScene(this.sprite)

                if (propConfig.visualBlocker) {
                    this.visualBlockedSprite = new Sprite(tex)
                    this.visualBlockedSprite.scale.x = scaleX
                    this.visualBlockedSprite.scale.y = scale
                    this.visualBlockedSprite.alpha = propConfig.visualBlocker.alpha
                    this.visualBlockedSprite.x = this.position.x
                    this.visualBlockedSprite.y = this.position.y
                    this.visualBlockedSprite.zIndex = this.position.y + zOffset
                    this.visualBlockedSprite['update'] = () => {}
                    this.visualBlockedSprite.anchor.x = 0.5
                    this.visualBlockedSprite.anchor.y = 0.5

                    this.blockerConfig = propConfig.visualBlocker

                    this.blockerYMin = propConfig.visualBlocker.blockerYOffset - propConfig.visualBlocker.blockerYRadius
                    this.blockerYMax = propConfig.visualBlocker.blockerYOffset + propConfig.visualBlocker.blockerYRadius

                    this.blockerXMax = propConfig.visualBlocker.blockerXRadius
                    this.blockerXMin = -propConfig.visualBlocker.blockerXRadius
                }
            } else {
                const animSpeed = propConfig.overrideAnimSpeed || 0.5
                try {
                    this.animatedSprite = new SpritesheetAnimatorComponent(this, spriteSheet, DESTRUCTIBLE_ANIMATIONS.IDLE, animSpeed)
                } catch (err) {
                    throw new Error(`Error when creating animated sprite for asset '${assetName}' using spritesheet '${sheetName}' ; likely missing animation '${DESTRUCTIBLE_ANIMATIONS.IDLE}' under the 'animations' section of the .json`)
                }
								if (propConfig.lightingConfig) {
                  const color = propConfig.lightingConfig.colors.pickRandom()
                  this.animatedSprite.spriteSheetAnimator.setColor(color.r, color.g, color.b, color.a)
									const lightingScale =  randomRange(propConfig.lightingConfig.minScale, propConfig.lightingConfig.maxScale)
									this.animatedSprite.setScale(lightingScale, lightingScale)
									this.animatedSprite.overrideZindex((zOffset * lightingScale) + position.y)
                } else {
									this.animatedSprite.setScale(scale, scale)
									this.animatedSprite.overrideZindex(zOffset + position.y)
								}

                this.animatedSprite.addToScene()
            }
        }

        if (propConfig.colliders) {
            const collisionFunction = (propConfig.damagePlayer || propConfig.hitsToDestroy) ? this.onCollision.bind(this) : undefined
            const layer = propConfig.collisionLayer === undefined ? CollisionLayerBits.Prop : propConfig.collisionLayer
            this.colliderComponent = new ColliderComponent(propConfig.colliders, this, layer, collisionFunction, true)
            CollisionSystem.getInstance().addCollidable(this.colliderComponent)

            if (debugConfig.collisions.drawPropColliders) {
                this.colliderComponent.drawColliders()
            }
        }
    }

    addToScene() {
        if (!this.isInScene) {
            this.isInScene = true

            if (this.sprite) {
                this.renderer.addPropToScene(this.sprite)
            } else if (this.instancedSprites) {
                for (let i = 0; i < this.instancedSprites.length; ++i) {
                    this.renderer.addPropToScene(this.instancedSprites[i])
                }
            } else if (this.animatedSprite) {
                this.animatedSprite.playAnimation(DESTRUCTIBLE_ANIMATIONS.IDLE)
                this.animatedSprite.update(0)
                this.animatedSprite.addToScene()
            }

            if (this.colliderComponent) {
                CollisionSystem.getInstance().addCollidable(this.colliderComponent)
            }
        }
    }

    removeFromScene() {
        if (this.isInScene) {
            this.isInScene = false

            this.removeGfx()

            if (this.colliderComponent) {
                CollisionSystem.getInstance().removeCollidable(this.colliderComponent)
            }
        }
    }

    update(delta: timeInSeconds, now?: timeInMilliseconds) {
        if (this.isInScene) {
            if (this.animatedSprite) {
                this.animatedSprite.update(delta)
            }

            if (this.blockerConfig) {
                const blocking = (this.distToPlayerX > this.blockerXMin && this.distToPlayerX < this.blockerYMax && this.distToPlayerY > this.blockerYMin && this.distToPlayerY < this.blockerYMax)
                this.setBlocking(blocking)
            }
        }
    }

    setBlocking(isBlocking: boolean) {
        if (isBlocking && !this.isBlocked) {
            // we weren't blocking before, but we are now
            this.isBlocked = true

            if (this.sprite) {
                this.renderer.removeFromScene(this.sprite)
                this.renderer.addDisplayObjectToScene(this.visualBlockedSprite)
            } else if (this.animatedSprite) {
                this.animatedSprite.container.alpha = this.blockerConfig.alpha
            } else {
                // groups of instanced sprites currently not supported
            }

            GameState.player.activateClarityAura(ClarityAuraReason.BehindProp)
        } else if (!isBlocking && this.isBlocked) {
            // we were blocking before, but we aren't anymore
            this.isBlocked = false

            if (this.sprite) {
                this.renderer.removeFromScene(this.visualBlockedSprite)
                this.renderer.addPropToScene(this.sprite)
            } else if (this.animatedSprite) {
                this.animatedSprite.container.alpha = 1
            } else {
                // groups of instanced sprites currently not supported
            }

            GameState.player.deactivateClarityAura(ClarityAuraReason.BehindProp)
        }
    }

    // Used by prop recycling
    setOffset(offset: Vector) {
        this.position.x = this.originalPosition.x + offset.x
        this.position.y = this.originalPosition.y + offset.y

        const newX = this.position.x
        const newY = this.position.y

        if (this.sprite) {
            this.sprite.x = newX
            this.sprite.y = newY

            this.sprite.zIndex = newY + (this.propConfig.zOffset || 0)

            if (this.visualBlockedSprite) {
                this.visualBlockedSprite.x = newX
                this.visualBlockedSprite.y = newY
                this.visualBlockedSprite.zIndex = newY + (this.propConfig.zOffset || 0)
            }
        } else if (this.animatedSprite) {
            this.animatedSprite.overrideZindex(newY + (this.propConfig.zOffset || 0))
            // x, y is set each frame by the animated sprite itself
        } else {
            for (let i = 0; i < this.instancedSprites.length; ++i) {
                this.instancedSprites[i].x = newX - this.instancedSprites[i].propOffset.x
                this.instancedSprites[i].y = newY - this.instancedSprites[i].propOffset.y

                this.sprite.zIndex = newY - this.instancedSprites[i].propOffset.y + (this.propConfig.zOffset || 0)
            }
        }

        if (this.colliderComponent) {
            CollisionSystem.getInstance().reinsertEntity(this.colliderComponent)
        }
    }

    removeGfx() {
        if (this.sprite) {
            this.renderer.removeFromScene(this.sprite)
        } else if (this.animatedSprite) {
            this.animatedSprite.removeFromScene()
        } else {
            this.instancedSprites.forEach((instancedSprite) => {
                this.renderer.removeFromScene(instancedSprite)
            })
        }
    }

    destroy() {
        if (this.animatedSprite) {
            this.animatedSprite.playAnimation(DESTRUCTIBLE_ANIMATIONS.DESTROYED, undefined, () => {
                this.removeFromScene()
                GameState.removeEntity(this)
                PropPlacer.getInstance().removeProp(this)
                CollisionSystem.getInstance().removeCollidable(this.colliderComponent)
            })
        } else {
            this.removeFromScene()
            GameState.removeEntity(this)
            PropPlacer.getInstance().removeProp(this)
            CollisionSystem.getInstance().removeCollidable(this.colliderComponent)
        }
    }

    spawnPickups() {
        const pickups = []
        for (let i = 0; i < this.propConfig.pickupsToDrop; i++) {
            pickups.push(getGroundPickupToDrop(GroundPickupPoolType.Destructible, 1))
        }

        splayGroundPickupsInRadius(pickups, this.position.x, this.position.y, 500, 700, 0.04)
    }

    takeDamage(destroyInstantly?: boolean) {
        if (!this.isDestructible) {
            return
        }

        const now = InGameTime.highResolutionTimestamp()
        if (now < this.lastHitTime + PROP_INVULN_BETWEEN_HITS && !destroyInstantly) {
            return
        }

        this.hitsToDestroy -= 1
        this.lastHitTime = now
        
        if (this.hitsToDestroy === 0 || destroyInstantly) {
            this.destroy()
            callbacks_addCallback(this, () => {
                this.spawnPickups()
                PropPlacer.getInstance().onDestroyDestructibleCallbacks.forEach((cb) => cb(this))
            }, 0.25)
            if (this.propConfig.onDestroySound) {
                Audio.getInstance().playSfx(this.propConfig.onDestroySound)
            }
            const explodey = AssetManager.getInstance().getAssetByName('aoe-explosion-white').data
            Renderer.getInstance().addOneOffEffectByConfig(explodey, this.position.x, this.position.y, 99999, 1, DEFAULT_AOE_EXPLOSION_DURATION, true, true)
        } else if (this.hitsToDestroy > 0) {
            Audio.getInstance().playSfx(this.propConfig.onHitSound)
            spawnDustPfx(this.position.x, this.position.y + 20, 30, 2, 2, 2.0)

            if (this.animatedSprite) {
                this.animatedSprite.playAnimation(DESTRUCTIBLE_ANIMATIONS.HIT, DESTRUCTIBLE_ANIMATIONS.IDLE)
            }
        }
    }

    onCollision(otherEntity: ColliderComponent, collisionVX: number, collisionVY: number) {
        const owner = otherEntity.owner as IEntity
        if (this.propConfig.damagePlayer && owner.entityType === EntityType.Player) {
            const player = owner as Player

            player.takeDamage(1, this, true)
        } else if (this.hitsToDestroy > 0 && (DESTRUCTIBLE_COLLISION_LAYERS.includes(otherEntity.layer) || DESTRUCTIBLE_COLLISION_ENTITY_TYPES.includes(owner.entityType))) {
            this.takeDamage()
        }
    }
}

function spawnDustPfx(x: number, y: number, variance: number, scale: number, amount: number, timeScale: number = 1.0) {
    const effectConfig = AssetManager.getInstance().getAssetByName('destructible-props-fx').data
    for (let i = 0; i < amount; i++) {
        const posx = x + Math.getRandomInt(-variance, variance)
        const posy = y + Math.getRandomInt(-variance, variance)
        const effect = Renderer.getInstance().addOneOffEffectByConfig(effectConfig, posx, posy, 9999, scale, 0.6, true, true)
        effect.timeScale = timeScale
    }
}
