import { EnemyAI, VariantAI } from "./ai-types"
import prismfly from "./enemy-configs/prismfly"
import chaosSeer from "./enemy-configs/chaos-seer"
import crab from "./enemy-configs/crab"
import eyeBat from "./enemy-configs/eyebat"
import mosquito from "./enemy-configs/mosquito"
import theOneEyeBat from "./enemy-configs/the-one-eyebat"
import shrieker from "./enemy-configs/shrieker"
// import hermitCrab from "./enemy-configs/hermit-crab"
import blimpie from "./enemy-configs/blimpie"
import goblinJester from "./enemy-configs/loot-goblin"
import shamblingMound from "./enemy-configs/shambling-mound"
import dreamerBoss from "./enemy-configs/dreamer-boss"
import { hugeShamblingMound2, hugeShamblingMound3, hugeShamblingMound4, hugeShamblingMound5, shamblingMound2, shamblingMound3, shamblingMound4, shamblingMound5 } from "./enemy-configs/shambler-variants"
import { eyeBat2, eyeBat3, eyeBatChoreo } from "./enemy-configs/eyebat-variants"
import { blimpie2, blimpie3 } from "./enemy-configs/blimpie-variants"
import { hugeShamblingMound } from "./enemy-configs/shambler-variants"
import { shriekerKnight, shriekerKnightRedPlume, shriekerKnightBlueSpear, shriekerVines, shriekerKnightBluePlume, shriekerKnightRedSpear, shriekerKnightBluePlumeFlying, shriekerKnightBlueSpearFlying } from "./enemy-configs/shrieker-variants"
import { bruteEyeBat, bruteShambler, bruteShrieker } from "./enemy-configs/brute-trio"
import shamblingTower from "./enemy-configs/shambling-tower"
import { demonMosquito } from "./enemy-configs/mosquito-variants"
import { mosquito2, mosquito3 } from "./enemy-configs/mosquito-variants"
import crystalSpider from "./enemy-configs/crystal-spider"
import mushie from "./enemy-configs/mushie"
import skeletonMage from "./enemy-configs/skeleton-mage"
import spider from "./enemy-configs/spider"
import wisp from "./enemy-configs/wisp"
import { crystalSpider2, crystalSpider3, crystalSpider4 } from "./enemy-configs/crystal-spider-variants"
import { mushie2, mushie3 } from "./enemy-configs/mushie-variants"
import { skeletonMage2, skeletonMage3 } from "./enemy-configs/skeleton-mage-variants"
import { spider2 } from "./enemy-configs/spider-variants"
import { wisp2 } from "./enemy-configs/wisp-variants"
import yeti from "./enemy-configs/yeti"
import iceDrake from "./enemy-configs/ice-drake"
import snowFury from "./enemy-configs/snow-fury"
import eyesicle from "./enemy-configs/eyesicle"
import { snowFury2, snowFury3, snowFuryChoreo1, snowFuryChoreo2, snowFuryChoreo3 } from "./enemy-configs/snow-fury-variants"
import { eyesicle2 } from "./enemy-configs/eyesicle-variants"
import winterGoblin from "./enemy-configs/winter-goblin"
import { winterGoblin2, winterGoblin3, winterGoblin4, winterGoblin5 } from "./enemy-configs/winter-goblin-variants"
import snowball from "./enemy-configs/snowball"
import iceElemental from "./enemy-configs/ice-elemental"
import { iceElemental2 } from "./enemy-configs/ice-elemental-variants"
import mrCuddles from "./enemy-configs/mr-cuddles"
import choreoPetCat from "./enemy-configs/choreo-pet-cat"
import { ChoreoPetCrystal, choreoPetHorse, choreoPetPlant, choreoPetRotSon } from "./enemy-configs/choreo-pet-variants"

const EnemyDefinitions: EnemyAI[] = [
	crab,
	mosquito,
	eyeBat,
	prismfly,
	shrieker,
	blimpie,
	goblinJester,
	shamblingMound,
	shamblingTower,
	crystalSpider,
	mushie,
	skeletonMage,
	spider,
	wisp,
	snowFury,
	winterGoblin,
	eyesicle,

	// debug
	chaosSeer,
	theOneEyeBat,
	// hermitCrab,
	dreamerBoss,
	iceDrake,
	yeti,
	snowball,
	iceElemental,
	mrCuddles,
	choreoPetCat,
]

const variantDefinitions: VariantAI[] = [
	shamblingMound2,
	shamblingMound3,
	shamblingMound4,
	shamblingMound5,
	hugeShamblingMound,
	hugeShamblingMound2,
	hugeShamblingMound3,
	hugeShamblingMound4,
	hugeShamblingMound5,
	eyeBat2,
	eyeBat3,
	eyeBatChoreo,
	blimpie2,
	blimpie3,
	shriekerVines,
	shriekerKnight,
	shriekerKnightRedPlume,
	shriekerKnightBluePlume,
	shriekerKnightBluePlumeFlying,
	shriekerKnightRedSpear,
	shriekerKnightBlueSpear,
	shriekerKnightBlueSpearFlying,
	bruteShambler,
	bruteEyeBat,
	bruteShrieker,
	demonMosquito,
	mosquito2,
	mosquito3,
	crystalSpider2,
	crystalSpider3,
	crystalSpider4,
	mushie2,
	mushie3,
	skeletonMage2,
	skeletonMage3,
	spider2,
	wisp2,
	snowFury2,
	snowFury3,
	snowFuryChoreo1,
	snowFuryChoreo2,
	snowFuryChoreo3,
	eyesicle2,
	winterGoblin2,
	winterGoblin3,
	winterGoblin4,
	winterGoblin5,
	iceElemental2,
	choreoPetHorse,
	choreoPetPlant,
	choreoPetRotSon,
	ChoreoPetCrystal
]

EnemyDefinitions.forEach((enemy) => {
	enemy.baseVariant = enemy.name
})

for (let i = 0; i < variantDefinitions.length; ++i) {
	const variant = variantDefinitions[i]
	const parent = EnemyDefinitions.find((e) => e.name === variant.extends)
	console.assert(parent)
	
	const definition = JSON.parse(JSON.stringify(parent)) as EnemyAI // deep clone
	definition.name = variant.name
	definition.appearance = variant.appearance
	definition.baseVariant = parent.name
	
	if (variant.type !== undefined) {
		definition.type = variant.type
	}
	if (variant.maxHealth) {
		definition.baseAttributes.baseStats.maxHealth = variant.maxHealth
	}
	if (variant.movementSpeed) {
		definition.baseAttributes.baseStats.movementSpeed = variant.movementSpeed
	}
	if (variant.knockbackResist) {
		definition.baseAttributes.baseStats.knockbackResist = variant.knockbackResist
	}
	if (variant.baseDamage !== undefined) {
		definition.baseAttributes.baseStats.baseDamage = variant.baseDamage
	}
	if (variant.attackSize) {
		definition.baseAttributes.baseStats.attackSize = variant.attackSize
	}
	if (variant.projectileSpeed) {
		definition.baseAttributes.baseStats.projectileSpeed = variant.projectileSpeed
	}
	if (variant.projectileCount) {
		definition.baseAttributes.baseStats.projectileCount = variant.projectileCount
	}
	if (variant.projectileSpreadAngle !== undefined) {
		definition.baseAttributes.baseStats.projectileSpreadAngle = variant.projectileSpreadAngle
	}
	if (variant.projectileLifeSpan !== undefined) {
		definition.baseAttributes.baseStats.projectileLifeSpan = variant.projectileLifeSpan
	}
	if (variant.chillChance !== undefined) {
		definition.baseAttributes.baseStats.chillChance = variant.chillChance
	}
	if (variant.chillPotency !== undefined) {
		definition.baseAttributes.baseStats.chillPotency = variant.chillPotency
	}
	if (variant.colliders) {
		definition.baseAttributes.colliders = variant.colliders
	}
	if (variant.layer !== undefined) {
		definition.baseAttributes.colliderLayer = variant.layer
	}
	if (variant.states) {
		for (const key in variant.states) {
			definition.states[key] = variant.states[key]
		}
	}

	EnemyDefinitions.push(definition)
}

export { EnemyDefinitions }