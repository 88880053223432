<template>
	<div id="find-create-story">
		<Panel id="book-review" :height="-1">
			<template #panel-content>
				<div class="create-story-container">
					<div class="form-data">
						<div class="input-wrapper">
							<Header class="margin-top" :header-text="`Begin a New Story`" :text-alignment="'center'" :font-face="'pixl'" :font-size="'large'"></Header>
							<DropDown class="input center-label" :options="getGameTypeOptions" :selected="getGameType" :label-text="'Game Type'" :label-id="'game-type'" @on-click-fn="updateGameType"></DropDown>
							<div class="game-type-info" :lang="this.$i18n.locale">
								<div v-if="getGameType === 'solo'">
									{{ $t('story_select.explain_solo_mode') }}
								</div>
								<div v-else-if="getGameType === 'private'">
									{{ $t('story_select.explain_friends_mode') }}
								</div>
								<div v-else-if="getGameType === 'public'">
									{{ $t('story_select.explain_community_mode') }}
								</div>
							</div>
							<div v-if="getGameType != 'solo'">
								<!-- <DropDown :options="storyLengths" :selected="storyLength" :label-text="'Story Length'" :label-id="'story-lengths'" @on-click-fn="updateStoryLength" :selected-item="storyLength"></DropDown> -->
								<DropDown class="input center-label" :selected="getVotingMethod" :options="votingMethods" :label-text="'Voting Methods'" :label-id="'plot-twists'" @on-click-fn="updateVotingMethod"></DropDown>
								<!-- TODO please name this good, Chapter Vote Length, eugh -->
								<DropDown class="input center-label" :selected="getVoteLength" :label-text="'Chapter Vote Length'" :options="voteLengths" :label-id="'vote-length'" @on-click-fn="updateVoteLength"></DropDown>
							</div>
							<DropDown class="input center-label" :options="getDifficulties" :selected="getDifficultySelection" :label-text="'Difficulty'" :label-id="'difficulty'" @on-click-fn="updateDifficultySelection"></DropDown>
							<!-- <div v-else style="min-height: 216px; min-width: 1px;">
							</div> -->
							<!-- Removing for now, not specced currently -goose 	
							<div v-if="gameTypeSelected != 'solo'" class="votes-container">
								<div class="label" :lang="this.$i18n.locale">Max Votes Per Person</div>
								<input type="text" :value="maxVotesString" @blur="setMaxVotes($event)" />
							</div> -->
							<!-- <Checkbox v-if="gameTypeSelected != 'solo'" :label="'story_select.show_votes'" :inline-label="true" @on-click-fn="showVotes = !showVotes" :checked="showVotes" :font-size="xSmall"></Checkbox> -->
							<!-- <Checkbox :label="'story_select.enable_chat'" :inline-label="true" @on-click-fn="enableChat = !enableChat" :checked="enableChat" :font-size="xSmall"></Checkbox> -->
							<!-- <Checkbox v-if="gameTypeSelected != 'solo'" :label="'story_select.enable_meta_prog'" :inline-label="true" @on-click-fn="enableMetaProgression = !enableMetaProgression" :checked="enableMetaProgression" :font-size="xSmall"></Checkbox> -->
							<!-- <Checkbox :label="'story_select.start_with_plot_twist'" :inline-label="true" @on-click-fn="startWithPlotTwist = !startWithPlotTwist" :checked="startWithPlotTwist" :font-size="xSmall"></Checkbox> -->
							<div class="map-selection-container">
								<Header class="margin-top" :header-text="`Map Selection`" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'small'" :font-Color="'offWhite'" :enable-drop-shadow="false"></Header>
								<div class="icons-resized-container">
									<div v-for="(map, index) in getMaps" :key="index" class="icon-row">
										<SquareItem :item="map" @on-click-fn="updateMapSelection(map.id)" :selected="isMapSelected(map.id)" :disabled="mapIsLocked(map.id)" :small-icons="true" :map-icon="'primary-boomerang'"></SquareItem>
									</div>
								</div>
								<Pagination class="pagination" :disablePips="true" :title-string="selectCategoryString" @on-click-back-fn="previousCategory" @on-click-forward-fn="nextCategory"></Pagination>
							</div>
						</div>

						<div class="button-container">
							<TextButton :button-label="$t('story_select.button_back')" :button-style="'blue'" @on-click-fn="closeModal" :disabled="attemptLogIn"></TextButton>
							<TextButton :button-label="$t('story_select.button_create_story')" :button-style="'bright-green'" @on-click-fn="createUserStory" :optional-param="{ view: Views.CHARACTER_SELECT }" :disabled="!selectionIsValid"></TextButton>
						</div>
					</div>
				</div>
			</template>
		</Panel>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Panel from '../ui-components/panel/panel.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import Checkbox from '../ui-components/form-inputs/checkbox.vue'
import DropDown from '../ui-components/form-inputs/dropdown.vue'
import { Views } from '../store/ui-store'
import { TutorialFlagsEnum } from '../../ftue/tutorial-flags'
import SquareItem from '../ui-components/item/square-items.vue'
import { MetaUnlocksManager } from '../../upgrades/meta/meta-unlocks-manager'

export default {
	name: 'FindCreateStory',
	components: {
		Panel,
		Checkbox,
		DropDown,
		TextButton,
		Header,
		SquareItem,
	},
	data() {
		const dataObj = {
			Views: Views,

			storyLength: 10,
			plotTwistSelected: 'raffle',
			showVotes: false,
			maxVotes: 1,
			maxVotesString: '1',
			enableChat: false,
			enableMetaProgression: false,
			startWithPlotTwist: false,

			// drop down data
			storyLengths: [
				{
					id: 5,
					name: '5',
				},
				{
					id: 7,
					name: '7',
				},
				{
					id: 10,
					name: '10',
				},
			],
			voteLengths: [
				{ id: 1, name: '1 hour' },
				{ id: 6, name: '6 hours' },
				{ id: 12, name: '12 hours' },
				{ id: 24, name: '24 hours' },
			],
			votingMethods: [
				{
					id: 'popularVote',
					name: 'Popular Vote',
				},
				{
					id: 'raffle',
					name: 'Raffle',
				},
			],
		}
		if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
			dataObj.voteLengths.unshift({ id: 0, name: '5 minutes (debug)' })
		}
		return dataObj
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapGetters('story', ['getVotingMethod', 'getGameType', 'getVoteLength', 'getMapSelection', 'getMaps', 'getDifficultySelection', 'getDifficulties']),
		...mapGetters('ui', ['portal']),
		...mapGetters('ftue', ['getFlag']),
		...mapState('user', ['attemptLogIn']),
		isFtue() {
			return !this.getFlag(TutorialFlagsEnum.MadeFirstStory)
		},
		getGameTypeOptions() {
			if (this.isFtue) {
				return [
					{
						id: 'solo',
						name: 'Solo',
					},
				]
			} else {
				return [
					{
						id: 'public',
						name: 'Community (public)',
					},
					{
						id: 'private',
						name: 'Friends (shared link)',
					},
					{
						id: 'solo',
						name: 'Solo',
					},
				]
			}
		},
		selectionIsValid() {
			return !this.attemptLogIn && !this.mapIsLocked(this.getMapSelection)
		}
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapActions('story', ['createStory']),
		...mapMutations('story', ['updateVotingMethod', 'updateGameType', 'updateVoteLength', 'updateCreateStoryPrompt', 'updateMapSelection', 'updateDifficultySelection']),
		...mapMutations('ui', ['updateDarkenOverlay']),
		setMaxVotes(e) {
			const votes = e.target.value
			const votesInt = parseInt(votes, 10)
			this.maxVotesString = ''

			if (votesInt >= 99) {
				this.maxVotes = 99
				this.maxVotesString = 'Infinite'
			} else if (votesInt < 99 && votesInt >= 1) {
				this.maxVotes = votes
				this.maxVotesString = votes.toString()
			} else {
				this.maxVotes = 1
				this.maxVotesString = '1'
			}
		},
		updateStoryLength(param) {
			this.storyLength = param
		},
		createUserStory() {
			this.updateDarkenOverlay(false)
			this.updateCreateStoryPrompt()

			const storyData = {
				plotTwist: this.plotTwistSelected,
				showVotes: this.showVotes,
				maxVotes: this.maxVotes,
				enableChat: this.enableChat,
				enableMetaProgression: this.enableMetaProgression,
				startWithPlotTwist: this.startWithPlotTwist,
			}
			this.createStory()
		},

		closeModal() {
			this.updateCreateStoryPrompt()
			this.updateDarkenOverlay(false)
		},
		isMapSelected(mapId) {
			return this.getMapSelection === mapId
		},
		mapIsLocked(mapId) {
			return !MetaUnlocksManager.getInstance().isMapAllowed(mapId)
		},
	},
	mounted() {
		if (this.isFtue) {
			this.updateGameType('solo')
		}
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

#find-create-story {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	#book-review {
		width: 660px;

		.create-story-container {
			width: 640px;
			display: flex;
			justify-content: center;

			.explain-game-type {
				color: @offWhite;
				font-size: 20px;
				padding: 8px 0px;
				.regularFont(@enSecondaryFontPTBold);
			}

			.form-data {
				width: 578px;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.input-wrapper {
					width: 578px;
					align-self: center;

					.input {
						margin-top: 20px;
					}

					select {
						width: 100%;
						background-color: @darkBlue;
						color: @green;
						font-size: 24px;
						padding: 8px 0px;
						.regularFont(@enSecondaryFontPTBold);
					}

					#checkbox {
						margin-top: 10px;
					}

					.votes-container {
						input {
							background-color: @darkBlue;
							border: none;
							font-size: 24px;
							color: @green;
							height: 35px;
							width: 85px;
						}
					}

					.game-type-info {
						color: @offWhite;
						margin: 10px 0px;

						&[lang='en'] {
							.regularFont(@enSecondaryFontPTBold);
							font-size: 22px;
							letter-spacing: 2px;
						}
					}
				}

				.button-container {
					display: flex;
					margin-top: 40px;
					align-self: center;

					.text-button {
						height: 77px;
						width: 253px;

						&:first-child {
							margin-right: 20px;
						}

						&:last-child {
							margin-left: 20px;
						}
					}
				}
			}
			.map-selection-container {
				width: 578px;
				margin: 20px 0px 0px;
				.icons-resized-container {
					margin: 20px 0px 0px;
					width: 578px;
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
					justify-content: center;
					align-content: center;
					transform: scale(0.97);
				}
				.pagination {
					margin: 10px 0px;
					display: flex;
					align-items: center;
				}
			}
		}
	}
}
</style>
