import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { VariantAI } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"

export const winterGoblin2: VariantAI = {
    extends: ENEMY_NAME.WINTER_GOBLIN_1,
    name: ENEMY_NAME.WINTER_GOBLIN_2,
    appearance: {
        asset: SpineDataName.WINTER_GOBLIN_2,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
    movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.69,
}

export const winterGoblin3: VariantAI = {
    extends: ENEMY_NAME.WINTER_GOBLIN_1,
    name: ENEMY_NAME.WINTER_GOBLIN_3,
    appearance: {
        asset: SpineDataName.WINTER_GOBLIN_3,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
    movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.73,
}

export const winterGoblin4: VariantAI = {
    extends: ENEMY_NAME.WINTER_GOBLIN_1,
    name: ENEMY_NAME.WINTER_GOBLIN_4,
    appearance: {
        asset: SpineDataName.WINTER_GOBLIN_4,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
    movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.77,
}

export const winterGoblin5: VariantAI = {
    extends: ENEMY_NAME.WINTER_GOBLIN_1,
    name: ENEMY_NAME.WINTER_GOBLIN_5,
    appearance: {
        asset: SpineDataName.WINTER_GOBLIN_5,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
    movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 0.81,
}