import { Vector } from 'sat'
import decomp from 'poly-decomp'
import { radians, degrees, percentage, timeInSeconds } from './primitive-types'
import MersenneTwister from 'mersenne-twister'
import { map } from 'lodash'
import { debugConfig } from './debug-config'
import { PolygonCollider } from '../engine/collision/colliders'
import '../utils/standard-library-additions'

const PI: number = Math.PI
export const HALF_PI = PI / 2

export class Vectors {
	static Zero: Vector = new Vector(0, 0)
	static One: Vector = new Vector(1, 1)
	static Right: Vector = new Vector(1, 0)
	static Left: Vector = new Vector(-1, 0)
	static Up: Vector = new Vector(0, 1)
	static Down: Vector = new Vector(0, -1)
	static All = [Vectors.Zero, Vectors.One, Vectors.Right, Vectors.Left, Vectors.Up, Vectors.Down]
}

export type VectorPredicate = (point: Vector) => boolean

/// Many objects have x and y members but are not SAT vectors
///  It's convenient to be able to pass them to vector methods
export interface VectorXY {
	x: number
	y: number
}

export const basicNumberLerp = (initialValue: number, valueToLerpTo: number, lerpRate: number) => {
	return initialValue * (1 - lerpRate) + valueToLerpTo * lerpRate
}

Vectors.All.forEach((v) => {
	Object.freeze(v)
})

export const degToRad = function (angle: degrees): radians {
	return angle * (Math.PI / 180)
}
export const radToDeg = function (angle: radians): degrees {
	return angle * (180 / Math.PI)
}

// dividend dependent modulo; works with negative values
export const modWrap = function (value: number, mod: number) {
	return ((value % mod) + mod) % mod
}

/**
 *
 * @param angle Angle in degrees from 0-360
 * @param min
 * @param max
 */
export const clampAngle = function (angle: degrees, min: number, max: number) {
	const normalizedMin = normalizeAngle180(min - angle)
	const normalizedMax = normalizeAngle180(max - angle)

	if (normalizedMin <= 0 && normalizedMax >= 0) {
		return angle
	}

	if (Math.abs(normalizedMin) < Math.abs(normalizedMax)) {
		return min
	} else {
		return max
	}
}

export const normalizeAngle180 = function (angle: degrees) {
	while (angle > 180) {
		angle -= 360
	}
	while (angle <= -180) {
		angle += 360
	}
	return angle
}

export const wrapAngle = function (angle: degrees) {
	angle = angle % 360
	if (angle < 0) {
		angle += 360
	}
	return angle
	// angle += 360
	// while (angle < 0) {
	// 	angle += 360
	// }
	// return angle - Math.floor(angle / 360) * angle
}

export const wrapRadian = function (rad: radians) {
	while (rad < -PI) {
		rad += 2 * PI
	}
	while (rad > PI) {
		rad -= 2 * PI
	}
	return rad
}

export const modAngle = function (angle: number, divisor: number) {
	return angle - (angle / divisor) * angle
}

export const getAcuteAngle = function (originAngle: radians, destinationAngle: radians) {
	return Math.atan2(Math.sin(destinationAngle - originAngle), Math.cos(destinationAngle - originAngle))
}

export const angleDistanceDegrees = function (angle1: degrees, angle2: degrees): number {
	const dist = Math.abs(angle2 - angle1) % 360
	if (dist > 180) {
		return 360 - dist
	} else {
		return dist
	}
}

export const angleDistanceRads = function (angle1: radians, angle2: radians): number {
	const dist = Math.abs(angle2 - angle1) % (Math.PI * 2)
	if (dist > Math.PI) {
		return Math.PI * 2 - dist
	} else {
		return dist
	}
}

export function average(a: number[]) {
	let sum = 0
	a.forEach((n) => (sum += n))
	return sum / a.length
}

export const add = function (v1: VectorXY, v2: VectorXY): Vector {
	return new Vector(v1.x + v2.x, v1.y + v2.y)
}

export const sub = function (v1: VectorXY, v2: VectorXY): Vector {
	return new Vector(v1.x - v2.x, v1.y - v2.y)
}

export const subVXY = function (v1: VectorXY, x: number, y: number): Vector {
	return new Vector(v1.x - x, v1.y - y)
}

export const scale = function (v1: VectorXY, s: number): Vector {
	return new Vector(v1.x * s, v1.y * s)
}

export const mag = function (v: Vector): number {
	return Math.sqrt(v.dot(v))
}

export const vecAngle = function (a: Vector, b: Vector): radians {
	const dot = a.x * b.x + a.y * b.y
	const det = a.x * b.y - a.y * b.x
	return Math.atan2(det, dot)
}

export function angleOfVector(v: VectorXY): radians {
	return Math.atan2(v.y, v.x)
}

export const vecLerp = function (a: VectorXY, b: VectorXY, t: number): Vector {
	return add(scale(a, 1 - t), scale(b, t))
}

/**
 * Converts a chance/percentage to a count. Hard to explain so I'll give example:
 * chance=3.6 would return 3, with a 60% chance of a 4
 * @param chance chance as well as count of how many loops
 */
export function chanceToLoops(chance: percentage | number): number {
	throwIfNegative(chance, `chance in chanceToLoops`)
	let loops = Math.floor(chance)
	const fractional = chance % 1
	if (Math.random() <= fractional) {
		loops++
	}
	return loops
}

export const vecMoveTowards = function (pos: VectorXY, target: VectorXY, step: number): Vector {
	const delta: Vector = sub(target, pos)
	const squaredLength: number = delta.dot(delta)
	if (squaredLength < step * step) {
		return new Vector(target.x, target.y)
	}
	const dirX = delta.x / Math.sqrt(squaredLength)
	const dirY = delta.y / Math.sqrt(squaredLength)
	return add(pos, new Vector(step * dirX, step * dirY))
}

export const randomRange = function (min: number, max: number, mt?: MersenneTwister): number {
	const r = mt ? mt.random() : Math.random()
	return r * (max - min) + min
}

export const roundRandomlyToInt = function (val: number): number {
	const remainder = (val % 1) * 1000
	if (Math.getRandomInt(1, 1000) < remainder) {
		val = Math.floor(val) + 1
	} else {
		val = Math.floor(val)
	}
	return val
}

export const randomMultiRoll = function (numRolls: number = 1): number {
	let bestRoll = 0
	while (numRolls > 0) {
		numRolls--
		const roll = Math.random()
		if (roll > bestRoll) {
			bestRoll = roll
		}
	}
	return bestRoll
}

// NOTE: functions that take dt are framerate independent

export const damp = function (source: number, target: number, smoothing: number, dt: number) {
	// described here: http://www.rorydriscoll.com/2016/03/07/frame-rate-independent-damping-using-lerp/
	return Math.lerp(source, target, 1 - Math.pow(smoothing, dt))
}

//  framerate independent damping
export const vecDamp = function (source: Vector, target: Vector, smoothing: number, dt: number) {
	// described here: http://www.rorydriscoll.com/2016/03/07/frame-rate-independent-damping-using-lerp/
	return vecLerp(source, target, 1 - Math.pow(smoothing, dt))
}

//  framerate independent damping
export const radDamp = function (source: number, target: number, smoothing: number, dt: number) {
	return lerpRot(source, target, 1 - Math.pow(smoothing, dt))
}

export function triangleWave(input: number) {
	return Math.abs((input % 4) - 2) - 1
}

// copied from nengi
const lerpRot = function (a: number, b: number, t: number) {
	const s = (1 - t) * Math.sin(a) + t * Math.sin(b)
	const c = (1 - t) * Math.cos(a) + t * Math.cos(b)
	return Math.atan2(s, c)
}

export const makeTangent = function (v: VectorXY, clockwise: boolean = true) {
	const x = v.x
	if (clockwise) {
		v.x = v.y
		v.y = -x
	} else {
		v.x = -v.y
		v.y = x
	}
}

// get perpendicular vector
export function perp(v: { x: number; y: number }) {
	return { x: -v.y, y: v.x }
}

// https://stackoverflow.com/questions/1560492/how-to-tell-whether-a-point-is-to-the-right-or-left-side-of-a-line
export function isPointLeftOfLine(lineStart: Vector, lineEnd: Vector, position: Vector) {
	return ((lineEnd.x - lineStart.x) * (position.y - lineStart.y) - (lineEnd.y - lineStart.y) * (position.x - lineStart.x)) > 0
}

// fuller description of how this works here:
// http://danikgames.com/blog/how-to-intersect-a-moving-target-in-2d/
export const shotInterceptVelocity = function (shotPos: VectorXY, shotSpeed: number, targetPos: VectorXY, targetVel: Vector) {
	const dirToTarget: Vector = sub(targetPos, shotPos).normalize()

	// decompose targets velocity onto
	//  axis moving to/from shotPos (parallel to dir):
	const targetVelParallel: Vector = scale(dirToTarget, targetVel.dot(dirToTarget))
	//  and axis perpendicular to that:
	const targetVelPerp: Vector = sub(targetVel, targetVelParallel)

	// the perpendicular velocities must be identical, otherwise there is no hit
	const shotVelPerp: Vector = targetVelPerp

	const shotVelSpeed: number = mag(shotVelPerp)
	if (shotVelSpeed > shotSpeed) {
		// shot is not fast enough, best we can do is shoot parallel to target
		const vel = targetVel
			.clone()
			.normalize()
			.scale(shotSpeed)
		return { velocity: vel }
	} else {
		const shotSpeedParallel: number = Math.sqrt(shotSpeed * shotSpeed - shotVelSpeed * shotVelSpeed)
		const shotVelParallel: Vector = dirToTarget.clone().scale(shotSpeedParallel)

		const shotRadius: number = 0
		const targetRadius: number = 0

		const timeToCollision: number = (mag(sub(shotPos, targetPos)) - shotRadius - targetRadius) / (shotVelParallel.len() - targetVelParallel.len())

		const shotVel: Vector = shotVelParallel.add(shotVelPerp)
		const interceptPos = add(shotPos, scale(shotVel, timeToCollision))

		return { velocity: shotVel, interceptPos }
	}
}

// returns the smallest angle you would need to add to [current] to make [target]
export const angleDiff = function (target: radians, current: radians) {
	return ((target - current + Math.PI * 3) % (Math.PI * 2)) - Math.PI
}

export const smoothDampRad = function (curRad: number, vel: number, targetRad: number, delta: number, smoothTime: number) {
	// make angle diff <180° so we damp in correct direction
	while (targetRad - curRad > PI) {
		curRad += 2 * PI
	}
	while (curRad - targetRad > PI) {
		targetRad += 2 * PI
	}

	const out = smoothDamp(curRad, vel, targetRad, delta, smoothTime)
	out[0] = wrapRadian(out[0])
	return out
}

/**
 * Smoothly moves between pos and target using vel as the current velocity.
 * This updates the velocity (in return) to keep the smooth motion for next frame.
 * see: Critically Damped Ease-In/Ease-Out Smoothing : Game Programming Gems 4
 *
 * @param pos current position
 * @param vel current velocity moving from pos to target
 * @param target target we're moving towards
 * @param delta
 * @param smoothTime the amount of time the smoothing would take *if moving at full velocity* - IMPORTANT
 * @returns [updated position, updated velocity]
 *
 */
export function smoothDamp(pos: number, vel: number, target: number, delta: number, smoothTime: number) {
	const omega = 2.0 / smoothTime
	const x = omega * delta
	const exp = 1.0 / (1.0 + x + 0.48 * x * x + 0.235 * x * x * x)
	const change = pos - target

	const temp = (vel + omega * change) * delta
	vel = (vel - omega * temp) * exp
	pos = target + (change + temp) * exp
	return [pos, vel]
}
/** Same as smoothDamp, but in 2d. See comments at {@link smoothDamp}. */
export function smoothDampV(pos: VectorXY, vel: VectorXY, target: VectorXY, delta: number, smoothTime: number) {
	const outx = smoothDamp(pos.x, vel.x, target.x, delta, smoothTime)
	const outy = smoothDamp(pos.y, vel.y, target.y, delta, smoothTime)
	pos.x = outx[0]
	pos.y = outy[0]
	vel.x = outx[1]
	vel.y = outy[1]
}

export const lengthXY = function (x, y) {
	return Math.sqrt(x * x + y * y)
}

export const lengthV = function (v: VectorXY) {
	return Math.sqrt(v.x * v.x + v.y * v.y)
}

export const distanceSquared = function (x1: number, y1: number, x2: number, y2: number) {
	return (x1 - x2) ** 2 + (y1 - y2) ** 2
}

export const distanceSquaredVV = function (v1: VectorXY, v2: VectorXY) {
	return (v1.x - v2.x) ** 2 + (v1.y - v2.y) ** 2
}

export const distance = function (x1: number, y1: number, x2: number, y2: number) {
	return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2)
}

export const distanceVV = function (v1: VectorXY, v2: VectorXY) {
	return Math.sqrt(distanceSquaredVV(v1, v2))
}

export const withinDistanceVV = function (v1: VectorXY, v2: VectorXY, dist: number) {
	return distanceSquared(v1.x, v1.y, v2.x, v2.y) < dist * dist
}

export const withinDistanceVXY = function (v1: VectorXY, v2x: number, v2y: number, dist: number) {
	return distanceSquared(v1.x, v1.y, v2x, v2y) < dist * dist
}

export const withinDistanceXYXY = function (x1: number, y1: number, x2: number, y2: number, dist: number) {
	return distanceSquared(x1, y1, x2, y2) < dist * dist
}

export const midpoint = function (vectors: { position: Vector }[]) {
	let avgX = 0
	let avgY = 0
	const length = vectors.length
	vectors.forEach((v) => {
		avgX += v.position.x
		avgY += v.position.y
	})
	return { x: avgX / length , y: avgY / length }
}

export function inRect(x: number, y: number, xmin: number, ymin: number, xmax: number, ymax: number) {
	return x >= xmin && x <= xmax && y >= ymin && y <= ymax
}

/** Returns a or b, whichever is closest to x */
export function closest(x: number, a: number, b: number) {
	if (Math.abs(x - b) < Math.abs(x - a)) {
		return b
	}
	return a
}

// approximates the derivative of func, by getting y value separated by small h/dx
export function crappyDerivative(x: number, func: (x: number) => number, dx = 0.0001) {
	const y1 = func(x)
	const y2 = func(x + dx) // yes
	const dy = y2 - y1
	return dy / dx
}

/**  maps a number from one range to another
 *  out = mapToRange(1,  0,2,  10,20)  // (inputRange:(0-2), outputRange:(10,20))
 *  // out == 15
 */
export function mapToRange(input: number, input_start: number, input_end: number, output_start: number, output_end: number, clamped = false) {
	throwIfNotFinite(input, 'input')
	throwIfNotFinite(input_start, 'input_start')
	throwIfNotFinite(input_end, 'input_end')
	throwIfNotFinite(output_start, 'output_start')
	if (input_start === input_end) {
		console.assert(input === input_start, `bad input range in MapToRange:(${input_start},${input_end})`)
		return output_start
	}

	const slope = (output_end - output_start) / (input_end - input_start)
	throwIfNotFinite(slope)
	let output = output_start + slope * (input - input_start)
	if (clamped) {
		if (output_start > output_end) {
			[output_start, output_end] = [output_end, output_start]
		}
		output = Math.clamp(output, output_start, output_end)
	}
	throwIfNotFinite(output)
	return output
}

export const ellipseRadius = function (e: { rX: number; rY: number }, a: radians): number {
	return (e.rX * e.rY) / Math.sqrt(e.rX ** 2 * Math.sin(a) ** 2 + e.rY ** 2 * Math.cos(a) ** 2)
}

export function inEllipse(e: { rX: number; rY: number }, point: VectorXY) {
	return (point.x * point.x) / (e.rX * e.rX) + (point.y * point.y) / (e.rY * e.rY) < 1
}

// ccwConcavePolygon: a list of vertices which define a counter-clock-wise, potentially concave polygon
// returns: an array of sat polygons
export function makeSatPolygons(position: VectorXY, ccwConcavePolygon: number[]): PolygonCollider[] {
	const decompConcave = []
	for (let i = 0; i < ccwConcavePolygon.length; i += 2) {
		const v0 = ccwConcavePolygon[i]
		const v1 = -ccwConcavePolygon[i + 1]
		decompConcave.push([v0, v1])
	}
	decomp.makeCCW(decompConcave)
	const convexPolygons = decomp.quickDecomp(decompConcave)
	const polygons = map(convexPolygons, (polygon: number[]) => makeSatPolygon(position, polygon))
	return polygons
}

export function makeSatPolygon(position: VectorXY, verticies: number[]): PolygonCollider {
	const vecVert = []
	for (let i = 0; i < verticies.length; i++) {
		const x = verticies[i][0]
		const y = verticies[i][1]
		const v = new Vector(x, y)
		vecVert.push(v)
	}
	return new PolygonCollider(new Vector(position.x, position.y), vecVert)
}

export const rotateV = function (v: Vector, rad: radians) {
	const cos = Math.cos(rad)
	const sin = Math.sin(rad)
	return new Vector(v.x * cos - v.y * sin, v.x * sin + v.y * cos)
}

export const getRandomDirectionVector = function (dist: number = 1): Vector {
	const rad = Math.random() * Math.PI * 2
	return new Vector(Math.cos(rad) * dist, Math.sin(rad) * dist)
}

export function getVertices(p: number[], flipY = false): Vector[] {
	const vertices: Vector[] = []
	for (let i = 0; i < p.length; i += 2) {
		const y = p[i + 1]
		vertices.push(new Vector(p[i], flipY ? -y : y))
	}
	return vertices
}

export function getRandomPointInCircle(xPos: number, yPos: number, radius: number): Vector {
	// sqrt the radius factor to make distribution uniform
	// https://programming.guide/random-point-within-circle.html
	const r = radius * Math.sqrt(Math.random())
	const a = Math.PI * 2 * Math.random()
	const x = xPos + Math.cos(a) * r
	const y = yPos + Math.sin(a) * r
	return new Vector(x, y)
}

export function getRandomPointInCircleRange(xPos: number, yPos: number, minRange: number, maxRange: number, mt?: MersenneTwister, out?: Vector): Vector {
	const angle = Math.PI * 2 * (mt ? mt.random() : Math.random())
	const vec = out ?? new Vector()
	vec.x = 1
	vec.y = 0
	vec.rotate(angle)

	const randomDist = randomRange(minRange, maxRange, mt)
	vec.scale(randomDist)

	vec.x += xPos
	vec.y += yPos
	return vec
}

export function getRandomPointInRect(xCenter: number, yCenter: number, width: number, height: number): Vector {
	const x = xCenter - width / 2 + width * Math.random()
	const y = yCenter - height / 2 + height * Math.random()
	return new Vector(x, y)
}

export function getRandomPointInRectRange(xCenter: number, yCenter: number, minWidth: number, maxWidth: number, minHeight: number, maxHeight: number, out?: Vector): Vector {
	const vec = out ?? new Vector()

	let x = Math.random() * (maxWidth - minWidth) + minWidth
	let y = Math.random() * (maxHeight - minHeight) + minHeight

	if (Math.random() > 0.5) {
		x *= -1
	}

	if (Math.random() > 0.5) {
		y *= -1
	}

	vec.x = x + xCenter
	vec.y = y + yCenter

	return vec
}

// used in WIP unicodeDegreesChar function only
// export function compassIndexDegrees(angle: degrees, divisions: number): number {
// 	angle = wrapAngle(angle)
// 	const divSize = 360 / divisions
// 	let point = Math.floor((angle + divSize * 0.5) / divSize)

// 	point = Math.clamp(point, 0, divisions - 1)
// 	return point
// }

// TODO3: diagonal arrows are not supported on my terminal.. having only 4 compass points is kinda pointless... scrap?
//  also, these are pointed in wrong direction for our game, needs sorting out (grabbed from previous project)
// export function unicodeDegreesChar(angle: degrees): string {
// 	const angleChars = ['↑', '↗', '→', '↘', '↓', '↙', '←', '↖']
// 	const idx = compassIndexDegrees(angle, angleChars.length)
// 	//console.log(angle, idx)
// 	return angleChars[idx]
// }

// export function unicodeVectorChar(v: Vector): string {
// 	const angle = angleInDegreesFromVector(v)

// 	return unicodeDegreesChar(angle)
// }

export function roundToDecimals(n: number, decimals: number) {
	return parseFloat(n.toFixed(decimals))
}

export function throwIfNotFinite(x: number, optionalMessage?: string) {
	if (!debugConfig.disableThrowIfNotFinite) {
		if (typeof x !== 'number' || !Number.isFinite(x)) {
			throw new Error(optionalMessage || 'number is not finite or not a number: ' + x)
		}
	}
}

export function throwIfNaN(x: number, optionalMessage?: string) {
	if (Number.isNaN(x)) {
		throw new Error(optionalMessage || 'number is NaN')
	}
}

export function throwIfTruthy(thing: any, message: string) {
	if (thing) {
		throw new Error(message)
	}
}

export function throwIfFalsy(thing: any, message: string) {
	if (!thing) {
		throw new Error(message)
	}
}

export function throwIfNegative(x: number, optionalMessage?: string) {
	if (x < 0) {
		throw new Error(optionalMessage || 'number is negative')
	}
}

export function getProjectileHorizontalDistance(xVelocity: number, time: timeInSeconds) {
	return xVelocity * time
}

export function getProjectileVerticalDistance(initialHeight: number, intialVerticalVelocity: number, time: timeInSeconds, gravity: number) {
	return initialHeight + intialVerticalVelocity * time - gravity * (time**2) / 2
}

export function getProjectileFlightTime(initialHeight: number, initialVerticalVelocity: number, gravity: number) {
	if (initialHeight === 0) {
		return 2 * (initialVerticalVelocity / gravity)
	} else {
		return (initialVerticalVelocity + Math.sqrt(initialVerticalVelocity**2 + (2*gravity*initialHeight))) / gravity
	}
}

export function getProjectileRange(initialHeight: number, horizontalVelocity: number, initialVerticalVelocity: number, gravity: number) {
	return horizontalVelocity * getProjectileFlightTime(initialHeight, initialVerticalVelocity, gravity)
}

export function getProjectileVelocity(angle: radians, range: number, gravity: number, height?: number) {
	if (height) {
		const top = Math.sqrt(gravity) * range * Math.sqrt(secant(angle))
		const botPart1 = (2 * height * Math.cos(angle))
		const botPart2 = (2 * range * Math.sin(angle))
		const bottom = Math.sqrt(botPart1 + botPart2)

		// console.log(`range`, range, `height`, height, `angle`, angle)
		// console.log(`cos angle`, Math.cos(angle), `sin angle`, Math.sin(angle),  `height cos`, height * Math.cos(angle), `range sin`, range * Math.sin(angle))
		// console.log(`top`, top, `bot1`, botPart1, `bot2`, botPart2, `bottom`, bottom, `result`, top/bottom)

		return top / bottom
	} else {
		const top = Math.sqrt(gravity) * Math.sqrt(range)
		const bottom = Math.sqrt(Math.sin(2 * angle))
		const result = top / bottom

		// console.log(`range`, range, `angle`, angle, `2sin`, Math.sin(2* angle))
		// console.log(`t, b, r`, top, bottom, result)

		return result
	}
}

function secant(value: number) { 
	return 1 / Math.cos(value)
}


// everything must be between 0 and 2pi
export function isBetweenAngles(angle: radians, left: radians, right: radians) {
	// https://stackoverflow.com/questions/10236848/is-angle-in-between-two-angles
	if(left <= right) {
		if(right - left <= PI) {
			return left <= angle && angle <= right
		} else {
			return right <= angle || angle <= left
		}
	} else {
		if(left - right <= PI) {
			return right <= angle && angle <= left
		} else {
			return left <= angle || angle <= right
		}
	}
}

/** returns array of numbers in ascending order */
export function order(x1: number, x2: number) {
	if (x1 > x2) {
		return [x2, x1]
	} else {
		return [x1, x2]
	}
}

export function isPointInRect(point: VectorXY, rect: { center: VectorXY; size: VectorXY }) {
	const hx = rect.size.x * 0.5
	const hy = rect.size.y * 0.5
	const minx = rect.center.x - hx
	const maxx = rect.center.x + hx
	const miny = rect.center.y - hy
	const maxy = rect.center.y + hy
	return point.x >= minx && point.x <= maxx && point.y >= miny && point.y < maxy
}

/** returns true if `x` is between `boundary1` and `boundary1` */
export function isBetweenInclusive(x: number, boundary1: number, boundary2: number) {
	const ordered = order(boundary1, boundary2)
	return x >= ordered[0] && x <= ordered[1]
}

export function toCountdownString(durationInSeconds: number, includeDays: boolean) {
	const days = includeDays ? Math.floor(durationInSeconds / (3600 * 24)) : 0
	const daysInSeconds = days * 3600 * 24

	const hours = Math.floor(durationInSeconds / 3600) - daysInSeconds / (60 * 60)
	const hoursInSeconds = hours * 3600

	const minutes = Math.floor(durationInSeconds / 60) - daysInSeconds / 60 - hoursInSeconds / 60
	const minutesInSeconds = minutes * 60

	const seconds = durationInSeconds - daysInSeconds - hoursInSeconds - minutesInSeconds

	const hStr = hours < 10 ? '0' + hours : hours
	const mStr = minutes < 10 ? '0' + minutes : minutes
	const sStr = seconds < 10 ? '0' + seconds : seconds

	if (includeDays) {
		return `${days}d ${hStr}:${mStr}:${sStr}`
	}
	return `${hStr}:${mStr}:${sStr}`
}

export function to4DigitTruncatedString(x: number): string {
	if (x > 999999999999) { //trillion
		return parseFloat((x / 1000000000000).toPrecision(4)) + "T"
	}

	if (x > 999999999) { //billion
		return parseFloat((x / 1000000000).toPrecision(4)) + "B"
	}

	if (x > 999999) { // million
		return parseFloat((x / 1000000).toPrecision(4)) + "M"
	}

	if (x > 9999) { // ten thousand (9999 is an ok number to have)
		return parseFloat((x / 1000).toPrecision(4)) + "K"
	}

	if (isNaN(x)) {
		return ''
	}

	return x.toString()
}

export function to3DigitTruncatedString(x: number): string {
	if (x > 999999999999) { //trillion
		return parseFloat((x / 1000000000000).toPrecision(3)) + "T"
	}

	if (x > 999999999) { //billion
		return parseFloat((x / 1000000000).toPrecision(3)) + "B"
	}

	if (x > 999999) { // million
		return parseFloat((x / 1000000).toPrecision(3)) + "M"
	}

	if (x > 999) { // thousand
		return parseFloat((x / 1000).toPrecision(3)) + "K"
	}

	if (isNaN(x)) {
		return ''
	}

	return x.toString()
}

export function getMultiplicativeReduction(initialValue: number, reductionPerRanks: number, ranks: number) {
	//There's probably a simple math equation for this, but I can't think of it right now
	for (let i = 0; i < ranks; ++i) {
		const reduction = initialValue * reductionPerRanks
		initialValue -= reduction
	}

	return initialValue
}
