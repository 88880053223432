import { ReadOnlyMap } from "../utils/read-only-map";

export const ModelPaths = new ReadOnlyMap({
	'player-skins': "./sprites/player/player.json",
	'player-bow': "./sprites/player/player-bow.json",
	'player-quadruped': "./sprites/player/player-quadruped.json",
	// 'crab': "./sprites/enemies/crab/crab.json",
	'prismfly': "./sprites/enemies/prismfly/prismfly.json",
	'dreamer-boss': "./sprites/enemies/dreamer-boss/dreamer-boss.json",
	"ice-drake": "./sprites/enemies/ice-drake/everfrost.json",
	'chaos-seer-boss': "./sprites/enemies/chaos-seer-boss/chaos-seer-boss.json",
	'prism-fly-boss': "./sprites/enemies/prism-fly-boss/prism-fly-boss.json",
	// 'weapon-crossbow': "./sprites/weapons/player_crossbows.json",
	'weapon-bow': "./sprites/weapons/player_bows.json",
	'weapon-spear': "./sprites/weapons/player_spears.json",
	'weapon-wand': "./sprites/weapons/player_wands.json",
	'weapon-boomerang': "./sprites/weapons/player_boomerangs.json",
	'weapon-cannon': "./sprites/weapons/player_cannons.json",
	// 'definitely-rat': "./sprites/weapons/definitely-rat/definitely-rat.json",
	'yeti': "./sprites/enemies/yeti/abominable-yeti.json",
})
