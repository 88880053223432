import { differenceInMilliseconds, parseISO } from 'date-fns'
import { clone } from 'lodash'
import { nextTick } from 'vue'
import { TutorialFlagsEnum } from '../../ftue/tutorial-flags'
import { startArchivedStoriesWalkthrough, startCreateStoryWalkthrough, startStoriesWalkthrough } from '../../ftue/tutorial-walkthroughs'
import { MUTATOR_DEFINITIONS } from '../../mutators/mutator-definitions'
import { MetaPerkName } from '../../upgrades/meta/meta-perk-name'
import { getChapterRuns, getMyStories, getStories, getStory, postArchiveStory, postJoinStory, postSearchStories, postStory } from '../../utils/api/griddle-requests'
import clientLogger from '../../utils/client-logger'
import { Views } from '../store/ui-store'
import { UI } from '../ui'
import { MapNames } from '../../world-generation/world-data'
import { StoryDifficulty } from '../../game-data/difficulties'


export const TOTAL_ACTIVE_STORIES = 15

export enum StoryScreens {
	ACTIVE_STORIES = 'activeStories',
	ARCHIVED_STORIES = 'archivedStories',
	SEARCH_STORY = 'findStory',
}

export type VoteLength = 0 | 1 | 6 | 12 | 24

export type PlotTwistUI = {
	id: string
	icon: string
	name: string
	description: string
}

export type SearchModeDurationHours = 2 | 24 | 48 | 168 | 720

export type SearchMemberSize = 'small' | 'medium' | 'large' | 'massive'

export type SearchMode = 'hot' | 'new'

export type SearchOptions = {
	page: number
	mode: SearchMode
	modeDurationHours?: SearchModeDurationHours
	minChapter?: number
	maxChapter?: number
	hasPlotTwists?: MetaPerkName[]
	memberSize?: SearchMemberSize
}

let maxChapter = 0
Object.values(MUTATOR_DEFINITIONS).forEach((v) => {
	if (!v.isDebug) {
		maxChapter++
	}
})


const initialSearchState: SearchOptions = {
	page: 0,
	mode: 'new',
	modeDurationHours: 24,
	minChapter: 1,
	maxChapter: maxChapter,
	hasPlotTwists: [],
	memberSize: null,
}

//TODO: remove unused?
export type Story = {
	id: string
	creator: string
	name: string
	chapter: number
	isPublic: boolean
	players: string[]
	createdAt: string
	twists: string[]
	mapSelected: string
	storyDifficulty: StoryDifficulty
	formattedTwists: PlotTwistUI[]
	newStory: {
		votingMethod: 'raffle' | 'popularVote'
		gameType: 'public' | 'private' | 'solo'
		voteLength: VoteLength
		mapSelection: MapNames
		difficultySelection: StoryDifficulty
	}
	showCreateModal: boolean
	showActiveStories: boolean
	showArchivedStories: boolean
	showFindStory: boolean
	storyScreens: StoryScreens
	searchedStoryResults: any
	searchParams: SearchOptions
	twitSlotIndex1: string
	twitSlotIndex2: string
	twitSlotIndex3: string
	activeAnimationSeqTriggered: boolean
	archivedAnimationSeqTriggered: boolean
	searchAnimationSeqTriggered: boolean
	gameIdInput: string
	gameIdError: boolean
	mapSelection: string
	activeStoryCount: number
}

const initialState = {
	selectedStoryId: '',
	stories: [],
	fetchingStories: true,
	selectedStory: null,
	oldSelectedChapter: '1',
	selectedChapter: '1',
	selectedChapterRuns: [],
	chapterRunDataLoading: false,
	selectedChapterTableStats: [],
	selectedTwists: [],
	mapSelected: '',
	storyDifficulty: 'normal',
	newStory: {
		votingMethod: 'popularVote',
		gameType: 'public',
		voteLength: 6,
		mapSelection: 'forest',
		difficultySelection: 'normal'
	},
	showActiveStories: true,
	showArchivedStories: false,
	showFindStory: false,
	showCreateModal: false,
	showGameIdSearchModal: false,
	storyScreens: StoryScreens,
	searchedStoryResults: [],
	searchParams: clone(initialSearchState),
	twitSlotIndex1: '',
	twitSlotIndex2: '',
	twitSlotIndex3: '',
	activeAnimationSeqTriggered: false,
	archivedAnimationSeqTriggered: false,
	searchAnimationSeqTriggered: false,
	gameIdInput: '',
	gameIdError: false,
	activeStoryCount: 0
}

/**
 * Mutates the story to change the twists from an array of strings to an object with UI details.
 * @param story
 */
function hydrateTwistsOnStory(story: any) {
	if (story.formattedTwists === undefined) {
		story.formattedTwists = []
	}
	story.twists.forEach((twistName, i) => {
		if (twistName === null) {
			return
		}
		const twistDef = MUTATOR_DEFINITIONS[twistName]
		if (twistDef) {
			story.formattedTwists[i] = { twist: twistName, id: twistName, icon: twistDef.icon, name: twistDef.name, desc: twistDef.description, isPositive: Boolean(twistDef.isPositive) }
		} else {
			story.twists.remove(twistName)
		}
	})
}

/**
 * Convert a large array of run objects into just the subset of data we need to show the runs table
 * @param runs
 * @returns
 */
export function getTableStatsForChapterRuns(runs: any[]) {
	runs = runs.map((run) => {
		const { username, character, sumOfEnemiesKilled, totalRunDurationInSeconds, totalScore, isWin, iconTag, iconColor } = run

		return {
			username,
			character: character, //TODO: real name
			sumOfEnemiesKilled: sumOfEnemiesKilled,
			totalRunDurationInSeconds: totalRunDurationInSeconds,
			totalScore: totalScore,
			isWin: isWin ? 'Victory!' : '',
			iconTag: iconTag,
			iconColor: iconColor
		}
	})
	return runs
}

const store = {
	namespaced: true,
	modules: {},
	state: initialState,
	getters: {
		getSelectedStoryId(state: StoryStoreState) {
			return state.selectedStoryId
		},
		getSelectedStory(state: StoryStoreState) {
			return state.selectedStory
		},
		isSelectedStorySolo(state: StoryStoreState) {
			if (state.selectedStory) {
				return state.selectedStory.isSolo
			}
			return false
		},
		getStoryTwists(state: StoryStoreState) {
			return state.selectedTwists
		},
		getVotingMethod(state: StoryStoreState) {
			return state.newStory.votingMethod
		},
		getGameType(state: StoryStoreState) {
			return state.newStory.gameType
		},
		getVoteLength(state: StoryStoreState) {
			return state.newStory.voteLength
		},
		votingTimeLeft(state, getters, rootState, rootGetters) {
			const endTime = parseISO(state.selectedStory.nextTally)
			return differenceInMilliseconds(endTime, rootGetters['time/date'])
		},
		isVotingOpen(state, getters) {
			return state.selectedChapter === state.oldSelectedChapter
		},
		getPlayerActiveStories(state, rootGetters, rootState) {
			const playerId = rootState.user.id
			const playerActiveStories = []
			for (const story of state.stories) {
				const playerStory = story.players.find((p) => p.playerId === playerId)
				if (playerStory && !playerStory.isArchived) {
					playerActiveStories.push(story)
				}
			}
			return playerActiveStories
		},
		getPlayerArchivedStories(state, rootGetters, rootState) {
			const playerId = rootState.user.id
			const playerArchivedStories = []
			for (const story of state.stories) {
				const playerStory = story.players.find((p) => p.playerId === playerId)
				if (playerStory && playerStory.isArchived) {
					playerArchivedStories.push(story)
				}
			}
			return playerArchivedStories
		},
		getModeDuration(state: StoryStoreState) {
			return state.searchParams.modeDurationHours
		},
		getMode(state: StoryStoreState) {
			return state.searchParams.mode
		},
		getMinChapter(state: StoryStoreState) {
			return state.searchParams.minChapter
		},
		getMaxChapter(state: StoryStoreState) {
			return state.searchParams.maxChapter
		},
		getMemberSize(state: StoryStoreState) {
			return state.searchParams.memberSize
		},
		getTwistSlotIndex1(state: StoryStoreState) {
			return state.twitSlotIndex1
		},
		getTwistSlotIndex2(state: StoryStoreState) {
			return state.twitSlotIndex2
		},
		getTwistSlotIndex3(state: StoryStoreState) {
			return state.twitSlotIndex3
		},
		getPage(state: StoryStoreState) {
			return state.searchParams.page
		},
		getMapSelection(state: StoryStoreState) {
			return state.newStory.mapSelection
		},
		getMaps(state: StoryStoreState) {
			if (process.env.IS_WEB) {
				return [{ id: 'forest', name: 'Forest', icon: 'map-icon-forest' }]
			} else {
				return [
					{ id: 'forest', name: 'Forest', desc: 'An adventure set deep in a magical forest.', icon: 'map-icon-forest' },
					{ id: 'tundra', name: 'Mountain', desc: 'A dangerous stroll up a brisk mountain. +50% currency.', icon: 'map-icon-mountain' },
				]
			}
		},
		getDifficultySelection(state: StoryStoreState) {
			return state.newStory.difficultySelection
		},
		getDifficulties(state: StoryStoreState) {
			if (process.env.IS_WEB) {
				return [
					{ id: 'normal', name: 'Normal', desc: 'The default difficulty, recommended for new players.' },
				]
			} else {
				return [
					{ id: 'normal', name: 'Normal', desc: 'The default difficulty, recommended for new players.' },
					{ id: 'hard', name: 'Hard', desc: 'A more challenging difficulty, recommended if you have played other arena roguelikes.' },
					{ id: 'brutal', name: 'Brutal', desc: 'Very difficult, recommended if you have acquired lots of perks and unlocks.' },
				]
			}
		},
		getStoryDifficulty(state: StoryStoreState) {
			return state.storyDifficulty
		},
	},
	mutations: {
		updateModeDuration(state: StoryStoreState, modeDuration: SearchModeDurationHours) {
			state.searchParams.modeDurationHours = modeDuration
			state.searchParams.page = 0
		},
		updateMode(state: StoryStoreState, mode: SearchMode) {
			state.searchParams.mode = mode
			state.searchParams.page = 0
		},
		updateMinChapter(state: StoryStoreState, minChapter: number) {
			state.searchParams.minChapter = minChapter
			state.searchParams.page = 0
		},
		updateMaxChapter(state: StoryStoreState, maxChapter: number) {
			state.searchParams.maxChapter = maxChapter
			state.searchParams.page = 0
		},
		updateMemberSize(state: StoryStoreState, memberSize: SearchMemberSize) {
			state.searchParams.memberSize = memberSize
			state.searchParams.page = 0
		},
		updateTwistSlotIndex1(state: StoryStoreState, twist: MetaPerkName) {
			state.twitSlotIndex1 = twist
			state.searchParams.hasPlotTwists.push(twist)
			state.searchParams.page = 0
		},
		updateTwistSlotIndex2(state: StoryStoreState, twist: MetaPerkName) {
			state.twitSlotIndex2 = twist
			state.searchParams.hasPlotTwists.push(twist)
			state.searchParams.page = 0
		},
		updateTwistSlotIndex3(state: StoryStoreState, twist: MetaPerkName) {
			state.twitSlotIndex3 = twist
			state.searchParams.hasPlotTwists.push(twist)
			state.searchParams.page = 0
		},
		clearSearchParams(state: StoryStoreState) {
			state.searchParams = clone(initialSearchState)
			state.searchParams.hasPlotTwists = []
			state.twitSlotIndex1 = ''
			state.twitSlotIndex2 = ''
			state.twitSlotIndex3 = ''
		},
		updatePage(state: StoryStoreState, page: number) {
			state.searchParams.page = page
		},
		updateFetchingStories(state: StoryStoreState, fetchingStories) {
			state.fetchingStories = fetchingStories
		},
		updateStories(state: StoryStoreState, stories) {
			stories.forEach(hydrateTwistsOnStory)
			state.stories = stories
		},
		updateSelectedChapter(state: StoryStoreState, storyChapter) {
			state.selectedChapter = storyChapter
		},
		updateChapterRuns(state: StoryStoreState, runs) {
			state.selectedChapterRuns = runs
		},
		updateChapterTableStats(state: StoryStoreState, stats) {
			state.selectedChapterTableStats = stats
		},
		setChapterRunDataLoading(state: StoryStoreState, loading) {
			state.chapterRunDataLoading = loading
		},
		updateVotingMethod(state: StoryStoreState, votingMethod: 'raffle' | 'popularVote') {
			state.newStory.votingMethod = votingMethod
		},
		updateGameType(state: StoryStoreState, gameType: 'public' | 'private' | 'solo') {
			state.newStory.gameType = gameType
		},
		updateVoteLength(state: StoryStoreState, voteLength: VoteLength) {
			state.newStory.voteLength = voteLength
		},
		updateStoryScreen(state: StoryStoreState, screen) {
			switch (screen) {
				case StoryScreens.ACTIVE_STORIES:
					state.showActiveStories = true
					state.showArchivedStories = false
					state.showFindStory = false
					break
				case StoryScreens.ARCHIVED_STORIES:
					state.showActiveStories = false
					state.showArchivedStories = true
					state.showFindStory = false

					if (!UI.getInstance().store.getters['ftue/getFlag'](TutorialFlagsEnum.ArchivedStories)) {
						startArchivedStoriesWalkthrough()
						this.commit('ftue/completeFlag', TutorialFlagsEnum.ArchivedStories, { root: true })
					}
					break
				case StoryScreens.SEARCH_STORY:
					state.showActiveStories = false
					state.showArchivedStories = false
					state.showFindStory = true
					break
			}
		},
		updateCreateStoryPrompt(state: StoryStoreState) {
			state.showCreateModal = !state.showCreateModal
		},
		updateGameIdSearchPrompt(state: StoryStoreState) {
			state.showGameIdSearchModal = !state.showGameIdSearchModal
		},
		updateSearchResults(state: StoryStoreState, stories) {
			if (stories.length === 0) {
				state.searchParams.page = 0
			}
			stories.forEach(hydrateTwistsOnStory)
			state.searchedStoryResults = stories
		},

		// If the animation is a pain point when switch screens
		// I can use these unused variables to disable the animation
		// on that screen if you seen it once. I still want it to play
		// the a fetch for stories does happen though.
		updateActiveAnimationSeq(state: StoryStoreState) {
			state.activeAnimationSeqTriggered = true
		},
		updateSearchAnimationSeq(state: StoryStoreState) {
			state.searchAnimationSeqTriggered = true
		},
		updateArchiveAnimationSeq(state: StoryStoreState) {
			state.archivedAnimationSeqTriggered = true
		},
		resetAnimationSequence(state: StoryStoreState) {
			state.activeAnimationSeqTriggered = false
			state.archivedAnimationSeqTriggered = false
			state.searchAnimationSeqTriggered = false
		},
		updateGameIdInput(state: StoryStoreState, gameId: string) {
			state.gameIdInput = gameId
			state.gameIdError = false
		},
		updateGameIdError(state: StoryStoreState, error: boolean) {
			state.gameIdError = error
		},
		resetGameIdState(state: StoryStoreState) {
			state.gameIdInput = ''
			state.gameIdError = false
		},
		updateMapSelection(state: StoryStoreState, mapSelection: MapNames) {
			state.newStory.mapSelection = mapSelection
		},
		updateActiveStoryCount(state: StoryStoreState, count: number) {
			state.activeStoryCount = count
		},
		updateDifficultySelection(state: StoryStoreState, difficultySelection: StoryDifficulty) {
			state.newStory.difficultySelection = difficultySelection
		},
	},
	actions: {
		async fetchMyStories({ state, commit, rootState, rootGetters }: { state: StoryStoreState; commit: any; rootState: any; rootGetters: any }) {

			try {
				commit('updateFetchingStories', true)
				const stories = await getMyStories(state.showArchivedStories)
				commit('updateStories', stories)
				if (!state.showArchivedStories) {
					commit('updateActiveStoryCount', stories.length)
				}
				commit('updateFetchingStories', false)
			} catch (error) {
				commit('updateFetchingStories', false)
				clientLogger.error('Error occurred when attempting to fetch Stories', error)
			}
		},
		async fetchStoryById({ state, commit, dispatch, rootState }: { state: StoryStoreState; commit: any; dispatch: any; rootState: any }, storyId: string) {
			try {
				console.log('Attempting to fetch Story by Id')

				const story = await getStory(storyId)
				if (story.id !== undefined) {
					dispatch('setSelectedStory', story)
					dispatch('fetchChapterRuns', state.selectedChapter)
				}
			} catch (error) {
				clientLogger.error('Error occurred when attempting to fetch Stories', error)
			}
		},
		async fetchChapterRuns({ state, commit }: { state: StoryStoreState; commit: any }, chapter: number) {
			try {
				console.log(`Fetching chapter runs for ${state.selectedStoryId} chapter ${chapter}`)
				commit('setChapterRunDataLoading', true)
				const runs = await getChapterRuns(state.selectedStoryId, chapter)
				console.log('Runs', runs)
				commit('updateChapterRuns', runs)
				commit('updateChapterTableStats', getTableStatsForChapterRuns(runs))
				commit('setChapterRunDataLoading', false)
			} catch (err) {
				clientLogger.error('Error occurred when attempting to fetch chapter runs', err)
			}
		},
		async refreshStory({ state, commit, dispatch }: { state: StoryStoreState; commit: any; dispatch: any }) {
			const id = state.selectedStoryId
			state.oldSelectedChapter = state.selectedChapter
			await dispatch('story/fetchStoryById', id, { root: true })
			dispatch('story/fetchChapterRuns', state.selectedChapter, { root: true })
		},
		async joinStory({ state, dispatch, rootState, getters }: { state: StoryStoreState; dispatch: any; rootState: any; getters: any }) {
			const userId = rootState.user.id

			if ((state.selectedStory.creator.includes(userId) || state.selectedStory.players.find((p) => p.playerId === userId)) && !state.selectedStory.currentUserHasArchived) {
				dispatch('ui/startGame', null, { root: true })
				dispatch('activityFeed/cancelLongPollGetNewActivity', null, { root: true })
			} else {
				try {
					const joinResults = await postJoinStory(state.selectedStoryId)

					if (joinResults === 200) {
						console.log('User Joined Story, Jumping to Game')

						dispatch('ui/startGame', null, { root: true })
						dispatch('activityFeed/cancelLongPollGetNewActivity', null, { root: true })
					}
				} catch (error) {
					console.log('Error occurred when attempting to join that story', error)
				}
			}
		},
		async verifyGameId({ state, dispatch, commit }: { state: StoryStoreState, dispatch: any, commit: any }) {
			const storyId = state.gameIdInput
			try {
				const story = await getStory(storyId)
				if (story.id !== undefined) {
					dispatch('setSelectedStory', story)
					dispatch('fetchChapterRuns', state.selectedChapter)
					dispatch('ui/changeActiveView', Views.CHARACTER_SELECT, { root: true })
					commit('resetGameIdState')
					commit('updateGameIdSearchPrompt')
					commit('ui/updateDarkenOverlay', false, { root: true })
				} else {
					commit('updateGameIdError', true)
				}
			} catch (error) {
				commit('updateGameIdError', true)
				console.log('Error occurred when attempting to search and join that story', error)
			}

		},
		async createStory({ commit, dispatch, state }: { commit: any; dispatch: any; state: StoryStoreState }) {
			const body = {
				isPublic: state.newStory.gameType === 'public',
				isSolo: state.newStory.gameType === 'solo',
				votingMethod: state.newStory.votingMethod,
				voteLength: state.newStory.voteLength,
				mapSelection: state.newStory.mapSelection,
				difficultySelection: state.newStory.difficultySelection
			}

			try {
				const apiResult = await postStory(body)

				if (apiResult) {
					dispatch('setSelectedStory', apiResult)
					dispatch('ui/changeActiveView', Views.CHARACTER_SELECT, { root: true })
					commit('ftue/completeFlag', TutorialFlagsEnum.MadeFirstStory, { root: true })
				}
			} catch (error) {
				clientLogger.error('Error occurred when attempting to create a story', error)
			}
		},
		async archiveStory({ state, commit, dispatch }: { state: StoryStoreState; commit: any; dispatch: any }, payload: any) {
			const { storyId } = payload
			try {
				await postArchiveStory(storyId)
				await dispatch('story/fetchMyStories', null, { root: true })
			} catch (error) {
				clientLogger.error('Error occurred when attempting to archive the users story', error)
			}
		},

		async searchForStories({ state, commit, dispatch }: { state: StoryStoreState; commit: any; dispatch: any }, payload: any) {
			commit('updateFetchingStories', true)
			const body: SearchOptions = clone(state.searchParams)

			for (const key in body) {
				if (body[key] === null) {
					delete body[key]
				}
			}

			if (body.hasPlotTwists.length === 0) {
				delete body['hasPlotTwists']
			}

			try {
				const searchResults = await postSearchStories(body)
				commit('updateSearchResults', searchResults)
				commit('updateFetchingStories', false)
			} catch (error) {
				clientLogger.error('Error occurred when attempting to search for stories', error)
			}
		},

		async fetchAndSearchStories({ state, commit, dispatch, rootGetters }) {
			commit('updateFetchingStories', true)
			const body: SearchOptions = clone(state.searchParams)

			for (const key in body) {
				if (body[key] === null) {
					delete body[key]
				}
			}

			if (body.hasPlotTwists.length === 0) {
				delete body['hasPlotTwists']
			}

			commit('updateFetchingStories', true)

			try {
				const storyPromise = getMyStories(state.showArchivedStories)
				// const searchPromise = postSearchStories(body)

				const result = await Promise.all([storyPromise])
				const stories = result[0]
				// const searchResults = result[1]

				commit('updateStories', stories)
				if (!state.showArchivedStories) {
					commit('updateActiveStoryCount', stories.length)
				}
				commit('updateFetchingStories', false)

				// commit('updateSearchResults', searchResults)
			} catch (error) {
				clientLogger.error('Error occurred when attempting to search and fetch for stories', error)
			} finally {
				commit('updateFetchingStories', false)
			}

			const getFlag = rootGetters['ftue/getFlag']
			if (!getFlag(TutorialFlagsEnum.MadeFirstStory)) {
				// vue is cursed, this is actually 2 nextTick()s; the action finishing results in 1 tick, then the commit gives another.
				// we need the commit to finish so the 'create story' button appears before we try to find the element
				nextTick(() => {
					startCreateStoryWalkthrough()
				})
			} else if (!getFlag(TutorialFlagsEnum.Stories) && getFlag(TutorialFlagsEnum.PlayedStory)) {
				nextTick(() => {
					startStoriesWalkthrough()
				})
				commit('ftue/completeFlag', TutorialFlagsEnum.Stories, { root: true })
			}
		},

		async changeStoryScreen({ commit, dispatch, state }: { commit: any; dispatch: any; state: StoryStoreState }, screen: any) {
			commit('updateStoryScreen', screen)

			if (screen != StoryScreens.SEARCH_STORY) {
				await dispatch('story/fetchMyStories', null, { root: true })
			} else {
				await dispatch('story/searchForStories', null, { root: true })
			}
		},

		setSelectedStory({ commit, state, dispatch }, story) {
			hydrateTwistsOnStory(story)
			state.selectedStory = story
			state.selectedStoryId = story.id
			state.selectedChapter = story.chapter
			state.selectedTwists = story.formattedTwists
			state.mapSelected = story.mapSelection
			state.storyDifficulty = story.difficulty

			dispatch('activityFeed/startLongPollGetNewActivity', undefined, { root: true })
		}
	},
}

export type StoryStoreState = typeof initialState
export type StoryStore = typeof store

export const storyStore = () => {
	return store
}
