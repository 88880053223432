<template>
	<div id="tooltip" class="">
		<div id="evolution-upgrade-tooltip" v-if="currentHoveredItem" class="tooltip" :class="{flip: currentHorizontalProp === 'right' }" :style="{ [currentVerticalProp]: `${currentVerticalValue}px`, [currentHorizontalProp]: `${currentHorizontalValue}px` }">
			<div :lang="this.$i18n.locale" class="header"> {{ tooltipHeader }}</div>
			<div :lang="this.$i18n.locale" class="body">{{ tooltipDescription }}</div>
			<div v-if="tooltipComponent === 'twist' && showAdditionalDetails">
				<div v-if="!this.indexedTwists[this.currentHoveredItem.id].userInfo" class="twist-votes" :lang="this.$i18n.locale">{{$t('tooltip.twist_no_votes')}}</div>
				<div v-else class="twist-votes" :lang="this.$i18n.locale">{{$t('tooltip.twist_player_vote')}}</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: '',
	components: {},
	data() {
		return {}
	},
	props: {},
	computed: {
		...mapGetters('tooltip', ['currentHoveredItem', 'currentHorizontalValue', 'currentVerticalValue', 'currentHorizontalProp', 'currentVerticalProp', 'currentQuadrantString', 'tooltipHeader', 'tooltipDescription']),
		...mapState('tooltip', ['tooltipComponent', 'showAdditionalDetails']),
		...mapState('endChapter', ['indexedTwists'])
	},
	methods: {
		...mapMutations('tooltip', ['updateTooltipPosition']),

		moveTooltip(event) {
			if (this.currentHoveredItem === null) return
			this.updateTooltipPosition(event)
		},
	},
	mounted() {
		window.addEventListener('mousemove', this.moveTooltip)
	},
	unmounted() {
		window.removeEventListener('mousemove', this.moveTooltip)
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

#tooltip {
	.tooltip {
		position: fixed;
		pointer-events: none;
		z-index: 9999999999;
		border-image: url('~/assets/ui/tooltip/tool-tip-container.png');
		border-image-slice: 42 42 42 42 fill;
		border-image-width: 42px 42px 42px 42px;
		border-style: solid;
		min-height: 50px;
		min-width: 100px;
		max-width: 400px;
		padding: 15px 24px;

		.header {
			color: @green;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-variant: small-caps;
				font-size: 38px;
			}

			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
			}
		}
		.body {
			color: @cream;
			&[lang='en'] {
				.regularFont(@enSecondaryFontPT);
				font-size: 18px;
			}
			&[lang='zh'] {
				.regularFont(@zhHansHeaderFont);
				font-size: 24px;
			}
		}
		.twist-votes{
			margin-top: 10px;
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-variant: small-caps;
				font-size: 26px;
				color: @cream;
			}
		}
	}
}
</style>
