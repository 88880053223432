<template>
	<!-- selected drop shadow green -->
	<div class="square-item" @click="$emit('onClickFn', item)" @mouseover="onMouseEnter" @mouseleave="onMouseLeave" :class="{ selected: selected, disabled: disabled, faded: faded, goldHighlight: goldHighlight }">
		<div v-if="!playerIcon" class="background"></div>
		<div :class="{ icon: !twistUnlock && !playerIcon, twistIcon: twistUnlock && !playerIcon, playerIcon: playerIcon, 'map-icon': mapIcon}" :style="iconStyleFromItem()"></div>
		<div class="icon-overlay" :style="iconOverlayFromItem()"></div>
		<div v-if="!playerIcon || playerIcon && selected" :class="{ goldHighlight: goldHighlight, border: !goldHighlight }"></div>
		<div v-if="!playerIcon || playerIcon && selected" :class="{ 'gold-trim': goldtrimEnabled }"></div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { PlayerIconColor } from '../../../settings/player-icon-tags'
import { getIconColorHex } from '../../store/settings-store'
import { SpriteSheets, getIconFromSpriteSheet } from '../../store/ui-store'

export default {
	name: 'SquareItem',
	components: {},
	data() {
		return {}
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
		selected: {
			type: Boolean,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: true,
		},
		faded: {
			type: Boolean,
			required: false,
			default: false,
		},
		goldHighlight: {
			type: Boolean,
			required: false,
			default: false,
		},
		goldtrimEnabled: {
			type: Boolean,
			required: false,
			default: true,
		},
		selectedColour: {
			type: String,
			required: false,
			default: '#9afe8b',
		},
		twistUnlock: {
			type: String,
			required: false,
			default: '',
		},
		playerIcon: {
			type: String,
			required: false,
			default: '',
		},
		iconColor: {
			type: String,
			required: false,
			default: PlayerIconColor.Orange
		},
		mapIcon: {
			type: String,
			required: false,
			default: '',
		}
	},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		...mapMutations('tooltip', ['showTooltip', 'currentComponentHovered', 'updateTooltipPosition']),
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		iconStyleFromItem() {
			if (this.item.twistUnlock) {
				return getIconFromSpriteSheet(SpriteSheets.UPGRADE_ICONS, this.item.icon)
			} else if (this.playerIcon){
				return getIconFromSpriteSheet(SpriteSheets.PLAYER_ICONS, this.item.icon)
			}
			return getIconFromSpriteSheet(SpriteSheets.PERKS_CHARACTER_ICONS, this.item.icon)
		},
		iconOverlayFromItem() {
			if (this.item.twistUnlock) {
				return getIconFromSpriteSheet(SpriteSheets.UPGRADE_ICONS, this.item.icon)
			} else if (this.playerIcon){
				let styleblock = getIconFromSpriteSheet(SpriteSheets.PLAYER_ICONS, this.item.icon)
				styleblock["background-color"] = this.getHexFromColor()
				styleblock["mask-position"] = `${styleblock["background-position"]}`;
				return styleblock
			}
			return getIconFromSpriteSheet(SpriteSheets.PERKS_CHARACTER_ICONS, this.item.icon)
		},
		getHexFromColor() {
			return getIconColorHex(this.iconColor)
		},
		onMouseEnter(event) {
			if (this.item.hideTooltip) {
				return
			}

			if (this.item.desc || this.item.description || this.item.name) {
				this.showTooltip({ item: this.item })
				this.updateTooltipPosition(event)
			}
		},
		onMouseLeave(event) {
			if (this.item.hideTooltip) {
				return
			}

			if (this.item.desc || this.item.description || this.item.name) {
				this.updateTooltipPosition(event)
				this.showTooltip({ item: null, component: null })
			}
		},
	},
}
</script>

<style lang="less" scoped>
.square-item {
	width: 145px;
	height: 140px;
	position: relative;
	margin: 0px 20px;
	&.selected {
		filter: drop-shadow(0px 0px 5px v-bind(selectedColour));
		transform: scale(1.05);
	}
	&.disabled {
		filter: grayscale();
	}
	&.faded {
		opacity: 0.4;
	}
	.background {
		background-image: url('~/assets/ui/item/framed-portrait-background.png');
		width: 142px;
		height: 138px;
		position: absolute;
		background-repeat: no-repeat;
	}
	.icon {
		position: absolute;
		width: 142px;
		height: 138px;
		background-repeat: no-repeat;
		background-image: url('~/assets/ui/item/perks-skills-characters-weapons-icons.png');
	}
	.twistIcon {
		position: absolute;
		width: 142px;
		height: 138px;
		transform: scale(1.6);
		transform-origin: center;
		margin: auto;
		inset: 0;
		background-repeat: no-repeat;
		background-image: url('~/assets/ui/item/in-game-icons.png');
	}
	.playerIcon {
		position: absolute;
		width: 142px;
		height: 138px;
		transform: scale(1.6);
		transform-origin: center;
		margin: auto;
		inset: 0;
		background-repeat: no-repeat;
		background-image: url('~/assets/ui/player-icons/player-icons-gray.png');
	}
	.icon-overlay{
		position: absolute;
		width: 142px;
		height: 138px;
		transform: scale(1.6);
		transform-origin: center;
		margin: auto;
		inset: 0;
		background-repeat: no-repeat;
		mix-blend-mode: overlay;
		-webkit-mask-image: url('~/assets/ui/player-icons/player-icons-gray.png');
		mask-image: url('~/assets/ui/player-icons/player-icons-gray.png');
	}
	.map-icon{
		position: absolute;
		width: 142px;
		height: 138px;
		background-repeat: no-repeat;
		background-image: url('~/assets/ui/item/perks-skills-characters-weapons-icons.png');
	}
	.border {
		background-image: url('~/assets/ui/item/framed-portrait-border.png');
		width: 145px;
		height: 140px;
		position: absolute;
		background-repeat: no-repeat;
	}
	.goldHighlight {
		background-image: url('~/assets/ui/item/framed-portrait-border-gold.png');
		width: 145px;
		height: 140px;
		position: absolute;
		background-repeat: no-repeat;
	}

}
</style>
