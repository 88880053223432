import { Buff } from "../../buffs/buff"
import { BuffIdentifier } from "../../buffs/buff.shared"
import { getIgniteStacks } from "../../buffs/generic-buff-definitions"
import { ColliderComponent } from "../../engine/collision/collider-component"
import { Effect } from "../../engine/graphics/pfx/effect"
import ProjectileEffectManager from "../../engine/graphics/projectile-effect-manager"
import { Renderer } from "../../engine/graphics/renderer"
import { SpritesheetAnimatorComponent } from "../../engine/graphics/spritesheet-animator-component"
import { getDamageByPlayerLevel } from "../../game-data/player-formulas"
import { defaultStatAttribute } from "../../game-data/stat-formulas"
import EntityStatList from "../../stats/entity-stat-list"
import { callbacks_addCallback } from "../../utils/callback-system"
import { timeInSeconds, timeInMilliseconds } from "../../utils/primitive-types"
import { ObjectPool } from "../../utils/third-party/object-pool"
import { AssetManager } from "../../web/asset-manager"
import { Enemy } from "../enemies/enemy"
import { DamagingGroundHazard, DamagingGroundHazardParams } from "./damaging-ground-hazard"
import { HAZARD_ELIPSE_COLLIDER_CONFIG } from "./ellipse-hazard-params"

const STEP_DISTANCE = 130
const LAYER_SPACING = 105
const FIRE_EFFECT_NAME = 'field-of-fire-pfx'

const EFFECT_Z_OFFSET = -140

const RETURN_TO_POOL_DELAY: timeInSeconds = 1.25

export interface BigIgnitePoolHazardParams extends DamagingGroundHazardParams {
	applyStacks: number
}

export class BigIgnitePoolHazard extends DamagingGroundHazard {
	
	static pool: ObjectPool

	applyingStacks: number
	jankStatList: EntityStatList

	private usedEffects: Effect[] = []

	private boundOnReturnTimeoutDone: () => void

	constructor() {
		super()
		this.jankStatList = new EntityStatList(this._resetStats)

		this.boundOnReturnTimeoutDone = this.onReturnToPoolTimeoutDone.bind(this)
	}

	override setDefaultValues(defaultValues: any, overrideValues?: BigIgnitePoolHazardParams): void {
		this.statList = this.jankStatList
		
		super.setDefaultValues(defaultValues, overrideValues)

		if(overrideValues) {
			this.applyingStacks = overrideValues.applyStacks

			this.addGraphicsToScene(overrideValues.position.x, overrideValues.position.y, overrideValues.triggerRadius)
		}
	}

	onEntityEnterTrigger(entity: ColliderComponent): void {
	}

	onEntityLeaveTrigger(entity: ColliderComponent): void {
	}

	update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		super.update(delta, now)
	}

	private addGraphicsToScene(x: number, y: number, radius: number) {
		const layers = Math.floor(radius / LAYER_SPACING)
		const distanceBetweenLayers = radius / layers
		
		const renderer = Renderer.getInstance().mgRenderer

		for(let layer = 0; layer <= layers; layer++) {
			const layerRadius = radius - (distanceBetweenLayers * layer)

			const circumfrance = 2 * Math.PI * layerRadius
			const numberOfSprites = circumfrance / STEP_DISTANCE

			const stepSize = (Math.PI * 2) / numberOfSprites

			const start = Math.random() * (2 * Math.PI)
			const end = start + (2 * Math.PI)

			for(let a = start; a < end; a += stepSize) {
				const effect = ProjectileEffectManager.allocEffect(FIRE_EFFECT_NAME)

				const circleX = Math.sin(a)
				const circleY = Math.cos(a)
				effect.x = circleX * layerRadius + x
				effect.y = circleY * layerRadius + y

				effect.zIndex = effect.y + EFFECT_Z_OFFSET

				renderer.addEffectToScene(effect)

				this.usedEffects.push(effect)
			}
		}
	}

	private removeGraphicsFromScene() {
		const renderer = Renderer.getInstance().mgRenderer

		for (let i =0; i < this.usedEffects.length; ++i) {
			renderer.removeFromScene(this.usedEffects[i])	
			ProjectileEffectManager.freeEffect(this.usedEffects[i])
		}

		this.usedEffects.length = 0
	}

	returnToPool() {
		this.damaging = false
		for (let i =0; i < this.usedEffects.length; ++i) {
			this.usedEffects[i].enabled = false
		}
		callbacks_addCallback(this, this.boundOnReturnTimeoutDone, RETURN_TO_POOL_DELAY)
	}

	private onReturnToPoolTimeoutDone() {
		this.removeGraphicsFromScene()
		BigIgnitePoolHazard.pool.free(this)
	}

	isPlayerOwned(): boolean {
		return true
	}

	onHitEnemy(enemy: Enemy) {
		// ignite takes the highest rolling stacks, so just apply at full strength and let the tickFn sort it out
		Buff.apply(BuffIdentifier.Ignite, this, enemy, this.applyingStacks)
	}

	private _resetStats(statList: EntityStatList) {
		defaultStatAttribute(statList)

		statList._actualStatValues.baseDamage = 0
		statList._actualStatValues.attackRate = 3
	}

	override setOffset(offset: SAT.Vector): void {
		super.setOffset(offset)
		// won't get called I think
	}
}