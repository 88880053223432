import { Buff } from './buff'
import { Player } from '../entities/player'
import { Enemy } from '../entities/enemies/enemy'
import { timeInMilliseconds, timeInSeconds } from '../utils/primitive-types'
import { BuffIdentifier } from './buff.shared'

export interface IBuffableEntity {
	applyVisualBuff(buffId: BuffIdentifier, stacks?: number)
	removeVisualBuff(buffId: BuffIdentifier, stacks?: number)
}

export type BuffableEntity = Player | Enemy

export class BuffSystem {
	static buffs: Buff[] = []

	static update(delta: timeInSeconds, now: timeInMilliseconds) {
		BuffSystem.buffs.forEach((buff) => {
			buff.update(delta, now)
		})
	}

	static removeAll() {
		const buffs = [...BuffSystem.buffs]
		buffs.forEach((buff) => {
			buff.wearOff()
		})
	}
}

export type ApplyBuffOnHitState = {
	buffId: BuffIdentifier
	owner: BuffableEntity
	stacks?: number
	duration?: number
}

export type PlayerBinaryFlags = 'on-pickup-gain-banditry'
	| 'big-pickup-on-skill-use'
	| 'damage-bottom-upgrade'
	| 'ignite-every-3rd-attack'
	| 'attack-rate-stacking-attack-rate'
	| 'ranger-trap-toss'
	| 'ranger-trap-bleed'
	| 'rangers-mark-spreads-on-death'
	| 'chain-same-target'
	| 'chain-damage-per-chain-remaining'
	| 'bow-pin-down'
	| 'bow-pin-down-increased-duration'
	| 'boiling-blood'
	| 'tesla-coil-arc-on-chain'
	| 'tesla-coil-ignite'
	| 'rat-parade-fat-rat'
	| 'rat-parade-shoot-behind'
	| 'rat-parade-shoot-all-directions'
	| 'rat-parade-bombs'
	| 'mutator-narrow-focus'
	| '1-additional-upgrade-choice'
	| 'gain-damage-based-on-paper-scraps-40'
	| 'wand-biggify-every-3rd-shot'
	| '3-round-burst'
	| 'apply-bleed-to-pierced-enemies'
	| 'attack-rate-after-reload'
	| 'controlled-demolition'
	| 'nikola-scope-op'
	| 'nikola-scope-light-show'
	| 'bonus-point-blank-damage'
	| 'constant-movement-yoink'
	| 'yoink-fast-ghosted'
	| 'killstreak-damage'
	| 'killstreak-pickup-movement'
	| 'killstreak-xp'
	| 'killstreak-fast-attacks'
	| 'killstreak-fire-random-weapon'
	| 'killstreak-fire-all-weapons'
	| 'killstreak-explosion'
	| 'recover-ammo-when-primary-projectiles-are-deleted'
	| 'pierce-damage-per-pierce-remaining'
	| 'gatling-gun'
	| 'barbarian-striker'
	| 'barbarian-practical-skills'
	| 'barbarian-intimidating-aura'
	| 'barbarian-wider-authority'
	| 'barbarian-tough-skin'
	| 'barbarian-fast-rolls'
	| 'barbarian-fearsome-roar'
	| 'barbarian-aggessive-posturing'
	| 'barbarian-destructive-rollout'
	| 'ignited-enemies-gain-movement-speed'
	| 'poisoned-enemies-leave-poison-pool'
	| 'xp-awarded-every-60-seconds'
	| 'player-takes-knockback'
	| 'accelerate-projectiles'
	| 'cone-dog-chomp-movement'
	| 'cone-dog-thorn-stacks'
	| 'cone-dog-double-damage-stacks'
	| 'cone-dog-double-size-stacks'
	| 'cone-dog-more-ammo-stacks'
	| 'cone-dog-chomp-thorn-kill'
	| 'cone-dog-cooldown-reduction'
	| 'cone-dog-missing-health-thorn-cooldowns'
	| 'bees-chance-for-triple'
	| 'ramp-primary-damage'
	| 'auto-split'
	| 'noxious-leak'
	| 'starburst'
	| 'careful-shooter'
	| 'flame-fast'
	| 'lightning-strikes-twice'
	| 'high-precision-torch'
	| 'blood-soaked-rounds'
	| 'markswitch-final-form'
	| 'chakram-autofire'
	| 'boomerang-chaining'
	| 'boomerang-lightning-strike-on-hit'
	| 'ignite-explodes'
	| 'ignite-spreads-on-death'
	| 'poison-spreads-on-death-50'
	| 'doom-self-explosion-damage'
	| 'doom-attack-size-40'
	| 'elemental-consistency'
	| 'catch-em-all'
	| 'boomerang-shoot-all-as-spread'
	| 'boomerang-recall-with-click'
	| 'longbow-stun'
	| 'longbow-walk-it-off'
	| 'longbow-taking-names'
	| 'longbow-insta-kill-crits'
	| 'longbow-final-form'
	| 'primary-weapon-emits-shrapnel'
	| 'primary-weapon-emits-snare-nets'
	| 'spear-holy-spear'
	| 'spear-righteous-fervor'
	| 'spear-holy-light'
	| 'spear-divine-punishment'
	| 'spear-eye-of-mercy'
	| 'spear-saints-spear'
	| 'rat-parade-stun'
	| 'rat-parade-bigger-explosions'
	| 'killer-wasps'
	| 'lightning-strikes-on-skill-use'
	| 'lightning-rod'
	| 'lightning-rod-faster'
	| 'auto-magnet-pet-ransom-notes'
	| 'pickup-range-nullified-during-pet-events'
	| 'chained-together'
	| 'butterfingers'
	| 'fountains-of-mana-flag'
	| 'summers-rage'
	| 'speed-of-light'
	| 'flash-of-brilliance'
	| 'resilient-fire'
	| 'starry-malevolence'
	| 'quietly-into-the-night'
	| 'balm-of-the-night'
	| 'wisdom-of-the-goddess'
	| 'solar-supremacy'
	| 'lunar-lady'
	| 'eminence-of-equinox'
	| 'pet-double-attack'
	| 'speed-drafting'
	| 'head-start'
	| 'intelligence-dump'
	| 'random-ricochet'
	| 'berserker'
	| 'enraged'
	| 'scrapyard'
	| 'double-ignite-duration'
	| 'ignite-pool'
	| 'twist-killstreak-better-go-fast'
	| 'twist-spectral-hoe'
	| 'enemies-permanently-chilled-on-touch'
	| 'demigod-of-thunder'
	| 'mountain-climber'
	| 'take-extra-enemy-damage'
	| 'take-extra-boss-damage'
	| 'masters-degree'
	| 'skillful-moves'
	| 'legends-never-die'
	| 'harsh-rejection'
	| 'prestigious-treasure-trove'
	| 'cannon-attack-rate-on-reload'
	| 'killing-for-flowers'
	| 'hearts-explosion'
	| 'lootstreak'
	| 'cannon-shock'
	| 'cannon-poison-explosion'

export const BinaryFlagDefaultState = {
	"attack-rate-ignite-attacks": {
		attackCounter: 0,
	},
	"flame-fast": {
		attackCounter: 0,
	},
	"wand-biggify-every-3rd-shot": {
		attackCounter: 0,
	},
	"crit-after-active-reload-5": {
		critsRemaining: 0,
	},
	'chain-damage-per-chain-remaining': {
		damageBonus: 0.1
	},
	'gatling-gun': {
		shotCount: 0,
		attackRateMulti: -1,
		notFiringAcc: 0,
	},
	'constant-movement-yoink': {
		// track how long the player has moved to invoke the boost
		timeSpentMoving: 0,
		// give them a little safeguard if they take their hand off the controls momentarily (like switching directions)
		timeSafeguard: 0.5,
	},
	'yoink-fast-ghosted': {
		duration: 2000,
		cooldown: 0,
		xpCollectedRecently: [],
	},
	'killstreak-damage': {
		bonus: null,
	},
	'killstreak-pickup-movement': {
		bonus1: null,
		bonus2: null,
	},
	'killstreak-xp': {
		bonus: null,
	},
	'killstreak-fast-attacks': {
		bonus1: null,
		bonus2: null,
		bonus3: null,
		bonus4: null,
	},
	'auto-split': {
		autoSplitDistance: 0,
	},
	'noxious-leak': {
		poolRef: null,
	},
	'xp-awarded-every-60-seconds': {
		xpBanked: 0,
		lastMinute: 0,
	},
	'lightning-strikes-twice': {
		attackCounter: 0
	},
	'pickup-range-nullified-during-pet-events': {
		eventStarted: false,
	},
	'chained-together': {
		knockback: 750
	},
	'resilient-fire': {
		hitsTaken: 0
	},
	'balm-of-the-night': {
		timeSinceTransfrom: 0,
		heartDropped: false
	},
	'speed-drafting': {
		timeInBoost: 0,
		speedMult: null
	},
	'berserker':{
		bonus1: null,
		bonus2: null,
		bonus3: null,
	},
	'demigod-of-thunder': {
		cooldown: 15,
		numLightningStrikes: 12,
		acc: 5,
	},
	'take-extra-boss-damage': {
		extraDamage: 0
	},
	'take-extra-enemy-damage': {
		extraDamage: 0
	},
	'killing-for-flowers': {
		enemiesKilled: 0,
		eventPosition: {
			x: 0,
			y: 0
		},
		onKilledFn: (enemy: Enemy) => {}
	},
	'cannon-poision-explosion': {
		lastExplosionTime: 0
	}
}

export const YOINK_FAST_GHOSTFORM_XP_NEEDED = 15
export const YOINK_FAST_GHOSTFORM_COOLDOWN: timeInSeconds = 60.0
export const CONSTANT_MOVEMENT_YOINK_DURATION: timeInSeconds = 1.0
export const KILLSTREAK_DAMAGE_MULTI = 1.2
export const KILLSTREAK_MOVESPEED_MULTI = 0.50
export const KILLSTREAK_PICKUP_RANGE_MULTI = 1.6
export const KILLSTREAK_XP_DROP_MULTI = 0.4
export const KILLSTREAK_ATTACK_RATE_MULTI = 0.4
export const KILLSTREAK_COOLDOWN_RATE_MULTI = 0.3333
export const KILLSTREAK_RELOAD_RATE_MULTI = 0.3333
export const NOXIOUS_LEAK_DURATION: timeInSeconds = 4
export const LIGHTNING_STRIKES_TWICE_DAMAGE_MULT = 1.5
export const LIGHTNING_STRIKES_TWICE_PRIMARY_WEAPON_DAMAGE_SPLASH_RADIUS = 175
export const LIGHTNING_STRIKES_TWICE_ANY_WEAPON_DAMAGE_SPLASH_RADIUS = 140
export const BOOMERANG_LIGHTNING_STRIKE_DAMAGE_MULT = 1
export const BOOMERANG_LIGHTNING_STRIKE_SPLASH_RADIUS = 125
export const BLOOD_SOAKED_ROUNDS_AMMO_CHANCE = 0.2
export const BLOOD_SOAKED_ROUNDS_AMMO_CHANCE_MAX = 1
export const CAREFUL_SHOOTER_STACKS_REQUIRED_FOR_BLOOD_SOAKED_ROUNDS_BONUS = 13

export const DEFAULT_STUN_DURATION = 1_000
export const LONGBOW_STUN_DURATION = 1_500
export const RAT_PARADE_STUN_DURATION = 750
export const BOSS_STUN_TIMESCALE = 0.25
export const CANNON_CHILLED_SHOCK_DURATION: timeInMilliseconds = 2000

export const MAX_BOOST_TIME = 2.4
export const MAX_BOOST_SPEED = 0.33
export const MIN_BOOST_SPEED = -0.35
export const ENEMY_BOOST_RADIUS_BONUS = 200

export const BOOST_SPEED_PER_TICK = 0.01


export const PIERCE_DAMAGE_PER_PIERCE_REMAINING_MULTI = 0.3

type FlagToStateMap = typeof BinaryFlagDefaultState

export type PartialFlagToStateMap = Partial<typeof BinaryFlagDefaultState>