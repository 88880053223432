import { camelCase, keyBy, startCase } from 'lodash'
import { MetaUpgradeDefinitions } from '../../upgrades/meta/meta-perk-definitions'
import { MetaUnlockTag, UnlockDefinitions } from '../../upgrades/meta/meta-unlocks-definitions'
import { MetaUnlocksManager } from '../../upgrades/meta/meta-unlocks-manager'
import { getPerks, postBuyPerk, postBuyUnlock, postRefundPerk, postRefundPerks } from '../../utils/api/griddle-requests'
import { uuid } from '../../utils/primitive-types'
import { GoogleAnalyticsHandler } from '../../analytics/google-analytics'
import { UI } from '../ui'

export enum StoreTab {
	Perks = 'perks',
	CharactersAndWeapons = 'charactersAndWeapons',
	Twists = 'twists',
	Special = 'special'
}

const allPerksOrderedArray = [
	// 1st currency
	'gameSpeed',
	'allDamage',
	'attackSize',
	'regeneration',
	'pickupRange',
	'knockback',
	'ailmentPotency',
	'reloadSpeed',
	'cooldownSpeed',
	'characterSkillCooldown',

	// 2nd currency
	'extraHearts',
	'attackRate',
	'moveSpeed',
	'pierce',
	'xpBoost',
	'mainWeaponVicious',
	'mainWeaponAgility',

	// 3rd currency
	'mainWeaponCrush',
	'projectileCount',
	'splitting',
	'chaining',
	'randomPet',
]

interface Perk {
	id: string
	name: string
	description: string
	icon: string
	maxRanks: number
	rankCosts: number[]
	currency: "paper_scraps" | "lost_scrolls" | "magic_tomes" //TODO: validate against common-names
	refundable: boolean
	unlockCondition: string //TODO: validate against common-names
	ranks: number
	playerId?: uuid
	cost?: number
	unlockTag?: MetaUnlockTag
	isUnlock?: boolean
}

const WISHLIST_PERK = {
	id: 'wishlist-cta',
	name: '???',
	description: 'More content is available in the full version! Buy it now on Steam!',
	icon: 'locked-random',
	maxRanks: 0,
	rankCosts: [],
	currency: "paper_scraps" as any, // this language man
	refundable: false,
	unlockCondition: '',
	ranks: 0,
	isWishlistCTA: true
}

const initialState: {
	allPerks: Perk[],
	perkMap: any,
	selectedPerk: Perk,
	disableTransactionButtons: boolean,
	unlockPurchaseOptions: any,
	twistUnlocks: any,
	selectedStoreTab: StoreTab,
	showPerks: boolean
} = {
	allPerks: [],
	twistUnlocks: [],
	perkMap: {},
	selectedPerk: null,
	disableTransactionButtons: false,
	unlockPurchaseOptions: {},
	selectedStoreTab: StoreTab.Perks,
	showPerks: false,
}

export function getPerkCost(perk: Perk) {
	if (perk.rankCosts) {
		return perk.rankCosts[perk.ranks]
	} else {
		return perk.cost
	}
}
export function canBuyPerk(currencies, perk: Perk) {
	const currency = currencies[camelCase(perk.currency)]
	if (perk.maxRanks) {
		return currency >= getPerkCost(perk) && perk.ranks < perk.maxRanks
	}
	const owned = MetaUnlocksManager.getInstance().isUnlocked(perk.unlockTag)

	return currency >= getPerkCost(perk) && !owned
}
export function canRefundPerk(perk: Perk) {
	return perk.refundable && perk.ranks > 0
}

const store = {
	namespaced: true,
	modules: {},
	state: initialState,
	getters: {
		allPerks(state: MetaProgressionState) {
			return state.allPerks
		},
		purchasedPerks(state: MetaProgressionState) {
			return state.allPerks.filter((perk) => {
				return perk.ranks > 0
			})
		},
		refundablePerks(state: MetaProgressionState) {
			return state.allPerks.filter((perk) => {
				return perk.ranks > 0 && perk.refundable
			})
		},
		selectedPerkCost(state: MetaProgressionState) {
			if (state.selectedPerk.rankCosts) {
				if (state.selectedPerk.ranks === state.selectedPerk.maxRanks) {
					return 0
				}
				return state.selectedPerk.rankCosts[state.selectedPerk.ranks]
			} else {
				return state.selectedPerk.cost
			}
		},
		unlockConditionMet: (state: MetaProgressionState) => (perk) => {
			if (perk && perk.unlockCondition) {
				const unlocksManager = MetaUnlocksManager.getInstance()
				const isUnlocked = unlocksManager.isUnlocked(perk.unlockCondition)
				console.log(isUnlocked)
			}
			if (perk && perk.unlockCondition && !MetaUnlocksManager.getInstance().isUnlocked(perk.unlockCondition)) {
				return false
			}
			return true
		},
		unlockPurchaseState: (state: MetaProgressionState) => (perk) => {
			if (perk.isWishlistCTA) {
				return ''
			}

			const isUnlocked = MetaUnlocksManager.getInstance().isUnlocked(perk.unlockTag)
			if (perk.mission) {
				if (isUnlocked) {
					return 'meta_progression_store.unlocked'
				} else {
					return 'meta_progression_store.unlockable'
				}
			} else {
				if (isUnlocked) {
					return 'meta_progression_store.unlocked'
				} else {
					return 'meta_progression_store.purchasable'
				}
			}
		},
		getUnlockState: (state: MetaProgressionState) => (perk) => {
			const isUnlocked = MetaUnlocksManager.getInstance().isUnlocked(perk.unlockTag)
			if (perk.mission) {
				if (isUnlocked) {
					return 'unlocked'
				} else {
					return 'unlockable'
				}
			} else {
				if (isUnlocked) {
					return 'unlocked'
				} else {
					return 'purchasable'
				}
			}
		}
	},
	mutations: {
		updateExample(state: MetaProgressionState, param) {
		},
		selectPerk(state: MetaProgressionState, perk: Perk) {
			console.log(perk)
			state.selectedPerk = perk
		},
		setPurchasableUnlocks(state: MetaProgressionState, unlocks) {
			state.twistUnlocks = []
			state.unlockPurchaseOptions = []

			unlocks.forEach((unlock) => {
				const def = UnlockDefinitions[unlock.unlockTag]
				if (!def) {
					console.error(`Could not find UnlockDefinition for ${unlock.unlockTag}, NOT registering unlock.`)
					return
				}
				unlock.name = def.name
				unlock.description = def.description
				unlock.icon = def.icon
				unlock.isUnlock = true
				unlock.isSteam = def.isSteam
			})

			// Sort unlocks into the right lists
			for (const [tag, definition] of Object.entries(UnlockDefinitions)) {
				if (definition.twistUnlock) {
					let twistUnlock
					const currencyTwistUnlockIdx = unlocks.findIndex((unlock) => unlock.unlockTag === tag)
					if (currencyTwistUnlockIdx >= 0) {
						twistUnlock = unlocks[currencyTwistUnlockIdx]
						// Mixin to make sure the icon display data is added to griddle data. Need to define a better shared interface for items displayed in perk store 
						Object.assign(twistUnlock, definition)
						unlocks.splice(currencyTwistUnlockIdx, 1)
					} else {
						twistUnlock = { unlockTag: tag, ...definition }
					}
					state.twistUnlocks.push(twistUnlock)
				}
				else if (definition.mission) {
					state.unlockPurchaseOptions.push({ unlockTag: tag, ...definition })
				}
			}

			// Push whatever is left into unlockPurchaseOptions
			unlocks.forEach((unlock) => { state.unlockPurchaseOptions.push(unlock) })

			// TODO check on IS_ELECTRON but ensure this doesn't regress with the demo release
			if (!process.env.IS_ELECTRON) {
				state.twistUnlocks.unshift(WISHLIST_PERK)
				state.unlockPurchaseOptions.unshift(WISHLIST_PERK)
			}
			if (process.env.IS_WEB) {
				state.unlockPurchaseOptions = state.unlockPurchaseOptions.filter((unlock) => !unlock.isSteam)
				state.twistUnlocks = state.twistUnlocks.filter((unlock) => !unlock.isSteam)
			}
		},
		setStoreTab(state: MetaProgressionState, tab: StoreTab) {
			state.selectedStoreTab = tab
			GoogleAnalyticsHandler.getInstance().trackPerkTabs(tab, UI.getInstance().store.state.story.selectedStoryId)
		},
		showOrHidePerks(state: MetaProgressionState, visible: boolean) {
			state.showPerks = visible
			if (visible) {
				GoogleAnalyticsHandler.getInstance().trackPerkTabs(state.selectedStoreTab, UI.getInstance().store.state.story.selectedStoryId)
			}
		}
	},
	actions: {
		async fetchPerks({ state, commit, rootState }: { state: MetaProgressionState; commit: any; rootState: any }) {
			console.group('fetchPerks()')
			const perks = await getPerks()
			console.log({ perks })
			state.allPerks = perks
			state.allPerks.forEach((perk) => {
				perk.name = MetaUpgradeDefinitions[perk.id].name
				perk.description = MetaUpgradeDefinitions[perk.id].description
				perk.unlockCondition = MetaUpgradeDefinitions[perk.id].unlockCondition
				perk.icon = MetaUpgradeDefinitions[perk.id].icon
			})
			state.allPerks = state.allPerks.sort((a, b) => {
				const ai = allPerksOrderedArray.indexOf(a.id)
				const bi = allPerksOrderedArray.indexOf(b.id)
				if (ai > bi) {
					return 1
				} else if (ai < bi) {
					return -1
				}
				return 0
			})

			// TODO ensure that we flag this out for the demo when we bring the demo back up.
			if (!process.env.IS_ELECTRON) {
				state.allPerks.unshift(WISHLIST_PERK)
			}

			state.perkMap = keyBy(perks, 'id')
			if (!state.selectedPerk) {
				state.selectedPerk = state.allPerks[1]
			}

			console.groupEnd()
		},
		async buyPerk({ state, commit, rootState }: { state: MetaProgressionState; commit: any; rootState: any }, perk: Perk) {
			console.group('buyPerk()')
			state.disableTransactionButtons = true

			try {
				if (perk.isUnlock) {
					const { currencies, unlocks } = await postBuyUnlock(perk.unlockTag)

					MetaUnlocksManager.getInstance().setUnlocks(unlocks)
					console.log(`bought unlock ${perk.unlockTag}`)
					commit('user/updateCurrencies', currencies, { root: true })
					commit('characterSelect/forceCharacterAndWeaponRefresh', undefined, { root: true })
					const oldOptions = state.unlockPurchaseOptions
					state.unlockPurchaseOptions = [...oldOptions] // vue ┗|｀O′|┛
					GoogleAnalyticsHandler.getInstance().trackPerkPurchase(perk.unlockTag, UI.getInstance().store.state.story.selectedStoryId)
				} else {
					perk.ranks += 1 // optimistically update this
					const { perk: perkUpdated, currencies } = await postBuyPerk(perk.id, 1)
					perk.ranks = perkUpdated.ranks
					commit('user/updateCurrencies', currencies, { root: true })
					console.log(`set ${perkUpdated.perkId} to rank ${perkUpdated.ranks}`)
					GoogleAnalyticsHandler.getInstance().trackPerkPurchase(perk.id, UI.getInstance().store.state.story.selectedStoryId)
				}
			} catch (err) {
				console.error(`error when buying perk`)
				console.error(err)
			}

			console.groupEnd()

			state.disableTransactionButtons = false
		},
		async refundPerk({ state, commit, rootState }: { state: MetaProgressionState; commit: any; rootState: any }, perk: Perk) {
			state.disableTransactionButtons = true
			console.group('refundPerk()')
			perk.ranks -= 1 // optimistically update this
			const { refunds, currencies } = await postRefundPerk(perk.id, 1)
			refunds.forEach((refund) => {
				const perk = state.perkMap[refund.perkId]
				if (perk) {
					perk.ranks = refund.ranks
					console.log(`set ${refund.perkId} to rank ${refund.ranks}`)
				}

			})
			commit('user/updateCurrencies', currencies, { root: true })
			GoogleAnalyticsHandler.getInstance().trackPerkPurchase(perk.id, UI.getInstance().store.state.story.selectedStoryId)
			console.groupEnd()
			state.disableTransactionButtons = false
		},
		async refundAllPerks({ state, commit, rootState, getters }: { state: MetaProgressionState; commit: any; rootState: any, getters }) {
			state.disableTransactionButtons = true
			console.group('refundAllPerks()')
			const refundablePerks: Perk[] = getters.refundablePerks
			refundablePerks.forEach((perk) => perk.ranks = 0)
			const perksToRefund = refundablePerks.map((perk) => {
				return { perkId: perk.id, ranksToRefund: perk.ranks }
			})
			console.log({ perksToRefund })
			const { refunds, currencies } = await postRefundPerks(perksToRefund)
			refunds.forEach((refund) => {
				const perk = state.perkMap[refund.perkId]
				if (perk) {
					perk.ranks = refund.ranks
					console.log(`set ${refund.perkId} to rank ${refund.ranks}`)
				}
			})
			commit('user/updateCurrencies', currencies, { root: true })
			GoogleAnalyticsHandler.getInstance().trackPerkRefundAll(UI.getInstance().store.state.story.selectedStoryId)
			console.groupEnd()
			state.disableTransactionButtons = false
		},
	},
}

export type MetaProgressionState = typeof initialState
export type MetaProgressionStore = typeof store

export const metaProgressionStore = () => {
	return store
}
