<template>
	<div class="twist-votes">
		<tabNavigation :use-icons="true" :items="indexedTwists" :tab-text="tabVoteCount" @on-click-fn="updateSelectedTab" :selected-index="selectedTab">
			<template v-slot:tab-content>
				<Header :header-text="'Votes'" :text-alignment="'center'" :font-face="'pixl'" :font-size="'large'" :enable-drop-shadow="false"></Header>
				<div v-if="tabVoteCount[selectedTab] > 0" class="content">
					<div class="player-name-header" :lang="this.$i18n.locale">Player Name</div>
					<div class="names">
						<div v-for="user in indexedTwists[selectedTab].userInfo" :key="user" class="names-container">
							<PlayerAvatar :icon-tag="user.iconTag" :icon-color="user.iconColor" :disabled="false" :icon-size="true" :show-background="true" :show-username="false" :show-icon="true"></PlayerAvatar>
							<div class="name" :lang="this.$i18n.locale">{{ user.username }}</div>
						</div>
					</div>
				</div>
				<div v-else class="content">
					<div class="player-name-header center" :lang="this.$i18n.locale">No Votes for the current Twist</div>
				</div>
				<div class="button-container">
					<TextButton class="close-button" :button-label="$t('tab_modal.button_close')" :button-style="'bright-green'" @on-click-fn="closeModal"></TextButton>
				</div>
			</template>
		</tabNavigation>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import tabNavigation from '../ui-components/tab-navigation/tab.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import PlayerAvatar from '../ui-components/item/player-avatar.vue'
import TextButton from '../ui-components/buttons/text-button.vue'

export default {
	name: 'TwistVotes',
	components: {
		tabNavigation,
		Header,
		PlayerAvatar,
		TextButton,
	},
	data() {
		return {
			selectedTab: 0,
		}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('endChapter', ['indexedTwists']),
		tabVoteCount() {
			return this.indexedTwists.map((item) => item.count)
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('endChapter', ['updateShowVoteDetailsModal']),
		updateSelectedTab(index) {
			this.selectedTab = index
		},
		closeModal() {
			this.updateShowVoteDetailsModal(false)
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');
.twist-votes {
	:deep(.tab-navigation-panel) {
		.tab-content {
			border-width: 21px 92px 40px !important;
		}
	}
	.content {
		.player-name-header {
			&[lang='en'] {
				.regularFont(@enRegularFontPixl);
				font-size: 40px;
				font-variant: small-caps;
				color: @offWhite;
			}
			&.center {
				text-align: center;
			}
		}
		.names {
			max-height: 365px;
			overflow-y: auto;
			.names-container {
				width: 73px;
				height: 56px;
				display: flex;
				flex-direction: row;
				margin: 5px 0px;
				:deep(#player-avatar) {
					scale: 0.37;
					transform-origin: left top;
					width: 73px;
				}
				.name {
					display: flex;
					align-items: center;
					position: relative;
					top: -3px;
					&[lang='en'] {
						.regularFont(@enRegularFontPixl);
						font-size: 32px;
						color: @offWhite;
					}
				}
			}
		}
	}
			.button-container{
			display: flex;
			justify-content: center;
			margin-top: 20px;
			.text-button{
				width: 200px;
			}
		}
}
</style>
