<template>
	<div class="book-review-prompt">
		<Panel id="book-review" :on-close-btn-click="() => {}" :panel-identifier="'bookReview'" :hide-banner="true" :height="-1" :hide-close-button="true">
			<template #panel-content>
				<div class="book-review-container">
					<Header :header-text="'Story Review'" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'large'" :enable-drop-shadow="false"></Header>
					<div class="top-container">
						<Header :header-text="'How difficult was that Chapter?'" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'small'" :enable-drop-shadow="false"></Header>
						<div v-show="bookReviewErrors.difficulty" class="text-error" :lang="this.$i18n.locale">Please select one of the following</div>
						<div class="center-column">
							<TextButton v-for="diff in difficultyOptions" :key="diff" :button-label="diff" :button-style="getDifficultyStyle(diff)" @on-click-fn="setDifficulty" :optional-param="diff"></TextButton>
						</div>
					</div>
					<div class="middle-container">
						<Header :header-text="'How did the Chapter feel?'" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'small'" :enable-drop-shadow="false"></Header>
						<div v-show="bookReviewErrors.adjectives" class="text-error" :lang="this.$i18n.locale">Please select one of the following</div>
						<div class="center-column adjectives">
							<Checkbox v-for="adj in adjectiveOptions" :key="adj" :label="adj" :inline-label="true" @on-click-fn="toggleAdjective" :optional-param="adj" :checked="checkIfAdjectiveIsSelected(adj)"></Checkbox>
						</div>
					</div>
					<div class="bottom-container">
						<div class="flex-column row-gap">
							<Header :header-text="'Choose your Favorite:'" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'small'" :enable-drop-shadow="false"></Header>
							<div v-show="bookReviewErrors.favoriteThing" class="text-error" :lang="this.$i18n.locale">Please select one of the following</div>
							<div class="center-row">
								<ItemUpdated
									v-for="item in favoriteOptions"
									:key="item"
									:item="item"
									:component="bookReviewCategories[0] === 'twists' ? 'twist' : ''"
									:small-icons="bookReviewCategories[1] === 'twists' ? true : false"
									:tooltipEnabled="true"
									:on-click-prop="setFavorite"
									:selected="favoriteIsSelected(item.id)"
									:hovered="false"
									:on-hover-fn="
										() => {
											return false
										}
									"
									:disable-background="true"
									:disable-circle="bookReviewCategories[0] === 'twists'"
								></ItemUpdated>
							</div>
						</div>
						<div class="flex-column row-gap">
							<Header :header-text="`Choose your Least Favorite:`" :text-alignment="'center'" :font-face="'boldPT'" :font-size="'small'" :enable-drop-shadow="false"></Header>
							<div v-show="bookReviewErrors.leastFavoriteThing" class="text-error" :lang="this.$i18n.locale">Please select one of the following</div>
							<div class="center-row">
								<ItemUpdated
									v-for="item in leastFavoriteOptions"
									:key="item"
									:item="item"
									:component="bookReviewCategories[1] === 'twists' ? 'twist' : ''"
									:small-icons="bookReviewCategories[1] === 'twists' ? true : false"
									:tooltipEnabled="true"
									:on-click-prop="setLeastFavorite"
									:selected="leastFavoriteIsSelected(item.id)"
									:hovered="false"
									:on-hover-fn="
										() => {
											return false
										}
									"
									:disable-background="true"
									:disable-circle="bookReviewCategories[1] === 'twists'"
								></ItemUpdated>
							</div>
						</div>
					</div>
					<div class="button-container">
						<TextButton :button-label="$t('book_review.button_skip')" :button-style="'blue'" @on-click-fn="skipBookReview"></TextButton>
						<TextButton :button-label="$t('discord.share_feedback')" :button-style="'blue'" :fontColor="'failureRed'" @on-click-fn="openDiscord" :small-text="true" :no-wrap="true"></TextButton>
						<TextButton :button-label="$t('book_review.button_submit')" :button-style="'green'" @on-click-fn="submitBookReview"></TextButton>
					</div>
				</div>
			</template>
		</Panel>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Background from '../ui-components/background/background.vue'
import Divider from '../ui-components/dividers-headers/divider.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import ItemUpdated from '../ui-components/item/items-updated.vue'
import Panel from '../ui-components/panel/panel.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import Checkbox from '../ui-components/form-inputs/checkbox.vue'
import { loadFromLocalStorage } from '../../utils/local-storage'
import { NUMBER_OF_RUNS_LOCAL_KEY } from '../store/end-chapter-store'

export default {
	name: 'EndChapterReview',
	components: {
		Panel,
		Background,
		Divider,
		TextButton,
		ItemUpdated,
		Header,
		Checkbox
	},
	data() {
		return {
			difficultyOptions: ['Easy', 'Just Right', 'Hard'],
			difficulty: '',
			adjectives: [],
			favoriteThing: '',
			leastFavoriteThing: '',

		}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapState('endChapter', ['adjectiveOptions', 'favoriteOptions', 'leastFavoriteOptions', 'bookReviewErrors', 'bookReviewCategories', 'endRunResults', 'indexedTwists']),
		// ...mapGetters('levelUp', []),
		// ...mapGetters('endChapter', []),
		...mapGetters('story', ['votingTimeLeft', 'isVotingOpen']),
		...mapState('story', ['selectedChapter', 'oldSelectedChapter']),
	},
	methods: {
		...mapMutations('endChapter', ['updateShowBookReviewModal', 'updateBookReviewErrors', 'resetBookReviewErrors', 'updateShowVoteProgressModal', 'setQueueWishlistPrompt']),
		...mapActions('endChapter', ['finishBookReview']),
		...mapMutations('ui', ['updateDarkenOverlay', 'registerKeystrokeHandler', 'updatePreviousHandler']),

		getDifficultyStyle(option: string) {
			return this.difficulty === option ? 'green' : 'blue'
		},
		getAdjectiveStyle(option: string) {
			return this.adjectives.includes(option) ? 'green' : 'blue'
		},
		getFavoriteStyle(option: string) {
			return this.favoriteThing === option ? 'green' : 'blue'
		},
		getleastFavoriteStyle(option: string) {
			return this.leastFavoriteThing === option ? 'green' : 'blue'
		},

		setDifficulty(option: string) {
			this.difficulty = option
			console.log(`setDifficulty ${this.difficulty}`)
		},
		toggleAdjective(option: string) {
			console.log(`toggleAdjective ${this.adjectives.includes(option)} => ${this.adjectives.includes(option)}`)
			if (this.adjectives.includes(option)) {
				this.adjectives.remove(option)
			} else {
				this.adjectives.push(option)
			}
		},
		checkIfAdjectiveIsSelected(option: string) {
			console.log(`checkIfAdjectiveIsSelected ${this.adjectives.includes(option)}`)
			return this.adjectives.includes(option)
		},
		setFavorite(option: string) {
			this.favoriteThing = option
			console.log(`setFavorite ${this.favoriteThing}`)
		},
		setLeastFavorite(option: string) {
			this.leastFavoriteThing = option
			console.log(`setLeastFavorite ${this.leastFavoriteThing}`)
		},
		favoriteIsSelected(index) {
			if (this.favoriteThing !== null) {
				return index === this.favoriteThing.id
			}
		},
		leastFavoriteIsSelected(index) {
			if (this.leastFavoriteThing !== null) {
				return index === this.leastFavoriteThing.id
			}
		},
		openDiscord() {
            window.open('https://discord.gg/xbXhxe7Dxs')
		},

		submitBookReview() {
			// Errors stored in Vuex
			this.resetBookReviewErrors()

			//check your variables if they are empty... if you put the variables in VUEX make sure to update these
			if(this.difficulty === ''){
				this.updateBookReviewErrors('difficulty')
			}
			if(this.adjectives.length === 0){
				this.updateBookReviewErrors('adjectives')
			}
			if(this.favoriteOptions.length > 0 && this.favoriteThing === ''){
				this.updateBookReviewErrors('favoriteThing')
			}
			if(this.leastFavoriteOptions.length > 0 && this.leastFavoriteThing === ''){
				this.updateBookReviewErrors('leastFavoriteThing')
			}

			// Break out
			if(Object.values(this.bookReviewErrors).some( v => v === true)){
				return
			}

			let difficultyEnum

			switch (this.difficulty) {
				case 'Easy':
					difficultyEnum = 'easy'
					break
				case 'Just Right':
					difficultyEnum = 'justRight'

					this.tryWishlistPrompt()
					break
				case 'Hard':
					difficultyEnum = 'hard'
					break
			}

			const payload = {
				difficulty: difficultyEnum,
				feels: this.adjectives,
				favoriteType: this.bookReviewCategories[0],
				favorite: this.favoriteThing.name,
				leastFavoriteType: this.bookReviewCategories[1],
				leastFavorite: this.leastFavoriteThing.name
			}
			console.log(`You said the run was ${payload.feels.join(', ')}, and ${payload.difficulty}. Your favorite ${payload.favoriteType} was ${payload.favorite} and your least favorite ${payload.leastFavoriteType} was ${payload.leastFavorite}.`)
			this.updateShowBookReviewModal(false)
			if (this.isVotingOpen && this.indexedTwists.length) {
				this.updateShowVoteProgressModal(true)
			} else {
				this.updateDarkenOverlay(false)
			}
			this.finishBookReview(payload)
		},
		skipBookReview(){
			this.updateShowBookReviewModal(false)
			if (this.isVotingOpen && this.indexedTwists.length) {
				this.updateShowVoteProgressModal(true)
			} else {
				this.updateDarkenOverlay(false)
			}
		},
		tryWishlistPrompt() {
			try {
				// time in seconds, 5 mins 40 seconds
				if (this.endRunResults.runDuration > 340) {
					const numRuns = loadFromLocalStorage(NUMBER_OF_RUNS_LOCAL_KEY)
					let num = Number.parseInt(numRuns)
					num = isNaN(num) ? 0 : num
					if (num >= 5) {
						// show the prompt!
						this.setQueueWishlistPrompt(true)
					}
				}
			} catch (err) {
				console.error('Error in tryWishlistPrompt', err)
			}
		}
	},
	mounted () {
		this.updatePreviousHandler()
		const callBacks = [
			{
				keys: ['Escape'],
				callBack: this.skipBookReview.bind(this)
			},
			{
				keys: ['Enter', 'Space'],
				callBack: this.submitBookReview.bind(this)
			}
		] 
		this.registerKeystrokeHandler(callBacks)
	}
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');
.book-review-prompt {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	#book-review {
		:deep(.panel-inner) {
			border-width: 46px 11px 22px;

			.book-review-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.top-container,
				.middle-container,
				.bottom-container {
					.text-error {
						text-align: center;
						color: @failureRed;
						&[lang='en'] {
							.regularFont(@enSecondaryFontPTBold);
							font-size: 24px;
							font-variant: small-caps;
						}
						&[lang='zh'] {
							.regularFont(@zhHansHeaderFont);
							font-size: 24px;
						}
					}
				}

				.top-container,
				.middle-container {
					margin: 0px 15px;
					.center-column {
						display: flex;
						flex-wrap: wrap;
						width: 910px;
						margin: 20px 0px;
						.text-button {
							width: 259px;
							margin: 5px 20px;
						}
						#checkbox {
							flex: 0 0 300px;
							margin:8px 0px;
						}
					}
				}
				.top-container {
					.center-column {
					}
				}
				.middle-container {
				}
				.bottom-container {
					display: flex;
					flex-direction: row;
					justify-content: center;
					.flex-column {
						&.row-gap {
							margin: 0 50px;
						}
						.center-row {
							justify-content: center;
							display: flex;
						}
					}
					.center-row{
						.item{
							margin: 0px 17px;
						}
					}
				}
				.button-container {
					display: flex;
					justify-content: center;

					margin: 40px 0px 20px 0px;
					.text-button {
						margin: 0px 15px;
						width: 290px;
					}
				}
			}
		}
	}
}
</style>
