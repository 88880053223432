import { Vector } from "sat"
import { ComponentOwner } from "../engine/component-owner"
import { GameState, getNID } from "../engine/game-state"
import { Effect } from "../engine/graphics/pfx/effect"
import { Renderer } from "../engine/graphics/renderer"
import { degToRad } from "../utils/math"
import { timeInSeconds, timeInMilliseconds, radians } from "../utils/primitive-types"
import { AssetManager } from "../web/asset-manager"
import AISystem from "./enemies/ai-system"
import { EntityType, IEntity } from "./entity-interfaces"
import { Player } from "./player"

const BLAST_COOLDOWN: timeInSeconds = 0.7

const SPREAD_RADIANS: radians = degToRad(80)
const SPREAD_RADIUS: number = 500

const KNOCKBACK_STRENGTH = 700

export class RepulsionBlasts implements IEntity {
    nid: number
    entityType: EntityType = EntityType.Projectile
    timeScale: number = 1

    owner: Player

    private effect: Effect

    private blastCooldown: timeInSeconds
    private angle: radians
    private isActive: boolean = false

    private reuseKnockbackVector: Vector

    constructor() {
        this.nid = getNID(this)
        this.reuseKnockbackVector = new Vector()

        const effectConfig = AssetManager.getInstance().getAssetByName('cone-repulsion-pfx').data
        this.effect = new Effect(effectConfig, Renderer.getInstance().cameraState)
    }

    addToObject(owner: Player, angle: radians) {
        this.owner = owner
        this.blastCooldown = BLAST_COOLDOWN
        this.angle = angle
        this.isActive = true

        this.effect.rot = angle
        // this.effect.enabled = true
        this.effect.emitters.forEach(e => {
            e.reset()
        })
        Renderer.getInstance().mgRenderer.addEffectToScene(this.effect)

        GameState.addEntity(this)
    }

    removeFromObject() {
        if (!this.isActive) {
            return
        }

        this.owner = null
        // this.effect.enabled = false
        this.isActive = false
        Renderer.getInstance().mgRenderer.removeFromScene(this.effect)

        GameState.removeEntity(this)
    }

    onRemoveDelayDone() {
        if (!this.isActive) {
            Renderer.getInstance().mgRenderer.removeFromScene(this.effect)
        }
    }

    update(delta: timeInSeconds, now?: timeInMilliseconds): void {
        this.effect.x = this.owner.position.x
        this.effect.y = this.owner.position.y
        this.effect.zIndex = this.owner.position.y

        this.angle = this.owner.aimAngle
        this.effect.rot = this.angle

        this.blastCooldown -= delta
        if (this.blastCooldown <= 0) {
            this.blastCooldown += BLAST_COOLDOWN
            this.knockbackEnemies()
        }
    }

    knockbackEnemies() {
		const enemiesBehindPlayer = AISystem.getInstance().getEnemiesInAngleRange(this.owner.position, this.angle, SPREAD_RADIANS, SPREAD_RADIUS)
        enemiesBehindPlayer.forEach(e => {
            this.reuseKnockbackVector.copy(e.position)
            this.reuseKnockbackVector.sub(this.owner.position)
            this.reuseKnockbackVector.normalize()
            this.reuseKnockbackVector.scale(KNOCKBACK_STRENGTH, KNOCKBACK_STRENGTH)
            e.addKnockBack(this.reuseKnockbackVector)
        })
    }
}
