import { autoDetectRenderer, Container, Graphics, Renderer as PIXIRenderer } from 'pixi.js'
import { IParticleRendererCamera } from '../../engine/graphics/pfx/sprite-particle-renderer'
import { timeInSeconds } from '../../utils/primitive-types'
import { Vector } from 'sat'
import { basicNumberLerp, throwIfNotFinite } from '../../utils/math'
import { AssetManager } from '../../web/asset-manager'
import ProjectileEffectManager from './projectile-effect-manager'
import { debugConfig } from '../../utils/debug-config'
import { LayerRenderer } from './renderers/layer-renderer'
import { InstancedSpriteBatcher } from './pfx/instanced-sprite-batcher'
import { RenderQueue } from './render-queue'
import SpatialGridVisual from '../collision/spatial-grid-visual'
import { WORLD_DATA } from '../../world-generation/world-data'
import { EffectConfig } from './pfx/effectConfig'
import { Effect } from './pfx/effect'
import { Projectile } from '../../projectiles/projectile-types'
import { attachments_removeAttachments } from '../../utils/attachments-system'
import { simpleAnimation_removeAnimations } from '../../utils/simple-animation-system'
import { Camera } from './camera-logic'
import { debugtool } from '../../utils/decorators'
import { Vignette } from './vignette'

const MAX_CAM_DIST_X = 75
const MAX_CAM_DIST_Y = 50

export class Renderer {
	bgRenderer: LayerRenderer
	mgRenderer: LayerRenderer
	fgRenderer: LayerRenderer
	pixiRenderer: PIXIRenderer

	instancedSpriteBatcher: InstancedSpriteBatcher
	renderQueue: RenderQueue
	projectileEffectManager: ProjectileEffectManager
	private debugGraphicsCache: Map<number, Graphics> = new Map()
	stage: Container
	camera: Container
	cameraState: IParticleRendererCamera
	zoomLevel: number

	vignette: Vignette

	private renderTimeMap: Map<string, number> = new Map()
	private framesCountedTimes: number = 0

	static getInstance() {
		return Renderer.instance
	}

	static instance: Renderer

	constructor() {
		try {
			const canvas: HTMLCanvasElement = document.getElementById('main-canvas') as HTMLCanvasElement

			this.pixiRenderer = autoDetectRenderer({
				width: window.innerWidth,
				height: window.innerHeight,
				view: canvas,
				antialias: false,
				transparent: false,
				resolution: 1,
			})

			this.pixiRenderer.clear()

			window.document.removeEventListener('mousemove', this.pixiRenderer.plugins.interaction.onPointerMove, true);
			window.document.removeEventListener('pointermove', this.pixiRenderer.plugins.interaction.onPointerMove, true);

			// canvas.addEventListener('webglcontextlost', analyticsWebglContextLost_SendOnce)

			// enable this line to turn textureGC to manual mode
			// this.pixiRenderer.textureGC.mode = PIXI.GC_MODES.MANUAL

			const MAX_TEXTURES = this.pixiRenderer.plugins.batch.MAX_TEXTURES

			console.debug('[RENDER] create InstancedSpriteBatcher and RenderQueue')

			//TODO?: texture preloading

			this.instancedSpriteBatcher = new InstancedSpriteBatcher(this.pixiRenderer, MAX_TEXTURES)
			this.renderQueue = new RenderQueue(this.pixiRenderer, this.instancedSpriteBatcher)

			this.zoomLevel = Camera.getInstance().getTargetZoom()
			this.stage = new Container()
			this.stage.name = 'stage'
			this.stage.filters = []
			this.camera = new Container()
			this.camera.name = 'camera'

			this.cameraState = {
				x: 0,
				y: 0,
				zoom: 1.0,
				halfWidth: window.innerWidth / 2.0,
				halfHeight: window.innerHeight / 2.0,
			}

			// this.bgRenderer = new BackgroundRenderer(params.gameClientState)
			this.bgRenderer = new LayerRenderer(this.renderQueue, this.cameraState)
			this.bgRenderer.name = 'background'

			// this.mgRenderer = new MiddlegroundRenderer(this.renderQueue, this.cameraState)
			this.mgRenderer = new LayerRenderer(this.renderQueue, this.cameraState)
			this.mgRenderer.name = 'middleground'
			// projectile specific renderer
			// this.fgRenderer = new ForegroundRenderer(this.renderQueue, this.cameraState)
			this.fgRenderer = new LayerRenderer(this.renderQueue, this.cameraState)
			this.fgRenderer.name = 'foreground'
			if (debugConfig.collisions.showGrid) {
				this.fgRenderer.addChild(new SpatialGridVisual(WORLD_DATA.infiniteWorldDimension, WORLD_DATA.infiniteWorldDimension))
			}

			this.stage.addChild(this.bgRenderer)
			this.stage.addChild(this.mgRenderer)
			this.stage.addChild(this.fgRenderer)
			this.camera.addChild(this.stage)

			this.camera.scale.set(this.zoomLevel)

			this.projectileEffectManager = new ProjectileEffectManager(this.mgRenderer, this.cameraState)
			this.projectileEffectManager.onAssetsLoaded()

			//TODO: handle window resize events, zoom level, etc.
			this.vignette = new Vignette(this.zoomLevel)
			this.fgRenderer.addChild(this.vignette.container)

			const objs = this.drawDummyObjects()
			for (let obj of objs) {
				// this.bgRenderer.addChild(obj)
			}

		} catch (e) {
			console.error('Error during renderer startup', e)
			throw e
		}

		Renderer.instance = this
	}

	update(delta: timeInSeconds): void {
		this.projectileEffectManager.update(delta)
		this.bgRenderer.update(delta)
		this.mgRenderer.update(delta)
		this.fgRenderer.update(delta)

		const cameraPos = this.getCameraCenterWorldPos()
		this.vignette.updatePosition(cameraPos)

		this.pixiRenderer.render(this.camera)
		this.framesCountedTimes++
	}

	registerProjectile(projectile: Projectile): Effect[] {
		return this.projectileEffectManager.registerProjectile(projectile)
	}

	unregisterProjectile(nid: number): void {
		this.projectileEffectManager.unregisterProjectile(nid)
	}
	
	drawDummyObjects() {
		const objs = []
		for (let i = 0; i <= 10; i++) {
			const gfx = new Graphics()
			gfx.beginFill(0xff0000)
			gfx.drawCircle(i * 100, i * 60, 100)
			gfx.endFill()
			objs.push(gfx)
		}
		for (let i = 0; i <= 10; i++) {
			const gfx = new Graphics()
			gfx.beginFill(0x0000ff)
			gfx.drawRect(1000 - i * 50, i * 60, 120, 50)
			gfx.endFill()
			objs.push(gfx)
		}
		return objs
	}

	getCameraWorldXPos() {
		return -(this.camera.x - 0.5 * window.innerWidth) / this.zoomLevel
	}

	getCameraWorldYPos() {
		return -(this.camera.y - 0.5 * window.innerHeight) / this.zoomLevel
	}

	getCameraCenterWorldPos() {
		return new Vector(this.getCameraWorldXPos(), this.getCameraWorldYPos())
	}

	mouseCoordinatesToWorldCoordinates(mouseX: number, mouseY: number) {
		const camLeft = -(this.camera.x / this.zoomLevel)
		const camTop = -(this.camera.y / this.zoomLevel)

		return {
			x: camLeft + mouseX / this.zoomLevel,
			y: camTop + mouseY / this.zoomLevel,
		}
	}

	centerCameraOnPoint(x, y, lerpT: number): void {
		//below clamps the camera to the world bounds
		///x = Math.clamp(x, this.cameraMinX, this.cameraMaxX)
		//y = Math.clamp(y, this.cameraMinY, this.cameraMaxY)
		x = -x
		y = -y

		const newXTarget = x * this.zoomLevel + 0.5 * window.innerWidth
		const newYTarget = y * this.zoomLevel + 0.5 * window.innerHeight

		const noZoomXTarget = x + 0.5 * window.innerWidth
		const noZoomYTarget = y + 0.5 * window.innerHeight

		let lerpedNewX = basicNumberLerp(this.camera.x, newXTarget, lerpT)
		let lerpedNewY = basicNumberLerp(this.camera.y, newYTarget, lerpT)
		let lerpedNewXWithoutZoom = basicNumberLerp(this.cameraState.x, noZoomXTarget, lerpT)
		let lerpednewYWithoutZoom = basicNumberLerp(this.cameraState.y, noZoomYTarget, lerpT)

		const xDistance = lerpedNewX - newXTarget
		const yDistance = lerpedNewY - newYTarget

		const noZoomXDist = lerpedNewXWithoutZoom - noZoomXTarget
		const noZoomYDist = lerpednewYWithoutZoom - noZoomYTarget

		if (xDistance > MAX_CAM_DIST_X) {
			lerpedNewX = newXTarget + MAX_CAM_DIST_X
		} else if (xDistance < -MAX_CAM_DIST_X) {
			lerpedNewX = newXTarget - MAX_CAM_DIST_X
		}

		if (yDistance > MAX_CAM_DIST_Y) {
			lerpedNewY = newYTarget + MAX_CAM_DIST_Y
		} else if (yDistance < -MAX_CAM_DIST_Y) {
			lerpedNewY = newYTarget - MAX_CAM_DIST_Y
		}

		if (noZoomXDist > MAX_CAM_DIST_X) {
			lerpedNewXWithoutZoom = noZoomXTarget + MAX_CAM_DIST_X
		} else if (noZoomXDist < -MAX_CAM_DIST_X) {
			lerpedNewXWithoutZoom = noZoomXTarget - MAX_CAM_DIST_X
		}

		if (noZoomYDist > MAX_CAM_DIST_Y) {
			lerpednewYWithoutZoom = noZoomYTarget + MAX_CAM_DIST_Y
		} else if (noZoomYDist < -MAX_CAM_DIST_Y) {
			lerpednewYWithoutZoom = noZoomYTarget - MAX_CAM_DIST_Y
		}

		this.camera.x = lerpedNewX
		this.camera.y = lerpedNewY
		this.cameraState.zoom = this.zoomLevel
		this.cameraState.x = lerpedNewXWithoutZoom
		this.cameraState.y = lerpednewYWithoutZoom
		this.cameraState.halfWidth = window.innerWidth * 0.5
		this.cameraState.halfHeight = window.innerHeight * 0.5
	}

	updateZoomLevel(zoomLevel: number, forceGraphicResize: boolean = false) {
		// if (this.zoomLevel.toFixed(3) !== (zoomLevel * this.debugZoomLevel).toFixed(3)) {
		// 	console.log({ zoomLevel })
		// }
		if (this.pixiRenderer) {
			if (this.zoomLevel !== zoomLevel || forceGraphicResize) {
				this.zoomLevel = zoomLevel //* this.debugZoomLevel
				// this.bgRenderer?.resizeGradient(zoomLevel)
				// this.fgRenderer?.resizeVignette(zoomLevel)
				this.vignette.resizeVignette(zoomLevel)
			}
		}
		this.camera.scale.set(this.zoomLevel)
		this.pixiRenderer.resize(window.innerWidth, window.innerHeight)
	}

	addOneOffEffectByConfig(pfxAsset: EffectConfig, x: number, y: number, z?: number, scale: number = 1, duration: number = 1, prewarm: boolean = false, foreground: boolean = false, alpha: number = 1) {
		throwIfNotFinite(x)
		throwIfNotFinite(y)

		const pfx = ProjectileEffectManager.allocEffect(pfxAsset.assetName)
		pfx.x = x
		pfx.y = y
		pfx.zIndex = z ? z : y + 1
		pfx.scale = scale

		pfx.emitters.forEach((e) => {
			e.alpha = alpha
		})

		const renderer = foreground ? this.fgRenderer : this.mgRenderer
		renderer.addOneOffEffectToScene(pfx, duration, prewarm)

		return pfx
	}

	addEffectToScene(particleEffect: string, x: number, y: number, z?: number, scale: number = 1) {
		const pfx = ProjectileEffectManager.allocEffect(particleEffect)
		pfx.x = x
		pfx.y = y
		pfx.zIndex = z ?? y
		pfx.scale = scale
		this.mgRenderer.addEffectToScene(pfx)
		return pfx
	}
	
	removeEffectFromScene(effect: Effect) {
		attachments_removeAttachments(effect)
		simpleAnimation_removeAnimations(effect)
		this.mgRenderer.removeFromScene(effect)
		
		ProjectileEffectManager.freeEffect(effect)
	}

	cleanUp() {
		this.projectileEffectManager.cleanUp()

		this.stage.destroy({children: true})
		this.camera.destroy({children: true})
		this.stage = null
		this.camera = null
		this.vignette.destroy()
		this.vignette = null

		this.bgRenderer.shutdown()
		this.mgRenderer.shutdown()
		this.fgRenderer.shutdown()

		this.instancedSpriteBatcher.destroy() // need more?
		this.debugGraphicsCache.clear()

		this.pixiRenderer.destroy()

		this.renderQueue = null
		this.instancedSpriteBatcher = null

		Renderer.instance = null
	}

	/** returns a debug pixi Graphics object to use for drawing, that will be destroyed in destroyAfterSeconds, unless permanent */
	private getDebugGraphics(destroyAfterSeconds, permanent) {
		const cacheKey = destroyAfterSeconds * 1000
		let graphics = this.debugGraphicsCache.get(cacheKey) // this is hella weird but it's debug so whatever
		if (!graphics) {
			graphics = new Graphics()
			graphics['update'] = () => {}

			this.mgRenderer.addDisplayObjectToScene(graphics)
			this.debugGraphicsCache.set(cacheKey, graphics)

			if (!permanent) {
				setTimeout(() => {
					this.mgRenderer.removeFromScene(graphics)
					graphics.destroy()
					this.debugGraphicsCache.delete(cacheKey)
				}, destroyAfterSeconds * 1000)
			}
		}
		return graphics
	}

	drawLine({ sourceX, sourceY, destX, destY, color, permanent, destroyAfterSeconds }) {
		const debugGraphics = this.getDebugGraphics(destroyAfterSeconds, permanent)
		debugGraphics.lineStyle(2, color)
		debugGraphics.moveTo(sourceX, sourceY)
		debugGraphics.lineTo(destX, destY)
	}

	drawCircle({ x, y, radius, color, permanent, destroyAfterSeconds, scale }) {
		const circleGraphics = this.getDebugGraphics(destroyAfterSeconds, permanent)
		circleGraphics.lineStyle(3, color)
		circleGraphics.drawCircle(x, y, radius * scale)
	}

	@debugtool
	addRenderTime(key: string, amount: number) {
		if (!debugConfig.benchmarkMode) {
			return
		}

		let oldValue = this.renderTimeMap.get(key)
		if (oldValue !== undefined) {
			this.renderTimeMap.set(key, oldValue + amount)
		} else {
			this.renderTimeMap.set(key, amount)
		}
	}

	@debugtool
	clearRenderTimes() {
		let sum = 0 // not correct; double counting
		console.group('RENDER TIMES, RENDER TIMES')
		const arr = Array.from(this.renderTimeMap.entries())
		arr.sort((a, b) => b[1] - a[1])

		arr.forEach((v) => {
			console.log(`${v[0]} : ${v[1]/this.framesCountedTimes}`)
			// sum += v
		})
		console.log(`SUM : ${sum/this.framesCountedTimes}`)
		console.groupEnd()

		console.log(`avg number of flushes: ${this.renderQueue.numSwitches / this.framesCountedTimes}`)

		this.renderTimeMap.clear()

		this.framesCountedTimes = 0
	}

	// drawText({ text, x, y, color, permanent, destroyAfterSeconds, scale }: DrawText) {
	// 	if (!debugConfig.debug && !debugConfig.disableExpensiveDrawCallWarnings) {
	// 		console.warn('WARNING: Calling very expensive (i.e. hitch inducing) drawText in renderer! This should not happen frequently.')
	// 	}
	// 	if (clientConfig.debug) {
	// 		this.drawTextDirect(text, x, y, color, permanent, destroyAfterSeconds, this.debugMiddleground, scale)
	// 	}
	// }
}