import { InstancedSpriteSheetAnimator } from "../engine/graphics/instanced-spritesheet-animator"
import { POI } from "../pois/poi";
import { ObjectPool } from "../utils/third-party/object-pool"
import { AssetManager } from "../web/asset-manager"
import { EventTypes } from "./event-types"
import { killingForFlowersEventPreset } from "./gameplay-event-definitions";
import { GameplayTimedEventSystem } from "./gameplay-timed-event-system";
import { ShrineGameplayEvent } from "./shrine-gameplay-event";

const PLANT_Z_OFFSET = -30

export class KillingForFlowersShrineGameplayEvent extends ShrineGameplayEvent {

	flowerGfxPool: ObjectPool

	lastEventPresetType: string

	constructor() {
			super()

			const heartFlowerSheet = AssetManager.getInstance().getAssetByName('heart-flower').spritesheet

			this.flowerGfxPool = new ObjectPool(() => {
					return new InstancedSpriteSheetAnimator(heartFlowerSheet, 'idle')
			}, {}, 1, 1, 'heartFlowerPool')
	}

	override setRandomPreset(): void {
		this.eventPresetConfig = killingForFlowersEventPreset.killingForFlowers
	}
	
	override startEvent(): void {
			super.startEvent()

			// place a plant at the POI
			const gfxPool = this.flowerGfxPool as ObjectPool
			const sprite: InstancedSpriteSheetAnimator = gfxPool.alloc()

			this.activePoi.state.sprite = sprite
			this.activePoi.state.gfxPool = gfxPool

			const poiPos = this.activePoi.position
			sprite.position.copy(poiPos)
			sprite.zIndex = poiPos.y + PLANT_Z_OFFSET
			sprite.setAnchor(0, 1)
			sprite.playAnimation('spawn')
			sprite.addToScene()
	}

	protected override onEventDoneInternal(poi: POI) {
		const sprite: InstancedSpriteSheetAnimator = poi.state.sprite
		const gfxPool = poi.state.gfxPool

		sprite.playAnimation('destroyed', undefined, () => {
				sprite.removeFromScene()
				gfxPool.free(sprite)

				poi.freeFromPool()
		})
			GameplayTimedEventSystem.getInstance().onEventEnd(EventTypes.KillingForFlowers)
	}
}
