import EntityStatList from "../../../stats/entity-stat-list"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { AutoFireSecondaryWeapon } from "./auto-fire-secondary-weapon"
import { AllWeaponTypes } from "../../weapon-types"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { DamageSource, getDamageFromDamageSource } from "../../../projectiles/damage-source"
import { EntityType } from "../../../entities/entity-interfaces"
import { LightningStrike, LightningStrikeParams } from "../../../entities/lightning-strike"
import { callbacks_addCallback, callbacks_removeCallbacksFromOwner } from "../../../utils/callback-system"
import { GameState } from "../../../engine/game-state"
import { Vector } from "sat"
import { Player } from "../../../entities/player"
import { ObjectPoolTyped } from "../../../utils/third-party/object-pool"
import { getEffectMultiplierBasedOnSkill } from "../../../game-data/player-formulas"
import { Audio } from "../../../engine/audio"

export class DarkStormyNightWeapon extends AutoFireSecondaryWeapon implements DamageSource {
	numEntitiesChained: number = 0
	numEntitiesPierced: number = 0

	nid: number
	entityType: EntityType
	weaponType: AllWeaponTypes = AllWeaponTypes.DarkStormyNight
	timeScale: number = 1

	showImmediateDamageNumber: boolean = false

	constructor(player: Player, statList: EntityStatList) {
		super(player, statList)

		if(!LightningStrike.pool) {
			LightningStrike.pool = new ObjectPoolTyped<LightningStrike, LightningStrikeParams>(() => new LightningStrike(), {}, 8, 4)
		}
	}

	fire() {
		const count = Math.ceil(this.statList.getStat(StatType.projectileCount))
		const radius = this.statList.getStat(StatType.attackSize)

		for (let i = 0; i < count; i++) {
			callbacks_addCallback(this, () => {
				LightningStrike.strikeEnemiesOnScreen(1, this.statList, radius, 0, 1.0, 'dark-stormy-night', this.weaponType)
			}, 0.05 * i)
		}

		if (GameState.player.binaryFlags.has('lightning-rod') || GameState.player.binaryFlags.has('lightning-rod-faster')) {
			const cooldown = this.statList.getStat(StatType.cooldownInterval) / 1000
			let times = [cooldown * 0.333333, cooldown * 0.666666]
			if (GameState.player.binaryFlags.has('lightning-rod-faster')) {
				times.push(0)
			}
			for (const t of times) {
				callbacks_addCallback(this, () => {
					this.lightningRod()
				}, t)
			}
		}
	}

	forceStopFiring() {
		callbacks_removeCallbacksFromOwner(this)
    }
	
	isPlayerOwned(): boolean {
		return true
	}

	getKnockbackDirection(mutableEntityPos: SAT.Vector): SAT.Vector {
		mutableEntityPos.x = 0
		mutableEntityPos.y = 0
		return mutableEntityPos
	}

	resetStatsFunction(statList: EntityStatList) {
		defaultStatAttribute(statList)
		statList._actualStatValues.projectileCount = 1
		statList._actualStatValues.baseDamage = 23
		statList._actualStatValues.attackRate = 1
		statList._actualStatValues.skillDuration = 3
		statList._actualStatValues.attackSize = 125
		statList._actualStatValues.placementRange = 0

		statList._actualStatValues.allDamageMult = 1

		statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 6_000
		statList._actualStatValues.reloadInterval = 50
	}

	lightningStrikesOnSkillUse(cooldown: number, ammo: number = 1) {
		const multi = getEffectMultiplierBasedOnSkill(cooldown, ammo)
		const count = Math.max(Math.ceil(this.statList.getStat(StatType.projectileCount) * multi), 1)
		const radius = this.statList.getStat(StatType.attackSize)

		for (let i = 0; i < count; i++) {
			callbacks_addCallback(this, () => {
				LightningStrike.strikeEnemiesOnScreen(1, this.statList, radius, 0, 1.0, 'dark-stormy-night', this.weaponType)
			}, 0.05 * i)
		}
	}

	lightningRod() {
		const damage = getDamageFromDamageSource(this) * 1.4
		const radius = this.statList.getStat(StatType.attackSize) * 1.2
		const knockback = this.statList.getStat(StatType.attackKnockback)

		var pos = GameState.player.position.clone().add(new Vector(0, -radius/4))

		LightningStrike.strikeEnemy(({ position: pos } as unknown as any), this.statList, radius, knockback, 1.0, 'dark-stormy-night', this.weaponType) // we only care about the position property
		Audio.getInstance().playSfx('SFX_Lightning_Rod');
	}
}
