import { EnemyPackStageConfig } from "./enemy-equilibrium-spawner";
import { ENEMY_NAME } from "./enemy-names";

export const FOREST_ACT_1_STAGES: EnemyPackStageConfig[] = [
	{
		min: -1, //ensure we begin as soon as the game starts, to offset fairly far 
		max: 20,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND,
				equilibriumAmount: 6,
				maxAmount: 20,
				spawnAmount: 1,
				spawnRate: 2,
			},
		],
	},
	// { // debug spawn everything
	// 	min: 0,
	// 	max: 20,
	// 	packs: [
	// 		{
	// 			enemyName: ENEMY_NAME.SHAMBLING_MOUND,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 20,
	// 			spawnAmount: 8,
	// 			spawnRate: 3.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.MOSQUITO,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 20,
	// 			spawnAmount: 8,
	// 			spawnRate: 10.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.EYEBAT_1,
	// 			equilibriumAmount: 4,
	// 			maxAmount: 6,
	// 			spawnAmount: 2,
	// 			spawnRate: 5.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.DEMON_SKEETER,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 20,
	// 			spawnAmount: 4,
	// 			spawnRate: 4.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.BLIMPIE,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.SHRIEKER,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.SHAMBLING_MOUND_2,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.SHAMBLING_MOUND_3,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.SHAMBLING_MOUND_4,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.SHAMBLING_MOUND_5,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.HUGE_SHAMBLING_MOUND,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.HUGE_SHAMBLING_MOUND_2,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.HUGE_SHAMBLING_MOUND_3,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.HUGE_SHAMBLING_MOUND_4,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.HUGE_SHAMBLING_MOUND_5,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.EYEBAT_2,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.EYEBAT_3,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.BLIMPIE_2,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.BLIMPIE_3,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 10,
	// 			spawnAmount: 1,
	// 			spawnRate: 14.0,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.CRYSTAL_SPIDER_1,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.CRYSTAL_SPIDER_2,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.CRYSTAL_SPIDER_3,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.CRYSTAL_SPIDER_4,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.MUSHIE_1,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.MUSHIE_2,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.MUSHIE_3,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.SKELETON_MAGE_1,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.SKELETON_MAGE_2,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.SKELETON_MAGE_3,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.SPIDER_1,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.SPIDER_2,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.WISP_1,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// 		{
	// 			enemyName: ENEMY_NAME.WISP_2,
	// 			equilibriumAmount: 6,
	// 			maxAmount: 20,
	// 			spawnAmount: 1,
	// 			spawnRate: 2,
	// 		},
	// {
	// 	enemyName: ENEMY_NAME.SNOWBALL,
	// 	equilibriumAmount: 6,
	// 	maxAmount: 20,
	// 	spawnAmount: 1,
	// 	spawnRate: 2,
	// },
	// {
	// 	enemyName: ENEMY_NAME.ICE_ELEMENTAL,
	// 	equilibriumAmount: 6,
	// 	maxAmount: 20,
	// 	spawnAmount: 1,
	// 	spawnRate: 2,
	// },
	// {
	// 	enemyName: ENEMY_NAME.ICE_ELEMENTAL_2,
	// 	equilibriumAmount: 6,
	// 	maxAmount: 20,
	// 	spawnAmount: 1,
	// 	spawnRate: 2,
	// },

	// 	],
	// },
	// {
	// 	min: 1,
	// 	max: 30,
	// 	packs: [
	// 		{
	// 			enemyName: ENEMY_NAME.PRISMFLY,
	// 			equilibriumAmount: 0,
	// 			maxAmount: 1,
	// 			spawnAmount: 1,
	// 			spawnRate: 9999.0,
	// 		},
	// 	],
	// },
	{
		min: 21,
		max: 60,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND,
				equilibriumAmount: 12,
				maxAmount: 25,
				spawnAmount: 3,
				groupAmount: { min: 0, max: 2 },
				spawnRate: 3.0,
			},
		],
	},
	{
		min: 61,
		max: 120,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND,
				equilibriumAmount: 40,
				maxAmount: 90,
				spawnAmount: 5,
				groupAmount: { min: 5, max: 5 },
				spawnRate: 3.5,
			},
			{
				enemyName: ENEMY_NAME.MOSQUITO,
				equilibriumAmount: 0,
				maxAmount: 8,
				spawnAmount: 4,
				groupAmount: { min: 4, max: 4 },
				spawnRate: 10.0,
			},
		],
	},
	{
		min: 121,
		max: 160,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND,
				equilibriumAmount: 70,
				maxAmount: 150,
				spawnAmount: 5,
				groupAmount: { min: 4, max: 5 },
				spawnRate: 3.0,
			},
			{
				enemyName: ENEMY_NAME.EYEBAT_1,
				equilibriumAmount: 2,
				maxAmount: 4,
				spawnAmount: 1,
				spawnRate: 5.0,
			},
		],
	},
	{
		min: 161,
		max: 220,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND,
				equilibriumAmount: 70,
				maxAmount: 150,
				spawnAmount: 5,
				groupAmount: { min: 4, max: 5 },
				spawnRate: 3.0,
			},
			{
				enemyName: ENEMY_NAME.BLIMPIE,
				equilibriumAmount: 0,
				maxAmount: 2,
				spawnAmount: 1,
				spawnRate: 3.5,
			},
		]
	},
	{
		min: 221,
		max: 240,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND,
				equilibriumAmount: 10,
				maxAmount: 40,
				spawnAmount: 4,
				groupAmount: { min: 2, max: 3 },
				spawnRate: 3.0,
			},
			{
				enemyName: ENEMY_NAME.EYEBAT_1,
				equilibriumAmount: 3,
				maxAmount: 5,
				spawnAmount: 1,
				spawnRate: 5.0,
			},
		],
	},
	{
		min: 241,
		max: 300,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND,
				equilibriumAmount: 100,
				maxAmount: 200,
				spawnAmount: 8,
				groupAmount: { min: 6, max: 8 },
				spawnRate: 3.0,
			},
			{
				enemyName: ENEMY_NAME.EYEBAT_1,
				equilibriumAmount: 4,
				maxAmount: 8,
				spawnAmount: 2,
				spawnRate: 5.0,
			},
			{
				enemyName: ENEMY_NAME.BLIMPIE,
				equilibriumAmount: 0,
				maxAmount: 3,
				spawnAmount: 1,
				spawnRate: 3.5,
			}
		],
	},
]

export const FOREST_ACT_2_STAGES = [
	{
		min: 326,
		max: 350,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND_2,
				equilibriumAmount: 25,
				maxAmount: 60,
				spawnAmount: 8,
				groupAmount: { min: 6, max: 8 },
				spawnRate: 3.0,
			},
		],
	},
	{
		min: 351,
		max: 420,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND_2,
				equilibriumAmount: 55,
				maxAmount: 80,
				spawnAmount: 8,
				groupAmount: { min: 6, max: 8 },
				spawnRate: 2.8,
			}
		],
	},
	{
		min: 391,
		max: 420,
		packs: [
			{
				enemyName: ENEMY_NAME.SHRIEKER,
				equilibriumAmount: 1,
				maxAmount: 5,
				spawnAmount: 1,
				spawnRate: 14.0,
			},
		],
	},
	{
		min: 421,
		max: 520,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND_2,
				equilibriumAmount: 120,
				maxAmount: 200,
				spawnAmount: 8,
				groupAmount: { min: 6, max: 8 },
				spawnRate: 3.0,
			},
			{
				enemyName: ENEMY_NAME.DEMON_SKEETER,
				equilibriumAmount: 16,
				maxAmount: 24,
				spawnAmount: 8,
				groupAmount: { min: 8, max: 8 },
				spawnRate: 16.0,
			},
		],
	},
	{
		min: 521,
		max: 540,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND_3,
				equilibriumAmount: 10,
				maxAmount: 40,
				spawnAmount: 3,
				groupAmount: { min: 0, max: 2 },
				spawnRate: 3.0,
			},
			{
				enemyName: ENEMY_NAME.SHRIEKER,
				equilibriumAmount: 1,
				maxAmount: 6,
				spawnAmount: 2,
				groupAmount: { min: 2, max: 2 },
				spawnRate: 14.0,
			},
		],
	},
	{
		min: 541,
		max: 580,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND_3,
				equilibriumAmount: 120,
				maxAmount: 150,
				spawnAmount: 8,
				groupAmount: { min: 6, max: 8 },
				spawnRate: 3.0,
			},
			{
				enemyName: ENEMY_NAME.SHRIEKER,
				equilibriumAmount: 1,
				maxAmount: 12,
				spawnAmount: 4,
				groupAmount: { min: 4, max: 4 },
				spawnRate: 18.0,
			},
			{
				enemyName: ENEMY_NAME.BLIMPIE,
				equilibriumAmount: 2,
				maxAmount: 5,
				spawnAmount: 1,
				spawnRate: 3,
			},
		],
	},
	{
		min: 581,
		max: 600,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND_3,
				equilibriumAmount: 90,
				maxAmount: 120,
				spawnAmount: 8,
				groupAmount: { min: 6, max: 8 },
				spawnRate: 3.0,
			},
			{
				enemyName: ENEMY_NAME.BLIMPIE,
				equilibriumAmount: 2,
				maxAmount: 5,
				spawnAmount: 1,
				spawnRate: 3,
			},
		],
	},
]

export const FOREST_ACT_3_STAGES = [
	{
		min: 626,
		max: 660,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND_4,
				equilibriumAmount: 120,
				maxAmount: 140,
				spawnAmount: 10,
				groupAmount: { min: 8, max: 10 },
				spawnRate: 3.0,
			},
			{
				enemyName: ENEMY_NAME.EYEBAT_1,
				equilibriumAmount: 8,
				maxAmount: 20,
				spawnAmount: 3,
				groupAmount: { min: 3, max: 3 },
				spawnRate: 8.0,
			},
		],
	},
	{
		min: 661,
		max: 690,
		packs: [
			{
				enemyName: ENEMY_NAME.SHRIEKER,
				equilibriumAmount: 3,
				maxAmount: 15,
				spawnAmount: 4,
				groupAmount: { min: 4, max: 4 },
				spawnRate: 15.0,
			},
		],
	},
	{
		min: 661,
		max: 720,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND_4,
				equilibriumAmount: 150,
				maxAmount: 200,
				spawnAmount: 10,
				groupAmount: { min: 8, max: 10 },
				spawnRate: 2.5,
			},
			{
				enemyName: ENEMY_NAME.EYEBAT_1,
				equilibriumAmount: 12,
				maxAmount: 20,
				spawnAmount: 3,
				groupAmount: { min: 3, max: 3 },
				spawnRate: 5.0,
			},
			{
				enemyName: ENEMY_NAME.DEMON_SKEETER,
				equilibriumAmount: 16,
				maxAmount: 24,
				spawnAmount: 8,
				groupAmount: { min: 8, max: 8 },
				spawnRate: 12.0,
			},
		],
	},
	{
		min: 721,
		max: 810,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND_4,
				equilibriumAmount: 240,
				maxAmount: 300,
				spawnAmount: 12,
				groupAmount: { min: 8, max: 10 },
				spawnRate: 2.5,
			},
		],
	},
	{
		min: 761,
		max: 810,
		packs: [
			{
				enemyName: ENEMY_NAME.BLIMPIE,
				equilibriumAmount: 5,
				maxAmount: 10,
				spawnAmount: 2,
				spawnRate: 2,
			}
		]
	},
	{
		min: 811,
		max: 830,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND_4,
				equilibriumAmount: 120,
				maxAmount: 150,
				spawnAmount: 12,
				groupAmount: { min: 10, max: 11 },
				spawnRate: 2.25,
			},
		],
	},
	{
		min: 831,
		max: 880,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND_5,
				equilibriumAmount: 200,
				maxAmount: 240,
				spawnAmount: 12,
				groupAmount: { min: 9, max: 11 },
				spawnRate: 2.25,
			},
			{
				enemyName: ENEMY_NAME.EYEBAT_1,
				equilibriumAmount: 20,
				maxAmount: 25,
				spawnAmount: 3,
				groupAmount: { min: 2, max: 3 },
				spawnRate: 7.0,
			},
			{
				enemyName: ENEMY_NAME.BLIMPIE,
				equilibriumAmount: 6,
				maxAmount: 10,
				spawnAmount: 2,
				spawnRate: 2,
			}
		],
	},
	{
		min: 881,
		max: 900,
		packs: [
			{
				enemyName: ENEMY_NAME.SHAMBLING_MOUND_5,
				equilibriumAmount: 100,
				maxAmount: 200,
				spawnAmount: 12,
				groupAmount: { min: 10, max: 12 },
				spawnRate: 2.35,
			},
			{
				enemyName: ENEMY_NAME.EYEBAT_1,
				equilibriumAmount: 10,
				maxAmount: 15,
				spawnAmount: 3,
				groupAmount: { min: 3, max: 3 },
				spawnRate: 6.0,
			},
		],
	},
	{
		min: 901,
		max: 925,
		packs: [
			{
				enemyName: ENEMY_NAME.BLIMPIE,
				equilibriumAmount: 6,
				maxAmount: 10,
				spawnAmount: 1,
				spawnRate: 10,
			}
		],
	},
]
