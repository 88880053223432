import { Vector } from "sat"
import { Effect } from "../engine/graphics/pfx/effect"
import { EffectConfig } from "../engine/graphics/pfx/effectConfig"
import { Renderer } from "../engine/graphics/renderer"
import EnemyEquilibriumSpawner from "../entities/enemies/enemy-equilibrium-spawner"
import { PetCollectionName } from "../entities/pets/pet"
import { WildPet, WildPetParams } from "../entities/pets/wild-pet"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../game-data/player-formulas"
import { attachments_addAttachment, attachments_removeAttachments } from "../utils/attachments-system"
import { timeInSeconds } from "../utils/primitive-types"
import { ObjectPoolTyped } from "../utils/third-party/object-pool"
import { InGameTime } from "../utils/time"
import { AssetManager } from "../web/asset-manager"
import { PropPlacer } from "../world-generation/prop-placement"
import { EventTypes } from "./event-types"
import { EventStartData, GameplayEvent } from "./gameplay-event-definitions"
import { GameplayTimedEventSystem } from "./gameplay-timed-event-system"


const DEFAULT_TIME_LIMIT =  900
const MIN_SPAWN_DISTANCE_FROM_PLAYER = 800
const MAX_SPAWN_DISTANCE_FROM_PLAYER = 1200
const MOVEMENT_SPEED = PLAYER_DEFAULT_MOVEMENTSPEED * 0.5

const PET_TAMING_DISTANCE = 800
const PET_TAMING_DISTANCE_2 = PET_TAMING_DISTANCE ** 2
const AURA_EFFECT_RADIUS_SCALE = 3
const ENEMY_SPAWN_MULT = 3

const TIME_TO_WIN_IN_SECONDS = 20


export class RoamingWildlingsEventSystem implements GameplayEvent {

	static getInstance() {
		if (!RoamingWildlingsEventSystem.instance) {
			RoamingWildlingsEventSystem.instance = new RoamingWildlingsEventSystem()
		}

		return RoamingWildlingsEventSystem.instance
	}

	static destroy() {
		RoamingWildlingsEventSystem.instance = null
	}

	private static instance: RoamingWildlingsEventSystem

	protected eventStartData: EventStartData

	private eventIsActive: boolean
	private spawnPosition: Vector = new Vector()
	private playerInZone: boolean
	private timeAcc: number
	private endTime: timeInSeconds
	private petOptions: PetCollectionName[] = ['pet-cat', 'pet-crystal', 'pet-horse', 'pet-plant', 'pet-rot-son']
	private activePet: WildPet
	private auraEffectConfig: EffectConfig
	private auraEffect: Effect
	private heartEffectConfig: EffectConfig

	static petPools: Map<PetCollectionName, ObjectPoolTyped<WildPet, WildPetParams>>

	constructor() {
		this.makePetPools()
		this.eventIsActive = false
		this.activePet = new WildPet(this.petOptions.pickRandom())
		this.makePfx()
	}

	private makePetPools() {
		if (!RoamingWildlingsEventSystem.petPools){
			RoamingWildlingsEventSystem.petPools = new Map()

			this.petOptions.forEach(v => {
				const objectPool = new ObjectPoolTyped<WildPet, WildPetParams>(() => {
						return new WildPet(v)
				}, {}, 2, 1, `roaming-${v}`)

				RoamingWildlingsEventSystem.petPools.set(v, objectPool)
			})
		}
	}

	private getRandomPet(params: WildPetParams) {
		const petName = this.petOptions.pickRandom()
		const pool = RoamingWildlingsEventSystem.petPools.get(petName)
		const pet = pool.alloc(params)
		pet.pool = pool
		return pet
	}

	update(delta: timeInSeconds) {
		if (this.eventIsActive) {
			if (this.activePet.distanceFromPlayer2 <= PET_TAMING_DISTANCE_2) {
				this.timeAcc += delta
				if (!this.playerInZone) {
					this.onPlayerEnterZone()
				}
			}
			else {
				this.timeAcc = 0
				if (this.playerInZone) {
					this.onPlayerLeaveZone()
				}
			}

			if (this.timeAcc >= TIME_TO_WIN_IN_SECONDS) {
				this.endEvent(true)
			} else if (InGameTime.timeElapsedInSeconds >= this.endTime) {
				this.endEvent(false)
			}
		}
	}

	makePfx() {
		this.heartEffectConfig =  AssetManager.getInstance().getAssetByName('hearts-explosion').data
		
		this.auraEffectConfig = AssetManager.getInstance().getAssetByName('roaming-wildlings-pfx').data
		const renderer = Renderer.getInstance()
		const cam = renderer.cameraState
		this.auraEffect = new Effect(this.auraEffectConfig, cam)
		this.auraEffect.scale = AURA_EFFECT_RADIUS_SCALE
	}

	showHeartPfx() {
		Renderer.getInstance().addOneOffEffectByConfig(this.heartEffectConfig, this.activePet.position.x, this.activePet.position.y, 99999)
	}

	showAuraPFX() {
		this.auraEffect.x = this.activePet.position.x
		this.auraEffect.y = this.activePet.position.y
		Renderer.getInstance().bgRenderer.addEffectToScene(this.auraEffect)
	}

	hideAuraPFX() {
		Renderer.getInstance().bgRenderer.removeFromScene(this.auraEffect)
	}

	setStartData(data: EventStartData) {
		this.eventStartData = data
	}

	startEvent(): void {
		this.eventIsActive =  true
		const timeLimit = this.eventStartData.timeLimit ?? DEFAULT_TIME_LIMIT
		this.endTime =  InGameTime.timeElapsedInSeconds + timeLimit
		const minDist = Math.max(MIN_SPAWN_DISTANCE_FROM_PLAYER, PET_TAMING_DISTANCE + 300)
		const maxDist = Math.max(MAX_SPAWN_DISTANCE_FROM_PLAYER, minDist)
		PropPlacer.getInstance().getRandomValidPositionInWorld(minDist, maxDist, PET_TAMING_DISTANCE, this.spawnPosition)

		this.activePet = this.getRandomPet({
			spawnX: this.spawnPosition.x,
			spawnY: this.spawnPosition.y,
			movementBehavior: 'wander',
			movementSpeed: MOVEMENT_SPEED,
			maxDistanceFromPlayer: maxDist,
			maxWanderTime: 9_999
		})
	}

	onPlayerEnterZone() {
		this.showAuraPFX()
		attachments_addAttachment(this.auraEffect, this.activePet)
		EnemyEquilibriumSpawner.getInstance().temporarySpawnRateMulti += ENEMY_SPAWN_MULT
		this.playerInZone = true
		/* Renderer.getInstance().drawCircle({
			x: this.activePet.x,
			y: this.activePet.y,
			radius: PET_TAMING_DISTANCE,
			permanent: false,
			destroyAfterSeconds: 2,
			color: 0xFF0000,
			scale: 1
		}) */
	}

	onPlayerLeaveZone() {
		this.hideAuraPFX()
		attachments_removeAttachments(this.auraEffect)
		EnemyEquilibriumSpawner.getInstance().temporarySpawnRateMulti -= ENEMY_SPAWN_MULT
		this.playerInZone = false
	}

	endEvent(eventSuccess: boolean) {
		this.eventIsActive = false
		if(eventSuccess) {
			this.showHeartPfx()
			this.onPlayerLeaveZone()
			this.activePet.joinPlayerPets()
		}
		this.activePet.despawn()
		GameplayTimedEventSystem.getInstance().onEventEnd(EventTypes.RoamingWildlings)
	}

}