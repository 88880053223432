<template>
    <div class="container" @click="leaveScreen" :style="{ transform: `perspective(1px) translateZ(0) scale(${responsiveScale})`, 'transform-origin': `center`, height: `${(1/responsiveScale)*100}%`, width: `${(1/responsiveScale)*100}%` }">
        <div class="top-section">
            <div class="ooi-box">
                <div class="header">
                    DEVELOPED & PUBLISHED BY
                </div>
                <br>
                <img src="../../../assets/ui/logos/end-credits-OOI-logo.png" style="width: 250px; height: 215.6px;"/>
            </div>
            <div class="diebrary-box">
                <img src="../../../assets/ui/logos/diebrary.png" style="width: 550px; height: 214.4px;"/>
                <img src="../../../assets/ui/backgrounds/end-credits-flower.png" style="width: 58px; height: 68px;"/>
            </div>
            <div class="tlp-box">
                <div class="header" >
                    WITH SUPPORT BY
                </div>
                <div class="content" >
                    <img src="../../../assets/ui/logos/end-credits-TLP-logo.png" style="width: 250px; height: 260px;"/>
                    <div class="text-container">
                        <div class="header-text">
                            Consultant
                        </div>
                        <div class="name-text">
                            Tyler Noiles
                        </div>
                        <div class="header-text">
                            UI/UX Consultant
                        </div>
                        <div class="name-text">
                            Jon Beals
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="main-section">
            <div class="column">
                <div class="header-text">
                    Chief Executive Officer
                </div>
                <div class="name-text">
                    Andrew Ayre
                </div>
                <div class="header-text">
                    Head of Canadian Operations
                </div>
                <div class="name-text">
                    Deirdre Ayre
                </div>
                <div class="header-text">
                    Chief Financial Officer
                </div>
                <div class="name-text">
                    Jodi McLellan
                </div>
                <div class="header-text">
                    Chief Technology Officer
                </div>
                <div class="name-text">
                    Gordon Wood
                </div>
                <div class="header-text">
                    Product Director
                </div>
                <div class="name-text">
                    Ryan Hale
                </div>
                <br>
                <div class="header-text">
                    Trenchcoat Producer
                </div>
                <div class="name-text">
                    Shelby Christie<br>
                    Daniel Eichler<br>
                    Angus Fletcher
                </div>
            </div>
            <div class="column">
                <div class="header-text">
                    Lead Programmer
                </div>
                <div class="name-text">
                    Angus Fletcher
                </div>
                <div class="header-text">
                    Senior Programmer
                </div>
                <div class="name-text">
                    Daniel Eichler
                </div>
                <div class="header-text">
                    Programmers
                </div>
                <div class="name-text">
                    Ben Enman<br>
                    David Greeley<br>
                    Callum MacKenzie
                </div>
                <br>
                <div class="header-text">
                    Lead Designer
                </div>
                <div class="name-text">
                    Daniel Eichler
                </div>
                <div class="header-text">
                    Designer
                </div>
                <div class="name-text">
                    Liam Peveril
                </div>
                <div class="header-text">
                    Associate Designers
                </div>
                <div class="name-text">
                    Angus Fletcher<br>
                    Callum MacKenzie
                </div>
            </div>
            <div class="column">
                <div class="header-text">
                    Art Director
                </div>
                <div class="name-text">
                    Ben Thwaites
                </div>
                <div class="header-text">
                    Lead Artist
                </div>
                <div class="name-text">
                    Shelby Christie
                </div>
                <div class="header-text">
                    Artists
                </div>
                <div class="name-text">
                    Cassidy Byrne<br>
                    Elina Tyshchenko
                </div>
                <div class="header-text">
                    UI/UX Artist
                </div>
                <div class="name-text">
                    David Greeley
                </div>
                <br>
                <div class="header-text">
                    Audio Engineer
                </div>
                <div class="name-text">
                    Bob Baffy<br>
                    Angus Fletcher
                </div>
                <br>
                <div class="header-text">
                    Marketing Manager
                </div>
                <div class="name-text">
                    Alicja Krzesz
                </div>
                <div class="header-text">
                    Business Development Manager
                </div>
                <div class="name-text">
                    Max Knechtel
                </div>
            </div>
            <div class="column">
                <div class="header-text">
                    Community Support/Management
                </div>
                <div class="name-text">
                    Victoria McLaughlin<br>
                    Gabriel Nordholm
                </div>
                <br>
                <div class="header-text">
                    QA Manager
                </div>
                <div class="name-text">
                    Kiefer Richardson
                </div>
                <div class="header-text">
                    QA Supervisor
                </div>
                <div class="name-text">
                    Matt Mackey
                </div>
                <div class="header-text">
                    QA Project Manager
                </div>
                <div class="name-text">
                    Neil Campbell
                </div>
                <div class="header-text">
                    QA Project Lead
                </div>
                <div class="name-text">
                    Jessie Duffy
                </div>
                <div class="header-text">
                    Lead Tester
                </div>
                <div class="name-text">
                    Collins Davis
                </div>
                <div class="header-text">
                    Testers
                </div>
                <div class="name-text">
                    Chelsea Cross<br>
                    Lauren Edwards<br>
                    Edric Ganga
                </div>
            </div>
            <div class="column">
                <div class="name-text">
                    Jarett Jackson<br>
                    Samuel MacKenzie<br>
                    Jesse Martin<br>
                    Evan McLeod<br>
                    Cameron Munn<br>
                    Nell Richard<br>
                    Ryan Wagg
                </div>
                <br>
                <div class="header-text">
                    SPECIAL THANKS TO:
                </div>
                <div class="double-name-box">
                    <div class="name-container">
                        Ryan Boyce<br>
                        Brenden Doyle<br>
                        Natalie Eichler<br>
                        Crystal Eichler<br>
                        Jake Emmons<br>
                        Joe Emmons<br>
                        Kyle Engan<br>
                        Harry Enman<br>
                        Travis Facette
                    </div>
                    <div class="name-container">
                        Celine Gorham<br>
                        Matthew Jones<br>
                        Julia Pagenkopf<br>
                        Kendra Peveril<br>
                        Olivia Ramsay<br>
                        Bryan Sharpley<br>
                        Sam Stiles<br>
                        Colin Wallace<br>
                        Cameron Young
                    </div>
                </div>
                <div class="header-text">
                    and YOU!
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
	name: 'Credits',
	components: {
	},
	data() {
	},
	computed: {
		...mapState('ui', ['previousView']),
		...mapState('uiScaling', ['responsiveScale']),
	},
	methods: {
		...mapActions('ui', ['changeActiveView']),
		...mapMutations('ui', ['registerKeystrokeHandler', 'updatePreviousHandler']),
		leaveScreen() {
			this.changeActiveView(this.previousView)
		}
	},
	mounted () {
		this.updatePreviousHandler()
		const callBacks = [
			{
				keys: ['Escape', 'Enter', 'Space'],
				callBack: this.leaveScreen.bind(this)
			}
		] 
		this.registerKeystrokeHandler(callBacks)
	}
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .top-section {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        height: max-content;

        .ooi-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .header {
			    .regularFont(@enRegularFontPixl);
                color: @green;
                font-size: 24px;
				font-variant: small-caps;

                text-shadow: 3px 3px #000000;
            }

            width: 400px;
        }

        .diebrary-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 400px;
        }

        .tlp-box {
            .header {
                .regularFont(@enRegularFontPixl);
                color: @green;
                font-size: 24px;
                font-variant: small-caps;
                text-align: center;

                text-shadow: 3px 3px #000000;
            }

            .content {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;

                .text-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            width: 400px;
        }
    }

    .main-section {
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;

        .column {
            width: 350px;

            .double-name-box {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .name-container {
                    .regularFont(@enRegularFontPixl);
                    color: @cream;
                    font-size: 32px;
				    font-variant: small-caps;
                    text-align: center;
                    letter-spacing: 1px;

                    text-shadow: 3px 3px #000000;
                }
            }
        }
    }
}

.header-text {
    margin-top: 10px;

    .regularFont(@enRegularFontPixl);
    color: @green;
    font-size: 40px;
    font-variant: small-caps;
    text-align: center;

    text-shadow: 3px 3px #000000;
    text-overflow: nowrap;
    white-space: nowrap;
}

.name-text {
    .regularFont(@enRegularFontPixl);
    color: @cream;
    font-size: 32px;
    font-variant: small-caps;
    text-align: center;
    letter-spacing: 1px;

    text-shadow: 3px 3px #000000;
}
</style>
