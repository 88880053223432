import EntityStatList from "../../../stats/entity-stat-list"
import { SkillWeapon } from "../../skill-weapon"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { AllWeaponTypes } from "../../weapon-types"
import { Beam, DEFAULT_BEAM_COLLIDER_CONFIG, makeBeamPool } from "../../../beams/beams"
import { Player } from "../../../entities/player"
import { BoxColliderConfig } from "../../../engine/collision/colliders"
import { angleOfVector, degToRad } from "../../../utils/math"
import { degrees, radians, timeInSeconds } from "../../../utils/primitive-types"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { Enemy } from "../../../entities/enemies/enemy"
import { getDamageByPlayerLevel } from "../../../game-data/player-formulas"
import { getPoisonStacks } from "../../../buffs/generic-buff-definitions"
import { Buff } from "../../../buffs/buff"
import { BuffIdentifier } from "../../../buffs/buff.shared"
import { GroundPickup } from "../../../entities/pickups/ground-pickup"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { AssetManager } from "../../../web/asset-manager"
import { ProjectileSystem } from "../../../projectiles/projectile-system"
import { InstancedSpriteSheetAnimator } from "../../../engine/graphics/instanced-spritesheet-animator"
import { Vector } from "sat"
import { GroundPickupType } from "../../../entities/pickups/ground-pickup-types"
import { GameState } from "../../../engine/game-state"
import { Audio } from "../../../engine/audio"

const BASE_ATTACK_WIDTH = 220
const BASE_ATTACK_LENGTH = 550
const FRAME_ONE_LENGTH = 75
const CONE_ANGLE = 105
const BEAM_GROW_SPEED = 1_500 // units per second

const MAX_GFX_COUNT = 18
const GFX_BASE_SCALE = 0.5
const NO_POISON_ANIM_NAME = 'pulsa-shock-pulse-shock'
const POISON_ANIM_NAME = 'pulsa-shock-pulse-shock-poison'

export class RadarSkillWeapon extends SkillWeapon {
    weaponType: AllWeaponTypes = AllWeaponTypes.RadarSkill

    poisonEnemiesOnHit: boolean

    private hitboxBeam: Beam
    private timeSinceFired: timeInSeconds

    private beamLength: number
    private beamWidth: number
    private beamAngle: radians

    private finishAttackNextFrame: boolean

    private coneAngle: number
    private isBoxCollider: boolean

    private gfx: InstancedSpriteSheetAnimator[]
    private activeGfxCount: number
    private gfxPosition: Vector

    constructor(player: Player, statList: EntityStatList) {
        super(player, statList)

        makeBeamPool()
        // in hindsight after seeing the gfx this should have used projectiles
        this.hitboxBeam = Beam.pool.alloc({
            x: player.x,
            y: player.y,
            angle: player.aimAngle,
            width: BASE_ATTACK_WIDTH,
            length: 0,
            maxLength: 0,
            
            noGfx: true,
            noCollisions: true, // we add & remove the colliders ourselves
            noDoubleStrike: true,

            onHitCallback: this.onHitEnemy.bind(this),

            statList: this.statList,
            weaponType: this.weaponType
        })

        // now make it a cone
        this.coneAngle = CONE_ANGLE

        const minAngleColliderConfig = {} as any as BoxColliderConfig
        const maxAngleColliderConfig = {} as any as BoxColliderConfig
        const minQuarterAngleColliderConfig = {} as any as BoxColliderConfig
        const maxQuarterAngleColliderConfig = {} as any as BoxColliderConfig
        Object.assign(minAngleColliderConfig, DEFAULT_BEAM_COLLIDER_CONFIG)
        Object.assign(maxAngleColliderConfig, DEFAULT_BEAM_COLLIDER_CONFIG)
        Object.assign(minQuarterAngleColliderConfig, DEFAULT_BEAM_COLLIDER_CONFIG)
        Object.assign(maxQuarterAngleColliderConfig, DEFAULT_BEAM_COLLIDER_CONFIG)
        
        const halfAngle = degToRad(CONE_ANGLE / 2)
        const quarterAngle = halfAngle / 2
        minAngleColliderConfig.angle = halfAngle
        minQuarterAngleColliderConfig.angle = quarterAngle
        maxAngleColliderConfig.angle = -halfAngle
        maxQuarterAngleColliderConfig.angle = -quarterAngle

        this.hitboxBeam.addColliders([minAngleColliderConfig, maxAngleColliderConfig, minQuarterAngleColliderConfig, maxQuarterAngleColliderConfig])

        this.isBoxCollider = false
        this.poisonEnemiesOnHit = false
        this.gfxPosition = new Vector()

        this.makeGfx()
    }

    protected useSkill(): void {
        // shoot a cone (BEAM) based on the player's movement direction
        // enemies get knocked back
        const attackSize = this.statList.getStat(StatType.attackSize)
        this.beamAngle = this.player.aimAngle

        this.beamLength = attackSize * BASE_ATTACK_LENGTH
        this.beamWidth = attackSize * BASE_ATTACK_WIDTH

        // how to make the thing expand without double hitting ? 
        // have to make changes to beam code

        this.hitboxBeam.position.copy(this.player.position)
        if (this.isBoxCollider) {
            this.hitboxBeam.setColliderProperties(FRAME_ONE_LENGTH, this.beamLength, this.beamAngle)
        } else {
            this.hitboxBeam.setColliderProperties(FRAME_ONE_LENGTH, BASE_ATTACK_WIDTH, this.beamAngle)
        }
        this.hitboxBeam.addColliderToScene()
        // this.hitboxBeam.colliderComponent.drawColliders()

        this.timeSinceFired = 0
        this.finishAttackNextFrame = false

        this.activateGfx()

        Audio.getInstance().playSfx('SFX_Radar_Skill');
    }
    
    update(delta: number) {
        if (this.hitboxBeam.isColliderInScene) {
            this.timeSinceFired += delta

            if (this.hitboxBeam.firedLastFrame) {
                if (this.finishAttackNextFrame) {
                    this.hitboxBeam.removeColliderFromScene()
                    // this.hitboxBeam.colliderComponent.stopDrawingColliders()
                    this.hitboxBeam.alreadyHitEntities.clear()
                    for (let i = 0; i < this.activeGfxCount; ++i) {
                        this.gfx[i].removeFromScene()
                    }
                } else {
                    const unclampedLength = FRAME_ONE_LENGTH + (this.timeSinceFired * BEAM_GROW_SPEED)
                    let beamFinalLength: number
                    if (unclampedLength >= this.beamLength) {
                        beamFinalLength = this.beamLength
                        this.finishAttackNextFrame = true
                    } else {
                        beamFinalLength = unclampedLength
                    }

                    if (this.isBoxCollider) {
                        this.hitboxBeam.setColliderProperties(beamFinalLength, this.beamLength, this.beamAngle)
                    } else {
                        this.hitboxBeam.setColliderProperties(beamFinalLength, this.beamWidth, this.beamAngle)
                    }
                    
                    // this.hitboxBeam.colliderComponent.stopDrawingColliders()
                    // this.hitboxBeam.colliderComponent.drawColliders()

                    this.updateGfx(beamFinalLength)
                }
            } else {
                const unclampedLength = FRAME_ONE_LENGTH + (this.timeSinceFired * BEAM_GROW_SPEED)
                let beamFinalLength: number
                if (unclampedLength >= this.beamLength) {
                    beamFinalLength = this.beamLength
                } else {
                    beamFinalLength = unclampedLength
                }

                this.updateGfx(beamFinalLength)
            }
        }
    }
    
    resetStatsFunction(statList: EntityStatList) {
        defaultStatAttribute(statList)

        statList._actualStatValues.attackSize = 1
        statList._actualStatValues.attackRate = 99999
        statList._actualStatValues.attackKnockback = 2_500 // idk
        statList._actualStatValues.maxAmmo = 1
        statList._actualStatValues.reloadAmmoIncrement = 1
        statList._actualStatValues.cooldownInterval = 500
        statList._actualStatValues.reloadInterval = 12_000
        statList._actualStatValues.attackPierceCount = 999_999
    }
    
    expandConeCollider(degrees: number) {
        const extraDegrees = this.coneAngle + degrees
        if (extraDegrees < 180) {
            const halfAngle = degToRad(extraDegrees / 2)

            const minColliderConfig = {} as any as BoxColliderConfig
            const maxColliderConfig = {} as any as BoxColliderConfig
            Object.assign(minColliderConfig, DEFAULT_BEAM_COLLIDER_CONFIG)
            Object.assign(maxColliderConfig, DEFAULT_BEAM_COLLIDER_CONFIG)

            minColliderConfig.angle = halfAngle
            maxColliderConfig.angle = -halfAngle

            this.hitboxBeam.addColliders([minColliderConfig, maxColliderConfig])
        
            this.coneAngle = extraDegrees
        } else {
            this.makeColliderBox()
        }
    }

    contractConeCollider(degrees: degrees) {
        // for debug only
        this.coneAngle -= degrees
        if (this.coneAngle < 180) {
            this.isBoxCollider = false

            this.hitboxBeam.revertColliders() 
            
            const minAngleColliderConfig = {} as any as BoxColliderConfig
            const maxAngleColliderConfig = {} as any as BoxColliderConfig
            const minQuarterAngleColliderConfig = {} as any as BoxColliderConfig
            const maxQuarterAngleColliderConfig = {} as any as BoxColliderConfig
            Object.assign(minAngleColliderConfig, DEFAULT_BEAM_COLLIDER_CONFIG)
            Object.assign(maxAngleColliderConfig, DEFAULT_BEAM_COLLIDER_CONFIG)
            Object.assign(minQuarterAngleColliderConfig, DEFAULT_BEAM_COLLIDER_CONFIG)
            Object.assign(maxQuarterAngleColliderConfig, DEFAULT_BEAM_COLLIDER_CONFIG)
            
            const halfAngle = degToRad(CONE_ANGLE / 2)
            const quarterAngle = halfAngle / 2
            minAngleColliderConfig.angle = halfAngle
            minQuarterAngleColliderConfig.angle = quarterAngle
            maxAngleColliderConfig.angle = -halfAngle
            maxQuarterAngleColliderConfig.angle = -quarterAngle

            this.hitboxBeam.addColliders([minAngleColliderConfig, maxAngleColliderConfig, minQuarterAngleColliderConfig, maxQuarterAngleColliderConfig])

            const angleDiff = this.coneAngle - CONE_ANGLE
            this.coneAngle = CONE_ANGLE
            this.expandConeCollider(angleDiff)
        }
    }

    makeColliderBox() {
        if (!this.isBoxCollider) {
            this.isBoxCollider = true

            this.hitboxBeam.revertColliders()
        }
    }

    onHitEnemy(enemy: Enemy) {
        if (enemy.isDead()) {
            return
        }

        if (this.poisonEnemiesOnHit) {
            const levelDamage = getDamageByPlayerLevel()
            const stacks = getPoisonStacks(levelDamage)

            Buff.apply(BuffIdentifier.Poison, this, enemy, stacks)
        }
    }

    setBeamCollectsPickups(collect: boolean) {
        if (collect) {
            this.hitboxBeam.onPickupHitCallback = this.onHitPickup.bind(this) 
            this.hitboxBeam.changeCollisionLayer(CollisionLayerBits.EnemyAndPickup)
        } else {
            this.hitboxBeam.onPickupHitCallback = null
            this.hitboxBeam.changeCollisionLayer(CollisionLayerBits.PlayerProjectile)
        }
    }

    onHitPickup(pickup: GroundPickup) {
		if (pickup.pickupType === GroundPickupType.Healing) {
			if (GameState.player.currentHealth === GameState.player.lastMaxHealth && !GameState.player.binaryFlags.has('hearts-explosion')) {
				return
			}
		}

        pickup.onPickedUp(this.player)
    }

    private makeGfx() {
        const sheet = AssetManager.getInstance().getAssetByName('pulse-shock').spritesheet

        this.gfx = []
        for (let i = 0; i < MAX_GFX_COUNT; ++i) {
            this.gfx[i] = new InstancedSpriteSheetAnimator(sheet, NO_POISON_ANIM_NAME, 0.78)
            this.gfx[i].setAnchor(1, 0)
            this.gfx[i].scale.x = GFX_BASE_SCALE
            this.gfx[i].scale.y = GFX_BASE_SCALE
        }
    }

    private activateGfx() {
        this.activeGfxCount = Math.floor(this.coneAngle / 15)
        const animName = this.poisonEnemiesOnHit ? POISON_ANIM_NAME : NO_POISON_ANIM_NAME

        for (let i = 0; i < this.activeGfxCount; ++i) {
            const gfxAngle = this.beamAngle + ProjectileSystem.projectileSpreadAngle(i, this.activeGfxCount, this.coneAngle)
            this.gfx[i].position.copy(this.hitboxBeam.position)
            this.gfx[i].rotation = gfxAngle
            this.gfx[i].addToScene()
            this.gfx[i].playAnimation(animName)
        }
    }

    private updateGfx(beamLength: number) {
        for (let i =0; i < this.activeGfxCount; ++i) {
            this.gfxPosition.x = beamLength
            this.gfxPosition.y = 0
            this.gfxPosition.rotate(this.gfx[i].rotation)
            this.gfxPosition.add(this.hitboxBeam.position)

            this.gfx[i].position.copy(this.gfxPosition)
        }
    }

    protected canUseSkill(): boolean {
        return true
    }
}
