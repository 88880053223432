import { GameState } from "../engine/game-state"
import AISystem from "../entities/enemies/ai-system"
import EnemyEquilibriumSpawner from "../entities/enemies/enemy-equilibrium-spawner";
import { EventTypes } from "../events/event-types"
import { GameplayTimedEventSystem } from "../events/gameplay-timed-event-system"
import { PetRescueEventSystem } from "../events/pet-rescue-gameplay-event"
import { StatOperator, StatType } from "../stats/stat-interfaces-enums"
import { debugConfig } from "../utils/debug-config";
import { MapConfig, MapConfigRecord, MapNames, MapOption, mapNames, stringNameToMapOption } from "./world-data";

export class MapSystem {
    static getInstance(): MapSystem{
        if(!MapSystem.instance){
            MapSystem.instance = new MapSystem()
        }
        return this.instance
     }
   	private static instance: MapSystem

    mapType: MapOption = MapOption.Forest
	mapName: MapNames = mapNames.FOREST

    constructor(){
    }

    setMap(mapName: MapNames){
		let mapOption = stringNameToMapOption(mapName)
        
		if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
			if (debugConfig.enableOverrideMap) {
				this.mapType = debugConfig.overrideMap
				this.mapName = debugConfig.overrideMap
				return
			}

			const searchParams = new URLSearchParams(window.location.search)
			if (searchParams.has('map')) {
				this.mapType = searchParams.get('map') as MapOption
				this.mapName = this.mapType
				return
			}
		}
		this.mapType = mapOption
		this.mapName = mapName
		console.log(`Map: ${this.mapType}`)
	}

    getMapConfig(): MapConfig {
		return MapConfigRecord[this.mapType]
	}

	applyMapEffects() {
		const player = GameState.player
		PetRescueEventSystem.getInstance().enemyKillAmountBonus += 0.33

		if (this.mapType === MapOption.Forest) {
			player.stats.addStatBonus(StatType.ignitePotency, StatOperator.SUM, 0.2)
			PetRescueEventSystem.getInstance().enemyKillAmountBonus -= 0.25
			GameplayTimedEventSystem.getInstance().addEventDefinition(
				EventTypes.ForestPlants,
				{
					excludedBy: [],
					coolDown: 0,
					spawnWindow: [
						{
							min: 50,
							max: 50
						}
					],
					frequency: 0,
					maxConcurrent: 1,
					timeLimit: undefined
				}
			)
			
			AISystem.getInstance().xpEmptyCountBonusPercent += 0.20
			AISystem.getInstance().repopulateXpShuffleBag()
		} else if (this.mapType === MapOption.Tundra) {
			player.binaryFlags.add('mountain-climber')
		}
	}
}