<template>
    <div class="story-more-info">
        <div :class="map"></div>
        <div :class="difficulty"></div>
        <div :class="{ 'difficulty-text': true, [difficultyColor]: true }" :lang="this.$i18n.locale">{{ $t(difficultyText) }}</div>
    </div>
</template>

<script lang="ts">
import { StoryDifficulty } from '../../game-data/difficulties'

export default {
    name: 'StoryDifficultyMap',
    props: {
        difficulty: {
            type: String,
            required: true
        },
        map: {
            type: String,
            required: true
        }
    },
    computed: {
        difficultyText() {
			switch (this.difficulty) {
				case StoryDifficulty.Normal:
					return 'story_select.normal'
				case StoryDifficulty.Hard: 
					return 'story_select.hard'
				case StoryDifficulty.Brutal:
					return 'story_select.brutal'
			}
		},
		difficultyColor() {
			switch (this.difficulty) {
				case StoryDifficulty.Normal:
					return 'normal-green'
				case StoryDifficulty.Hard: 
					return 'hard-orange'
				case StoryDifficulty.Brutal:
					return 'brutal-red'   
			}
		}
    }
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

.story-more-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;

    .forest {
        background-image: url('../../../assets/ui/story/small-forest-icon.png');
        width: 26px;
        height: 34px;

        margin-bottom: 3px;
        padding-right: 3px;
    }

    .tundra {
        background-image: url('../../../assets/ui/story/small-mountain-icon.png');
        width: 47px;
        height: 29px;

        margin-bottom: 3px;
        padding-right: 3px;
    }

    .normal {
        background-image: url('../../../assets/ui/story/small-difficulty-icon-normal.png');
        width: 26px;
        height: 32px;
    }

    .hard {
        background-image: url('../../../assets/ui/story/small-difficulty-icon-hard.png');
        width: 26px;
        height: 32px;
    }

    .brutal {
        background-image: url('../../../assets/ui/story/small-difficulty-icon-brutal.png');
        width: 26px;
        height: 32px;
    }

    .difficulty-text {
        margin-left: 10px;

        &[lang='en'] {
            .regularFont(@enRegularFontPixl);
            font-size: 30px;
        }
        &[lang='zh'] {
            .regularFont(@zhHansHeaderFont);
        }

        &.normal-green {
            color: #0dd14e;
        }

        &.hard-orange {
            color: #d07812;
        }

        &.brutal-red {
            color: #ff0000;
        }
    }
}
</style>