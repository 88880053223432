import { Vector } from 'sat'
import { ColliderType, updateColliderPositions } from '../../engine/collision/colliders'
import { SpatialGridCell } from '../../engine/collision/spatial-entity'
import EntityStatList, { BossEnemyGlobalStatList, CommonEnemyGlobalStatList, EnemyGlobalStatList, UncommonEnemyGlobalStatList } from '../../stats/entity-stat-list'
import { chanceToLoops, distanceSquaredVV, getRandomPointInCircleRange, mapToRange, midpoint, randomRange, throwIfNotFinite, withinDistanceVV } from '../../utils/math'
import { degrees, percentage, radians, timeInMilliseconds, timeInSeconds } from '../../utils/primitive-types'
import { GAMEPLAY_SPEED_MULTIPLIER, InGameTime, waitUntil } from '../../utils/time'
import { DamageableEntityType, EntityType, IEntity } from '../entity-interfaces'
import { PLOT_TWIST_INTELLIGENCE_DUMP, Player } from '../player'
import { GameState, getNID } from '../../engine/game-state'
import { StatName, StatType } from '../../stats/stat-interfaces-enums'
import { EnemyGFX } from './enemy-gfx'
import { AIStates, AttackTypes, AttackWithCooldown, DeadBehaviours, EnemeyRageConfig, EnemyAI, EnemyAIBaseStats, EnemyType, FightingBehaviours, LerpSettings, MovementStrategy } from './ai-types'
import { fightingBehaviours, FightingSubstate } from './behaviours/fighting'
import { deadBehaviours } from './behaviours/dead'
import { ColliderComponent } from '../../engine/collision/collider-component'
import CollisionSystem from '../../engine/collision/collision-system'
import { PlayerProjectile } from '../../projectiles/projectile'
import { Renderer } from '../../engine/graphics/renderer'
import { ObjectPoolTyped, PoolableObject } from '../../utils/third-party/object-pool'
import { BOSS_ENEMY_NAMES, BRUTE_TRIO_ENEMIES, CHAPTER_ENDING_BOSS_ENEMY_NAMES, COMMON_ENEMY_NAMES, ENEMY_NAME, UNCOMMON_ENEMY_NAMES } from './enemy-names'
import { ComponentOwner } from '../../engine/component-owner'
import { allocGroundPickup, getGroundPickupToDrop, GroundPickup, GroundPickupPoolType, GROUND_PICKUP_CONFIGS, magnetAllCurrency, splayGroundPickupsInRadius, GUARANTEED_HEART_DROP_MULTI } from '../pickups/ground-pickup'
import { Audio } from '../../engine/audio'
import { getFacingDirection } from '../../spine-config/aim-util'
import { Buff } from '../../buffs/buff'
import { AILMENT_DEBUFFS, BuffIdentifier } from '../../buffs/buff.shared'
import { DamageSource, getDamageFromDamageSource } from '../../projectiles/damage-source'
import { getDamageNumberAccumulatorDelay, spawnDamageNumber } from '../../damage-numbers/damage-numbers'
import { DamageNumberStyle } from '../../damage-numbers/damage.shared'
import { CHANCE_TO_DROP_PICKUP, NO_MORE_XP_TIME } from '../../game-data/levelling'
import { Container, Graphics } from 'pixi.js'
import AISystem, { MAGNET_SHAMBLER_HEALTH_MULTI, MAX_SHAMBLER_COUNT_FOR_MAGENET_SPAWN } from './ai-system'
import { Cooldown } from '../../cooldowns/cooldown'
import { CooldownSystem } from '../../cooldowns/cooldown-system'
import { BOSS_STUN_TIMESCALE, CANNON_CHILLED_SHOCK_DURATION, DEFAULT_STUN_DURATION, ENEMY_BOOST_RADIUS_BONUS, IBuffableEntity, LIGHTNING_STRIKES_TWICE_ANY_WEAPON_DAMAGE_SPLASH_RADIUS, LIGHTNING_STRIKES_TWICE_DAMAGE_MULT, LIGHTNING_STRIKES_TWICE_PRIMARY_WEAPON_DAMAGE_SPLASH_RADIUS, LONGBOW_STUN_DURATION, NOXIOUS_LEAK_DURATION, PIERCE_DAMAGE_PER_PIERCE_REMAINING_MULTI } from '../../buffs/buff-system'
import { defaultStatAttribute } from "../../game-data/stat-formulas"
import { Beam } from '../../beams/beams'
import { MASSIVE_DAMAGE_AMOUNT, MASSIVE_DAMAGE_BOSS_AMOUNT, PLAYER_ESTIMATED_MAX_COMMON_CURRENCY_PER_RUN, PLAYER_POISON_POOL_RADIUS, PLAYER_IGNITE_SPREAD_ON_DEATH_RADIUS, PLAYER_IGNITE_SPREAD_ON_DEATH_TARGETS, PLAYER_POISON_SPREAD_ON_DEATH_TARGETS, RADAR_WEALTHY_ENEMY_XP_AMOUNT, RADAR_WEALTHY_DROP_FORCE_MIN, RADAR_WEALTHY_DROP_FORCE_MAX, scrapyardWeightList, PLOT_TWIST_SPOOKY_GHOSTS_SUPERNATURAL_XP_DROP_CHANCE, IGNITE_EXPLODE_DAMAGE_MAX_HP_SCALE, IGNITE_EXPLODE_RADIUS, IGNITE_EXPLODE_PFX_SIZE, PLOT_TWIST_BIGGER_THEY_ARE_XP_DROP_AMOUNT } from '../../game-data/player-formulas'
import { AppliedBuffVisuals, clearAttachedPfx, handleBuffChange } from '../../buffs/buff-visuals'
import { fleeingBehaviours } from './behaviours/fleeing'
import { UI } from '../../ui/ui'
import PlayerMetricsSystem from '../../metrics/metric-system'
import { BARBARIAN_PASSIVE_BASE_STATS_DATA, getBarbarianPassiveSkillRange } from '../../weapons/actual-weapons/passives/barbarian-passive-skill'
import { CharacterType } from '../../game-data/characters'
import { AILMENT_TO_POTENCY_STAT, DAMAGE_OVER_TIME_SOURCE, getBleedStacks, getChillStacks, getIgniteStacks, getPoisonStacks, getStacksForAilment, triggerDoomExplosion, triggerPoisonPool as triggerPoisonPool, triggerBuffSpread as triggerBuffSpread, IGNITE_DURATION } from '../../buffs/generic-buff-definitions'
import { VictoryDeathManager } from '../../engine/victory-death-manager'
import { AllWeaponTypes } from '../../weapons/weapon-types'
import { NoxiousLeakHazard } from '../hazards/noxious-leak-hazard'
import { KillEnemiesInCirclePOI } from '../../pois/kill-enemies-in-circle'
import { LightningStrike } from '../lightning-strike'
import { EYE_OF_MERCY_AOE_SIZE, SAINTS_SPEAR_AOE_SIZE } from '../../weapons/actual-weapons/primary/spear-weapon'
import { CollisionLayerBits } from '../../engine/collision/collision-layers'
import { EffectConfig } from '../../engine/graphics/pfx/effectConfig'
import { AssetManager } from '../../web/asset-manager'
import { RARE_CURRENCY_DROP_CONFIG } from '../../game-data/currency'
import { callbacks_addCallback } from '../../utils/callback-system'
import { DEFAULT_AOE_EXPLOSION_DURATION, DEFAULT_AOE_EXPLOSION_PFX_SIZE, dealAOEDamageDamageSource, dealAOEDamageSimple, setExplosionColor } from '../../projectiles/explosions'
import { persistedBehaviours } from './behaviours/persisted'
import { AllEventTypes } from '../../events/event-stat-types'
import { onHitBehaviours } from './behaviours/on-hit'
import { debugtool } from '../../utils/decorators'
import { FOUNTAINS_OF_MANA_XP_DROP_CHANCE } from '../../events/fountains-of-mana-gameplay-event'
import { HolyLight } from '../holy-light'
import { Prop } from '../prop'
import { BuffDefinition } from '../../buffs/buff-definition'
import { EnemyMelee, EnemyMeleeParams } from '../../projectiles/enemy-melee-attack'
import { checkIfPointIsInView } from '../../engine/graphics/camera-logic'
import { GroundPickupConfigType } from '../pickups/ground-pickup-types'
import { MapSystem } from '../../world-generation/map-system'
import { debugConfig } from '../../utils/debug-config'
import { GOBLIN_XP_DROP_AMOUNT } from '../../events/goblin-gameplay-event'
import { DEFAULT_HUGE_SHAMBLING_MOUND_KNOCKBACK_RESIST, HUGE_SHAMBLING_MOUND_SCALE } from './enemy-configs/shambler-variants'
import { Cannon } from '../../weapons/actual-weapons/primary/cannon-weapon'
import EnemyEquilibriumSpawner from './enemy-equilibrium-spawner'

export interface EnemyInitialParams {
	x: number
	y: number
	isMerged?: boolean
}

const MAX_KNOCKBACK2 = 3_000 ** 2

export class Enemy implements IEntity, ComponentOwner, PoolableObject, IBuffableEntity /*, IProjectileShooter,*/ {
	entityType: EntityType = EntityType.Enemy
	nid: number
	timeScale: number = 1

	// Debug
	debugVisuals: Container
	colliderGraphics: Graphics
	colliderBoundsGraphics: Graphics

	readonly isEnemy: boolean = true

	// Movement
	get velocity() {
		return this._velocity
	}
	set velocity(value: Vector) {
		this._velocity = value
	}

	// Fighting
	maxHealth: number = 1
	get maxEnergy() {
		//TODO restore this when statList changes are through
		return 1 //this.statList.getStat('maxEnergy')
	}

	// Adding direct getters and setters for x and y for now
	set x(v) {
		this.position.x = v
	}
	get x() {
		return this.position.x
	}
	set y(v) {
		this.position.y = v
	}
	get y() {
		return this.position.y
	}

	get zIndex() {
		return this.gfx.activeAnimator.zIndex
	}

	get currentHealth(): number {
		return this._currentHealth
	}

	set currentHealth(value) {
		throwIfNotFinite(this._currentHealth, 'currentHealth1')
		this._currentHealth = value
		throwIfNotFinite(this._currentHealth, 'currentHealth2')
	}

	get visualAimAngle(): radians {
		return this._visualAimAngle
	}

	set visualAimAngle(v) {
		this._visualAimAngle = v
		//TODO BEN: Changing aim angle doesn't work with new spritesheets, gotta think of a better place to update the facing direction for rigged models

		/* const rotBone: PIXI.spine.core.Bone = this.gfx.riggedModel.skeleton.findBone('root-aim')
		updateAimFacing(this.gfx.riggedModel, this.facingDirection, true)
		if (rotBone) {
			updateAimRotation(rotBone, v + Math.PI, this.facingDirection)
		} */
	}

	baseHealth: number

	// Temp/Testing
	currentEnergy: number = 0
	fleeTimer?: number
	deathTimer?: number
	strafeDistance?: percentage // Percentage of screen width and height at which enemy should stay distanced from player
	groundItemDropStack: GroundPickupConfigType[] = []
	timeSinceLastDrop: number = 0
	hitsFromPlayerWeapon: number = 0
	isBoss = false
	isChoreoSpawn = false
	isEventSpawn = false
	toBeMerged = false
	isMerged = false
	bonusDropMult: number = 1
	ghosted: boolean

	// AI States
	enteredCurrentState: number = InGameTime.highResolutionTimestamp()

	// TODO Hotcakes: Contemplating moving this lil bag of state into fighting.ts and just making that a class of its own that can hold onto its own state
	substateData: {
		timeInSubstate: timeInSeconds,
		currentSubstate: FightingSubstate,
		lerpSettings?: LerpSettings
		lerpTimer?: timeInSeconds
		lerpIndex?: number,
		currentSpeed?: number
		savedVelocity?: Vector
		lastSpawnTime?: timeInSeconds
		meleeState?: string
	}

	currentStateValue: AIStates

	set currentState(value: AIStates) {
		this.currentStateValue = value
	}

	get currentState() {
		return this.currentStateValue
	}

	toBeDeleted: boolean

	name: ENEMY_NAME
	config: EnemyAI

	// Positional
	position: Vector
	spawnPosition: Vector
	knockbackVelocity: Vector = new Vector(0, 0)
	cell: SpatialGridCell
	velocityAngle: number = 0 // Will probably need this, in loot its only updated in fightingBehaviours
	//projectilesNearby: ServerProjectile[] = [] //Probably will need this later but we are leaving projectiles out for now
	//propsNearby: Set<ServerTerrainProp> = new Set() //DOn't need for now but probably adding props later
	facingDirection: number = 1 // 1 (facing right) or -1 (facing left)
	keepFacingDirection: boolean = false
	scale: number

	// Collision
	_velocity: Vector = new Vector(0, 0)

	useMovementOverride: boolean = false
	private movementDirectionOverride: Vector


	get movementSpeed(): number {
		return this.statList.getStat('movementSpeed')
	}
	
	decelerationRate: number
	knockbackDecelerationRate: number
	knockbackResist: number
	knockbackImmune: boolean
	turningRateInDegrees: degrees

	_currentHealth: number = 0
	damageTakenAmount: number = 0
	damageTakenAcc: timeInSeconds = 0

	immuneToRecycle: boolean

	private _parentStatList: EntityStatList 
	statList: EntityStatList
	buffs: Buff[] = []
	attachedBuffEffects: Map<BuffIdentifier, AppliedBuffVisuals> = new Map<BuffIdentifier, AppliedBuffVisuals>()

	// For allowing enemy to snap turn in certain circumstances
	quickTurnReady: boolean = true

	// TODO HOTCAkes previously target was ICombatant a union type of ServerPlayer and ServerEnemy
	// for now Player is sufficient but we may want this to be an abstract Entity class or a union type if enemies can target pets or clones of the player (if such a skill exists)
	target: Player = null
	shooting: boolean = false

	// TODO HOTCAKES: Fighting behaviours will probably have to be completely redefined to be more hoard-like
	fightingBehaviour: FightingBehaviours
	readonly fightingAttackType: AttackTypes

	// Dead
	deadBehaviour: DeadBehaviours
	corpseTimeoutInSeconds: number

	// Fighting
	movementStrategy: MovementStrategy
	cooldown?: Cooldown

	engagementMaxDistance: number
	engagementMinDistance: number
	engagementMaxDistance2: number
	engagementMinDistance2: number

	readonly modelCenterOffset: number

	movementMaxDistance: number
	movementMinDistance: number
	movementMaxDistance2: number
	movementMinDistance2: number

	// persisted
	persistedStates: any[]

	directionToPlayer: Vector = new Vector() // not normalized

	get distanceToPlayer2() {
		return this.distanceToPlayerSquared
	}
	private distanceToPlayerSquared: number
	private avoidanceRadiusSquared: number
	private speedBoostRadiusSquared: number

	// Any movement target other than the player
	targetPosition: Vector = new Vector()
	
	previousPos: Vector = new Vector(0, 0)

	get speedBoostRadius2() {
		return this.speedBoostRadiusSquared
	}

	aimVector: Vector = new Vector(0, 0)
	fixedAimVector: Vector = new Vector(0, 0)
	useFixedAimVector: boolean = false
	ignoreAngleOnWeaponOffset: boolean = false
	visualAimAngleSpeed: number = 0
	private _visualAimAngle: radians
	readonly visualAimLockSeconds: number
	//projectileConfig: ProjectileConfig //TODO HOTCAKES: Don't need this for now, but maybe once projectiles are in? Most enemies are probbably melee anyway so maybe much more simplified?
	//readonly shotLeadPrecision: ShotLeadPrecision

	gfx: EnemyGFX
	attackOffset: Vector
	zOffset: number

	private explosionEffectConfig: EffectConfig

	// Radius if this enemy explodes
	explosionRadius: number
	explosionDamageFromHealthMulti: percentage

	hasFinishedDeadBehaviour: boolean
	recursiveRespawnDisabled: boolean

	colliderComponent: ColliderComponent

	deathRewardRate: percentage
	currentRage?: EnemeyRageConfig
	rageIndex: number
	totalTimeAlive: timeInSeconds

	onKilled?: (enemy: Enemy) => void

	// wacky shit
	markedForMultiProjectile: boolean = false
	stackGoodBoyOnDeath: boolean = false
	acidBottleTicks: number = 0
	primaryWeaponDamageBonus: number = 1
	recycleTime: number
	offScreenTime: number = 0
	isLootGoblin: boolean
	isYeti: boolean
	isBasicShambler: boolean
	isSpecial: boolean
	isWealthy: boolean
	isFleeing: boolean = false
	isPet: boolean = false
	private nearbyMergeEnemies: Enemy[] = []
	static igniteExplodeEffectConfig: EffectConfig

	// TODO: Evaluate whether there can be targets aside from player (pets, clones of the player?)
	// restore enemyConfig type asap
	constructor(enemyConfig: EnemyAI, spawnPos: Vector) {

		this.config = enemyConfig
		this.name = enemyConfig.name
		this.isLootGoblin = this.name === ENEMY_NAME.LOOT_GOBLIN_JESTER
		this.isYeti = this.name === ENEMY_NAME.YETI
		this.isBasicShambler = (enemyConfig.baseVariant === ENEMY_NAME.SHAMBLING_MOUND && enemyConfig.name !== ENEMY_NAME.HUGE_SHAMBLING_MOUND) || enemyConfig.name === ENEMY_NAME.SHAMBLING_MOUND
		this.isBoss = BOSS_ENEMY_NAMES.includes(this.name)
		this.isSpecial = Boolean(this.config.baseAttributes.isSpecial)
		this.isPet = Boolean(this.config.baseAttributes.isPet)


		const { baseAttributes, states } = enemyConfig
		const { fighting, fleeing, dead } = states //TODO hotcakes: revisit these

		/* TODO integrate debug system later
		if (debugConfig.enemy.absolutelyUnderNoCircumstanceSpawnEnemies) {
			throw new Error(`absolutelyUnderNoCircumstanceSpawnEnemies is enabled but we're still creating enemies`)
		} */

		// TODO restore this assert once we've pulled getMainAppearance
		// console.assert(this.modelData, enemyConfig.name, getMainAppearance(enemyConfig))
		this.attackOffset = baseAttributes.attackOffset

		/**
		 * -- POSITIONAL --
		 */
		this.position = spawnPos

		/**
		 * -- COMBAT --
		 */

		this.target = null
		this.currentState = AIStates.FIGHTING

		/**
		 * -- COLLISION --
		 */
		this.colliderComponent = new ColliderComponent(baseAttributes.colliders, this, baseAttributes.colliderLayer, this.onCollision, false, baseAttributes.colliderIsKinematic)

		//baseAttributes.colliderIsKinematic

		/**
		 * -- STATS --
		 */
		this._parentStatList = getEnemyParentStatlist(enemyConfig.name)
		this.statList = attributesToStatList(enemyConfig.baseAttributes.baseStats, this._parentStatList)
		this.statList.getStat('movementSpeed') // force a recalc
		this.deathRewardRate = baseAttributes.deathRewardRate || 1.0
		/**
		 * -- MOVEMENT --
		 */
		// Movement speed handled by the getter
		// How fast does this AI slow down
		this.decelerationRate = baseAttributes.decelerationRate
		this.knockbackDecelerationRate = baseAttributes.knockbackDecelerationRate
		this.knockbackResist = this.getStat(StatType.knockbackResist)
		this.knockbackImmune = Boolean(this.config.baseAttributes.knockbackImmune)
		// How fast does this AI turn
		this.turningRateInDegrees = baseAttributes.turningRatePerSecondInDegrees

		this.movementDirectionOverride = new Vector()

		/**
		 *  --FIGHTING SETTINGS --
		 */

		// What this AI should do while fighting
		this.fightingBehaviour = fighting.movementStrategy.behaviour
		// What type of attack should this AI do when it attacks
		this.fightingAttackType = fighting.attackConfig.attackType
		if (fighting.attackConfig.attackType === AttackTypes.PROJECTILE || fighting.attackConfig.attackType === AttackTypes.GROUND_HAZARD || fighting.attackConfig.attackType === AttackTypes.MELEE ) {
			this.cooldown = new Cooldown(undefined, fighting.attackConfig.cooldownDef)
			CooldownSystem.getInstance().addCooldown(this.cooldown)
		}
		if (fighting.attackConfig.attackType === AttackTypes.MELEE) {
			if(!EnemyMelee.pool) {
				EnemyMelee.pool = new ObjectPoolTyped<EnemyMelee, EnemyMeleeParams>(() => new EnemyMelee(), {}, 1, 1)
			}
		}

		// Movement behavior and any necessary parameters that define that behavior
		this.movementStrategy = fighting.movementStrategy
		// At what distance should this AI perform its attacks
		this.engagementMaxDistance = fighting.engagementMaxDistance
		this.engagementMinDistance = fighting.engagementMinDistance

		this.engagementMaxDistance2 = fighting.engagementMaxDistance ** 2
		this.engagementMinDistance2 = fighting.engagementMinDistance ** 2

		this.modelCenterOffset = fighting.modelCenterOffset === undefined ? 0 : fighting.modelCenterOffset

		this.movementMaxDistance = fighting.movementMaxDistance
		this.movementMinDistance = fighting.movementMinDistance

		this.movementMaxDistance2 = fighting.movementMaxDistance ** 2
		this.movementMinDistance2 = fighting.movementMinDistance ** 2

		this.substateData = {
			timeInSubstate: 0,
			currentSubstate: FightingSubstate.None,
		}

		/**
		 *  -- FLEEING SETTINGS --
		 */

		this.fleeTimer = fleeing ? fleeing.timeToFlee : undefined

		/**
		 * -- DEAD SETTINGS --
		 */
		// What this AI should do while dead
		this.deadBehaviour = dead.behaviour
		// How long should this AI stick aronud after dead (in seconds) before being deleted
		this.corpseTimeoutInSeconds = dead.corpseTimeoutInSeconds
		// AI explosion radius
		this.explosionRadius = dead.explosionRadius || 0
		this.explosionDamageFromHealthMulti = dead.explosionDamage || 0

		// If this AI is configured to fire a projectile for its attack...
		/* 		TODO work this in when we integrate projectiles
		if (this.fightingAttackType === AttackTypes.PROJECTILE) {
					this.attackOptions = fighting.attackOptions
					this.shotLeadPrecision = fighting.shotLeadPrecision
					this.visualAimLockSeconds = fighting.visualAimLockSeconds
		
					this.onFiredProjectile()
				} */

		this.explosionEffectConfig = AssetManager.getInstance().getAssetByName('aoe-explosion-white').data

		this.gfx = new EnemyGFX(this)
		this.gfx.useIdleAnimation = this.config.appearance.useIdleAnim
		this.zOffset = this.config.appearance.zOffset ?? 0

		this.visualAimAngle = 0
		this.totalTimeAlive = 0
		this.rageIndex = 0

		this.immuneToRecycle = Boolean(this.config.baseAttributes.immuneToRecycle)

		if (this.config.states.persisted) {
			this.persistedStates = []
			for (let i = 0; i < this.config.states.persisted.length; ++i) {
				this.persistedStates.push({})
			}
		}

		this.bonusDropMult = baseAttributes.bonusDropMult ?? 1
	}

	applyVisualBuff(buffId: BuffIdentifier, stacks?: number) {
		handleBuffChange(this, buffId, false)
	}
	
	removeVisualBuff(buffId: BuffIdentifier, stacks?: number) {
		handleBuffChange(this, buffId, true)
	}

	setDefaultValues(defaultValues: any, overrideValues?: EnemyInitialParams) {
		if (overrideValues) {
			this.nid = getNID(this)

			this.maxHealth = this.statList.getStat(StatType.maxHealth)
			this.currentHealth = this.maxHealth
			this.baseHealth = this.maxHealth
			this.knockbackResist = this.getStat(StatType.knockbackResist)
			this.currentState = AIStates.FIGHTING
			this.target = GameState.player
			this.toBeDeleted = false
			this.deathRewardRate = this.config.baseAttributes.deathRewardRate || 1.0
			this.recycleTime = 0
			this.toBeMerged = false
			this.isMerged = Boolean(overrideValues.isMerged)
			this.bonusDropMult = 1

			this.position.x = overrideValues.x
			this.position.y = overrideValues.y
			this.colliderComponent.previousPosition.x = overrideValues.x
			this.colliderComponent.previousPosition.y = overrideValues.y

			this.isEventSpawn = false
			this.isChoreoSpawn = false
			// console.log(`${this.name} isBoss ${this.isBoss}`)

			this.gfx.resetModel()

			this.setDirectionToPlayer()

			const xDiffSign = Math.sign(this.directionToPlayer.x)
			this.facingDirection = xDiffSign === 0 ? 1 : xDiffSign
			if (this.isPet) {
				this.facingDirection *= -1
			}
			this.gfx.activeAnimator.scale.x = Math.abs(this.gfx.activeAnimator.scale.x) * -this.facingDirection

			if (this.isBoss) {
				Buff.apply(BuffIdentifier.LikeABoss, this, this)
				if (this.name === ENEMY_NAME.MR_CUDDLES) {
					Audio.getInstance().playSfx('SFX_MrCuddles_Spawn');
				} else if (this.name === ENEMY_NAME.ICE_DRAKE) {
					Audio.getInstance().playSfx('SFX_IceDrake_Spawn');
				} else if (this.name === ENEMY_NAME.YETI) {
					Audio.getInstance().playSfx('SFX_Yeti_Spawn');
				}
			}

			this.colliderComponent.isColliderActive = true
			this.colliderComponent.setColliders(this.config.baseAttributes.colliders)
			this.colliderComponent.setLayer(this.config.baseAttributes.colliderLayer) // only needed because choreo lines can change this
			CollisionSystem.getInstance().addCollidable(this.colliderComponent)
			this.gfx.addToScene()

			this.scale = this.config.appearance.scale || 1
			this.scaleGraphicsAndColliders(this.scale, this.scale)

			this.setAvoidanceRadius()

			this.hasFinishedDeadBehaviour = false
			this.recursiveRespawnDisabled = false

			if (this.config.baseAttributes.buffOnSpawn) {
				const [buffId, stacks, duration] = this.config.baseAttributes.buffOnSpawn
				Buff.apply(buffId, this, this, stacks, duration)
			}

			if (this.config.baseAttributes.playSpawnAnimation) {
				this.gfx.playSpawnAnim()
			}

			if (AISystem.getInstance().shamblerMagnetTwist && this.isBasicShambler && !this.isMerged) {
				AISystem.getInstance().basicShamblerCount++
				if (AISystem.getInstance().basicShamblerCount >= MAX_SHAMBLER_COUNT_FOR_MAGENET_SPAWN) {
					AISystem.getInstance().basicShamblerCount = 0
					this.groundItemDropStack.push(GroundPickupConfigType.MagnetSmall)
					this.setCircularScale(this.scale, HUGE_SHAMBLING_MOUND_SCALE)
					this.knockbackResist = DEFAULT_HUGE_SHAMBLING_MOUND_KNOCKBACK_RESIST
					this.maxHealth =  this.maxHealth * MAGNET_SHAMBLER_HEALTH_MULTI
					this.currentHealth = this.maxHealth
					Buff.apply(BuffIdentifier.Berserk, this, this)
				}
			}

			GameState.addEntity(this)
		}
	}

	cleanup() {
		Buff.removeAll(this)

		CollisionSystem.getInstance().removeCollidable(this.colliderComponent)
		this.gfx.removeFromScene()
		this.gfx.playMovementAnim()

		if (this.debugVisuals) {		
			Renderer.getInstance().mgRenderer.removeFromScene(this.debugVisuals)
			this.debugVisuals.destroy()
			this.debugVisuals = null
			this.colliderComponent.stopDrawingColliders()
		}

		this.useMovementOverride = false
		this.toBeMerged = false
		this.deathTimer = undefined
		this.totalTimeAlive = 0
		this.currentRage = undefined
		this.rageIndex = 0
		this.fightingBehaviour = this.config.states.fighting.movementStrategy.behaviour
		this.movementMinDistance = this.config.states.fighting.movementMinDistance
		this.movementMaxDistance = this.config.states.fighting.movementMaxDistance
		this.hitsFromPlayerWeapon = 0
		this.stackGoodBoyOnDeath = false
		this.isFleeing = false
		this.acidBottleTicks = 0
		this.primaryWeaponDamageBonus = 1
		this.recycleTime = 0
		this.knockbackImmune = Boolean(this.config.baseAttributes.knockbackImmune)
		this.keepFacingDirection = false
		this.immuneToRecycle = Boolean(this.config.baseAttributes.immuneToRecycle)
		this.substateData = {
			timeInSubstate: 0,
			currentSubstate: FightingSubstate.None,
		}
		this.target = null


		this.onKilled = undefined

		if (this.config.states.persisted) {
			for (let i = 0; i < this.config.states.persisted.length; ++i) {
				this.persistedStates[i] = {}
			}
		}

		GameState.removeEntity(this)
	}


	getStat(statName: StatName) {
		return this.statList.getStat(statName)
	}

	getParticleEffectType() {
		const attackConfig = this.config.states.fighting.attackConfig as AttackWithCooldown
		return attackConfig.particleEffectType
	}

	update(delta: number): void {
		const AISys = AISystem.getInstance()
		if (debugConfig.collisions.drawEnemyColliders) {
			if (!this.debugVisuals && !this.isDead()) {
				this.makeDebugVisuals()
				Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.debugVisuals)
			}
		} else {
			if (this.debugVisuals) {
				Renderer.getInstance().mgRenderer.removeFromScene(this.debugVisuals)
				this.debugVisuals.destroy()
				this.debugVisuals = null
				this.colliderComponent.stopDrawingColliders()
			}
		}

		this.setDirectionToPlayer()

		if (this.deathTimer !== undefined && !this.isDead()) {
			if (this.deathTimer <= 0) {
				this.transitionToDead(false)
			} else (
				this.deathTimer -= delta
			)
		}

		this.totalTimeAlive += delta

		if (this.config.rage && this.rageIndex < this.config.rage.length) {
			const nextRage = this.config.rage[this.rageIndex]
			if (this.totalTimeAlive >= nextRage.enrageStart) {
				this.currentRage = nextRage
				this.rageIndex++
				if (this.currentRage.applyFunction) {
					this.currentRage.applyFunction(this)
				}
			}
		}

		if (!this.toBeMerged){
			const stateDeltaScale = this.currentRage ? this.currentRage.cooldownBonus : 1

			if (this.config.states.persisted) {
				for (let i = 0; i < this.config.states.persisted.length; ++i) {
					const behaviour = persistedBehaviours[this.config.states.persisted[i].behaviour]
					if (behaviour) {
						behaviour(this, this.config.states.persisted[i], this.persistedStates[i], delta)
					}
				}
			}

			if (this.currentState === AIStates.FIGHTING) {
				this.fighting(delta * stateDeltaScale)
			} else if (this.currentState === AIStates.FLEEING) {
				this.fleeing(delta * stateDeltaScale)
			} else if (this.currentState === AIStates.IDLE) {
				this.idle(delta * stateDeltaScale)
			} else if (this.currentState === AIStates.DEAD) {
				this.dead(delta * stateDeltaScale)
			} else {
				throw new Error('Entity was found to be in state not recognized by FSM')
			}
		}

		//this.updateTimedActions()
		// The above states will mutate this entities velocity; apply results via updatePhysics() every frame
		if (!this.isDead()) {
			if (this.useMovementOverride) {
				this.velocity.copy(this.movementDirectionOverride)
				this.velocity.scale(this.movementSpeed)
			
			}

			this.previousPos.copy(this.position)

			this.updatePhysics(delta)

			if (this.config.states.scaleWithDistanceRate) {
				const scaledDistanceThisFrame = distanceSquaredVV(this.position, this.previousPos) 
				this.scaleCircularEnemyOverDistance(scaledDistanceThisFrame, this.config.states.scaleWithDistanceRate)
			}
			
			if (this.debugVisuals) {
				this.debugVisuals['update'](delta)
			}

			this.damageTakenAcc -= delta
			if (this.damageTakenAcc <= 0 && this.damageTakenAmount > 0) {
				spawnDamageNumber(Math.round(Math.max(1, this.damageTakenAmount)), this.position.x, this.position.y, DamageNumberStyle.Enemy)
				this.damageTakenAcc = getDamageNumberAccumulatorDelay()
				this.damageTakenAmount = 0
			}

			if (AISys.spookyGhostTwist) {
				if (!this.ghosted && AISys.spookyGhostCooldownUp() && AISys.spookyGhostRoll()) {
					AISys.applySpookyGhost(this)
				}
			}
		}

		if (!this.config.baseAttributes.noFlip && !this.keepFacingDirection) {
			const signOfDirToPlayer = Math.sign(this.directionToPlayer.x)
			this.facingDirection = signOfDirToPlayer ? signOfDirToPlayer : 1
			if (this.isFleeing) {
				this.facingDirection *= -1
			}

			this.gfx.activeAnimator.scale.x = Math.abs(this.gfx.activeAnimator.scale.x) * -this.facingDirection
		} else if (!this.keepFacingDirection) {
			// enemies are ALL facing the wrong way in source art.
			//  so to *not* flip them, we need to set them to -1 facingDirection
			this.facingDirection = this.isPet ? 1 : -1
		}
		this.handleFacingDirection()
		this.gfx.update(delta)
	}

	//TODO: somehow this gets called `this` set to the collider, not the Enemy?
	onCollision(otherCollider, collisionVX: number, collisionVY: number) {
		const otherEntity = otherCollider.owner
		const selfEntity = (this as unknown as ColliderComponent).owner
		if (otherEntity instanceof Player) {
			if (selfEntity.config.states.fighting.onCollisionFn) {
				selfEntity.config.states.fighting.onCollisionFn(selfEntity, otherEntity as Player, collisionVX, collisionVY)
			}
		} else if (otherEntity instanceof Prop) {
			if (selfEntity.config.states.fighting.onPropCollisionFn) {
				selfEntity.config.states.fighting.onPropCollisionFn(selfEntity, otherEntity as Prop, collisionVX, collisionVY)
			}
		}
	}

	handleFacingDirection() {
		this.gfx.setScale(Math.abs(this.gfx.activeAnimator.scale.x) * -this.facingDirection, this.gfx.activeAnimator.scale.y)
		for (const c of this.colliderComponent.colliders) {
			if (c.type === ColliderType.Circle) {
				c.offsetPos.x = Math.abs(c.offsetPos.x) * this.facingDirection
			}
		}

		updateColliderPositions(this.colliderComponent.colliderConfigs, this.colliderComponent.colliders, this.position)

		if (this.debugVisuals) {
			this.debugVisuals.scale.x = Math.abs(this.debugVisuals.scale.x) * -this.facingDirection
		}
	}

	setMovementOverride(x: number, y: number) {
		this.useMovementOverride = true
		this.movementDirectionOverride.x = x
		this.movementDirectionOverride.y = y
	}

	// Only supports circle colliders for now
	scaleGraphicsAndColliders(scaleX: number, scaleY: number) {
		this.gfx.setScale(scaleX, scaleY)

		this.scaleCircleColliderRadius(this.colliderComponent, scaleX, scaleY)
	}

	scaleCircleColliderRadius(component: ColliderComponent, scaleX: number, scaleY: number) {
		let success = false
		for (const c of component.colliders) {
			if (c.type === ColliderType.Circle) {
				c.r *= Math.abs(scaleX)
				c.pos.x *= Math.sign(scaleX)
				c.pos.y *= Math.sign(scaleY)
				success = true
			}
		}

		if (success) {
			component.recalculateBounds()
		}
		return success
	}

	// rotateGraphicsAndColliders(angleInRads: number) {
	// 	this.gfx.rotate(angleInRads)

	// 	this.rotateColliders(this.colliderComponent, angleInRads)
	// }

	rotateColliders(component: ColliderComponent, angleInRads: number) {
		let success = false

		for (const c of component.colliders) {
			if (c.type === ColliderType.Circle) {
				c.offsetPos.rotate(angleInRads)
				success = true
			}
		}

		if (success) {
			component.recalculateBounds()
		}
		return success
	}

	isDead() {
		return this.currentState === AIStates.DEAD
	}

	hasBuff(buff: BuffIdentifier) {
		return Buff.getBuff(this, buff)
	}

	onBuffApplied(buffId: BuffIdentifier): void {

	}

	onBuffUpdateStacks(buffId: BuffIdentifier, oldStacks: number, newStacks: number): void {

	}

	onBuffWearOff(buffId: BuffIdentifier): void {

	}

	isInRage(): boolean {
		return Boolean(this.currentRage)
	}

	canTakeDamage(): boolean {
		/* TODO Revisit when we implement buffs
		if (hasInvulnerableBuff(this) || this.spawnInTimer > 0) {
			return false
		} */
		return !this.isDead()
	}

	reduceEnergy(amount) {
		this.currentEnergy = Math.max(0, this.currentEnergy - amount)
	}

	onHitByDamageSource(damageSource: DamageSource, damageScale: number = 1, ignoreKnockBack?: boolean): GroundPickup {
		if (!this.isDead()) {
			let damage: number
			if (damageSource.entityType === EntityType.Projectile) {
				const shield = Buff.getBuff(this, BuffIdentifier.ProjectileShield)
				if (shield) {
					shield.wearOffStacks(1)
					return null
				}

				const projectile = damageSource as PlayerProjectile
				damage = this.getProjectileDamage(projectile)
				if (projectile.isPrimaryWeaponProjectile) {
					damage *= this.primaryWeaponDamageBonus
				}
			} else if (damageSource.entityType === EntityType.Beam) {
				const beam = damageSource as Beam
				if (beam.hasRandomChanceForMassiveDamage && beam.dealMassiveDamage) {
					damage = this.config.type === EnemyType.BOSS ? MASSIVE_DAMAGE_BOSS_AMOUNT : MASSIVE_DAMAGE_AMOUNT
				} else {
					damage = this.getDamage(damageSource)
				}
			} else {
				damage = this.getDamage(damageSource)
			}

			damage *= damageScale

			if (!ignoreKnockBack) {
				applyKnockback(this, damageSource)
			}

			if (damageSource.isPlayerOwned()) {
				const player = GameState.player
				if (player.binaryFlags.has('bonus-point-blank-damage') || player.binaryFlags.has('barbarian-striker')) {

					const dist = distanceSquaredVV(this.position, player.position)

					if (player.binaryFlags.has('bonus-point-blank-damage')) {
						// maps 30% damage bonus to <=200 range, falling off to 0% bonus at >=600 range
						const multi = mapToRange(dist, 200 ** 2, 600 ** 2, 1.3, 1.0, true)
						damage *= multi
					}

					if (player.binaryFlags.has('barbarian-striker') && dist <= getBarbarianPassiveSkillRange() ** 2) {
						damage *= BARBARIAN_PASSIVE_BASE_STATS_DATA.strikerDamageMult
					}
				}
				if (player.binaryFlags.has('gain-damage-based-on-paper-scraps-40')) {
					const multi = Math.clerp(1.0, 1.4, player.commonCurrency / PLAYER_ESTIMATED_MAX_COMMON_CURRENCY_PER_RUN)
					damage *= multi
				}

				if (damageSource.entityType === EntityType.Projectile) {
					const projectile = damageSource as PlayerProjectile
					if (projectile.weaponType === AllWeaponTypes.Wand) {
						this.hitsFromPlayerWeapon++

						if (player.binaryFlags.has('noxious-leak')) {
							const impactDamage = this.getProjectileDamage(projectile)
							const state = player.binaryFlagState['noxious-leak']
							if (this.hitsFromPlayerWeapon >= 2 && !state.poolRef) {
								// make a ground hazard
								const pool = state.poolRef = NoxiousLeakHazard.pool.alloc({
									statList: player.primaryWeapon.statList,
									lifeTime: NOXIOUS_LEAK_DURATION,
									triggerRadius: 1, // arbitrary, we set the radius later with addImpactDamage()
									position: this.position.clone(),
									damageTargetType: DamageableEntityType.Enemy,
									weaponType: projectile.weaponType
								})
								pool.addImpactDamage(impactDamage)
							} else if (state.poolRef) {
								const noxiousPool = state.poolRef as NoxiousLeakHazard
								if (noxiousPool.triedToReturnToPool) {
									state.poolRef = null
								} else if (withinDistanceVV(this.position, noxiousPool.position, noxiousPool.triggerRadius)) {
									// Grow with each wand impact
									noxiousPool.addImpactDamage(impactDamage)
								}
							}
						}

						if (player.binaryFlags.has('lightning-strikes-twice')) {
							if (this.hitsFromPlayerWeapon % 2 === 0 || this.currentHealth <= damage) {
								LightningStrike.pool.alloc({
									statList: player.primaryWeapon.statList,
									damageScale: LIGHTNING_STRIKES_TWICE_DAMAGE_MULT,
									splashRadius: LIGHTNING_STRIKES_TWICE_PRIMARY_WEAPON_DAMAGE_SPLASH_RADIUS,
									targetEntity: this,
									attackKnockback: 0
								})
							}
						}
					} else { // non-wand
						if (player.binaryFlags.has('lightning-strikes-twice')) {
							const data = player.binaryFlagState['lightning-strikes-twice']
							data.attackCounter += 1
							if (data.attackCounter >= 7) {
								data.attackCounter -= 7
								const radius = LIGHTNING_STRIKES_TWICE_ANY_WEAPON_DAMAGE_SPLASH_RADIUS
								LightningStrike.strikeEnemiesOnScreen(1, player.primaryWeapon.statList, radius, 0, LIGHTNING_STRIKES_TWICE_DAMAGE_MULT)
							}
						}

					}
				}
			}

			let playSfx = true

			if ([AllWeaponTypes.AcidBottles, AllWeaponTypes.TeslaCoil, AllWeaponTypes.DamageOverTime].includes(damageSource.weaponType)) {
				playSfx = false
			}

			this.applyDebuffsFromDamage(damageSource, damage)

			const xp = this.takeDamageSimple(damage, playSfx, undefined, undefined, !damageSource.showImmediateDamageNumber)

			if (damageSource.isPlayerOwned()) {
				const player = GameState.player
				const actualDamage = this._currentHealth < 0 ? damage + this._currentHealth : damage
				player.onTargetDamaged(this, damageSource, actualDamage)
				if (this.currentHealth <= 0) {
					player.onTargetKilled(this, damageSource)
				}

			}
			// TODO: Reminder - This should probably be moved above takeDamageSimple, and refactored to use a const instead of critChance
			const critChance = damageSource.statList.getStat(StatType.critChance)
			if (Math.random() <= critChance) {
				// TODO: uncomment once crit damage is implemented
				//const critMult = damageSource.statList.getStat('critDamage')
				//damage *= critMult
				if (GameState.player.binaryFlags.has('longbow-insta-kill-crits') && this.config.type === EnemyType.BASIC) {
					damage = Math.max(this.maxHealth, damage)
				}
			}

			if (this.isDead()) {
				PlayerMetricsSystem.getInstance().trackMetric('WEAPON_KILL', damageSource, this)

				if (GameState.player.binaryFlags.has('spear-eye-of-mercy') && damageSource.weaponType === AllWeaponTypes.Spear) {
					this.applyAOEBuff(damageSource, damage)
				}

				if (GameState.player.binaryFlags.has('spear-saints-spear') && damageSource.weaponType === AllWeaponTypes.Spear) {
					this.saintsSpearExplosion(damageSource)
				}
			} else {
				if (this.config.baseAttributes.playHitAnimation) {
					this.gfx.playHitAnim()
				}
			}

			return xp
		}

		return null
	}

	getDamage(damager: DamageSource): number {
		const baseDamage = damager.statList.getStat(StatType.baseDamage)
		let damageMultiplier = damager.statList.getStat(StatType.allDamageMult)

		// @TODO I don't like how we get the flags/player
		if (GameState.player.binaryFlags.has('chain-damage-per-chain-remaining')) {
			const bonusData = GameState.player.binaryFlagState['chain-damage-per-chain-remaining'] as any
			const chainsRemaining = damager.statList.getStat(StatType.projectileChainCount) - damager.numEntitiesChained

			damageMultiplier *= 1 + (bonusData.damageBonus * chainsRemaining)
		}

		if (GameState.player.binaryFlags.has('pierce-damage-per-pierce-remaining')) {
			const pierceReamining = damager.statList.getStat(StatType.attackPierceCount) - damager.numEntitiesPierced
			damageMultiplier *= 1 + (PIERCE_DAMAGE_PER_PIERCE_REMAINING_MULTI * pierceReamining)
		}

		return Math.round(baseDamage * damageMultiplier * this.statList.getStat(StatType.damageTakenMult))
	}

	getProjectileDamage(projectile: PlayerProjectile) {
		return Math.round(getDamageFromDamageSource(projectile) * this.statList.getStat(StatType.damageTakenMult))
	}

	takeDamageSimple(damage: number, playSfx: boolean = true, isDOT: boolean = false, trackDamageSource?: DamageSource, delayDamageNumber?: boolean): GroundPickup {
		const shield = Buff.getBuff(this, BuffIdentifier.AllDamageShield)
		if (shield) {
			shield.wearOffStacks(1)
			return null
		}
		if (!this.isDead()) {
			this.currentHealth -= damage
			if (isDOT) {
				//DOTs tracked here, not onHitByDamageSource, because the way they calculate damage is totally different/up front
				PlayerMetricsSystem.getInstance().trackMetric("WEAPON_DAMAGE", DAMAGE_OVER_TIME_SOURCE, damage, this)
			} else if (trackDamageSource) {
				PlayerMetricsSystem.getInstance().trackMetric("WEAPON_DAMAGE", trackDamageSource, damage, this)
			}

			if ((isDOT || delayDamageNumber) && this.currentHealth > 0) {
				this.damageTakenAmount += damage
			} else if (damage > 0) {
				spawnDamageNumber(Math.round(Math.max(1, damage)), this.position.x, this.position.y, DamageNumberStyle.Enemy)
			}

			if (playSfx) {
				Audio.getInstance().playSfx(this.config.soundEffects.impact)
			}

			if (this.currentHealth <= 0) {
				return this.transitionToDead(true)
			} else if (this.config.states.onHit) {
				for (let i = 0; i < this.config.states.onHit.length; ++i) {
					onHitBehaviours[this.config.states.onHit[i].behaviour](this, this.config.states.onHit[i], damage, isDOT)
				}
			}
		}

		return null
	}

	applyDebuffsFromDamage(damageSource: DamageSource, damageDealt: number) {
		let ailmentApplications: number
		const genericChanceBonus = (GameState.player.binaryFlags.has('elemental-consistency') ? 0.25 : 0)

		function getChancePlusBonus(stat) {
			if (stat > 0) {
				return stat + genericChanceBonus
			}
			return stat
		}

		if (!this.isDead()) {
			const player = GameState.player

			const shockChance = getChancePlusBonus(damageSource.statList.getStat(StatType.shockChance))
			if (Math.random() <= shockChance) {
				Buff.apply(BuffIdentifier.Shock, damageSource, this, damageSource.statList.getStat(StatType.shockPotency))
			}

			let stunChance = getChancePlusBonus(damageSource.statList.getStat(StatType.stunChance))
			let stunDuration = undefined
			if (player.binaryFlags.has('longbow-stun')) {
				if (damageSource instanceof PlayerProjectile) {
					if (damageSource.chargeDefinition?.chargePercent >= 100) {
						stunChance = 1.0
					}
				}
				stunDuration = LONGBOW_STUN_DURATION
			}
			if (Math.random() <= stunChance) {
				if (damageSource.entityType === EntityType.Projectile) {
					const proj = damageSource as PlayerProjectile
					if (proj.weaponType === AllWeaponTypes.Bow) {
						if (player.binaryFlags.has('longbow-walk-it-off')) {
							Buff.apply(BuffIdentifier.LongbowWalkItOff, damageSource, this)
						}

					}
				}
				stunEnemy(this, damageSource, stunDuration)
			}

			ailmentApplications = chanceToLoops(getChancePlusBonus(damageSource.statList.getStat(StatType.poisonChance)))
			while (ailmentApplications > 0) {
				ailmentApplications--
				const stacks = getPoisonStacks(damageDealt) * damageSource.statList.getStat(StatType.poisonPotency)
				Buff.apply(BuffIdentifier.Poison, damageSource, this, stacks)
			}

			const igniteDurationScale = player.binaryFlags.has('double-ignite-duration') ? 2 : 1
			const igniteChance = getChancePlusBonus(damageSource.statList.getStat(StatType.igniteChance))
			if (Math.random() < igniteChance) {
				let potency = damageSource.statList.getStat(StatType.ignitePotency)
				if (igniteChance > 1.0) {
					potency += igniteChance - 1.0
				}
				const stacks = getIgniteStacks(damageDealt) * potency
				Buff.apply(BuffIdentifier.Ignite, damageSource, this, stacks, IGNITE_DURATION * igniteDurationScale)
			}

			ailmentApplications = chanceToLoops(getChancePlusBonus(damageSource.statList.getStat(StatType.chillChance)))
			if (ailmentApplications >= 1 && damageSource.weaponType === AllWeaponTypes.Cannon && GameState.player.binaryFlags.has('cannon-shock')) {
				Buff.apply(BuffIdentifier.Shock, damageSource, this, damageSource.statList.getStat(StatType.shockPotency), CANNON_CHILLED_SHOCK_DURATION)
			}
			while (ailmentApplications > 0) {
				ailmentApplications--
				const stacks = getChillStacks(damageDealt) * damageSource.statList.getStat(StatType.chillPotency)
				Buff.apply(BuffIdentifier.Chill, damageSource, this, stacks)
			}

			ailmentApplications = chanceToLoops(getChancePlusBonus(damageSource.statList.getStat(StatType.bleedChance)))
			while (ailmentApplications > 0) {
				ailmentApplications--
				const stacks = getBleedStacks(damageDealt) * damageSource.statList.getStat(StatType.bleedPotency)
				Buff.apply(BuffIdentifier.Bleed, damageSource, this, stacks)
			}
		}

		ailmentApplications = chanceToLoops(getChancePlusBonus(damageSource.statList.getStat(StatType.doomChance)))
		while (ailmentApplications > 0) {
			ailmentApplications--
			const buff = Buff.apply(BuffIdentifier.Doom, damageSource, this)
			buff.state.potency = damageSource.statList.getStat(StatType.doomPotency)
			buff.state.damage += damageDealt
		}

		ailmentApplications = chanceToLoops(getChancePlusBonus(damageSource.statList.getStat(StatType.randomAilmentChance)))
		while (ailmentApplications > 0) {
			ailmentApplications--
			const randomAilment = AILMENT_DEBUFFS.pickRandom()
			const baseStacks = getStacksForAilment(randomAilment, damageDealt)
			const potencyStat = AILMENT_TO_POTENCY_STAT[randomAilment]
			const potency = damageSource.statList.getStat(potencyStat)

			const buff = Buff.apply(randomAilment, damageSource, this, baseStacks * potency)

			if (randomAilment === BuffIdentifier.Doom) {
				buff.state.potency = potency
				buff.state.damage += damageDealt
			}
		}
	}

	applyAOEBuff(damageSource: DamageSource, damageDealt: number) {
		const nearbyEntities = CollisionSystem.getInstance().getEntitiesInArea(this.position, EYE_OF_MERCY_AOE_SIZE, CollisionLayerBits.HitEnemyOnly)
		for (const entity of nearbyEntities) {
			const targetEntity = entity.owner
			if (targetEntity.entityType === EntityType.Enemy && !targetEntity.isDead()) {
				const enemy = targetEntity as Enemy
				enemy.applyDebuffsFromDamage(damageSource, damageDealt)
				enemy.applyHolyLight(damageSource)
			}
		}
	}

	teleport(position: Vector) {
		this.position.copy(position)
		this.gfx.update(0)
		CollisionSystem.getInstance().reinsertEntity(this.colliderComponent)
		this.setDirectionToPlayer()
	}

	addKnockBack(kb: Vector) {
		if (!this.knockbackImmune) {
			this.knockbackVelocity = this.knockbackVelocity.add(kb)

			const knockbackLen2 = this.knockbackVelocity.len2()
			if (knockbackLen2 > MAX_KNOCKBACK2) {
				this.knockbackVelocity.scale(MAX_KNOCKBACK2/knockbackLen2)
			}
		}
	}

	avoidNearbyEnemies() {
		const avoidanceFactor = 0.75 // This is kinda like how aggressively they back off eachother

		let xAvoid = 0
		let yAvoid = 0

		this.nearbyMergeEnemies.length = 0
		this.nearbyMergeEnemies.push(this)

		for (const cell of this.colliderComponent.cells) {
			for (const entity of cell) {
				if (entity.id !== this.nid) {
					if (entity.owner.isEnemy && !entity.owner.isDead()) {
						const nearbyEnemy = entity.owner as Enemy
						if (this.canMerge(nearbyEnemy)) {
							nearbyEnemy.toBeMerged = true
							this.nearbyMergeEnemies.push(nearbyEnemy)
							if (this.nearbyMergeEnemies.length === AISystem.getInstance().monsterMergeAmount && AISystem.getInstance().mergeRoll()) {
								this.toBeMerged = true
								const mergeTarget = midpoint(this.nearbyMergeEnemies)
								this.nearbyMergeEnemies.forEach((e) => {
									e.setTargetPosition(mergeTarget.x, mergeTarget.y)
								})
								AISystem.getInstance().addEnemiesToMergeQueue(this.nearbyMergeEnemies)
								return
							}
						}

						// This ensures we don't do avoidance between normal enemies and flyers
						if (entity.owner.colliderComponent.layer === this.colliderComponent.layer) {
							const distance = (this.x - entity.position.x) ** 2 + (this.y - entity.position.y) ** 2
							const avoidDist = Math.max(this.avoidanceRadiusSquared, entity.owner.avoidanceRadiusSquared)
							if (distance <= avoidDist) {
								xAvoid += this.x - entity.position.x
								yAvoid += this.y - entity.position.y
							}
						}
					}
				}
			}
		}

		// Clear flag if merge was unsuccessful
		if (AISystem.getInstance().monsterMergeTwist) {
			enemiesNearby.forEach((e) => {
				e.toBeMerged = false
			})
		}

		this.velocity.x += xAvoid * avoidanceFactor
		this.velocity.y += yAvoid * avoidanceFactor
	}

	updatePhysics(delta: number) {
		if (!this.useMovementOverride && !this.toBeMerged) {
			this.avoidNearbyEnemies()
		}

		//const distanceTravelledThisFrame = this.velocity.len() * delta
		const vel = this._velocity
		const kbVel = this.knockbackVelocity
		const distanceTravelledThisFrame = vel.len2() + kbVel.len2() // (not really)

		// Apply deceleration to the entities velocity x every frame (only if they aren't effectively already stopped)
		if (distanceTravelledThisFrame < 0.1) {
			vel.x = 0
			vel.y = 0
			kbVel.x = 0
			kbVel.y = 0
			this.quickTurnReady = true
		} else {
			let speed
			if (this.substateData.currentSpeed !== undefined) {
				speed = this.substateData.currentSpeed
			} else {
				speed = this.currentRage ? this.movementSpeed * this.currentRage.movementBonus : this.movementSpeed
			}
			speed *= GAMEPLAY_SPEED_MULTIPLIER

			if (GameState.player.binaryFlags.has('barbarian-intimidating-aura')) {
				const dist = distanceSquaredVV(this.position, GameState.player.position)
				if (dist <= getBarbarianPassiveSkillRange() ** 2) {
					if (!Buff.getBuff(this, BuffIdentifier.Chill)) {
						Buff.apply(BuffIdentifier.Chill, GameState.player, this, getChillStacks(0) * 0.5) // half-strength chill
					}
				}
			}

			vel.normalize()
			vel.scale(speed)

			this.x = this.x + vel.x * delta + kbVel.x * delta
			this.y = this.y + vel.y * delta + kbVel.y * delta

			kbVel.x = Math.lerp(kbVel.x, 0, Math.clamp(this.knockbackDecelerationRate * delta * this.knockbackResist, 0, 1))
			kbVel.y = Math.lerp(kbVel.y, 0, Math.clamp(this.knockbackDecelerationRate * delta * this.knockbackResist, 0, 1))
		}
	}

	// FSM Potential State 1 of 4: Fighting
	private fighting(delta: number) {
		if (this.fleeTimer && this.substateData.currentSubstate === FightingSubstate.Strafe && this.substateData.timeInSubstate * 1000 > this.fleeTimer) {
			this.transitionToFleeing()
			return
		}
		// Had no reason to leave this state this frame so execute this AIs configured behaviour for this state
		const state = fightingBehaviours[this.fightingBehaviour]
		if (typeof state === 'function') {
			state(this, delta)
		}
	}

	// FSM Potential State 2 of 4: 💀Dead💀
	private dead(delta: number) {
		// First check the various factors that might lead us to leave this FSM state and transition if necessary
		if (this.timeInStateMs() > this.corpseTimeoutInSeconds * 1000 && !this.groundItemDropStack.length) {
			//ServerEventSystem.getInstance().emit(Events.ENEMY_DEATH_ANIMATION_END, this.position.x, this.position.y)
			this.toBeDeleted = true
			return
		}

		if (this.groundItemDropStack.length) {
			this.dropGroundPickupFromStack(delta)
		}

		// Had no reason to leave this state this frame so execute this AIs configured behaviour for this state
		deadBehaviours[this.deadBehaviour](this, delta)

	}

	timeInStateMs(): timeInSeconds {
		const t = InGameTime.highResolutionTimestamp() - this.enteredCurrentState
		return t
	}

	private setDirectionToPlayer() {
		const playerPos = GameState.player.position
		this.directionToPlayer.x = playerPos.x - this.position.x
		this.directionToPlayer.y = playerPos.y - this.position.y - this.modelCenterOffset
		this.distanceToPlayerSquared = this.directionToPlayer.len2()
	}

	setTargetPosition(x: number, y: number) {
		this.targetPosition.x = x
		this.targetPosition.y = y
	}

	// FSM Potential State 3 of 4: Fleeing
	private fleeing(delta: number) {
		// Had no reason to leave this state this frame so execute this AIs configured behaviour for this state
		const state = fleeingBehaviours['flee']
		if (typeof state === 'function') {
			state(this, delta)
		}
	}

	// FSM Potential State 4 of 4: Idle
	private idle(delta: number) {
		// Nothing to do
	}

	private changestate(aistate: AIStates, subState: string = '') {
		this.enteredCurrentState = InGameTime.highResolutionTimestamp()

		this.currentState = aistate
		this.substateData.currentSubstate = FightingSubstate.None
		this.substateData.timeInSubstate = 0
		//this.currentSubState = subState

		const nextState = this.config.states[this.currentState] as any
	}

	private getStateBehaviour(state: AIStates) {
		switch (state) {
			case AIStates.FIGHTING:
				return fightingBehaviours[this.fightingBehaviour]
			case AIStates.DEAD:
				return deadBehaviours[this.deadBehaviour]
		}
		return null
	}


	private transitionToFighting(): void {
		this.changestate(AIStates.FIGHTING, 'chasing')

		/* TODO HOTCAKES: Revisit when we have an AI brain 
		if (this.brain) {
			// if enemy is retransitioning back to their brain, reset it so they're not mid-attack
			this.brain.reset()
		} */

		/* TODO HOTCAKES: Only used for shriekers. Restore this if/when they are added 
		if (this.config.baseAttributes.hasMovableCollider) {
			const movableCollider = this.config.baseAttributes.colliders.find((col) => {
			})
			if (movableCollider) {
				movableCollider.position = this.config.baseAttributes.defaultMovableColliderPosition
			}
		} */
	}

	private transitionToFleeing(): void {
		this.isFleeing = true
		
		this.changestate(AIStates.FLEEING)
	}

	transitionToIdle(): void {
		this.velocity.x = 0
		this.velocity.y = 0
		this.changestate(AIStates.IDLE)
	}

	transitionToDead(killed: boolean): GroundPickup {
		this.changestate(AIStates.DEAD, `R.I.P.`)
		this.target = null
		this.shooting = false

		const enemyEquilibrium = EnemyEquilibriumSpawner.getInstance()

		clearAttachedPfx(this)
		this.gfx.die()
		
		CollisionSystem.getInstance().removeCollidable(this.colliderComponent)

		/* 		const behaviour = deadBehaviours[this.deadBehaviour]
		
				// HOTCAKES Do we need delta here? if so we'll have to propagate that down to transition
				behaviour(this, 0.01) */

		if (CHAPTER_ENDING_BOSS_ENEMY_NAMES.includes(this.name)) {
			console.log(`Final boss killed check successful`)
			Audio.getInstance().playSfx('SFX_Boss_Prism_Death_Freeze')
			PlayerMetricsSystem.getInstance().trackMetric('EVENT_COMPLETED', AllEventTypes.BOSS)
			PlayerMetricsSystem.getInstance().trackMetric('EVENT_COMPLETED', AllEventTypes.ACT_COMPLETE)

			// wait what should be enough time to suck up all of the currency
			callbacks_addCallback(this, () => {
				magnetAllCurrency(GameState.player)
			}, 0.75)
			callbacks_addCallback(this, () => {
				magnetAllCurrency(GameState.player)
			}, 1.25)
			callbacks_addCallback(this, () => {
				magnetAllCurrency(GameState.player)
			}, 1.75)
			callbacks_addCallback(this, () => {
				VictoryDeathManager.victory(GameState.player)
			}, 3.0)
		} else if (BOSS_ENEMY_NAMES.includes(this.name) && this.isEventSpawn) {
			PlayerMetricsSystem.getInstance().trackMetric('EVENT_COMPLETED', AllEventTypes.BOSS)
			let giveBossReward: boolean = true
			if (BRUTE_TRIO_ENEMIES.includes(this.name)) {
				const aiSystem = AISystem.getInstance()
				aiSystem.bruteTrioKillCount++
				if (aiSystem.bruteTrioKillCount < 3) {
					giveBossReward = false
				} else {
					aiSystem.bruteTrioKillCount = 0
					enemyEquilibrium.isLastBossInTrio = true
				}
			}

			if (giveBossReward) {
				let upgradeOptions = 4
				if (GameState.player.binaryFlags.has('masters-degree')) {
					upgradeOptions += 1
				}
				PlayerMetricsSystem.getInstance().trackMetric('EVENT_COMPLETED', AllEventTypes.ACT_COMPLETE)
				// console.log(`DEATH name: ${this.name}  hp: ${this._currentHealth}/${this.maxHealth}`)
				Audio.getInstance().playSfx('SFX_Item_Drop_Astronomical')
				callbacks_addCallback(this, () => {
					GameState.player.rollBossReward(upgradeOptions)
				}, 0.5)

				callbacks_addCallback(this, () => {
					UI.getInstance().emitMutation('ui/addNewBigMessage', ['', `Act ${InGameTime.currentAct}`]) // subtitle sized
				}, 10.5)

				callbacks_addCallback(this, () => {
					EnemyEquilibriumSpawner.getInstance().forceStartNextPack(true)
				}, 5)
			}
		}

		if (killed) {
			PlayerMetricsSystem.getInstance().trackMetric('ENEMIES_KILLED', this)			
			const aiSystem = AISystem.getInstance()
			
			if (this.isBoss && enemyEquilibrium.enableDramaticShowdownTwist) {
				
				if(enemyEquilibrium.bruteTrioEventStarted){
					enemyEquilibrium.bruteTrioAct2BossesKilled++ 

					if(enemyEquilibrium.bruteTrioAct2BossesKilled == 3){
						enemyEquilibrium.dramaticShowdownResumeSpawning()
					}
				} else {
					enemyEquilibrium.dramaticShowdownResumeSpawning()
				}
			}

			const player = GameState.player
			let xpPickupDropped: GroundPickup = null
			// Rare Currency
			const rareCurrencyConfig = RARE_CURRENCY_DROP_CONFIG[this.name]
			if (rareCurrencyConfig) {
				const rareCurrencyDropMulti = player.getStat(StatType.rareCurrencyDropMulti)
				const amount = Math.round(randomRange(rareCurrencyConfig.min, rareCurrencyConfig.max)) * rareCurrencyDropMulti
				for (let i = 0; i < amount; i++) {
					this.groundItemDropStack.push(GroundPickupConfigType.RareCurrency)
				}
			}

			if (this.isBoss && aiSystem.theBiggerTheyAreFlag) {
				let iterations = 0
				while (iterations < PLOT_TWIST_BIGGER_THEY_ARE_XP_DROP_AMOUNT) {
						const xpPickup: GroundPickupConfigType = AISystem.getInstance().xpDropShuffleBag.next()
						if (xpPickup !== GroundPickupConfigType.None) {
							this.groundItemDropStack.push(xpPickup)
							iterations++
						}
				}
			}

			if (this.isLootGoblin) {
				let iterations = 0
				while (iterations < GOBLIN_XP_DROP_AMOUNT) {
					if (InGameTime.timeElapsedInSeconds < NO_MORE_XP_TIME) {
						const xpPickup: GroundPickupConfigType = AISystem.getInstance().xpDropShuffleBag.next()
						if (xpPickup !== GroundPickupConfigType.None) {
							this.groundItemDropStack.push(xpPickup)
							iterations++
						}
					} else {
						const xpPickup = GroundPickupConfigType.XPLarge
						this.groundItemDropStack.push(xpPickup)
						iterations++
					}
				}
			} else {
				let deathRewardRate = this.deathRewardRate
				const xpDrops = true

				if (player.binaryFlags.has('ignite-spreads-on-death')) {
					const ignite = Buff.getBuff(this, BuffIdentifier.Ignite)
					if (ignite && ignite.stacks) {
						triggerBuffSpread(ignite, PLAYER_IGNITE_SPREAD_ON_DEATH_RADIUS, 1, 'highest-rolling', PLAYER_IGNITE_SPREAD_ON_DEATH_TARGETS, false)
					}
				}

				if (player.binaryFlags.has('poison-spreads-on-death-50')) {
					const poison = Buff.getBuff(this, BuffIdentifier.Poison)
					if (poison && poison.stacks) {
						triggerBuffSpread(poison, PLAYER_IGNITE_SPREAD_ON_DEATH_RADIUS, 0.50, 'all', PLAYER_POISON_SPREAD_ON_DEATH_TARGETS, true)
					}
				}

				if (player.binaryFlags.has('poisoned-enemies-leave-poison-pool')) {
					const poison = Buff.getBuff(this, BuffIdentifier.Poison)
					if (poison && poison.stacks) {
						triggerPoisonPool(poison, PLAYER_POISON_POOL_RADIUS)
					}
				}

				if (player.binaryFlags.has('cannon-poison-explosion')) {
					const poison = Buff.getBuff(this, BuffIdentifier.Poison)
					const flagState = GameState.player.binaryFlagState['cannon-poision-explosion']
					if (poison && poison.stacks && InGameTime.timeElapsedInSeconds - flagState.lastExplosionTime >= 1) {
						const damageSource = player.primaryWeapon as Cannon
						dealAOEDamageDamageSource(CollisionLayerBits.HitEnemyOnly, 150, this.position, damageSource, 0.5, undefined, undefined, true, 'poison')
						flagState.lastExplosionTime = InGameTime.timeElapsedInSeconds
					}
				}

				const doom = Buff.getBuff(this, BuffIdentifier.Doom)
				if (doom) {
					triggerDoomExplosion(doom, 0.15, doom.state.potency, doom.state.damage)
				}

				if (Math.random() <= deathRewardRate) {
					if (this.stackGoodBoyOnDeath) {
						Buff.apply(BuffIdentifier.GoodBoyBuff, GameState.player, GameState.player, 1)
						this.stackGoodBoyOnDeath = false
					}

					// Currently only used for hearts, will need to be moved up if we apply drop restrictions to other types
					const excludeGroundDrops = this.config.states.dead.excludeGroundDrops ?? []

					// Hearts
					let heartPickupChance = player.stats.getStat('heartDropMulti')
					if (player.characterType === CharacterType.Barbarian) {
						const dist = distanceSquaredVV(this.position, player.position)
						if (dist <= getBarbarianPassiveSkillRange() ** 2) {
							heartPickupChance *= BARBARIAN_PASSIVE_BASE_STATS_DATA.heartDropRateMult
						}
					}
					// if this changes and `getGroundPickupToDrop()` has more than hearts, this probably needs to change
					const pickup = getGroundPickupToDrop(GroundPickupPoolType.Enemy, heartPickupChance)
					if (pickup && !excludeGroundDrops.includes[pickup]) {
						if (this.bonusDropMult > 1){
							let dropCount = 0
							while (dropCount < this.bonusDropMult - 1 && InGameTime.timeElapsedInSeconds < NO_MORE_XP_TIME) {
								const extraHeart = getGroundPickupToDrop(GroundPickupPoolType.Enemy, GUARANTEED_HEART_DROP_MULTI)
								if (extraHeart === GroundPickupConfigType.HealingSmall || extraHeart === GroundPickupConfigType.HealingLarge) {
									this.groundItemDropStack.push(extraHeart)
									dropCount++
								}
							}
						} else {
							allocGroundPickup(pickup, this.x, this.y, 0, 0)
						}
					}

					// Common Currency
					const commonCurrencyPickup = AISystem.getInstance().currencyDropShuffleBag.next()
					const currencyDropMulti = player.getStat(StatType.commmonCurrencyDropMulti) + MapSystem.getInstance().getMapConfig().currencyMultiplier
					if (commonCurrencyPickup !== GroundPickupConfigType.None) {
						const currencyValue = chanceToLoops(GROUND_PICKUP_CONFIGS[commonCurrencyPickup].config.amount * currencyDropMulti)

						if (player.binaryFlags.has('scrapyard')) {

							const totalWeight = scrapyardWeightList.reduce((acc, drop) => acc + drop.weight, 0);

							for (let i = 0; i < currencyValue; i++) {
								const randomWeight = Math.random() * totalWeight;

								for (const drop of scrapyardWeightList) {
									const force = new Vector()
									getRandomPointInCircleRange(0, 0, 1000, 2000, undefined, force)

									if (randomWeight < drop.weight) {
										if (drop.type === 'currency') {
											this.groundItemDropStack.push(commonCurrencyPickup);
										} else if (drop.type === 'xp') {
											let xpPickup = GroundPickupConfigType.None;
											while (xpPickup === GroundPickupConfigType.None && InGameTime.timeElapsedInSeconds < NO_MORE_XP_TIME) {
												xpPickup = AISystem.getInstance().xpDropShuffleBag.next();
											}
											allocGroundPickup(xpPickup, this.x, this.y, force.x, force.y);
										} else if (drop.type === 'rottenHeart') {
											allocGroundPickup(GroundPickupConfigType.RottenHeart, this.x, this.y, force.x, force.y);
										}
									}
								}
							}
						} else {
							for (let i = 0; i < currencyValue; i++) {
								// Droping a fountain of small coins worth 1 each, rather than a single "medium" or "large" coin worth multiple, as per a request from design
								this.groundItemDropStack.push(GroundPickupConfigType.CommonCurrencySmall)
							}
						}
	
					}

					if (xpDrops) {
						// Experience
						if (this.isWealthy && InGameTime.timeElapsedInSeconds < NO_MORE_XP_TIME) {		
							const xpToDrop: GroundPickupConfigType[] = []					
							while (xpToDrop.length < RADAR_WEALTHY_ENEMY_XP_AMOUNT) {
								const xpPickup = AISystem.getInstance().xpDropShuffleBag.next()
								if (xpPickup !== GroundPickupConfigType.None) {
									xpToDrop.push(xpPickup)
								}
							}

							splayGroundPickupsInRadius(xpToDrop, this.position.x, this.position.y, RADAR_WEALTHY_DROP_FORCE_MIN, RADAR_WEALTHY_DROP_FORCE_MAX, 0)
						} else {
							let xpPickup: GroundPickupConfigType = GroundPickupConfigType.None		
							const intelligenceDump = player.binaryFlags.has('intelligence-dump')

							if(player.binaryFlags.has('fountains-of-mana-flag')){
								const dropChance = intelligenceDump ? FOUNTAINS_OF_MANA_XP_DROP_CHANCE / PLOT_TWIST_INTELLIGENCE_DUMP : FOUNTAINS_OF_MANA_XP_DROP_CHANCE
								if(Math.random() < dropChance){
									xpPickup = AISystem.getInstance().xpDropShuffleBag.next()
								}
							} else if(player.binaryFlags.has('intelligence-dump')){
								if (Math.random() < PLOT_TWIST_INTELLIGENCE_DUMP){
									xpPickup = AISystem.getInstance().xpDropShuffleBag.next()
								}
							} else {
								xpPickup = AISystem.getInstance().xpDropShuffleBag.next()
							}
							
							// if we don't find a pickup, roll the player's bonus drop chance
							let xpDropChance = player.stats.getStat('xpReDropChance')
							if (player.binaryFlags.has('barbarian-practical-skills')) {
								const dist = distanceSquaredVV(this.position, player.position)
								if (dist <= getBarbarianPassiveSkillRange() ** 2) {
									xpDropChance *= BARBARIAN_PASSIVE_BASE_STATS_DATA.paracticalSkillsXpMult
								}
								
							}

							if(intelligenceDump){
								xpDropChance *= PLOT_TWIST_INTELLIGENCE_DUMP
							}
							
							if (xpPickup === GroundPickupConfigType.None && Math.random() < xpDropChance) {
								xpPickup = AISystem.getInstance().xpDropShuffleBag.next()
							}

							if (xpPickup !== GroundPickupConfigType.None && !debugConfig.disablePickups) {
								if (this.bonusDropMult > 1){
									let dropCount = 0
									while (dropCount < this.bonusDropMult - 1 && InGameTime.timeElapsedInSeconds < NO_MORE_XP_TIME) {
										const extraXp = AISystem.getInstance().xpDropShuffleBag.next()
										if (extraXp !== GroundPickupConfigType.None) {
											this.groundItemDropStack.push(extraXp)
											dropCount++
										}
									}
								} else {
									if (this.ghosted && Math.random() <= PLOT_TWIST_SPOOKY_GHOSTS_SUPERNATURAL_XP_DROP_CHANCE) {
										xpPickup = GroundPickupConfigType.supernaturalXP
									}
									xpPickupDropped = allocGroundPickup(xpPickup, this.x, this.y, 0, 0)
								}
							}
						}		
					}
				}
				
				if (this.damageTakenAmount > 0) {
					spawnDamageNumber(Math.round(Math.max(1, this.damageTakenAmount)), this.position.x, this.position.y, DamageNumberStyle.Enemy)
					this.damageTakenAmount = 0
				}
			}

			GameState.player.passiveSkill.onEnemyKilled(this)

			const killPOIs: KillEnemiesInCirclePOI[] = GameState.poiList
			killPOIs.forEach((poi) => {
				if (poi.onEnemyKilled) {
					poi.onEnemyKilled()
				}
			})

			// Set per enemy instance
			if (this.onKilled) {
				this.onKilled(this)
			}

			if (player.binaryFlags.has('killing-for-flowers')) {
				player.binaryFlagState['killing-for-flowers'].onKilledFn(this)
			}

			if (this.hasBuff(BuffIdentifier.Ignite) && GameState.player.binaryFlags.has('ignite-explodes')) {
				dealAOEDamageSimple(CollisionLayerBits.HitEnemyOnly, IGNITE_EXPLODE_RADIUS, this.position, Math.round(this.maxHealth * IGNITE_EXPLODE_DAMAGE_MAX_HP_SCALE), GameState.player, undefined, undefined, DAMAGE_OVER_TIME_SOURCE)
				if (!Enemy.igniteExplodeEffectConfig) { // forgive me
					Enemy.igniteExplodeEffectConfig = AssetManager.getInstance().getAssetByName('burnarang-flame-burst-explosion').data
				}
				Renderer.getInstance().addOneOffEffectByConfig(Enemy.igniteExplodeEffectConfig, this.x, this.y, this.y + 200, IGNITE_EXPLODE_RADIUS / IGNITE_EXPLODE_PFX_SIZE, DEFAULT_AOE_EXPLOSION_DURATION, true, true)
			}

			// per enemy config
			const onDeadFunctions = this.config.states.dead.onDeadFunctions
			if (onDeadFunctions) {
				for (let i = 0; i < onDeadFunctions.length; ++i) {
					onDeadFunctions[i](this)
				}
			}

			Buff.removeAll(this)
			return xpPickupDropped
		}
		return null
	}

	private dropGroundPickupFromStack(delta: number) {
		if (!this.groundItemDropStack.length) {
			return
		}

		if (debugConfig.disablePickups) {
			this.groundItemDropStack.length = 0
		}

		this.timeSinceLastDrop += delta

		if (this.timeSinceLastDrop >= 30 / 1000) {
			const randomXOff = Math.getRandomFloat(-100, 100)
			const randomYOff = Math.getRandomFloat(-100, 100)
			allocGroundPickup(this.groundItemDropStack.pop(), this.x + randomXOff, this.y + randomYOff, randomXOff * 8, randomYOff * 8)
			this.timeSinceLastDrop = 0
		}
	}

	applyHolyLight(damageSource: DamageSource) {
		Buff.apply(BuffIdentifier.HolyLight, damageSource, this)
	}

	destroy() {
		this._parentStatList.removeChild(this.statList)
	}

	private saintsSpearExplosion(damageSource) {
		if (Math.random() <= GameState.player.primaryWeapon.statList.getStat(StatType.saintsSpearChance)) {
			const enemiesInArea = CollisionSystem.getInstance().getEntitiesInArea(this.position, SAINTS_SPEAR_AOE_SIZE, CollisionLayerBits.HitEnemyOnly)
			for (const entity of enemiesInArea) {
				const enemy = entity.owner as Enemy
				if (enemy.nid !== this.nid) {
					enemy.takeDamageSimple(this.maxHealth / 2)
					if (enemy.isDead()) {
						enemy.saintsSpearExplosion(damageSource)
						HolyLight.emitHolyLight(enemy.position, SAINTS_SPEAR_AOE_SIZE)
					}
				}
			}
		}
	}

	private scaleCircularEnemyOverDistance(distance: number, scalingRate: number){
		const oldScale = this.scale
		const newScale = this.scale + distance * scalingRate
		this.setCircularScale(oldScale, newScale)
	}

	setCircularScale(oldScale, newScale) {
		this.scale = newScale
		this.gfx.setScale(newScale, newScale)
		const scalingFactor = newScale / oldScale
		this.scaleCircleColliderRadius(this.colliderComponent, scalingFactor, scalingFactor)
		this.setAvoidanceRadius()
		this.scaleZOffset()
	}

	private setAvoidanceRadius() {
		this.avoidanceRadiusSquared = (this.config.baseAttributes.avoidanceRadius * this.scale) ** 2
		this.speedBoostRadiusSquared = ((this.config.baseAttributes.avoidanceRadius * this.scale) + ENEMY_BOOST_RADIUS_BONUS) ** 2
	}

	private scaleZOffset() {
		const baseZOffset = this.config.appearance.zOffset ?? 0
		this.zOffset = baseZOffset * this.scale
	}

	private canMerge(nearbyEnemy: Enemy) {
		return AISystem.getInstance().monsterMergeTwist
		&& AISystem.getInstance().mergeCooldownUp()
		&& !this.isMerged 
		&& !this.toBeMerged
		&& !nearbyEnemy.toBeMerged
		&& !nearbyEnemy.isMerged
		&& nearbyEnemy.name === this.name
		&& checkIfPointIsInView(this.position)
		&& distanceSquaredVV(this.position, nearbyEnemy.position) <= (this.colliderComponent.bounds.width + AISystem.getInstance().monsterMergeRadiusPadding) ** 2
	}

	/* TODO revisit when we add projectiles 
	madeProjectile(proj: ServerProjectile) {
		const now = RealTime.timestampOfCurrentFrameStartInMs * 0.001
		this._latestProjectileLifespan = Math.max(this._latestProjectileLifespan, now + proj.lifespan)
	} */

	@debugtool
	makeDebugVisuals() {
		this.debugVisuals = new Container()
		this.debugVisuals['update'] = (delta: timeInSeconds) => {
			this.debugVisuals.x = this.position.x
			this.debugVisuals.y = this.position.y
		}

		this.colliderGraphics = new Graphics()
		this.colliderBoundsGraphics = new Graphics()

		this.debugVisuals.addChild(this.colliderGraphics)
		this.debugVisuals.addChild(this.colliderBoundsGraphics)

		this.colliderGraphics.clear()
		this.colliderGraphics.lineStyle(3, 0x40B01A)
		for (const collider of this.colliderComponent.colliders) {
			const pos = collider.offsetPos
			if (collider.type === ColliderType.Circle) {
				this.colliderGraphics.drawCircle(pos.x, pos.y, collider.r)
			} else if (collider.type === ColliderType.Box) {
				// this is borked in general with boxes, slightly concerning for the bounds
			}
		}

		this.colliderComponent.drawColliders()

		this.colliderBoundsGraphics.clear()
		this.colliderBoundsGraphics.lineStyle(3, 0xf44336)
		//const colliderboundsRadius = Math.sqrt(((this.colliderComponent.bounds.height / 2) ** 2) + ((this.colliderComponent.bounds.width / 2) ** 2))
		const bounds = this.colliderComponent.bounds
		this.colliderBoundsGraphics.drawRect(bounds.minX, bounds.minY, bounds.width, bounds.height)

		this.debugVisuals.position.x = this.position.x
		this.debugVisuals.position.y = this.position.y
	}
	dramaticShowdownRemoveEnemies(){
		const effectConfig = AssetManager.getInstance().getAssetByName('dramatic-disappearance').data
		Renderer.getInstance().addOneOffEffectByConfig(effectConfig, this.x, this.y, 9999, 1, undefined, undefined, false)
		this.toBeDeleted = true
	}
}


function attributesToStatList(stats: EnemyAIBaseStats, parentStatList: EntityStatList): EntityStatList {
	return new EntityStatList((statList) => {
		defaultStatAttribute(statList)
		for (const key in stats) { //TODO: I love this because it reduces boilerplate, but it also isn't explicit. Does anyone care?
			statList._actualStatValues[key] = stats[key]
		}
	}, parentStatList)
}

function getEnemyParentStatlist(enemyName: ENEMY_NAME): EntityStatList {
	if (COMMON_ENEMY_NAMES.includes(enemyName)) {
		return CommonEnemyGlobalStatList
	} else if (UNCOMMON_ENEMY_NAMES.includes(enemyName)) {
		return UncommonEnemyGlobalStatList
	} else if (BOSS_ENEMY_NAMES.includes(enemyName)) {
		return BossEnemyGlobalStatList
	}
	return EnemyGlobalStatList
}

function applyKnockback(enemy: Enemy, damageEntity: DamageSource) {
	if (enemy.knockbackImmune) {
		return
	}

	if (!damageEntity.statList.getStat('attackKnockback')) {
		return
	}

	let knockBackDirection = damageEntity.getKnockbackDirection(enemy.position.clone())

	knockBackDirection.x *= damageEntity.statList.getStat('attackKnockback')
	knockBackDirection.y *= damageEntity.statList.getStat('attackKnockback')
	enemy.addKnockBack(knockBackDirection)
	// enemy.knockbackVelocity = enemy.knockbackVelocity.add(knockBackDirection)
}

export function stunEnemy(enemy: Enemy, owner: any, stunDuration?: timeInMilliseconds) {
	if (!stunDuration) {
		stunDuration = DEFAULT_STUN_DURATION
	}
	
	if (enemy.isBoss) {
		stunDuration *= BOSS_STUN_TIMESCALE
	}

	Buff.apply(BuffIdentifier.Stun, owner, enemy,undefined, stunDuration)
}