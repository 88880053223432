import { Buff } from "../buffs/buff"
import { BuffDefinition } from "../buffs/buff-definition"
import { BuffTags, StackStyle } from "../buffs/buff-enums"
import { PlayerBinaryFlags } from "../buffs/buff-system"
import { BuffIdentifier } from "../buffs/buff.shared"
import { Player } from "../entities/player"
import { StatBonusValue } from "../stats/entity-stat-list"

import { StatName, StatOperator, StatType, StatUnit } from "../stats/stat-interfaces-enums"
import { AllWeaponTypes } from "../weapons/weapon-types"
import { PRIMARY_WEAPON_UPGRADES } from "./primary-weapon-upgrades"
import { SECONDARY_WEAPON_UPGRADES } from "./secondary-weapon-upgrades"
import { CHARACTER_UPGRADES } from "./character-upgrades"
import { Audio } from "../engine/audio"
import { StatSelectors } from "../stats/stat-selector"
import { PetRescueEventSystem } from "../events/pet-rescue-gameplay-event"
import { UpgradePools } from "./upgrade-manager"
import { AffectedEntities, AffectedEntitiesAllPrimaryWeapons, AffectedEntitiesAllSecondaryWeapons } from './upgrade-entities'
import { EVENT_UPGRADES } from "./boss-and-event-upgrades"
import { isPlayer } from "../entities/entity-interfaces"

const SHAPE_TOP_WEIGHT = 3
const SHAPE_MIDDLE_WEIGHT = 4
const SHAPE_BOTTOM_WEIGHT = 6
const UNLOCK_SECONDARY_WEAPON_WEIGHT = 6
// additional weights are in upgrade-specific files

const upgradeCollectionNames = [
	'debugMetaProg',
	'bossCloseAndBloody',
	'bossFasterThanBefore',
	'bossStrongerBetter',
	'bossBulletHeaven',
	'bossBrokenHeart',
	'bossMasterOfElements',
	'bossHyperPets',
	'bossChainReaction',
	'bossForestFire',
	'bossBigBrainLearning',
	'bossJokersWild',
	'BossRatherBeRich',
	'bossMoreGainMorePain',
	'bossIcyComeIcyGo',
	'bossDemigodOfThunder',
	'speed',
	'straightShooter',
	'elementalConsistency',
	'pickupRange',
	'damage',
	'attackRate',
	'rangerSkillUpgrades',
	'teslaCoilUnlock',
	'teslaCoilUpgrades',
	'teslaCoilRapidExtras',
	'teslaCoilTowerExtras',
	'teslaCoilLongDistanceExtras',
	'chaining',
	'rangerPassiveUpgrades',
	'acidBottlesUnlock',
	'acidBottlesUpgrades',
	'acidBottlesLeftUpgrades',
	'acidBottlesMiddleUpgrades',
	'acidBottlesRightUpgrades',
	'ratParadeUnlock',
	'ratParadeUpgrades',
	'ratParadeLeftUpgrades',
	'ratParadeMiddleUpgrades',
	'ratParadeRightUpgrades',
	'bowUpgrades',
	'longbowEvolution',
	'longbowFinalForm',
	'bowAssassinsArchUpgrades',
	'bowAssassinsArchFinalForm',
	'bowStormBreakerUpgrades',
	'bowStormBreakerFinalForm',
	'wandUpgrades',
	'pierce',
	'projectilesForward',
	'nikolaScopeUnlock',
	'nikolaScopeUpgrades',
	'nikolaScopeLaserLightExtras',
	'nikolaScopeShockInfusedExtras',
	'nikolaScopePrettyOPExtras',
	'splitting',
	'health',
	'dangerDiving',
	'killstreak',
	'wandTacticalStrikeEvolution',
	'wandTacticalStrikeFinalForm',
	'wandRapidFireEvolution',
	'wandRapidFireFinalForm',
	'wandMarkswitchEvolution',
	'wandMarkswitchFinalForm',
	'attackSize',
	'petBoost',
	'barbarianPassiveUpgrades',
	'barbarianSkillUpgrades',
	'boomerangUpgrades',
	'chakramUpgrades',
	'chakramFinalForm',
	'outbackUpgrades',
	'outbackFinalForm',
	'ricochetRangUpgrades',
	'ricochetRangFinalForm',
	'coneDogUpgrades',
	'coneDogChompUpgrades',
	'coneDogSynergyUpgrades',
	'coneDogThornsUpgrades',
	'ignite',
	'poison',
	'doom',
	'beesUnlock',
	'beesUpgrades',
	'beesBeestormExtras',
	'beesKillerWaspsExtras',
	'beesBeesForeverExtras',
	'spearBase',
	'spearNaginata',
	'spearNaginataFinalForm',
	'spearHolySpear',
	'spearHolySpearFinalForm',
	'spearGrandPike',
	'spearGrandPikeFinalForm',
	'luteUnlock',
	'luteUpgrades',
	'luteDDRExtras',
	'luteStatusExtras',
	'lutePowerUpExtras',
	'darkStormyNightUnlock',
	'darkStormyNightUpgrades',
	'darkStormyNightStrikesExtras',
	'darkStormyNightSkillExtras',
	'darkStormyNightThunderExtras',
	'solarAttunementUpgrades',
	'lunarAttunementUpgrades',
	'solarUltimate',
	'lunarUltimate',
	'equinoxUltimate',
	'radarShockPulseUpgrades',
	'radarGadgetUpgrades',

	'fireFairiesUnlock',
	'fireFairiesUpgrades',
	'fireFairiesSkyfireFairyExtras',
	'fireFairiesFairyDanceExtras',
	'fireFairiesFlamesForeverExtras',
	'phoenixBombsUnlock',
	'phoenixBombsUpgrades',
	'phoenixpaloozaExtras',
	'phoenixGrowthExtras',
	'phoenixRebirthExtras',

	'cannonUpgrades',
	'cannonParasiticUpgrades',
	'cannonParasiticFinalForm',
	'cannonChemicalUpgrades',
	'cannonChemicalFinalForm',
	'cannonAtomicUpgrades',
	'cannonAtomicFinalForm'

] as const

export type UpgradeCollectionName = typeof upgradeCollectionNames[number]

export type UpgradeCollection = UpgradeDiamond | UpgradeTree | UpgradeSingle | UpgradeLine

export type UpgradeStatBonuses = [StatSelectors, StatName, StatOperator, StatBonusValue][]

export type UpgradeDefinition = {
	name: string,
	desc: string,
	/** Which pools the upgrade can show up in. Filled in manually or programmatically if undefined. */
	pools?: UpgradePools,
	icon?: string
	upgradeIndex?: number
	/** These buffs get auto-applied when the upgrade is chosen. */
	autoAppliedBuff?: BuffDefinition
	/** These buffs are used somehow by this upgrade (often a binary flag), but aren't auto-applied. */
	extraBuffs?: BuffDefinition[]
	binaryFlags?: PlayerBinaryFlags[]

	/** Takes the statName, operatorType, and stat number from the StatBonus constructor*/
	statBonuses?: UpgradeStatBonuses
	simpleApplyFn?: (player: Player, state: any) => void
	simpleRemoveFn?: (player: Player, state: any) => void

	unlocks?: UpgradeCollectionName[]
	// Does this lock out another upgrade tree? (Solara)
	locks?: UpgradeCollectionName[]
	affectedEntities: AffectedEntities[]
}

interface UpgradeShapeBase {
	shape: 'diamond' | 'tree' | 'single' | 'line'
	name: string
	desc: string
	/** Indicates that this upgrade tree is for the Steam version of the game and shouldn't be rolled in the web version */
	isSteam?: boolean
	isUnlock?: boolean
	// The number of prequisites required unlock this upgrade (used for Solara's equinox ultimate)
	unlockPrereqs?: number
	metaStartsLocked?: boolean

	requireWeapon?: AllWeaponTypes // temp filtering for switching primary weapons
	isSecondaryUnlock?: boolean
	parentIndex?: string
	upgradeEvolutionTier?: 'tier1' | 'tier2' | 'tier3' // Only for evolution upgrades UI

	upgradeAllowedFunction?: () => boolean
}

interface UpgradeDiamond extends UpgradeShapeBase {
	shape: 'diamond'
	top: UpgradeDefinition,
	left: UpgradeDefinition,
	right: UpgradeDefinition,
	bottom: UpgradeDefinition,
}

interface UpgradeTree extends UpgradeShapeBase {
	shape: 'tree'
	top: UpgradeDefinition,
	left: UpgradeDefinition,
	right: UpgradeDefinition,
	leftLeaf: UpgradeDefinition,
	middleLeaf: UpgradeDefinition,
	rightLeaf: UpgradeDefinition
}

interface UpgradeSingle extends UpgradeShapeBase {
	shape: 'single'
	top: UpgradeDefinition
}

interface UpgradeLine extends UpgradeShapeBase {
	shape: 'line'
	upgrades: UpgradeDefinition[]
}

export type UpgradeCollections = Record<UpgradeCollectionName, UpgradeCollection>

export function getUpgradesOfCollection(c: UpgradeCollection): UpgradeDefinition[] {
	if (c.shape === "diamond") {
		return [c.top, c.left, c.right, c.bottom]
	} else if (c.shape === "tree") {
		return [c.top, c.left, c.right, c.leftLeaf, c.middleLeaf, c.rightLeaf]
	} else if (c.shape === 'single') {
		return [c.top]
	} else if (c.shape === 'line') {
		return c.upgrades
	}
}

const GENERIC_UPGRADES: Partial<UpgradeCollections> = {
	"petBoost": {
		name: "Pet Upgrades",
		desc: "",
		shape: "diamond",
		top: {
			name: "Pet Caller",
			desc: "Call for a Pet to aid you!",
			icon: 'upgrade-pet-caller',
			pools: {
				"level-up": 2,
				"boss": 0,
				"event": 0,
			},
			simpleApplyFn(player, state) {
				PetRescueEventSystem.getInstance().createAndAddPet()
			},
			affectedEntities: [AffectedEntities.Pet],
		},
		left: {
			name: "Petnip",
			desc: "Massively increase all Pet {glossary.cooldown_speed}",
			icon: 'upgrade-petnip',
			statBonuses: [
				[['Pets'], 'cooldownInterval', StatOperator.MULTIPLY, -0.333333],
			],
			affectedEntities: [AffectedEntities.Pet],
		},
		right: {
			name: "Aikido Darling",
			desc: "Massively increase all Pet {glossary.damage} and {glossary.attack_size}",
			icon: 'upgrade-aikido-darling',
			statBonuses: [
				[['Pets'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.6],
				[['Pets'], 'attackSize', StatOperator.SUM_THEN_MULTIPLY, 0.5],
			],
			affectedEntities: [AffectedEntities.Pet],
		},
		bottom: {
			name: "Two's Company",
			desc: "Another two Pets come to your aid!",
			icon: 'upgrade-twos-company',
			simpleApplyFn(player, state) {
				PetRescueEventSystem.getInstance().createAndAddPet()
				PetRescueEventSystem.getInstance().createAndAddPet()
			},
			affectedEntities: [AffectedEntities.Pet],
		},
	},

	"straightShooter": {
		name: "Straight Shooter",
		desc: "",
		shape: "single",
		isUnlock: true,
		top: {
			name: "Straight Shooter",
			desc: "{glossary.sacrifice} your {glossary.chain} to gain a massive {glossary.damage} increase, and {glossary.sacrifice} your {glossary.split} to gain an even larger {glossary.damage} increase",
			icon: 'upgrade-straight-shooter',
			pools: {
				"level-up": 2,
				"boss": 0,
				"event": 0,
			},
			simpleApplyFn(player: Player, state) {
				// console.log('Straight Shooter converters')
				// console.log(`before ${player.stats.getStat('projectileChainCount')} chain, ${player.stats.getStat('allDamageMult')} dmg`)
				state.converter1 = {
					inputStatType: StatType.projectileChainCount,
					inputStatUnit: StatUnit.Number,
					inputRatio: 1.0,
					inputFreeRatio: 0.0,
					inputMinReserve: 0.0,
					outputStatType: StatType.allDamageMult,
					outputStatOperator: StatOperator.SUM_THEN_MULTIPLY,
					outputStatUnit: StatUnit.Percentage,
					outputRatio: 0.55,
				}
				player.stats.addConverter(state.converter1)
				// console.log(`after ${player.stats.getStat('projectileChainCount')} chain, ${player.stats.getStat('allDamageMult')} dmg`)
				// console.log(`before ${player.stats.getStat('projectileSplitCount')} split, ${player.stats.getStat('allDamageMult')} dmg`)
				state.converter2 = {
					inputStatType: StatType.projectileSplitCount,
					inputStatUnit: StatUnit.Number,
					inputRatio: 1.0,
					inputFreeRatio: 0.0,
					inputMinReserve: 0.0,
					outputStatType: StatType.allDamageMult,
					outputStatOperator: StatOperator.SUM_THEN_MULTIPLY,
					outputStatUnit: StatUnit.Percentage,
					outputRatio: 0.9,
				}
				player.stats.addConverter(state.converter2)
				// console.log(`after ${player.stats.getStat('projectileSplitCount')} split, ${player.stats.getStat('allDamageMult')} dmg`)
			},
			simpleRemoveFn(player, state) {
				console.log({ state })
				player.stats.removeConverter(state.converter1)
				player.stats.removeConverter(state.converter2)
			},
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},
	},

	"elementalConsistency": {
		name: "Elemental Consistency",
		desc: "",
		shape: "single",
		isUnlock: true,
		top: {
			name: "Elemental Consistency",
			desc: "Attacks gain a moderate chance to inflict ANY {glossary.ailment} you currently have",
			icon: 'upgrade-elemental-consistency',
			pools: {
				"level-up": 2,
				"boss": 0,
				"event": 0,
			},
			binaryFlags: ['elemental-consistency'],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},
	},

	"pickupRange": {
		name: "Pickup Range Upgrades",
		desc: "",
		shape: "diamond",

		top: {
			name: "Longer Forearm",
			desc: "Moderately increases your {glossary.pick_up_range}",
			icon: 'upgrade-longer-forearm',
			autoAppliedBuff: new BuffDefinition({
				identifier: BuffIdentifier.PickupTop,
				tags: [BuffTags.Upgrade],
				duration: 0,
				lastsForever: true,
				startingStacks: 1,
				stackStyle: StackStyle.None,
				showToClient: false,
				applyFn(buff: Buff) {
					const player = buff.appliedTo
					if (isPlayer(player)) {
						buff.state.bonus = player.stats.addStatBonus('pickupRange', StatOperator.SUM_THEN_MULTIPLY, 0.3)
						player.updateColliderRadius(player.pickupColliderComponent, player.stats.getStat('pickupRange'))
					} else {
						console.warn("Applying an upgrade to a non-player, you probably don't wanna do this!")
					}
				},
				wearOffFn(buff: Buff) {
					const player = buff.appliedTo
					if (isPlayer(player)) {
						buff.state.bonus.remove()
						player.updateColliderRadius(player.pickupColliderComponent, player.stats.getStat('pickupRange'))
					}
				},
			}),
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
		},
		left: {
			name: "Steal & Heal",
			desc: "Moderately increases your {glossary.pick_up_range}, Hearts drop a bit more often",
			icon: 'upgrade-steal-and-heal',
			autoAppliedBuff: new BuffDefinition({
				identifier: BuffIdentifier.PickupLeft,
				tags: [BuffTags.Upgrade],
				duration: 0,
				lastsForever: true,
				startingStacks: 1,
				stackStyle: StackStyle.None,
				showToClient: false,
				applyFn(buff: Buff) {
					const player = buff.appliedTo
					if (isPlayer(player)) {
						buff.state.bonus1 = player.stats.addStatBonus('pickupRange', StatOperator.SUM_THEN_MULTIPLY, 0.3)
						buff.state.bonus2 = player.stats.addStatBonus('heartDropMulti', StatOperator.SUM_THEN_MULTIPLY, 0.3)
						player.updateColliderRadius(player.pickupColliderComponent, player.stats.getStat('pickupRange'))
					} else {
						console.warn("Applying an upgrade to a non-player, you probably don't wanna do this!")
					}
				},
				wearOffFn(buff: Buff) {
					buff.state.bonus1.remove()
					buff.state.bonus2.remove()
				},
			}),
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
		},
		right: {
			name: "The Wise One",
			desc: "Slightly increase {glossary.pick_up_range} and {glossary.xp_drop_rate}",
			icon: 'upgrade-the-wise-one',
			autoAppliedBuff: new BuffDefinition({
				identifier: BuffIdentifier.PickupRight,
				tags: [BuffTags.Upgrade],
				duration: 0,
				lastsForever: true,
				startingStacks: 1,
				stackStyle: StackStyle.None,
				showToClient: false,
				applyFn(buff: Buff) {
					const player = buff.appliedTo
					if (isPlayer(player)) {
						buff.state.bonus1 = player.stats.addStatBonus('pickupRange', StatOperator.SUM_THEN_MULTIPLY, 0.2)
						buff.state.bonus2 = player.stats.addStatBonus('xpReDropChance', StatOperator.SUM, 0.2)
						player.updateColliderRadius(player.pickupColliderComponent, player.stats.getStat('pickupRange'))
					} else {
						console.warn("Applying an upgrade to a non-player, you probably don't wanna do this!")
					}
				},
				wearOffFn(buff: Buff) {
					buff.state.bonus1.remove()
					buff.state.bonus2.remove()
					const player = buff.appliedTo as Player
					player.updateColliderRadius(player.pickupColliderComponent, player.stats.getStat('pickupRange'))
				},
			}),
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
		},
		bottom: {
			name: "Skillful Collection",
			desc: "Whenever you use your Skill, briefly triple {glossary.pick_up_range}",
			binaryFlags: ['big-pickup-on-skill-use'],
			icon: 'upgrade-skillful-collection',
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
		}
	},


	"damage": {
		name: "Damage Upgrades",
		desc: "",
		shape: "diamond",
		top: {
			name: "Big Stick",
			desc: "Slightly increase {glossary.damage}",
			icon: 'upgrade-big-stick',
			statBonuses: [
				[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.15]
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},
		left: {
			name: "Primary Specialist",
			desc: "Greatly increase {glossary.primary_weapon_damage}, and slightly increase {glossary.attack_size}",

			icon: 'upgrade-primary-specialist',
			statBonuses: [
				[['Player'], 'attackSize', StatOperator.SUM_THEN_MULTIPLY, 0.10],
				[['PrimaryWeapon'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.40]
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},
		right: {
			name: "Secondary Specialist",
			desc: "Slightly increase {glossary.secondary_weapon_damage}, and {glossary.cooldown_speed}",
			icon: 'upgrade-secondary-specialist',
			statBonuses: [
				[['SecondaryWeapons'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.20],
				[['Player'], 'cooldownInterval', StatOperator.MULTIPLY, -0.10]
			],
			affectedEntities: [...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Player, AffectedEntities.ConeDog],
		},
		bottom: {
			name: "Massive Attacks",
			desc: "Moderately increase {glossary.damage} and slightly increase {glossary.attack_size}, but shoot slower",
			icon: 'upgrade-massive-attack',
			statBonuses: [
				[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.30],
				[['Player'], 'attackSize', StatOperator.SUM_THEN_MULTIPLY, 0.17],
				[['Player'], 'attackRate', StatOperator.MULTIPLY, -0.20],
				[['Player'], 'chargeRate', StatOperator.MULTIPLY, -0.20],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},
	},
	"speed": {
		name: "Speed Upgrades",
		desc: "",
		shape: "diamond",
		top: {
			// Except it's localized
			name: "Hustle Up", // can't change this name without change to unlock validator on griddle
			desc: "Slightly increase {glossary.movement_speed}",
			icon: 'upgrade-hustle-up',
			statBonuses: [
				[['Player'], 'movementSpeed', StatOperator.SUM_THEN_MULTIPLY, 0.09],
			],
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
		},
		left: {
			name: "Quicktomancy", // can't change this name without change to unlock validator on griddle
			desc: "Slightly increase {glossary.movement_speed} and {glossary.pick_up_range}",
			icon: 'upgrade-quicktomancy',
			statBonuses: [
				[['Player'], 'movementSpeed', StatOperator.SUM_THEN_MULTIPLY, 0.09],
				[['Player'], 'pickupRange', StatOperator.SUM_THEN_MULTIPLY, 0.20],
			],
			simpleApplyFn(player, state) {
				player.updateColliderRadius(player.pickupColliderComponent, player.stats.getStat(StatType.pickupRange))
			},
			simpleRemoveFn(player, state) {
				player.updateColliderRadius(player.pickupColliderComponent, player.stats.getStat(StatType.pickupRange))
			},
			affectedEntities: [AffectedEntities.Player],
		},
		right: {
			name: "Fleetfooted", // can't change this name without change to unlock validator on griddle
			desc: "Moderately increase {glossary.movement_speed} and your character skill's {glossary.cooldown_speed}",
			icon: 'upgrade-fleet-flooted',
			statBonuses: [
				[['Player'], 'movementSpeed', StatOperator.SUM_THEN_MULTIPLY, 0.12],
				[['PlayerSkill'], 'cooldownInterval', StatOperator.MULTIPLY, -0.15],
			],
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
		},
		bottom: {
			name: "Photon", // can't change this name without change to unlock validator on griddle
			desc: "Massively increase {glossary.movement_speed}, {glossary.sacrifice} 1 heart",
			icon: 'upgrade-photon',
			statBonuses: [
				[['Player'], 'movementSpeed', StatOperator.SUM_THEN_MULTIPLY, 0.20],
				[['Player'], 'maxHealth', StatOperator.SUM, -2],
			],
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
			simpleApplyFn(player: Player, state) {
				player.heal(0)
			},
		}
	},

	"attackSize": {
		name: "Attack Size Upgrades",
		desc: "",
		shape: "diamond",
		top: {
			name: "Bigger is Better",
			desc: "Moderately increase {glossary.attack_size}",
			icon: 'upgrade-bigger-is-better',
			statBonuses: [
				[['Player'], 'attackSize', StatOperator.SUM_THEN_MULTIPLY, 0.20],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},
		left: {
			name: "Make a Thump",
			desc: "Moderately increase {glossary.damage}, and greatly increase Primary Weapon {glossary.knockback}",
			icon: 'upgrade-make-a-thump',
			statBonuses: [
				[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.20],
				[['PrimaryWeapon'], 'attackKnockback', StatOperator.SUM_THEN_MULTIPLY, 0.50],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},
		right: {
			name: "All About That Size",
			desc: "Moderately increase Secondary Weapon {glossary.attack_size} and slightly increase Primary Weapon {glossary.attack_size}",
			icon: 'upgrade-all-about-that-size',
			statBonuses: [
				[['PrimaryWeapon'], 'attackSize', StatOperator.SUM_THEN_MULTIPLY, 0.20],
				[['SecondaryWeapons'], 'attackSize', StatOperator.SUM_THEN_MULTIPLY, 0.30],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},
		bottom: {
			name: "Something That Big's Gotta Hurt",
			desc: "A large portion of your {glossary.attack_size} is added as {glossary.damage}",
			icon: 'upgrade-something-that-bigs-gotta-hurt',
			statBonuses: [
				[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.10],
			],
			simpleApplyFn(player: Player, state) {
				console.log('Something That Big upgrade converter')
				console.log(`before ${player.stats.getStat('attackSize')} size, ${player.stats.getStat('allDamageMult')} dmg`)
				state.converter = {
					inputStatType: StatType.attackSize,
					inputStatUnit: StatUnit.Percentage,
					inputRatio: 0.0,
					inputFreeRatio: 0.40,
					inputMinReserve: 1.0,
					outputStatType: StatType.allDamageMult,
					outputStatOperator: StatOperator.SUM_THEN_MULTIPLY,
					outputStatUnit: StatUnit.Percentage,
					outputRatio: 1.0,
				}
				player.stats.addConverter(state.converter)
				console.log(`after ${player.stats.getStat('attackSize')} size, ${player.stats.getStat('allDamageMult')} dmg`)
			},
			simpleRemoveFn(player, state) {
				console.log({ state })
				player.stats.removeConverter(state.converter)
			},
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},
	},

	"pierce": {
		name: "Pierce Upgrades",
		desc: "",
		shape: "diamond",
		top: {
			name: "Pierce",
			desc: "Gain one {glossary.pierce}",
			icon: 'upgrade-pierce',
			statBonuses: [
				[['Player'], 'attackPierceCount', StatOperator.SUM, 1],
			],
			affectedEntities: [AffectedEntities.Bees, AffectedEntities.Bow, AffectedEntities.Lute, AffectedEntities.RatParade, AffectedEntities.Thorns, AffectedEntities.Wand, AffectedEntities.FireFairy],
		},
		left: {
			name: "Slice Thru",
			desc: "Enemies hit by an attack with {glossary.pierce} begin to {glossary.bleed}",
			icon: 'upgrade-slice-thru',
			binaryFlags: ['apply-bleed-to-pierced-enemies'],
			affectedEntities: [AffectedEntities.Bees, AffectedEntities.Bow, AffectedEntities.Lute, AffectedEntities.RatParade, AffectedEntities.Thorns, AffectedEntities.Wand, AffectedEntities.FireFairy, AffectedEntities.Boomerang],
		},
		right: {
			name: "Lethal Sniper",
			desc: "Gain one {glossary.pierce}, and massively increase {glossary.projectile_speed}",
			icon: 'upgrade-lethal-sniper',
			statBonuses: [
				[['Player'], 'attackPierceCount', StatOperator.SUM, 1],
				[['Player'], 'projectileSpeed', StatOperator.SUM_THEN_MULTIPLY, 0.4],
			],
			affectedEntities: [AffectedEntities.Bees, AffectedEntities.Bow, AffectedEntities.Lute, AffectedEntities.RatParade, AffectedEntities.Thorns, AffectedEntities.Wand, AffectedEntities.FireFairy, AffectedEntities.Boomerang],
		},
		bottom: {
			name: "Death On Rails",
			desc: "Gain two more {glossary.pierce}.",
			icon: 'upgrade-death-on-rails',
			statBonuses: [
				[['Player'], 'attackPierceCount', StatOperator.SUM, 2],
			],
			affectedEntities: [AffectedEntities.Bees, AffectedEntities.Bow, AffectedEntities.Lute, AffectedEntities.RatParade, AffectedEntities.Thorns, AffectedEntities.Wand, AffectedEntities.FireFairy],
		},
	},


	"splitting": {
		name: "Splitting Upgrades",
		desc: "",
		shape: "diamond",
		top: {
			name: "Deliberate Damage",
			desc: "Slightly increase {glossary.damage}, slightly reduce {glossary.projectile_speed}",
			icon: 'upgrade-deliberate-damage',
			statBonuses: [
				[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.15],
				[['Player'], 'projectileSpeed', StatOperator.MULTIPLY, -0.2],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},
		left: {
			name: "Slow & Steady",
			desc: "Moderately increase {glossary.damage}, slightly reduce {glossary.projectile_speed}",
			icon: 'upgrade-slow-and-steady',
			statBonuses: [
				[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.20],
				[['Player'], 'projectileSpeed', StatOperator.MULTIPLY, -0.25],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},
		right: {
			name: "Crowd Hopper",
			desc: "Primary weapon: Projectiles {glossary.split} once, but deal moderately less {glossary.damage}",
			icon: 'upgrade-crowd-hopper',
			unlocks: ['straightShooter'],
			statBonuses: [
				[['PrimaryWeapon'], 'projectileSplitCount', StatOperator.SUM, 1],
				[['PrimaryWeapon'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, -0.40],
			],
			affectedEntities: [AffectedEntities.Bow, AffectedEntities.Wand, AffectedEntities.Boomerang],
		},
		bottom: {
			name: "2-Way Split",
			desc: "Projectiles {glossary.split} once, but deal moderately less {glossary.damage}",
			icon: 'upgrade-2-way-split',
			unlocks: ['straightShooter'],
			statBonuses: [
				[['Player'], 'projectileSplitCount', StatOperator.SUM, 1],
				[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, -0.40],
			],
			affectedEntities: [AffectedEntities.Bees, AffectedEntities.Bow, AffectedEntities.Lute, AffectedEntities.Thorns, AffectedEntities.Wand, AffectedEntities.FireFairy, AffectedEntities.Boomerang],
		},
	},


	"health": {
		name: "Health Upgrades",
		desc: "",
		shape: "diamond",
		top: {
			name: "Have Heart",
			desc: "Gain a heart",
			icon: 'upgrade-have-heart',
			statBonuses: [
				[['Player'], 'maxHealth', StatOperator.SUM, 2],
			],
			simpleApplyFn(player: Player, state) {
				player.heal(2)
				Audio.getInstance().playSfx('SFX_Player_Fully_Healed')
			},
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
		},
		left: {
			name: "Regeneration",
			desc: "Recover a heart instantly, then every 60 seconds, recover a heart",
			icon: 'upgrade-regeneration',
			autoAppliedBuff: new BuffDefinition({
				identifier: BuffIdentifier.HealthRegeneration,
				tags: [BuffTags.Upgrade],
				duration: 0,
				tickInterval: 60000,
				lastsForever: true,
				startingStacks: 1,
				stackStyle: StackStyle.None,
				showToClient: true,
				tickFn(buff: Buff) {
					const player = buff.appliedTo
					if (isPlayer(player)) {
						player.heal(2)
						Audio.getInstance().playSfx('SFX_Player_Fully_Healed')
					}
				},
			}),
			simpleApplyFn(player: Player, state) {
				player.heal(2)
				Audio.getInstance().playSfx('SFX_Player_Fully_Healed')
			},
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
		},
		right: {
			name: "Cupid's Recycling",
			desc: "Hearts drop much more often",
			icon: 'upgrade-cupids-recycling',
			statBonuses: [
				[['Player'], 'heartDropMulti', StatOperator.SUM_THEN_MULTIPLY, 0.5],
			],
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
		},
		bottom: {
			name: "Bodybuilder",
			desc: "Gain 2 hearts",
			icon: 'upgrade-body-builder',
			statBonuses: [
				[['Player'], 'maxHealth', StatOperator.SUM, 4],
			],
			simpleApplyFn(player: Player, state) {
				player.heal(4)
				Audio.getInstance().playSfx('SFX_Player_Fully_Healed')
			},
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
		},
	},


	"attackRate": {
		name: "Attack Rate Upgrades",
		desc: "",
		shape: "diamond",
		top: {
			name: "Faster Attacks", // can't change this name without change to unlock validator on griddle
			desc: "Slightly increase {glossary.attack_rate}",
			icon: 'upgrade-faster-attacks',
			statBonuses: [
				[['Player'], 'attackRate', StatOperator.SUM_THEN_MULTIPLY, 0.15],
				[['Player'], 'chargeRate', StatOperator.SUM_THEN_MULTIPLY, 0.15],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, AffectedEntities.TeslaCoil, AffectedEntities.Lute, AffectedEntities.FireFairy, AffectedEntities.NikolaScope, AffectedEntities.RatParade],
		},
		left: {
			name: "Rapid Deployment", // can't change this name without change to unlock validator on griddle
			desc: "Moderately increase {glossary.cooldown_speed} for all weapons and your character's skill, and slightly increase reload rate",
			icon: 'upgrade-rapid-deployment',
			statBonuses: [
				[['Player'], 'cooldownInterval', StatOperator.MULTIPLY, -0.2],
				[['Player'], 'reloadInterval', StatOperator.MULTIPLY, -0.1],
			],
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog, AffectedEntities.Wand, ...AffectedEntitiesAllSecondaryWeapons],
		},
		right: {
			name: "High Tide Blessing", // can't change this name without change to unlock validator on griddle
			desc: "A large portion of your {glossary.attack_rate} is added as {glossary.damage}, and slightly increase {glossary.attack_rate}",
			icon: 'upgrade-high-tide-blessing',
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, AffectedEntities.TeslaCoil, AffectedEntities.Lute, AffectedEntities.FireFairy, AffectedEntities.NikolaScope, AffectedEntities.RatParade],
			statBonuses: [
				[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.10],
			],
			simpleApplyFn(player: Player, state) {
				state.converter = {
					inputStatType: StatType.attackRate,
					inputStatUnit: StatUnit.Percentage,
					inputRatio: 0.0,
					inputFreeRatio: 0.333333,
					inputMinReserve: 1.0,
					outputStatType: StatType.allDamageMult,
					outputStatOperator: StatOperator.SUM_THEN_MULTIPLY,
					outputStatUnit: StatUnit.Percentage,
					outputRatio: 1.0,
				}
				player.stats.addConverter(state.converter)
			},
			simpleRemoveFn(player, state) {
				player.stats.removeConverter(state.converter)
			},
		},
		bottom: {
			name: "Ultra Rapid Fire", // can't change this name without change to unlock validator on griddle
			desc: "Moderately increase {glossary.attack_rate}",
			icon: 'upgrade-ultra-rapid-fire',
			statBonuses: [
				[['Player'], 'attackRate', StatOperator.SUM_THEN_MULTIPLY, 0.25],
				[['Player'], 'chargeRate', StatOperator.SUM_THEN_MULTIPLY, 0.25]
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, AffectedEntities.TeslaCoil, AffectedEntities.Lute, AffectedEntities.FireFairy, AffectedEntities.NikolaScope, AffectedEntities.RatParade],
		},
	},


	'chaining': {
		name: 'Chaining Upgrades',
		desc: "",
		shape: 'diamond',
		top: {
			name: 'Chaining Attacks',
			desc: 'Attacks {glossary.chain} through one more enemy',
			icon: 'upgrade-chaining-attacks',
			unlocks: ['straightShooter'],
			statBonuses: [
				[['Player'], StatType.projectileChainCount, StatOperator.SUM, 1],
			],
			affectedEntities: [AffectedEntities.TeslaCoil, AffectedEntities.Bow, AffectedEntities.Lute, AffectedEntities.Thorns, AffectedEntities.Wand, AffectedEntities.FireFairy],
		},
		left: {
			name: 'Chain Distance',
			desc: 'Attacks can {glossary.chain} further',
			icon: 'upgrade-chain-distance',
			statBonuses: [
				[['Player'], StatType.projectileChainDistanceMultiplier, StatOperator.SUM_THEN_MULTIPLY, 0.5],
			],
			affectedEntities: [AffectedEntities.TeslaCoil, AffectedEntities.Bow, AffectedEntities.Lute, AffectedEntities.Thorns, AffectedEntities.Wand, AffectedEntities.FireFairy],
		},
		right: {
			name: 'Chain Impact',
			desc: 'Attacks that {glossary.chain} hit moderately harder, losing some power as they chain',
			icon: 'upgrade-chain-impact',
			binaryFlags: ['chain-damage-per-chain-remaining'],
			affectedEntities: [AffectedEntities.TeslaCoil, AffectedEntities.Bow, AffectedEntities.Lute, AffectedEntities.Thorns, AffectedEntities.Wand, AffectedEntities.FireFairy],
		},
		bottom: {
			name: 'Chain Recursion',
			desc: 'Your attacks can chain back to the same target and {glossary.chain} once more',
			icon: 'upgrade-chain-recursion',
			unlocks: ['straightShooter'],
			binaryFlags: ['chain-same-target'],
			statBonuses: [
				[['Player'], StatType.projectileChainCount, StatOperator.SUM, 1],
			],
			affectedEntities: [AffectedEntities.TeslaCoil, AffectedEntities.Bow, AffectedEntities.Lute, AffectedEntities.Thorns, AffectedEntities.Wand, AffectedEntities.FireFairy],
		},
	},

	'projectilesForward': {
		name: 'Forward Projectile Upgrades',
		desc: "",
		shape: 'diamond',
		top: {
			name: 'Bonus Bullet',
			desc: 'All weapons get one more projectile but they do slightly less {glossary.damage}',
			icon: 'upgrade-bonus-bullet',
			statBonuses: [
				[['Player'], StatType.projectileCount, StatOperator.SUM, 1],
				[['Player'], StatType.projectileSpreadAngle, StatOperator.SUM, 12],
				[['Player'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, -0.15],
			],
			affectedEntities: [AffectedEntities.Bees, AffectedEntities.Bow, AffectedEntities.Lute, AffectedEntities.Thorns, AffectedEntities.Wand, AffectedEntities.Boomerang, AffectedEntities.NikolaScope, AffectedEntities.AcidBottles, AffectedEntities.FireFairy, AffectedEntities.DarkStormyNight, AffectedEntities.PhoenixBombs, AffectedEntities.RatParade],
		},
		left: {
			name: 'Tighten Spread',
			desc: 'Your primary weapon has a much tighter {glossary.projectile_spread} and moderately increased {glossary.damage}',
			icon: 'upgrade-tighten-spread',
			statBonuses: [
				[['PrimaryWeapon'], StatType.projectileSpreadAngle, StatOperator.MULTIPLY, -0.5],
				[['PrimaryWeapon'], StatType.allDamageMult, StatOperator.SUM_THEN_MULTIPLY, 0.25],
			],
			affectedEntities: [AffectedEntities.Bow, AffectedEntities.Wand, AffectedEntities.Boomerang, AffectedEntities.Spear],
		},
		right: {
			name: 'Wide Spray',
			desc: 'Your primary weapon gets one more projectile, with a much broader {glossary.projectile_spread}',
			icon: 'upgrade-wide-spray',
			statBonuses: [
				[['PrimaryWeapon'], StatType.projectileCount, StatOperator.SUM, 1],
				[['PrimaryWeapon'], StatType.projectileSpreadAngle, StatOperator.SUM_THEN_MULTIPLY, 0.5],
				[['PrimaryWeapon'], StatType.projectileSpreadAngle, StatOperator.SUM, 12],
			],
			affectedEntities: [AffectedEntities.Bow, AffectedEntities.Wand, AffectedEntities.Boomerang],
		},
		bottom: {
			name: 'Even More Bullets',
			desc: 'All weapons get one more projectile',
			icon: 'upgrade-even-more-bullets',
			statBonuses: [
				[['Player'], StatType.projectileCount, StatOperator.SUM, 1],
				[['Player'], StatType.projectileSpreadAngle, StatOperator.SUM, 12],
			],
			affectedEntities: [AffectedEntities.Bees, AffectedEntities.Bow, AffectedEntities.Lute, AffectedEntities.Thorns, AffectedEntities.Wand, AffectedEntities.Boomerang, AffectedEntities.NikolaScope, AffectedEntities.AcidBottles, AffectedEntities.FireFairy, AffectedEntities.DarkStormyNight, AffectedEntities.PhoenixBombs, AffectedEntities.RatParade],
		},
	},


	'dangerDiving': {
		name: 'Danger Diving Upgrades',
		desc: "",
		shape: 'diamond',
		top: {
			name: "Banditry",
			desc: "Picking up XP slightly boosts your {glossary.movement_speed}, {glossary.attack_rate}, {glossary.charge_rate} for five seconds.",
			icon: 'upgrade-banditry',
			binaryFlags: ['on-pickup-gain-banditry'],
			extraBuffs: [
				new BuffDefinition({
					identifier: BuffIdentifier.Banditry,
					tags: [BuffTags.Movement],
					duration: 5000,
					lastsForever: false,
					startingStacks: 1,
					stackStyle: StackStyle.RefreshDuration,
					reapplyDuration: 5000,
					reapplyStacks: 1,
					showToClient: true,
					updateStacksFn(buff: Buff, oldStacks: number, newStacks: number) {
						const player = buff.appliedTo
						const speedBonus = 0.01 * Math.min(10, newStacks / 4)
						const rateBonus = 0.01 * Math.min(20, newStacks / 2)
						if (isPlayer(player)) {
							if (!buff.state.speedBonus) {
								buff.state.speedBonus = player.stats.addStatBonus('movementSpeed', StatOperator.SUM_THEN_MULTIPLY, speedBonus)
								buff.state.attackRateBonus = player.stats.addStatBonus('attackRate', StatOperator.SUM_THEN_MULTIPLY, rateBonus)
								buff.state.chargeRateBonus = player.stats.addStatBonus('chargeRate', StatOperator.SUM_THEN_MULTIPLY, rateBonus)
							} else {
								buff.state.speedBonus.update(speedBonus)
								buff.state.attackRateBonus.update(rateBonus)
								buff.state.chargeRateBonus.update(rateBonus)
							}
						}
					},
					wearOffFn(buff: Buff) {
						const { speedBonus, attackRateBonus, chargeRateBonus } = buff.state
						speedBonus.remove()
						attackRateBonus.remove()
						chargeRateBonus.remove()
					}
				}),
			],
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog, ...AffectedEntitiesAllPrimaryWeapons],
		},
		left: {
			name: 'Martial Tactics',
			desc: 'Moderately increase your {glossary.damage} to nearby targets.',
			icon: 'upgrade-martial-tactics',
			binaryFlags: ['bonus-point-blank-damage'],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
		},
		right: {
			name: 'Aether Magnet',
			desc: 'Every ten seconds of constant moving, your {glossary.pick_up_range} massively increases.',
			icon: 'upgrade-aether-magnet',
			binaryFlags: ['constant-movement-yoink'],
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
		},
		bottom: {
			name: 'Heist',
			desc: 'If you collect XP drops fast enough, enter a Phase state for 7 seconds. 60s cooldown.',
			icon: 'upgrade-heist',
			binaryFlags: ['yoink-fast-ghosted'],
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
		},
	},


	'killstreak': {
		name: 'Killstreak Upgrades',
		desc: "",
		shape: 'diamond',
		top: {
			name: "Rapid Killer", // if you change this name you must change it in 'mutator-definitions.ts' as well
			desc: "Enable Killstreaks! {glossary.kill_streak}: Moderately increase your {glossary.damage}",
			icon: 'upgrade-rapid-killer',
			binaryFlags: ['killstreak-damage'],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
			simpleApplyFn(player, state) {
				player.enableKillstreaks()
			},
		},
		left: {
			name: 'High-Speed Plunderer',
			desc: '{glossary.kill_streak}: Massively increase your {glossary.pick_up_range} and moderately increase {glossary.movement_speed}',
			icon: 'upgrade-high-speed-plunderer',
			binaryFlags: ['killstreak-pickup-movement'],
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
			simpleApplyFn(player, state) {
				player.enableKillstreaks()
			},
		},
		right: {
			name: 'Meteoric Pace',
			desc: '{glossary.kill_streak}: Moderately increase your {glossary.attack_rate}, {glossary.cooldown_speed}, and {glossary.reload}',
			icon: 'upgrade-meteoric-pace',
			binaryFlags: ['killstreak-fast-attacks'],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons],
			simpleApplyFn(player, state) {
				player.enableKillstreaks()
			},
		},
		bottom: {
			name: 'Kill Trigger',
			desc: '{glossary.kill_streak}: Every 150 kills, unleash an explosion and fire a random secondary weapon',
			icon: 'upgrade-kill-trigger',
			binaryFlags: ['killstreak-fire-random-weapon', 'killstreak-explosion'],
			affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog, ...AffectedEntitiesAllSecondaryWeapons],
			simpleApplyFn(player, state) {
				player.enableKillstreaks()
			},
		},
	},



	"ignite": {
		name: "Ignite Upgrades",
		desc: "",
		shape: "diamond",
		top: {
			name: "Only a Spark",
			desc: "Attacks and Pets gain a small chance to {glossary.ignite} enemies, and deal slightly increased {glossary.damage}",
			icon: 'upgrade-only-a-spark',
			unlocks: ['elementalConsistency'],
			statBonuses: [
				[['Player', 'Pets'], 'igniteChance', StatOperator.SUM, 0.25],
				[['Player'], 'allDamageMult', StatOperator.SUM, 0.15],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
		left: {
			name: "Burn Brighter",
			desc: "{glossary.ignite} deals greatly increased {glossary.damage}",
			icon: 'upgrade-burn-brighter',
			statBonuses: [
				[['Player', 'Pets'], 'ignitePotency', StatOperator.SUM_THEN_MULTIPLY, 0.5],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
		right: {
			name: "Dry Heat",
			desc: "Attacks and Pets gain a large chance to {glossary.ignite} enemies",
			icon: 'upgrade-dry-heat',
			statBonuses: [
				[['Player', 'Pets'], 'igniteChance', StatOperator.SUM, 0.35],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
		bottom: {
			name: "Wildfire",
			desc: "When an enemy with {glossary.ignite} dies, the {glossary.ignite} spreads to 4 nearby enemies",
			icon: 'upgrade-wild-fire',
			binaryFlags: ['ignite-spreads-on-death'],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
	},

	"poison": {
		name: "Poison Upgrades",
		desc: "",
		shape: "diamond",
		top: {
			name: "Poison-Coated Weapons", // can't change this name without change to unlock validator on griddle
			desc: "Attacks and Pets gain a small chance to {glossary.poison} enemies, and slightly increase {glossary.attack_rate}",
			icon: 'upgrade-poison-coated-weapons',
			unlocks: ['elementalConsistency'],
			statBonuses: [
				[['Player', 'Pets'], 'poisonChance', StatOperator.SUM, 0.25],
				[['Player'], 'attackRate', StatOperator.SUM, 0.10],
				[['Player'], 'chargeRate', StatOperator.SUM, 0.10],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
		left: {
			name: "Powerful Toxin", // can't change this name without change to unlock validator on griddle
			desc: "{glossary.poison} deals greatly increased damage",
			icon: 'upgrade-powerful-toxin',
			statBonuses: [
				[['Player', 'Pets'], 'poisonPotency', StatOperator.SUM_THEN_MULTIPLY, 0.5],
				[['Player'], 'attackRate', StatOperator.SUM, 0.10],
				[['Player'], 'chargeRate', StatOperator.SUM, 0.10],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
		right: {
			name: "Dripping With Venom", // can't change this name without change to unlock validator on griddle
			desc: "Attacks gain a moderate chance to {glossary.poison} enemies",
			icon: 'upgrade-dripping-with-venom',
			statBonuses: [
				[['Player', 'Pets'], 'poisonChance', StatOperator.SUM, 0.35],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
		bottom: {
			name: "Poisonous Corruption", // can't change this name without change to unlock validator on griddle
			desc: "When an enemy with {glossary.poison} dies, some of the {glossary.poison} is spread out to 5 nearby enemies",
			icon: 'upgrade-fast-acting-poison',
			binaryFlags: ['poison-spreads-on-death-50'],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
	},


	"doom": {
		name: "Doom Upgrades",
		desc: "",
		shape: "diamond",
		top: {
			name: "Minor Travesty",
			desc: "Attacks and Pets gain a small chance to {glossary.doom} enemies, but lose a small amount of direct {glossary.damage}",
			icon: 'upgrade-minor-travesty',
			unlocks: ['elementalConsistency'],
			statBonuses: [
				[['Player', 'Pets'], 'doomChance', StatOperator.SUM, 0.15],
				[['Player', 'Pets'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, -0.20],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
		left: {
			name: "Gloom and Doom",
			desc: "Enemies can take damage from their own {glossary.doom} explosion",
			icon: 'upgrade-gloom-and-doom',
			binaryFlags: ['doom-self-explosion-damage'],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
		right: {
			name: "For Doom the Bell Tolls",
			desc: "Attacks and Pets gain a moderate chance to {glossary.doom} enemies",
			icon: 'upgrade-for-doom-the-bell-tolls',
			statBonuses: [
				[['Player', 'Pets'], 'doomChance', StatOperator.SUM, 0.25],
			],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
		bottom: {
			name: "Widespread Calamity",
			desc: "{glossary.doom} explosions have massively increased {glossary.attack_size}",
			icon: 'upgrade-widespread-calamity',
			binaryFlags: ['doom-attack-size-40'],
			affectedEntities: [...AffectedEntitiesAllPrimaryWeapons, ...AffectedEntitiesAllSecondaryWeapons, AffectedEntities.Pet],
		},
	},


	"debugMetaProg": {
		name: "DEBUG",
		desc: "DEBUG",
		shape: "line",
		isUnlock: true,
		upgrades: [
			{
				name: "MetaProg Survivability +2",
				desc: "MetaProg loadout for survivability (+2 heart, +100% heart drop)",
				icon: 'upgrade-steal-and-heal',
				pools: {
					"level-up": 0,
					"boss": 0,
					"event": 0,
				},
				affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
				statBonuses: [
					[['Player'], 'maxHealth', StatOperator.SUM, 4],
					[['Player'], 'heartDropMulti', StatOperator.SUM_THEN_MULTIPLY, 1.0],
				],
			},
			{
				name: "MetaProg Projectiles +2",
				desc: "MetaProg loadout for a projectiles build (+2 projectiles)",
				icon: 'upgrade-steal-and-heal',
				pools: {
					"level-up": 0,
					"boss": 0,
					"event": 0,
				},
				affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
				statBonuses: [
					[['Player'], 'projectileCount', StatOperator.SUM, 2],
					[['Player'], 'projectileSpreadAngle', StatOperator.SUM, 24],
				],
			},
			{
				name: "MetaProg Split +2",
				desc: "MetaProg loadout for a projectile splitting build (+2 split)",
				icon: 'upgrade-steal-and-heal',
				pools: {
					"level-up": 0,
					"boss": 0,
					"event": 0,
				},
				affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
				statBonuses: [
					[['Player'], 'projectileSplitCount', StatOperator.SUM, 2],
				],
			},
			{
				name: "MetaProg Attack Size +50%",
				desc: "MetaProg loadout for an area attack build (+50% attack size, TODO: +1 attack zone)",
				icon: 'upgrade-steal-and-heal',
				pools: {
					"level-up": 0,
					"boss": 0,
					"event": 0,
				},
				affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
				statBonuses: [
					[['Player'], 'attackSize', StatOperator.SUM_THEN_MULTIPLY, 0.50],
				],
			},
			{
				name: "MetaProg Reload/Cooldown -20% time",
				desc: "MetaProg Reload/Cooldown -20% time",
				icon: 'upgrade-steal-and-heal',
				pools: {
					"level-up": 0,
					"boss": 0,
					"event": 0,
				},
				affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
				statBonuses: [
					[['Player'], 'reloadInterval', StatOperator.SUM_THEN_MULTIPLY, -0.20],
					[['Player'], 'cooldownInterval', StatOperator.MULTIPLY, -0.2],
				],
			},
			{
				name: "MetaProg Attack Rate +50%",
				desc: "MetaProg Attack Rate +50%",
				icon: 'upgrade-steal-and-heal',
				pools: {
					"level-up": 0,
					"boss": 0,
					"event": 0,
				},
				affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
				statBonuses: [
					[['Player'], 'attackRate', StatOperator.SUM_THEN_MULTIPLY, 0.50],
					[['Player'], 'chargeRate', StatOperator.SUM_THEN_MULTIPLY, 0.50],
				],
			},
			{
				name: "MetaProg Damage +50%",
				desc: "MetaProg Damage +50%",
				icon: 'upgrade-steal-and-heal',
				pools: {
					"level-up": 0,
					"boss": 0,
					"event": 0,
				},
				affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
				statBonuses: [
					[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.50],
				],
			},
			{
				name: "MetaProg Damage +50% again",
				desc: "MetaProg Damage +50% again",
				icon: 'upgrade-steal-and-heal',
				pools: {
					"level-up": 0,
					"boss": 0,
					"event": 0,
				},
				affectedEntities: [AffectedEntities.Player, AffectedEntities.ConeDog],
				statBonuses: [
					[['Player'], 'allDamageMult', StatOperator.SUM_THEN_MULTIPLY, 0.50],
				],
			},
		],
	},

}

export const UPGRADE_COLLECTIONS: Partial<UpgradeCollections> = {
	...GENERIC_UPGRADES,
	...PRIMARY_WEAPON_UPGRADES,
	...SECONDARY_WEAPON_UPGRADES,
	...CHARACTER_UPGRADES,
	...EVENT_UPGRADES,
}

export const WEAPON_UPGRADE_COLLECTIONS: Partial<UpgradeCollections> = {
	...PRIMARY_WEAPON_UPGRADES,
	...SECONDARY_WEAPON_UPGRADES,
}

export const NON_WEAPON_UPGRADE_COLLECTIONS: Partial<UpgradeCollections> = {
	...GENERIC_UPGRADES,
	...CHARACTER_UPGRADES,
}

export const EVENT_UPGRADE_COLLECTIONS: Partial<UpgradeCollections> = {
	...EVENT_UPGRADES,
}

function addMissingPools() {
	/**
	 * Upgrades deeper in a shape have higher weight
	 * Initial unlocks cannot roll from bosses, because it sucks for a boss to force you into a new thing
	 * Secondary unlocks and first primary weapon upgrade have higher weight, to flesh out your weapons early game
	 */
	const genericTop: UpgradePools = {
		"level-up": SHAPE_TOP_WEIGHT,
	}
	const genericMiddle: UpgradePools = {
		"level-up": SHAPE_MIDDLE_WEIGHT,
	}
	const genericBottom: UpgradePools = {
		"level-up": SHAPE_BOTTOM_WEIGHT,
	}
	const genericSingle: UpgradePools = {
		"level-up": SHAPE_BOTTOM_WEIGHT,
	}
	const unlockWeaponSingle: UpgradePools = {
		"level-up": UNLOCK_SECONDARY_WEAPON_WEIGHT,
	}
	const genericArr = [genericTop, genericMiddle, genericBottom]

	for (const [_upgradeKey, coll] of Object.entries(UPGRADE_COLLECTIONS)) {
		if (coll.shape === "diamond") {
			coll.top.pools = coll.top.pools || genericTop
			coll.left.pools = coll.left.pools || genericMiddle
			coll.right.pools = coll.right.pools || genericMiddle
			coll.bottom.pools = coll.bottom.pools || genericBottom
		} else if (coll.shape === "line") {
			coll.upgrades.forEach((upgrade, i) => {
				upgrade.pools = upgrade.pools || genericArr[Math.min(i, genericArr.length - 1)]
			})
		} else if (coll.shape === "tree") {
			// bonus weighting for our primary weapon's first unlock
			if (coll.requireWeapon) {
				coll.top.pools = coll.top.pools
			} else {
				coll.top.pools = coll.top.pools || genericTop
			}
			coll.left.pools = coll.left.pools || genericMiddle
			coll.right.pools = coll.right.pools || genericMiddle
			coll.leftLeaf.pools = coll.leftLeaf.pools || genericBottom
			coll.middleLeaf.pools = coll.middleLeaf.pools || genericBottom
			coll.rightLeaf.pools = coll.rightLeaf.pools || genericBottom
		} else if (coll.shape === "single") {
			// bonus weighting to unlock secondary weapons
			if (coll.isSecondaryUnlock) {
				coll.top.pools = coll.top.pools || unlockWeaponSingle
			} else {
				coll.top.pools = coll.top.pools || genericSingle
			}
		}
	}
}
addMissingPools()
