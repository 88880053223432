import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { VariantAI } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"

export const choreoPetHorse: VariantAI = {
    extends: ENEMY_NAME.CHOREO_PET_CAT,
    name: ENEMY_NAME.CHOREO_PET_HORSE,
    appearance: {
        asset: SpineDataName.PET_HORSE_SPRITESHEET,
        spriteSheet: true,
        skin: EnemyBipedSkinType.DEFAULT,
    },
}

export const choreoPetPlant: VariantAI = {
	extends: ENEMY_NAME.CHOREO_PET_CAT,
	name: ENEMY_NAME.CHOREO_PET_PLANT,
	appearance: {
			asset: SpineDataName.PET_PLANT_SPRITESHEET,
			spriteSheet: true,
			skin: EnemyBipedSkinType.DEFAULT,
	},
}

export const ChoreoPetCrystal: VariantAI = {
	extends: ENEMY_NAME.CHOREO_PET_CAT,
	name: ENEMY_NAME.CHOREO_PET_CRYSTAL,
	appearance: {
			asset: SpineDataName.PET_CRYSTAL_SPRITESHEET,
			spriteSheet: true,
			skin: EnemyBipedSkinType.DEFAULT,
	},
}

export const choreoPetRotSon: VariantAI = {
	extends: ENEMY_NAME.CHOREO_PET_CAT,
	name: ENEMY_NAME.CHOREO_PET_ROT_SON,
	appearance: {
			asset: SpineDataName.PET_ROT_SON_SPRITESHEET,
			spriteSheet: true,
			skin: EnemyBipedSkinType.DEFAULT,
	},
}