<template>
	<div class="levelup-prompt">
		<div class="title" :lang="this.$i18n.locale">
			{{ $t('level_up.title') }}
		</div>
		<Panel id="level-up" :panel-identifier="'levelUp'" :height="630">
			<template #panel-content>
				<div class="container">
					<div class="left-container">
						<SmallPanel :height="0" :alt-color="true">
							<template #small-panel-content>
								<div class="header-container">
									<Header :header-text="activeName" :text-alignment="'center'" :font-face="'pixl'" :font-size="'large'" :enable-drop-shadow="false"></Header>
								</div>
							</template>
						</SmallPanel>
						<div class="description">
							<Description :description-string="activeDesc"></Description>
						</div>
						<div class="icons-fixed-container">
							<div class="icons-header" :lang="this.$i18n.locale">This upgrade affects:</div>
							<div class="icons-resized-container">
								<div v-for="(loadoutItem, index) in getPlayerLoadout" :key="index" class="icon-row">
									<SquareItem :item="loadoutItem" @on-click-fn="() => {}" :selected="false" :disabled="disableIcon(loadoutItem)" :small-icons="true"></SquareItem>
								</div>
							</div>
						</div>
					</div>
					<div class="right-container">
						<div class="rolled-upgrades">
							<ItemUpdated v-for="item in upgrades" :key="item" :item="item" :on-click-prop="toggleSelectedUpgradeItem" :selected="upgradeIsSelected(item.id)" :disable-background="true" :disable-circle="false"></ItemUpdated>
						</div>
						<div class="upgrade-tree">
							<FocusedUpgrade v-if="hasSelectedUpgrade"></FocusedUpgrade>
						</div>
					</div>
				</div>
			</template>
		</Panel>
		<div class="button-container">
			<TextButton id="level-up-confirm-btn" :button-label="'Upgrade!'" :button-style="'blue'" @on-click-fn="applyUpgrades" :disabled="!hasSelectedUpgrade"></TextButton>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Panel from '../ui-components/panel/panel.vue'
import TextButton from '../ui-components/buttons/text-button.vue'
import FocusedUpgrade from '../ui-components/upgrade/focused-upgrade.vue'
import ItemUpdated from '../ui-components/item/items-updated.vue'
import Header from '../ui-components/dividers-headers/header.vue'
import SmallPanel from '../ui-components/panel/small-panel.vue'
import Description from '../ui-components/text/description.vue'
import SquareItem from '../ui-components/item/square-items.vue'
import { AffectedEntities, AffectedEntitiesAllPrimaryWeapons } from '../../upgrades/upgrade-entities'

export default {
	name: 'LevelUp',
	components: {
		Panel,
		TextButton,
		FocusedUpgrade,
		ItemUpdated,
		Header,
		SmallPanel,
		Description,
		SquareItem,
	},
	data() {
		return {
			diamond: true,
			tree: false,
		}
	},
	computed: {
		...mapGetters('levelUp', ['getUpgrade', 'activeDesc', 'activeName', 'getPlayerLoadout']),
		...mapState('levelUp', ['upgrades', 'selectedUpgrade', 'HoveredAbility', 'hoveredUpgrade']),
		...mapGetters('characterSelect', ['getCharacterData', 'getWeaponData']),
		hasSelectedUpgrade() {
			return Boolean(this.selectedUpgrade)
		},
	},
	methods: {
		...mapActions('levelUp', ['toggleSelectedUpgradeItem', 'upgradeOver', 'applyUpgrades']),
		upgradeIsSelected(upgradeIndex) {
			if (this.selectedUpgrade !== null) {
				return upgradeIndex === this.selectedUpgrade.id
			}
		},
		upgradeIsHovered(upgradeIndex) {
			if (this.hoveredUpgrade !== null && this.selectedUpgrade !== this.hoveredUpgrade) {
				return upgradeIndex === this.hoveredUpgrade.id
			}
		},
		disableIcon(loadoutItem) {
			if (!this.selectedUpgrade) {
				return
			}

			for (const entity of this.selectedUpgrade.affectedEntities) {
				if ((entity == AffectedEntities.Player || entity == AffectedEntities.ConeDog) && this.getCharacterData.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.Wand && entity === loadoutItem.icon && this.getWeaponData.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.Bow && entity === loadoutItem.icon && this.getWeaponData.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.Boomerang && entity === loadoutItem.icon && this.getWeaponData.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.Spear && entity === loadoutItem.icon && this.getWeaponData.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.Cannon && entity === loadoutItem.icon && this.getWeaponData.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.Pet && entity === loadoutItem.icon && this.getPlayerLoadout.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.TeslaCoil && entity === loadoutItem.icon && this.getPlayerLoadout.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.AcidBottles && entity === loadoutItem.icon && this.getPlayerLoadout.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.NikolaScope && entity === loadoutItem.icon && this.getPlayerLoadout.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.RatParade && entity === loadoutItem.icon && this.getPlayerLoadout.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.Bees && entity === loadoutItem.icon && this.getPlayerLoadout.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.Lute && entity === loadoutItem.icon && this.getPlayerLoadout.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.DarkStormyNight && entity === loadoutItem.icon && this.getPlayerLoadout.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.FireFairy && entity === loadoutItem.icon && this.getPlayerLoadout.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.PhoenixBombs && entity === loadoutItem.icon && this.getPlayerLoadout.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
				if (entity == AffectedEntities.PhoenixBombs && entity === loadoutItem.icon && this.getPlayerLoadout.some((data) => data.icon === loadoutItem.icon)) {
					return false
				}
			}
			return true
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../web/global/fonts/fonts.less');

.levelup-prompt {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	#level-up {
		:deep(.panel-inner) {
			border-width: 46px 11px 22px;

			.container {
				display: flex;
				flex-direction: row;
				.left-container {
					width: 546px;
					margin: 0px 10px 0px 20px;
					.header-container {
						display: flex;
						align-items: center;
						height: 100%;
						width: 546px;
						#header {
							width: 546px;
							padding: 10px 0px;
							text-align: center;
							display: flex;
							justify-content: center;
							margin: 0px 18px;
							.header {
							}
						}
					}
					.description {
						min-height: 300px;
						margin: 30px 0px 10px 0px;
					}
					.icons-fixed-container {
						transform-origin: center;
						width: 546px;
						height: 160px;
						position: relative;
						display: flex;
						flex-wrap: wrap;
						flex-direction: column;
						.icons-header {
							align-items: top;
							position: absolute;
							top: -30px;
							width: 100%;
							text-align: center;
							justify-content: center;
							&[lang='en'] {
								.regularFont(@enRegularFontPixl);
								color: @green;
								font-size: 30px;
							}

							&[lang='zh'] {
								.regularFont(@zhHansHeaderFont);
							}
						}
						.icons-resized-container {
							width: 926px;
							display: flex;
							flex-wrap: wrap;
							flex-direction: row;
							justify-content: center;
							align-content: center;
							transform: scale(0.47);
							// Because your using scale() you can use transform-origin center to change the origin.
							transform-origin: top left;
							position: absolute;
							left: 60px;
							.icon-row {
								flex: 0 0 89px;
								margin-top: 20px;
							}
						}
					}
				}
				.right-container {
					min-width: 540px;
					min-height: 540px;
					margin: 0px 20px 0px 10px;
					.rolled-upgrades {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;

						.item {
							margin: 0px 5px 0px 5px;
						}
					}
					.upgrade-tree {
						display: flex;
						align-items: center;
						justify-content: center;
						min-height: 444px;
					}
				}
			}
		}
	}
	.button-container {
		margin-top: 10px;
	}

	.title {
		margin-top: -20px;
		color: @offWhite;
		text-align: center;
		&[lang='en'] {
			.regularFont(@enSecondaryFontPTBold);
			font-variant: small-caps;
			font-size: 45px;
		}

		&[lang='zh'] {
			.regularFont(@zhHansHeaderFont);
		}
	}
}
.break {
	flex-basis: 100%;
	height: 0;
}
</style>
