import { Container } from "pixi.js"
import { Buff } from "../buffs/buff"
import { RAT_PARADE_STUN_DURATION } from "../buffs/buff-system"
import { BuffIdentifier } from "../buffs/buff.shared"
import { CollisionLayerBits } from "../engine/collision/collision-layers"
import CollisionSystem from "../engine/collision/collision-system"
import { GameState } from "../engine/game-state"
import { Effect } from "../engine/graphics/pfx/effect"
import { EffectConfig } from "../engine/graphics/pfx/effectConfig"
import { ColorPropConfig, ColorPropertyMode } from "../engine/graphics/pfx/emitterConfig"
import { Renderer } from "../engine/graphics/renderer"
import { Enemy, stunEnemy } from "../entities/enemies/enemy"
import { EntityType, IEntity } from "../entities/entity-interfaces"
import { GroundPickup } from "../entities/pickups/ground-pickup"
import { Player } from "../entities/player"
import { VectorXY } from "../utils/math"
import { AllWeaponTypes } from "../weapons/weapon-types"
import { AssetManager } from "../web/asset-manager"
import { DamageSource } from "./damage-source"
import { callbacks_addCallback } from "../utils/callback-system"
import { timeInSeconds } from "../utils/primitive-types"
import { Prop } from "../entities/prop"

type ExplosionColorPresets = 'physical' | 'fire' | 'ice' | 'lightning' | 'poison'
type ExplosionColor = {r: number, g: number, b: number, a: number}

export type ExplosionColorConfigs =  Partial<Record<ExplosionColorPresets, ExplosionColor>>

// Used to color generic aoe-explosion-white to whatever is needed for a given elemental effect 
export const ExplosionColors: ExplosionColorConfigs =  {
	"fire": {
		r: 1,
		g: 0.34901960784313724,
		b: 0,
		a: 0.51
	},
	"poison": {
		r: 0.4235294117647059,
		g: 1,
		b: 0,
		a: 0.5
	},
	"ice": {
		r: 0,
		g: 0.8235294117647058,
		b: 1,
		a: 0.64
	},
	"lightning": {
		r: 0.1607843137254902,
		g: 0,
		b: 1,
		a: 0.58
	},
	"physical": {
		r: 1,
		g: 1,
		b: 1,
		a: 0.73
	}
} as const

export function setExplosionColor(effect: Effect, colorConfig: ExplosionColorPresets) {
	const color = ExplosionColors[colorConfig]
	effect.emitters.forEach((e)=>{
			e.startColor = color
	})
}

export const DEFAULT_AOE_EXPLOSION_DURATION = 0.6
export const DEFAULT_AOE_EXPLOSION_PFX_SIZE = 200

export function dealAOEDamageSimple(collsionLayer: CollisionLayerBits, radius: number, position: VectorXY, damage: number, damager: IEntity, scaleWithPlayerLevel?: boolean, ignoreEntityId?: number, trackDamageSource?: DamageSource, overridePlayerDamage?: number) {
    const player = GameState.player
	const playerDamage = overridePlayerDamage || 1

    if (scaleWithPlayerLevel) {
        damage = damage + Math.floor(player.level / 2)
    }

    const nearbyEntities = CollisionSystem.getInstance().getEntitiesInArea(position, radius, collsionLayer)
    
    const xpExploded: GroundPickup[] = []
    const pickUpExplodedXP = player.binaryFlags.has('controlled-demolition')

    for (const collider of nearbyEntities) {
        const targetEntity = collider.owner

        if(targetEntity.nid === ignoreEntityId) {
            continue
        }

        if (targetEntity.entityType === EntityType.Player) {
            const player = targetEntity as Player
            player.takeDamage(playerDamage, damager, true)
        } else if (targetEntity.entityType === EntityType.Enemy && targetEntity.nid !== damager.nid && !targetEntity.isDead()) {
            const enemy = targetEntity as Enemy
            const xp = enemy.takeDamageSimple(damage, undefined, undefined, trackDamageSource)

            if (pickUpExplodedXP && xp) {
                xpExploded.push(xp)
            }
        }
    }

    if (pickUpExplodedXP) {
        if (xpExploded.length > 1) {
            for (let i = 0; i < xpExploded.length; ++i) {
                xpExploded[i].onPickedUp(player)
            }
        }
    }
}

let explosionEffectConfig

export function dealAOEDamageDamageSource(collsionLayer: CollisionLayerBits, radius: number, position: VectorXY, damager: DamageSource, damageScale?: number, ignoreKnockBack?: boolean, ignoreEntityId?: number, showExplosionPFX: boolean = false, explosionColor?: ExplosionColorPresets, overrideExplosionEffect?: EffectConfig) {
	const nearbyEntities = CollisionSystem.getInstance().getEntitiesInArea(position, radius, collsionLayer)

	const player = GameState.player
	let xpExploded: GroundPickup[] = []
	const pickUpExplodedXP = player.binaryFlags.has('controlled-demolition')
	const hasHolyLight = player.binaryFlags.has('spear-holy-light')

	for (const collider of nearbyEntities) {
		const targetEntity = collider.owner

		if (targetEntity.nid === ignoreEntityId) {
			continue
		}

		if (targetEntity.entityType === EntityType.Player) {
			const player = targetEntity as Player
			player.takeDamage(1, damager, true)
		} else if (targetEntity.entityType === EntityType.Enemy && targetEntity.nid !== damager.nid && !targetEntity.isDead()) {
			const enemy = targetEntity as Enemy

			if (damager.weaponType === AllWeaponTypes.RatParade && GameState.player.binaryFlags.has('rat-parade-stun')) {
				stunEnemy(enemy, damager, RAT_PARADE_STUN_DURATION)
			}
			const xp = enemy.onHitByDamageSource(damager, damageScale, ignoreKnockBack)

			if (damager.weaponType === AllWeaponTypes.Spear && hasHolyLight) {
				enemy.applyHolyLight(damager)
			}

			if (pickUpExplodedXP && xp) {
				xpExploded.push(xp)
			}
		} else if (targetEntity.entityType === EntityType.Prop) {
			const prop = targetEntity as Prop
			// TODO: Should we consider addind prop damage to other AOE damage sources?
			if (prop.isDestructible && damager.weaponType === AllWeaponTypes.Cannon) {
				prop.takeDamage(true)
			}
		}
	}

	if (showExplosionPFX || overrideExplosionEffect) {
		if (!explosionEffectConfig) {
			explosionEffectConfig = AssetManager.getInstance().getAssetByName('aoe-explosion-white')?.data
		}
		let effectConfig = overrideExplosionEffect ?? explosionEffectConfig

		const pfx = Renderer.getInstance().addOneOffEffectByConfig(effectConfig, position.x, position.y, position.y + 200, radius / DEFAULT_AOE_EXPLOSION_PFX_SIZE, DEFAULT_AOE_EXPLOSION_DURATION, true, true)
		setExplosionColor(pfx, explosionColor)
	}

    if (pickUpExplodedXP) {
        if (xpExploded.length > 1) {
            for (let i = 0; i < xpExploded.length; ++i) {
                xpExploded[i].onPickedUp(player)
            }
        }
    }

    return nearbyEntities
}
