import { Vector } from "sat"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import CollisionSystem from "../../../engine/collision/collision-system"
import { Effect } from "../../../engine/graphics/pfx/effect"
import { EffectConfig } from "../../../engine/graphics/pfx/effectConfig"
import { Renderer } from "../../../engine/graphics/renderer"
import { CharacterType, PLAYER_SKINS, getSkillIconFromCharacterType } from "../../../game-data/characters"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import EntityStatList from "../../../stats/entity-stat-list"
import { AssetManager } from "../../../web/asset-manager"
import { SkillWeapon } from "../../skill-weapon"
import { AllWeaponTypes } from "../../weapon-types"
import { Enemy, stunEnemy } from "../../../entities/enemies/enemy"
import { getIgniteStacks } from "../../../buffs/generic-buff-definitions"
import { getDamageByPlayerLevel } from "../../../game-data/player-formulas"
import { Buff } from "../../../buffs/buff"
import { BuffIdentifier } from "../../../buffs/buff.shared"
import { timeInSeconds } from "../../../utils/primitive-types"
import { InGameTime } from "../../../utils/time"
import { pl } from "date-fns/locale"
import { UI } from "../../../ui/ui"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { Audio } from "../../../engine/audio"
import { PlayerBinaryFlags } from "../../../buffs/buff-system"
import { Player } from "../../../entities/player"
import { Camera } from "../../../engine/graphics/camera-logic"

// TODO check with design for real number
const SOLAR_FLARE_DAMAGE_STACKS_MOD = 4.0
const BASE_SOLAR_FLARE_RADIUS = 500
const BASE_SOLAR_FLARE_EFFECT_SCALE = 1.25

const BASE_LUNAR_CRUSH_RADIUS = 650
const BASE_LUNAR_CRUSH_EFFECT_SCALE = 2.5
const LUNAR_CRUSH_PHASE_DURATION = 1_500
const LUNAR_CRUSH_STUN_DURATION = 1_500
const LUNAR_CRUSH_VACUUM_DURATION = 0.5
const LUNAR_CRUSH_PULL_FORCE = 200

const STARRY_NIGHT_DURATION = 2_250

const SUMMERS_RAGE_RADIUS_MULT = 1.3
const ULTIMATE_RADIUS_MULT = 1.8

const SOLAR_FLARE_SHAKE_AMOUNT = 0.65
const LUNAR_CRASH_SHAKE_AMOUNT = 0.55

export const SOLAR_SUPREMACY_DROP_CHANCE = 0.25
export const MOON_SOUL_DROP_RADIUS = 450
export const LUNAR_ATTUNEMENT_XP_REDUCTION = 0.2
export const WISDOM_OF_GODDESS_XP_REDUCTION = 0.1
export const SUN_SOUL_DURATION_IN_MILLIS = 8_000
export const BALM_OF_NIGHT_ACTIVE_SECONDS = 5 

export class SolaraSkillWeapon extends SkillWeapon {
	weaponType: AllWeaponTypes = AllWeaponTypes.SolaraSkill

	// Effects
	solarEffectConfig: EffectConfig = AssetManager.getInstance().getAssetByName('solara-solar-flare').data
	lunarEffectConfig: EffectConfig = AssetManager.getInstance().getAssetByName('solara-lunar-blow').data
	transformationEffectConfig: EffectConfig =  AssetManager.getInstance().getAssetByName('solara-transformation').data

	transformationEffect: Effect


	lunarCrushDurationSeconds: timeInSeconds = LUNAR_CRUSH_VACUUM_DURATION
	lunarCrushCounterSeconds: timeInSeconds = 0

	// This is only set when Solara unlocks one of her ultimates
	ultimateForm: PlayerBinaryFlags

	useSkill(): void {
		this.transformationEffect = Renderer.getInstance().addOneOffEffectByConfig(this.transformationEffectConfig, this.player.position.x, this.player.position.y, this.player.position.y)
		if (!this.player.binaryFlags.has('solar-supremacy') && !this.player.binaryFlags.has('lunar-lady')) {
			this.aspectTransform()
		}
		if (this.player.characterType === CharacterType.SolaraSun) {
			this.solarFlare()
		} else {
			this.lunarCrush()
		}

        Audio.getInstance().playSfx('SFX_Solara_Skill');
	}

	protected canUseSkill(): boolean {
		return true
	}

	update(delta: number) {
		this.updateEffectPosition()
		if (this.lunarCrushCounterSeconds <= this.lunarCrushDurationSeconds) {
			const reducedForce = LUNAR_CRUSH_PULL_FORCE / 2
			this.vacuumEffect(BASE_LUNAR_CRUSH_RADIUS, reducedForce, true, delta)
		}
	}

	private updateEffectPosition(){
		if (this.transformationEffect){
			this.transformationEffect.x = this.player.position.x
			this.transformationEffect.y = this.player.position.y
		}
	}

	aspectTransform(transformOverride?: CharacterType) {
		if ((this.player.characterType === CharacterType.SolaraSun && transformOverride !== CharacterType.SolaraSun) || transformOverride === CharacterType.SolaraMoon) {
			this.player.characterType = CharacterType.SolaraMoon
			// Update binary flag states
			if (this.player.binaryFlags.has('balm-of-the-night')) {
				const state = this.player.binaryFlagState['balm-of-the-night']
				state.timeSinceTransfrom = InGameTime.timeElapsedInSeconds
				state.heartDropped = false
			}

			// Apply lunar buffs
			Buff.apply(BuffIdentifier.LunarAttunement, this.player, this.player)

			// Remove solar buffs
			Buff.remove(this.player, BuffIdentifier.SolarAttunement)
			Buff.remove(this.player, BuffIdentifier.SpeedOfLight)
			
		} else if (this.player.characterType === CharacterType.SolaraMoon || transformOverride === CharacterType.SolaraSun) {
			this.player.characterType = CharacterType.SolaraSun
			// Apply solar buffs
			Buff.apply(BuffIdentifier.SolarAttunement, this.player, this.player)
			if (this.player.binaryFlags.has('speed-of-light')) {
				Buff.apply(BuffIdentifier.SpeedOfLight, this.player, this.player)
			}

			// Remove Lunar Buffs
			Buff.remove(this.player, BuffIdentifier.LunarAttunement)
		}
		this.player.riggedModel.skeleton.setSkinByName(PLAYER_SKINS[this.player.characterType])

		UI.getInstance().emitMutation('player/updateSkillIcon', getSkillIconFromCharacterType(this.player.characterType, this.ultimateForm))
		Audio.getInstance().playSfx('SFX_Gear_Battery')
	}

	private solarFlare() {
		let radiusMult = 1
		if (this.player.binaryFlags.has('summers-rage')) {
			radiusMult = SUMMERS_RAGE_RADIUS_MULT
		}
		if (this.player.binaryFlags.has('solar-supremacy') || this.player.binaryFlags.has('eminence-of-equinox')) {
			radiusMult = ULTIMATE_RADIUS_MULT
		}
		const attackRadius = BASE_SOLAR_FLARE_RADIUS * radiusMult
		const enemies = CollisionSystem.getInstance().getEntitiesInArea(this.player.position, attackRadius, CollisionLayerBits.HitEnemyOnly)
		for (let i = 0; i < enemies.length; ++i) {
			const enemy = enemies[i].owner as Enemy
			const stacks = getIgniteStacks(getDamageByPlayerLevel() * SOLAR_FLARE_DAMAGE_STACKS_MOD) * this.player.getStat(StatType.ignitePotency)
			Buff.apply(BuffIdentifier.Ignite, this.player, enemy, stacks)
		}
		
		if (this.player.binaryFlags.has('flash-of-brilliance')) {
			this.player.instantReload()
			this.player.secondaryWeapons.forEach((weapon) => {
				weapon.fire()
			})
		}

		Renderer.getInstance().addOneOffEffectByConfig(this.solarEffectConfig, this.player.position.x, this.player.position.y, this.player.position.y - 200, BASE_SOLAR_FLARE_EFFECT_SCALE * radiusMult)
		Audio.getInstance().playSfx('SFX_Elemental_Fire', { volume: 0.5 })

		Camera.getInstance().triggerShake(SOLAR_FLARE_SHAKE_AMOUNT)

		/* Renderer.getInstance().drawCircle({
			x: this.player.x,
			y: this.player.y,
			radius: attackRadius,
			permanent: false,
			destroyAfterSeconds: 0.8,
			color: 0xFF0000,
			scale: 1
		}) */
	}

	private lunarCrush() {
		Buff.apply(BuffIdentifier.Phase, this.player, this.player, undefined, LUNAR_CRUSH_PHASE_DURATION)

		const enemies = CollisionSystem.getInstance().getEntitiesInArea(this.player.position, BASE_LUNAR_CRUSH_RADIUS, CollisionLayerBits.HitEnemyOnly)
		for (let i = 0; i < enemies.length; ++i) {
			const enemy = enemies[i].owner as Enemy
			let duration = LUNAR_CRUSH_STUN_DURATION
			if (this.player.binaryFlags.has('starry-malevolence')) {
				duration =  STARRY_NIGHT_DURATION
				Buff.apply(BuffIdentifier.Shock, this.player, enemy, 1, duration)
			}
			stunEnemy(enemy, this.player, duration)
		}

		this.lunarCrushCounterSeconds = 0
		this.vacuumEffect(BASE_LUNAR_CRUSH_RADIUS, LUNAR_CRUSH_PULL_FORCE, false, 0)

		Renderer.getInstance().addOneOffEffectByConfig(this.lunarEffectConfig, this.player.position.x, this.player.position.y, this.player.position.y - 200, BASE_LUNAR_CRUSH_EFFECT_SCALE)
		Audio.getInstance().playSfx('SFX_Boss_Prism_Breath', { volume: 0.5 })

		/* Renderer.getInstance().drawCircle({
			x: this.player.x,
			y: this.player.y,
			radius: BASE_LUNAR_CRUSH_RADIUS,
			permanent: false,
			destroyAfterSeconds: 0.8,
			color: 0xFF0000,
			scale: 1
		}) */

		Camera.getInstance().triggerShake(LUNAR_CRASH_SHAKE_AMOUNT)
	}

	private vacuumEffect(radius: number, force: number, decayForce: boolean, delta: timeInSeconds) {
		let vacuumForce = -force
		if (decayForce) {
			const multi = 1 - (this.lunarCrushCounterSeconds / this.lunarCrushDurationSeconds)
			vacuumForce *= multi
		}
		this.lunarCrushCounterSeconds += delta
		this.player.knockbackSurroundingEnemies(radius, vacuumForce)
	}

	resetStatsFunction(statList: EntityStatList) {
		defaultStatAttribute(statList)
		statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 300
		statList._actualStatValues.reloadInterval = 35_000
	}
}