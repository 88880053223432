import { Vector } from 'sat'
import { Audio } from '../../engine/audio'
import { ColliderComponent } from '../../engine/collision/collider-component'
import { CircleColliderConfig, ColliderType } from '../../engine/collision/colliders'
import { CollisionLayerBits } from '../../engine/collision/collision-layers'
import CollisionSystem from '../../engine/collision/collision-system'
import { ComponentOwner } from '../../engine/component-owner'
import { GameState, getNID } from '../../engine/game-state';
import { SingleSpriteGraphicsComponent } from '../../engine/graphics/single-sprite-graphics-component'
import { distanceSquaredVV, getRandomDirectionVector, subVXY } from '../../utils/math';
import { percentage, timeInSeconds } from "../../utils/primitive-types";
import { ObjectPool, PoolableObject } from '../../utils/third-party/object-pool'
import { Enemy } from '../enemies/enemy';
import { EntityType, IEntity } from "../entity-interfaces";
import { Player } from '../player'
import { InGameTime } from '../../utils/time'
import { CollectorPet } from '../pets/collector-pet'
import { AllWeaponTypes } from '../../weapons/weapon-types'
import { LuteWeapon } from '../../weapons/actual-weapons/secondary/lute-weapon'
import { callbacks_addCallback } from '../../utils/callback-system'
import { getDamageByPlayerLevel, HUGE_XP_VALUE, LARGE_XP_VALUE, MEDIUM_XP_VALUE, PLOT_TWIST_HARDCORE_SURVIVAL_LARGE_HEART_STAMINA_RECOVERY, PLOT_TWIST_HARDCORE_SURVIVAL_SMALL_HEART_STAMINA_RECOVERY, PLOT_TWIST_HEARTS_EXPLOSION_AOE_RADIUS, PLOT_TWIST_HEARTS_EXPLOSION_DAMAGE_MULTI, PLOT_TWIST_HEARTS_EXPLOSION_KNOCKBACK_FORCE, PLOT_TWIST_HEARTS_EXPLOSION_PFX_SCALE, PLOT_TWIST_HEARTS_EXPLOSION_PICKUP_RADIUS_SCALE, RADAR_PICKUP_GROUP_RADIUS, SMALL_XP_VALUE, SUPERNATURAL_XP_VALUE } from '../../game-data/player-formulas'
import { DamageSource } from '../../projectiles/damage-source'
import { PetRescueEventSystem } from '../../events/pet-rescue-gameplay-event'
import { SolaraPassiveSkill } from '../../weapons/actual-weapons/passives/solara-passive-skill'
import { Buff } from '../../buffs/buff'
import { BuffIdentifier } from '../../buffs/buff.shared'
import { MONSTER_WHISTLE_LOOT_GOBLIN_CHANCE, PLANTED_XP_DURATION, SPICY_PEPPER_DURATION, SPICY_PEPPER_SPEED, getMonsterWhistleEnemyName, getNumMonsterWhistleEnemies, getPlantGrowthXpType } from '../../game-data/plot-twist-misc'
import AISystem from '../enemies/ai-system'
import { ENEMY_NAME } from '../enemies/enemy-names'
import { ExternalPhysicsForce } from '../../engine/physics/physics'
import { StatType } from '../../stats/stat-interfaces-enums'
import { CreepyEggGameplayEvent } from '../../events/creepy-egg-gameplay-event'
import { GroundPickupConfigType, GroundPickupType } from './ground-pickup-types'
import { PlantedXP } from './planted-xp'
import { debugConfig } from '../../utils/debug-config'
import { MapOption } from '../../world-generation/world-data'
import { dealAOEDamageSimple } from '../../projectiles/explosions'
import { Renderer } from '../../engine/graphics/renderer'

const XP_COLLISION_DAMAGE_SOURCE: DamageSource = {
	weaponType: AllWeaponTypes.WorldHazard,
	statList: null,
	numEntitiesChained: 0,
	numEntitiesPierced: 0,
	isPlayerOwned: () => true,
	getKnockbackDirection: () => new Vector(0, 0),
	nid: -1,
	entityType: EntityType.Projectile,
	timeScale: 1,
	showImmediateDamageNumber: false,
	update: (delta, now) => { },
}

const FRICTION = 0.02

const HEART_PICKUP_CHANCE_BY_TIME = [
	{ start: 0, chance: 0.015 },
	{ start: 151, chance: 0.012 },
	{ start: 301, chance: 0.009 },
	{ start: 451, chance: 0.006 },
	{ start: 601, chance: 0.0035 },
	{ start: 751, chance: 0.0025 },
].reverse() // to use find() later

export const GUARANTEED_HEART_DROP_MULTI = 1000

export const GROUND_PICKUP_CONFIGS = {
	[GroundPickupConfigType.HealingSmall]: {
		type: GroundPickupType.Healing,
		config: {
			amount: 2,
		},
		lifeTime: 60,
		spriteSheet: 'pickup-icons',
		sprite: 'small-heart-drop.png'
	},
	[GroundPickupConfigType.HealingLarge]: {
		type: GroundPickupType.Healing,
		config: {
			amount: 6,
		},
		lifeTime: 60,
		spriteSheet: 'pickup-icons',
		sprite: 'big-heart-drop.png'
	},
	[GroundPickupConfigType.XPSmall]: {
		type: GroundPickupType.Experience,
		config: {
			amount: SMALL_XP_VALUE,
		},
		spriteSheet: 'pickup-icons',
		sprite: 'xp-01.png'
	},
	[GroundPickupConfigType.XPMedium]: {
		type: GroundPickupType.Experience,
		config: {
			amount: MEDIUM_XP_VALUE,
		},
		spriteSheet: 'pickup-icons',
		sprite: 'xp-02.png'
	},
	[GroundPickupConfigType.XPLarge]: {
		type: GroundPickupType.Experience,
		config: {
			amount: LARGE_XP_VALUE,
		},
		spriteSheet: 'pickup-icons',
		sprite: 'xp-03.png'
	},
	[GroundPickupConfigType.XPHuge]: {
		type: GroundPickupType.Experience,
		config: {
			amount: HUGE_XP_VALUE,
		},
		spriteSheet: 'pickup-icons',
		sprite: 'spectral-farming-xp.png'
	},
	[GroundPickupConfigType.supernaturalXP]: {
		type: GroundPickupType.Experience,
		config: {
			amount: SUPERNATURAL_XP_VALUE,
		},
		spriteSheet: 'pickup-icons',
		sprite: 'supernatural-xp.png'
	},
	[GroundPickupConfigType.MagnetSmall]: {
		type: GroundPickupType.Magnet,
		config: {
			radius: 2500,
		},
		spriteSheet: 'pickup-icons',
		sprite: 'xp-pick-up.png'
	},
	[GroundPickupConfigType.MagnetLarge]: { //currently unused
		type: GroundPickupType.Magnet,
		config: {
			radius: 2200,
		},
		spriteSheet: 'pickup-icons',
		sprite: 'xp-pick-up.png'
	},
	[GroundPickupConfigType.Nuke]: {
		type: GroundPickupType.Nuke,
		config: {
			radius: 1600,
		},
		spriteSheet: 'pickup-icons',
		sprite: 'nuke.png'
	},
	[GroundPickupConfigType.Map]: {
		type: GroundPickupType.Map,
		config: {},
		spriteSheet: 'pickup-icons',
		sprite: 'pet-note.png'
	},
	[GroundPickupConfigType.LutePower]: {
		type: GroundPickupType.LutePower,
		config: {},
		spriteSheet: 'pickup-icons',
		sprite: 'lute-powerup.png',
		lifeTime: 10,
		waitForRested: true
	},
	[GroundPickupConfigType.SunSoul]: {
		type: GroundPickupType.SunSoul,
		config: {},
		spriteSheet: 'pickup-icons',
		sprite: 'sun-soul.png',
		waitForRested: true
	},
	[GroundPickupConfigType.CommonCurrencySmall]: {
		type: GroundPickupType.CommonCurrency,
		config: {
			amount: 1,
		},
		spriteSheet: 'pickup-icons',
		sprite: 'drop-paper-scraps.png'
	},
	// We only use this to access the amount of currency that should drop
	[GroundPickupConfigType.CommonCurrencyMedium]: {
		type: GroundPickupType.CommonCurrency,
		config: {
			amount: 5,
		},
		spriteSheet: 'pickup-icons',
		sprite: 'drop-paper-scraps.png'
	},
	// Ditto the above
	[GroundPickupConfigType.CommonCurrencyLarge]: {
		type: GroundPickupType.CommonCurrency,
		config: {
			amount: 10,
		},
		spriteSheet: 'pickup-icons',
		sprite: 'drop-paper-scraps.png'
	},
	[GroundPickupConfigType.RareCurrency]: {
		type: GroundPickupType.RareCurrency,
		config: {
			amount: 1,
		},
		spriteSheet: 'pickup-icons',
		sprite: 'drop-lost-scroll.png'
	},
	[GroundPickupConfigType.RottenHeart]: {
		type: GroundPickupType.RottenHeart,
		config: {
			amount: 2,
			range: 75
		},
		spriteSheet: 'pickup-icons',
		sprite: 'rotten-heart-drop.png'
	},
	[GroundPickupConfigType.CreepyEgg]: {
		type: GroundPickupType.CreepyEgg,
		config: {},
		lifeTime: 45,
		spriteSheet: 'pickup-icons',
		sprite: 'egg.png'
	},
	[GroundPickupConfigType.BiggifyElixir]: {
		type: GroundPickupType.BiggifyElixir,
		config: {},
		spriteSheet: 'pickup-icons',
		sprite: 'elixir.png'
	},
	[GroundPickupConfigType.DwindleyElixir]: {
		type: GroundPickupType.DwindleyElixir,
		config: {},
		spriteSheet: 'pickup-icons',
		sprite: 'elixir.png'
	},
	[GroundPickupConfigType.SpicyPepper]: {
		type: GroundPickupType.SpicyPepper,
		config: {},
		spriteSheet: 'pickup-icons',
		sprite: 'pepper.png'
	},
	[GroundPickupConfigType.MonsterWhistle]: {
		type: GroundPickupType.MonsterWhistle,
		config: {},
		spriteSheet: 'pickup-icons',
		sprite: 'whistle.png'
	}
}

export const GROUND_PICKUP_WEIGHTED_LIST_VALUES: Array<[GroundPickupConfigType, number]> = [
	[GroundPickupConfigType.MagnetSmall, 30],
	[undefined, 890+80], // none / heart surrogate
]

export const GROUND_PICKUP_HEARTS_WEIGHTED_LIST_VALUES: Array<[GroundPickupConfigType, number]> = [
	[GroundPickupConfigType.HealingSmall, 890],
	[GroundPickupConfigType.HealingLarge, 80],
]

export const DESTRUCTIBLE_DROP_WEIGHTED_LIST_VALUES: Record<MapOption, Array<[GroundPickupConfigType, number]>> = {
	[MapOption.Forest]: [
		[GroundPickupConfigType.HealingSmall, 73],
		[GroundPickupConfigType.XPLarge, 20],
		[GroundPickupConfigType.MagnetSmall, 7],
	],
	[MapOption.Tundra]: [
		[GroundPickupConfigType.HealingSmall, 60],
		[GroundPickupConfigType.XPLarge, 30],
		[GroundPickupConfigType.MagnetSmall, 10],
	],
	[MapOption.Fungi]: [
		[GroundPickupConfigType.HealingSmall, 60],
		[GroundPickupConfigType.XPLarge, 30],
		[GroundPickupConfigType.MagnetSmall, 10],
	],
	[MapOption.Hollow]: [
		[GroundPickupConfigType.HealingSmall, 60],
		[GroundPickupConfigType.XPLarge, 30],
		[GroundPickupConfigType.MagnetSmall, 10],
	],
}

export interface PickupAmountConfig {
	amount: number
}

export interface PickupRadiusConfig {
	radius: number
}

export interface PickupRangeConfig {
	range: number
}

export interface EmptyPickupConfig { }

export type GroundPickupConfig = PickupAmountConfig | PickupRadiusConfig | EmptyPickupConfig | PickupRangeConfig

export enum GroundPickupPoolType {
	Enemy,
	Boss,
	Destructible
}

export function getGroundPickupToDrop(pool: GroundPickupPoolType, heartChanceMulti: number): GroundPickupConfigType | null {
	if (debugConfig.disablePickups) {
		return null
	}

	if (pool === GroundPickupPoolType.Enemy) {
		const { chance } = HEART_PICKUP_CHANCE_BY_TIME.find(({ start }) => {
			return InGameTime.timeElapsedInSeconds >= start
		})

		if (Math.random() <= chance) {
			// specials
			const pickup = GameState.enemyGroundPickups.pickRandom().value[0]
			if (pickup !== undefined) {
				if (pickup !== GroundPickupConfigType.CreepyEgg || CreepyEggGameplayEvent.getInstance().isEggDropable()) {
					return pickup
				}
			}
		}

		if (Math.random() <= chance * heartChanceMulti) {
			const key = GameState.enemyHeartPickups.pickRandom()
			return key.value[0]
		}

		return null
	} else if (pool === GroundPickupPoolType.Destructible) {
		const pickup = GameState.propGroundPickups.pickRandom().value[0]
		if (pickup !== GroundPickupConfigType.CreepyEgg || CreepyEggGameplayEvent.getInstance().isEggDropable()) {
			return pickup
		}

		return null
	}
}

const BASE_COLLIDER_CONFIG: CircleColliderConfig[] = [
	{
		type: ColliderType.Circle,
		position: [0, 0],
		radius: 80,
	}
]

const PICKED_UP_ACCELERATION = 2
const PICKED_UP_DISAPPEAR_DIST_2 = 900

const DEFAULT_PICKED_UP_SPEED = 3.5

const FADE_OUT_TIME: timeInSeconds = 0.5 // disappear over this duration when auto-removed

const GFX_Z_OFFSET = -300

export interface GroundPickupIniitalParams {
	x: number
	y: number
	forceX: number
	forceY: number
	noPreemptivePickup: boolean
	waitForRestToPickup: boolean
}

export class GroundPickup implements IEntity, ComponentOwner, PoolableObject {
	static allActiveXpPickups: Map<number, GroundPickup> = new Map()
	
	// engine & collision
	entityType: EntityType = EntityType.Pickup
	nid: number
	timeScale: number = 1

	position: Vector
	velocity: Vector
	colliderComponent: ColliderComponent
	damagingCollider: ColliderComponent
	damageMultiplier: percentage = 1.0

	gfx: SingleSpriteGraphicsComponent

	// own props
	pickupType: GroundPickupType
	pickupConfig: GroundPickupConfig
	pickupConfigType: GroundPickupConfigType
	
	noPreemptivePickup: boolean
	isPickedUp: boolean = false
	pickedUpSpeed: number = DEFAULT_PICKED_UP_SPEED
	collector: Player | CollectorPet

	dropSoundThreshold: number

	pool: ObjectPool

	private entitiesCollided: number[] = []

	private waitForRestToPickup: boolean
	private baseWaitForRestToPickup: boolean
	private hasRested: boolean

	private isHidden: boolean = false

	// Is this being carried by a Choreo Pet (not to be confused with Crystal pets)
	isCarried: boolean = false
	private carrierPosition: Vector
	private carrierOffset: Vector =  new Vector(0, 0)

	private autoRemove: boolean
	readonly maxLife: number
	lifeRemaining: number

	nearbyXPPickups: Map<number, GroundPickup>
	private boundRemoveFromNearbyPickup: (pickup: GroundPickup) => void

	constructor(pickupType: GroundPickupConfigType) {
		this.nid = getNID(this)

		const config = GROUND_PICKUP_CONFIGS[pickupType]
		this.pickupType = config.type
		this.pickupConfig = config.config
		this.pickupConfigType = pickupType

		if (config.type === GroundPickupType.Experience) {
			this.nearbyXPPickups = new Map()
			this.boundRemoveFromNearbyPickup = this.removeFromNearbyPickup.bind(this)
		}

		// console.log(`Add ground item at ${position.x} ${position.y}`)
		this.position = new Vector()
		this.velocity = new Vector()

		this.gfx = new SingleSpriteGraphicsComponent(config.spriteSheet, config.sprite, this)

		this.colliderComponent = new ColliderComponent(BASE_COLLIDER_CONFIG, this, CollisionLayerBits.GroundItem)

		if (GameState.player.binaryFlags.has('butterfingers') && this.pickupType === GroundPickupType.Experience) {
			this.damagingCollider = new ColliderComponent(BASE_COLLIDER_CONFIG, this, CollisionLayerBits.HitEnemyOnly, this.onDamagingCollision.bind(this))
		}

		this.autoRemove = Boolean(config.lifeTime)
		this.maxLife = config.lifeTime

		this.waitForRestToPickup = Boolean(config.waitForRested)
		this.baseWaitForRestToPickup = this.waitForRestToPickup
	}

	setDefaultValues(defaultValues: any, overrideValues?: GroundPickupIniitalParams) {
		if (overrideValues) {
			this.position.x = overrideValues.x
			this.position.y = overrideValues.y

			this.velocity.x = overrideValues.forceX
			this.velocity.y = overrideValues.forceY

			this.colliderComponent.previousPosition.x = overrideValues.x
			this.colliderComponent.previousPosition.y = overrideValues.y

			this.waitForRestToPickup = overrideValues.waitForRestToPickup || overrideValues.noPreemptivePickup || this.baseWaitForRestToPickup
			this.hasRested = !this.waitForRestToPickup
			this.noPreemptivePickup = overrideValues.noPreemptivePickup

			if (this.pickupType === GroundPickupType.Experience && !this.waitForRestToPickup) {
				this.setNearbyXpGroups()
			}

			//TODO: play drop SFX
			this.dropSoundThreshold = 100.0 + Math.random() * 50.0 - 25.0

			this.lifeRemaining = this.maxLife

			this.isHidden = false
			this.gfx.instancedSprite.zIndex = overrideValues.y + GFX_Z_OFFSET
			this.gfx.addToScene()

			if (!this.noPreemptivePickup) {
				CollisionSystem.getInstance().addCollidable(this.colliderComponent)
			}

			GameState.addEntity(this)

			if (this.pickupType === GroundPickupType.CreepyEgg) {
				CreepyEggGameplayEvent.getInstance().isEggPickupInWorld = true
			}
		}
	}

	cleanup() {
		if (this.colliderComponent.isInScene) {
			CollisionSystem.getInstance().removeCollidable(this.colliderComponent)
		}

		if (this.damagingCollider && this.damagingCollider.isInScene) {
			CollisionSystem.getInstance().removeCollidable(this.damagingCollider)
		}
		this.damageMultiplier = 1.0

		if (this.gfx.isInScene) {
			this.gfx.removeFromScene()
		}

		GameState.removeEntity(this)

		this.isPickedUp = false
		this.pickedUpSpeed = DEFAULT_PICKED_UP_SPEED
		this.collector = null

		this.entitiesCollided.length = 0

		if (this.colliderComponent.layer !== CollisionLayerBits.GroundItem) {
			this.colliderComponent.setLayer(CollisionLayerBits.GroundItem)
		}

		if (this.pickupType === GroundPickupType.CreepyEgg) {
			CreepyEggGameplayEvent.getInstance().isEggPickupInWorld = false
		}

		this.isCarried = false
		this.carrierPosition = null
		this.carrierOffset.x = 0
		this.carrierOffset.y = 0
		this.autoRemove = Boolean(this.maxLife)
	}

	update(delta: timeInSeconds): void {
		if (!this.isPickedUp || (this.waitForRestToPickup && !this.hasRested)) {
			if (this.velocity) {
				if (this.velocity.len2() > 100) {
					this.position.x += this.velocity.x * delta
					this.position.y += this.velocity.y * delta
					this.gfx.instancedSprite.zIndex = this.position.y + GFX_Z_OFFSET

					this.velocity.x *= Math.pow(FRICTION, delta)
					this.velocity.y *= Math.pow(FRICTION, delta)
				} else {
					this.velocity.x = 0
					this.velocity.y = 0

					if (this.waitForRestToPickup) {
						this.hasRested = true

						if (!this.colliderComponent.isInScene) {
							CollisionSystem.getInstance().addCollidable(this.colliderComponent)
						}

						if (this.damagingCollider && this.damagingCollider.isInScene) {
							CollisionSystem.getInstance().removeCollidable(this.damagingCollider)
						}

						if (this.pickupType === GroundPickupType.Experience && ! this.isCarried) {
							this.setNearbyXpGroups()
						}
					}
 
					if (this.isCarried && this.carrierPosition) {
						this.position.x = this.carrierPosition.x + this.carrierOffset.x
						this.position.y = this.carrierPosition.y + this.carrierOffset.y
					}
				}
			}
		} else if (!this.isHidden && this.hasRested) {
			this.pickedUpSpeed *= 1 + (delta * PICKED_UP_ACCELERATION)

			const posDiff = this.collector.position.clone().sub(this.position)

			if (posDiff.len2() <= PICKED_UP_DISAPPEAR_DIST_2) {
				this.onPickupDone()
			} else {
				const direction = posDiff.clone().normalize()
				direction.x *= this.pickedUpSpeed
				direction.y *= this.pickedUpSpeed

				if (Math.abs(direction.x) > Math.abs(posDiff.x)) {
					direction.x = posDiff.x
				}

				if (Math.abs(direction.y) > Math.abs(posDiff.y)) {
					direction.y = posDiff.y
				}

				this.position.add(direction)
			}
		}

		if (!this.isHidden) {
			this.gfx.update()
		}

		if (this.autoRemove) {
			this.lifeRemaining -= delta

			if (!this.isPickedUp && this.lifeRemaining <= 0) {
				CollisionSystem.getInstance().removeCollidable(this.colliderComponent)
				const lerpedOut = -this.lifeRemaining / FADE_OUT_TIME

				if (lerpedOut >= 1) {
					this.pool.free(this)
					this.gfx.instancedSprite.scaleX = 1
					this.gfx.instancedSprite.scaleY = 1
				} else {
					this.gfx.instancedSprite.scaleX = Math.lerp(1, 0, lerpedOut)
					this.gfx.instancedSprite.scaleY = Math.lerp(1, 0, lerpedOut)
				}
			}
		}
	}

	onPickedUp(collector: Player | CollectorPet) {
		if (!this.isPickedUp) {
			if (this.colliderComponent.isInScene) {
				CollisionSystem.getInstance().removeCollidable(this.colliderComponent)
			}

			this.isPickedUp = true
			this.isCarried = false
			this.collector = collector

			if (this.pickupType === GroundPickupType.Experience) {
				this.removeFromNearbyXpGroups()
			}
		}
	}

	collectImmediately(player: Player) {
		this.collector = player
		this.onPickupDone()
	}

	onPickupDone() {
		if (this.collector.entityType === EntityType.Player) {
			const player = this.collector as Player
			// probably apply the buff or add the XP or w/e here
			if (this.pickupType === GroundPickupType.Experience) {
				const xpPitch = ((player.currentXP / player.nextLevel) * 0.75) + 0.8
				const config = this.pickupConfig as PickupAmountConfig
				
				if (player.binaryFlags.has('twist-spectral-hoe') && this.pickupConfigType !== GroundPickupConfigType.XPHuge) {
					// plant the XP
					PlantedXP.pool.alloc({
						dropPickup: getPlantGrowthXpType(this.pickupConfigType),
						plantTime: PLANTED_XP_DURATION,
						position: this.position
					})

					Buff.apply(BuffIdentifier.SpectralFarmerSlow, player, player)
				} else {
					player.addXP(config.amount * player.getStat('xpValueMulti'))
					Audio.getInstance().playSfx('SFX_XP_Pickup', { rate: xpPitch })
				}
			} else if (this.pickupType === GroundPickupType.Healing) {
				Audio.getInstance().playSfx('SFX_Loot_Pickup')
				if (player.binaryFlags.has('hearts-explosion') && player.currentHealth === player.lastMaxHealth) {
					applyPlotTwistHeartsExplosion(player)
				} else {
					player.heal((this.pickupConfig as PickupAmountConfig).amount)

					if (player.IsStaminaActive) {
						if (this.pickupConfigType === GroundPickupConfigType.HealingLarge) {
							player.addStamina(PLOT_TWIST_HARDCORE_SURVIVAL_LARGE_HEART_STAMINA_RECOVERY)
						} else {
							// small heart
							player.addStamina(PLOT_TWIST_HARDCORE_SURVIVAL_SMALL_HEART_STAMINA_RECOVERY)
						}
					}
				}

			} else if (this.pickupType === GroundPickupType.CommonCurrency || this.pickupType === GroundPickupType.RareCurrency) {
				player.addCurrency(this.pickupType, (this.pickupConfig as PickupAmountConfig).amount)
				Audio.getInstance().playSfx('UI_Smelting_Reward')
			} else if (this.pickupType === GroundPickupType.Magnet) {
				magnetVacuumInRadius(player, MAGNET_PICKUPS, (this.pickupConfig as PickupRadiusConfig).radius)
				Audio.getInstance().playSfx('UI_Smelting_Reward')

			} else if (this.pickupType === GroundPickupType.Nuke) {
				killEnemiesInRadius(player, (this.pickupConfig as PickupRadiusConfig).radius)
				Audio.getInstance().playSfx('UI_Smelting_Reward')
			} else if (this.pickupType === GroundPickupType.Map) {
				Audio.getInstance().playSfx('UI_Click_Daily_Reward')
				PetRescueEventSystem.getInstance().allMapsCollected()
			} else if (this.pickupType === GroundPickupType.LutePower) {
				// @TODO cooler audio
				Audio.getInstance().playSfx('SFX_Loot_Pickup')
				const lute = player.getWeapon(AllWeaponTypes.Lute) as LuteWeapon
				if (lute) {
					lute.onPowerUpPickedUp()
				}
			} else if (this.pickupType === GroundPickupType.SunSoul) {
				// @TODO cooler audio
				Audio.getInstance().playSfx('SFX_Loot_Pickup')
				const passive = player.getWeapon(AllWeaponTypes.SolaraPassive) as SolaraPassiveSkill
				if (passive) {
					passive.onPowerUpPickedUp()
				}
			} else if( this.pickupType === GroundPickupType.RottenHeart ){
				player.takeDamage((this.pickupConfig as PickupAmountConfig).amount, player)
				Audio.getInstance().playSfx('SFX_Loot_Pickup')

			} else if (this.pickupType === GroundPickupType.CreepyEgg) {
				// @TODO cooler audio
				Audio.getInstance().playSfx('SFX_Loot_Pickup')

				Buff.apply(BuffIdentifier.CreepyEgg, player, player)

				CreepyEggGameplayEvent.getInstance().setPlayerHoldingEgg(true)
			} else if (this.pickupType === GroundPickupType.BiggifyElixir) {
				// @TODO cooler audio
				Audio.getInstance().playSfx('SFX_Loot_Pickup')

				Buff.apply(BuffIdentifier.BiggifyElixir, player, player)
			} else if (this.pickupType === GroundPickupType.DwindleyElixir) {
				// @TODO cooler audio
				Audio.getInstance().playSfx('SFX_Loot_Pickup')

				Buff.apply(BuffIdentifier.DwindleyElixir, player, player)
			} else if (this.pickupType === GroundPickupType.SpicyPepper) {
				// @TODO cooler audio
				Audio.getInstance().playSfx('SFX_Loot_Pickup')
				let rollDirection: Vector
				if (player.isMovementLocked()) {
					rollDirection = player.movementInput.clone().scale(player.getStat(StatType.movementSpeed))
				} else {
					rollDirection = player.velocity.clone()
				}

				if (rollDirection.x === 0 && rollDirection.y === 0) {
					// pick a random direction if no direction is held
					const rad = Math.random() * Math.PI * 2
					rollDirection.x = Math.cos(rad)
					rollDirection.y = Math.sin(rad)

					rollDirection.scale(player.getStat(StatType.movementSpeed))
				}

				const force = new ExternalPhysicsForce(rollDirection.scale(SPICY_PEPPER_SPEED), SPICY_PEPPER_DURATION, undefined, true)
				player.externalForces.push(force)
				Buff.apply(BuffIdentifier.Invulnerable, player, player, 1, SPICY_PEPPER_DURATION * 1_000)
				Buff.apply(BuffIdentifier.SpicyPepper, player, player)

			} else if (this.pickupType === GroundPickupType.MonsterWhistle) {
				// @TODO cooler WHISTLE audio
				Audio.getInstance().playSfx('SFX_Monster_Whistle_Pickup')
				

				// get the enemy type depending on map and game time
				const enemyName = getMonsterWhistleEnemyName()
				const numEnemies = getNumMonsterWhistleEnemies()
				
				const aiSys = AISystem.getInstance()
				aiSys.spawnGroup(enemyName, numEnemies)

				if (Math.random() <= MONSTER_WHISTLE_LOOT_GOBLIN_CHANCE) {
					// spawn a goblin too
					const gropuDirection = aiSys.lastGroupDirectionRad
					aiSys.spawnGroupFromDirection(ENEMY_NAME.LOOT_GOBLIN_JESTER, 1, gropuDirection)
				}
			}
			if (player.binaryFlags.has('lootstreak')) {
				player.setKillstreak(player.currentKillstreak + 1)
			}
			this.pool.free(this)
		} else {
			// (pets)
			this.hide()
		}
	}

	addDamagingColliderToScene(damageMulti: percentage) {
		if (damageMulti) {
			this.damageMultiplier = damageMulti
		}
		if (this.damagingCollider && !this.damagingCollider.isInScene) {
			CollisionSystem.getInstance().addCollidable(this.damagingCollider)
		}
	}

	// Not for normal pickup stuff! Players and pets have their own onCollision where that happens!
	private onDamagingCollision(otherEntity: ColliderComponent, collisionVX: number, collisionVY: number) {
		// only happens with the 'butterfingers' plot twist
		const enemy = otherEntity.owner as Enemy
		const foundId = this.entitiesCollided.find((val) => val === enemy.nid)
		if (foundId) {
			return
		}
		this.entitiesCollided.push(enemy.nid)
		enemy.takeDamageSimple(Math.max(1, getDamageByPlayerLevel() * 0.6 * this.damageMultiplier), false, false, XP_COLLISION_DAMAGE_SOURCE)
	}

	private hide() {
		if (!this.isHidden) {
			this.gfx.removeFromScene()
			this.isHidden = true
		}
	}


	private setNearbyXpGroups() {
		if (this.isCarried) {
			return
		}
		const pickups = CollisionSystem.getInstance().getEntitiesInArea(this.position, RADAR_PICKUP_GROUP_RADIUS, CollisionLayerBits.PlayerPickup)
		for (let i = 0; i < pickups.length; ++i) {
			const p = pickups[i].owner as GroundPickup
			if (p.pickupType === GroundPickupType.Experience && !p.isCarried) {
				p.nearbyXPPickups.set(this.nid, this)
				this.nearbyXPPickups.set(p.nid, p)
			}
		}

		GroundPickup.allActiveXpPickups.set(this.nid, this)
	}

	private removeFromNearbyXpGroups() {
		this.nearbyXPPickups.forEach(this.boundRemoveFromNearbyPickup)

		this.nearbyXPPickups.clear()

		GroundPickup.allActiveXpPickups.delete(this.nid)
	}

	private removeFromNearbyPickup(pickup: GroundPickup) {
		pickup.nearbyXPPickups.delete(this.nid)
	}

	// Only used for special case of the Pet Choreo Event
	setCarrier(carrierPosition: Vector, offsetX: number, offsetY: number, lifeTime: timeInSeconds) {
		this.isCarried = true
		this.velocity.x = 0
		this.velocity.y = 0
		this.carrierPosition = carrierPosition
		this.carrierOffset.x = offsetX
		this.carrierOffset.y = offsetY

		// Breakin the rules here, forgive me
		this.autoRemove = true
		this.lifeRemaining = lifeTime
	}
}

const groundPickupPools: Map<GroundPickupConfigType, ObjectPool> = new Map()

export function makeGroundPickupPools() {	
	if (groundPickupPools.size > 0) {
		return
	}

	// XP needs a lot more in the pool than the other pickups
	
	let xpPool
	xpPool = new ObjectPool(() => new GroundPickup(GroundPickupConfigType.XPSmall), {}, 200, 10, `xpSmall-ground-pickup-pool`)
	groundPickupPools.set(GroundPickupConfigType.XPSmall, xpPool)

	xpPool = new ObjectPool(() => new GroundPickup(GroundPickupConfigType.XPMedium), {}, 200, 10, `xpMedium-ground-pickup-pool`)
	groundPickupPools.set(GroundPickupConfigType.XPMedium, xpPool)

	xpPool = new ObjectPool(() => new GroundPickup(GroundPickupConfigType.XPLarge), {}, 200, 10, `xpLarge-ground-pickup-pool`)
	groundPickupPools.set(GroundPickupConfigType.XPLarge, xpPool)

	const pickupConfigTypes = Object.values(GroundPickupConfigType).filter((v) => !isNaN(Number(v))) as GroundPickupConfigType[]
	for (const configType of pickupConfigTypes) {
		if (configType !== GroundPickupConfigType.None && !groundPickupPools.has(configType)) {
			const pool = new ObjectPool(() => new GroundPickup(configType), {}, 5, 2, `${configType}-ground-pickup-pool`)
			groundPickupPools.set(configType, pool)
		}
	}
}

const REUSABLE_ALLOC_PARAMS = { x: 0, y: 0, forceX: 0, forceY: 0, noPreemptivePickup: false, waitForRestToPickup: false }

export function allocGroundPickup(type: GroundPickupConfigType, x: number, y: number, forceX: number = 0, forceY: number = 0, noPreemptivePickup?: boolean, waitForRestToPickup?: boolean): GroundPickup {
	REUSABLE_ALLOC_PARAMS.x = x
	REUSABLE_ALLOC_PARAMS.y = y
	REUSABLE_ALLOC_PARAMS.forceX = forceX
	REUSABLE_ALLOC_PARAMS.forceY = forceY
	REUSABLE_ALLOC_PARAMS.noPreemptivePickup = Boolean(noPreemptivePickup)
	REUSABLE_ALLOC_PARAMS.waitForRestToPickup = Boolean(waitForRestToPickup)

	const pool = groundPickupPools.get(type)
	const pickup = pool.alloc(REUSABLE_ALLOC_PARAMS)
	pickup.pool = pool
	return pickup
}

const MAGNET_PICKUPS = [
	GroundPickupType.Healing,
	GroundPickupType.Experience,
	GroundPickupType.CommonCurrency,
	GroundPickupType.RareCurrency,
]

const CURRENCY_PICKUPS = [
	GroundPickupType.CommonCurrency,
	GroundPickupType.RareCurrency,
]

function magnetVacuumInRadius(player: Player, pickupList: GroundPickupType[], radius?: number) {
	// @TODO use collision system check instead ?
	GameState.pickupList.forEach((pickup) => {
		if (pickupList.includes(pickup.pickupType)) {
			if (pickup.pickupType === GroundPickupType.Healing) {
				if (player.currentHealth === player.lastMaxHealth && !player.binaryFlags.has('hearts-explosion')) {
					return
				}
			}

			if (pickup.isCarried) {
				return
			}

			if (radius) {
				if (distanceSquaredVV(player.position, pickup.position) <= radius * radius) {
					pickup.onPickedUp(player)
				}
			} else {
				pickup.onPickedUp(player)
			}
		}
	})
}

export function splayGroundPickupsInRadius(pickups: GroundPickupConfigType[], x: number, y: number, forceMin: number, forceMax: number, speed: timeInSeconds) {
	let i = 0
	const placementVec = new Vector(x, y)
	for (const pickupType of pickups) {
		i++
		if (pickupType !== undefined && pickupType !== null) {
			callbacks_addCallback(GroundPickup, () => {
				const randVec = new Vector(Math.getRandomInt(forceMin, forceMax), 0).rotate(Math.PI * Math.random() * 2)
				const pickup = allocGroundPickup(pickupType, placementVec.x, placementVec.y, randVec.x, randVec.y, false, true)
			}, speed * i)	
		}
	}
}

export function magnetAllCurrency(player: Player) {
	magnetVacuumInRadius(player, CURRENCY_PICKUPS)
}

export function killEnemiesInRadius(player: Player, radius: number) {
	GameState.enemyList.forEach((enemy: Enemy) => {
		const enemyToPlayerVector: Vector = subVXY(player.position, enemy.position.x, enemy.position.y - enemy.modelCenterOffset)
		const enemyToPlayerDistance = enemyToPlayerVector.len2()
		// TODO Replace hard-coded 1200 with actual screen width
		if (enemyToPlayerDistance <= radius * radius) {
			enemy.transitionToDead(true)
		}
	})
}

export function applyPlotTwistHeartsExplosion(player: Player) {
	const damage = getDamageByPlayerLevel() * PLOT_TWIST_HEARTS_EXPLOSION_DAMAGE_MULTI
	Renderer.getInstance().addOneOffEffectByConfig(player.heartsExplosionPfxConfig, player.position.x, player.position.y, player.position.y, PLOT_TWIST_HEARTS_EXPLOSION_PFX_SCALE, 0.5)
	dealAOEDamageSimple(CollisionLayerBits.HitEnemyOnly, PLOT_TWIST_HEARTS_EXPLOSION_AOE_RADIUS, player.position, damage, player)
	CollisionSystem.getInstance().knockbackAOEfromPoint(player.position, PLOT_TWIST_HEARTS_EXPLOSION_AOE_RADIUS, PLOT_TWIST_HEARTS_EXPLOSION_KNOCKBACK_FORCE)
}