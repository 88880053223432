<template>
	<div class="chapter-summary-table">
		<div class="list-data-container">
			<div class="table-container">
				<table class="table">
					<thead class="header">
						<th class="cellData" :lang="this.$i18n.locale">{{ $t('summary_table.header_player_name') }}</th>
						<th class="cellData" :lang="this.$i18n.locale">{{ $t('summary_table.header_total_Score') }}</th>
						<th class="cellData" :lang="this.$i18n.locale">{{ $t('summary_table.header_total_run_duration_in_seconds') }}</th>
						<th class="cellData" :lang="this.$i18n.locale">{{ $t('summary_table.header_sum_of_enemies_killed') }}</th>
						<th class="cellData" :lang="this.$i18n.locale">{{ $t('summary_table.header_character') }}</th>
						<th class="cellData" :lang="this.$i18n.locale">{{ $t('summary_table.header_is_win') }}</th>
					</thead>
					<tbody v-for="(rowData, rowIndex) in tableRowStats" :key="rowIndex" class="rowData">
						<td class="icon">
							<PlayerAvatar :icon-tag="rowData.iconTag" :icon-color="rowData.iconColor" :text-color="'limerGreen'" :disabled="false" :icon-size="'medium'" :show-background="true" :show-username="true" :username="rowData.username" :show-icon="true"></PlayerAvatar>
						</td>
						<td class="stat" :lang="this.$i18n.locale">
							{{ rowData.totalScore}}
						</td>
						<td class="stat" :lang="this.$i18n.locale">
							{{ rowData.totalRunDurationInSeconds}}
						</td>
						<td class="stat" :lang="this.$i18n.locale">
							{{ rowData.sumOfEnemiesKilled}}
						</td>
						<td class="character-icon" :lang="this.$i18n.locale">
							<div :class="'portrait ' + rowData.character"></div>
						</td>
						<td class="stat" :lang="this.$i18n.locale">
							{{ rowData.isWin ? 'Victory!' : 'Defeat' }}
						</td>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Pagination from '../pagination/pagination.vue'
import PlayerAvatar from '../../ui-components/item/player-avatar.vue'

export default {
	name: 'ChapterSummaryTable',
	components: {
		Pagination,
		PlayerAvatar
	},
	data() {
		return {
			tableRowStats: [],
		}
	},
	props: {
		tableRowData: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapState('story', ['chapterRunDataLoading', 'selectedChapterTableStats']),
	},
	methods: {
		getTableStats() {
			const arrayColData = this.selectedChapterTableStats
				.sort((a, b) => {
					if (b.isWin && !a.isWin) {
						return 1
					} else if (!b.isWin && a.isWin) {
						return -1
					} else {
						return 0
					}
				})
			this.tableRowStats = arrayColData
		},
		getIconColor(username){
			const result = this.selectedChapterTableStats.find(item => item.username === username);
    		return result ? result.iconColor : null;
		}
	},
	mounted() {
		this.getTableStats()
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

table, th, td {
	border: 0px;
	border-collapse: collapse
}

.chapter-summary-table {
	.list-data-container {
		position: relative;
		overflow-x: hidden;
		width: 100%;
		
		.table-container {
			overflow: auto;
			// overflow-y: scroll;
			// overflow-x: hidden;
			height: 375px;
			width: 100%;
			background-color: @darkBlue;

			.table {
				width: 100%;

				.header {
					width: 100%;
					margin-bottom: 10px;
					table-layout: fixed;
					background-color: @darkBlue;

					th {
						position: sticky;
						top: 0px;
						z-index: 303033;
						background-color: @darkBlue;
					}

					&.empty {
						height: 42px;
					}

					.cellData {
						text-align: center;
						color: @offWhite;
						padding-top: 20px;
						&[lang='en'] {
							.regularFont(@enRegularFontPixl);
							font-size: 30px;
						}
						&[lang='zh'] {
							.regularFont(@zhHansHeaderFont);
						}
					}
				}

				.rowData {
					overflow-x: hidden;
					overflow-y: hidden;
					height: 40px;
					width: 100%;

					.icon {
						height: 40px;
					}

					.stat {
						text-align: center;
						color: @limerGreen;
						overflow-y: hidden;
						overflow-x: hidden;
						min-width: 100px;
						height: 40px;


						&[lang='en'] {
							.regularFont(@enRegularFontPixl);
							font-size: 30px;
						}
						&[lang='zh'] {
							.regularFont(@zhHansHeaderFont);
						}
					}

					.character-icon {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
						height: 100%;
						.portrait {
							width: 40px;
							height: 40px;
							background-size: cover;
							&.coneDog {
								background-image: url('../../../../assets/ui/character-portraits/character-cone-dog-circle.png');
							}

							&.ranger {
								background-image: url('../../../../assets/ui/character-portraits/character-elf-n-ranjar-circle.png');
							}

							&.barbarian {
								background-image: url('../../../../assets/ui/character-portraits/character-barb-circle.png');
							}

							&.solara {
								background-image: url('../../../../assets/ui/character-portraits/character-solara-circle.png');
							}

							&.radar {
								background-image: url('../../../../assets/ui/character-portraits/character-radar-circle.png');
							}
						}
					}
				}
			}
		}
		
	}
}
</style>
