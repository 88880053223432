<template></template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'Rebind',
	components: {},
	data() {
		return {
			count: 5,
			intervalId: null,
		}
	},
	props: {},
	computed: {
	},
	methods: {
		...mapMutations('settings', ['setIsRebindingKey']),
		...mapMutations('ui', ['unregisterKeystrokeHandler', 'restorePreviousHandler', 'updatePreviousHandler']),
		handleKeyPress(event) {
			this.$emit('keyPress', event)
		},
		handleMouseClick(event) {
			const button = event.button
			let buttonString
			switch (button) {
				case 0:
				case 1:
					buttonString = 'MouseL'
					break
				case 2:
					buttonString = 'MouseR'
					break
				default:
					return
			}
			this.$emit('mouseClick', buttonString)
		},
	},
	mounted() {
		// Temporarily disable the UIStore keystroke handler while rebinding is taking place
		this.updatePreviousHandler()
		this.unregisterKeystrokeHandler()

		document.addEventListener('keydown', this.handleKeyPress)
		document.addEventListener('mousedown', this.handleMouseClick)
		this.$emit('countdown', this.count)
		this.setIsRebindingKey(true)
		this.intervalId = setInterval(() => {
			if (this.count > 0) {
				this.count--
				this.$emit('countdown', this.count);
			} else {
				clearInterval(this.intervalId)
				this.$destroy()
			}
		}, 1000)
	},
	beforeUnmount() {
		// Restore The UIStore keystroke handler while rebinding is taking place
		this.restorePreviousHandler()

		this.setIsRebindingKey(false)
		document.removeEventListener('keydown', this.handleKeyPress)
		document.removeEventListener('mousedown', this.handleMouseClick)
		this.$emit('countdown', 5);
		clearInterval(this.intervalId);
	},
}
</script>

<style lang="less" scoped></style>
