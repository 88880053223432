export enum CollisionLayerBits {
    None = 0,
    Player = 1 << 0,
    Enemy = 1 << 1,
    Prop = 1 << 2,
    PlayerProjectile = 1 << 3,
    EnemyProjectile = 1 << 4,
    GroundItem = 1 << 5,
    PlayerPickup = 1 << 6,
    PlayerGroundHazard = 1 << 7,
    HitEnemyOnly = 1 << 8,
	FlyingEnemy = 1 << 9,
    NeutralGroundHazard = 1 << 10,
    Pet = 1 << 11,
    NoProjectileProp = 1 << 12,
    PetPickup = 1 << 13,
    EnemyAndPickup = 1 << 14,
    TemporalDistortion = 1 << 15,
    EnemyAndPlayer = 1 << 16,
    HitPlayerOnly = 1 << 17
}

export const COLLISION_VS_LAYERS = {
    [CollisionLayerBits.Player]: CollisionLayerBits.Enemy | CollisionLayerBits.EnemyProjectile | CollisionLayerBits.Prop | CollisionLayerBits.FlyingEnemy | CollisionLayerBits.NeutralGroundHazard | CollisionLayerBits.NoProjectileProp | CollisionLayerBits.TemporalDistortion | CollisionLayerBits.EnemyAndPlayer | CollisionLayerBits.HitPlayerOnly,
    [CollisionLayerBits.Enemy]: CollisionLayerBits.Player | CollisionLayerBits.Prop | CollisionLayerBits.PlayerProjectile | CollisionLayerBits.PlayerGroundHazard | CollisionLayerBits.HitEnemyOnly | CollisionLayerBits.NeutralGroundHazard | CollisionLayerBits.NoProjectileProp | CollisionLayerBits.EnemyAndPickup | CollisionLayerBits.TemporalDistortion | CollisionLayerBits.EnemyAndPlayer,
    [CollisionLayerBits.Prop]: CollisionLayerBits.Enemy | CollisionLayerBits.EnemyProjectile | CollisionLayerBits.Player | CollisionLayerBits.PlayerProjectile | CollisionLayerBits.Pet | CollisionLayerBits.TemporalDistortion,
    [CollisionLayerBits.PlayerProjectile]: CollisionLayerBits.Enemy | CollisionLayerBits.Prop | CollisionLayerBits.FlyingEnemy | CollisionLayerBits.TemporalDistortion,
    [CollisionLayerBits.EnemyProjectile]: CollisionLayerBits.Player | CollisionLayerBits.Prop | CollisionLayerBits.TemporalDistortion,
    [CollisionLayerBits.GroundItem]: CollisionLayerBits.Prop | CollisionLayerBits.PlayerPickup | CollisionLayerBits.PetPickup | CollisionLayerBits.EnemyAndPickup | CollisionLayerBits.TemporalDistortion,
    [CollisionLayerBits.PlayerPickup]: CollisionLayerBits.GroundItem,
    [CollisionLayerBits.PlayerGroundHazard]: CollisionLayerBits.Enemy | CollisionLayerBits.FlyingEnemy | CollisionLayerBits.TemporalDistortion,
    [CollisionLayerBits.HitEnemyOnly]: CollisionLayerBits.Enemy | CollisionLayerBits.FlyingEnemy,
	[CollisionLayerBits.FlyingEnemy]: CollisionLayerBits.Player | CollisionLayerBits.PlayerProjectile | CollisionLayerBits.PlayerGroundHazard | CollisionLayerBits.HitEnemyOnly | CollisionLayerBits.NeutralGroundHazard | CollisionLayerBits.EnemyAndPickup | CollisionLayerBits.EnemyAndPlayer,
    [CollisionLayerBits.NeutralGroundHazard]: CollisionLayerBits.Player | CollisionLayerBits.Enemy | CollisionLayerBits.FlyingEnemy, // maybe not flying enemies? could be something play with
    [CollisionLayerBits.Pet]: CollisionLayerBits.Prop | CollisionLayerBits.NoProjectileProp,
    [CollisionLayerBits.NoProjectileProp]: CollisionLayerBits.Enemy | CollisionLayerBits.Player | CollisionLayerBits.Pet,
    [CollisionLayerBits.PetPickup]: CollisionLayerBits.GroundItem,
    [CollisionLayerBits.EnemyAndPickup]: CollisionLayerBits.Enemy | CollisionLayerBits.FlyingEnemy | CollisionLayerBits.GroundItem,
    [CollisionLayerBits.TemporalDistortion]: CollisionLayerBits.Player | CollisionLayerBits.Enemy | CollisionLayerBits.Prop | CollisionLayerBits.PlayerProjectile | CollisionLayerBits.EnemyProjectile | CollisionLayerBits.GroundItem | CollisionLayerBits.PlayerGroundHazard | CollisionLayerBits.NeutralGroundHazard | CollisionLayerBits.Pet,
    [CollisionLayerBits.EnemyAndPlayer]: CollisionLayerBits.Player | CollisionLayerBits.Enemy | CollisionLayerBits.FlyingEnemy,
    [CollisionLayerBits.HitPlayerOnly]: CollisionLayerBits.HitPlayerOnly
}
