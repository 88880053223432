import { ColliderType } from "../../../engine/collision/colliders"
import { EnemyBipedSkinType, SpineDataName } from "../../../spine-config/spine-config"
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyType, FightingBehaviours, ShotLeadPrecision } from "../ai-types"
import { ENEMY_NAME } from "../enemy-names"
import { Vector } from "sat"
import { EnemyDefaults } from "./enemy-defaults"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { PLAYER_DEFAULT_MOVEMENTSPEED } from "../../../game-data/player-formulas"

const prismfly: EnemyAI = {
	name: ENEMY_NAME.PRISMFLY_BOSS,
	type: EnemyType.BOSS,
	objectPoolInitialSize: 1,
	objectPoolGrowthSize: 1,
	appearance: {
		asset: SpineDataName.PRISMFLY_BOSS,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [],
		spriteSheet: true,
		useIdleAnim: true
	},
	baseAttributes: {
		...EnemyDefaults,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 115,
				position: [0, -30],
			},
		],
		colliderLayer: CollisionLayerBits.FlyingEnemy,
		baseStats: {
			...EnemyDefaults.baseStats,
			movementSpeed: PLAYER_DEFAULT_MOVEMENTSPEED * 1.2,
			maxHealth: 50.0,
		},
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 360,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,
		animationSpeeds: 0.25
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Enemy_Hit',
	},
	states: {
		fighting: {
			movementStrategy: {
				behaviour: FightingBehaviours.CHARGE_ATTACK,
				params: {
					holdDirectionDistance: 750, // how far away from the player we must be, before our charge is over
					holdDirectionTime: 1, // How long we must spend charging at minimum (can be 0)
					aimTime: 1.75, // how long they spend standing still aiming
					chargeLerpSpeed: [
						{
							targetSpeedMult: 4.0, // effects their speed when actually charging
							maxTime: 4 // how quickly we get to max speed of our charge
						},
					]
				},
			},
			attackConfig: {
				attackType: AttackTypes.NONE,
			},
			engagementMaxDistance: 675,
			engagementMinDistance: 0,
			modelCenterOffset: 45,
			movementMaxDistance: 725, // how close should the enemy be before charging?
			movementMinDistance: 230,
			shotLeadPrecision: ShotLeadPrecision.PERFECT,
			visualAimLockSeconds: 0.5,
		},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default prismfly
