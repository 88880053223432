// This table is only used for the End run screen for the table that has the weapon icon in it // Temp and will be replaced!
<template>
	<div class="end-run-weapons-tab">
		<table id="table">
			<thead>
				<tr class="header">
					<th v-for="(header, index) in colData" :key="index" class="col-header" :lang="this.$i18n.locale">{{ $t(header.headerName) }}</th>
				</tr>
			</thead>
			<div class="body-container">
				<tbody class="table-body">
					<tr v-for="(rowItem, rowIndex) in rowData" :key="rowIndex" class="table-row">
						<td class="table-cell large-font" :lang="this.$i18n.locale">
							<div class="icon">
								<ItemUpdated :item="rowItem" :medium-icons="true" :tooltip-enabled="true" @on-click-fn="() => {}" :selected="false" :disabled="false" :disable-background="true" :disable-circle="false"></ItemUpdated>
							</div>
						</td>
						<td :lang="this.$i18n.locale" class="table-cell">
							{{ rowItem.dps }}
						</td>
						<td :lang="this.$i18n.locale" class="table-cell">
							{{ rowItem.totalDamage }}
						</td>
					</tr>
				</tbody>
			</div>
		</table>
	</div>
</template>

<script lang="ts">
import ItemUpdated from '../item/items-updated.vue';

export default {
	name: 'IconTable',
	components: {
		ItemUpdated
	},
	data() {
		return {}
	},
	props: {
		colData: {
			type: Array,
			default: () => [],
		},
		rowData: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		// ...mapGetters('ui', ),
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),

	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.end-run-weapons-tab {
	height: 100%;

	#table {
		table-layout: fixed;
		width: 100%;

		thead,
		tbody tr {
			width: 100%;
			display: table;
			table-layout: fixed;
		}
		.header {
			font-weight: bold;
			font-size: 25px;
			.col-header {
				padding: 15px 0px;
				text-align: center;
				color: @offWhite;
				&[lang='en'] {
					.regularFont(@enRegularFontPixl);
					font-variant: small-caps;
					font-size: 34px;
					font-weight: lighter;
				}
				&[lang='zh'] {
					.regularFont(@zhHansHeaderFont);
				}
				&:first-child {
					text-align: left;
					padding-left: 17px;
				}
				&:last-child {
					padding-right: 17px;
				}
			}
		}

		.body-container {
			width: 100%;
			height: 410px;
			overflow-x: hidden;
			overflow-y: scroll;

			.table-body {
				display: block;
				.table-row {
					padding: 5px 0px;
					.table-cell {
						text-align: center;
						color: @green;
						border-bottom: 6px solid transparent;
						.icon {
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;
							.square-item {
								margin: unset;
							}
						}
						&[lang='en'] {
							.regularFont(@enRegularFontPixl);
							font-size: 36px;
							letter-spacing: 2px;
						}

						&[lang='zh'] {
							.regularFont(@zhHansHeaderFont);
						}
						&:first-child {
							text-align: left;
							color: @green;
						}
						
					}
				}
			}
		}
		
	}
}
</style>
