import { mapToRange } from "../utils/math"
import { percentage } from "../utils/primitive-types"

// NOTE: setting this lower than 0.2 makes the red not go away fully. Probably could be investigated more,but for now I'm just clamping it.
export const MIN_DAMAGE_ALPHA = 0.25
export const MOVEMENT_TRACK_END_ADJUST = 0.15
export const MOVEMENT_EPSILON = 0.1

interface TrackConfig {
	trackIndex: number
	loop: boolean
	holdLastFrame?: boolean
	animationDuration?: number
	mixInDuration?: number
	mixOutDuration?: number
	next?: AnimationTrack
}

// Used for adding some alpha blending to hit animations
export interface DamageConfig {
	// these are ratios (0->1),not percentages
	hitAnimationDamageRatios: percentage[]
}

export const basicEnemyDamageConfig: DamageConfig = {
	hitAnimationDamageRatios: [0.0, 0.3],
}

export function getHitAnimAlpha(damageConfig: DamageConfig, maxHealth: number, damage: number): number {
	const damageRatio = damage / maxHealth
	const hitRatios = damageConfig.hitAnimationDamageRatios

	//console.log(`getHitAnimAlpha maxHealth:${maxHealth} :damage:${damage}`)
	const hitAlpha = mapToRange(damageRatio, hitRatios[0], hitRatios[1], MIN_DAMAGE_ALPHA, 1, true)
	//console.log(`damageRatio:${damageRatio} hitAlpha:${hitAlpha}`)

	return hitAlpha
}

//TODO: could probably delete a bunch of this
export enum AnimationTrack {
	IDLE = 'idle',
	IDLE_NO_WEAPON = 'idle-no-weapon',
	IDLE_BOW_DRAW_BACK = 'idle-draw-back',
	IDLE_BOW_DRAWN = 'idle-drawn',
	IDLE_BOW_RELEASE = 'idle-release',
	ATTACKING_IDLE = 'attacking_idle',
	IDLE_CANNON = 'cannon-idle', 
	MOVEMENT = 'movement',
	MOVEMENT2 = 'movement2',
	MOVEMENT_BOW_DRAWN = 'movement-drawn',
	MOVEMENT_NO_WEAPON = 'movement-no-weapon',
	MOVEMENT_CANNON = 'cannon-movement',
	AIM = 'aim',
	SHOOT = 'shoot',
	SHOOT_SLOW_RECOIL = 'shoot-slow-recoil',
	SHOOT_FAST_RECOIL = 'shoot-fast-recoil',
	SHOOT_SLOW_RETURN = 'shoot-slow-return',
	SHOOT_FAST_RETURN = 'shoot-fast-return',
	SWIPE = 'swipe',
	SPEAR_STAB = 'spear-stab',
	SPEAR_DASH_START = 'spear-dash-start',
	SPEAR_DASH_IDLE = 'spear-dash-idle',
	SPEAR_DASH_END = 'spear-dash-end',
	HIT = 'hit',
	HIT_CANNON = 'cannon-hit',
	INTRO = 'intro',
	OUTRO = 'outro',
	TELEPORT_DISAPPEAR = 'teleport-disappear',
	TELEPORT_REAPPEAR = 'teleport-reappear',
	INTERACT = 'interact',
	DODGE_ROLL = 'dodge-roll',
	DODGE_ROLL_INVULN = 'dodge-roll-shield',
	DODGE_ROLL_CANNON = 'cannon-dodge-roll',
	SPECIAL_ABILITY_AOE = 'special-ability-aoe',
	SPECIAL_ABILITY_TARGETED = 'special-ability-targeted',
	SPAWN = 'spawn',
	DESPAWN = 'despawn',
	DEATH = 'death',
	DEATH_CANNON = 'cannon-death',
	LONG_RANGE_ATTACK = 'longrangeattack',
	SHORT_RANGE_ATTACK = 'shortrangeattack',
	EMERGE = 'emerge',
	SUBMERGE = 'submerge',
	SPRAY_ATTACK = 'spray-attack',
	SPREAD_SPORES = 'spread-spores',
	SUMMON_MINIONS = 'summon-minions',
	SUMMON_MINIONS_SHORT = 'summon-minions-short',
	EXPLODE = 'explode',
	DAMAGE_IDLE = 'damage-idle',
	DESPAWN_IDLE = 'despawn-idle',
	SUBMERGE_IDLE = 'submerge-idle',
	DANCE = 'dance',
	DIG = 'dig',
	GROUND_ATTACK = 'ground-attack',
	ARMOR_IDLE = 'armor-idle',
	ARMOR_MOVEMENT = 'armor-movement',
	SKELETON_SHOOT = 'skeleton-shoot',
	STOMP = 'stomp',
	BOMBARD = 'bombard',
	ARMOR_UP = 'armor-up',
	FOG_CLOUDS = 'fog-clouds',
	FOG_TILE = 'fog-tile',
	HIDE_WORM = 'dig-down',
	SHOW_WORM = 'wormmail-dig-up',
	WORMMAIL_IDLE = 'wormmail-idle',
	WORM_GONE_IDLE = 'hole',
	ATTACK_360 = '360',
	//HEALING_SPRING = 'healing-spring',
	HEALING_SPRING_END = 'healing-spring-end',
	HEALING_SPRING_LOOP = 'healing-spring-loop',
	HEALING_SPRING_START = 'healing-spring-start',
	POINT_ATTACK = 'point-attack',
	SKY_RAID_IN = 'sky-raid-in',
	SKY_RAID_LANDING = 'sky-raid-landing',
	PRISMATIC_BEAM = 'prismatic-beam',
	CRYSTAL_COCOON_IN = 'crystal-cocoon-in',
	CRYSTAL_COCOON_IDLE = 'crystal-cocoon-idle',
	CRYSTAL_COCOON_OUT = 'crystal-cocoon-out',
	CRYSTAL_BREATH = 'crystal-breath',
	WING_GUST = 'wing-gust',
	EXPLOSIVE_SHARDS = 'explosive-shards',
	ONYX_SHIELD = 'onyx-shield',
	PETRIFYING_WAVE = 'petrifying-wave',
	STONE_RAIN = 'stone-rain',
	DAMAGED_45 = 'damaged-45',
	DAMAGED_HORIZONTAL = 'damaged-horizontal',
	DAMAGED_VERTICAL = 'damaged-vertical',
	DEATH_45 = 'death-45',
	DEATH_HORIZONTAL = 'death-horizontal',
	DEATH_VERTICAL = 'death-vertical',
	HIT_45 = 'hit-45',
	HIT_VERTICAL = 'hit-vertical',
	HIT_HORIZONTAL = 'hit-horizontal',
	HIT_DAMAGED_45 = 'hit-damaged-45',
	HIT_DAMAGED_HORIZONTAL = 'hit-damaged-horizontal',
	HIT_DAMAGED_VERTICAL = 'hit-damaged-vertical',
	IDLE_45 = 'idle-45',
	IDLE_HORIZONTAL = 'idle-horizontal',
	IDLE_VERTICAL = 'idle-vertical',
	INTRO_45 = 'intro-45',
	INTRO_HORIZONTAL = 'intro-horizontal',
	INTRO_VERTICAL = 'intro-vertical',
	EMOTE_NONE = 'none',
	EMOTE_TYPING = 'typing-indicator',
	EMOTE_LOOP = 'loop',
	EMOTE_ANIMATION = 'animation',
	GENERIC_UNVEIL = 'generic-unveil',
	RUN = 'run',
	BLIZZARD_CLOUDS = 'blizzard-clouds',
	BLIZZARD_TILE = 'blizzard-tile',
	PHOENIX_BOMB_BOMB = 'phoenix-bomb-bomb',
	PHOENIX_BOMB_FLYING = 'phoenix-bomb-flying',
	PHOENIX_BOMB_RESURRECTION = 'phoenix-bomb-resurrection',
}

const MAX_TRACK_INDEX = 5

const AnimationTrackData: Map<AnimationTrack, TrackConfig> = new Map()
AnimationTrackData.set(AnimationTrack.FOG_TILE, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.BLIZZARD_CLOUDS, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.BLIZZARD_TILE, { trackIndex: 1, loop: true })
AnimationTrackData.set(AnimationTrack.FOG_CLOUDS, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.IDLE, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.IDLE_CANNON, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.IDLE_NO_WEAPON, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.ATTACKING_IDLE, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.MOVEMENT, { trackIndex: 1, loop: true, mixOutDuration: 0.1 })
AnimationTrackData.set(AnimationTrack.MOVEMENT_CANNON, { trackIndex: 1, loop: true, mixOutDuration: 0.1 })
AnimationTrackData.set(AnimationTrack.RUN, { trackIndex: 1, loop: true, mixOutDuration: 0.1 })
AnimationTrackData.set(AnimationTrack.AIM, { trackIndex: 2, loop: true })
AnimationTrackData.set(AnimationTrack.SHOOT, { trackIndex: 2, loop: false, mixOutDuration: 0.05 })
AnimationTrackData.set(AnimationTrack.STOMP, { trackIndex: 2, loop: false, mixOutDuration: 0.15 })
AnimationTrackData.set(AnimationTrack.BOMBARD, { trackIndex: 2, loop: false, mixOutDuration: 0.1 })
AnimationTrackData.set(AnimationTrack.MOVEMENT_NO_WEAPON, { trackIndex: 1, loop: true, mixOutDuration: 0.1 })
AnimationTrackData.set(AnimationTrack.SHOOT_FAST_RECOIL, { trackIndex: 2, loop: false, mixOutDuration: 0.0 })
AnimationTrackData.set(AnimationTrack.SHOOT_FAST_RETURN, { trackIndex: 2, loop: false, mixOutDuration: 0.0 })
AnimationTrackData.set(AnimationTrack.SHOOT_SLOW_RECOIL, { trackIndex: 2, loop: false, mixOutDuration: 0.0 })
AnimationTrackData.set(AnimationTrack.SHOOT_SLOW_RETURN, { trackIndex: 2, loop: false, mixOutDuration: 0.0 })
AnimationTrackData.set(AnimationTrack.SPEAR_STAB, { trackIndex: 1, loop: false, next: AnimationTrack.IDLE })
AnimationTrackData.set(AnimationTrack.SPEAR_DASH_START, { trackIndex: 1, loop: false, next: AnimationTrack.SPEAR_DASH_IDLE, mixOutDuration: 0.1 })
AnimationTrackData.set(AnimationTrack.SPEAR_DASH_IDLE, { trackIndex: 1, loop: false})
AnimationTrackData.set(AnimationTrack.SPEAR_DASH_END, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE, mixOutDuration: 0.1 })
AnimationTrackData.set(AnimationTrack.HIT, { trackIndex: 2, loop: false, })
AnimationTrackData.set(AnimationTrack.HIT_CANNON, { trackIndex: 2, loop: false, })
AnimationTrackData.set(AnimationTrack.TELEPORT_DISAPPEAR, { trackIndex: 4, loop: false })
AnimationTrackData.set(AnimationTrack.TELEPORT_REAPPEAR, { trackIndex: 4, loop: false })
AnimationTrackData.set(AnimationTrack.INTERACT, { trackIndex: 2, loop: false })
AnimationTrackData.set(AnimationTrack.DODGE_ROLL, { trackIndex: 4, loop: false, animationDuration: 0.6, mixOutDuration: 0.0 })
AnimationTrackData.set(AnimationTrack.DODGE_ROLL_CANNON, { trackIndex: 4, loop: false, animationDuration: 0.6, mixOutDuration: 0.0 })
AnimationTrackData.set(AnimationTrack.DODGE_ROLL_INVULN, { trackIndex: 4, loop: false, animationDuration: 0.6, mixOutDuration: 0.0 })
AnimationTrackData.set(AnimationTrack.SPECIAL_ABILITY_AOE, { trackIndex: 3, loop: false })
AnimationTrackData.set(AnimationTrack.SPECIAL_ABILITY_TARGETED, { trackIndex: 3, loop: false })
// TODO2 - HACK: Making the death animation play ~endlessly until we can figure out why it transitions to standing up at the end
AnimationTrackData.set(AnimationTrack.DEATH, { trackIndex: MAX_TRACK_INDEX, loop: false, animationDuration: Number.MAX_SAFE_INTEGER })
AnimationTrackData.set(AnimationTrack.DEATH_CANNON, { trackIndex: MAX_TRACK_INDEX, loop: false, animationDuration: Number.MAX_SAFE_INTEGER })
AnimationTrackData.set(AnimationTrack.LONG_RANGE_ATTACK, { trackIndex: 2, loop: false, mixOutDuration: 0.1 })
AnimationTrackData.set(AnimationTrack.SHORT_RANGE_ATTACK, { trackIndex: 2, loop: false, mixOutDuration: 0.1 })
AnimationTrackData.set(AnimationTrack.PHOENIX_BOMB_BOMB, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.PHOENIX_BOMB_FLYING, { trackIndex: 1, loop: true })
AnimationTrackData.set(AnimationTrack.PHOENIX_BOMB_RESURRECTION, { trackIndex: 2, loop: true })

// Bow
AnimationTrackData.set(AnimationTrack.IDLE_BOW_DRAW_BACK, { trackIndex: 4, loop: false, next: AnimationTrack.IDLE_BOW_DRAWN, mixOutDuration: 0.5 })
AnimationTrackData.set(AnimationTrack.IDLE_BOW_DRAWN, { trackIndex: 4, loop: true })
AnimationTrackData.set(AnimationTrack.IDLE_BOW_RELEASE, { trackIndex: 4, loop: false })
AnimationTrackData.set(AnimationTrack.MOVEMENT_BOW_DRAWN, { trackIndex: 5, loop: true, mixOutDuration: 0.1, mixInDuration: 0.3  })

// poison bottle
AnimationTrackData.set(AnimationTrack.SPAWN, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE })
AnimationTrackData.set(AnimationTrack.DESPAWN, { trackIndex: 0, loop: false, holdLastFrame: true })


// fungi-boss specific, currently
AnimationTrackData.set(AnimationTrack.DESPAWN_IDLE, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.EMERGE, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE })
AnimationTrackData.set(AnimationTrack.SUBMERGE, { trackIndex: 0, loop: false, next: AnimationTrack.SUBMERGE_IDLE })
AnimationTrackData.set(AnimationTrack.SUBMERGE_IDLE, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.SPRAY_ATTACK, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE })
AnimationTrackData.set(AnimationTrack.SPREAD_SPORES, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE })
AnimationTrackData.set(AnimationTrack.SUMMON_MINIONS, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE })
AnimationTrackData.set(AnimationTrack.SUMMON_MINIONS_SHORT, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE })

AnimationTrackData.set(AnimationTrack.EXPLODE, { trackIndex: 1, loop: false })
AnimationTrackData.set(AnimationTrack.DAMAGE_IDLE, { trackIndex: 1, loop: true })

// beach boss
AnimationTrackData.set(AnimationTrack.DANCE, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.DIG, { trackIndex: 2, loop: false })
AnimationTrackData.set(AnimationTrack.GROUND_ATTACK, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE })
AnimationTrackData.set(AnimationTrack.ARMOR_IDLE, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.ARMOR_MOVEMENT, { trackIndex: 1, loop: true })
AnimationTrackData.set(AnimationTrack.SKELETON_SHOOT, { trackIndex: 4, loop: false })

AnimationTrackData.set(AnimationTrack.ARMOR_UP, { trackIndex: 3, loop: true, mixInDuration: 0.2, mixOutDuration: 0.2 })

// forest boss
AnimationTrackData.set(AnimationTrack.ATTACK_360, { trackIndex: 3, loop: false, mixInDuration: 0.2, mixOutDuration: 0.2 })
AnimationTrackData.set(AnimationTrack.POINT_ATTACK, { trackIndex: 3, loop: false, mixInDuration: 0.2, mixOutDuration: 0.2 })
AnimationTrackData.set(AnimationTrack.HEALING_SPRING_START, { trackIndex: 3, loop: false, next: AnimationTrack.HEALING_SPRING_LOOP })
AnimationTrackData.set(AnimationTrack.HEALING_SPRING_LOOP, { trackIndex: 3, loop: true })
AnimationTrackData.set(AnimationTrack.HEALING_SPRING_END, { trackIndex: 3, loop: false })

// prism boss
AnimationTrackData.set(AnimationTrack.SKY_RAID_IN, { trackIndex: 3, loop: false, mixInDuration: 0.2 })
AnimationTrackData.set(AnimationTrack.SKY_RAID_LANDING, { trackIndex: 3, loop: false, mixOutDuration: 0.2 })
AnimationTrackData.set(AnimationTrack.CRYSTAL_BREATH, { trackIndex: 3, loop: false, mixInDuration: 0.2, mixOutDuration: 0.2 })
AnimationTrackData.set(AnimationTrack.WING_GUST, { trackIndex: 3, loop: false, mixInDuration: 0.2, mixOutDuration: 0.2 })
AnimationTrackData.set(AnimationTrack.PRISMATIC_BEAM, { trackIndex: 3, loop: false, mixInDuration: 0.2, mixOutDuration: 0.2 })
AnimationTrackData.set(AnimationTrack.CRYSTAL_COCOON_IN, { trackIndex: 3, loop: false, next: AnimationTrack.CRYSTAL_COCOON_IDLE })
AnimationTrackData.set(AnimationTrack.CRYSTAL_COCOON_IDLE, { trackIndex: 3, loop: true })
AnimationTrackData.set(AnimationTrack.CRYSTAL_COCOON_OUT, { trackIndex: 3, loop: false })

// highlands boss
AnimationTrackData.set(AnimationTrack.EXPLOSIVE_SHARDS, { trackIndex: 3, loop: false, mixInDuration: 0.2, mixOutDuration: 0.2 })
AnimationTrackData.set(AnimationTrack.ONYX_SHIELD, { trackIndex: 3, loop: false, mixInDuration: 0.2, mixOutDuration: 0.2 })
AnimationTrackData.set(AnimationTrack.PETRIFYING_WAVE, { trackIndex: 3, loop: false, mixInDuration: 0.2, mixOutDuration: 0.2 })
AnimationTrackData.set(AnimationTrack.STONE_RAIN, { trackIndex: 3, loop: false, mixInDuration: 0.2, mixOutDuration: 0.2 })

// Worm mail
AnimationTrackData.set(AnimationTrack.HIDE_WORM, { trackIndex: 1, loop: false, animationDuration: Number.MAX_SAFE_INTEGER, next: AnimationTrack.WORM_GONE_IDLE })
AnimationTrackData.set(AnimationTrack.SHOW_WORM, { trackIndex: 2, loop: false, next: AnimationTrack.IDLE })
AnimationTrackData.set(AnimationTrack.WORMMAIL_IDLE, { trackIndex: 7, loop: true })
AnimationTrackData.set(AnimationTrack.WORM_GONE_IDLE, { trackIndex: 3, loop: true })

AnimationTrackData.set(AnimationTrack.OUTRO, { trackIndex: 0, loop: false, holdLastFrame: true })

// Bulwark
AnimationTrackData.set(AnimationTrack.INTRO_45, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE_45 })
AnimationTrackData.set(AnimationTrack.INTRO_HORIZONTAL, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE_HORIZONTAL })
AnimationTrackData.set(AnimationTrack.INTRO_VERTICAL, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE_VERTICAL })

AnimationTrackData.set(AnimationTrack.IDLE_45, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.IDLE_HORIZONTAL, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.IDLE_VERTICAL, { trackIndex: 0, loop: true })

AnimationTrackData.set(AnimationTrack.HIT_45, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE_45 })
AnimationTrackData.set(AnimationTrack.HIT_HORIZONTAL, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE_HORIZONTAL })
AnimationTrackData.set(AnimationTrack.HIT_VERTICAL, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE_VERTICAL })

AnimationTrackData.set(AnimationTrack.HIT_DAMAGED_45, { trackIndex: 0, loop: false, next: AnimationTrack.DAMAGED_45 })
AnimationTrackData.set(AnimationTrack.HIT_DAMAGED_HORIZONTAL, { trackIndex: 0, loop: false, next: AnimationTrack.DAMAGED_HORIZONTAL })
AnimationTrackData.set(AnimationTrack.HIT_DAMAGED_VERTICAL, { trackIndex: 0, loop: false, next: AnimationTrack.DAMAGED_VERTICAL })

AnimationTrackData.set(AnimationTrack.DAMAGED_45, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.DAMAGED_HORIZONTAL, { trackIndex: 0, loop: true })
AnimationTrackData.set(AnimationTrack.DAMAGED_VERTICAL, { trackIndex: 0, loop: true })

AnimationTrackData.set(AnimationTrack.DEATH_45, { trackIndex: 0, loop: false })
AnimationTrackData.set(AnimationTrack.DEATH_HORIZONTAL, { trackIndex: 0, loop: false })
AnimationTrackData.set(AnimationTrack.DEATH_VERTICAL, { trackIndex: 0, loop: false })

// Emotes
AnimationTrackData.set(AnimationTrack.EMOTE_NONE, {trackIndex: 0, loop: true})
AnimationTrackData.set(AnimationTrack.EMOTE_TYPING, {trackIndex: 0, loop: true})
AnimationTrackData.set(AnimationTrack.EMOTE_LOOP, {trackIndex: 0, loop: true})
AnimationTrackData.set(AnimationTrack.EMOTE_ANIMATION, {trackIndex: 0, loop: true})

// UI Slots
AnimationTrackData.set(AnimationTrack.INTRO, { trackIndex: 0, loop: false, next: AnimationTrack.IDLE})
AnimationTrackData.set(AnimationTrack.GENERIC_UNVEIL, { trackIndex: 0, loop: false, holdLastFrame: true })

// Object.values(AnimationTrack).forEach((anim) => {
// 	console.assert(AnimationTrackData.get(anim), `missing track data for ${anim}`)
// })

export function getAnimationTrackData(animationName: AnimationTrack) {
	const trackData = AnimationTrackData.get(animationName)
	console.assert(trackData, `missing track data for:${animationName}`)
	return trackData
}
