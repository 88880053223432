<template>
	<div class="search-filters">
		<Panel :height="840">
			<template #panel-content>
				<div class="filters-container">
					<div class="header">
						<Header :font-size="'large'" :font-face="'pixl'" :text-alignment="'center'" :fontColor="'cream'" :headerText="'Story Filters'"></Header>
					</div>
					<div class="filer-inputs">
						<div class="new-hot-inputs">
							<Checkbox :label="'New'" :inline-label="true" @on-click-fn="updateMode('new')" :checked="this.getMode === 'new'"></Checkbox>
							<Checkbox :label="'Hot'" :inline-label="true" @on-click-fn="updateMode('hot')" :checked="this.getMode === 'hot'"></Checkbox>
						</div>
						<div class="mode-duration-input">
							<Header :font-size="'small'" :font-face="'boldPT'" :text-alignment="'center'" :fontColor="'offWhite'" :headerText="'Duration'"></Header>
							<DropDown class="mode-duration-dropdown" :options="modeDuration" :selected="getModeDuration" :label-text="''" :label-id="'mode-duration'" @on-click-fn="updateModeDuration"></DropDown>
						</div>
						<div class="member-input">
							<Header :font-size="'small'" :font-face="'boldPT'" :text-alignment="'center'" :fontColor="'offWhite'" :headerText="'Amount of Players'"></Header>
							<DropDown class="member-dropdown" :options="memberSize" :selected="memberSizeDropdown" :label-text="''" :label-id="'member-size'" @on-click-fn="updateMemberSize"></DropDown>
						</div>
						<div class="range-input">
							<Header :font-size="'small'" :font-face="'boldPT'" :text-alignment="'center'" :fontColor="'offWhite'" :headerText="'Chapters'"></Header>
							<div class="range-wrapper">
								<Amount :key="componentKey" :selected-amount="getMinChapter" @update-text-input="updateMinChapterText" @decrease="decrementMinChapter" @increase="incrementMinChapter" :min-amount="1" :max-amount="getTwistsWithId.length" :range="'min'"></Amount>
								<div class="middle-text" :lang="this.$i18n.locale">Through</div>
								<Amount :key="componentKey" :selected-amount="getMaxChapter" @update-text-input="updateMaxChapterText" @decrease="decrementMaxChapter" @increase="incrementMaxChapter" :min-amount="getMinChapter" :max-amount="getTwistsWithId.length" :range="'max'"></Amount>
							</div>
						</div>
						<div class="twists-inputs">
							<Header :font-size="'small'" :font-face="'boldPT'" :text-alignment="'center'" :fontColor="'offWhite'" :headerText="'Twists'"></Header>
							<DropDown class="twist-dropdown" :options="getTwistsWithId" :selected="getTwistSlotIndex1" :label-text="''" :label-id="'twists-1'" @on-click-fn="updateTwistSlotIndex1"></DropDown>
							<DropDown class="twist-dropdown" :options="getTwistsWithId" :selected="getTwistSlotIndex2" :label-text="''" :label-id="'twists-2'" @on-click-fn="updateTwistSlotIndex2"></DropDown>
							<DropDown class="twist-dropdown" :options="getTwistsWithId" :selected="getTwistSlotIndex3" :label-text="''" :label-id="'twists-3'" @on-click-fn="updateTwistSlotIndex3"></DropDown>
						</div>
						<div class="button-container">
							<TextButton :button-label="$t('story_select.button_clear')" :disabled="false" :button-style="'bright-green'" @on-click-fn="clearSearchParams" :optional-param="{}" :font-color="'darkBlue'"></TextButton>
						</div>
					</div>
				</div>
			</template>
		</Panel>
	</div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { MUTATOR_DEFINITIONS } from '../../../mutators/mutator-definitions'
import Checkbox from '../../ui-components/form-inputs/checkbox.vue'
import DropDown from '../../ui-components/form-inputs/dropdown.vue'
import Amount from '../../ui-components/form-inputs/amount.vue'
import Header from '../../ui-components/dividers-headers/header.vue'
import TextButton from '../../ui-components/buttons/text-button.vue'
import Panel from '../../ui-components/panel/panel.vue'

export default {
	name: 'SearchContainer',
	components: {
		Panel,
		Header,
		TextButton,
		Checkbox,
		DropDown,
		Amount,
	},

	watch: {
		searchParams: {
			handler(newValue, oldValue) {
				this.searchForStories()
			},
			deep: true,
		},
	},
	data() {
		return {
			componentKey: 0,
			twistDropDown1: '',
			twistDropDown2: '',
			twistDropDown3: '',
			memberSizeDropdown: '',
			minChapter: 1,
			maxChapter: 10,
			mode: 'hot',
			memberSize: [
				{
					id: 'small',
					name: 'Small 0 - 4',
				},
				{
					id: 'medium',
					name: 'Medium 4 - 10',
				},
				{
					id: 'large',
					name: 'Large 11 - 25',
				},
				{
					id: 'massive',
					name: 'Massive 26+',
				},
			],
			modeDuration: [
				{
					id: '2',
					name: '2 Hours',
				},
				{
					id: '24',
					name: '24 Hours',
				},
				{
					id: '48',
					name: '48 Hours',
				},
				{
					id: '168',
					name: '168 Hours',
				},
				{
					id: '720',
					name: '720 Hours',
				},
			],
		}
	},
	props: {},
	computed: {
		// ...mapGetters('ui', ),
		...mapGetters('story', ['getModeDuration', 'getMode', 'getMinChapter', 'getMaxChapter', 'getMemberSize', 'getTwistSlotIndex1', 'getTwistSlotIndex2', 'getTwistSlotIndex3']),
		...mapState('story', ['searchParams']),
		getTwistsWithId() {
			const twists = []

			for (const [key, mutator] of Object.entries(MUTATOR_DEFINITIONS)) {
				if (mutator.isDebug) {
					continue
				}

				twists.push({
					id: mutator.id,
					name: mutator.name,
				})
			}

			twists.sort((a, b) => a.name.localeCompare(b.name))
			return twists
		},
		userSearchParams() {
			return this.searchParams
		},
	},
	methods: {
		// ...mapMutations('ui', ),
		// ...mapActions('ui', ),
		...mapMutations('story', ['updateModeDuration', 'updateMode', 'updateMinChapter', 'updateMaxChapter', 'updateMemberSize', 'clearSearchParams', 'updateTwistSlotIndex1', 'updateTwistSlotIndex2', 'updateTwistSlotIndex3']),
		...mapActions('story', ['searchForStories']),

		decrementMinChapter() {
			if (this.getMinChapter > 1) {
				this.updateMinChapter(this.getMinChapter - 1)
			}
		},
		incrementMinChapter() {
			if (this.getMinChapter < this.getMaxChapter - 1) {
				this.updateMinChapter(this.getMinChapter + 1)
			}
		},
		decrementMaxChapter() {
			if (this.getMaxChapter > this.getMinChapter + 1) {
				this.updateMaxChapter(this.getMaxChapter - 1)
			}
		},
		incrementMaxChapter() {
			if (this.getMaxChapter < this.getTwistsWithId.length) {
				this.updateMaxChapter(this.getMaxChapter + 1)
			}
		},
		updateMinChapterText(value) {
			if (value < this.getMaxChapter && value > 0) {
				this.updateMinChapter(value)
			}
			this.componentKey += 1
		},
		updateMaxChapterText(value) {
			if (value > this.getMinChapter) {
				this.updateMaxChapter(value)
			}
			this.componentKey += 1
		},
	},
}
</script>

<style lang="less" scoped>
@import url('../../../web/global/fonts/fonts.less');

.search-filters {
	width: 410px;
	top: 75px;
	left: 30px;
	position: absolute;

	.filters-container {
		.header {
			header {
			}
		}
		.filer-inputs {
			.new-hot-inputs {
				margin: 10px 10px 20px;
				display: flex;
				justify-content: space-between;
				checkbox {
				}
			}
			.member-input {
				.member-dropdown {
					margin: 10px 10px 20px;
				}
			}
			.range-input {
				display: flex;
				flex-direction: column;
				.range-wrapper {
					display: flex;
					justify-content: space-between;
					background-color: @darkBlue;
					padding: 10px 5px;
					margin: 10px 10px;
					.middle-text {
						align-self: center;
						color: @green;
						margin: 0px 5px;
						font-size: 24px;
						&[lang='en'] {
							.regularFont(@enSecondaryFontPTBold);
						}
						&[lang='zh'] {
							.regularFont(@zhHansHeaderFont);
						}
					}
				}
			}
			.twists-inputs {
				.twist-dropdown {
					margin: 10px;
				}
			}
			.button-container {
				display: flex;
				justify-content: center;
				margin: 20px 10px 0px;
			}
			.mode-duration-input {
				.mode-duration-dropdown {
					margin: 10px;
				}
			}
		}
	}
}
</style>
