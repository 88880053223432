import { Vector } from "sat"
import { ComponentOwner } from "../engine/component-owner"
import { GameState, getNID } from "../engine/game-state"
import { timeInMilliseconds, timeInSeconds } from "../utils/primitive-types"
import { ObjectPoolTyped, PoolableObject } from "../utils/third-party/object-pool"
import { AssetManager } from "../web/asset-manager"
import { EntityType, IEntity } from "./entity-interfaces"
import { Renderer } from "../engine/graphics/renderer"
import { EffectConfig } from "../engine/graphics/pfx/effectConfig"
import { Effect } from "../engine/graphics/pfx/effect"
import ProjectileEffectManager from "../engine/graphics/projectile-effect-manager"

const FALLING_BITS_DURATION = 1
const FALLING_BITS_SCALE = 0.8
const FALLING_BITS_DEFAULT_SPEED = 800
const OFFSET_Y = 15
const OFFSET_Z = 250


export interface FallingBitParams {
	position: Vector,
	yKillPlane: number,
	timeScale: number,
	targetDirection: number
	speed?: number
}

export class FallingBits implements PoolableObject, ComponentOwner, IEntity {
	static pool: ObjectPoolTyped<FallingBits, FallingBitParams>

	nid: number
	entityType: EntityType
	timeScale: number = 1

	position: Vector
	speed: number = 0
	yKillPlane: number = 0
	targetDirection: number = 0

	private effectConfig: EffectConfig
	private effect: Effect

	constructor() {
		this.nid = getNID(this)
		this.entityType = EntityType.Projectile // Should we have an entity type for simple visuals?
		this.position = new Vector()
		this.effectConfig = AssetManager.getInstance().getAssetByName('cannon-falling-bits').data
	}

	setDefaultValues(defaultValues: any, overrideValues?: FallingBitParams) {
		if (overrideValues) {

			this.position.copy(overrideValues.position)
			this.position.y += OFFSET_Y
			this.speed = overrideValues.speed ?? FALLING_BITS_DEFAULT_SPEED
			this.yKillPlane = overrideValues.yKillPlane
			this.targetDirection = overrideValues.targetDirection
			this.timeScale = overrideValues.timeScale

			this.effect = Renderer.getInstance().addOneOffEffectByConfig(this.effectConfig, this.position.x, this.position.y, undefined, FALLING_BITS_SCALE, FALLING_BITS_DURATION)
			GameState.addEntity(this)
		}
	}

	cleanup() {
		GameState.removeEntity(this)
	}

	update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		this.position.y += this.speed * delta * this.timeScale
		this.effect.x = this.position.x
		this.effect.y = this.position.y
		this.effect.zIndex = this.position.y - (this.targetDirection * OFFSET_Z)
		if (this.position.y >= this.yKillPlane) {
			this.returnToPool()
		}
	}

	returnToPool() {
		Renderer.getInstance().mgRenderer.removeFromScene(this.effect)
		ProjectileEffectManager.freeEffect(this.effect)
		FallingBits.pool.free(this)
	}
}