import { Vector } from "sat"
import { GameState } from "../engine/game-state"
import AISystem from "../entities/enemies/ai-system"
import { ENEMY_NAME } from "../entities/enemies/enemy-names"
import { splayGroundPickupsInRadius } from "../entities/pickups/ground-pickup"
import { KillSpawnedEnemiesPOI, KillSpawnedEnemiesPOIParams } from "../pois/kill-spawned-enemies"
import { POI } from "../pois/poi"
import { distanceSquaredVV } from "../utils/math"
import { timeInMilliseconds, timeInSeconds } from "../utils/primitive-types"
import { ObjectPoolTyped } from "../utils/third-party/object-pool"
import { InGameTime } from "../utils/time"
import { EventStartData, GameplayEvent } from "./gameplay-event-definitions"
import { GameplayTimedEventSystem } from "./gameplay-timed-event-system"
import { PropPlacer } from "../world-generation/prop-placement"
import { EventTypes } from "./event-types"
import { GroundPickupConfigType } from "../entities/pickups/ground-pickup-types"

const REMOVE_TOWER_DIST_2 = 2_000 ** 2

export class ShamblingTowerGameplayEvent implements GameplayEvent {
	private params: KillSpawnedEnemiesPOIParams
    
    private reusePositionVector: Vector
    private reusePickupArray: GroundPickupConfigType[]
    private eventStartData: EventStartData

    private activePOIs: POI[]

    constructor() {
        if (!KillSpawnedEnemiesPOI.pool) {
            KillSpawnedEnemiesPOI.pool = new ObjectPoolTyped<KillSpawnedEnemiesPOI, KillSpawnedEnemiesPOIParams>(() => new KillSpawnedEnemiesPOI(), {}, 2, 1)
        }

        this.reusePositionVector = new Vector()
        this.reusePickupArray = []
        this.activePOIs = []

        this.params = {
			xPosition: 0,
			yPosition: 0,
			radius: 300,
			state: {},
			onPlayerEnteredFn: undefined,
			onPlayerExitedFn: undefined,
			whilePlayerInZoneFn: undefined,
			onEventDone: (poi: KillSpawnedEnemiesPOI, state: any) => {
				this.onEventDoneInternal(poi)
			},
            enemyNames: [ENEMY_NAME.SHAMBLING_TOWER]
		}
    }

    setStartData(data: EventStartData) {
		this.eventStartData = data
	}

    startEvent() {
        PropPlacer.getInstance().getRandomValidPositionInWorld(500, 1200, 600, this.reusePositionVector)
    
        this.params.xPosition = this.reusePositionVector.x
        this.params.yPosition = this.reusePositionVector.y

        const poi = KillSpawnedEnemiesPOI.pool.alloc(this.params)
        this.activePOIs.push(poi)
    }

    update(delta: timeInSeconds) {
        const now = InGameTime.timeElapsedInSeconds
        const playerPos = GameState.player.position 

        for (let i = 0; i < this.activePOIs.length; ++i) {
            const poi = this.activePOIs[i]
            if (!poi.poiEnding && now > poi.timeStarted + this.eventStartData.timeLimit) {
                // failure, but don't remove unless the tower is off the screen
                const dist = distanceSquaredVV(playerPos, poi.position)
                if (dist > REMOVE_TOWER_DIST_2) {
                    poi.onComplete(false)
                }
            }
        }
    }

    private onEventDoneInternal(poi: KillSpawnedEnemiesPOI) {
        if (poi.playerWon && !GameState.player.binaryFlagState['pickup-range-nullified-during-pet-events'].eventStarted) {
            let xpCount = 0
            let iter = 0
            while (xpCount < 25 && iter < 50) {
                const xpPickup = AISystem.getInstance().xpDropShuffleBag.next() as GroundPickupConfigType
                if (xpPickup) {
                    this.reusePickupArray.push(xpPickup)
                    xpCount++
                }
                iter++
            }
    
            splayGroundPickupsInRadius(this.reusePickupArray, poi.position.x, poi.position.y, 1200, 1700, 0.3)
            this.reusePickupArray.length = 0
        }

        this.activePOIs.remove(poi)

		GameplayTimedEventSystem.getInstance().onEventEnd(EventTypes.ShamblingTower)
	}
}
