import { Container, Sprite, Text } from "pixi.js"
import { Vector } from "sat"
import { GameState, getNID } from "../engine/game-state"
import { InstancedSprite } from "../engine/graphics/instanced-sprite"
import { Renderer } from "../engine/graphics/renderer"
import { PauseManager } from "../engine/pause-manager"
import { EntityType, IEntity } from "../entities/entity-interfaces"
import { ShrineGameplayEventPOI } from "../events/shrine-gameplay-event"
import { distanceSquaredVV } from "../utils/math"
import { timeInSeconds, timeInMilliseconds } from "../utils/primitive-types"
import { ObjectPool, PoolableObject } from "../utils/third-party/object-pool"
import { InGameTime } from "../utils/time"
import { AssetManager } from "../web/asset-manager"
import { GameClient } from "../engine/game-client"

const CHAIN_BORDER_SCALE = 0.5
const MANA_CHAIN_BORDER_SCALE = 0.5
const PLANT_BORDER_SCALE = 0.5
const HEART_CHAIN_BORDER_SCALE = 0.5

const BRICK_BORDER_ONE_BRICK_LENGTH = 120
const CHAIN_BORDER_ONE_CHAIN_LENGTH = 150 / 2
const MANA_CHAIN_BORDER_ONE_CHAIN_LENGTH = 150 / 2
const PLANT_BORDER_ONE_PLANT_LENGTH = 160 * PLANT_BORDER_SCALE
const HEART_CHAIN_BORDER_ONE_CHAIN_LENGTH = 150 / 2


export interface POIParams {
	xPosition: number
	yPosition: number
	radius: number
	timeLimit?: timeInSeconds
	state?: any
	lockPlayerInZone?: boolean
	borderSpriteOverride?: BorderSpriteType
	onPlayerEnteredFn?: (poi: any, state: any) => void
	onPlayerExitedFn?: (poi: any, state: any) => void
	whilePlayerInZoneFn?: (poi: any, delta: timeInSeconds, state: any) => void
	onEventDone?: (poi: any, state: any) => void
	onEventStarted?: (poi: any, state: any) => void
}

export type BorderSpriteData = {
	normalSprite: InstancedSprite
	normalPool: ObjectPool
	glowSprite: InstancedSprite
	glowPool: ObjectPool
}

export type BorderSpriteType = 'brick' | 'chain' | 'mana' | 'plant' | 'heart'

export abstract class POI implements IEntity, PoolableObject, ShrineGameplayEventPOI {
    nid: number
    entityType: EntityType = EntityType.POI
	timeScale: number = 1

    position: Vector

    isActive: boolean
    poiEnding: boolean
    isPlayerInZone: boolean
	playerWon: boolean

    state: any

    radius: number
    radiusSquared: number

    winDelayTime: timeInMilliseconds
	timeLimit?: timeInSeconds
	timeStarted: timeInSeconds
	timeEnding: timeInSeconds

	lockPlayerInZone: boolean
	lockActivated: boolean

    // gfx
    showGraphics: boolean
	textContainer: Container
	bannerText: Text
	borderSprites: BorderSpriteData[]
	borderSpriteOverride?: BorderSpriteType

	// these have gotten a bit much
	static stoneBrickSprites: ObjectPool
	static stoneBrick2Sprites: ObjectPool
	static glowStoneBrickSprites: ObjectPool
	static glowStoneBrick2Sprites: ObjectPool
	static chainSprites: ObjectPool
	static glowChainSprites: ObjectPool
	static manaChainSprites: ObjectPool
	static manaChain2Sprites: ObjectPool
	static manaChain3Sprites: ObjectPool
	static glowManaChainSprites: ObjectPool
	static glowManaChain2Sprites: ObjectPool
	static glowManaChain3Sprites: ObjectPool
	static plantSprites: ObjectPool
	static plant2Sprites: ObjectPool
	static glowPlantSprites: ObjectPool
	static glowPlant2Sprites: ObjectPool
	static heartChainSprites: ObjectPool
	static heartChain2Sprites: ObjectPool
	static heartChain3Sprites: ObjectPool
	static glowHeartChainSprites: ObjectPool
	static glowHeartChain2Sprites: ObjectPool
	static glowHeartChain3Sprites: ObjectPool

	onPlayerEnteredFn?: (poi: any, state: any) => void
	onPlayerExitedFn?: (poi: any, state: any) => void
	whilePlayerInZoneFn?: (poi: any, delta: timeInSeconds, state: any) => void
	onEventDone?: (poi: any, state: any) => void

    constructor() {
		this.nid = getNID(this)

        this.position = new Vector()
        this.isActive = false
        this.isPlayerInZone = false
		this.borderSprites = []

		if (!POI.stoneBrickSprites) {
			const brick01 = AssetManager.getInstance().getAssetByName('shrine-brick-01').texture
			const glowBrick01 = AssetManager.getInstance().getAssetByName('shrine-glow-brick-01').texture
			const brick02 = AssetManager.getInstance().getAssetByName('shrine-brick-02').texture
			const glowBrick02 = AssetManager.getInstance().getAssetByName('shrine-glow-brick-02').texture

			POI.stoneBrickSprites = new ObjectPool(() => new InstancedSprite(brick01, 0, 0, -99_995), {}, 200, 1)
			POI.stoneBrick2Sprites = new ObjectPool(() => new InstancedSprite(brick02, 0, 0, -99_995), {}, 200, 1)
			POI.glowStoneBrickSprites = new ObjectPool(() => new InstancedSprite(glowBrick01, 0, 0, -99_995), {}, 200, 1)
			POI.glowStoneBrick2Sprites = new ObjectPool(() => new InstancedSprite(glowBrick02, 0, 0, -99_995), {}, 200, 1)

			const chain = AssetManager.getInstance().getAssetByName('shrine-chain').texture
			const glowChain = AssetManager.getInstance().getAssetByName('shrine-glow-chain').texture

			POI.chainSprites = new ObjectPool(() => new InstancedSprite(chain, 0, 0, -99_995, CHAIN_BORDER_SCALE, CHAIN_BORDER_SCALE), {}, 200, 1)
			POI.glowChainSprites = new ObjectPool(() => new InstancedSprite(glowChain, 0, 0, -99_995, CHAIN_BORDER_SCALE, CHAIN_BORDER_SCALE), {}, 200, 1)

			const manaChain1 = AssetManager.getInstance().getAssetByName('mana-shrine-chain-1').texture
			const manaChain2 = AssetManager.getInstance().getAssetByName('mana-shrine-chain-2').texture
			const manaChain3 = AssetManager.getInstance().getAssetByName('mana-shrine-chain-3').texture

			const glowManaChain1 = AssetManager.getInstance().getAssetByName('glow-mana-shrine-chain-1').texture
			const glowManaChain2 = AssetManager.getInstance().getAssetByName('glow-mana-shrine-chain-2').texture
			const glowManaChain3 = AssetManager.getInstance().getAssetByName('glow-mana-shrine-chain-3').texture

			POI.manaChainSprites = new ObjectPool(() => new InstancedSprite(manaChain1, 0, 0, -99_995, MANA_CHAIN_BORDER_SCALE, MANA_CHAIN_BORDER_SCALE), {}, 200, 1)
			POI.manaChain2Sprites = new ObjectPool(() => new InstancedSprite(manaChain2, 0, 0, -99_995, MANA_CHAIN_BORDER_SCALE, MANA_CHAIN_BORDER_SCALE), {}, 200, 1)
			POI.manaChain3Sprites = new ObjectPool(() => new InstancedSprite(manaChain3, 0, 0, -99_995, MANA_CHAIN_BORDER_SCALE, MANA_CHAIN_BORDER_SCALE), {}, 200, 1)
			
			POI.glowManaChainSprites = new ObjectPool(() => new InstancedSprite(glowManaChain1, 0, 0, -99_995, MANA_CHAIN_BORDER_SCALE, MANA_CHAIN_BORDER_SCALE), {}, 200, 1)
			POI.glowManaChain2Sprites = new ObjectPool(() => new InstancedSprite(glowManaChain2, 0, 0, -99_995, MANA_CHAIN_BORDER_SCALE, MANA_CHAIN_BORDER_SCALE), {}, 200, 1)
			POI.glowManaChain3Sprites = new ObjectPool(() => new InstancedSprite(glowManaChain3, 0, 0, -99_995, MANA_CHAIN_BORDER_SCALE, MANA_CHAIN_BORDER_SCALE), {}, 200, 1)

			const plant01 = AssetManager.getInstance().getAssetByName('plant-shrine-01').texture
			const glowPlant01 = AssetManager.getInstance().getAssetByName('plant-shrine-glow-01').texture 
			const plant02 = AssetManager.getInstance().getAssetByName('plant-shrine-02').texture
			const glowPlant02 = AssetManager.getInstance().getAssetByName('plant-shrine-glow-02').texture

			POI.plantSprites = new ObjectPool(() => new InstancedSprite(plant01, 0, 0, -99_995, PLANT_BORDER_SCALE, PLANT_BORDER_SCALE), {}, 200, 1)
			POI.glowPlantSprites = new ObjectPool(() => new InstancedSprite(glowPlant01, 0, 0, -99_995, PLANT_BORDER_SCALE, PLANT_BORDER_SCALE), {}, 200, 1)
			POI.plant2Sprites = new ObjectPool(() => new InstancedSprite(plant02, 0, 0, -99_995, PLANT_BORDER_SCALE, PLANT_BORDER_SCALE), {}, 200, 1)
			POI.glowPlant2Sprites = new ObjectPool(() => new InstancedSprite(glowPlant02, 0, 0, -99_995, PLANT_BORDER_SCALE, PLANT_BORDER_SCALE), {}, 200, 1)

			const heartChain1 = AssetManager.getInstance().getAssetByName('heart-shrine-chain-asset-1').texture
			const heartChain2 = AssetManager.getInstance().getAssetByName('heart-shrine-chain-asset-2').texture
			const heartChain3 = AssetManager.getInstance().getAssetByName('heart-shrine-chain-asset-3').texture

			const glowHeartChain1 = AssetManager.getInstance().getAssetByName('glow-heart-shrine-chain-asset-1').texture
			const glowHeartChain2 = AssetManager.getInstance().getAssetByName('glow-heart-shrine-chain-asset-2').texture
			const glowHeartChain3 = AssetManager.getInstance().getAssetByName('glow-heart-shrine-chain-asset-3').texture

			POI.heartChainSprites = new ObjectPool(() => new InstancedSprite(heartChain1, 0, 0, -99_995, HEART_CHAIN_BORDER_SCALE, HEART_CHAIN_BORDER_SCALE), {}, 200, 1)
			POI.heartChain2Sprites = new ObjectPool(() => new InstancedSprite(heartChain2, 0, 0, -99_995, HEART_CHAIN_BORDER_SCALE, HEART_CHAIN_BORDER_SCALE), {}, 200, 1)
			POI.heartChain3Sprites = new ObjectPool(() => new InstancedSprite(heartChain3, 0, 0, -99_995, HEART_CHAIN_BORDER_SCALE, HEART_CHAIN_BORDER_SCALE), {}, 200, 1)
			
			POI.glowHeartChainSprites = new ObjectPool(() => new InstancedSprite(glowHeartChain1, 0, 0, -99_995, HEART_CHAIN_BORDER_SCALE, HEART_CHAIN_BORDER_SCALE), {}, 200, 1)
			POI.glowHeartChain2Sprites = new ObjectPool(() => new InstancedSprite(glowHeartChain2, 0, 0, -99_995, HEART_CHAIN_BORDER_SCALE, HEART_CHAIN_BORDER_SCALE), {}, 200, 1)
			POI.glowHeartChain3Sprites = new ObjectPool(() => new InstancedSprite(glowHeartChain3, 0, 0, -99_995, HEART_CHAIN_BORDER_SCALE, HEART_CHAIN_BORDER_SCALE), {}, 200, 1)
		}

		this.makeGraphicsContainers()
    }

    setDefaultValues(defaultValues: any, overrideValues?: POIParams) {
        if (overrideValues) {
            this.position.x = overrideValues.xPosition
			this.position.y = overrideValues.yPosition
			this.onPlayerEnteredFn = overrideValues.onPlayerEnteredFn
			this.whilePlayerInZoneFn = overrideValues.whilePlayerInZoneFn
			this.onPlayerExitedFn = overrideValues.onPlayerExitedFn
			this.onEventDone = overrideValues.onEventDone
			this.state = overrideValues.state
			this.lockPlayerInZone = Boolean(overrideValues.lockPlayerInZone)
			this.lockActivated = false

			this.isActive = true
			this.playerWon = false

			this.radius = overrideValues.radius
			this.radiusSquared = this.radius * this.radius

			this.timeLimit = overrideValues.timeLimit
			this.timeStarted = InGameTime.timeElapsedInSeconds
			this.borderSpriteOverride = overrideValues.borderSpriteOverride
			if (overrideValues.timeLimit) {
				this.timeEnding = this.timeStarted + overrideValues.timeLimit
			}

            if (this.showGraphics) {
				this.makeBorderGraphics()

                this.textContainer.position.x = overrideValues.xPosition
                this.textContainer.position.y = overrideValues.yPosition
    			
                Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.textContainer)
            }

			GameState.addEntity(this)

			if (overrideValues.onEventStarted) {
				overrideValues.onEventStarted(this, this.state)
			}
        }
    }

    cleanup() {
		this.isActive = false
		this.state = null
		this.poiEnding = false

        if (this.showGraphics) {
            Renderer.getInstance().mgRenderer.removeFromScene(this.textContainer)

			this.freeBorderGraphics()
        }

		this.isPlayerInZone = false

		GameState.removeEntity(this)
    }
    
    update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		const playerPos = GameState.player.position
		const xDiff = playerPos.x - this.position.x
		const yDiff = playerPos.y - this.position.y
		const distSquared = xDiff ** 2 + yDiff ** 2

		let isInZone = distSquared <= this.radiusSquared
        if (!isInZone && this.lockActivated) {
			isInZone = true

			const dist = Math.sqrt(distSquared)
			const xNorm = xDiff / dist
			const yNorm = yDiff / dist

			const xScaled = xNorm * this.radius
			const yScaled = yNorm * this.radius

			playerPos.x = xScaled + this.position.x
			playerPos.y = yScaled + this.position.y
		}
		
        if (this.isActive && isInZone !== this.isPlayerInZone) {
			if (isInZone) {
				if (this.lockPlayerInZone) {
					this.lockActivated = true
				}

				if (this.onPlayerEnteredFn) {
					this.onPlayerEnteredFn(this, this.state)
				}
			} else if (!isInZone && this.onPlayerExitedFn) {
				this.onPlayerExitedFn(this, this.state)
			}

			this.isPlayerInZone = isInZone

			if (this.showGraphics) {
				this.playerInZoneChangeGlowBorderChange()
			}
		}
		
        if (this.isPlayerInZone) {
            if (this.isActive && this.whilePlayerInZoneFn) {
				this.whilePlayerInZoneFn(this, delta, this.state)
			}
        }
		
		if (!this.poiEnding) {
			if (this.timeLimit && InGameTime.timeElapsedInSeconds >= this.timeEnding && !this.isPlayerInZone) {
				this.onComplete(false)
			}
		} else {
			if (now >= this.winDelayTime && !PauseManager.isPaused()) {
				this.freeFromPool()
			}
		}
    }

    onComplete(victory: boolean) {
		this.playerWon = victory
		this.isActive = false
		this.poiEnding = true

		if (this.isPlayerInZone && this.onPlayerExitedFn) {
			this.onPlayerExitedFn(this, this.state)
		}

		this.winDelayTime = InGameTime.highResolutionTimestamp() + 2_000

		if (this.onEventDone) {
			this.onEventDone(this, this.state)
		}
	}

    setBannerText(text: string) {
        this.bannerText.text = text
		this.bannerText.x = -this.bannerText.width / 2
    }

    makeGraphicsContainers() {
		this.textContainer = new Container()
		this.textContainer['update'] = () => {}

		this.bannerText = new Text('', {
			fontSize: 34,
			fontWeight: 900,
			letterSpacing: 1,
			align: 'center',
			fill: 'white',
			dropShadow: true,
			dropShadowAngle: 2,
			dropShadowColor: '#2a313e',
			dropShadowDistance: 4,
			lineJoin: 'bevel',
			miterLimit: 5,
			padding: 8,
			stroke: '#2a313e',
			strokeThickness: 2,
		})
		this.bannerText.y -= 200

		this.textContainer.addChild(this.bannerText)
		this.textContainer.zIndex = 999_999
	}

	makeBorderGraphics() {
		const circumference = Math.PI * 2 * this.radius
		const useChains = this.lockPlayerInZone || this.borderSpriteOverride === 'chain'
		let tileLength = useChains ? CHAIN_BORDER_ONE_CHAIN_LENGTH : BRICK_BORDER_ONE_BRICK_LENGTH
		if (this.borderSpriteOverride) {
			switch(this.borderSpriteOverride) {
				case 'brick':
					tileLength = BRICK_BORDER_ONE_BRICK_LENGTH
					break
				case 'chain':
					tileLength = CHAIN_BORDER_ONE_CHAIN_LENGTH
					break
				case 'mana':
					tileLength = MANA_CHAIN_BORDER_ONE_CHAIN_LENGTH
					break
				case 'plant':
					tileLength = PLANT_BORDER_ONE_PLANT_LENGTH
					break
				case 'heart':
					tileLength = HEART_CHAIN_BORDER_ONE_CHAIN_LENGTH
					break
			}
		}

		const numTiles = Math.floor(circumference / tileLength)
		const radStep = (Math.PI * 2) / numTiles
		const renderer = Renderer.getInstance().bgRenderer

		for (let i = 0; i < numTiles; ++i) {
			const rotation = i * radStep
			const xPos = Math.sin(rotation) * this.radius + this.position.x
			const yPos = Math.cos(rotation) * this.radius + this.position.y

			let normalPool: ObjectPool
			let glowPool: ObjectPool

			if (useChains || this.borderSpriteOverride === 'chain') {
				normalPool = POI.chainSprites
				glowPool = POI.glowChainSprites
			} else if (this.borderSpriteOverride === 'mana') {
				if (Math.random() < 0.33) {
					normalPool = POI.manaChainSprites
					glowPool = POI.glowManaChainSprites
				} else if (Math.random() < 0.66) {
					normalPool = POI.manaChain2Sprites
					glowPool = POI.glowManaChain2Sprites
				} else {
					normalPool = POI.manaChain3Sprites
					glowPool = POI.glowManaChain3Sprites
				}
			} else if (this.borderSpriteOverride === 'plant') {
				if (Math.random() < 0.5) {
					normalPool = POI.plantSprites
					glowPool = POI.glowPlantSprites
				} else {
					normalPool = POI.plant2Sprites
					glowPool = POI.glowPlant2Sprites
				}
			} else if (this.borderSpriteOverride === 'heart') {
				if (Math.random() < 0.33) {
					normalPool = POI.heartChainSprites
					glowPool = POI.glowHeartChainSprites
				} else if (Math.random() < 0.66) {
					normalPool = POI.heartChain2Sprites
					glowPool = POI.glowHeartChain2Sprites
				} else {
					normalPool = POI.heartChain3Sprites
					glowPool = POI.glowHeartChain3Sprites
				}
			} else {
				if (Math.random() < 0.5) {
					normalPool = POI.stoneBrickSprites
					glowPool = POI.glowStoneBrickSprites
				} else {
					normalPool = POI.stoneBrick2Sprites
					glowPool = POI.glowStoneBrick2Sprites
				}
			}

			const sprite = normalPool.alloc() as InstancedSprite
			sprite.x = xPos
			sprite.y = yPos
			sprite.rot = -rotation

			const glowSprite = glowPool.alloc() as InstancedSprite
			glowSprite.x = xPos
			glowSprite.y = yPos
			glowSprite.rot = -rotation

			renderer.addPropToScene(sprite)

			this.borderSprites.push({
				normalSprite: sprite,
				normalPool,
				glowSprite,
				glowPool
			})
		}
	}

	freeBorderGraphics() {
		const renderer = Renderer.getInstance().bgRenderer
		
		for (let i = 0; i < this.borderSprites.length; ++i) {
			const data = this.borderSprites[i]
			if (this.isPlayerInZone) {
				renderer.removeFromScene(data.glowSprite)
			} else {
				renderer.removeFromScene(data.normalSprite)
			}
			
			data.glowPool.free(data.glowSprite as any)
			data.normalPool.free(data.normalSprite as any)
		}

		this.borderSprites.length = 0
	}

	// names are hard
	private playerInZoneChangeGlowBorderChange() {
		const renderer = Renderer.getInstance().bgRenderer
		for (let i = 0; i < this.borderSprites.length; ++i) {
			const data = this.borderSprites[i]
			if (this.isPlayerInZone) {
				renderer.removeFromScene(data.normalSprite)
				renderer.addPropToScene(data.glowSprite)
			} else {
				renderer.removeFromScene(data.glowSprite)
				renderer.addPropToScene(data.normalSprite)
			}
		}
	}

    abstract freeFromPool(): void
}