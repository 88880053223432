import EntityStatList from "../../../stats/entity-stat-list"
import { SkillWeapon } from "../../skill-weapon"
import { defaultStatAttribute } from "../../../game-data/stat-formulas"
import { AllWeaponTypes } from "../../weapon-types"
import { ExternalPhysicsForce } from "../../../engine/physics/physics"
import CollisionSystem from "../../../engine/collision/collision-system"
import { CollisionLayerBits } from "../../../engine/collision/collision-layers"
import { Enemy } from "../../../entities/enemies/enemy"
import { InGameTime } from "../../../utils/time"
import { Buff } from "../../../buffs/buff"
import { BuffIdentifier } from "../../../buffs/buff.shared"
import { SecondaryWeapon } from "../../secondary-weapon"
import { Renderer } from "../../../engine/graphics/renderer"
import { EffectConfig } from "../../../engine/graphics/pfx/effectConfig"
import { AssetManager } from "../../../web/asset-manager"
import { Audio } from "../../../engine/audio"
import { timeInSeconds } from "../../../utils/primitive-types"
import playAnimation from "../../../engine/graphics/play-animation"
import { AnimationTrack } from "../../../spine-config/animation-track"
import PlayerMetricsSystem from "../../../metrics/metric-system"
import { Vector } from "sat"
import { StatType } from "../../../stats/stat-interfaces-enums"
import { getDamageByPlayerLevel } from "../../../game-data/player-formulas"

export const BARBARIAN_SKILL_BASE_STATS_DATA = {
	skillSpeed: 5.42,
	cooldownReductionInMs: 0.333 * 1000,
}

const BASE_PFX_EXPLOSION_SIZE = 100
const EXTRA_INVULN_TIME_SECONDS = 0.3

export class BarbarianSkillWeapon extends SkillWeapon {
	weaponType: AllWeaponTypes = AllWeaponTypes.BarbarianSkill
	aoeEffectConfig: EffectConfig = AssetManager.getInstance().getAssetByName('aoe-explosion-lightning').data // Placeholder effect
	knockbackFrames: number = 0

	useSkill(): void {
		let rollDirection: Vector
		if (this.player.isMovementLocked()) {
			rollDirection = this.player.movementInput.clone().scale(this.player.getStat(StatType.movementSpeed))
		} else {
			rollDirection = this.player.velocity.clone()
		}

		const speed = BARBARIAN_SKILL_BASE_STATS_DATA.skillSpeed
		const duration = this.statList._actualStatValues.skillDuration as timeInSeconds
		const force = new ExternalPhysicsForce(rollDirection.scale(speed), duration, this.onFinishCallback.bind(this), true)
		this.player.externalForces.push(force)

		this.player.isTumbleRolling = true

		if (this.player.binaryFlags.has('barbarian-tough-skin')){
			this.player.invulnUntilTime = InGameTime.highResolutionTimestamp() + ((duration + EXTRA_INVULN_TIME_SECONDS) * 1000)
		}

		if (this.player.binaryFlags.has('barbarian-aggessive-posturing')) {
			let cooldownReduction = BARBARIAN_SKILL_BASE_STATS_DATA.cooldownReductionInMs
			if (this.player.binaryFlags.has('barbarian-destructive-rollout')) {
				cooldownReduction += cooldownReduction
				Buff.apply(BuffIdentifier.BarbarianDamageBoost, this.player, this.player)
			}
			this.player.secondaryWeapons.forEach((weapon: SecondaryWeapon) =>{
				weapon.cooldown.reduceNextCooldown(cooldownReduction)
			})
		}
		Audio.getInstance().playSfx('SFX_Player_Roll')
		playAnimation(this.player.riggedModel, this.player.dodgeRollAnimation)
		this.player.weapon.visible = false

	}

	protected canUseSkill(): boolean {
		return true
	}

	private onFinishCallback(force){
		this.player.isTumbleRolling = false
			if (this.player.binaryFlags.has('barbarian-fearsome-roar')) {
				const attackRadius = this.statList.getStat('attackSize')
				const enemiesInArea = CollisionSystem.getInstance().getEntitiesInArea(this.player.position, attackRadius, CollisionLayerBits.HitEnemyOnly)
				for (let i = 0; i < enemiesInArea.length; ++i) {
					const enemy = enemiesInArea[i].owner as Enemy
					const damage = getDamageByPlayerLevel()
					enemy.takeDamageSimple(damage)
					PlayerMetricsSystem.getInstance().trackMetric("WEAPON_DAMAGE", this, damage, enemy)
				}
				this.knockbackEffect(attackRadius, this.statList.getStat('attackKnockback'), true)
			}
			if (this.player.binaryFlags.has('barbarian-fast-rolls')) {
				Buff.apply(BuffIdentifier.BarbarianMovementSpeedBuff, this.player, this.player)
			}
			this.player.weapon.visible = true
	}

	knockbackEffect(radius, force, fx: boolean = true) {
		if (fx) {
			const pfxScale = radius /  BASE_PFX_EXPLOSION_SIZE
			Renderer.getInstance().addOneOffEffectByConfig(this.aoeEffectConfig, this.player.position.x, this.player.position.y, this.player.position.y - 200, pfxScale, 1)
			Audio.getInstance().playSfx('SFX_Elemental_Fire') // Placeholder sfx
		}
		this.player.knockbackSurroundingEnemies(radius, force)
	}

	update(delta: number) {
		if (this.player.isTumbleRolling) {
			// pretty heavy to happen every frame
			this.knockbackEffect(100, 350, false)
		}
	}

	resetStatsFunction(statList: EntityStatList) {
		defaultStatAttribute(statList)
		statList._actualStatValues.attackSize = 475
		statList._actualStatValues.placementRange = 0
		statList._actualStatValues.baseDamage = 10
		statList._actualStatValues.attackKnockback = 1_200
		statList._actualStatValues.skillDuration = 0.25

		statList._actualStatValues.maxAmmo = 1
		statList._actualStatValues.reloadAmmoIncrement = 1
		statList._actualStatValues.cooldownInterval = 300
		statList._actualStatValues.reloadInterval = 5_000
	}
}