{"frames": {

"character-barb.png":
{
	"frame": {"x":282,"y":3,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"character-cone-dog.png":
{
	"frame": {"x":425,"y":3,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"character-elf-n-ranjar.png":
{
	"frame": {"x":568,"y":3,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"character-radar.png":
{
	"frame": {"x":711,"y":3,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"character-solara.png":
{
	"frame": {"x":139,"y":3,"w":139,"h":138},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":138},
	"sourceSize": {"w":139,"h":138}
},
"locked-character.png":
{
	"frame": {"x":854,"y":3,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"locked-random.png":
{
	"frame": {"x":3,"y":145,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"locked-weapon.png":
{
	"frame": {"x":146,"y":146,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"map-icon-forest.png":
{
	"frame": {"x":289,"y":146,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"map-icon-mountain.png":
{
	"frame": {"x":432,"y":146,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"passives-field-op-gadget.png":
{
	"frame": {"x":575,"y":146,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"passives-martial-warfare.png":
{
	"frame": {"x":718,"y":146,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"passives-more-back-space.png":
{
	"frame": {"x":861,"y":146,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"passives-no-thumbs.png":
{
	"frame": {"x":3,"y":288,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"passives-rangers-mark.png":
{
	"frame": {"x":146,"y":289,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"passives-solunar-attunement.png":
{
	"frame": {"x":289,"y":289,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-5-chapter-story.png":
{
	"frame": {"x":432,"y":289,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-7-chapter-story.png":
{
	"frame": {"x":575,"y":289,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-10-chapter-story.png":
{
	"frame": {"x":718,"y":289,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-30-chapter-story.png":
{
	"frame": {"x":861,"y":289,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-a-shocking-number-of-explosions.png":
{
	"frame": {"x":3,"y":431,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-ailment-potency-bonus.png":
{
	"frame": {"x":146,"y":432,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-aoe-size.png":
{
	"frame": {"x":289,"y":432,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-attack-rate.png":
{
	"frame": {"x":432,"y":432,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-attack-size.png":
{
	"frame": {"x":575,"y":432,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-best-friend.png":
{
	"frame": {"x":718,"y":432,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-chaining.png":
{
	"frame": {"x":861,"y":432,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-character-skill-cooldown.png":
{
	"frame": {"x":3,"y":574,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-cooldown-speed.png":
{
	"frame": {"x":146,"y":575,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-currency-boost.png":
{
	"frame": {"x":289,"y":575,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-damage.png":
{
	"frame": {"x":432,"y":575,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-defensive-knock-aways-up.png":
{
	"frame": {"x":575,"y":575,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-deferred-learning.png":
{
	"frame": {"x":718,"y":575,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-elemental-maelstrom.png":
{
	"frame": {"x":861,"y":575,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-extra-hearts.png":
{
	"frame": {"x":3,"y":717,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-game-speed.png":
{
	"frame": {"x":146,"y":718,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-heart-drop-chance.png":
{
	"frame": {"x":289,"y":718,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-increase-chance-character.png":
{
	"frame": {"x":432,"y":718,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-increase-chance-generic.png":
{
	"frame": {"x":575,"y":718,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-increase-chance-primary.png":
{
	"frame": {"x":718,"y":718,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-increase-chance-secondary.png":
{
	"frame": {"x":861,"y":718,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-knockback.png":
{
	"frame": {"x":3,"y":860,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-main-weapon-agility.png":
{
	"frame": {"x":146,"y":861,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-main-weapon-crush.png":
{
	"frame": {"x":289,"y":861,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-main-weapon-vicious.png":
{
	"frame": {"x":432,"y":861,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-max-ammo.png":
{
	"frame": {"x":575,"y":861,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-move-speed.png":
{
	"frame": {"x":718,"y":861,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-narrow-focus.png":
{
	"frame": {"x":861,"y":861,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-pet-cooldown.png":
{
	"frame": {"x":3,"y":1003,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-pet-strength.png":
{
	"frame": {"x":146,"y":1004,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-pet-survival.png":
{
	"frame": {"x":289,"y":1004,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-pick-up-range.png":
{
	"frame": {"x":432,"y":1004,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-piercing.png":
{
	"frame": {"x":575,"y":1004,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-projectile-count.png":
{
	"frame": {"x":718,"y":1004,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-projectile-speed.png":
{
	"frame": {"x":861,"y":1004,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-regeneration.png":
{
	"frame": {"x":3,"y":1146,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-reload-speed.png":
{
	"frame": {"x":146,"y":1147,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-scrap-count.png":
{
	"frame": {"x":289,"y":1147,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-scroll-count.png":
{
	"frame": {"x":432,"y":1147,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-split-personality.png":
{
	"frame": {"x":575,"y":1147,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-splitting.png":
{
	"frame": {"x":718,"y":1147,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-start-story-with-plot-twist.png":
{
	"frame": {"x":861,"y":1147,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-weapon-focus.png":
{
	"frame": {"x":3,"y":1289,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-weapon-specialist.png":
{
	"frame": {"x":146,"y":1290,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-xp-boost.png":
{
	"frame": {"x":289,"y":1290,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-xp-on-start-of-level.png":
{
	"frame": {"x":432,"y":1290,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"perk-zoomies.png":
{
	"frame": {"x":575,"y":1290,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"primary-boomerang.png":
{
	"frame": {"x":718,"y":1290,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"primary-bow.png":
{
	"frame": {"x":861,"y":1290,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"primary-cannon.png":
{
	"frame": {"x":3,"y":1432,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"primary-spear.png":
{
	"frame": {"x":146,"y":1433,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"primary-wand.png":
{
	"frame": {"x":289,"y":1433,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"secondary-acid-bottle.png":
{
	"frame": {"x":432,"y":1433,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"secondary-bees.png":
{
	"frame": {"x":575,"y":1433,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"secondary-cone-dog-thorns.png":
{
	"frame": {"x":718,"y":1433,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"secondary-dark-stormy-night.png":
{
	"frame": {"x":861,"y":1433,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"secondary-fire-fairy.png":
{
	"frame": {"x":3,"y":3,"w":132,"h":132},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":132,"h":132},
	"sourceSize": {"w":132,"h":132}
},
"secondary-lute.png":
{
	"frame": {"x":3,"y":1575,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"secondary-nikolas-scope.png":
{
	"frame": {"x":146,"y":1576,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"secondary-pets.png":
{
	"frame": {"x":289,"y":1576,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"secondary-phoenix-bombs.png":
{
	"frame": {"x":432,"y":1576,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"secondary-rat-parade.png":
{
	"frame": {"x":575,"y":1576,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"secondary-tesla-coil.png":
{
	"frame": {"x":718,"y":1576,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"skills-aspect.png":
{
	"frame": {"x":861,"y":1576,"w":138,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":138,"h":139},
	"sourceSize": {"w":138,"h":139}
},
"skills-chomp.png":
{
	"frame": {"x":3,"y":1718,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"skills-lunar-blow.png":
{
	"frame": {"x":146,"y":1719,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"skills-pulse-shock.png":
{
	"frame": {"x":289,"y":1719,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"skills-solar-flare.png":
{
	"frame": {"x":432,"y":1719,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"skills-thorn-trap.png":
{
	"frame": {"x":575,"y":1719,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
},
"skills-tumble-roll.png":
{
	"frame": {"x":718,"y":1719,"w":139,"h":139},
	"rotated": false,
	"trimmed": false,
	"spriteSourceSize": {"x":0,"y":0,"w":139,"h":139},
	"sourceSize": {"w":139,"h":139}
}},
"meta": {
	"app": "https://www.codeandweb.com/texturepacker",
	"version": "1.1",
	"image": "perks-skills-characters-weapons-icons.png",
	"format": "RGBA8888",
	"size": {"w":1003,"h":1861},
	"scale": "1",
	"smartupdate": "$TexturePacker:SmartUpdate:5fdf5e48290584bb5ae1b998004e56ca:38f789183ffd351724cae1959257445f:5ade4839a16bee15af39096a3dc33ddc$"
}
}
