import { Vector } from "sat"
import { Audio } from "../engine/audio"
import { ComponentOwner } from "../engine/component-owner"
import { GameState } from "../engine/game-state"
import { SpritesheetAnimatorComponent } from "../engine/graphics/spritesheet-animator-component"
import EntityStatList from "../stats/entity-stat-list"
import { timeInMilliseconds, timeInSeconds } from "../utils/primitive-types"
import { ObjectPoolTyped, PoolableObject } from "../utils/third-party/object-pool"
import { AssetManager } from "../web/asset-manager"
import AISystem from "./enemies/ai-system"
import { Enemy } from "./enemies/enemy"
import { EntityType, IEntity } from "./entity-interfaces"

const EFFECT_LIFETIME = 0.5
const EFFECT_SPEED = 0.25
const GFX_ROTATION_OFFSET = Math.PI

const LONGBOW_FINAL_FORM_DAMAGE_MULT = 0.75
const LONGBOW_FINAL_FORM_KNOCKBACK_RADIUS = 500
const LONGBOW_FINAL_FORM_KNOCKBACK_ANGLE_SPREAD = Math.PI / 2

export interface LongbowBlowbackParams {
	position: Vector,
	angleBehindPlayer: number
	statList: EntityStatList
	damage: number
}

export class LongbowBlowback implements PoolableObject, ComponentOwner, IEntity {
	static pool: ObjectPoolTyped<LongbowBlowback, LongbowBlowbackParams>
	triedToReturnToPool: boolean

	nid: number
	entityType: EntityType
	timeScale: number = 1
	statList: EntityStatList
	damage: number

	position: Vector
	angle: number

	remainingLifetime: number

	visuals: SpritesheetAnimatorComponent

	numEntitiesChained: number
	numEntitiesPierced: number

	constructor() {
		this.makeVisuals()
		this.entityType = EntityType.GroundHazard
		this.position = new Vector()
		this.remainingLifetime = EFFECT_LIFETIME
		this.triedToReturnToPool = false
	}

	setDefaultValues(defaultValues: any, overrideValues?: LongbowBlowbackParams) {
		if (overrideValues) {

			this.position.copy(overrideValues.position)
			this.angle = overrideValues.angleBehindPlayer
			this.statList = overrideValues.statList
			this.damage = overrideValues.damage

			this.remainingLifetime = EFFECT_LIFETIME
			this.triedToReturnToPool = false

			GameState.addEntity(this)
			this.setVisuals()
			this.hitEnemy()
		}
	}

	cleanup() {
		this.visuals.removeFromScene()
		this.statList = null

		GameState.removeEntity(this)
	}

	update(delta: timeInSeconds, now?: timeInMilliseconds): void {
		this.visuals.update(delta)
		this.remainingLifetime -= delta
		if (!this.triedToReturnToPool && this.remainingLifetime <= 0) {
			this.returnToPool()
			this.triedToReturnToPool = true
		}
	}

	hitEnemy() {
		Audio.getInstance().playSfx('SFX_Knockback')
		const angleBehindPlayer = this.angle
		const origin = this.position
		const enemiesBehindPlayer = AISystem.getInstance().getEnemiesInAngleRange(origin, angleBehindPlayer, LONGBOW_FINAL_FORM_KNOCKBACK_ANGLE_SPREAD, LONGBOW_FINAL_FORM_KNOCKBACK_RADIUS)
		const reuseVector = new Vector()
		const knockback = this.statList.getStat('attackKnockback')
		if (enemiesBehindPlayer.length) {
			enemiesBehindPlayer.forEach((enemy: Enemy) => {
				enemy.takeDamageSimple(this.damage * LONGBOW_FINAL_FORM_DAMAGE_MULT)
				reuseVector.copy(enemy.position)
				reuseVector.sub(origin)
				reuseVector.normalize()
				reuseVector.scale(knockback, knockback)
				enemy.addKnockBack(reuseVector)
			})
		}
	}

	// Not used (yet)
	getKnockbackDirection(mutableEntityPos: Vector): Vector {
		return mutableEntityPos.sub(this.position).normalize()
	}

	makeVisuals() {
		const spriteSheet = AssetManager.getInstance().getAssetByName('longbow-pfx').spritesheet
		this.visuals = new SpritesheetAnimatorComponent(this, spriteSheet, 'longbow-pfx', EFFECT_SPEED)
	}

	setVisuals() {
		this.visuals.spriteSheetAnimator.rotation = this.angle + GFX_ROTATION_OFFSET
		this.visuals.spriteSheetAnimator.scale.y = -GameState.player.facingDirection
		this.visuals.addToScene()
		this.visuals.spriteSheetAnimator.restartCurrentAnim()
	}

	isPlayerOwned(): boolean {
		return true
	}

	returnToPool() {
		LongbowBlowback.pool.free(this)
	}
}